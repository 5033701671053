import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import { where, orderBy, limit } from 'firebase/firestore';
import { useCollection } from '../hooks/useCollection';
import { useLocation } from 'react-router-dom'

import EntryTicket from './EntryTicket'
import { useGetOrder } from '../hooks/useGetOrder';
import SignIn from './SignIn';
export default function Orders() {
    const { user } = useUserAuth();
    const [isPayClicked, setIsPayClicked] = useState(false);
    const [signInFlag, setSignInFlag] = useState(true);
    const { orders, orderCnt, isCompleted } = useGetOrder();
    const [isNewUser, setIsNewUser] = useState(false);
    const [reload, setReload] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    const queryEvent = [
        where('EventStatus', '==', 'Active'),
        orderBy('EventStartDate', 'desc'),
        // limit(30)
    ]

    const { documents: eventDocs, error: eventDocError } = useCollection('EventList', queryEvent)
    function setSignIn(flag, userData) {
        setSignInFlag(flag);
        // setUserDetails(userData);
    }

    useEffect(() => {
        setReload(!reload)
    }, [isCompleted])

    return (
        <>
            {!user.userInfo && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>}

            {user && user.userInfo && <div className="container-fluid">

                <div className="row no-gutters">
                    <div className="col-lg-12 col-md-12 col-sm-12">

                        <br></br>
                        <h4 style={{ fontWeight: '1000', color: '#348DCB', position: 'relative', top: '2px', textAlign: 'center' }}>
                            <span className="material-symbols-outlined" style={{ paddingRight: '10px', position: 'relative', top: '5px' }}>
                                shopping_cart
                            </span>
                            Cart
                        </h4>

                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12">

                        <div className="row no-gutters" id="divRegEvent">


                            {orders && eventDocs && orders.map((registrerObj) => {
                                let eventObt = eventDocs.find(e => e.id === registrerObj.EventID);

                                return <EntryTicket key={registrerObj.CategoryName + eventObt.EventID + registrerObj.ParticipantID}
                                    event={eventObt ? eventObt : ''}
                                    participant={registrerObj}
                                    setIsPayClicked={setIsPayClicked}
                                    showQR={false}
                                    setLoginFlag={setSignInFlag}
                                    playerID={registrerObj.ParticipantID}
                                    showColumn={4}
                                ></EntryTicket>
                            })}

                        </div>

                    </div>
                    {orders && orders.length === 0 && <div className="col-lg-12 col-md-12 col-sm-12">

                        <div className="row no-gutters" id="divRegEvent">
                            No order available in cart
                        </div>
                    </div>}

                </div>
            </div>}
        </>
    )
}
