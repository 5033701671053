import React, { useState, useEffect } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import { where, orderBy } from 'firebase/firestore';
import { useCollection } from '../hooks/useCollection';
import EntryTicket from './EntryTicket';
import Loading from './Loading';
export default function OrderHistory() {
    const { user } = useUserAuth();
    console.log('in Orders', user)
    const [eventIDs, setEventIDs] = useState([])
    const [playerIDs, setPlayerIDs] = useState([])
    const [orders, setOrders] = useState(null)
    const [isPayClicked, setIsPayClicked] = useState(false);
    const [signinFlag, setSigninFlag] = useState(true);
    const [orderCnt, setOrderCnt] = useState(0);
    const [flag, setFlag] = useState();
    const [allEvent, setAllEvent] = useState([]);

    const [activeEvent, setActiveEvent] = useState([]);
    const [activeEventCnt, setActiveEventCnt] = useState(0);
    const [inactiveEvent, setInactiveEvent] = useState([]);
    const [inactiveEventCnt, setInactiveEventCnt] = useState(0);
    const [completedEvent, setCompletedEvent] = useState([]);
    const [completedEventCnt, setCompletedEventCnt] = useState(0);
    const [cancelledEvent, setCancelledEvent] = useState([]);
    const [cancelledEventCnt, setCancelledEventCnt] = useState(0);
    const [onholdEvent, setOnholdEvent] = useState([]);
    const [onholdEventCnt, setOnholdEventCnt] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchedEntry, setSearchedEntry] = useState([]);
    const [selectTab, setSelectTab] = useState('Active');
    const [searchKey, setSearchKey] = useState('');
    const [entryDetails, setEntryDetails] = useState([]);

    // const { orders: myOrder, orderCnt: myOrderCnt } = useGetOrder(user && user.userInfo && user.userInfo.uid)
    // console.log('myOrder, orderCnt: myOrderCnt', myOrder, myOrderCnt)
    const queryUser = [
        where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ' '),

    ]
    const queryRegistration = [
        where('CreatedBy', '==', user && user.userInfo ? user.userInfo.uid : ' '),

    ]
    const queryEvent = [
        // where('EventStatus', 'in', ['Active',['Completed]]),
        // orderBy('EventStartDate', 'desc'),
        // limit(30)
    ]

    const { documents: eventDocs, error: eventDocError } = useCollection('EventList', queryEvent)
    const { documents: playerDocs, error: PlayerDocError } = useCollection('Participants', queryUser)
    const { documents: registrationDoc, error: registrationDocError } = useCollection('EventRegistrationDetails', queryRegistration)

    console.log('eventDocError', eventDocs, ':: PlayerDocError', playerDocs, 'registrationDoc', registrationDoc)

    useEffect(() => {

        let playerList = [];
        playerDocs.forEach(player => {
            player.PlayerID && playerList.push(player.PlayerID)

        });
        setPlayerIDs(playerList)

        let activeEventIds = []
        let inactiveEventIds = []
        let completedEventIds = []
        let onholdEventIds = []
        let cancelledEventIds = []

        eventDocs.forEach(element => {
            if (element.EventStatus === 'Active') {
                activeEventIds.push(element.id)
            } else if (element.EventStatus === 'Inactive') {
                inactiveEventIds.push(element.id)
            } else if (element.EventStatus === 'Completed') {
                completedEventIds.push(element.id)
            } else if (element.EventStatus === 'On-hold') {
                onholdEventIds.push(element.id)
            } else if (element.EventStatus === 'Cancelled') {
                cancelledEventIds.push(element.id)
            }
        });

        // setEventIDs(eventIds)

        let _finalActiveList = [];
        let _finalAllList = [];
        let _finalInactiveList = [];
        let _finalCompletedList = [];
        let _finalOnholdList = [];
        let _finalCancelledList = [];
        registrationDoc.forEach(registration => {
            console.log(registration, playerIDs)

            if (playerList.includes(registration.ParticipantID) && activeEventIds.includes(registration.EventID)) {
                _finalActiveList.push({
                    ...registration,
                    EventStatus: 'Active',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Active'
                })
                _finalAllList.push({
                    ...registration,
                    EventStatus: 'Active',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Active'
                })
            } else if (playerList.includes(registration.ParticipantID) && inactiveEventIds.includes(registration.EventID)) {
                _finalInactiveList.push({
                    ...registration,
                    EventStatus: 'Inactive',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Inactive'
                })
                _finalAllList.push({
                    ...registration,
                    EventStatus: 'Inactive',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Inactive'
                })
            } else if (playerList.includes(registration.ParticipantID) && completedEventIds.includes(registration.EventID)) {
                _finalCompletedList.push({
                    ...registration,
                    EventStatus: 'Completed',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Completed'
                })
                _finalAllList.push({
                    ...registration,
                    EventStatus: 'Completed',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Completed'
                })

            } else if (playerList.includes(registration.ParticipantID) && onholdEventIds.includes(registration.EventID)) {
                _finalOnholdList.push({
                    ...registration,
                    EventStatus: 'Onhold',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Onhold' + 'on-hold'
                })
                _finalAllList.push({
                    ...registration,
                    EventStatus: 'Onhold',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Onhold' + 'on-hold'
                })
            } else if (playerList.includes(registration.ParticipantID) && cancelledEventIds.includes(registration.EventID)) {
                _finalCancelledList.push({
                    ...registration,
                    EventStatus: 'Cancelled',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Cancelled'
                })
                _finalAllList.push({
                    ...registration,
                    EventStatus: 'Cancelled',
                    searchKey: registration.ParticipantID + registration.ParticipantName + registration.CategoryName + 'Cancelled'
                })
            }
        });


        setSearchedEntry(_finalAllList);
        setAllEvent(_finalAllList);
        setActiveEvent(_finalActiveList);
        setActiveEventCnt(_finalActiveList.length);
        setInactiveEvent(_finalInactiveList);
        setInactiveEventCnt(_finalInactiveList.length);
        setCompletedEvent(_finalCompletedList);
        setCompletedEventCnt(_finalCompletedList.length)
        setCancelledEvent(_finalCancelledList);
        setCancelledEventCnt(_finalCancelledList.length);
        setOnholdEvent(_finalOnholdList);
        setOnholdEventCnt(_finalOnholdList.length);

    }, [playerDocs, user, eventDocs, registrationDoc])


    // function GetDocumentList(list) {
    //     console.log(list)
    //     setOrders(list)
    //     // navigate("/MyOrder", { state: { 'orderList': list } });
    // }
    function setLoginFlag(flag) {
        // console.log('in setLoginFlag', flag)
        // setLoginFlagForWithdraw(true)
        setSigninFlag(flag);
    }

    function searchParticipation() {
        if (searchKey !== '') {
            var newArray = allEvent.filter(function (el) {
                return el.searchKey.toUpperCase().includes(searchKey.toUpperCase());
            }
            );
            // console.log(newArray);
            setSearchedEntry(newArray);
            // setFilterEventStatus('');
        }
    }
    console.log('playerIDs', playerIDs, eventIDs, orders, allEvent, activeEvent)
    let eventDet = null;
    return (
        <>
            <div className='container-fluid'>
                <div>
                    <h2>{'My Orders'}</h2>
                </div>
                <div className="row no-gutters">

                    {loading && <Loading></Loading>}
                    {((registrationDoc && registrationDoc.length === 0) && searchedEntry.length === 0) &&

                        <div style={{ alignContent: "center" }}>
                            <strong >You have no registration !!</strong>

                        </div>

                    }

                    {registrationDoc.length > 5 &&
                        <>
                            <br className='small'></br>
                            <div className='event-search-div'>
                                <div>
                                    <input style={{ height: '45px', borderRadius: '8px 0 0 8px' }} type="text" id="userName" placeholder='e.g. event name or player name or category name etc' required
                                        onChange={(e) => {
                                            setSearchKey(e.target.value)
                                            searchParticipation()

                                        }} value={searchKey} />
                                    <button className="mybutton button5" style={{ height: '45px', borderRadius: '0 8px 8px 0' }} onClick={() => {
                                        searchParticipation();

                                    }}>
                                        <span style={{ fontSize: '1.6rem' }} className="material-symbols-outlined">
                                            search
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </>
                    }

                    {/* {props.registrationType !== 'Pending' && <div> */}
                    {true && <div>
                        <br />
                        <div className="event-details-menu-outter sticky-top">
                            <div className="event-details-menu">
                                <a className={(selectTab === 'Active') ? 'active' : ''} onClick={(e) => {
                                    setSelectTab('Active')
                                    console.log('selectTab', selectTab)
                                }}>
                                    {/* <span className="material-symbols-outlined">
                                    info
                                </span> */}
                                    <h1>Upcoming</h1>
                                    <h2 className={(selectTab === 'Active') ? 'active' : ''}>{activeEventCnt}</h2>

                                </a>
                                <a className={(selectTab === 'Completed') ? 'active' : ''} onClick={(e) => {
                                    setSelectTab('Completed')
                                    // console.log('selectTab', selectTab)
                                }}>
                                    {/* <span className="material-symbols-outlined">
                                    directions_run
                                </span> */}
                                    <h1>Completed</h1>
                                    <h2 className={(selectTab === 'Completed') ? 'active' : ''}>{completedEventCnt}</h2>
                                </a>
                                <a className={(selectTab === 'Cancelled') ? 'active' : ''} onClick={(e) => {
                                    setSelectTab('Cancelled')
                                    // console.log('selectTab', selectTab)
                                }}>
                                    {/* <span className="material-symbols-outlined">
                                    groups
                                </span> */}
                                    <h1>Cancelled</h1>
                                    <h2 className={(selectTab === 'Cancelled') ? 'active' : ''}>{cancelledEventCnt}</h2>
                                    {/* <h2 className={calledFrom.current === 'Participant' ? 'active' : ''}>{participantCount.current}</h2> */}
                                </a>
                                <a className={(selectTab === 'Onhold') ? 'active' : ''} onClick={(e) => {
                                    setSelectTab('Onhold')
                                    console.log('selectTab', selectTab)
                                }}>
                                    {/* <span className="material-symbols-outlined">
                                    groups
                                </span> */}
                                    <h1>Onhold</h1>
                                    <h2 className={(selectTab === 'Onhold') ? 'active' : ''}>{onholdEventCnt}</h2>
                                    {/* <h2 className={cal
                                    ledFrom.current === 'Participant' ? 'active' : ''}>{participantCount.current}</h2> */}
                                </a>
                                <div style={{ width: false ? "100%" : "25%" }} className={selectTab === 'Active' ? 'event-details-menu-indicator Details' :
                                    selectTab === 'Completed' ? 'event-details-menu-indicator Entries' :
                                        selectTab === 'Cancelled' ? 'event-details-menu-indicator Participant' : ''}></div>
                            </div>

                        </div>
                    </div>}
                    <br />
                    {searchedEntry.map((participant) => {

                        // console.log('participant', participant, eventDocs);
                        eventDet = eventDocs.find(e => e.id === participant.EventID)
                        // console.log('eventDet', eventDet);
                        /*
                        <EntryTicket key={registrerObj.CategoryName + eventObt.EventID + registrerObj.ParticipantID}
                                                            event={eventObt ? eventObt : ''}
                                                            participant={registrerObj}
                                                            setIsPayClicked={setIsPayClicked}
                                                            showQR={false}
                                                            setLoginFlag={setLoginFlag}
                                                            playerID={registrerObj.ParticipantID}
                                                            showColumn={4}
                                                        ></EntryTicket>
                        */

                        if (selectTab === 'Active' && (participant.EventStatus.toUpperCase() === 'OPEN' || participant.EventStatus.toUpperCase() === 'UPCOMING' || participant.EventStatus.toUpperCase() === 'ACTIVE')) {
                            return <EntryTicket
                                key={participant.id}
                                event={eventDet}
                                participant={participant}
                                showQR={true}
                                setLoginFlag={setLoginFlag}
                                playerID={participant.ParticipantID}
                                showColumn={4}
                            ></EntryTicket>

                        } else if (selectTab === 'Cancelled' && participant.EventStatus.toUpperCase() === 'CANCELLED') {
                            return <EntryTicket
                                key={participant.id}
                                event={eventDet}
                                participant={participant}
                                showQR={true}
                                setLoginFlag={setLoginFlag}
                                playerID={participant.ParticipantID}
                                showColumn={4}></EntryTicket>
                        } else if (selectTab === 'Completed' && (participant.EventStatus.toUpperCase() === 'CLOSED' || participant.EventStatus.toUpperCase() === 'COMPLETED')) {
                            return <EntryTicket
                                key={participant.id}
                                event={eventDet}
                                participant={participant}
                                showQR={true}
                                setLoginFlag={setLoginFlag}
                                playerID={participant.ParticipantID}
                                showColumn={4}></EntryTicket>
                        } else if (selectTab === 'Onhold' && participant.EventStatus.toUpperCase() === 'ONHOLD') {
                            return <EntryTicket
                                key={participant.id}
                                event={eventDet}
                                participant={participant}
                                showQR={true}
                                setLoginFlag={setLoginFlag}
                                playerID={participant.ParticipantID}
                                showColumn={4}></EntryTicket>
                        }


                    })}
                </div>
            </div>
        </>
    )
}
