import React, { useState, useEffect } from 'react'
import EventDetailsMenu from './EventDetailsMenu'
import EDTournamentDetails from './EDTournamentDetails'
import EDAboutEvent from './EDAboutEvent'
import EventDetailsLogo from './EventDetailsLogo'
import '../css/EventDetails.css'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import { useParams } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument.js'
import { where, orderBy } from 'firebase/firestore';
import { useCollection } from '../hooks/useCollection.js'
import { useUserAuth } from '../context/UserAuthcontext.js'
// import { documentId } from 'firebase/firestore'
export default function EventDetails() {
    const { eventID } = useParams();
    const { user } = useUserAuth()
    const [isDrawAvailable, setIsDrawAvailable] = useState(false)

    const queryConstraints = [
        where('EventID', '==', eventID),
        // Add more query constraints as needed
    ];
    const queryConstraintsRegistration = [
        where('EventID', '==', eventID),
        orderBy('ParticipantName')
        // Add more query constraints as needed
    ];

    const queryConstraintsCategory = [
        where('EventID', '==', eventID),
        orderBy('Gender'),
        orderBy('EventType'),
        orderBy('CategoryName'),
        // Add more query constraints as needed
    ];

    console.log('queryConstraints : ', queryConstraints)
    const { document: eventDoc, error: eventDocError } = useDocument('EventList', eventID)
    const { documents: ownerDocs, error: ownerDocError } = useCollection('EventOwner', queryConstraints)
    const { documents: controllerDocs, error: controllerDocError } = useCollection('EventController', queryConstraints)
    const { documents: categoryDocs, error: categoryDocError } = useCollection('EventCategory', queryConstraintsCategory)
    const { documents: participantDocs, error: participantDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistration)
    const { documents: matchKnockoutDocs, error: matchKnockoutDocError } = useCollection('TX_MatchDetails', queryConstraints)
    const { documents: matchLeagueDocs, error: matchLeagueDocError } = useCollection('TX_MatchDetails', queryConstraints)

    const [eventDetails, setEventDetails] = useState(null);
    const [entryCount, setEntryCount] = useState(0);
    const [dataFlag, setDataFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [partcipantObj, setPartcipantObj] = useState(
        {
            uniqueParticipantDetails: [],
            participantDetails: [],
            participantCount: 0,
            flagSet: false
        }
    )
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location])
    function GetDocumentList(list) {
        console.log(list)
    }
    function checkEditable() {

        let userPhone = ''
        userPhone = user && user.userInfo && user.userInfo.Phone && user.userInfo.Phone.substring(user.userInfo.Phone.length - 10, user.userInfo.Phone.length)
        // console.log('userPhone : ', userPhone)
        // console.log('eventDoc.EventOwnerPhone.search(userPhone) : ', eventDoc && eventDoc.EventOwnerPhone && eventDoc.EventOwnerPhone.search(userPhone))
        // console.log('owner ', ownerDocs)
        // console.log('owner ', ownerDocs)
        console.log('ownerDocs.find(e => e.OwnerPhone.includes(userPhone)) ',
            ownerDocs.find(e => e.OwnerPhone.includes(userPhone)))

        if (user && user.userInfo && eventDoc && eventDoc.EventOwnerPhone && eventDoc.EventOwnerPhone.search(userPhone) >= 0) {
            console.log('in 1')

            setIsEditEnabled(true)
        }

        if ((user && user.userInfo && (eventDoc && eventDoc.OrganizerID === user.userInfo.uid)) ||
            (user && user.userInfo && user.userInfo.SelectedRole && (user.userInfo.SelectedRole.toUpperCase() === 'ADMIN'))) {
            console.log('in 2')
            setIsEditEnabled(true)
        }
        if (ownerDocs && ownerDocs.find(e => e.OwnerPhone.includes(userPhone))) {
            console.log('in 3')
            setIsEditEnabled(true)
        }
        if (eventDoc && eventDoc.EventOwnerPhone && eventDoc.EventOwnerPhone.search(userPhone) >= 0) {
            console.log('in 4')
            setIsEditEnabled(true)
        }

        if (matchKnockoutDocs && matchKnockoutDocs.length > 0) {
            setIsDrawAvailable(true)
        }
        if (matchLeagueDocs && matchLeagueDocs.length > 0) {
            setIsDrawAvailable(true)
        }
        // setLoading(false);
    }
    function getData() {
        // setLoading(true);
        let uplayerList = []
        let cnt = 0;
        participantDocs.forEach(element => {
            if (!uplayerList.find(e => e.ParticipantID === element.ParticipantID)) {
                cnt = cnt + 1;

                uplayerList.push({
                    ParticipantID: element.ParticipantID,
                    PlayerName: element.ParticipantName,
                    ParticipantUserID: element.PlayerUserID
                })
            }
            if (element.PartnerPlayerID !== '') {
                if (!uplayerList.find(e => e.ParticipantID === element.PartnerPlayerID)) {
                    uplayerList.push({
                        ParticipantID: element.PartnerPlayerID,
                        PlayerName: element.PartnerPlayerName,
                        ParticipantUserID: ''
                    })
                }
            }

            setEntryCount(cnt);
            // setLoading(false);
            setPartcipantObj({
                uniqueParticipantDetails: uplayerList,
                participantDetails: participantDocs,
                participantCount: cnt,
                flagSet: true
            });

        });
    }


    useEffect(() => {
        setEventDetails(eventDoc);
        onAuthStateChanged(auth, (user) => {
            if (user) {

                const uid = user.uid;
                // ...
                // console.log("uid", uid)
            } else {
                // User is signed out
                // ...
                // console.log("user is logged out")
            }
            // console.log(eventDetails);
        })
        getData();
        checkEditable()
        setDataFlag(true)


        // setQuery2([
        //     where('EventID', 'in', ['7Xe5nQpp2wlPv7mGy5n9', 'Tvn8yMDM8gFmUAxZWU4x',
        //         'b5xgXV2Rk5QNI7j33YVY',
        //         'AxvreAHIeggsCLIV6D41',
        //         'OxSEg0LPMC7l9fInqvZy',
        //         'lBzLODoqf7CuilVflm5c',
        //         'qsgPoTagtd2mQzRpAeXk',
        //         '6pxlSuVRQBM0B2PAue9M',
        //         'ObI5vT5C7IJWLbGj0Qpg',
        //         '3pDlQgIAfsqBtsg8SGJ7',
        //         'DRXdoL1i4v0dmVAXQA5i',
        //         'Y5wZgJ4ahxcv0NVr87lj'
        //     ]),
        //     where('ParticipantID', 'in', ['TPL44', 'TPL45', 'TPL46']),
        // ]
        // )
        // console.log('before query')
        // setQuery1([
        //     where(documentId(), 'in', ['7Xe5nQpp2wlPv7mGy5n9', 'Tvn8yMDM8gFmUAxZWU4x',
        //         'b5xgXV2Rk5QNI7j33YVY',
        //         'AxvreAHIeggsCLIV6D41',
        //         'OxSEg0LPMC7l9fInqvZy',
        //         'lBzLODoqf7CuilVflm5c',
        //         'qsgPoTagtd2mQzRpAeXk',
        //         '6pxlSuVRQBM0B2PAue9M',
        //         'ObI5vT5C7IJWLbGj0Qpg',
        //         '3pDlQgIAfsqBtsg8SGJ7',
        //         'DRXdoL1i4v0dmVAXQA5i',
        //         'Y5wZgJ4ahxcv0NVr87lj'
        //     ]),
        // ]
        // )
    }, [eventDoc, ownerDocs, controllerDocs, categoryDocs]);


    // const mLink = useMemo(() => {
    //     return eventDoc && partcipantObj && partcipantObj.flagSet && <EventDetailsMenu calledFrom='Details'
    //         // {<EventDetailsMenu calledFrom='Details'

    //         eventID={eventDoc.eventID}
    //         eventDetails={eventDoc}
    //     // entryCount={entryCount}
    //     //uniqueParticipantDetails={partcipantObj.uniqueParticipantDetails}
    //     //participantDetails={partcipantObj.participantDetails}
    //     //participantCount={partcipantObj.participantCount}
    //     />
    // }, [eventDoc, partcipantObj])
    console.log('IsEditEnabled : ', isEditEnabled);
    console.log('eventDetails : ', eventDetails);
    return (
        <>
            {eventDoc && <EventDetailsMenu calledFrom='Details'

                eventID={eventID}
                eventDetails={eventDoc}
            // entryCount={entryCount}
            // uniqueParticipantDetails={partcipantObj.uniqueParticipantDetails}
            // participantDetails={partcipantObj.participantDetails}
            // participantCount={partcipantObj.participantCount}
            />}
            {/* {console.log(memoSetPartcipantObj)} */}

            <div className="container-fluid">

                <div className="row no-gutters">

                    <div className="col-lg-8 col-md-12 col-sm-12">

                        {eventDoc && dataFlag && <EventDetailsLogo IsEditEnabled={isEditEnabled} eventDetails={eventDoc}></EventDetailsLogo>}
                    </div>
                    {/* {loading && <lottie-player style={{ height: '400px' }} background="transparent" speed="1" loop autoplay></lottie-player>}
                    {loading && <Loading height='400px' weight='100%'></Loading>} */}

                    {eventDetails && dataFlag && <EDTournamentDetails IsEditEnabled={isEditEnabled}
                        eventDetails={eventDetails}
                        showRegistration={true}
                        showRegistrationButton={true}
                    // partcipantObj={partcipantObj}
                    // rentryCount={entryCount}
                    // isDrawAvailable={isDrawAvailable} 
                    />}


                    {eventDoc && dataFlag && <EDAboutEvent IsEditEnabled={isEditEnabled}
                        eventDetails={eventDoc}
                        ownerDetails={ownerDocs}
                        controllerDetails={controllerDocs}
                        categoryDetails={categoryDocs}
                        isDrawAvailable={isDrawAvailable}
                    />}
                </div>
            </div>
        </>
    )
}
