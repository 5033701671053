import React, { useState } from 'react'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'
import RichTextEditor from "react-rte";

export default function EditEventRules(props) {
    const [resultMessage, setResultMessage] = useState('');

    const [editedRules, setEditedRules] = useState(props.eventDetails && props.eventDetails.RulesAndRegulations ? props.eventDetails.RulesAndRegulations : '');
    const [isRulesEdit, setIsRulesEdit] = useState(false);
    const [rulesvalue, setRulesvalue] = useState(
        RichTextEditor.createValueFromString(editedRules, "html")
    );
    const handleCancelRules = () => {
        setIsRulesEdit(false);
    };

    const handleEditRules = () => {
        setIsRulesEdit(true);
    };

    const handleSaveRules = async () => {
        try {
            //save in DB Start
            SaveRegulation(rulesvalue.toString("html"))
            //save in DB End

            setIsRulesEdit(false);
        } catch (error) {
            console.error("Error updating document:", error);
        }
    };

    async function SaveRegulation(rulesAndRegulations) {
        console.log('in SaveRegulation');


        var para1 = {};
        // var rulesAndRegulations = props.eventDetails.RulesAndRegulations.replaceAll("\n", ";");

        para1 = {
            EventID: props.eventDetails.Eventid,
            RulesAndRegulations: rulesAndRegulations,
        };
        console.log('para1', para1)
        const ret = await httpsCallable(functions, "updateEventDetails_RulesAndRegulations");
        ret(para1).then(async (result) => {
            setResultMessage('Event Rules Saved Successfully !!')
            props.setEventDetails({
                ...props.eventDetails,
                RulesAndRegulations: rulesAndRegulations
            })

            setTimeout(function () {
                setResultMessage('');
            },);
            // setLoading(false);
        })
    }
    return (
        <div id="regulationDiv" className="event-setup-announcement-section">

            <div className="row no-gutter">

                <div className="event-detail-input-div" style={{ padding: '0 20px', display: 'flex' }}>
                    <h1 style={{ paddingRight: '20px' }}>Rules & Regulations</h1><br />
                    <div style={{
                        // position: 'absolute',
                        // right: '-50px',
                        // top: '50%',
                        // transform: 'translateY(-100%)',
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        background: '#348DCB',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }} onClick={() => handleEditRules()}>
                        <span style={{ fontSize: '1.1rem', color: '#fff' }} className="material-symbols-outlined">
                            edit
                        </span>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="event-detail-input-div">

                        {/* <textarea name="" onChange={(e) =>
                            props.setEventDetails({
                                ...props.eventDetails,
                                RulesAndRegulations: e.target.value
                            })}
                            value={props.eventDetails.RulesAndRegulations}
                            className=""></textarea> */}

                        {isRulesEdit ? (
                            <div>
                                <div>
                                    <RichTextEditor
                                        value={rulesvalue}
                                        onChange={setRulesvalue}
                                    />
                                </div>
                                <div className="vg10"></div>
                                <div className="d-flex justify-content-between">
                                    <div
                                        className="theme_btn btn_border"
                                        onClick={handleCancelRules}
                                        style={{
                                            width: "fit-content",
                                        }}
                                    >
                                        <button className="mybutton button5 event-details-save-btn"
                                            onClick={handleCancelRules} type="button">Cancel</button>

                                    </div>
                                    <div
                                        className="theme_btn btn_fill"
                                        onClick={handleSaveRules}
                                        style={{
                                            width: "fit-content",
                                        }}
                                    >
                                        <button className="mybutton button5 event-details-save-btn"
                                            onClick={handleSaveRules} type="button">Save Rules</button>


                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="d-flex align-items-center">
                                    <p
                                        className="editortext"
                                        dangerouslySetInnerHTML={{
                                            __html:

                                                rulesvalue.toString(
                                                    "html"
                                                ),
                                        }}
                                    ></p>

                                </div>
                            </>
                        )}
                    </div>
                </div>
                {/* 
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="add-more-line">
                        <div></div>
                        <a href="/">ADD ONE MORE LINE</a>
                    </div>
                </div> */}
                {/* <div id="errorMsgRegulation" style={{ display: 'none' }}
                  className="col-lg-12 col-md-12 col-sm-12"><br />

                  <div className="event-detail-input-div">
                      <div></div>
                      <span id="errorMssageRegulation">{errorMssageRegulation}</span>
                  </div>

              </div> */}
                {/* <div className="col-lg-12 col-md-12 col-sm-12"><br />

                    <div className="event-details-save-btn-div">
                        <div></div>
                        <button className="mybutton button5 event-details-save-btn"
                            onClick={SaveRegulation} type="button">SAVE regulations</button>
                    </div>

                </div> */}
                <div className="col-lg-12 col-md-12 col-sm-12"><br />
                    <div className="event-details-save-btn-div">
                        {resultMessage}
                    </div><br />

                </div>

            </div>

        </div>
    )
}
