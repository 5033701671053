import React, { useState } from 'react';
import QRCode from 'react-qr-code';

export default function QRCodeComponent(props) {
    const [back, setBack] = useState('white')
    const [fore, setFore] = useState('black')
    const [size, setSize] = useState(10)
    const [value, setValue] = useState('10')
    return (
        <center>
            {/* <br />
            <br />
            <input
                type="text"
                onChange={(e) => setValue(e.target.value)}
                placeholder="Value of Qr-code"
                value={value}
            />
            <br />
            <br />
            <input
                type="text"
                onChange={(e) => setBack(e.target.value)}
                placeholder="Background color"
            />
            <br />
            <br />
            <input
                type="text"
                onChange={(e) => setFore(e.target.value)}
                placeholder="Foreground color"
            />
            <br />
            <br />
            <input
                type="number"
                onChange={(e) => setSize(parseInt(e.target.value ===
                    '' ? 0 : e.target.value, 10))}
                placeholder="Size of Qr-code"
            />
            <br />
            <br />
            <br /> */}
            {props.value && (
                <QRCode
                    title="GeeksForGeeks"
                    value={props.value}
                    bgColor={back}
                    fgColor={fore}
                    size={size === '' ? 0 : props.size}
                />
            )}
        </center>
    )
}
