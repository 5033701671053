import React, { useEffect, useState } from 'react'
import { useRazorpay } from '../hooks/useRozarPay';
import { useUserAuth } from '../context/UserAuthcontext';
// import { useLocalStorage } from "../context/useLocalStorage";
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";

export default function PaymentSection(props) {
    console.log('props', props)
    const [paymentSectionFlag, setPaymentSectionFlag] = useState(false);
    const [loading, setLoading] = useState(false);

    const { user } = useUserAuth();

    const [paymentSummary, setPaymentSummary] = useState({
        totalFee: 0,
        categoryCount: 0,
        convenienceCharge: 0,
        convenienceChargeTxt: '0',
        totalPayment: 0,
        totalPaymentTxt: '0',
        regID: [],

    })
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };
    const { makePayment, paymentResponse } = useRazorpay();
    // var options = {
    //     year: '2-digit',
    //     // year: 'numeric',
    //     month: 'short',
    //     day: 'numeric'
    // };
    useEffect(() => {
        console.log('props', props);
        let tAmt = 0;
        let tEvents = 0;
        let convenienceAmt = 0;
        let idList = [];
        props.Category.forEach(element => {
            tAmt = tAmt + Number(element.Fees);
            tEvents = tEvents + 1;
            idList.push(element.id);
        });
        if (!props.ShowCheckOut && props.ConvenienceCharge && props.ConvenienceCharge !== '' && props.ConvenienceCharge !== '0') {
            convenienceAmt = Math.round(tAmt * Number(props.ConvenienceCharge) / 100);
        }
        let totalPayment = Number(tAmt) + Number(convenienceAmt);
        setPaymentSummary({
            totalFee: Number(tAmt),
            categoryCount: tEvents,
            convenienceCharge: Number(convenienceAmt),
            convenienceChargeTxt: Number(convenienceAmt).toLocaleString('en-IN', curFormat),
            totalPayment: Number(totalPayment),
            totalPaymentTxt: Number(totalPayment).toLocaleString('en-IN', curFormat),
            regID: idList
        })
    }, [props])
    const handlePayment = () => {

        if (!(user && user.isLoggedIn && user.userInfo)) {
            props.setIsPayClicked(true);
        } else {
            let orderId = 'O_' + props.EventCode + '_' + props.PlayerID + '_' + new Date().getTime();
            let amount = paymentSummary.totalPayment;
            amount = 1

            makePayment(orderId,
                Number(amount),
                user && user.userInfo && user.userInfo.UserName, 'Payment for Registration',
                user && user.userInfo ? user.userInfo.Email : '',
                user.phoneNumber,
                paymentSummary.regID,
                // 'anita tripathi', 'Payment for Registration',
                // 'anitatripathi@gmail.com',
            ).then(async (e) => {
                console.log(e)
            })
        }


    };

    useEffect(() => {
        // console.log(paymentResponse)
        // let totalPayment = Number(convenienceAmt) + Number(props.participant.Fees);
        let status = '';
        // console.log('paymentResponse : ', paymentResponse);
        if (paymentResponse) {
            // console.log('paymentResponse.paymentStatus.toUpperCase()', paymentResponse.paymentStatus.toUpperCase())
            status = paymentResponse.paymentStatus.toUpperCase() === 'SUCCESS' ? 'Completed' : 'Pending';

            if (status === 'Completed') {
                // setNewPaymentStatus(status);
                if (paymentResponse && paymentResponse.transactionID && paymentResponse.transactionID !== '')
                    updateTransactioninDB(paymentResponse.transactionID, paymentSummary.totalPayment, status, paymentSummary.regID)//to be un commented 
            }
        }

    }, [paymentResponse])


    async function updateTransactioninDB(transactionid, paymentAmount, paymentstatus, catList) {
        if (user && user.userInfo) {
            let para = {
                RegisteredList: catList,
                TransactionID: transactionid,
                PaymentAmount: paymentAmount,
                PaymentStatus: paymentstatus,

                UserID: user.userInfo.uid

            };
            console.log('para', para);
            const updatetransaction = httpsCallable(functions, "updateRegistrationListWithTransactionID");

            return updatetransaction(para)
                .then(async (result) => {
                    // console.log('result', result)
                    // getParticipantList(selectedEvent.Eventid, searchEvent.EventCode);
                    // setPopupLoading(false);
                    // setShowPopup(false);
                })
        }
    }
    return (
        <div className={paymentSectionFlag ? 'payment-grid-div open' : 'payment-grid-div'} >
            <div className='payment-grid-div-arrow' onClick={() => setPaymentSectionFlag(!paymentSectionFlag)}>
                <span className="material-symbols-outlined">
                    expand_less
                </span>
            </div>

            <div className='payment-grid-div-first'>
                <div onClick={() => setPaymentSectionFlag(!paymentSectionFlag)}>
                    <h1>{paymentSummary.totalPaymentTxt}</h1>
                    <h2>{paymentSummary.categoryCount} Category</h2>
                </div>
                {props.Category.length === 0 ? <div>
                    <img src="/img/paid1.png" width='100px' alt="" />
                </div> : <div>
                    {!props.ShowCheckOut && <button className='mybutton button5'
                        style={{
                            height: '35px',
                            fontWeight: '600',
                            width: 'auto',
                            background: '#fff',
                            color: '#e10032',
                            minWidth: '120px'
                        }}
                        onClick={() => handlePayment()}>
                        {'Pay : ₹' + paymentSummary.totalPayment}
                    </button>}
                    {props.ShowCheckOut && <button className='mybutton button5'
                        style={{
                            height: '35px',
                            fontWeight: '600',
                            width: 'auto',
                            minWidth: '120px',
                            background: '#fff',
                            color: '#e10032',
                        }}
                        onClick={() => {
                            setLoading(true);
                            props.saveCategory()
                        }}>
                        <div style={{ display: !loading ? 'block' : 'none' }}>
                            <span
                                style={{ position: 'relative', top: '1.5px', left: '5px', fontSize: '0.9rem' }}>CHECKOUT</span>
                        </div>
                        <div className='btn-loading payment-section' style={{ display: loading ? 'block' : 'none', }}>
                            <lottie-player style={{ filter: 'brightness(50%) sepia(100) saturate(100) hue-rotate(25deg)' }}
                                src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                speed="0.7" loop autoplay></lottie-player>
                        </div>
                    </button>}
                </div>}
                <div className='payment-grid-div-first-click' onClick={() => setPaymentSectionFlag(!paymentSectionFlag)}></div>
            </div>
            <div className='payment-grid-div-second'>

                {props.Category && props.Category.map((category) => {
                    let amt = Number(category.Fees).toLocaleString('en-IN', curFormat)
                    return <div key={category.CategoryName}>
                        <span>{amt}</span>
                        <small>{category.CategoryName}</small>
                    </div>
                })}
                {!props.ShowCheckOut && paymentSummary.convenienceCharge > 0 &&
                    <div>
                        <span style={{ color: '#fff' }}>{paymentSummary.convenienceChargeTxt}</span>
                        <small style={{ color: '#fff' }}>{'Convenience Charges (' + props.ConvenienceCharge + '%)'}</small>
                    </div>
                }

                {/* <div>
                    <small style={{ color: '#fff' }}>Convenience Charges (5%)</small>
                    <span style={{ color: '#fff' }}>₹250</span>
                </div> */}
            </div>
        </div>
    )
}
