import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRazorpay } from '../hooks/useRozarPay';
import { useLocalStorage } from "../context/useLocalStorage";
import { useUserAuth } from '../context/UserAuthcontext';
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import { useNavigate } from 'react-router-dom';
import { where } from 'firebase/firestore';
import { useCollection } from '../hooks/useCollection.js';
import useDocumentUpdate from '../hooks/useDocumentUpdate.js';
function PGCreateEventPayment(props) {
    // const { state } = useLocation();
    // const {    } = state;
    console.log('props', props)
    // const [EventID, setEventID] = useState(props.EventID);
    // const [EventName, setEventName] = useState(props.EventName);
    const [EventStartDate, setEventStartDate] = useState(props.EventStartDate);
    const [City, setCity] = useState(props.City);
    // const [SportCode, setSportCode] = useState(props.SportCode);
    // const [SportName, setSportName] = useState(props.SportName);
    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();

    const { user } = useUserAuth();
    const navigate = useNavigate();

    const { makePayment, paymentResponse, handleRefund } = useRazorpay();
    const [totalPayment, setTotalPayment] = useState(0);
    const [gst, setGST] = useState(0);
    // const [popupLoading, setPopupLoading] = useState(false);

    // console.log('EventID: ', EventID)

    const query = [
        where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ' '),
    ]
    const { documents: packageDoc, error: packageDocError } = useCollection('UserPackageDetails', query)
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    console.log('packageDoc', packageDoc)
    useEffect(() => {
        let _amount = 0;
        if (packageDoc[0] && packageDoc[0].PaymentStatus === 'Pending') {
            _amount = Number(packageDoc[0].PaymentAmount) * 0.18;
            setGST(_amount)
            setTotalPayment(Number(packageDoc[0].PaymentAmount));

        }

    }, [packageDoc])
    function mymakePayment() {
        let orderId = props.EventID + '_' + new Date().getTime();
        setTotalPayment(1);
        // console.log('userDetails : ', userDetails, 'orderId : ', orderId, totalPayment)
        makePayment(orderId,
            Number(1),
            user && user.userInfo ? user.userInfo.UserName : '', 'Payment for Registration',
            user && user.userInfo ? user.userInfo.Email : '',
            user && user.userInfo ? user.userInfo.Phone : '',
            ['EventID']
        ).then(async (e) => {
            console.log(e, 'paymentResponse', paymentResponse);

        })

    }

    useEffect(() => {
        console.log(paymentResponse)
        let status = '';
        if (paymentResponse) {
            console.log('paymentResponse.paymentStatus.toUpperCase()', paymentResponse)
            status = paymentResponse.paymentStatus.toUpperCase() === 'SUCCESS' ? 'Completed' : 'Pending';
            if (status !== '' && status.toLowerCase() === 'completed') {
                // setNewPaymentStatus(status);
                updateTransactioninDB(paymentResponse.transactionID, totalPayment, status)
            }
        }

    }, [paymentResponse])

    async function updateEvent() {
        let _updateEvent = {
            'PaymentStatus': 'Completed',
            // 'PaymentAmount': paymentAmount,
            // 'TransactionID': transactionid,
            'ApprovalStatus': 'Approved',
            'EventStatus': 'Active',
            'UpdatedBy': user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        console.log(_updateEvent, props.EventID)
        await updateDocument('EventList', props.EventID, _updateEvent)

        let _updatePackage = {
            // 'PaymentStatus': paymentstatus,
            // 'TransactionID': transactionid,
            // 'TotalPaymentRecived': paymentAmount,
            'EventsAlreadyCreated': packageDoc[0].EventsAlreadyCreated + 1,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        console.log(_updatePackage, packageDoc[0].id)
        await updateDocument('UserPackageDetails', packageDoc[0].id, _updatePackage)

        navigate("/EventDetails/" + props.EventID);


    }
    async function updateTransactioninDB(transactionid, paymentAmount, paymentstatus) {
        // setPopupLoading(true);
        // console.log('user', user, 'userDetails', userDetails)
        if (user && user.userInfo) {
            let _updatePackage = {
                'PaymentStatus': paymentstatus,
                'TransactionID': transactionid,
                'TotalPaymentRecived': paymentAmount,
                'EventsAlreadyCreated': packageDoc[0].EventsAlreadyCreated + 1,
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()
            }
            await updateDocument('UserPackageDetails', packageDoc[0].id, _updatePackage)

            let _updateEvent = {
                'PaymentStatus': paymentstatus,
                'PaymentAmount': paymentAmount,
                'TransactionID': transactionid,
                'ApprovalStatus': 'Approved',
                'EventStatus': 'Active',
                'UpdatedBy': user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()
            }

            await updateDocument('EventList', props.EventID, _updateEvent)
            navigate("/EventDetails/" + props.EventID);

            // const updatetransaction = httpsCallable(functions, "updateTransactionIDForEventRegistration");
            // let para = {
            //     EventID: EventID,
            //     TransactionID: transactionid,
            //     PaymentAmount: paymentAmount,
            //     PaymentStatus: paymentstatus,


            // };
            // console.log('para', para);
            // return updatetransaction(para)
            //     .then(async (result) => {
            //         console.log('result', result)

            //         navigate("/EventDetails/" + EventID);

            //     })
        }
    }
    function payLater() {
        navigate("/OrganizerDashboard/");

    }

    return (
        <>
            <div className='payment-div'>
                <div className='payment-div-inner'>
                    <div style={{ width: '100%' }}>
                        <div className='row no-gutters' style={{ width: '100%' }}>
                            <div className='col-2'></div>
                            <div className='col-8' style={{ padding: '0 0 10px 25px' }}>
                                <div className='sports-div active'>
                                    <img src={props.sportImage} alt="" />
                                    <h1>{props.SportName}</h1>
                                </div>
                            </div>
                            <div className='col-2'></div>
                        </div>
                    </div>
                    <div className='payment-div-inner-details'>
                        <div>
                            <small>Event Name</small>
                            <h1>{props.EventName}</h1>
                        </div>
                        <div>
                            <small>City</small>
                            <h1>{City}</h1>
                        </div>
                    </div>
                    <div className='payment-div-inner-details'>
                        <div>
                            <small>Organization</small>
                            <h1>{props.OrganizationName}</h1>
                        </div>
                        <div>
                            <small>Start Date</small>
                            <h1>{
                                EventStartDate.seconds ?
                                    new Date(EventStartDate.seconds * 1000).toISOString().split("T")[0]
                                    : EventStartDate._seconds ?
                                        new Date(EventStartDate._seconds * 1000).toISOString().split("T")[0]
                                        : new Date().toISOString().split("T")[0]
                            }</h1>
                        </div>
                    </div>

                    <div style={{ width: '100%', borderBottom: '1px solid #ddd', margin: '10px 0' }}></div>

                    {packageDoc[0] && packageDoc[0].PaymentStatus === 'Pending' && <div className='payment-div-inner-details'>
                        <div>
                            <small>TPLiVE CHARGES</small>
                            <h1>{Number(totalPayment).toLocaleString('en-IN', curFormat)}</h1>
                        </div>
                        <div>
                            <small>GST</small>
                            <h1>{Number(gst).toLocaleString('en-IN', curFormat)}</h1>
                        </div>
                    </div>}

                    {/* <h2>Payment Status: {packageDoc[0] && packageDoc[0].PaymentStatus}</h2> */}
                    {/*  <h3>Total Payable: {Number(gst + totalPayment).toLocaleString('en-IN', curFormat)}</h3> */}
                    <br />
                    {packageDoc[0] && packageDoc[0].PaymentStatus === 'Pending' && <button className='mybutton button5' style={{ fontSize: '1.2rem' }} onClick={mymakePayment}>Pay Now {Number(gst + totalPayment).toLocaleString('en-IN', curFormat)}</button>}
                    {packageDoc[0] && packageDoc[0].PaymentStatus === 'Pending' && <button className='mybutton button5' style={{ fontSize: '1.2rem' }} onClick={payLater}>Pay Later {Number(gst + totalPayment).toLocaleString('en-IN', curFormat)}</button>}
                    {packageDoc[0] && packageDoc[0].PaymentStatus !== 'Pending' && <button className='mybutton button5' style={{ fontSize: '1.2rem' }} onClick={updateEvent}>Activate Event </button>}

                </div>
            </div>
        </>
    )
}

export default PGCreateEventPayment
