import React, { useEffect } from 'react'
import Events from './Events'
import { useLocation } from 'react-router-dom';
export default function Clubs() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    return (
        <Events></Events>
    )
}
