import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import '../css/Dashboard.css'
import ManageParticipant from '../components/ManageParticipant';
import { useState, useEffect } from "react";
import { useUserAuth } from '../context/UserAuthcontext'
import { useLocalStorage } from "../context/useLocalStorage";
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
// import ProfileHeading from '../components/ProfileHeading.js';
// import LinearProgressBar from '../components/LinearProgressBar.jsx';
// import { Button } from 'react-bootstrap';

import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
export default function PGDashboardParticipant(props) {
    // console.log(props)
    const navigate = useNavigate();

    // Scroll to the top of the page whenever the location changes start
    const { user, users, logout } = useUserAuth();
    const [totalEvent, setTotalEvent] = useState(0);
    const [ownedEvent, setOwnedEvent] = useState([]);
    const [activeCount, setActiveCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [cancelledCount, setCancelledCount] = useState(0);
    const [onholdCount, setOnholdCount] = useState(0);
    const [unpaidCount, setUnpaidCount] = useState(0);
    const [eventList, setEventList] = useState([]);
    const [createrUnpaid, setCreaterUnpaid] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    const location = useLocation();

    const queryEvent = [
        where('CreatedBy', '==', user && user.userInfo ? user.userInfo.uid : ' '),

    ]

    const { documents: eventDocs, error: eventDocError } = useCollection('EventList', queryEvent)

    // console.log('user:', userDetails.SelectedRole.toUpperCase())

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    // Scroll to the top of the page whenever the location changes end

    useEffect(() => {
        // console.log('userDetails ', userDetails.UserRole)
        // console.log(userDetails.UserRole.findIndex(e => e.TYPE === 'ADMIN'))
        if (user && user.userInfo && user.userInfo.UserRole && (user.userInfo.UserRole.findIndex(e => e.TYPE === 'ADMIN') >= 0)) {
            setIsAdmin(true)
            console.log('ADMIN True')

        }
        getAllEvent()
    }, [])

    async function getAllEvent() {
        var para = {};
        let eventOwner = [];
        let phone = user && user.userInfo && user.userInfo.Phone ? user.userInfo.Phone.slice(-10) : ''
        let owners = []
        let ownerEventList = []
        let createrUnpaidCount = 0;
        //let createrUnpaid = [];
        const ret = await httpsCallable(functions, "getEventList");
        ret(para).then(async (result) => {
            // console.log(result);
            setTotalEvent(result.data.Events.length)
            setEventList(result.data.Events);
            let unpaindEvent = [];
            if (!isAdmin) {
                unpaindEvent = result.data.Events.filter(e => (e.PaymentStatus === 'Pending' && e.CreatedBy === user.userInfo.uid));
            }
            else {
                unpaindEvent = result.data.Events.filter(e => (e.PaymentStatus === 'Pending'));
            }

            setCreaterUnpaid(unpaindEvent);
            setUnpaidCount(unpaindEvent.length);

            ownerEventList = result.data.Events.filter(e => e.CreatedBy === user.userInfo.uid)
            // console.log('1 ownerEventList : ', ownerEventList)
            owners = result.data.Owners.filter(e => e.OwnerPhone.includes(phone))
            owners.forEach(element => {
                if (ownerEventList.findIndex(e1 => e1.EventID === element.EventID) < 0) {
                    ownerEventList.push(element);
                }
            });

            owners = result.data.Controller.filter(e => e.ControllerPhone.includes(phone))
            owners.forEach(element => {
                if (ownerEventList.findIndex(e1 => e1.EventID === element.EventID) < 0) {
                    ownerEventList.push(element);
                }
            });

            setOwnedEvent(ownerEventList);
            let event = result.data.Events.filter(e => e.EventStatus.toLowerCase() === 'active')
            setActiveCount(event.length)

            event = result.data.Events.filter(e => e.EventStatus.toLowerCase() === 'closed')
            setCompletedCount(event.length)

            event = result.data.Events.filter(e => e.EventStatus.toLowerCase() === 'cancelled')
            setCancelledCount(event.length)

            event = result.data.Events.filter(e => e.EventStatus.toLowerCase() === 'onhold')
            setOnholdCount(event.length)

        })
    }

    function createEvent() {
        navigate("/CreateEvent", {
            state: {
                createStage: 'package'
            }
        });
    }
    function payRegistration() {
        if (!isAdmin) {
            // console.log('createrUnpaid[0]', createrUnpaid[0])
            // navigate("/PGCreateEventPayment/" + createrUnpaid[0].Eventid)
            navigate('/PGCreateEventPayment', {
                state: {
                    EventID: createrUnpaid[0].Eventid
                }
            })
        }
    }

    function pendingPayments() {
        // navigate('/PGEventPendingPayment', {
        //     state: {
        //         eventList: createrUnpaid
        //     }
        // })
        navigate('/Event')
    }
    console.log('1 : ownedEvent', isAdmin, unpaidCount)
    return (
        <div>
            <br />
            <div className='container'>
                {/* <div style={{ padding: '0 10px' }}>
                    <ProfileHeading></ProfileHeading>
                </div> */}

                <div className='dashboard-four-card-div'>
                    <div className='dashboard-four-card-div-inner'>
                        <div className='dashboard-four-card'>
                            <h1>List Your Event</h1>
                        </div>
                    </div>
                    <div className='dashboard-four-card-div-inner'>
                        <div className='dashboard-four-card'>
                            <h1>See My Orders</h1>
                        </div>
                    </div>
                </div>
                <br />
                <div className='dashboard-four-card-div'>
                    <div className='dashboard-four-card-div-inner'>
                        <div className='dashboard-four-big-card' onClick={() => {
                            navigate('/Orders')
                        }}>
                            <div>
                                <span style={{ color: '#FB4570' }} className="material-symbols-outlined">
                                    shopping_cart
                                </span>

                                <h2>Cart</h2>
                            </div>
                            <h3>Order details for Active Events</h3>
                        </div>
                    </div>
                    <div className='dashboard-four-card-div-inner'>
                        <div className='dashboard-four-big-card' onClick={() => navigate("/OrderHistory")}>
                            <div>
                                <span style={{ color: '#7c45fb' }} className="material-symbols-outlined">
                                    overview_key
                                </span>
                                <h2>Order</h2>
                            </div>
                            <h3>Edit login, name, and mobile number</h3>
                        </div>
                    </div>
                    <div className='dashboard-four-card-div-inner'>
                        <div className='dashboard-four-big-card' onClick={() => navigate("/RegisteredUserProfile")}>
                            <div>
                                <span style={{ color: '#fbbb45' }} className="material-symbols-outlined">
                                    location_on
                                </span>
                                <h2>Members</h2>
                            </div>
                            <h3>List registered Member and add or modify</h3>
                        </div>
                    </div>
                    <div className='dashboard-four-card-div-inner'>
                        <div className='dashboard-four-big-card' onClick={() => navigate("/Profile")}>
                            <div>
                                <span style={{ color: '#986a15' }} className="material-symbols-outlined">
                                    local_post_office
                                </span>
                                <h2>Profile</h2>
                            </div>
                            <h3>View and edit profile</h3>
                        </div>
                    </div>
                    <div className='dashboard-four-card-div-inner'>
                        <div className='dashboard-four-big-card' onClick={(e) => {
                            navigate('/Notification')
                        }}>
                            <div>
                                <span style={{ color: '#9e2690' }} className="material-symbols-outlined">
                                    wallet
                                </span>
                                <h2>Notifications</h2>
                            </div>
                            <h3>Add money to your balance</h3>
                        </div>
                    </div>
                    <div className='dashboard-four-card-div-inner'>
                        <div className='dashboard-four-big-card'>
                            <div>
                                <span style={{ color: '#5c4a4c' }} className="material-symbols-outlined">
                                    support_agent
                                </span>
                                <h2>Wallet</h2>
                            </div>
                            <h3>Add money to your balance</h3>
                        </div>
                    </div>
                </div>

                {/* <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div style={{ padding: '10px' }}>
                            <div className='total-number-card' style={{ background: '#348DCB' }}>
                                <h1 style={{ color: '#fff' }}>Total Number of Events</h1>
                                <h2 style={{ color: '#fff' }}>{eventList && eventList.length}</h2>
                                <span style={{ color: '#fff' }} className="material-symbols-outlined">
                                    widgets
                                </span>
                            </div>
                        </div>
                    </div> */}

                {/* <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div style={{ padding: '10px' }}>
                            <div className='total-number-card'>
                                <h1>Owned / Created Events</h1>
                                <h2>
                                    {ownedEvent ? ownedEvent.length : 0}
                                </h2>

                                {unpaidCount > 0 && <>
                                    <h1>Unpaind Event</h1>
                                    <h2>{unpaidCount}</h2>
                                </>}
                                {(!isAdmin || unpaidCount === 0) && <div>
                                    <button className='mybutton button5' onClick={createEvent}>Create Event</button>
                                </div>
                                }
                                {isAdmin && <div>
                                    <button className='mybutton button5' style={{ top: '30px' }} onClick={createEvent}>Create Event</button>
                                </div>
                                }

                                {(!isAdmin && unpaidCount > 0) && <div>
                                    <button className='mybutton button5' onClick={payRegistration}>Pay for Event Activation</button>
                                </div>}

                                {(isAdmin && unpaidCount > 0) && <div>
                                    <button className='mybutton button5' onClick={pendingPayments}>Pending Events</button>
                                </div>
                                }
                            </div>
                        </div>
                    </div> */}
                <div style={{ padding: '10px' }}>
                    {/* <div className='total-number-card' style={{ background: '#ad600d', paddingLeft: '120px' }}>
                        <h2 style={{ color: '#fff' }}>List Your Event</h2>
                        <h1 style={{ color: '#fff' }}>Total Number of Events</h1>
                        <span style={{ left: '20px', color: '#fff' }} className="material-symbols-outlined">
                            list_alt
                        </span>
                    </div> */}
                    <Link to="/OrganizerDashboard" className='list-your-event-section'>
                        {/* <div className='list-your-event-section-content'> */}
                        <span className="material-symbols-outlined">
                            list_alt
                        </span>
                        <div className='list-your-event-section-content'>
                            <div>
                                <h1>Listed Events ({eventDocs && eventDocs.length})</h1>
                                {/* <div>
                                    <div className="bar">
                                        <LinearProgressBar total="55" current="20" />
                                    </div>
                                    <h3>208</h3>
                                </div> */}
                                <button className='mybutton button5'>Create Event</button>
                            </div>
                            <h2>Are you an organizer. Publish your event in just few clicks.</h2>
                        </div>

                        {/* </div> */}
                    </Link>
                    {(isAdmin && unpaidCount > 0) && <div>
                        <button className='mybutton button5' onClick={pendingPayments}>Pending Events</button>
                    </div>
                    }
                    {(!isAdmin && unpaidCount > 0) && <div>
                        event<button className='mybutton button5' onClick={pendingPayments}>Pending Events</button>
                    </div>
                    }
                </div>
            </div>


            <div className='container'>
                <div className='row no-gutters'>

                    <div className='col-6'>

                        <div className='coach-card'>
                            <img src='/img/sports.png' alt='sports'></img>
                            <h1>Do you own an academy / arena?</h1>
                            <h2>Add the details here</h2>
                        </div>
                    </div>


                    <div className='col-6'>
                        <div className='coach-card'>
                            <img src='/img/coach.png' alt='sports'></img>
                            <h1>Are you a coach or mentor?</h1>
                            <h2>Add your profile here</h2>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='coach-card'>
                            <img src='/img/umpire.png' alt='sports'></img>
                            <h1>Are you an umpire or a referee?</h1>
                            <h2>Add your profie here</h2>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='coach-card'>
                            <img src='/img/sports.png' alt='sports'></img>
                            <h1>Are you a Fitness Trainer?</h1>
                            <h2>Add the details here</h2>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='coach-card'>
                            <img src='/img/sports.png' alt='sports'></img>
                            <h1>Are you a Physiotherapist?</h1>
                            <h2>Add the details here</h2>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='coach-card'>
                            <img src='/img/sports.png' alt='sports'></img>
                            <h1>Are you a Dietician?</h1>
                            <h2>Add the details here</h2>
                        </div>
                    </div>
                </div>
            </div>

            {/* <hr /> */}
            {
                <div className='container'>

                    <div className='dashboard-cards'>
                        {/* <div style={{ padding: '10px' }}>
                            <div className='total-number-card'>
                                <h1>Active</h1>
                                <h2>{activeCount}</h2>
                                <span style={{ fontSize: '5rem' }} className="material-symbols-outlined">
                                    new_releases
                                </span>
                            </div>
                        </div> */}
                        {/* <div style={{ padding: '10px' }}>
                            <div className='total-number-card'>
                                <h1 style={{ color: 'green' }}>Completed</h1>
                                <h2 style={{ color: 'green' }}>{completedCount}</h2>
                                <span style={{ fontSize: '5rem', color: 'green' }} className="material-symbols-outlined">
                                    done_all
                                </span>
                            </div>
                        </div>
                        <div style={{ padding: '10px' }}>
                            <div className='total-number-card'>
                                <h1 style={{ color: 'red' }}>Cancelled</h1>
                                <h2 style={{ color: 'red' }}>{cancelledCount}</h2>
                                <span style={{ fontSize: '5rem', color: 'red' }} className="material-symbols-outlined">
                                    block
                                </span>
                            </div>
                        </div>
                        <div style={{ padding: '10px' }}>
                            <div className='total-number-card'>
                                <h1 style={{ color: 'orange' }}>On-hold</h1>
                                <h2 style={{ color: 'orange' }}>{onholdCount}</h2>
                                <span style={{ fontSize: '5rem', color: 'orange' }} className="material-symbols-outlined">
                                    back_hand
                                </span>
                            </div>
                        </div> */}
                    </div>

                </div>
            }
            {/* Participant Dashboard < br /> */}
            {/* <hr>
            </hr>
            <ManageParticipant EventList={props.EventList} registrationType={'Pending'} ></ManageParticipant> */}
            {/* <hr> */}
            {/* </hr>
            <ManageParticipant EventList={props.EventList} registrationType={'all'} ></ManageParticipant> */}

            {/* <button onClick={createEvent}>Create Event</button> */}
        </div >
    )
}
