import React, { useRef, useState, useEffect } from 'react'
// import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useDocument } from '../hooks/useDocument';
import { useCollection } from '../hooks/useCollection';
import { where, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
export default function EventDetailsMenu(props) {
    // const { eventID } = useParams();

    const [isDrawAvailable, setIsDrawAvailable] = useState(false)
    // console.log('eventID', props.eventID);
    const navigate = useNavigate();
    const queryConstraints = [
        where('EventID', '==', props && props.eventID ? props.eventID : ''),
        where('IsActive', '==', true)
        // Add more query constraints as needed
    ];
    // const queryConstraintsActive = [
    //     where('EventID', '==', props && props.eventID ? props.eventID : ''),
    //     // Add more query constraints as needed
    // ];
    const queryConstraintsRegistration = [
        where('EventID', '==', props && props.eventID ? props.eventID : ''),
        orderBy('ParticipantName')
        // Add more query constraints as needed
    ];

    // console.log('queryConstraints : ', queryConstraints)
    const { document: event, error: eventDocError } = useDocument('EventList', props.eventID)
    // const { documents: ownerDocs, error: ownerDocError } = useCollection('EventOwner', queryConstraints)
    // const { documents: controllerDocs, error: controllerDocError } = useCollection('EventController', queryConstraints)
    // const { documents: categoryDocs, error: categoryDocError } = useCollection('EventCategory', queryConstraints)
    const { documents: participantDocs, error: participantDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistration)
    const { documents: matchKnockoutDocs, error: matchKnockoutDocError } = useCollection('TX_MatchDetails', queryConstraints)
    const { documents: matchLeagueDocs, error: matchLeagueDocError } = useCollection('TX_MatchDetails', queryConstraints)
    const { documents: eventCategoryDocs, error: eventCategoryDocsError } = useCollection('EventCategory', queryConstraints)

    // console.log('participantDocs::', participantDocs, ':::participantDocError::', participantDocError)
    // console.log('flag : ', !(eventDetails.current.ShowParticipantFlag && eventDetails.current.ShowParticipantFlag === 'No'));
    // const { state } = useLocation();
    // const { calledFrom, eventID, eventDetails, entryCount, uniqueParticipantDetails, participantDetails, participantCount } = state;

    // console.log('from EventDetailsMenu : participantCount = ', participantCount, ' :: participantDetails : ', participantDetails,
    //     ':: uniqueParticipantDetails : ', uniqueParticipantDetails);
    // console.log('props : ', props);
    // let cnt = 1;
    const [playerList, setPlayerList] = useState([])
    const swiperRef = useRef(null);
    useEffect(() => {
        console.log('props.calledFrom', props.calledFrom)
        if (props.calledFrom === 'Details') {
            focusOnSlide(0)
        } else if (props.calledFrom === 'Entries') {
            focusOnSlide(1)
        } else if (props.calledFrom === 'Participant') {
            focusOnSlide(2)
        } else if (props.calledFrom === 'Category') {
            focusOnSlide(3)
        }
        // else if (props.calledFrom === 'Registration') {
        //     focusOnSlide(0)
        // } 
        else if (props.calledFrom === 'Schedule' || props.calledFrom === 'Draws') {
            focusOnSlide(4)
        } else if (props.calledFrom === 'Results') {
            focusOnSlide(5)
        }

        participantDocs && participantDocs.forEach(element => {
            if (!playerList.find(e => e.ParticipantID === element.ParticipantID)) {
                console.log('in if 11')
                playerList.push({
                    ParticipantID: element.ParticipantID,
                    ParticipantName: element.ParticipantName,
                })
            }
            if (element.PartnerPlayerID && element.PartnerPlayerID !== '' &&
                (!playerList.find(e => e.ParticipantID === element.PartnerPlayerID))) {
                console.log('in if 2')
                playerList.push({
                    ParticipantID: element.PartnerPlayerID,
                    ParticipantName: element.PartnerPlayerName,
                })
            }

        });
        setPlayerList(playerList);
        matchKnockoutDocs && matchKnockoutDocs.length > 0 && setIsDrawAvailable(true)
        matchLeagueDocs && matchLeagueDocs.length > 0 && setIsDrawAvailable(true)

    }, [participantDocs, matchKnockoutDocs, matchLeagueDocs, event]);

    // Function to slide to a specific index
    const focusOnSlide = (index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index); // Index of the slide (0-based)
        }
    };

    return (
        // <div className="event-details-menu-outter" style={{ zIndex: '999' }}>
        //     <div className="event-details-menu">

        //         <Link to={"/EventDetails/" + eventID} className={calledFrom.current === 'Details' ? 'active' : ''}>
        //             <span className="material-symbols-outlined">
        //                 info
        //             </span>
        //             <h1>Details</h1>
        //         </Link>
        //         {!(eventDetails.current.ShowParticipantFlag && eventDetails.current.ShowParticipantFlag === 'No') &&
        //             <Link to={"/EventEntries/" + eventID} className={calledFrom.current === 'Entries' ? 'active' : ''}>
        //                 <span className="material-symbols-outlined">
        //                     directions_run
        //                 </span>
        //                 <h1>Entries</h1>
        //                 <h2 className={calledFrom.current === 'Entries' ? 'active' : ''}>{entryCount.current}</h2>
        //             </Link>}
        //         {!(eventDetails.current.ShowParticipantFlag && eventDetails.current.ShowParticipantFlag === 'No') && <Link to="/EventParticipants"
        //             state={{
        //                 calledFrom: 'Participant',
        //                 eventID: eventID.current,
        //                 eventDetails: eventDetails.current,
        //                 entryCount: entryCount.current,
        //                 uniqueParticipantDetails: uniqueParticipantDetails.current,
        //                 participantDetails: participantDetails.current,
        //                 participantCount: participantCount.current
        //             }} className={calledFrom.current === 'Participant' ? 'active' : ''}>
        //             <span className="material-symbols-outlined">
        //                 groups
        //             </span>
        //             <h1>Participants</h1>
        //         </Link>}
        //         <div style={{ width: eventDetails.current.ShowParticipantFlag && eventDetails.current.ShowParticipantFlag === 'No' ? "100%" : "33.3%" }} className={calledFrom.current === 'Details' ? 'event-details-menu-indicator Details' : calledFrom.current === 'Entries' ? 'event-details-menu-indicator Entries' : calledFrom.current === 'Participant' ? 'event-details-menu-indicator Participant' : ''}></div>
        //     </div>

        // </div>

        <section className='sticky-menu'>
            <div className='container-fluid'>
                <Swiper
                    ref={swiperRef}
                    spaceBetween={15}
                    breakpoints={{
                        0: {
                            slidesPerView: 2.5,
                        },

                        576: {
                            slidesPerView: 3.5,
                        },
                        820: {
                            slidesPerView: 5.5,
                        },
                        1245: {
                            slidesPerView: 7.5,
                        },
                    }}
                    pagination={{ clickable: true }}
                    freeMode={true}
                    className='all_tenants'
                >
                    {true !== '' && <SwiperSlide key='Details'>
                        <div className={props.calledFrom === 'Details' ? 'event-data-div active' : 'event-data-div'} onClick={() => {
                            navigate('/EventDetails/' + props.eventID)
                        }}>
                            <span className="material-symbols-outlined">
                                page_info
                            </span>
                            <div>
                                <h1>Event</h1>
                                <h2>Details</h2>
                            </div>
                        </div>
                    </SwiperSlide>}

                    <SwiperSlide key='Entries'>
                        {/* Entries */}
                        <div className={props.calledFrom === 'Entries' ? 'event-data-div active' : 'event-data-div'} onClick={() => {
                            navigate("/EventEntries/" + props.eventID)
                        }}>
                            <span className="material-symbols-outlined">
                                passkey
                            </span>
                            <div>
                                <h1>Entries</h1>
                                <h2>{participantDocs && participantDocs.length}</h2>
                            </div>
                        </div>
                    </SwiperSlide>

                    {event && event.ShowParticipantFlag === 'YES' && <SwiperSlide key='Participant'>
                        <div className={props.calledFrom === 'Participant' ? 'event-data-div active' : 'event-data-div'} onClick={() => {
                            navigate("/EventParticipants/" + props.eventID + '/All')

                        }}>
                            <span className="material-symbols-outlined">
                                groups
                            </span>
                            <div>
                                <h1>Participants</h1>
                                <h2>{playerList && playerList.length}</h2>
                            </div>
                        </div>
                    </SwiperSlide>}

                    <SwiperSlide key='Category'>
                        {/* Entries */}
                        <div className={props.calledFrom === 'Category' ? 'event-data-div active' : 'event-data-div'} onClick={() => {
                            navigate("/EventCategory/" + props.eventID)
                        }}>
                            <span className="material-symbols-outlined">
                                passkey
                            </span>
                            <div>
                                <h1>Categories</h1>
                                <h2>{eventCategoryDocs && eventCategoryDocs.length}</h2>
                            </div>
                        </div>
                    </SwiperSlide>

                    {/* {props.eventDetails.RegistrationOpenFlag === 'ON' && <SwiperSlide key='Registration'>
                        <div className={props.calledFrom === 'Registration' ? 'event-data-div active' : 'event-data-div'} onClick={() => {
                            navigate('/RegisteredProfile/' + props.eventID)
                        }}>
                            <span className="material-symbols-outlined">
                                how_to_reg
                            </span>
                            <div>
                                <h1>Event</h1>
                                <h2>Registration</h2>
                            </div>
                        </div>
                    </SwiperSlide>} */}

                    {props.IsEditEnabled && <SwiperSlide key='Payments'>

                        <div className={props.calledFrom === 'Payments' ? 'event-data-div active' : 'event-data-div'}>
                            <span className="material-symbols-outlined">
                                payments
                            </span>
                            <div>
                                <h1>Payments</h1>
                                <h2>{12345}</h2>
                            </div>
                        </div>
                    </SwiperSlide>}

                    {/* {props.isDrawAvailable && props.eventDetails.PublishDrawFlag === 'ON' && <SwiperSlide SwiperSlide key={4}> */}
                    {event && event.PublishDrawFlag && event.PublishDrawFlag.toUpperCase() === 'ON' &&
                        <SwiperSlide SwiperSlide key='Draws'>
                            <div className={props.calledFrom === 'Draws' ? 'event-data-div active' : 'event-data-div'}
                                onClick={() => {
                                    navigate("/ShowDraw/" + props.eventID + "/true")
                                }}>
                                <span className="material-symbols-outlined">
                                    polyline
                                </span>
                                <div>
                                    <h1>Draws</h1>
                                    <h2>Published</h2>
                                </div>
                            </div>
                        </SwiperSlide>}

                    {/* {props.isDrawAvailable && props.eventDetails.PublishDrawFlag === 'ON' && <SwiperSlide key={5}> */}
                    {isDrawAvailable && <SwiperSlide key='Schedule'>
                        <div className={props.calledFrom === 'Schedule' ? 'event-data-div active' : 'event-data-div'}>
                            <span className="material-symbols-outlined">
                                alarm
                            </span>
                            <div>
                                <h1>Schedule</h1>
                                <h2>8711</h2>
                            </div>
                        </div>
                    </SwiperSlide>}

                    {/* {props.isDrawAvailable && props.eventDetails.PublishDrawFlag === 'ON' && <SwiperSlide key={6}> */}
                    {isDrawAvailable && <SwiperSlide key='Results'>
                        <div className={props.calledFrom === 'Results' ? 'event-data-div active' : 'event-data-div'}>
                            <span className="material-symbols-outlined">
                                contract
                            </span>
                            <div>
                                <h1>Results</h1>
                                <h2>1,78,096</h2>
                            </div>
                        </div>
                    </SwiperSlide>}
                    {/* {props.eventDetails && props.eventDetails.LocationMap && props.eventDetails.LocationMap !== '' && <SwiperSlide key={7}> */}

                </Swiper>
            </div>

            <h1 className='small' style={{ textAlign: 'center', fontSize: '1.1rem', padding: '0 10px' }}>
                {event && event.EventName}
            </h1>

        </section >


    )
}

