import React, { useEffect, useState, useRef } from 'react'
import '../css/EventRegistration.css'
import { useLocation, useParams } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
import RenderCategoryForRegistration from './RenderCategoryForRegistration';
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import { useUserAuth } from '../context/UserAuthcontext';
import { useNavigate } from 'react-router-dom';
// import EDTournamentDetails from '../components/EDTournamentDetails'
import EventDetailsMenu from './EventDetailsMenu.js'
import PaymentSection from './PaymentSection.js';
import EDTournamentDetails from './EDTournamentDetails.js'
import Loading from './Loading';
import { useCollection } from '../hooks/useCollection.js';
import { useDocument } from '../hooks/useDocument.js';
import { where } from 'firebase/firestore';
export default function RegistrationCategory() {

    const location = useLocation();
    const { users } = useUserAuth();
    const { eventID, playerID } = useParams()
    // const { state } = useLocation();
    // const { id, participantDetails } = state;
    console.log('eventID, PlayerID:', eventID, playerID)
    // const rTotalEvent = useRef(0);
    // const rTotalPayment = useRef(0);
    const paymentObj = useRef();
    const countObj = useRef();
    const [totalEvent, setTotalEvent] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    // const rSelectedCategory = useRef([]);
    const [categoryList, setCategoryList] = useState();
    // const [eventDetails, setEventDetails] = useState();
    // const [allCategoryList, setAllCategoryList] = useState([]);
    // const eventDetails = useLocalStorage('EventDetails', null);
    // const [eventID, setEventID] = useLocalStorage('EventID', null);
    const [registeredEvents, setRegisteredEvents] = useState([]);
    // const [alreadyRegisteredEvents, setAlreadyRegisteredEvents] = useState([]);
    // const [participantDetails, setParticipantDetails] = useState([]);
    const [showError, setShowError] = useState(false);

    const [showTotal, setShowTotal] = useState(false);
    // const [selectedCategory, setSelectedCategory] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [deletedEvent, setDeletedEvent] = useState([]);
    const navigate = useNavigate();
    // const [indexEvent, setIndexEvent] = useState(0);
    const [loading, setLoading] = useState(true);
    const [savingFlag, setSavingFlag] = useState(false);
    const [participantList, stParticipantList] = useState(null)

    const [paymentSection, setPaymentSection] = useState({
        flag: false,
        pendingCategory: []
    });

    const [isPayClicked, setIsPayClicked] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    const queryConstraintsEvent = [
        where('EventID', '==', eventID),
        // orderBy('UserName')
        // Add more query constraints as needed
    ];

    const queryConstraintsPlayer = [
        where('PlayerID', '==', playerID),
        // orderBy('UserName')
        // Add more query constraints as needed
    ];

    const { document: eventDetails, error: eventDetailsError } = useDocument('EventList', eventID)
    const { documents: playerList, error: playerDocError } = useCollection('Participants', queryConstraintsPlayer)
    const { documents: allCategoryList, error: allCategoryListError } = useCollection('EventCategory', queryConstraintsEvent)
    // console.log(' 2 a playerList.PlayerID', playerList && playerList[0], allCategoryList)
    const queryConstraintsRegistrationPlayer = [
        where('EventID', '==', eventID),
        where('ParticipantID', '==', playerID)
        // orderBy('UserName')
        // Add more query constraints as needed
    ];

    const queryConstraintsRegistrationPartner = [
        where('EventID', '==', eventID),
        where('PartnerPlayerID', '==', playerID)
        // orderBy('UserName')
        // Add more query constraints as needed
    ];
    const { documents: playerPartcipationDoc, error: palyerPartcipationDocError, loading: playerLoading } = useCollection('EventRegistrationDetails', queryConstraintsRegistrationPlayer)
    const { documents: partnerPartcipationDoc, error: partnerPartcipationDocError, loading: partnerLoading } = useCollection('EventRegistrationDetails', queryConstraintsRegistrationPartner)

    console.log('15. palyerPartcipationDoc', playerPartcipationDoc, 'partnerPartcipationDoc', partnerPartcipationDoc)
    useEffect(() => {
        let totalFees = 0;
        let totalCategory = 0;
        setLoading(true);
        // getAllCategoryList();
        // setEventDetails(eventDoc)
        // setAllCategoryList(eventCategoryDoc);
        checkCategory();
        // console.log('checkCategory() ', checkCategory());
        // eventDoc && setCategoryList(checkCategory());
        // getRegisteredEvents();
        console.log('13 : checkCategory() ', categoryList);
        let _paymentSection = {
            flag: false,
            pendingCategory: []
        };
        let participantDetailsObj = [];
        // let pendingCnt = 0
        // let pendingPrize = 0
        // console.log('AllCategoryList', eventCategoryDoc)
        // console.log('CategoryList', categoryList)

        playerPartcipationDoc && playerPartcipationDoc.forEach(element => {
            console.log('palyerPartcipationDoc', element)

            let setCategory = categoryList && categoryList.find(e => e.CategoryName === element.CategoryName);
            participantDetailsObj.push({
                ...element,
                Fees: setCategory ? setCategory.Fees : element.Fees,
                RegType: 'Self'
            })
            console.log('3. element.PaymentStatus 1', element, element.PaymentStatus, totalFees, Number(element.Fees))

            if (element.PaymentStatus === 'Pending') {
                totalFees = totalFees + Number(setCategory ? setCategory.Fees : element.Fees)
                totalCategory = totalCategory + 1
                _paymentSection.pendingCategory.push(element)
                _paymentSection.flag = true;
            }
            console.log('11. element.PaymentStatus', element.PaymentStatus, totalFees, Number(element.Fees))

        });
        console.log('_paymentSection', _paymentSection)
        setPaymentSection(_paymentSection);

        !partnerPartcipationDocError && partnerPartcipationDoc && partnerPartcipationDoc.forEach(async element1 => {
            console.log('apartnerPartcipationDoc', element1)
            participantDetailsObj.push({
                ...element1,
                RegType: 'Partner'
            })
        });
        setTotalEvent(totalCategory);
        setTotalPayment(totalFees);

        console.log('12 participantDetailsObj', participantDetailsObj)
        // setParticipantDetails(registrationDoc)
        setRegisteredEvents(participantDetailsObj ? participantDetailsObj : [])
        // setAlreadyRegisteredEvents(participantDetailsObj ? participantDetailsObj : [])
        if (totalFees > 0) {
            setShowTotal(true)
        }
        setLoading(false);
    }, [allCategoryList, playerList, playerPartcipationDoc, partnerPartcipationDoc]);


    function saveCategory() {
        console.log('in saveCategory')
        // e.preventDefault();
        var regCategory = [];
        setSavingFlag(true);
        let pendingFlag = false;
        console.log(allCategoryList);
        registeredEvents.forEach(element => {
            var partName = '';
            var partnerUID = '';
            var partnerPlayerID = '';
            // console.log(element);
            var pindex = partnerList.find(e => e.categoryName === element.CategoryName);
            if (pindex) {
                partName = pindex.partnerName;
                partnerUID = pindex.partnerUserID;
                partnerPlayerID = pindex.partnerID;
            }
            // console.log(element.CategoryName);
            if (element.RegType === undefined || element.RegType === 'Self') {
                // console.log(element.CategoryName);
                var selCat = {
                    RegistrationID: element.id,
                    CategoryName: element.CategoryName,
                    EventType: element.EventType,
                    Fees: element.Fees,
                    Gender: element.Gender,
                    MaxTeamSize: element.MaxTeamSize,
                    PartnerPlayerID: partnerPlayerID,
                    PartnerPlayerName: partName,
                    PartnerUserID: partnerUID,
                    PaymentStatus: element.PaymentStatus ? element.PaymentStatus : 'Pending'
                }
                if (element.PaymentStatus === undefined || element.PaymentStatus === 'Pending') {
                    pendingFlag = true;
                }
                regCategory.push(selCat);
                console.log('registeredEvents', registeredEvents);
            }

        });
        var catArrayDel = [];
        console.log('alreadyRegisteredEvents & registeredEvents', registeredEvents)
        playerPartcipationDoc.forEach(element => {
            let eventindex = registeredEvents.findIndex(e => e.CategoryName === element.CategoryName);
            console.log('eventindex', eventindex, element.CategoryName)
            if (eventindex < 0) {
                catArrayDel.push(element.CategoryName);
            }
        });

        setDeletedEvent(catArrayDel);


        console.log('participantDetails', playerList);
        var para1 = {};
        para1 = {
            EventID: eventDetails.id,// eventID,
            ParticipantID: playerList && playerList[0] ? playerList[0].PlayerID : '',
            PlayerID: playerList && playerList[0] ? playerList[0].id : '',
            ParticipantName: playerList && playerList[0] ? playerList[0].UserName : '',
            CategoryList: regCategory,//selectedCategory,
            DeleteCategoryList: catArrayDel,
        };
        console.log('para1', para1);
        // const ret1 = httpsCallable(functions, "registerAllEvent");
        if (regCategory.length > 0) {

            // ret1(para1).then((result) => {
            // window.localStorage.setItem('SelectedCategory', JSON.stringify(regCategory));
            setSavingFlag(false);
            if (pendingFlag) {
                // navigate("/RegistrationCheckout/" + eventID + "/" + playerID)
                if (playerList && playerList[0])
                    navigate("/PlayerParticipation/" + eventID + "/" + playerList[0].PlayerID)
            } else {
                setSavingFlag(false);
                navigate("/PaymentSuccessful", {
                    state: {
                        id: 1, participantDetails: playerList && playerList[0] ? playerList[0] : null,
                        paymentObj: null,
                        paymentStatus: 'Completed',
                        selectedCategory: null,
                        updatePayment: false
                    }
                });
            }
            // })
        } else {
            setSavingFlag(false);
            navigate("/PaymentSuccessful", {
                state: {
                    id: 1, participantDetails: playerList && playerList[0] ? playerList[0] : null,
                    paymentObj: null,
                    paymentStatus: 'Completed',
                    selectedCategory: null,
                    updatePayment: false
                }
            });

        }

    }
    // function getAllCategoryList() {
    //     // allCategoryList
    //     var para1 = {};
    //     para1 = {
    //         EventID: eventDetails[0].Eventid,// eventID,
    //     };
    //     console.log(para1);
    //     const ret1 = httpsCallable(functions, "getallEventCategory");
    //     ret1(para1).then((result) => {
    //         console.log(result)
    //         setAllCategoryList(result.data.categoryList);
    //         setCategoryList(checkCategory());
    //         getRegisteredEvents();
    //         paymentObj.current.innerHTML = '0';
    //         // console.log('hi')
    //         console.log('hi')
    //         console.log('hi')
    //         console.log('hi')
    //         console.log('hi')
    //         console.log('hi')
    //     })

    // }

    function partnerSetup(categoryName, partnerID, partnerName, partnerUserID) {
        let eventindex = partnerList.find(e => e.CategoryName === categoryName);
        var catArray = partnerList;
        catArray = catArray.splice(eventindex, 1)
        var parnerDet = {
            categoryName: categoryName,
            partnerID: partnerID,
            partnerName: partnerName,
            partnerUserID: partnerUserID
        }

        catArray.push(parnerDet);
        setPartnerList(catArray);
    }
    //const handleClick = useCallback(() => {
    var calculateFees = function (isAdd, amount, eventD, partnerName, partnerID) {
        setShowError(false);
        let flag = false;
        // let lTotalCategory = 0;
        // let lTotalFee = 0;

        // playerPartcipationDoc
        //      partnerPartcipationDoc


        // let catArray = registeredEvents ? registeredEvents : [];
        // let catArrayPending = paymentSection.pendingCategory;
        // let _paymentSection = paymentSection;
        // console.log('catArray', catArray, paymentSection);
        // console.log('in calculateFees isAdd : ', isAdd, ' amount : ', amount, ' evntid : ', eventD,
        //     'rTotalEvent.current : ', totalEvent, 'eventDoc.MaxEntryForParticipant', eventDetails);
        // if (isAdd === 'ADD') {
        //     if (!eventDetails.MaxEntryForParticipant || (Number(eventDetails.MaxEntryForParticipant ? eventDetails.MaxEntryForParticipant : '0')) > totalEvent) {
        //         // catArray = allCategoryList;
        //         catArray.push(eventD);
        //         catArrayPending.push(eventD);
        //         lTotalCategory = lTotalCategory + 1;
        //         lTotalFee = lTotalFee + Number(amount);
        //         // setTotalEvent(totalEvent + 1);
        //         // setTotalPayment(totalPayment + Number(amount));
        //         _paymentSection.flag = true;
        //         _paymentSection.pendingCategory = catArrayPending;
        //     } else {
        //         flag = true;

        //     }

        // } else {
        //     let eventindex = catArray.findIndex(e => e.CategoryName === eventD.CategoryName);
        //     // catArray = allCategoryList;
        //     console.log('eventindex', eventindex)
        //     if (eventindex >= 0)
        //         catArray.splice(eventindex, 1)
        //     // setTotalEvent(totalEvent - 1);
        //     // setTotalPayment(totalPayment - Number(amount));
        //     console.log('catArray', catArray)

        //     let eventindexPending = catArrayPending.findIndex(e => e.CategoryName === eventD.CategoryName);
        //     // catArray = allCategoryList;
        //     console.log('eventindex', eventindex)
        //     if (eventindexPending >= 0)
        //         catArrayPending.splice(eventindexPending, 1)

        //     lTotalCategory = lTotalCategory - 1;
        //     lTotalFee = lTotalFee - Number(amount);
        //     if (lTotalCategory === 0) {
        //         _paymentSection.flag = false;

        //     }

        // }
        // setTotalEvent(lTotalCategory);
        // setTotalPayment(lTotalFee);

        // console.log('totalEvent :', totalEvent, ":: totalPayment : ", totalPayment)
        // if (!showError) {
        //     // setAllCategoryList(catArray);
        //     // paymentObj.current.innerHTML = totalPayment;
        //     // countObj.current.innerHTML = totalEvent;
        //     lTotalFee === 0 ? setShowTotal(false) : setShowTotal(true);
        // }
        // _paymentSection.pendingCategory = catArrayPending;
        // setPaymentSection(_paymentSection);

        // setRegisteredEvents(catArray)
        // setShowError(flag);
        return true;
    }

    var calculateFees1 = function (isAdd, amount, eventD, partnerName, partnerID) {
        setShowError(false);
        let flag = false;
        let lTotalCategory = totalEvent;
        let lTotalFee = totalPayment;
        let catArray = registeredEvents ? registeredEvents : [];
        let catArrayPending = paymentSection.pendingCategory;
        let _paymentSection = paymentSection;
        console.log('catArray', catArray, paymentSection);
        console.log('in calculateFees isAdd : ', isAdd, ' amount : ', amount, ' evntid : ', eventD,
            'rTotalEvent.current : ', totalEvent, 'eventDoc.MaxEntryForParticipant', eventDetails);
        if (isAdd === 'ADD') {
            if (!eventDetails.MaxEntryForParticipant || (Number(eventDetails.MaxEntryForParticipant ? eventDetails.MaxEntryForParticipant : '0')) > totalEvent) {
                // catArray = allCategoryList;
                catArray.push(eventD);
                catArrayPending.push(eventD);
                lTotalCategory = lTotalCategory + 1;
                lTotalFee = lTotalFee + Number(amount);
                // setTotalEvent(totalEvent + 1);
                // setTotalPayment(totalPayment + Number(amount));
                _paymentSection.flag = true;
                _paymentSection.pendingCategory = catArrayPending;
            } else {
                flag = true;

            }

        } else {
            let eventindex = catArray.findIndex(e => e.CategoryName === eventD.CategoryName);
            // catArray = allCategoryList;
            console.log('eventindex', eventindex)
            if (eventindex >= 0)
                catArray.splice(eventindex, 1)
            // setTotalEvent(totalEvent - 1);
            // setTotalPayment(totalPayment - Number(amount));
            console.log('catArray', catArray)

            let eventindexPending = catArrayPending.findIndex(e => e.CategoryName === eventD.CategoryName);
            // catArray = allCategoryList;
            console.log('eventindex', eventindex)
            if (eventindexPending >= 0)
                catArrayPending.splice(eventindexPending, 1)

            lTotalCategory = lTotalCategory - 1;
            lTotalFee = lTotalFee - Number(amount);
            if (lTotalCategory === 0) {
                _paymentSection.flag = false;

            }

        }
        setTotalEvent(lTotalCategory);
        setTotalPayment(lTotalFee);

        console.log('totalEvent :', totalEvent, ":: totalPayment : ", totalPayment)
        if (!showError) {
            // setAllCategoryList(catArray);
            // paymentObj.current.innerHTML = totalPayment;
            // countObj.current.innerHTML = totalEvent;
            lTotalFee === 0 ? setShowTotal(false) : setShowTotal(true);
        }
        _paymentSection.pendingCategory = catArrayPending;
        setPaymentSection(_paymentSection);

        setRegisteredEvents(catArray)
        setShowError(flag);
        return flag;
    }
    // async function getRegisteredEvents() {
    //     var para1 = {};
    //     // console.log('ventDetails', eventDetails)
    //     para1 = {
    //         PlayerID: participantDetails.PlayerID,
    //         EventID: eventID,
    //     };
    //     // console.log('getRegisteredEvents para1', para1)
    //     const ret1 = httpsCallable(functions, "getAllRegisteredEventListByPlayerCode");
    //     var tcnt = 0;
    //     var tfees = 0;
    //     ret1(para1).then(async (result) => {
    //         console.log('registeredEvents : ', result.data);
    //         setRegisteredEvents(result.data.entryDetails);
    //         // setSelectedCategory(result.data);
    //         // rSelectedCategory.current = result.data.entryDetails;
    //         let catArrayPartner = [];

    //         result.data.entryDetails.forEach((res) => {
    //             // console.log(res);
    //             tcnt = tcnt + 1;
    //             tfees = tfees + Number(res.Fees);

    //             setShowTotal(true);
    //             var parnerDet = {};
    //             if (res.EventType.toUpperCase() === 'DOUBLE') {
    //                 parnerDet = {
    //                     categoryName: res.CategoryName,
    //                     partnerID: res.PartnerPlayerID,
    //                     partnerName: res.PartnerPlayerName,
    //                     partnerUserID: res.PartnerUserID
    //                 }
    //             }

    //             catArrayPartner.push(parnerDet);

    //         });
    //         setPartnerList(catArrayPartner);
    //         rTotalEvent.current = tcnt;
    //         rTotalPayment.current = tfees;
    //         setLoading(false);
    //         // setTotalObj({
    //         //     totalEvent: tcnt,
    //         //     totalPayment: tfees
    //         // });

    //     });

    // }
    function checkCategory() {
        // console.log('in checkCategory');
        let flagGender = false;
        let flagDate = false;
        let selCategory = [];

        console.log('in console checkCategory : ', eventDetails, allCategoryList);
        // getCategoryList()
        if (playerList && playerList[0]) {
            if (allCategoryList !== undefined && allCategoryList !== null) {
                allCategoryList.forEach(element => {
                    flagGender = false;
                    flagDate = false;
                    let dob = playerList[0].DateOfBirth && playerList[0].DateOfBirth.seconds ?
                        new Date(playerList[0].DateOfBirth.seconds * 1000) : new Date();
                    //                  console.log('category name ', element.CategoryName, 'dob', dob);
                    flagGender = (element.Gender.toUpperCase() === playerList[0].Gender.toUpperCase() ? true :
                        element.Gender.toUpperCase() === 'MIXED' ? true : false);
                    //                    console.log('category name ', element.CategoryName, ' flagGender ', flagGender);
                    if (flagGender === true) {
                        var refDate = new Date(element.ReferenceDate.seconds * 1000);
                        //                    console.log('category name ', element.CategoryName, 'refDate', refDate, ' element.DateRefType ', element.DateRefType, ' dob ', dob);
                        if (element.DateRefType === 'Before' && dob <= refDate) {
                            flagDate = true;
                        } else if (element.DateRefType === 'After' && dob >= refDate) {
                            flagDate = true;
                        }
                        if (flagDate === true) {
                            //                      console.log("Render Category", element.CategoryName);
                            selCategory.push(element);
                        }
                    }

                });
                // return selCategory;
                //        console.log('selCategory', selCategory)
                setCategoryList(selCategory)

            }
        }
        // console.log(selCategory)

    }

    function goBack() {
        navigate(-1);
    }
    // console.log('rSelectedCategory.current : ', rSelectedCategory.current)
    var indexCategory = 1;
    return (
        <>
            {eventDetails && <EventDetailsMenu calledFrom='Registration'
                eventID={eventID}
                eventDetails={eventDetails}
            />}
            {paymentSection.flag && <PaymentSection Category={paymentSection.pendingCategory}
                ConvenienceCharge={eventDetails && eventDetails.ConvenienceCharge}
                PlayerID={playerID}
                EventCode={eventDetails && eventDetails.EventCode} setIsPayClicked={setIsPayClicked}
                ShowCheckOut={true}
                saveCategory={saveCategory}
            ></PaymentSection>
            }

            {/* <div className='category-checkout' id="checkOutDiv" style={{ opacity: (!showTotal) ? '0' : '1', pointerEvents: (!showTotal) ? 'none' : 'all' }} >

                <div className="category-checkout-first-details">
                    <div className="first-details-total">

                        <div>
                            <h3>TOTAL</h3>

                        </div>
                        <div>
                            <h1><span>₹ </span> <span id="totalPrice" ref={paymentObj}>{totalPayment}</span></h1>
                            <h2><span id="noOfCategories" ref={countObj}>{totalEvent}</span> <span> CATEGORIES</span></h2>

                        </div>
                    </div>

                    <div className="category-checkout-button-div">
                        <button onClick={saveCategory} className="mybutton button5" style={{ width: '120px', height: '35px', background: '#333C5D' }}>
                            <div style={{ display: !savingFlag ? 'block' : 'none' }}>
                                <span
                                    style={{ position: 'relative', fontSize: '0.9rem', color: '#fff' }}>CHECKOUT</span>
                            </div>
                            <div className='btn-loading' style={{ display: savingFlag ? 'block' : 'none' }}>
                                <lottie-player
                                    src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                    speed="0.7" loop autoplay></lottie-player>
                            </div>
                        </button>
                    </div>

                </div>

            </div> */}
            <br />
            <div className="container-fluid">
                <div className="row no-gutters">

                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <br />
                        <div id="regProfileNewParticipantDetails">
                            <h3 style={{ fontWeight: '1000', color: '#348DCB', textAlign: 'center' }}>SELECT CATEGORY</h3>
                            <h1 className="reg-form-email" id="playerName">{playerList && playerList[0] && playerList[0].UserName} ({playerList && playerList[0] && playerList[0].PlayerID})</h1>
                            {playerList && playerList[0] && playerList[0].Gender === 'Female' ? <h5 className="reg-form-email female" id="playerGender">FEMALE</h5> : <h5 className="reg-form-email male" id="playerGender">MALE</h5>}
                            {/* <input type="hidden" id="hfPlayerDocID" />
                        <input type="hidden" id="hfPlayerID" />
                        <input type="hidden" id="hfGender" /> */}
                            <br />
                        </div>
                        {showError && <div className="row no-gutters" >
                            <h1 style={{ fontSize: '1.2rem', color: 'red', fontWeight: '500' }}>Maximum Category Allowed : {eventDetails.MaxEntryForParticipant}</h1>
                        </div>}
                        <div className='registration-catergory-loding' >
                            <div className='registration-catergory-loding-inner' style={{ opacity: loading ? '1' : '0', pointerEvents: loading ? 'all' : 'none' }}>
                                {/* <div className='registration-catergory-loding-inner' style={{ opacity: '0', pointerEvents: 'none' }}> */}
                                {/* <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json" background="transparent" speed="1" loop autoplay></lottie-player> */}
                                <Loading ></Loading>

                            </div>

                            <div className="row no-gutters" id="categoryDiv">
                                {!loading && categoryList && categoryList.length === 0 &&

                                    <div id="regProfileNewParticipantDetails">
                                        <h1 className="reg-form-email" style={{ color: 'red' }} >Member is not eligible for registration based on Date of birth</h1>

                                    </div>}
                                {!loading && categoryList && categoryList.map((category) => {
                                    console.log('registeredEvents', registeredEvents)
                                    let regEvent = registeredEvents && registeredEvents.find(e => e.CategoryName === category.CategoryName);
                                    // console.log(events.CategoryName, events, eventID, indexCategory
                                    //     , playerDoc.Gender)
                                    console.log('1 regEvent', regEvent, ' rTotalPayment.current', totalPayment)
                                    // if (regEvent) {
                                    //     // setShowTotal(true)
                                    //     sotalPayment.current = rTotalPayment.current + Number(category.Fees)
                                    // }
                                    return <RenderCategoryForRegistration key={category.CategoryName}
                                        eventsCategory={category}
                                        eventID={eventID}
                                        keyValue={indexCategory++}
                                        calculateFees={calculateFees}
                                        partnerSetup={partnerSetup}
                                        playerDetails={playerList[0]}
                                        registeredEventStatus={regEvent === undefined ? null : regEvent}
                                        playerGender={playerList && playerList[0] ? playerList[0].Gender : ''} >

                                    </RenderCategoryForRegistration>
                                })
                                }


                            </div><br />
                            {/* <hr style={{ border: 'none', borderTop: '1px solid #aaa' }} />
                            <div style={{ textAlign: 'center' }}>
                                <button onClick={goBack} className="mybutton button5"
                                    style={{ fontWeight: 'bold', textAlign: 'center' }}> &lt;&lt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back </button>
                            </div> */}
                        </div>
                        <hr style={{ border: 'none', borderTop: '1px solid #aaa' }} />

                        <br />


                    </div>
                    {eventDetails && <EDTournamentDetails eventDetails={eventDetails}
                        showRegistration={false}
                        showRegistrationButton={false}
                    />}

                </div>
            </div >
        </>
    )
}
