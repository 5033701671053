import React, { useEffect, useState } from 'react';
import '../css/NewEventCard.css'
import { Link, useNavigate } from 'react-router-dom';

// import { httpsCallable } from "firebase/functions";
// import { functions } from '../firebase.js'
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";
import useDocumentUpdate from '../hooks/useDocumentUpdate';

export default function NewEventCard(props) {
    console.log('props', props)
    const navigate = useNavigate();
    const { users, user, logout } = useUserAuth();
    const [url, setURL] = useState('');
    const [eventStatus, setEventStatus] = useState('');
    const [registrationStatus, setRegistrationStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [eventCode, setEventCode] = useState('BD');
    const [eventStatusDes, setEventStatusDes] = useState('');
    const [isLive, setIsLive] = useState(false);

    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();

    useEffect(() => {

        setEventStatus(props.event.EventStatus);
        setRegistrationStatus(props.event.RegistrationOpenFlag ? props.event.RegistrationOpenFlag.toUpperCase() : '');
        setPaymentStatus(props.event.OnlinePaymentModeFlag ? props.event.OnlinePaymentModeFlag.toUpperCase() : '');
        var today = new Date();

        let refdate = props.event.EventStartDate && props.event.EventStartDate._seconds ? new Date(props.event.EventStartDate._seconds * 1000) :
            props.event.EventStartDate && props.event.EventStartDate.seconds ? new Date(props.event.EventStartDate.seconds * 1000) :
                props.event.EventStartDate && props.event.EventStartDate ? new Date(props.event.EventStartDate) : new Date();
        var eDate = props.event.EventEndDate._seconds ? new Date(props.event.EventEndDate._seconds * 1000 + 60 * 60 * 24 * 1000) :
            props.event.EventEndDate.seconds ? new Date(props.event.EventEndDate.seconds * 1000 + 60 * 60 * 24 * 1000) : new Date();

        if (refdate <= today && eDate >= today && props.event.EventStatus.toUpperCase() === 'ACTIVE') {
            setIsLive(true);
        } else {
            setIsLive(false);
        }

        // else
        let imgURL = ''
        // setEventStatusDes('Registration is open')
        switch (props.event.SportName.toUpperCase()) {
            case 'BADMINTON':
                setEventCode('BD')
                imgURL = '/img/game/badminton.webp'
                break;
            case 'CARROM':
                setEventCode('CR')
                imgURL = '/img/game/carrom.webp'
                break;
            case 'CHESS':
                setEventCode('CH')
                imgURL = '/img/game/chess.webp'
                break;
            case 'SQUASH':
                setEventCode('SQ')
                imgURL = '/img/game/squash.webp'
                break;
            case 'TABLE TENNIS':
                setEventCode('TT')
                imgURL = '/img/game/tabletennis.webp'
                break;
            case 'TENNIS':
                setEventCode('TN')
                imgURL = '/img/game/tennis.webp'
                break;
            case 'CRICKET':
                setEventCode('CK')
                imgURL = '/img/game/CK.png'
                break;
            default:
                setEventCode('BD')
                imgURL = '/img/game/badminton.webp'
        };
        setURL(imgURL);
        if (props.event.EventStatus !== 'Active') {
            setEventStatusDes('Event is ' + props.event.EventStatus)
        } else if (props.event.RegistrationOpenFlag === 'ON') {
            let datecheck = new Date(props.event.EventStartDate.seconds * 1000 - (4 * 24 * 60 * 60 * 1000))
            if (datecheck < new Date()) {
                setEventStatusDes('Registration is closing soon')
            }
            else if (props.event.PublishDrawFlag && props.event.PublishDrawFlag.toLowerCase() === 'on') {
                setEventStatusDes('Draw is published')
            } else
                setEventStatusDes('Event is Active')

        } else {
            setEventStatusDes('Event is Active')
        }


        // if (props.event.EventStatus && props.event.EventStatus.toUpperCase() === 'CLOSED')
        //     setEventStatusDes('Event is Closed')
        // else if (props.event.EventStatus && props.event.EventStatus.toUpperCase() === 'CANCELLED')
        //     setEventStatusDes('Event is Cancelled')
        // else if (props.event.EventStatus && props.event.EventStatus.toUpperCase() === 'INACTIVE')
        //     setEventStatusDes('Event is in-active')
        // else if (props.event.EventStatus && props.event.EventStatus.toUpperCase() === 'ONHOLD')
        //     setEventStatusDes('Event is Onhold')
        // else if (props.event.EventMode && props.event.EventMode.toUpperCase() === 'FIXER')
        //     setEventStatusDes('Draw create')
        // else if (props.event.RegistrationOpenFlag && props.event.RegistrationOpenFlag.toUpperCase() === 'ON')
        //     setEventStatusDes('Registration is open')

        if (!imgURL || imgURL === '')
            setURL('/img/game/badminton.webp');
        // console.log(props.event.EventName, url)

    }, [])
    function selectEvent() {
        // var para1 = {};
        // window.localStorage.setItem("EventID", JSON.stringify(props.event.Eventid));
        // window.localStorage.setItem("EventDetails", JSON.stringify(props.event));
        if (user && user.userInfo && user.userInfo.SelectedRole && user.userInfo.SelectedRole.toUpperCase() !== 'ADMIN') {
            // navigate("/EditEvent", {
            //     state: {
            //         eventID: props.event.Eventid,

            //     }
            // });
            console.log('before EventDetails ')

            navigate("/EventDetails/" + (props.event.id ? props.event.id : props.event.Eventid));

        }
        else {

            // navigate("/EventDetails/" + props.event.Eventid);
            console.log('before EditEvent ')
            navigate("/EditEvent", {
                state: {
                    eventID: props.event.id ? props.event.id : props.event.Eventid,

                }
            });
            //Code chaneg for Prod need to check
            // navigate("/EventDetails", {
            //     state: {
            //         eventID: props.event.Eventid,
            //         eventDetails: props.event,
            //         entryCount: props.event.EntryCount
            //     }
            // });
            // console.log('props.event.EventCode', props.event.EventCode)
            // if (props.event.EventCode === 'TP_TT_0001') {
            // window.location.href = "https://tournamentplanner.in/screens/TPLive_TournamentDetails.aspx?SCode=" + eventCode + "&TCode=" + props.event.EventCode;
            // navigate("/EventDetails", {
            //     state: {
            //         eventID: props.event.Eventid,
            //         eventDetails: props.event,
            //         entryCount: props.event.EntryCount
            //     }
            // });
            // } else {
            //     window.location.href = "https://tournamentplanner.in/screens/TPLive_TournamentDetails.aspx?SCode=" + eventCode + "&TCode=" + props.event.EventCode;

            // }

        }
    }
    async function eventView() {
        // console.log('in eventView ', "EventDetails" + props.event.id ? props.event.id : props.event.Eventid)
        navigate("/EventDetails/" + (props.event.id ? props.event.id : props.event.Eventid));

        // navigate("/EventDetails", {
        //     state: {
        //         eventID: props.event.Eventid,
        //         eventDetails: props.event,
        //         entryCount: props.event.EntryCount
        //     }
        // });
    }
    async function eventStatusChanged() {
        console.log('in eventStatusChanged')
        let leventStatus = '';

        if (eventStatus === 'Active') {
            leventStatus = 'Inactive';
        }
        else {
            leventStatus = 'Active';
        }
        let _updatedData = {
            'EventStatus': leventStatus,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.event.id, _updatedData)

        // para1 = {
        //     EventID: props.event.Eventid,
        //     EventStatus: leventStatus,
        // };
        // // console.log(para1);
        // const ret = await httpsCallable(functions, "updateEventDetails_EventStatus");
        // ret(para1).then(async (result) => {
        //     setEventStatus(leventStatus)

        // })
    }
    async function registrationStatusChanged() {
        console.log('registrationStatusChanged');
        let lregistrationStatus = '';
        let EventMode = '';
        if (registrationStatus === 'ON') {
            lregistrationStatus = 'OFF';
            EventMode = 'OPEN'
        }
        else {
            lregistrationStatus = 'ON';
            EventMode = 'BOOK'
        }

        let _updatedData = {
            'RegistrationOpenFlag': lregistrationStatus,
            "EventMode": EventMode,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.event.id, _updatedData)

        // var para1 = {};
        // para1 = {
        //     EventID: props.event.Eventid,
        //     RegistrationOpenFlag: lregistrationStatus,
        // };
        // // console.log(para1);
        // const ret = await httpsCallable(functions, "updateEventFlag_RegistrationOn");
        // ret(para1).then(async (result) => {
        //     setRegistrationStatus(lregistrationStatus);
        // })
    }
    async function paymentStatusChanged() {
        console.log('paymentStatusChanged');
        let lpaymentStatus = '';
        if (paymentStatus === 'On') {
            lpaymentStatus = 'Off';
        }
        else {
            lpaymentStatus = 'On';
        }
        let _updatedData = {
            'OnlinePaymentModeFlag': lpaymentStatus,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.event.id, _updatedData)

        // var para1 = {};
        // para1 = {
        //     EventID: props.event.Eventid,
        //     OnlinePaymentModeFlag: lpaymentStatus,
        // };
        // // console.log(para1);
        // const ret = await httpsCallable(functions, "updateEventFlag_OnlinePaymentMode");
        // ret(para1).then(async (result) => {
        //     setPaymentStatus(lpaymentStatus);
        // })
    }
    // console.log('props.event.EventLogo', props.event.EventLogo, ':: url :: ', url);
    return (
        <>
            <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className='new-event-card'>

                    <div style={{ cursor: 'pointer' }} onClick={() => {
                        eventView()
                    }}>
                        <div className={props.event.EventStatus === 'RegistrationPending' ? "event-card event-id inreview" :
                            props.event.EventStatus === 'Active' ? "event-card event-id active" :
                                props.event.EventStatus === 'Inactive' ? "event-card event-id inactive" :
                                    props.event.EventStatus === 'Cancelled' ? "event-card event-id cancelled" :
                                        props.event.EventStatus === 'Onhold' ? "event-card event-id onhold" : "event-card event-id "
                        }>

                            {props.event.EventStatus === 'RegistrationPending' ? 'Registration Pending' : props.event.EventStatus}
                        </div>

                        <div>
                            <img src={props.event.BannerImage && props.event.BannerImage !== '' ? props.event.BannerImage :
                                props.event.EventLogo &&
                                    Array.isArray(props.event.EventLogo) && props.event.EventLogo.length > 0 ? props.event.EventLogo[0] :
                                    props.event.EventLogo && props.event.EventLogo !== '' ? props.event.EventLogo : url} alt="" />
                        </div>
                        <div className='new-event-card-details' >
                            <div className='new-event-card-details-name'>
                                {user && user.userInfo && user.userInfo.SelectedRole !== 'PARTICIPANT' && <h1>{props.event.EventCode}</h1>}
                                <div style={{ position: 'relative', height: '100px' }}>
                                    <h2 style={{ margin: '0' }}>{props.event.EventName}</h2>
                                    {/* <h3 style={{ margin: '0' }}>{props.event.OrganizationName}{'Prakash Padukone Badminton Academy'}</h3> */}
                                    <h3 style={{ margin: '0' }}>{props.event.OrganizationName}</h3>
                                    {/* <h3 style={{ margin: '0' }}>{props.event.OrganizationName}{'Padukone-Dravid Center of excelence Yelhanka'}</h3> */}
                                    <div style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                        <h3 className='blink' style={{ margin: '0', color: 'red' }}>{eventStatusDes}</h3>
                                        <small className='blink' style={{ color: 'red', fontSize: '1.1rem', fontWeight: 'bold' }}>{isLive ? 'LiVE' : ''}</small>
                                    </div>
                                </div>

                                <div className='new-event-card-details-date'>
                                    <div>
                                        <h4>{props.event.Locality && props.event.Locality.substring(0, 15)}</h4>
                                        <h5>{props.event.City}</h5>
                                    </div>
                                    <div>
                                        <h4>{props.event.EventSDate}</h4>
                                        <h5>Starting Date</h5>
                                    </div>
                                    <div>
                                        <h4> {props.event.Fees === '' ? '₹ 0' : props.event.Fees}</h4>
                                        <h5>Onwards</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {user && user.userInfo && user.userInfo.SelectedRole !== 'PARTICIPANT' &&
                        <>
                            <hr />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span style={{ fontSize: '1rem', color: '#aaa' }}>Event Status</span>
                                <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                                    <span className={eventStatus === 'Active' ? 'active' : ''} style={{ color: '#59981A' }}>On</span>

                                    <div className={eventStatus === 'Active' ? 'switch-button on' : 'switch-button off'}
                                        onClick={eventStatusChanged} >
                                        <div className="switch-button-inner"></div>
                                    </div>
                                    <span className={eventStatus === 'Active' ? '' : 'active'} style={{ color: '#EB542E' }}>Off</span>
                                </div>
                            </div>

                            <hr style={{ margin: '8px 0' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span style={{ fontSize: '1rem', color: '#aaa' }}>Registration Status</span>
                                <div className="switch" style={{ height: 'calc(100% - 10px)' }}>


                                    <span className={registrationStatus === 'ON' ? 'active' : ''} style={{ color: '#59981A' }}>On</span>

                                    <div className={registrationStatus === 'ON' ? 'switch-button on' : 'switch-button off'}
                                        onClick={registrationStatusChanged}>
                                        <div className="switch-button-inner"></div>
                                    </div>

                                    <span className={registrationStatus === 'ON' ? '' : 'active'} style={{ color: '#EB542E' }}>Off</span>
                                </div>
                            </div>
                            <hr style={{ margin: '8px 0' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <span style={{ fontSize: '1rem', color: '#aaa' }}>Payment Status</span>
                                <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                                    <span className={paymentStatus === 'ON' ? 'active' : ''} style={{ color: '#59981A' }}>On</span>
                                    <div className={paymentStatus === 'ON' ? 'switch-button on' : 'switch-button off'}
                                        onClick={paymentStatusChanged}>
                                        <div className="switch-button-inner"></div>
                                    </div>

                                    <span className={paymentStatus === 'ON' ? '' : 'active'} style={{ color: '#EB542E' }}>Off</span>
                                </div>
                            </div>
                            <hr style={{ margin: '5px 0 0 0' }} />
                            <div onClick={() => {
                                console.log('in clck')
                                selectEvent()
                            }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <small>EDIT</small>
                                <span className="material-symbols-outlined">
                                    edit
                                </span>
                            </div>

                        </>}

                </div>

            </div>
        </>
    )
}
