import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Disclaimer from './Disclaimer'
export default function PrivacyPolicy() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    return (
        <div>
            <div>
                {/* {console.log("in render")} */}
                <div className="" id="fullContent">

                    <div className="city-select-div" style={{ display: 'none' }}>
                        <div className="search">
                            {/* <input type="text" onChange={callOnChange()} placeholder="Enter city" name="" value="" /> */}
                            {/* <input type="text" onChange={this.callOnChange()} placeholder="Enter city" name="" value="" /> */}

                            <span className="material-symbols-outlined">
                                search
                            </span>
                        </div>

                        <div className="cities">
                            <a href="/">Banglore</a>
                            <a href="/">Pune</a>
                            <a href="/">Mumbai</a>
                            <a href="/">Delhi</a>
                            <a href="/">Kolkata</a>
                            <hr />
                            <a href="/">Agra</a>
                            <a href="/">Ahemdabad</a>
                            <a href="/">Ambala</a>
                        </div>

                    </div>
                    {/* {console.log(loading)} */}

                    <div className="" style={{ display: 'flex', justifyContent: 'center', background: '#333C5D', padding: '20px 0' }}>
                        <img src="../img/privacy_policy.svg" className='refund-img' alt="" />
                    </div>


                    <section id="genre">

                        <div className='container'>
                            <br />
                            <div style={{ border: '2px solid #333C5D' }} className="secbox">
                                <div style={{ display: 'inline-block' }} className="">
                                    <h4 style={{ fontWeight: '1000', color: '#348DCB' }}>
                                        <span className="material-symbols-outlined" style={{ paddingRight: '10px', position: 'relative', top: '5px' }}>
                                            policy
                                        </span>
                                        Privacy Policy
                                    </h4>
                                    <hr />
                                </div>
                                <ul>
                                    <li>
                                        <p>
                                            We may collect information about you in a variety of ways. The information we may collect via the application depends on the content and materials you use, and includes:

                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong> Personal Data</strong><br />
                                            Demographic and other personally identifiable information (such as your name, contact and email id) that you voluntarily give to us when choosing to participate in various activities related to the Application, such as application registration, in our forums, liking posts, sending feedback, responding to surveys or emails. If you choose to share data about yourself via your profile or other interactive areas of the Application, please be advised that all data you disclose in these areas is public and your data might accessible to others who access the Application.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Derivative Data</strong><br />
                                            The information our servers automatically collect when you access the Application, such as your native actions that are integral to the Application, including liking, re-blogging, or replying to a post, as well as other interactions with the Application and other users via server log files.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <strong>Financial Data</strong><br />
                                            The application never stores or maintains any financial information, such as data related to your payment method (e.g. credit card number, card brand, expiration date, Bank details etc.). All the financial information is handled by payment gateway providers and we encourage you to review their privacy policy and contact them directly in case of any discrepancy.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <br /><br />
                            <Disclaimer collapseFlag={true}></Disclaimer>
                            {/* <div style={{ border: '2px solid #333C5D' }} className="secbox">
                                <div style={{ display: 'inline-block' }} className="">
                                    <h4 style={{ fontWeight: '1000', color: '#348DCB' }}>
                                        <span className="material-symbols-outlined" style={{ paddingRight: '10px', position: 'relative', top: '5px' }}>
                                            privacy_tip
                                        </span>
                                        Disclaimer
                                    </h4>
                                    <hr />
                                </div>
                                <ul>
                                    <li>
                                        <p>
                                            TPLiVE (Tournament Planner LiVE) provided the www.tplive.in website as a service to the public. The information provided on the website is for general informational purposes only.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            TPLiVE is not responsible for any loss or damage of any kind arising out of use. While the information contained within the site is periodically updated, no guarantee is given that the information provided in this website is correct, complete, and up-to-date. Although the site may include links providing direct access to other internet resources, including websites, TPLiVE is not responsible for the accuracy, adequacy, validity, reliability, availability or completeness of the content of information contained in these sites. Links from the website to third-party sites do not constitute an endorsement by TPLiVE of the parties or their products and services. The appearance on the Web site of advertisements and product or service information does not constitute an endorsement by TPLiVE , and TPLiVE has not investigated the claims made by any advertiser. Advertisements and Product information is based solely on the content received from suppliers.
                                        </p>
                                    </li>
                                </ul>
                            </div> */}

                        </div>

                        <br /><br /><hr />


                    </section>

                </div>

            </div >
        </div >
    )
}
