import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import { useExcelImportExport } from '../hooks/useExcelImportExport';
// import SignIn from './SignIn';

export default function ProfileAdmin() {
    const { user, logout } = useUserAuth();
    const { exportExcel } = useExcelImportExport()
    const [showPopup, setShowPopup] = useState(false);
    // const [showLogout, setShowLogout] = useState(false);
    const [signinFlag, setSigninFlag] = useState(false);
    // const [isNewUser, setIsNewUser] = useState(false);

    const navigate = useNavigate();
    // function setSignIn(flag, userData) {
    //     console.log(flag)
    //     setSigninFlag(flag);
    //     setUserDetails(userData);
    // }

    useEffect(() => {
        // getPlayerList();

        if (user.isLoggedIn) {
            if (user.userInfo) {
                // console.log('userDetails: ', userDetails);
                setSigninFlag(true)

            }
        }
        else {
            // navigate("/PhoneSignUp", { state: { url: 'Profile' } });
            // navigate("/PhoneSignUp", { state: { url: '' } });
            setSigninFlag(false)

        }
    }, [user])

    const handleLogOut = async () => {
        try {
            if (window.confirm('Are you sure you wish to Logout !!')) {
                await logout();
                navigate("/")
            }

        } catch (err) {

        }
    }

    async function getEventList() {
        console.log('in getEventList');
        setShowPopup(true);
        var functionName = '';
        if (user && user.userInfo && user.userInfo.SelectedRole === 'Admin') {
            functionName = 'getAllEventDetails';
        } else {
            functionName = 'getAllEventDetailsForOrganizer';
        }
        console.log('functionName', functionName);
        const eventSummary = httpsCallable(functions, functionName);
        let para = {
            organizerID: user.userInfo.uid
        };

        var curFormat = {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        };

        var options = {
            year: '2-digit',
            // year: 'numeric',
            month: 'short',
            day: 'numeric'
        };

        return await eventSummary(para)
            .then(async (result) => {
                let data = result.data;
                var refdate = "";
                var today = new Date();
                var newData = [];
                var eventSDate = '';
                var eventEDate = '';
                // console.log(data)
                data.forEach(element => {
                    eventEDate = '';
                    eventSDate = '';
                    if (element.EventStartDate) {
                        refdate = new Date(element.EventStartDate._seconds * 1000);
                        eventSDate = refdate.toLocaleDateString("en-IN", options);
                    }
                    else {
                        eventSDate = "";
                    }
                    if (element.EventEndDate) {
                        var eDate = new Date(element.EventEndDate._seconds * 1000 + 60 * 60 * 24 * 1000);
                        eventEDate = eDate.toLocaleDateString("en-IN", options);
                    }
                    else {
                        eventEDate = '';
                    }
                    if (refdate <= today && eDate >= today && element.EventStatus.toUpperCase() === 'ACTIVE') {
                        element.isLive = true;
                    } else {
                        element.isLive = false;
                    }

                    newData.push({
                        ...element,
                        EventSDate: eventSDate,
                        EventEDate: eventSDate,
                        Fees: element.MinimumFee ? (Number(element.MinimumFee).toLocaleString('en-IN', curFormat)) : "",
                    })
                });
                setShowPopup(false);
                exportExcel(newData, 'EventList');
            });

    }
    function setUpdateDetails() {
        navigate("/EditProfile");
    }
    function addEvent() {
        // navigate("/AddEvent", { state: { eventID: '' } });
        navigate("/CreateEvent", {
            state: {
                createStage: 'package'
            }
        });

    }
    function editEvent() {
        // navigate("/EventList");
        navigate("/Event");

    }
    function createOrganization() {
        console.log('in createOrganization')
        navigate('/EditOrganization', { state: { actionType: 'Add' } })
    }
    function editOrganization() {
        console.log('in createOrganization')
        navigate('/EditOrganization', { state: { actionType: 'Edit' } })
    }
    function userManagement() {
        navigate('/UserList')
    }
    function downloadEventDetails() {
        getEventList();

    }

    function downloadParticipantDetails() {
        // navigate('/EventListSmall')
        // navigate('/UpdateEvent', { state: { action: 'ParticipantList' } })
        navigate('/UpdateEvent/' + 'ParticipantList')
    }
    function updatePartner() {
        // navigate('/UpdateEvent', { state: { action: 'UpdatePartner' } })
        navigate('/UpdateEvent/' + 'UpdatePartner')
    }

    function updatePayment() {
        // navigate('/UpdateEvent', { state: { action: 'UpdatePayment' } })
        navigate('/UpdateEvent/' + 'UpdatePayment')
    }
    function withdrawEntry() {
        // navigate('/UpdateEvent', { state: { action: 'WithdrawEntry' } })
        navigate('/UpdateEvent/' + 'WithdrawEntry')
    }

    function uploadEntry() {
        // navigate('/UpdateEvent', { state: { action: 'UploadEntry' } })
        navigate('/UpdateEvent/' + 'UploadEntry')
    }

    function SetupDraw() {
        // navigate('/UpdateEvent', { state: { action: 'SetupDraw' } })
        navigate('/UpdateEvent/' + 'SetupDraw')
    }
    function MatchSetup() {
        // navigate('/UpdateEvent', { state: { action: 'MatchSetup' } })
        navigate('/UpdateEvent/' + 'MatchSetup')
    }
    function ScheduleSetup() {
        // navigate('/UpdateEvent', { state: { action: 'ScheduleSetup' } })
        navigate('/UpdateEvent/' + 'ScheduleSetup')
    }

    function UpdateMatch() {
        // navigate('/UpdateEvent', { state: { action: 'UpdateMatch' } })
        navigate('/UpdateEvent/' + 'UpdateMatch')
    }
    return (
        <>
            <br />
            {/* {!signinFlag && <SignIn setSignIn={setSignIn}></SignIn>} */}

            <div className={showPopup ? 'pop-up-div open' : 'pop-up-div'}>
                <lottie-player src='https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json' background='transparent'
                    speed="1" style={{ width: '50%', height: '50%' }} loop autoplay />
            </div >


            <div className='row no-gutters'>
                <div className='col-lg-6 col-md-12 col-sm-12'>

                    <div className='profile-cards'>

                        <h1 className='profile-cards-heading'>Event Management</h1>

                        {/* <div className="container-fluid"> */}
                        <div className="row no-gutters">

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }} onClick={() => {
                                    addEvent()
                                }}>
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            add
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content' >
                                        <h1>Add Event</h1>
                                        <h2>Add a new event</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }} onClick={() => {
                                    editEvent()
                                }}>
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            page_info
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Edit Event</h1>
                                        <h2>Edit existing event details</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    <br></br>

                </div>

                {/* <div className='col-lg-6 col-md-12 col-sm-12'>

                    <div className='profile-cards' >
                        <h1 className='profile-cards-heading'>Organizer Management</h1>
                        <div className="row no-gutters">
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }} 
                                    onClick={() => {
                                        createOrganization()
                                    }}

                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            person_add
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Add Organizer</h1>
                                        <h2>Add new Organizer</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }} onClick={editOrganization}>
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            manage_accounts
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Edit Organizer</h1>
                                        <h2>Edit existing Organizer details</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>

                </div> */}

                <div className='col-lg-6 col-md-12 col-sm-12'>

                    <div className='profile-cards'>
                        <h1 className='profile-cards-heading'>User Management</h1>
                        <div className="row no-gutters">
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        userManagement()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            list_alt
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>User List</h1>
                                        <h2>Add User as Organizer, Refree</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='profile-cards-inner padding-difference'>
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            notifications
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>User Notification</h1>
                                        <h2>Email/in-app notification</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br></br>

                </div>


                <div className='col-lg-6 col-md-12 col-sm-12'>

                    <div className='profile-cards'>
                        <h1 className='profile-cards-heading'>Entry and Payments</h1>
                        <div className="row no-gutters">
                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        updatePartner()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            group
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Update Partner</h1>
                                        <h2>For participation</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        updatePayment()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            payments
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Update Payment</h1>
                                        <h2>For Offline / Reconcilation</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        uploadEntry()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            system_update_alt
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Upload Entry</h1>
                                        <h2>Upload Entry details from Sheet</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        withdrawEntry()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            cancel_presentation
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Withdraw Entry</h1>
                                        <h2>Withdraw Entry from Event</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <br></br>

                </div>

                <div className='col-lg-6 col-md-12 col-sm-12'>

                    <div className='profile-cards'>
                        <h1 className='profile-cards-heading'>Draw & Matches</h1>
                        <div className="row no-gutters">
                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        SetupDraw()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            polyline
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Setup Draw</h1>
                                        <h2>Setup, update and publish Draw</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        MatchSetup()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            settings
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Match setup</h1>
                                        <h2>Setup and update match details</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        ScheduleSetup()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            date_range
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Schedule Match</h1>
                                        <h2>Schedule Matches </h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        UpdateMatch()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            tune
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Update Match</h1>
                                        <h2>Update match status </h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />

                </div>
                <div className='col-lg-12 col-md-12 col-sm-12'>

                    <div className='profile-cards'>
                        <h1 className='profile-cards-heading'>Download Reports</h1>
                        <div className="row no-gutters">
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        downloadEventDetails()
                                    }}
                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            report
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Event Details</h1>
                                        <h2>Download event list</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className='profile-cards-inner padding-difference' style={{ PointerEvent: 'all' }}
                                    onClick={() => {
                                        downloadParticipantDetails()
                                    }}

                                >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            contact_emergency
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Partcipant Details</h1>
                                        <h2>Download partcipant details</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <br></br>

                </div>


            </div>

        </>
    )
}
