import { useEffect } from 'react'

import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
// import { useLocalStorage } from "../context/useLocalStorage";
// import { functions } from '../firebase.js'
// import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { useNavigate } from 'react-router-dom';
import CategoryCartItem from '../components/CategoryCartItem'
import { useUserAuth } from '../context/UserAuthcontext.js';
import { useParams } from 'react-router-dom';
import { useCollection } from '../hooks/useCollection.js';
import { useDocument } from '../hooks/useDocument.js';
import { where } from 'firebase/firestore';
import EventDetailsMenu from './EventDetailsMenu'
import EDTournamentDetails from './EDTournamentDetails'

export default function PaymentSuccessful() {
    const { users } = useUserAuth();
    const { eventID, playerID } = useParams()
    console.log('eventID, playerID', eventID, playerID)

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    const { state } = useLocation();
    // const { id, participantDetails, paymentObj, paymentStatus, selectedCategory, updatePayment } = state;
    // const { id, PlayerID, Eventid, participantDetails } = state;
    // console.log('id', id, ' participantDetails : ', participantDetails, ' paymentObj : ', paymentObj, ' paymentStatus : ', paymentStatus, ' selectedCategory : ', selectedCategory, ' updatePayment : ', updatePayment)
    // const [eventDetails, setEventDetails] = useState(window.localStorage.getItem('EventDetails') ? JSON.parse(window.localStorage.getItem('EventDetails')) : null);
    const [registeredEvents, setRegisteredEvents] = useState(null);
    const [partnerList, setPartnerList] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { document: eventDetails, error: eventDocError } = useDocument('EventList', eventID)
    const { document: participantDetails, error: playerDocError } = useDocument('Participants', playerID)

    const queryConstraintsRegistrationPlayer = [
        where('EventID', '==', eventID),
        where('ParticipantID', '==', participantDetails ? participantDetails.PlayerID : '')
        // orderBy('UserName')
        // Add more query constraints as needed
    ];

    const queryConstraintsRegistrationPartner = [
        where('EventID', '==', eventID),
        where('PartnerPlayerID', '==', participantDetails ? participantDetails.PlayerID : ' ')
        // orderBy('UserName')
        // Add more query constraints as needed
    ];
    const { documents: palyerPartcipationDoc, error: palyerPartcipationDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistrationPlayer)
    const { documents: partnerPartcipationDoc, error: partnerPartcipationDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistrationPartner)
    console.log('1 palyerPartcipationDoc', palyerPartcipationDoc)
    console.log('12 partnerPartcipationDoc', partnerPartcipationDoc)


    useEffect(() => {

        // if (updatePayment) {
        //     updatePaymentStatus(paymentObj);
        // } else {
        // getRegisteredEvents();
        // }
        let participantDetailsObj = [];
        let pendingCnt = 0
        let pendingPrize = 0
        // console.log('AllCategoryList', eventCategoryDoc)
        console.log('palyerPartcipationDoc', palyerPartcipationDoc)

        !palyerPartcipationDocError && palyerPartcipationDoc.forEach(element => {
            console.log('palyerPartcipationDoc', element)
            participantDetailsObj.push({
                ...element,
                RegType: 'Self'
            })
            // console.log('1. element.PaymentStatus', element.PaymentStatus, totalFees, Number(element.Fees))

            if (element.PaymentStatus === 'Pending') {
                pendingPrize = pendingPrize + Number(element.Fees)
                pendingCnt = pendingCnt + 1
            }
            // console.log('2. element.PaymentStatus', element.PaymentStatus, totalFees, Number(element.Fees))

        });
        !partnerPartcipationDocError && partnerPartcipationDoc.forEach(element1 => {
            console.log('partnerPartcipationDoc', element1)
            participantDetailsObj.push({
                ...element1,
                RegType: 'Partner'
            })
        });
        setRegisteredEvents(participantDetailsObj)
    }, [partnerPartcipationDoc, palyerPartcipationDoc]);

    // async function getRegisteredEvents() {
    //     setLoading(true);
    //     var para1 = {};
    //     para1 = {
    //         PlayerID: playerID,
    //         EventID: eventID,
    //     };
    //     const ret1 = httpsCallable(functions, "getAllRegisteredEventListByPlayerCode");
    //     ret1(para1).then(async (result) => {
    //         setRegisteredEvents(result.data.entryDetails);
    //         let catArrayPartner = [];
    //         result.data.entryDetails.forEach((res) => {

    //             if (res.EventType.toUpperCase() === 'DOUBLE') {
    //                 var parnerDet = {
    //                     categoryName: res.CategoryName,
    //                     partnerID: res.PartnerPlayerID,
    //                     partnerName: res.PartnerPlayerName,
    //                     partnerUserID: res.PartnerUserID
    //                 }

    //                 catArrayPartner.push(parnerDet);
    //             }

    //         });
    //         setPartnerList(catArrayPartner);
    //         setLoading(false);
    //     });

    // }
    function goBack() {
        navigate(-1);
    }

    return (
        <>
            {eventDetails && <EventDetailsMenu calledFrom='Payments'
                eventID={eventID}
                eventDetails={eventDetails}
            />}

            <div className="container-fluid">
                <div className="row no-gutters">
                    {/* <div className="col-lg-12 col-md-12 col-sm-12">


                    </div> */}

                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <br></br>
                        <div id="regProfileNewParticipantDetails">
                            {/* <h3 style={{ fontWeight: '1000', color: '#348DCB', textAlign: 'center' }}>{updatePayment ? 'Payment is successful' : 'Registered Event'}</h3> */}
                            <h3 style={{ fontWeight: '1000', color: '#348DCB', textAlign: 'center' }}>{'Payment is successful'}</h3>
                            <h1 className="reg-form-email" id="playerName">{participantDetails && participantDetails.UserName} ({participantDetails && participantDetails.PlayerID})</h1>

                            {participantDetails && participantDetails.Gender && participantDetails.Gender.toUpperCase() === 'FEMALE' && <h5 className="reg-form-email female" id="playerGender">FEMALE</h5>}
                            {participantDetails && participantDetails.Gender && participantDetails.Gender.toUpperCase() === 'MALE' && <h5 className="reg-form-email male" id="playerGender">MALE</h5>}
                            <br />
                        </div>
                        {loading && <div>
                            <lottie-player src="https://lottie.host/35ed7cc5-900e-420b-95d1-cb90642020e7/UV7Rv7AbhO.json" background="transparent" speed="1" style={{ width: '100%', height: '100%' }} loop autoplay></lottie-player>


                        </div>}

                        <div className="row no-gutters" id="divRegEvent">

                            {registeredEvents && registeredEvents.map((events) => {
                                return <CategoryCartItem key={events.CategoryName} eventDetails={events} ></CategoryCartItem>

                            })}
                            {/* <span> * marked event is registered by Partner</span> */}
                            <hr style={{ border: 'none', borderTop: '1px solid #aaa' }} />
                            <div style={{ textAlign: 'center' }}>
                                <button onClick={goBack} className="mybutton button5"
                                    style={{ fontWeight: 'bold', textAlign: 'center' }}> &lt;&lt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back </button>
                            </div>
                        </div>
                    </div>
                    {eventDetails && <EDTournamentDetails eventDetails={eventDetails}
                        showRegistration={false}
                        showRegistrationButton={false} />}

                </div>
            </div>
        </>
    )
}

