import React from 'react'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'
import { useState } from 'react';
import RichTextEditor from "react-rte";

export default function EditEventAnnouncement(props) {
    const [resultMessage, setResultMessage] = useState('');
    const [editedAnnouncement, setEditedAnnouncement] = useState(props.eventDetails && props.eventDetails.Announcement ? props.eventDetails.Announcement : '');
    const [isAnnouncementEdit, setIsAnnouncementEdit] = useState(false);
    const [announcementvalue, setAnnouncementvalue] = useState(
        RichTextEditor.createValueFromString(editedAnnouncement, "html")
    );
    const handleCancelAnnouncement = () => {
        setIsAnnouncementEdit(false);
    };

    const handleEditAnnouncement = () => {
        setIsAnnouncementEdit(true);
    };

    const handleSaveAnnouncement = async () => {
        try {
            //save in DB Start
            SaveAnnouncement(announcementvalue.toString("html"))
            //save in DB End

            setIsAnnouncementEdit(false);
        } catch (error) {
            console.error("Error updating document:", error);
        }
    };



    async function SaveAnnouncement(announcement) {
        console.log('in SaveAnnouncement');

        var para1 = {};


        para1 = {
            EventID: props.eventDetails.Eventid,
            Announcement: announcement,
        };

        const ret = await httpsCallable(functions, "updateEventDetails_Announcement");
        ret(para1).then(async (result) => {
            // setLoading(false);
            props.setEventDetails({
                ...props.eventDetails,
                Announcement: announcement
            })
            setResultMessage('Announcement Details Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })

    }

    return (
        <div id="accouncementDiv" className="event-setup-announcement-section">

            <div className="row no-gutter">

                <div className="event-detail-input-div" style={{ padding: '0 20px', display: 'flex' }}>
                    <h1>Announcement</h1><br />
                    {<div style={{
                        position: 'relative',
                        left: '20px',
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        background: '#348DCB',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }} onClick={() => handleEditAnnouncement()}>
                        <span style={{ fontSize: '1.1rem', color: '#fff', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} className="material-symbols-outlined">
                            edit
                        </span>
                    </div>}
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="event-detail-input-div">
                        {/* <textarea name="" onChange={(e) =>
                            props.setEventDetails({
                                ...props.eventDetails,
                                Announcement: e.target.value
                            })}
                            value={props.eventDetails.Announcement}
                            className=""></textarea> */}

                        {isAnnouncementEdit ? (
                            <div>
                                <div>
                                    <RichTextEditor
                                        value={announcementvalue}
                                        onChange={setAnnouncementvalue}
                                    />
                                </div>
                                <div className="vg10"></div>
                                <div className="d-flex justify-content-between">
                                    <div
                                        className="theme_btn btn_border"
                                        onClick={handleCancelAnnouncement}
                                        style={{
                                            width: "fit-content",
                                        }}
                                    >
                                        <button className="mybutton button5 event-details-save-btn"
                                            onClick={handleCancelAnnouncement} type="button">Cancel</button>

                                    </div>
                                    <div
                                        className="theme_btn btn_fill"
                                        onClick={handleSaveAnnouncement}
                                        style={{
                                            width: "fit-content",
                                        }}
                                    >
                                        <button className="mybutton button5 event-details-save-btn"
                                            onClick={handleSaveAnnouncement} type="button">Save Rules</button>


                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="d-flex align-items-center">
                                    <p
                                        className="editortext"
                                        dangerouslySetInnerHTML={{
                                            __html:

                                                announcementvalue.toString(
                                                    "html"
                                                ),
                                        }}
                                    ></p>

                                </div>
                            </>
                        )}
                    </div>
                </div>
                {/* 
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="add-more-line">
                        <div></div>
                        <a href="/">ADD ONE MORE LINE</a>
                    </div>
                </div> */}
                {/* <div id="errorMsgAnnouncement"
                  className="col-lg-12 col-md-12 col-sm-12"><br />

                  <div className="event-detail-input-div">
                      <div></div>
                      <span id="errorMssageAnnouncement">{errorMssageAnnouncement}</span>
                  </div>

              </div> */}
                {/* <div className="col-lg-12 col-md-12 col-sm-12"><br />

                    <div className="event-details-save-btn-div">
                        <div></div>
                        <button className="mybutton button5 event-details-save-btn"
                            onClick={SaveAnnouncement} type="button">Save Announcement</button>
                    </div>

                </div> */}
                <div className="col-lg-12 col-md-12 col-sm-12"><br />
                    <div className="event-details-save-btn-div">
                        {resultMessage}
                    </div><br />

                </div>


            </div>

        </div>
    )
}
