import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
// import SignIn from './SignIn';

export default function ProfileRefree() {
    const { user, logout } = useUserAuth();
    // const [signinFlag, setSigninFlag] = useState(false);

    const navigate = useNavigate();
    // function setSignIn(flag, userData) {
    //     console.log(flag)
    //     setSigninFlag(flag);
    //     setUserDetails(userData);
    // }

    useEffect(() => {
        // getPlayerList();

        if (user.isLoggedIn) {
            if (user.userInfo) {
                // console.log('userDetails: ', userDetails);
                // setSigninFlag(true)

            }
        }
        else {
            // navigate("/PhoneSignUp", { state: { url: 'Profile' } });
            // navigate("/PhoneSignUp", { state: { url: '' } });
            // setSigninFlag(false)

        }
    }, [user])

    const handleLogOut = async () => {
        try {
            if (window.confirm('Are you sure you wish to Logout !!')) {
                await logout();
                navigate("/")
            }

        } catch (err) {

        }
    }
    function setUpdateDetails() {

    }
    return (
        <>
            {/* {!signinFlag && <SignIn setSignIn={setSignIn}></SignIn>} */}
            {/* 
            <div className='profile-notification-div' >

                <div className='profile-notification-div-inner' onClick={() => {
                    setUpdateDetails();
                }

                }>
                    <div>
                        <span style={{ cursor: "pointer" }} className="material-symbols-outlined">
                            edit
                        </span>
                    </div>
                    <h1>Edit Profile Details</h1>
                </div>



                <div className='profile-notification-div-inner' onClick={() => handleLogOut()}>
                    <div>
                        <span style={{ cursor: "pointer" }} className="material-symbols-outlined">
                            logout
                        </span>
                    </div>
                    <h1>Logout</h1>
                </div>

            </div>

            <br></br> */}

            <div className='profile-cards'>
                <h1 className='profile-cards-heading'>Draw & Matches</h1>

                <div className='profile-cards-inner padding-difference'>
                    <div className='profile-cards-inner-circle'>
                        <span className="material-symbols-outlined">
                            language
                        </span>
                    </div>
                    <div className='profile-cards-inner-content'>
                        <h1>Update Match</h1>
                        <h2>Update Matches Status </h2>
                    </div>
                    <div className='profile-cards-inner-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>


            </div>

        </>
    )

}
