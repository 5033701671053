import { useState, useEffect } from 'react'
import { where, orderBy } from 'firebase/firestore'
import { useCollection } from './useCollection'
import { useUserAuth } from '../context/UserAuthcontext';

export const useGetOrder = () => {
    const { user } = useUserAuth();

    const [isCompleted, setIsCompleted] = useState(false)
    const [orders, setOrders] = useState([])
    const [orderCnt, setOrderCnt] = useState(0)

    // const queryUser = [
    //     where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ' '),

    // ]
    console.log('1 user.userInfo', user.userInfo)
    const queryRegistration = [
        where('PaymentStatus', '==', 'Pending'),
        where('CreatedBy', '==', user && user.userInfo ? user.userInfo.uid : ' '),

    ]
    const queryEvent = [
        where('EventStatus', '==', 'Active'),
        orderBy('EventStartDate', 'desc'),
    ]

    const { documents: eventDocs, error: eventDocError } = useCollection('EventList', queryEvent)
    // const { documents: playerDocs, error: PlayerDocError } = useCollection('Participants', queryUser)
    const { documents: registrationDoc, error: registrationDocError } = useCollection('EventRegistrationDetails', queryRegistration)

    useEffect(() => {

        // let playerList = [];
        // playerDocs.forEach(player => {
        //     player.PlayerID && playerList.push(player.PlayerID)
        // });

        let eventIds = []
        eventDocs.forEach(element => {
            eventIds.push(element.id)
        });

        let _finalList = [];
        registrationDoc.forEach(registration => {
            // console.log(registration, eventIds, playerIDs)
            if (eventIds.includes(registration.EventID)) {
                _finalList.push(registration)
            }
        });

        setOrders(_finalList)
        setOrderCnt(_finalList.length)
        setIsCompleted(true);

    }, [user, eventDocs, registrationDoc])

    return { orders, orderCnt, isCompleted }
}
