import React, { useState } from 'react'
import { useEffect } from 'react';
import useDocumentUpdate from '../hooks/useDocumentUpdate.js';

export default function UserCard(props) {
    const [userObj, setUserObj] = useState(null);
    const [roleList, setRoleList] = useState(['PARTICIPANT', 'ADMIN', 'ORGANIZER', 'REFREE'])
    const [selectedRole, setSelectedRole] = useState(null);
    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();

    useEffect(() => {
        setUserObj(props.userL)
        setSelectedRole(props.userL.UserRole);
    }, [props.userL]);
    async function funSelectedRoleNew(type, action) {
        console.log('userObj', type, selectedRole);
        var rList = selectedRole;
        if (action === 'ADD') {
            if (!rList.find(e => e.TYPE === type)) {
                rList.push({
                    TYPE: type
                });
            }
        }
        else {
            const index = rList.findIndex(e => e.TYPE === type);
            if (index > -1) { // only splice array when item is found
                rList.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        let _updatedData = {
            'UserRole': rList,
        }
        await updateDocument('UserList', props.userL.id, _updatedData)
    }
    async function saveUserDetails(status) {
        console.log('saveUserDetails : ', status)
        let _updatedData = {
            'UserStatus': status,
        }
        await updateDocument('UserList', props.userL.id, _updatedData)
    }
    return (
        <div className='col-lg-3 col-md-6 col-sm-12' key={userObj && userObj.PlayerID}>
            <div className='user-list-card'>
                <h1>{userObj && userObj.UserName} - {userObj && userObj.PlayerID}</h1>
                <hr></hr>

                <div className="row no-gutters">
                    <h2>Role</h2>
                    {/* {console.log(roleList)} */}
                    {roleList.map((role) => (
                        <div className="col-6" style={{ padding: '10px' }} key={role}>
                            <input type="checkbox" className="checkbox" style={{ width: '0px' }}
                                onChange={(e) => {
                                    if (role !== 'PARTICIPANT') {
                                        e.target.checked && funSelectedRoleNew(role, 'ADD');
                                        !e.target.checked && funSelectedRoleNew(role, 'REMOVE');
                                    }
                                }}
                                name={"userRole" + (userObj && userObj.PlayerID)} id={"reg" + role + (userObj && userObj.PlayerID)}
                                value={role}
                                checked={selectedRole && selectedRole.find(e => e.TYPE === role) ? true : false} />
                            <label style={{ height: '40px', border: '1px solid #ddd' }}
                                className="checkbox-label" id={"reg" + role + (userObj && userObj.PlayerID) + "Label"}
                                htmlFor={"reg" + role + (userObj && userObj.PlayerID)}>
                                <i className="fas fa-plus"
                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                <i className="fas fa-check"
                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                <span style={{ fontSize: '0.7rem' }}>{role}</span>
                            </label>
                        </div>
                    ))}

                    <hr />

                    <div className="col-6">
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%', }}>
                            <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                                <span className={userObj && userObj.UserStatus && userObj.UserStatus === 'Active' ? 'active' : ''}
                                    id="RegistrationOn" style={{ color: '#59981A', fontSize: '0.9rem' }}>Active</span>

                                <div onClick={() => {
                                    setUserObj({
                                        ...userObj,
                                        UserStatus: userObj.UserStatus === 'Active' ? 'Inactive' : 'Active',
                                    })
                                    saveUserDetails(userObj.UserStatus === 'Active' ? 'Inactive' : 'Active');
                                    // userObj.ApprovalStatus = userObj.ApprovalStatus === 'Active' ? 'Inactive' : 'Active'
                                }}
                                    className={(userObj && userObj.UserStatus && userObj.UserStatus !== 'Active') ? 'switch-button event-card-switch off' : 'switch-button event-card-switch on'} style={{ height: '21px', width: '45px' }}>
                                    <div className="switch-button-inner" style={{ width: '14px', height: '14px' }} ></div>
                                </div>

                                <span className={userObj && userObj.UserStatus && userObj.UserStatus !== 'Active' ? '' : 'active'} id="RegistrationOff"
                                    style={{ color: '#EB542E', fontSize: '0.9rem' }}>Inactive</span>
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className="col-6">

                        <div className="event-details-save-btn-div">
                            <div></div>
                            <button style={{ width: '95px' }} className="mybutton button5 event-details-save-btn"
                                onClick={() => { saveUserDetails() }} type="button">SAVE</button>
                        </div>

                    </div> */}

                </div>

            </div>
        </div >
    )
}
