import React, { useState, useEffect } from 'react'
import LeagueMatchDetails from './LeagueMatchDetails.js'
import SingleElimination from './SingleElimination.js'
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
export default function ShowDrawComponent(props) {
    // const [drawPublished, setDrawPublished] = useState(props.drawCategory.Published)
    const [drawCategory, setDrawCategory] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [eventID, setEventID] = useState(props.EventID)

    const eventQueryConstraints = [
        where('EventID', '==', props.EventID),

        // orderBy('OrganizationName')
        // Add more query constraints as needed
    ];
    const { documents: knowckoutDrawDoc, error: knowckoutDrawDocError } = useCollection('TX_MatchDetails', eventQueryConstraints)
    const { documents: leagueDrawDoc, error: leagueDrawDocError } = useCollection('TX_MatchDetailsLeague', eventQueryConstraints)
    const { documents: leagueStatusDoc, error: leagueStatusDocError } = useCollection('TX_LeagueStatus', eventQueryConstraints)

    useEffect(() => {
        let catList = categoryList;
        knowckoutDrawDoc.forEach(elementKnockOut => {
            if (!catList.find(e => e.CategoryName === elementKnockOut.CategoryName)) {
                catList.push({
                    CategoryName: elementKnockOut.CategoryName,
                    Published: elementKnockOut.Published ? elementKnockOut.Published : 'NO',
                    DrawType: 'Knockout'
                })
            }
        });
        leagueDrawDoc.forEach(elementLeague => {
            if (!catList.find(e => e.CategoryName === elementLeague.CategoryName)) {
                catList.push({
                    CategoryName: elementLeague.CategoryName,
                    Published: elementLeague.Published ? elementLeague.Published : 'NO',
                    DrawType: 'League'
                })
            }
        });
        let matchDetails = [];
        let lCatList = [];
        catList.forEach(element => {
            if (element.DrawType === 'Knockout') {
                matchDetails = knowckoutDrawDoc.filter(e => e.CategoryName === element.CategoryName)
            }
            if (element.DrawType === 'League') {
                matchDetails = leagueDrawDoc.filter(e => e.CategoryName === element.CategoryName)
            }
            lCatList.push({
                ...element,
                MatchDetails: matchDetails
            })
        });
        setCategoryList(lCatList);
        setDrawCategory(lCatList[0]);

    }, [knowckoutDrawDoc, leagueDrawDoc, leagueStatusDoc]);

    function updateDrawPublish(categoryName, publishFlag) {
        console.log(categoryName, publishFlag, drawCategory);
        // setDrawCategory({
        //     ...drawCategory,
        //     Published: publishFlag,
        // });
    }

    function PublishDraw(categoryName, publishStatus) {
        // setDrawCategory({
        //     ...drawCategory,
        //     Published: publishStatus,
        // });

    }
    // console.log('categoryList', categoryList, ' DrawCategory', drawCategory)
    return (
        <>
            {/* {console.log('props:', props)} */}
            <div className='row no-gutters' style={{ background: '#fff' }}>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <strong>Draw for - Category List</strong>
                </div>
                <div className='col-lg-12' style={{ border: '1' }}>
                    <div className='row no-gutters' style={{ padding: '0 5px' }}>
                        {/* {console.log('props.categoryList', categoryList)} */}

                        {categoryList && categoryList.map((obj) => {
                            return <div key={obj.CategoryName} className="col-4" style={{ padding: '0' }}>

                                <div style={{ widht: '100%', textAlign: 'center', padding: '8px 5px' }}>
                                    <input type="radio" checked={drawCategory === obj.CategoryName ? true : false}
                                        className="checkbox" style={{ width: '0px' }}
                                        name="EventNameDraw" id={"1" + obj.CategoryName.replaceAll(' ', '').replaceAll('-', '')}
                                        value={obj.CategoryName}
                                        onChange={() => {
                                            console.log('obj :: ', obj.CategoryName)
                                            props.ShowCategoryDraw(obj.CategoryNameobj);

                                        }} />
                                    <label style={{ height: '30px', border: '1px solid #ddd', paddingTop: '1px' }}
                                        className="checkbox-label setup-draw-label" id={"1" + obj.CategoryName.replaceAll(' ', '').replaceAll('-', '') + "Label"}

                                        htmlFor={"1" + obj.CategoryName.replaceAll(' ', '').replaceAll('-', '')}>
                                        <i className="fas fa-plus"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        <i className="fas fa-check"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        <span>{obj.CategoryName}</span>
                                    </label>
                                </div>

                            </div>
                        })}
                    </div>
                </div>
            </div>

            <div className='col-lg-12' style={{ textAlign: 'center' }}>
                <hr></hr>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <strong>Draw for Category : {drawCategory && drawCategory.CategoryName}</strong>
                    {props.IsAdmin && <div style={{ paddingLeft: '20px' }}>
                        <h1 style={{ fontSize: '0.9rem', color: '#888' }}>Publish</h1>
                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                            <span className={drawCategory && drawCategory.Published && drawCategory.Published.toUpperCase() === 'YES' ? 'active' : ''} style={{ color: '#59981A' }}>On</span>

                            <div className={drawCategory && drawCategory.Published && drawCategory.Published.toUpperCase() === 'YES' ? 'switch-button on' : 'switch-button off'}
                                style={{ height: '25px', width: '60px' }}
                                onClick={() => {
                                    //  console.log('in click')
                                    updateDrawPublish(drawCategory.CategoryName,
                                        drawCategory && drawCategory.Published && drawCategory.Published.toUpperCase() === 'YES' ? "No" : "Yes")
                                }}
                            >
                                <div className="switch-button-inner" style={{ height: '14px', width: '14px' }}></div>
                            </div>
                            <span className={drawCategory && drawCategory.Published && drawCategory.Published.toUpperCase() === 'YES' ? '' : 'active'} style={{ color: '#EB542E' }}>Off</span>
                        </div>
                    </div>}
                </div>

                {/* <hr></hr> */}
            </div>
            {drawCategory && drawCategory.DrawType === 'Knockout' && <SingleElimination EventID={props.EventID} MatchDetails={drawCategory.MatchDetails}></SingleElimination>}

            {drawCategory && drawCategory.DrawType === 'League' && <LeagueMatchDetails EventID={props.EventID} MatchDetails={drawCategory.MatchDetails} MatchStatus={drawCategory.MatchStatus}></LeagueMatchDetails>}


        </>
    )
}
