import React, { useState, useEffect } from 'react';

import '../css/Profile.css'
// import { functions } from '../firebase.js'
// import { httpsCallable } from "firebase/functions";
import { useUserAuth } from '../context/UserAuthcontext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
import ProfileGeneral from './ProfileGeneral';
import ProfileAdmin from './ProfileAdmin';
import ProfileOrganizer from './ProfileOrganizer';
import ProfileRefree from './ProfileRefree';
import ProfileHeading from './ProfileHeading';
import SignIn from './SignIn';
import { useDocument } from '../hooks/useDocument.js';
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';

export default function Profile() {
    const { user, logout } = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [showLogout, setShowLogout] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);

    const [signinFlag, setSigninFlag] = useState(false);
    const userid = user && user.userInfo ? user.userInfo.uid : 'test'
    const { document: userProfileDoc, error: userProfileDocError } = useDocument('UserList', userid)

    const queryEvent = [
        where('CreatedBy', '==', user && user.userInfo ? user.userInfo.uid : 'test'),
        // orderBy('EventStartDate', 'desc'),
        // limit(30)
    ]
    const { documents: eventDoc, error: eventDocError } = useCollection('EventList', queryEvent)

    function setSignIn(flag, userData, isFirstLogin) {
        console.log(flag, userData)
        // setUserDetails(userData);
        setSigninFlag(flag);

    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    // console.log('userDetails : ', userDetails)

    useEffect(() => {
        if (!isNewUser) {
            console.log('not new user', isNewUser)
            if (user.isLoggedIn) {
                if (user.userInfo) {
                    setSigninFlag(true)
                }
            }
        }
    }, [user])

    var getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };
    console.log('user', user)

    function setOrderHistory() {
        navigate("/OrderHistory");
    }
    function createEvent() {
        navigate("/CreateEvent", {
            state: {
                createStage: 'package'
            }
        });
    }

    function showDashboard() {
        navigate('/Dashboard');
    }
    function showOrder() {
        navigate('/Orders');
    }
    return (
        <>
            {user && !user.userInfo && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>}

            {/* <div className={false ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner' style={{ maxWidth: '700px', }}>
                    <h1 >
                        <strong>Change Address</strong>
                    </h1>
                    <hr />
                    <center>
                        <div className="event-detail-input-div" style={{ maxWidth: '390px', textAlign: 'left' }}>
                            <label>City</label>
                            <input type="text" id="City" placeholder="Enter City..."

                                required />

                        </div>
                    </center>

                    <center>
                        <div className="event-detail-input-div" style={{ maxWidth: '390px', textAlign: 'left' }}>
                            <label>Address</label>
                            <textarea name="" id="" height="70px" style={{ lineHeight: '1.3', resize: 'none' }}></textarea>
                        </div>
                    </center>

                    <div className='btn-div' style={{ borderTop: '1px solid #eee', padding: '15px 30px 0 30px' }}>
                        <button className='mybutton button5'>
                            <span>YES</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybutton button5'>
                            <span>Cancel</span>
                        </button>
                    </div>

                </div>
            </div> */}

            <div className={showLogout ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner'>
                    <h1 >
                        <strong>Are you sure you wish to logout?</strong>

                    </h1>

                    <hr />

                    <div className='btn-div'>
                        <button className='mybutton button5' onClick={() => {
                            logout();
                            navigate("/")

                        }}>
                            <span>YES</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybutton button5' onClick={() => {
                            setShowLogout(false);

                        }}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div >
            </div>

            {/* {signinFlag && user && user.userInfo && */}
            {user && user.userInfo &&
                <>
                    <div className='profile-heading-banner' style={{ position: 'relative' }}>
                        <img src="/img/profile_banner.png" width={'100%'} alt="" />
                        <div style={{ position: 'absolute', left: '0', top: '0', width: '100%', height: '100%', background: 'rgba(0,0,0,0.6)', zIndex: '10' }}></div>
                    </div>

                    <div className='container' style={{ position: 'relative' }}>

                        <div className='profile-heading-margin'>
                            <ProfileHeading></ProfileHeading>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                        {/* <div className='profile-heading-card' style={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/EditProfile');
                        }}
                    >
                        <div className='profile-heading-card-img'>
                            <img src={userDetails && userDetails.ProfilePicURL ? userDetails.ProfilePicURL : "/img/user_icon.png"} alt="" />
                        </div>
                        <div className='profile-heading-card-details'>
                            <div style={{ justifyContent: 'initial' }}>
                                <h2>Hi {userDetails && userDetails.UserName}</h2>
                                <h3 style={{ position: 'relative', top: '-2px', paddingLeft: '5px', fontSize: '1rem' }}>({userDetails && userDetails.PlayerID})</h3>
                            </div>
                            <hr style={{ margin: '2px 0 5px 0' }} />
                            <div>
                                <h4>{userDetails && userDetails.Phone}</h4>
                                <h3>{userDetails && userDetails.SelectedRole && userDetails.SelectedRole.toLowerCase().replace(/([A-Z])/g, ' $1')
                                    .replace(/^./, function (str) { return str.toUpperCase(); })}</h3>
                            </div>
                            <div>
                                <h5>{userDetails && userDetails.Email}</h5>
                                <h3>{userDetails && userDetails.City}</h3>
                            </div>
                            <div>
                                <h3>{userDetails && userDetails.DateOfBirth ?
                                    userDetails.DateOfBirth.seconds ?
                                        new Date(userDetails.DateOfBirth.seconds * 1000).toISOString().split("T")[0]
                                        : userDetails.DateOfBirth._seconds ?
                                            new Date(userDetails.DateOfBirth._seconds * 1000).toISOString().split("T")[0]
                                            : new Date(userDetails.DateOfBirth).toISOString().split("T")[0]
                                    : new Date().toISOString().split("T")[0]}</h3>
                            </div>
                        </div>
                        <div className='profile-heading-card-arrow'>
                            <span className="material-symbols-outlined">
                                arrow_forward_ios
                            </span>
                        </div>
                    </div> */}


                        <div className='profile-notification-div' >

                            <div style={{ cursor: "pointer" }} className='profile-notification-div-inner' onClick={() => {
                                showOrder();
                            }

                            }>
                                <div>
                                    <span className="material-symbols-outlined">
                                        shopping_cart
                                    </span>
                                </div>
                                <h1>Cart</h1>
                            </div>

                            <div style={{ cursor: "pointer" }} className='profile-notification-div-inner'
                                onClick={() => {
                                    setOrderHistory();
                                }}
                            >
                                <div>
                                    <span className="material-symbols-outlined">
                                        overview_key
                                    </span>
                                </div>
                                <h1>Orders</h1>
                            </div>

                            <div className='profile-notification-div-inner' onClick={() => navigate("/RegisteredUserProfile")}

                            >
                                <div>
                                    <span style={{ cursor: "pointer" }} className="material-symbols-outlined">
                                        groups
                                    </span>

                                </div>
                                <h1>Members</h1>
                            </div>

                            {/* <div style={{ cursor: "pointer" }} className='profile-notification-div-inner' onClick={() => handleLogOut()}> */}
                            <div style={{ cursor: "pointer" }} className='profile-notification-div-inner'
                            // onClick={() => {
                            //     setShowLogout(true);
                            // }}
                            >


                                <div>
                                    <span className="material-symbols-outlined">
                                        wallet
                                    </span>
                                </div>
                                <h1>Wallet</h1>
                            </div>

                        </div>

                    </div>

                </>}

            <div className='container'>
                <br />
                <Link to="/OrganizerDashboard" className='list-your-event-section'>
                    {/* <div className='list-your-event-section-content'> */}
                    <span className="material-symbols-outlined">
                        list_alt
                    </span>
                    <div className='list-your-event-section-content'>
                        <div>
                            <h1>Listed Events ({eventDoc && eventDoc.length})</h1>
                            {/* <div>
                                    <div className="bar">
                                        <LinearProgressBar total="55" current="20" />
                                    </div>
                                    <h3>208</h3>
                                </div> */}
                            <button className='mybutton button5' style={{ padding: '5px 0' }}>Create Event</button>
                        </div>
                        <h2>Are you an organizer. Publish your event in just few clicks.</h2>
                    </div>

                    {/* </div> */}
                </Link>

                <br />
                <div className='row no-gutters'>

                    <div className='col-6'>

                        <div className='coach-card'>
                            <img src='/img/sports.png' alt='sports'></img>
                            <h1>Do you own an academy / arena?</h1>
                            <h2>Add the details here</h2>
                        </div>
                    </div>


                    <div className='col-6'>
                        <div className='coach-card'>
                            <img src='/img/coach.png' alt='sports'></img>
                            <h1>Are you a coach or mentor?</h1>
                            <h2>Add your profile here</h2>
                        </div>
                    </div>

                    <div className='col-6'>
                        <div className='coach-card'>
                            <img src='/img/umpire.png' alt='sports'></img>
                            <h1>Are you an umpire or a referee?</h1>
                            <h2>Add your profie here</h2>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='coach-card'>
                            <img src='/img/sports.png' alt='sports'></img>
                            <h1>Are you a Fitness Trainer?</h1>
                            <h2>Add the details here</h2>
                        </div>
                    </div>
                </div>
                {/* <ProfileGeneral ></ProfileGeneral> */}

                {user && user.userInfo &&
                    user && user.userInfo && user.userInfo.SelectedRole && user.userInfo.SelectedRole.toUpperCase() === 'ADMIN' ?
                    // <ProfileAdmin userDetails={user}></ProfileAdmin> :
                    <ProfileAdmin ></ProfileAdmin> :
                    signinFlag && user && user.userInfo && user.userInfo.SelectedRole && user.userInfo.SelectedRole.toUpperCase() === 'ORGANIZER' ?
                        // <ProfileOrganizer userDetails={user.userInfo}></ProfileOrganizer> :
                        <ProfileOrganizer ></ProfileOrganizer> :
                        signinFlag && user && user.userInfo && user.userInfo.SelectedRole && user.userInfo.SelectedRole.toUpperCase() === 'REFREE' ?
                            // <ProfileRefree userDetails={user.userInfo}></ProfileRefree> :
                            <ProfileRefree ></ProfileRefree> :
                            // signinFlag && <ProfileGeneral userDetails={user.userInfo}></ProfileGeneral>
                            signinFlag && <ProfileGeneral ></ProfileGeneral>
                }
            </div>

            <div className='container'>

                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className='profile-cards'>
                        <div className='row no-gutters'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <Link to='/ContactUs' className='profile-cards-inner padding-difference' >
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            security
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content' >
                                        <h1>Help & Support</h1>
                                        <h2>Ask any questions you need clarified</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </Link>

                                <div className='profile-cards-inner padding-difference'>
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            sms
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>How to use this app</h1>
                                        <h2>Simple Steps to Get Started</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <Link to='/TermsAndConditions' className='profile-cards-inner padding-difference'>
                                    <div className='profile-cards-inner-circle'>
                                        <span className="material-symbols-outlined">
                                            description
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content'>
                                        <h1>Terms & Conditions</h1>
                                        <h2>Important Guidelines and User Responsibilities</h2>
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </Link>

                                <div className='profile-cards-inner padding-difference' style={{ background: '#df3a3a' }} onClick={(e) => {
                                    setShowLogout(true)
                                }}>
                                    <div className='profile-cards-inner-circle' style={{ background: '#fff' }}>
                                        <span className="material-symbols-outlined">
                                            logout
                                        </span>
                                    </div>
                                    <div className='profile-cards-inner-content' >
                                        <h1 style={{ position: 'relative', top: '-5px', color: '#fff' }}>Logout</h1>
                                        {/* <h2>Quick Answers to Common Questions</h2> */}
                                    </div>
                                    <div className='profile-cards-inner-arrow'>
                                        <span style={{ color: '#fff' }} className="material-symbols-outlined">
                                            arrow_forward_ios
                                        </span>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

                {/* <div style={{ width: '100%' }}
                    onClick={() => {
                        setShowLogout(true);
                    }}>
                    <button style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        left: '-1.5px',
                        marginBottom: '0',
                        width: '100%',
                        borderRadius: '10px',
                        background: '#e11032',
                        height: '40px',
                    }} className='mybutton button5'>
                        <span style={{
                            position: 'relative',
                            top: '-px',
                            fontSize: '1.3rem',
                            paddingRight: '5px'
                        }} className="material-symbols-outlined">
                            logout
                        </span>
                        <small style={{ fontSize: '1.1rem', }}>
                            Logout
                        </small></button>
                </div> */}
            </div><br />
            {/* {!signinFlag && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>} */}

        </>
    )
}
