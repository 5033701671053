import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
// import { functions } from '../firebase.js'
// import { httpsCallable } from "firebase/functions";
import EventDetailsMenu from './EventDetailsMenu'
import EDTournamentDetails from './EDTournamentDetails'
import EventPartcipantCard from './EventPartcipantCard'
import { useNavigate } from 'react-router-dom';

import '../css/EventDetails.css'
import '../css/EventParticipation.css'
import EventCategoryParticipant from './EventCategoryParticipant';
import { useDocument } from '../hooks/useDocument.js'
import { where, orderBy } from 'firebase/firestore';
import { useCollection } from '../hooks/useCollection.js'
import { useUserAuth } from '../context/UserAuthcontext.js'

export default function EventParticipants() {
    // const { state } = useLocation();
    // const [user] = useUserAuth()
    const { eventID, categoryName } = useParams();
    console.log('eventID, categoryName', eventID, categoryName)
    // const { eventID, calledFrom, eventDetails, entryCount, uniqueParticipantDetails, participantDetails, participantCount, selCategory } = state;

    const [eventDetails, setEventDetails] = useState(null)
    const [eventCategory, setEventCategory] = useState(null)
    const [participantDetails, setParticipantDetails] = useState(null)
    const [uniqueParticipantDetails, setUniqueParticipantDetails] = useState(null)

    // const queryConstraintsRegistration = [
    //     where('EventID', '==', eventID),
    //     // orderBy('ParticipantName')
    //     // Add more query constraints as needed
    // ];
    const queryConstraints = [
        where('EventID', '==', eventID),
        // Add more query constraints as needed
    ];
    // console.log('queryConstraints : ', queryConstraints)
    const { document: eventDoc, error: eventDocError } = useDocument('EventList', eventID)
    const { documents: categoryDoc, error: categoryDocError } = useCollection('EventCategory', queryConstraints)
    const { documents: particinpationDocs, error: particinpationDocsError } = useCollection('EventRegistrationDetails', queryConstraints)


    // console.log('categoryDoc : eventID', eventID, categoryDoc, categoryDocError)
    const [loading, setLoading] = useState(false);
    const [pList, setPList] = useState([]);
    const [localParticipantDetails, setLocalParticipantDetails] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const navigate = useNavigate();
    // console.log('uniqueParticipantDetails', uniqueParticipantDetails);
    const [localUniqueParticipantDetails, setLocalUniqueParticipantDetails] = useState(null);
    const [searchKey, setSearchKey] = useState('');

    useEffect(() => {
        setEventDetails(eventDoc)
        setParticipantDetails(particinpationDocs)
        setEventCategory(categoryDoc)
        getPlayerList(selectedCategory);

    }, [eventDoc, particinpationDocs, categoryDoc, categoryName]);

    function getPlayerList(catName) {
        let pListObj = [];
        // console.log('getPlayerList : catName', catName, '::particinpationDocs:', particinpationDocs)
        particinpationDocs.forEach(entry => {
            // console.log('entry', entry)
            if (categoryName === 'All' || entry.CategoryName === categoryName) {
                // console.log('1 entry', catName)

                if (!pListObj.find(e => e.PlayerID === entry.ParticipantID)) {
                    // console.log(entry.ParticipantID, entry.ParticipantName)
                    pListObj.push({
                        PlayerID: entry.ParticipantID,
                        PlayerName: entry.ParticipantName
                    })
                }
                if (entry.PartnerPlayerID && entry.PartnerPlayerID !== '' && !pListObj.find(e => e.PlayerID === entry.PartnerPlayerID)) {
                    pListObj.push({
                        PlayerID: entry.PartnerPlayerID,
                        PlayerName: entry.PartnerPlayerName
                    })
                }
            }
        });
        console.log('particinpationDocs : ', particinpationDocs, ':: pListObj : ', pListObj)
        setUniqueParticipantDetails(sortPlayerList(pListObj))
        getPlayer(pListObj);

    }
    function sortPlayerList(playerlist) {
        console.log('playerlist', playerlist);
        playerlist && playerlist.sort((a, b) => {
            let fa = a.PlayerName && a.PlayerName.toLowerCase(),
                fb = b.PlayerName && b.PlayerName.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        return playerlist;
    }

    // const memoAlphaParticipant = useMemo(() => {
    function getPlayer(playerlist) {
        console.log('in getPlayer', playerlist)
        let objList = [];
        let firstChar = null;
        let charList = [];
        let index = -1;
        playerlist = playerlist && playerlist.length > 1 ? sortPlayerList(playerlist) : playerlist;
        playerlist && playerlist.length > 0 && playerlist.forEach(element => {
            if (element.PlayerName) {

                if (firstChar !== element.PlayerName.substring(0, 1)) {
                    index++;
                    charList.push(element);
                    objList[index] = {
                        firstCharector: element.PlayerName.substring(0, 1),
                        playerList: charList
                    };
                    firstChar = element.PlayerName.substring(0, 1);
                } else {
                    objList[index].playerList.push(element)

                }
            }
            charList = [];
        });
        console.log('objList', objList)
        setPList(objList);
    }

    // let firstChar = null;
    function callParticipantDetails(playerID) {
        console.log('playerID, playerUserID', playerID)

        navigate("/PlayerParticipation/" + eventID + "/" + playerID);

        // navigate("/PlayerParticipation", {
        //     state: {
        //         eventDetails: eventDetails,
        //         // entryCount: entryCount,
        //         playerID: playerID,
        //         uniqueParticipantDetails: uniqueParticipantDetails,
        //         participantDetails: participantDetails,
        //         // participantCount: participantCount,
        //         // playerUserID: playerUserID
        //     }
        // });

    }

    function onChangeEvent(option) {
        // setSearchKey("");
        // setSelectedCategory(option);
        // console.log('option', option)
        // getPlayerList(option)
        navigate("/EventParticipants/" + eventID + '/' + option)

    }

    function searchPlayer() {
        let lplayerList = [];
        if (searchKey !== "") {
            setSelectedCategory('All');
        }
        let lParticipantList = [];
        lplayerList = uniqueParticipantDetails.filter(e => e.PlayerName.toUpperCase().includes(searchKey.toUpperCase()));
        setLocalUniqueParticipantDetails(lParticipantList);
        getPlayer(lplayerList);

    }
    // console.log('UniqueParticipantDetails', uniqueParticipantDetails)
    return (
        <>
            {eventDoc && <EventDetailsMenu calledFrom='Participant'
                eventID={eventID}
                eventDetails={eventDoc}
            />}
            <div className="container-fluid">

                <div className="row no-gutters">

                    {/* {eventDetails && <EventDetailsMenu calledFrom='Entries'
                            eventID={eventDetails.Eventid}
                            eventDetails={eventDetails}
                            entryCount={entryCount}
                            uniqueParticipantDetails={uniqueParticipantDetails}
                            participantDetails={participantDetails}
                            participantCount={participantCount}
                        />} */}

                    <div className="col-lg-8 col-md-8 col-sm-12">

                        {/* {loading && <lottie-player src="https://assets10.lottiefiles.com/private_files/lf30_27H8l4.json" background="transparent" speed="1" loop autoplay></lottie-player>} */}

                        <div>
                            <div className="" style={{ position: 'relative', zIndex: '5' }}>

                                <div className='total-participants-heading-div'>

                                    <div className='total-participants-search' style={{ position: 'relative', top: '-22px' }}>
                                        <input type='text' placeholder='Search Player Name...' onChange={(e) => {
                                            setSearchKey(e.target.value)
                                            searchPlayer()
                                        }}
                                            value={searchKey} ></input>
                                        <span className="material-symbols-outlined" onClick={searchPlayer}>
                                            search
                                        </span>
                                    </div>

                                    <div className="row no-gutters" style={{ width: '40%', position: 'relative', padding: '20px 10px 10px 10px' }}>
                                        <select name="" style={{ padding: '8px 50px 8px 5px', background: '#fff' }} className="total-participants-select" id="" value={categoryName}
                                            onChange={(e) => { onChangeEvent(e.target.value) }} >
                                            <option value="All" >All</option>
                                            {eventCategory && eventCategory.map((cat) => {
                                                return <option value={cat.CategoryName} key={cat.CategoryName}>{cat.CategoryName}</option>

                                            })}

                                        </select>

                                        <span style={{ top: '30%' }} className="material-symbols-outlined total-participants-select-icon">
                                            tune
                                        </span>

                                    </div>

                                </div>

                                {selectedCategory !== 'All' && <EventCategoryParticipant
                                    selectedCategory={selectedCategory}
                                    eventDetails={eventDetails}
                                    // playerList={pList}
                                    localParticipantDetails={uniqueParticipantDetails}></EventCategoryParticipant>}

                                {/* {selectedCategory === 'All' && <div className="row no-gutters"> */}
                                {<div className="row no-gutters">


                                    {pList && pList.length > 0 && pList.map((playerList) => {
                                        // console.log('playerList', playerList);
                                        return <EventPartcipantCard key={playerList.firstCharector}
                                            pList={playerList}
                                            eventID={eventID} callParticipantDetails={callParticipantDetails} />
                                    })}
                                    {pList && pList.length === 0 && <span> No Participants</span>}
                                    {loading && <lottie-player src="https://assets10.lottiefiles.com/private_files/lf30_27H8l4.json" background="transparent" speed="1" loop autoplay></lottie-player>}

                                </div>}
                            </div>
                        </div>

                        <hr />
                    </div>

                    {eventDetails && <EDTournamentDetails IsEditEnabled={false}
                        eventDetails={eventDetails}
                        showRegistration={true}
                        // partcipantObj={participantDetails}
                        showRegistrationButton={true}
                    // rentryCount={participantDetails ? participantDetails.length : 0}
                    // isDrawAvailable={false}
                    />}

                    {/* {eventDetails && <EDTournamentDetails eventDetails={eventDetails} showRegistration={true} />} */}
                    {/* {eventDetails && <EDAboutEvent eventDetails={eventDetails} />} */}
                </div>
            </div >
            {/* <div className="container-fluid">
                <div className="row no-gutters">
                    {eventDetails && <EventDetailsMenu eventDetails={eventDetails}
                        calledFrom='Participant'
                        participantCount={setUniqueParticipantDetails.length}
                        participantDetails={uniqueParticipantDetails}
                        isLoading={loading} />}
                    {eventDetails && <EDTournamentDetails eventDetails={eventDetails} showRegistration={true} />}
                </div>
            </div> */}
        </>
    )
}
