import React from 'react'
import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import EventDetailsMenu from './EventDetailsMenu'
import EDTournamentDetails from './EDTournamentDetails'
import '../css/EventDetails.css'
import Loading from './Loading'
// import ExportExcelComp from './ExportExcelComp.js';
// import e from 'express';

import { useParams } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument.js';
import { useCollection } from '../hooks/useCollection.js';
import { where, orderBy } from 'firebase/firestore';
export default function EventEntries() {
    // const { state } = useLocation();
    // const { calledFrom, eventDetails, entryCount, uniqueParticipantDetails, participantDetails, participantCount } = state;
    const { eventID } = useParams()

    const [isDrawAvailable, setIsDrawAvailable] = useState(false)
    const queryConstraintsRegistration = [
        where('EventID', '==', eventID),
        orderBy('ParticipantName')
        // Add more query constraints as needed
    ];


    const queryConstraints = [
        where('EventID', '==', eventID),
        // Add more query constraints as needed
    ];
    const queryConstraintsCategory = [
        where('EventID', '==', eventID),
        orderBy('Gender'),
        orderBy('EventType'),
        orderBy('CategoryName'),
        // Add more query constraints as needed
    ];

    // console.log('1 queryConstraints : ', queryConstraintsRegistration)
    const { documents: registrationDoc, error: registrationDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistration)
    const { documents: categoryDocs, error: categoryDocError } = useCollection('EventCategory', queryConstraintsCategory)
    const { document: eventDetails, error: eventDocError } = useDocument('EventList', eventID)
    const { documents: matchKnockoutDocs, error: matchKnockoutDocError } = useCollection('TX_MatchDetails', queryConstraints)
    const { documents: matchLeagueDocs, error: matchLeagueDocError } = useCollection('TX_MatchDetails', queryConstraints)

    //const [entryCount, setEntryCount] = useState(0);
    const [entryCountDetails, setEntryCountDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [categoryList, setCategoryList] = useState(null);
    // console.log('eventDetails : ', eventDetails, '::eventID:', eventDetails.Eventid);
    useEffect(() => {
        // setLoading(true);
        if (matchKnockoutDocs && matchKnockoutDocs.length > 0) {
            setIsDrawAvailable(true)
        }
        if (matchLeagueDocs && matchLeagueDocs.length > 0) {
            setIsDrawAvailable(true)
        }
        let regObj = null;
        let catList = [];
        let cnt = 1;
        categoryDocs.forEach(element => {
            regObj = registrationDoc.filter(e => e.CategoryName === element.CategoryName)
            catList.push({
                ...element,
                SNo: cnt,
                EntryCount: regObj ? regObj.length : 0
            })
            cnt = cnt + 1
        });
        setCategoryList(catList);

        // fetchData();
    }, [categoryDocs, registrationDoc]);

    // var para1 = {};
    // async function fetchData() {
    //     para1 = {
    //         EventID: eventID
    //     };
    //     var eventCnt = [];
    //     console.log('para1', para1)
    //     const ret1 = httpsCallable(functions, "getEventsEntryCount");
    //     ret1(para1).then((result) => {
    //         var cnt = 1;
    //         var cntEntry = 0;
    //         var ele = {};
    //         if (categoryDocs) {
    //             categoryDocs.forEach(element => {
    //                 var evtIndex = result.data.findIndex(e => e.CategoryName === element.CategoryName);
    //                 if (evtIndex >= 0) {
    //                     ele = {
    //                         SNo: cnt,
    //                         CategoryName: element.CategoryName,
    //                         EntryCount: result.data[evtIndex].EntryCount
    //                     }
    //                     cntEntry = cntEntry + Number(ele.EntryCount);
    //                     eventCnt.push(ele);
    //                 }
    //                 else {
    //                     ele = {
    //                         SNo: cnt,
    //                         CategoryName: element.CategoryName,
    //                         EntryCount: 0
    //                     }

    //                     eventCnt.push(ele);
    //                 }
    //                 cnt = cnt + 1;
    //             });
    //             // setEntryCount(cntEntry);

    //         }
    //         setEntryCountDetails(eventCnt);
    //         // console.log(eventDetails);
    //         setLoading(false);
    //         //   window.localStorage.setItem("EventDetails", JSON.stringify(result.data));
    //     });
    // }
    // const entryCountDetails = useMemo(() => {


    return (
        <>
            {eventDetails && <EventDetailsMenu calledFrom='Entries'
                eventDetails={eventDetails}
                eventID={eventID}
            />}
            <div className="container-fluid">

                <div className="row no-gutters">

                    {/* {eventDetails && <EventDetailsMenu calledFrom='Entries'
                            eventID={eventDetails.Eventid}
                            eventDetails={eventDetails}
                            entryCount={entryCount}
                            uniqueParticipantDetails={uniqueParticipantDetails}
                            participantDetails={participantDetails}
                            participantCount={participantCount}
                        />} */}
                    <div className="col-lg-8 col-md-8 col-sm-12">

                        <div className="" style={{ textAlign: 'right', position: 'relative', zIndex: '5' }}>
                            <br />
                            {/* {eventDetails && true && <ExportExcelComp data={entryCountDetails} fileName={'Entrysummary'} buttonName='Export Entry' />} */}

                            <table className="content-table" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Category</th>

                                        <th>Entries</th>
                                    </tr>
                                </thead>
                                <tbody id="eventCategoryDetails">
                                    {categoryList && categoryList.map((category) => (

                                        <tr key={category.CategoryName}>
                                            <td>{category.SNo}</td>
                                            <td>{category.CategoryName}</td>
                                            <td>{category.EntryCount === 0 ? category.EntryCount :
                                                eventDetails.ShowParticipantFlag === 'YES' ?
                                                    <Link to={"/EventParticipants/" + eventID + "/" + category.CategoryName}

                                                    >{category.EntryCount}</Link> :
                                                    category.EntryCount
                                            }

                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {/* {loading && <lottie-player src="https://assets10.lottiefiles.com/private_files/lf30_27H8l4.json" background="transparent" speed="1" loop autoplay></lottie-player>} */}
                            {loading && <Loading ></Loading>}

                        </div>


                    </div>

                    {eventDetails && <EDTournamentDetails
                        // IsEditEnabled={isEditEnabled}
                        IsEditEnabled={false}
                        eventDetails={eventDetails}
                        showRegistration={true}
                        showRegistrationButton={true}
                    // partcipantObj={registrationDoc}
                    // rentryCount={registrationDoc ? registrationDoc.length : 0}
                    // isDrawAvailable={isDrawAvailable}
                    // isDrawAvailable={isDrawAvailable}
                    />}
                    {/* {eventDetails && <EDTournamentDetails eventDetails={eventDetails} showRegistration={true} />} */}
                    {/* {eventDetails && <EDAboutEvent eventDetails={eventDetails} />} */}
                </div>
            </div >
            {/* <div className="container-fluid">

            <div className="row no-gutters">

                {eventDetails && <EventDetailsMenu eventDetails={eventDetails}
                    calledFrom='Entries'
                    entryCount={entryCount}
                    entryCountDetails={entryCountDetails}
                    isLoading={loading} />}

                {eventDetails && <EDTournamentDetails eventDetails={eventDetails} showRegistration={true} />}

            </div>
        </div> */}
        </>

    )
}
