import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import UserProfileCard from './UserProfileCard';
import EDTournamentDetails from './EDTournamentDetails'
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
// import $ from "jquery";
import '../css/EventRegistration.css'
import DatePicker from "react-datepicker";
import '../css/MydatePicker.css'
import "react-datepicker/dist/react-datepicker.css";
import { useLocalStorage } from "../context/useLocalStorage";
import NewMember from './NewMember';
import SignIn from './SignIn.js';
import { useCollection } from '../hooks/useCollection.js';
import { useDocument } from '../hooks/useDocument.js';
import { where, orderBy } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import EventDetailsMenu from './EventDetailsMenu'

import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
export default function RegisteredProfile() {
    const { users, user } = useUserAuth();
    const { eventID } = useParams();
    const [showLoading, setShowLoading] = useState(false);
    const [addNewFlag, setAddNewFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);
    const [participantList, setParticipantList] = useState(null);
    const selectedPlayer = '';
    const [signinFlag, setSignInFlag] = useState(false);
    // const [registeredProfile, setRegisteredProfile] = useState(null);

    const [isNewUser, setIsNewUser] = useState(false);
    const [returnMessage, setReturnMessage] = useState('');

    const [playerDetailsCopy, setPlayerDetailsCopy] = useState({
        pID: '',
        City: '',
        CollageName: '',
        CompanyName: '',
        CompanyAddress: '',
        Country: '',
        DateOfBirth: new Date(),
        District: '',
        Email: user && user.userInfo ? user.userInfo.Email : '',
        Gender: 'Male',
        Grade: 'I',
        HRContact: '',
        ParticipantID: '',
        ParticipantAddress: '',
        Phone: user && user.userInfo ? user.userInfo.Phone : '',
        Pincode: '',
        SchoolAddress: '',
        Size: 'XS',
        State: '',
        UserName: '',
        Identity: '',
        UserID: user && user.userInfo ? user.userInfo.uid : '',
        MemberIDType: '',
        MemberIDNumber: '',
        PlayerID: '',
        SelectedRole: 'PARTICIPANT',
        AdditonalPhone: users && users.current && users.current.phoneNumber,
        PhotoImgURL: '',
    });


    const queryConstraintsRegistration = [
        where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ''),
        orderBy('ParticipantName')
        // Add more query constraints as needed
    ];

    // console.log("user.userInfo", user.userInfo)
    const queryConstraintsPlayer = [
        where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ''),
        // orderBy('UserName')
        // Add more query constraints as needed
    ];

    console.log('queryConstraints : ', queryConstraintsPlayer, 'user.userInfo', user.userInfo)
    const { document: eventDoc, error: eventDocError } = useDocument('EventList', eventID)
    const { documents: registrationDoc, error: registrationDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistration)
    const { documents: playerListDoc, error: playerListDocError } = useCollection('Participants', queryConstraintsPlayer)

    console.log("user.userInfo", user.userInfo, "playerListDoc : ", playerListDoc, ':: playerListDocError : ', playerListDocError);
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    useEffect(() => {
        // console.log("user : ", user);
        // console.log('userDetails : ', userDetails);
        if (!isNewUser) {

            if (user.isLoggedIn) {
                if (user.userInfo) {
                }
                // console.log('userDetails', userDetails);
                // user && user.userInfo && setRegisteredProfile(playerListDoc);
                // setParticipantList(registrationDoc)
                setParticipantList(playerListDoc)
                // setParticipantListC(playerListDoc)

                setEventDetails(eventDoc)
                setSignInFlag(true);
            }
            else {
                setSignInFlag(false);
            }
        }

    }, [user, registrationDoc, eventDoc, playerListDoc])
    function regProfileToSecondSlide(e) {
        // e.preventDefault();
        // setShowUserBasicDetails(true);
        setAddNewFlag(true);
        // setFlag('second');

    }

    function setSignIn(flag, userData) {
        setSignInFlag(flag);
        // setUserDetails(userData);
    }

    function addNewMember(flag, playercode) {
        console.log('in Refistere file : addNewMember', flag);
        setAddNewFlag(flag, playercode);
        // fetchData();


    }
    function selectID(e) {
        setPlayerDetailsCopy({
            ...playerDetailsCopy,
            MemberIDType: e.target.value
        });
    }
    function onChangeValueMale(checked) {
        checked && setPlayerDetailsCopy({
            ...playerDetailsCopy,
            Gender: 'Male'
        });
        !checked && setPlayerDetailsCopy({
            ...playerDetailsCopy,
            Gender: 'Female'
        });
    }

    function onChangeValueFemale(checked) {

        checked && setPlayerDetailsCopy({
            ...playerDetailsCopy,
            Gender: 'Female'
        });
        !checked && setPlayerDetailsCopy({
            ...playerDetailsCopy,
            Gender: 'Male'
        });
    }

    async function regSaveDetails(e) {
        console.log(' in props.selectedPlayer', playerDetailsCopy, selectedPlayer)
        // console.log('in regSaveDetails , phone : ', user.userInfo.phoneNumber)
        // console.log(' in regSaveDetails, playerDetailsCopy', playerDetailsCopy)
        if (playerDetailsCopy.UserName === '' || playerDetailsCopy.DateOfBirth === '' || playerDetailsCopy.Gender === '') {
            setReturnMessage('Please enter User Name, Date of Birth and Genter to insert / update !!')

            console.log('in if')

            setTimeout(function () {
                setReturnMessage('');
            }, 5000);
        } else {
            console.log('in else')
            var para = {};
            para = {
                UserID: user.userInfo.uid,
                pID: playerDetailsCopy.pID ? playerDetailsCopy.pID : "",
                City: playerDetailsCopy.City,
                CollageName: playerDetailsCopy.CollageName,
                CompanyName: playerDetailsCopy.CompanyName,
                CompanyAddress: playerDetailsCopy.CompanyAddress,
                Country: playerDetailsCopy.Country,
                DateOfBirth: playerDetailsCopy.DateOfBirth,
                District: playerDetailsCopy.District,
                Email: playerDetailsCopy.Email,
                Gender: playerDetailsCopy.Gender,
                Grade: playerDetailsCopy.Grade,
                Identity: playerDetailsCopy.Identity,
                HRContact: playerDetailsCopy.HRContact,
                ParticipantID: playerDetailsCopy.ParticipantID,
                ParticipantAddress: playerDetailsCopy.ParticipantAddress,
                Phone: user.userInfo.phoneNumber,
                Pincode: playerDetailsCopy.Pincode,
                SchoolAddress: playerDetailsCopy.SchoolAddress,
                Size: playerDetailsCopy.Size,
                State: playerDetailsCopy.State,
                UserName: playerDetailsCopy.UserName,
                MemberIDType: playerDetailsCopy.MemberIDType,
                MemberIDNumber: playerDetailsCopy.MemberIDNumber,
                PlayerID: playerDetailsCopy.PlayerID,
                SelectedRole: playerDetailsCopy.SelectedRole,
                PhotoImgURL: playerDetailsCopy.PhotoImgURL,
                AdditonalPhone: playerDetailsCopy.AdditonalPhone,
            };

            setPlayerDetailsCopy({
                pID: '',
                City: '',
                DateOfBirth: new Date(),
                Email: user && user.userInfo ? user.userInfo.Email : '',
                Gender: 'Male',
                ParticipantAddress: '',
                Phone: user && user.userInfo ? user.userInfo.Phone : '',
                UserName: '',
                UserID: user && user.userInfo ? user.userInfo.uid : '',
                MemberIDType: '',
                MemberIDNumber: '',
            });

            // console.log(para);
            if (selectedPlayer !== '') {
                // console.log('in true')
                setLoading(true);

                const ret = await httpsCallable(functions, "updateParticipants");
                ret(para).then(async (result) => {
                    console.log('result 1', result);
                    setReturnMessage('Participant Updated Successfully !!')
                    setTimeout(function () {
                        setReturnMessage('');
                        addNewMember(false, selectedPlayer);
                    }, 5000);
                    // props.addNewMember(false, playerDetailsCopy.PlayerID);
                    setLoading(false);
                    setAddNewFlag(false)
                    // window.localStorage.setItem("userProfile", JSON.stringify(playerDetailsCopy));
                    // console.log("From Function " + result.data);
                });
            } else {
                // console.log('in false')
                setLoading(true);
                // console.log('para : ', para);
                const ret = await httpsCallable(functions, "createNewUser");
                ret(para).then(async (result) => {
                    console.log('result 2', result);
                    if (result && result.data && result.data.ParticipantID === 0) {
                        setReturnMessage(result.data.Error)
                        setTimeout(function () {
                            setReturnMessage('');
                        }, 5000);

                    } else {
                        setReturnMessage('Participant Added Successfully !!')

                        setTimeout(function () {
                            setReturnMessage('');
                        }, 5000);

                        // await GetUserDetails(user.uid).then((data1) => {

                        // });

                    }
                    setLoading(false);
                    setAddNewFlag(false)
                    // window.localStorage.setItem("userProfile", JSON.stringify(playerDetailsCopy));
                    // console.log("From Function " + result.data);
                });

            }
        }
    }
    let newArray = users && users.current && users.current.phoneNumber && users.current.phoneNumber.replace('+', '').match(/^(91|)?(\d{3})(\d{3})(\d{4})$/)
    // console.log('ParticipantList', participantList)
    return (
        <>
            {user && !user.userInfo && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>}

            {eventDoc && <EventDetailsMenu calledFrom='Registration'

                eventID={eventID}
                eventDetails={eventDoc}
            // entryCount={entryCount}
            // uniqueParticipantDetails={partcipantObj.uniqueParticipantDetails}
            // participantDetails={partcipantObj.participantDetails}
            // participantCount={partcipantObj.participantCount}
            />}

            <div className={addNewFlag ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner' style={{ maxWidth: '1200px' }}>

                    <h1 style={{ color: '#348DCB', fontWeight: 'bold' }}>New Member</h1>
                    <hr className='large' style={{ marginBottom: '0' }} />

                    <div className='row no-gutters' style={{ padding: '0px 20px' }}>
                        <div className='col-lg-6 col-md-6 col-sm-12' style={{ padding: '0px 15px 15px 15px' }}>

                            <h1 style={{ textAlign: 'left', color: '#348DCB', fontSize: '0.9rem', fontWeight: 'bold', paddingLeft: '20px' }}>Gender</h1>
                            <div className="row no-gutters">

                                <div className="col-6" >

                                    <input type="radio" className="checkbox" style={{ width: '0px' }}
                                        name="EventStatus" id="regParticipantMale" value="Male"
                                        checked={playerDetailsCopy.Gender === 'Male' ? true : false} onChange={(e) => {
                                            e.target.checked && onChangeValueMale(e.target.checked);
                                        }}
                                    />
                                    <label style={{ height: '40px', border: '1px solid #ddd' }}
                                        className="checkbox-label" id="regParticipantMaleLabel"
                                        htmlFor="regParticipantMale">
                                        <i className="fas fa-plus"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        <i className="fas fa-check"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        <span>Male</span>
                                    </label>

                                </div>

                                <div className="col-6">
                                    <input type="radio" className="checkbox" style={{ width: '0px' }}
                                        name="EventStatus" id="regParticipantFemale" value="Female"
                                        checked={playerDetailsCopy.Gender === 'Female' ? true : false} onChange={(e) => {
                                            e.target.checked && onChangeValueFemale(e.target.checked);
                                        }} />
                                    <label style={{ height: '40px', border: '1px solid #ddd' }}
                                        className="checkbox-label" id="regParticipantFemaleLabel"
                                        htmlFor="regParticipantFemale">
                                        <i className="fas fa-plus"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        <i className="fas fa-check"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        <span>Female</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='date-picker-add-member'>
                                <DatePicker
                                    showMonthDropdown
                                    showYearDropdown
                                    dateFormat="dd-MMM-yyyy"
                                    selected={playerDetailsCopy.DateOfBirth}
                                    onChange={(date) => {
                                        // console.log(date)
                                        setPlayerDetailsCopy({
                                            ...playerDetailsCopy,
                                            DateOfBirth: new Date(date)
                                        })
                                    }}
                                />
                                <span style={{
                                    position: 'absolute',
                                    top: '5px',
                                    left: '12px',
                                    color: '#348DCB',
                                    fontSize: '0.9rem',
                                    fontWeight: 'bold',
                                    background: '#fff',
                                    padding: '0 8px'
                                }}>Date of birth</span>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className="reg-participant-form-field">
                                <input type="text" required onChange={(e) => {
                                    setPlayerDetailsCopy({
                                        ...playerDetailsCopy,
                                        UserName: e.target.value
                                    });

                                }} value={playerDetailsCopy && playerDetailsCopy.UserName} />
                                <span>Name</span>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className="reg-participant-form-field update-member-name-input">
                                <input type="text" required
                                    onChange={(e) => {
                                        setPlayerDetailsCopy({
                                            ...playerDetailsCopy,
                                            AdditonalPhone: e.target.value
                                        });
                                    }}
                                    value={playerDetailsCopy.AdditonalPhone ? playerDetailsCopy.AdditonalPhone : users && users.current && users.current.phoneNumber}
                                />
                                <span>Mobile Number</span>
                            </div>
                        </div>
                        {/* <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className="reg-participant-form-field">
                                <input type="text" required onChange={(e) => {
                                    setPlayerDetailsCopy({
                                        ...playerDetailsCopy,
                                        ParticipantAddress: e.target.value
                                    })
                                }} value={playerDetailsCopy.ParticipantAddress} />
                                <span>Participnt Address</span>
                            </div>
                        </div> */}
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className="reg-participant-form-field">
                                <input type="text" required onChange={(e) => {

                                    setPlayerDetailsCopy({
                                        ...playerDetailsCopy,
                                        City: e.target.value
                                    })

                                }} value={playerDetailsCopy.City} />
                                <span >City</span>
                            </div>
                        </div>
                        {/* <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className="reg-participant-form-field">
                                <select name="" value={playerDetailsCopy.MemberIDType} onChange={selectID}>
                                    <option value="StateID">State player ID</option>
                                    <option value="NationalID">National Player ID</option>
                                    <option value="InternationID">international Player ID</option>
                                    <option value="Other">Other</option>
                                </select>
                                <span className="already-active">Player ID Type</span>
                            </div>
                        </div> */}
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className="reg-participant-form-field">
                                <input type="text" required onChange={(e) => {
                                    setPlayerDetailsCopy({
                                        ...playerDetailsCopy,
                                        MemberIDNumber: e.target.value
                                    })

                                }} value={playerDetailsCopy.MemberIDNumber} />
                                <span >Player ID (State ID/ National ID/ International ID) </span>
                            </div>
                        </div>
                    </div>
                    <br className='large' />
                    <hr />
                    {returnMessage !== '' && <div className='row no-gutters' style={{ padding: '0 20px' }} >
                        <div className='col-12'>
                            <Alert variant='danger'>{returnMessage}</Alert>
                        </div>

                    </div>}
                    <div className='row no-gutters' style={{ padding: '0 20px' }} onClick={(e) => regSaveDetails(e)}>
                        <div className='col-6'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => setAddNewFlag(false)}>
                                <button className='mybutton button5' style={{ fontSize: '1rem', width: '100%', maxWidth: '300px', background: '#aaa' }}>Cancel</button>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <button className='mybutton button5' style={{ fontSize: '1rem', width: '100%', maxWidth: '300px' }}>Save</button>
                            </div>
                            <div className='btn-loading' style={{ display: loading ? 'block' : 'none' }}>
                                <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                    speed="0.7" loop autoplay></lottie-player>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
            {/* {user && !user.userInfo && <div className="container-fluid">
                <div className="row no-gutters">

                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <br />
                        <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>
                    </div>
                </div>
            </div>}
            <br /> */}

            <div className="container-fluid">
                <div className="row no-gutters">

                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <br />
                        {/* {user && !user.userInfo && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>} */}
                        {/* {addNewFlag && <div id="regProfileNewParticipantDetails">
                            <h2 className="reg-form-email" id="userContact">+{newArray && newArray.length >= 5 ? +newArray[1] + '-' + newArray[2] + '-' + newArray[3] + '-' + newArray[4] : ''}</h2>

                            <h5 className="reg-form-email male" id="userGender">{user && user.userInfo ? user.userInfo.Gender : ''}</h5>
                            <h6 className="reg-form-email" id="userLocation">{user && user.userInfo ? user.userInfo.City : ''}</h6>
                            <NewMember selectedPlayer={selectedPlayer} addNewMember={addNewMember} showBack={true}></NewMember>

                            <br /><br />

                        </div>} */}


                        {user && user.userInfo && <div className="reg-participant-divs" id="regProfileFirstSlide"  >

                            <h3 style={{ fontWeight: '1000', color: '#348DCB', textAlign: 'center' }}>Members</h3>

                            <div className="row no-gutters" id="divParticipant">

                                <div className="col-lg-4 col-md-6 col-sm-12" style={{
                                    padding: '0'
                                }}>
                                    <div style={{ padding: '10px' }}>
                                        < div className="event-registration-participant-card add-paticipant-card"
                                            onClick={regProfileToSecondSlide}>
                                            <span className="material-symbols-outlined">
                                                add
                                            </span>
                                            <h1>ADD NEW</h1>
                                        </div>
                                    </div>
                                </div>

                                {showLoading && <div className="col-lg-4 col-md-6 col-sm-12">
                                    <lottie-player src="https://lottie.host/35ed7cc5-900e-420b-95d1-cb90642020e7/UV7Rv7AbhO.json" background="transparent" speed="1" style={{ width: '100%', height: '100%' }} loop autoplay></lottie-player>
                                </div>}

                                {playerListDoc && playerListDoc.map((participant) => {
                                    console.log('participant :', participant)
                                    return <UserProfileCard key={participant.id}
                                        isEdit={false}
                                        playerID={participant.id}
                                        participantDetails={participant}
                                        eventID={eventID}
                                        calledFrom="RegisteredProfile"></UserProfileCard>
                                })}

                            </div><br />
                            <hr style={{ border: 'none', borderTop: '1px solid #aaa' }} />

                        </div>}

                        {/* <div className="reg-participant-divs" id="regProfileSecondSlide">

                                    <NewMember selectedPlayer={selectedPlayer} addNewMember={addNewMember}></NewMember>

                                </div> */}

                        {/* </div> */}
                        {/* </div> */}

                    </div>
                    {/* {console.log('eventDetails', eventDetails)} */}
                    {/* {eventDetails && <EDTournamentDetails eventDetails={eventDetails} showRegistration={false} />} */}

                    {eventDetails && <EDTournamentDetails IsEditEnabled={false}
                        eventDetails={eventDetails}
                        showRegistration={false}
                        showRegistrationButton={false}
                    // partcipantObj={participantList}
                    // rentryCount={participantList ? participantList.length : 0}
                    // isDrawAvailable={false} 
                    />}

                    <br />
                </div>
            </div>
            <br /><br /><br />

        </>
    )
}
