// useFirestoreUpdateDocument.js
import { useState } from 'react';
import { doc, updateDoc, deleteDoc, addDoc, collection } from 'firebase/firestore';
import { projectFirestore } from '../firebase';

const useDocumentUpdate = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateDocument = async (collectionName, docId, updatedData) => {
        setLoading(true);
        setError(null);

        const docRef = doc(projectFirestore, collectionName, docId);

        try {
            await updateDoc(docRef, updatedData);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };


    const deleteDocument = async (collectionName, id) => {
        try {
            const docRef = doc(projectFirestore, collectionName, id);
            await deleteDoc(docRef);
            console.log('Document successfully deleted!');
        } catch (error) {
            console.error('Error deleting document: ', error);
        }
    };

    const addDocument = async (collectionName, addData) => {
        try {
            const docRef = await addDoc(collection(projectFirestore, collectionName), addData);
            console.log('Document added with ID: ', collectionName, docRef.id);
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };
    return { updateDocument, deleteDocument, addDocument, loading, error };
};

export default useDocumentUpdate;
