import React, { useState } from 'react';
import '../css/NewEventCard.css'
import { useLocation } from 'react-router-dom';


import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'
// import { useUserAuth } from '../context/UserAuthcontext';
// import { useLocalStorage } from "../context/useLocalStorage";


export default function PGEventPendingPayment() {
    const { state } = useLocation();
    const { eventList } = state;

    // const navigate = useNavigate();
    const [eventStatus, setEventStatus] = useState('');
    const [remarksDetails, setRemarksDetails] = useState({
        Eventid: '',
        remarks: '',
        amount: 2360
    });
    console.log('eventList', eventList)
    async function eventStatusChanged(eventID, status, remarks, amount) {
        console.log('in eventStatusChanged', eventID, status, remarks, amount)
        var para1 = {};

        para1 = {
            EventID: eventID,
            PaymentStatus: status,
            PaymentRemarks: remarks,
            PaymentAmount: Number(amount),
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventDetails_PaymentReconcilation");
        ret(para1).then(async (result) => {
            //     // setEventStatus(leventStatus)

        })
    }

    return (

        <div style={{ background: '#fff', borderRadius: '8px', padding: '10px' }}>
            <div className='row no-gutters'>
                <div className="col-12 event-label-div">
                    <strong>
                        Events onboarding pending payments (5)
                    </strong>
                </div>
            </div>
            <div className='row no-gutters'>


                <div className='col-lg-4 col-md-6 col-sm-12'>

                    {eventList.map((event) => {
                        return <div className='new-event-card' key={event.Eventid}>

                            <div  >

                                <div className='new-event-card-details' >
                                    <div className='new-event-card-details-name'>
                                        {/* {console.log('userDetails, userDetails', userDetails)} */}
                                        <h2>{event.EventName}</h2>

                                        <div className='new-event-card-details-date'>
                                            <div>
                                                <h4>{event.City}</h4>
                                                <h5>Location</h5>
                                            </div>
                                            <div>
                                                <h4>{event.EventStartDate && event.EventStartDate._seconds ? (new Date(event.EventStartDate._seconds * 1000)).toISOString().split("T")[0] : (new Date()).toISOString().split("T")[0]}</h4>
                                                <h5>Starting Date</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='new-event-card-details-name'>
                                        <div className='new-event-card-details-date'>
                                            <div>

                                                <h4><input type="number"
                                                    style={{ width: '100%', alignContent: 'center' }}
                                                    value={remarksDetails.Eventid === event.Eventid ? remarksDetails.amount : 2360}
                                                    readOnly={remarksDetails.Eventid === event.Eventid && remarksDetails.remarks !== '' ? false : true}
                                                    onChange={(e) => {
                                                        console.log('remarksDetails : ', remarksDetails)
                                                        setRemarksDetails({
                                                            ...remarksDetails,
                                                            Eventid: event.Eventid,
                                                            amount: e.target.value
                                                        })
                                                    }}></input></h4>
                                                <h5>Registration Amount</h5>
                                            </div>

                                            <div>

                                                <h4><input type="text"
                                                    style={{ width: '100%', alignContent: 'center' }}
                                                    value={remarksDetails.Eventid === event.Eventid ? remarksDetails.remarks : ''}
                                                    onChange={(e) => {
                                                        console.log('remarksDetails ', remarksDetails)
                                                        setRemarksDetails({
                                                            ...remarksDetails,
                                                            Eventid: event.Eventid,
                                                            amount: remarksDetails.amount ? remarksDetails.amount : 2360,
                                                            remarks: e.target.value
                                                        })
                                                    }}></input></h4>
                                                <h5>Remarks for Payment</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {event.Eventid === remarksDetails.Eventid && remarksDetails.remarks !== '' && <button className='mybutton button5'

                                            onClick={() => {
                                                console.log('remarksDetails : ', remarksDetails)
                                                eventStatusChanged(event.Eventid, 'Completed', remarksDetails.remarks, remarksDetails.amount)
                                            }}>Mark Payment Completed</button>}
                                    </div>

                                </div>
                            </div>



                        </div>

                    })
                    }

                </div>
            </div>
        </div >
    )
}
