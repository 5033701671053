import '../src/App.css';

import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import BeforeNavbar from './components/BeforeNavbar';

import Navbar from './components/Navbar';
import NavbarMobile from './components/NavbarMobile';
import BottomBar from './components/BottomBar';
import HomePage from './components/HomePage';
import HPGenere from './components/HPGenere'
import HPGrowWithUs from './components/HPGrowWithUs';
import HPGameSection from './components/HPGameSection';
import PartnerSection from './components/PartnerSection';
import Footer from './components/Footer';
import Events from './components/Events';
import EventDetails from './components/EventDetails';
import EventEntries from './components/EventEntries';
import EventParticipants from './components/EventParticipants';
import EventCategory from './components/EventCategory';
import ContactUs from './components/ContactUs';
import More from './components/More';
import FAQ from './components/FAQ';
import UserGuide from './components/UserGuide';
import Calculators from './components/Calculators';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundAndCancellation from './components/RefundAndCancellation';
import Location from './components/Location';
import Clubs from './components/Clubs';
import AboutUs from './components/AboutUs';
import TermsAndConditions from './components/TermsAndConditions';
import EventDetailsLink from './components/EventDetailsLink';
import PlayerParticipation from './components/PlayerParticipation';
import Profile from './components/Profile';
import PhoneSignUp from './components/PhoneSignUp';
import RegisteredProfile from './components/RegisteredProfile';
import ManageParticipant from './components/ManageParticipant';
import RegistrationCategory from './components/RegistrationCategory';
import RegistrationCheckout from './components/RegistrationCheckout';
import PaymentSuccessful from './components/PaymentSuccessful';
import PaymentFailure from './components/PaymentFailure';
import PaymentSuccess from './components/PaymentSuccess';
import UpdateEvent from './components/UpdateEvent';
import EventList from './components/EventList'
import EditEvent from './components/EditEvent'
import EditProfile from './components/EditProfile'
import EditOrganization from './components/EditOrganization'
import UserList from './components/UserList'
import TournamentBracket from './components/TournamentBracket';
import TournamentDraw from './components/TournamentDraw';
import Knockout from './components/Knockout';
import SingleElimination from './components/SingleElimination';
import AddEvent from './components/AddEvent';
import ShowDraw from './components/ShowDraw';
import MatchScheduleSetup from './components/MatchScheduleSetup';
import { UserAuthContextProvider } from './context/UserAuthcontext';
import PGDashboard from './pages/PGDashboard';
import PGDashboardOrganizer from './pages/PGDashboardOrganizer';
import PGCreateEvent from './pages/PGCreateEvent';
import PGCreateEventPayment from './pages/PGCreateEventPayment';
import PGEventPendingPayment from './pages/PGEventPendingPayment';
import WhatsAppIcon from './components/WhatsAppIcon';
import RegisteredUserProfile from './components/RegisteredUserProfile';
import MyOrder from './components/MyOrder';
import Orders from './components/Orders';
import OrderHistory from './components/OrderHistory';
import Notification from './components/Notification';
import ListYourEvent from './components/ListYourEvent';

function App() {
  // const [showFlag, setShowFlag] = useState(true);
  // const participants = ['Team 1', 'Team 2', 'Team 3', 'Team 4', 'Team 5', 'Team 6', 'Team 7', 'Team 8'];
  // const teams = ['Team 1', 'Team 2', 'Team 3', 'Team 4', 'Team 5', 'Team 6', 'Team 7', 'Team 8'];
  // const treeData = [
  //   {
  //     id: 1,
  //     name: 'Node 1',
  //     children: [
  //       {
  //         id: 2,
  //         name: 'Node 1.1',
  //         children: [
  //           {
  //             id: 3,
  //             name: 'Node 1.1.1',
  //           },
  //           {
  //             id: 4,
  //             name: 'Node 1.1.2',
  //           },
  //         ],
  //       },
  //       {
  //         id: 5,
  //         name: 'Node 1.2',
  //       },
  //     ],
  //   },
  // ];
  const [city, setCity] = useState(window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
    'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', ''));
  function setMyCity(selCity) {
    setCity(selCity);
  }
  const [myEvent, setMyEvent] = useState({
    eventDetails: null,
    entryCount: 0,
    participantCount: 0
  })

  useEffect(() => {
    // console.log(window.location.pathname);
    // if (window.location.pathname === '/PhoneSignUp' || window.location.pathname === '/UserProfile') {
    //   // setShowFlag(false);
    // }
    setCity(window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
      'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', ''));
    // console.log('city', city);
  }, [])

  function updateMyEvent(eventDetails, cntEntry, cntPartcipant) {
    setMyEvent({
      eventDetails: eventDetails,
      entryCount: cntEntry,
      participantCount: cntPartcipant
    });
  }

  function setJSON(json) {
    console.log('json : ', json)
  }
  // console.log('window.location.pathname', window.location.pathname);
  return (
    <>
      {/* <BeforeNavbar city={city} /> */}
      <UserAuthContextProvider>
        {/* <a href={`tel:+918484966096`} > */}
        <Routes>
          <Route exact path='/' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/Event' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/Dashboard' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/More' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/Location' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/Profile' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/clubs' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/ContactUs' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/EventDetails/:eventID' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/EventEntries/:eventID' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/EventEntries/:eventID' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/EventParticipants/:eventID/:categoryName' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/EventCategory/:eventID' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/PlayerParticipation/:eventID/:playerID' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/FAQ' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/UserGuide' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/Calculators' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/PrivacyPolicy' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/RefundAndCancellation' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/AboutUs' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/TermsAndConditions' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/ManageParticipant' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/EventList' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/UpdateEvent/:action' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/AddEvent' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/CreateEvent' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/EditEvent' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/UserList' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/EditProfile' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/PGCreateEventPayment' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/PGEventPendingPayment' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/OrganizerDashboard' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/MatchScheduleSetup' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/SingleElimination' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/MyOrder' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/Orders' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/OrderHistory' element={<WhatsAppIcon></WhatsAppIcon>} />
          <Route exact path='/ShowDraw/:eventID/:showMenu' element={<WhatsAppIcon></WhatsAppIcon>} />

        </Routes>
        {/* </a> */}
        <Routes>
          <Route exact path='/More' element={<Navbar isFlag={'more'} />} />
          <Route exact path='/Event' element={<Navbar isFlag={'event'} />} />
          <Route exact path='/clubs' element={<Navbar isFlag={'clubs'} />} />
          <Route exact path='/ContactUs' element={<Navbar isFlag={'contactus'} />} />
          <Route exact path='/Dashboard' element={<Navbar isFlag={'dashboard'} />} />
          <Route exact path='/' element={<Navbar isFlag={'home'} />} />
          <Route path='*' element={<Navbar isFlag={'all'} />} />

        </Routes>
        <Routes>
          <Route path='*' element={<NavbarMobile City={city}></NavbarMobile>} />
        </Routes>

        <BottomBar  ></BottomBar>
        <Routes>
          <Route exact path='/Profile' element={<Profile />} />
          <Route exact path='/PhoneSignUp' element={<PhoneSignUp />} />

          <Route exact path='/Event' element={<Events />} />
          <Route exact path='/ContactUs' element={<ContactUs></ContactUs>}></Route>
          <Route exact path='/More' element={<More />} />
          <Route exact path='/EventDetails/:eventID' element={<EventDetails />} />
          {/* <Route exact path='/EventDetailsLink/:eventID?' element={<EventDetailsLink />} /> */}
          {/* <Route exact path='/RegisteredProfile' element={<RegisteredProfile />} /> */}
          <Route exact path='/RegisteredProfile/:eventID' element={<RegisteredProfile />} />

          {/* <Route exact path='/EventEntries' element={<EventEntries />} /> */}
          <Route exact path='/EventEntries/:eventID' element={<EventEntries />} />
          {/* <Route exact path='/EventParticipants' element={<EventParticipants />} /> */}
          <Route exact path='/EventParticipants/:eventID/:categoryName' element={<EventParticipants />} />
          <Route exact path='/EventCategory/:eventID' element={<EventCategory />} />

          {/* <Route exact path='/PlayerParticipation' element={<PlayerParticipation />} /> */}
          <Route exact path='/PlayerParticipation/:eventID/:playerID' element={<PlayerParticipation />} />

          <Route exact path='/FAQ' element={<FAQ></FAQ>}></Route>
          <Route exact path='/ListYourEvent' element={<ListYourEvent></ListYourEvent>}></Route>
          <Route exact path='/Notification' element={<Notification></Notification>}></Route>
          <Route exact path="/UserGuide" element={<UserGuide></UserGuide>} ></Route>
          <Route exact path="/Calculators" element={<Calculators></Calculators>} ></Route>
          <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy></PrivacyPolicy>}></Route>
          <Route exact path='/RefundAndCancellation' element={<RefundAndCancellation></RefundAndCancellation>}></Route>
          <Route exact path='/Location' element={<Location City="All" setCity={setMyCity} />} />
          <Route exact path='/Clubs' element={<Clubs></Clubs>}></Route>
          <Route exact path='/AboutUs' element={<AboutUs></AboutUs>}></Route>
          <Route exact path='/TermsAndConditions' element={<TermsAndConditions></TermsAndConditions>}></Route>
          <Route exact path='/ManageParticipant' element={<ManageParticipant></ManageParticipant>}></Route>
          <Route exact path='/RegistrationCategory/:eventID/:playerID' element={<RegistrationCategory></RegistrationCategory>}></Route>
          {/* <Route exact path='/RegistrationCheckout' element={<RegistrationCheckout></RegistrationCheckout>}></Route> */}
          <Route exact path='/RegistrationCheckout/:eventID/:playerID' element={<RegistrationCheckout></RegistrationCheckout>}></Route>
          {/* <Route exact path='/PaymentSuccessful' element={<PaymentSuccessful></PaymentSuccessful>}></Route> */}
          <Route exact path='/PaymentSuccessful/:eventID/:playerID' element={<PaymentSuccessful></PaymentSuccessful>}></Route>
          <Route exact path='/PaymentFailure/:transactionid' element={<PaymentFailure></PaymentFailure>}></Route>
          <Route exact path='/PaymentSuccess/:transactionid' element={<PaymentSuccess></PaymentSuccess>}></Route>
          <Route exact path='/EventList' element={<EventList ></EventList>}></Route>
          <Route exact path='/UpdateEvent/:action' element={<UpdateEvent></UpdateEvent>}></Route>
          <Route exact path='/AddEvent' element={<AddEvent></AddEvent>}></Route>
          <Route exact path='/CreateEvent' element={<PGCreateEvent></PGCreateEvent>}></Route>
          <Route exact path='/EditEvent' element={<EditEvent ></EditEvent>}></Route>
          <Route exact path='/EditOrganization' element={<EditOrganization></EditOrganization>}></Route>
          <Route exact path='/UserList' element={<UserList></UserList>}></Route>
          <Route exact path='/EditProfile' element={<EditProfile></EditProfile>}></Route>
          <Route exact path='/Dashboard' element={<PGDashboard></PGDashboard>}></Route>
          <Route exact path='/RegisteredUserProfile' element={<RegisteredUserProfile></RegisteredUserProfile>}></Route>
          <Route exact path='/PGCreateEventPayment' element={<PGCreateEventPayment></PGCreateEventPayment>}></Route>
          <Route exact path='/PGEventPendingPayment' element={<PGEventPendingPayment></PGEventPendingPayment>}></Route>
          <Route exact path='/OrganizerDashboard' element={<PGDashboardOrganizer />} />

          <Route exact path='/MatchScheduleSetup' element={<MatchScheduleSetup></MatchScheduleSetup>}></Route>

          {/* <Route exact path='/TournamentDraw' element={<TournamentDraw participants={participants}></TournamentDraw>}></Route> */}

          {/* <Route exact path='/Knockout' element={<Knockout game={treeData} ></Knockout>}></Route> */}
          <Route exact path='/SingleElimination' element={<SingleElimination></SingleElimination>}></Route>
          <Route exact path='/MyOrder' element={<MyOrder></MyOrder>}></Route>
          <Route exact path='/Orders' element={<Orders></Orders>}></Route>
          <Route exact path='/OrderHistory' element={<OrderHistory></OrderHistory>}></Route>
          <Route exact path='/ShowDraw/:eventID/:showMenu' element={<ShowDraw></ShowDraw>}></Route>


          {/* <Route exact path='/TournamentBracket' element={<TournamentBracket participants={participants}></TournamentBracket>}></Route> */}

          <Route path='/' element={<HomePage updateMyEvent={updateMyEvent} />} />

        </Routes>
        <Routes>
          <Route path='/' element={<HPGenere></HPGenere>} />
        </Routes>
        <section>
          <Routes>
            <Route path='/' element={<HPGrowWithUs></HPGrowWithUs>} />
          </Routes>
        </section>
        <Routes>
          <Route path='/' element={<HPGameSection></HPGameSection>} />
        </Routes>
        <Routes>
          <Route path='/' element={<PartnerSection></PartnerSection>} />
        </Routes>
        <Footer />
      </UserAuthContextProvider>
    </>
  );
}

export default App;
