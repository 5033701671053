import React, { useState, useEffect } from 'react';
import ReactImageGallery from 'react-image-gallery';
import '../css/ImageGallery.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useLocalStorage } from "../context/useLocalStorage";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useImageUpload } from '../hooks/useImageUpload';
// import { functions, projectStorage } from '../firebase.js'
import { projectStorage } from '../firebase.js'
// import { httpsCallable } from "firebase/functions";
import useDocumentUpdate from '../hooks/useDocumentUpdate';
import { useUserAuth } from '../context/UserAuthcontext';

export default function EventDetailsLogo(props) {
    console.log('props', props)
    const { user } = useUserAuth();

    const [isEditEnabled, setIsEditEnabled] = useState(props.IsEditEnabled);
    const [thumbnailError, setThumbnailError] = useState('');
    const [resultMessage, setResultMessage] = useState('');
    // const [loadImage, setLoadImage] = useState(false);
    const [images, setImages] = useState([]);
    const { imgUpload, isImgCompressPending, imgCompressedFile } = useImageUpload()
    const [loadingShow, setloadingShow] = useState(false);
    const [eventDetails, setEventDetails] = useState(props.eventDetails);
    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();
    const [bannerImage, setBannerImage] = useState('');
    const [bannerSelect, setBannerSelect] = useState(false);
    const [announcement, setAnnouncement] = useState('');
    const [editAnnouncement, setEditAnnouncement] = useState(false);

    // console.log('EventDetailsLogo isEditEnabled ', isEditEnabled);
    useEffect(() => {
        let eventLogoURL = [];
        // console.log('props', props)
        setIsEditEnabled(props.IsEditEnabled);
        setEventDetails(props.eventDetails);
        setBannerImage(!props.eventDetails.BannerImage && props.eventDetails.EventLogo && Array.isArray(props.eventDetails.EventLogo) ? props.eventDetails.EventLogo[0] :
            props.eventDetails.BannerImage ? props.eventDetails.BannerImage : '')
        setAnnouncement(props.eventDetails.Announcement);
        props.eventDetails.EventLogo && Array.isArray(props.eventDetails.EventLogo) &&
            (bannerImage === props.eventDetails.EventLogo[0]) && setBannerSelect(true)
        switch (props.eventDetails.SportName.toUpperCase()) {
            case 'BADMINTON':
                eventLogoURL = '/img/game/badminton.webp'
                break;
            case 'CARROM':
                eventLogoURL = '/img/game/carrom.webp'
                break;
            case 'CHESS':
                eventLogoURL = '/img/game/chess.webp'
                break;
            case 'SQUASH':
                eventLogoURL = '/img/game/squash.webp'
                break;
            case 'TABLE TENNIS':
                eventLogoURL = '/img/game/tabletennis.webp'
                break;
            case 'TENNIS':
                eventLogoURL = '/img/game/tennis.webp'

                break;
            case 'CRICKET':
                eventLogoURL = '/img/game/CK.png'
                break;
            default:
                eventLogoURL = '/img/game/badminton.webp'

        };

        setImages(props.eventDetails.EventLogo && Array.isArray(props.eventDetails.EventLogo)
            & props.eventDetails.EventLogo.length > 0 ? props.eventDetails.EventLogo :
            props.eventDetails.EventLogo && !Array.isArray(props.eventDetails.EventLogo) && props.eventDetails.EventLogo !== '' ? [props.eventDetails.EventLogo]
                : [eventLogoURL]);

    }, [props.eventDetails, props.IsEditEnabled])

    function updateBanner() {

    }
    async function deleteLogoImage() {
        setloadingShow(true);
        let imageSRC = document.getElementsByClassName('image-gallery-center')[0].getElementsByClassName('image-gallery-image')[0].src;
        console.log('images', images, 'imageSRC', imageSRC)
        let imagesLocal = images;

        let imagesLocalIndex = imagesLocal.findIndex(e => e === imageSRC);

        // console.log(imagesLocalIndex)
        imagesLocal.splice(imagesLocalIndex, 1);
        // console.log(imagesLocal)
        setImages(imagesLocal)

        if (imageSRC === bannerImage) {
            setBannerImage(imagesLocal[0]);
        }
        await saveImageinDB();
        setloadingShow(false);
    }
    const handleFileChange = async (e, width, height) => {
        // setThumbnail(null)
        setloadingShow(true);
        let file = e.target.files[0]
        // console.log('file', file)
        // setLoadImage(true);
        const compressedImage = await imgUpload(file, width, height);

        if (!compressedImage) {
            setResultMessage('Please select a image file')

            setTimeout(function () {
                setResultMessage('');
            }, 5000)
            setloadingShow(false)
            return
        }
        if (!compressedImage.type.includes('image')) {
            setResultMessage('Selected file must be an image')

            setTimeout(function () {
                setResultMessage('');
            }, 5000)

            // setLoadImage(false);
            setloadingShow(false);
            return
        }
        // console.log('before upload')
        // if (newImageFile.size > 20000000) {
        //   setResultMessage('Image file size must be less than 20mb')

        // setTimeout(function () {
        //     setResultMessage('');
        // }, 5000)

        //   return
        // }

        // setThumbnailError(null)
        // setThumbnail(compressedImage)

        if (compressedImage) {
            let uploadPath = '';
            console.log('props.eventDetails : ', (Date.now()));
            uploadPath = `img/event/${props.eventDetails.id + "_" + (Date.now()) + '.png'}`
            const imgRef = ref(projectStorage, uploadPath);
            let para1 = {};
            let _images = images;
            uploadBytes(imgRef, compressedImage)
                .then(async (snapshot) => {
                    getDownloadURL(imgRef).then(async (urlRef) => {
                        // console.log('urlRef', urlRef);
                        // images.push(urlRef)
                        if (_images[0] && _images[0].slice(0, 9) === '/img/game')
                            _images[0] = urlRef;
                        else
                            _images.push(urlRef)
                        setImages(_images)
                        await saveImageinDB()

                        setloadingShow(false);
                    })


                });


        }
    }
    async function saveImageinDB() {
        console.log('images', images, " eventDetails.id ", eventDetails.id, 'setBannerImage', bannerImage)

        let _updatedData = {
            'EventLogo': images,
            "BannerImage": bannerImage && bannerImage !== undefined ? bannerImage : images[0],
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()

        }
        await updateDocument('EventList', eventDetails.id, _updatedData)
        // let para1 = {
        //     EventID: eventDetails.id,
        //     EventLogo: images,

        // };
        // const ret1 = httpsCallable(functions, "updateEventDetails_PosterList");
        // ret1(para1).then(async (result) => {
        //     // setLoadImage(false);
        //     setResultMessage('Event Poster Saved Successfully !!')
        //     setImages(images)

        //     setTimeout(function () {
        //         setResultMessage('');
        //     }, 5000);
        // });

    }
    async function saveAnnouncement() {
        console.log('before update announcement', announcement, props.eventDetails)

        if (announcement !== props.eventDetails.Announcement) {
            console.log('before update announcement', announcement)

            // if (new Date(withdrawDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.WithdrawalEndDate.seconds * 1000)).toISOString().split("T")[0]) {

            let _updatedData = {
                'Announcement': announcement,
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()

            }
            await updateDocument('EventList', props.eventDetails.id, _updatedData)

        }
    }
    function onThumbnailClick() {
        setBannerSelect(document.getElementsByClassName('image-gallery-center')[0].getElementsByClassName('image-gallery-image')[0].src === bannerImage)
        // console.log('onThumbnailClick', bannerSelect)
        // console.log(document.getElementsByClassName('image-gallery-center')[0].getElementsByClassName('image-gallery-image')[0].src)
        // console.log(bannerImage)

    }
    console.log('annoucncemtn', announcement)
    return (
        <div>
            <div className="fixed-white-area" style={{ background: '#f4f4f4' }}>
                <div className="" style={{ display: 'block' }}>
                    <div className="event-detail-notification">
                        <span className="material-symbols-outlined bell">
                            notifications_active
                        </span>
                        {!editAnnouncement && <h1 className="blink">{announcement && announcement !== '' ? announcement : 'Online payment is mandatory to confirm your entry'}
                        </h1>}
                        {editAnnouncement &&

                            <input type="text"
                                style={{ fontSize: '1rem', color: 'red', outline: 'none', }}
                                value={announcement}
                                // readOnly={!editWithdrawDate ? true : false}
                                onChange={(e) => {
                                    setAnnouncement(e.target.value);
                                }}
                            />
                        }
                        {isEditEnabled && !editAnnouncement && <span
                            style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer', paddingLeft: '10px' }}

                            className="material-symbols-outlined"
                            onClick={() => {
                                setEditAnnouncement(true)
                            }}
                        >
                            edit
                        </span>}

                        {isEditEnabled && editAnnouncement && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer', paddingLeft: '10px' }}

                            className="material-symbols-outlined"
                            onClick={() => {
                                saveAnnouncement()
                                setEditAnnouncement(false)
                            }}
                        >
                            check
                        </span>}
                    </div>
                </div>
            </div>

            <div>
                <div className="pcs_image_area relative" style={{ position: 'relative' }}>
                    {images.length > 0 ?
                        <div style={{ position: 'relative' }}>

                            <div className='bigimage_container'>
                                <ReactImageGallery
                                    id="imageGallary"
                                    // style={{ background: 'red' }}
                                    items={images.filter((url) => url).map((url, index) => ({
                                        original: url,
                                        thumbnail: url,
                                    }))}
                                    onThumbnailClick={onThumbnailClick}
                                    slideDuration={1000}
                                    onSlide={onThumbnailClick}
                                ></ReactImageGallery>
                            </div>


                        </div>
                        :
                        <img src="/img/default_banner.png" width='100%' alt="" />
                    }
                    {loadingShow && <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '90%', zIndex: '99' }}>
                        <lottie-player id="btnSendOTPLoad"
                            src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="rgba(0,0,0,0.7)"
                            speed="0.7" loop autoplay></lottie-player>
                    </div>}
                </div>

                <br />

                <div
                    style={{
                        display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', position: 'relative',
                        bottom: '160px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                >
                    {isEditEnabled
                        && <>
                            {images.length > 1 && <div style={{
                                zIndex: '999',
                                borderRadius: '50%',
                                border: '2px solid #ddd',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: '#fff',
                                width: '35px',
                                height: '35px',
                                cursor: 'pointer'
                            }} onClick={() => {
                                deleteLogoImage()
                                // console.log('image delete', imageComponent)
                            }}>
                                <span className="material-symbols-outlined">
                                    delete
                                </span>
                            </div>}


                            <label
                                // htmlFor={`upload_img_${doc.id}`}
                                className="mybutton button5"
                                style={{
                                    border: '1px solid #fff',
                                    height: '40px',
                                    paddingTop: '8px',
                                }}
                            >

                                {resultMessage === '' && <><input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange(e, 640, 360)}
                                />
                                    Upload image
                                </>}
                                {resultMessage}
                            </label>

                            <input type="radio" checked={bannerSelect ? true : false}
                                // onClick={() => {
                                //     console.log(document.getElementsByClassName('image-gallery-center')[0].getElementsByClassName('image-gallery-image')[0].src)
                                //     console.log(bannerImage)

                                // }}
                                onChange={(e) => {
                                    // console.log(document.getElementsByClassName('image-gallery-center')[0].getElementsByClassName('image-gallery-image')[0].src)
                                    // console.log(bannerImage)
                                    e.target.checked &&
                                        setBannerImage(
                                            document.getElementsByClassName('image-gallery-center')[0].getElementsByClassName('image-gallery-image')[0].src
                                        );
                                    setBannerSelect(true)
                                }} className="checkbox event-details-logo" style={{ width: '0px' }}
                                name="EventType" id="regParticipantOPEN" value="OPEN" />
                            <label style={{ width: '150px', height: '40px', border: '1px solid #ddd' }}
                                className="checkbox-label" id="regParticipantOPENLabel"
                                htmlFor="regParticipantOPEN">
                                <i className="fas fa-plus"
                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                <i className="fas fa-check"
                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                <span>Default Banner</span>
                            </label>


                            {/* <label htmlFor="mainImg">Upload Image</label> */}

                            {/* <button className='mybutton button5'
                                            style={{
                                                width: '150px',
                                                background: '#fff',
                                                borderRadius: '8px',
                                                border: '1px solid #ddd',
                                                fontSize: '1rem',
                                                color: '#444'
                                            }}
                                        >Upload image</button> */}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
