import React, { useState, useEffect } from 'react'
import '../css/EventRegistration.css'
import CategoryCartItem from '../components/CategoryCartItem'
import { useRazorpay } from '../hooks/useRozarPay';

import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
import { useUserAuth } from '../context/UserAuthcontext';
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import { useParams } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument.js';
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
import EventDetailsMenu from './EventDetailsMenu'
import EDTournamentDetails from './EDTournamentDetails'
// import useRazorpay from "react-razorpay";

export default function RegistrationCheckout() {
    // const { state } = useLocation();
    // const { id, participantDetails, selectedCategory } = state;
    // const { id, participantDetails, eventID, PlayerID } = state;
    const { users } = useUserAuth();

    const { eventID, playerID } = useParams()
    console.log('eventID, playerID', eventID, playerID)
    // const [eventCount, setEventCount] = useState(0);
    // const [totalPayment, setTotalPayment] = useState(0);
    // const [totalPendingPayment, setTotalPendingPayment] = useState(0);
    // const [convenienceCharge, setConvenienceCharge] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [participantDetails, setParticipantDetails] = useState(null);

    const [selectedCategory, setSelectedCategory] = useState([]);
    // const userDetails = useLocalStorage('userProfile', null);
    const { user } = useUserAuth();
    const [paymentObject, setPaymentObject] = useState({
        eventCount: 0,
        totalPayment: 0,
        totalPendingPayment: 0,
        convenienceCharge: 0,
        RegID: [],
    })
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    const [pendingCategory, setPendingCategory] = useState([]);
    const [openCheckoutDetails, setOpenCheckoutDetails] = useState(false);

    const navigate = useNavigate();
    const { makePayment, paymentResponse } = useRazorpay();


    const { document: eventDetails, error: eventDocError, loading: eventLoading } = useDocument('EventList', eventID)
    const { document: participantDetails, error: playerDocError, loading: participantLoading } = useDocument('Participants', playerID)

    const queryConstraintsRegistrationPlayer = [
        where('EventID', '==', eventID),
        where('ParticipantID', '==', !participantLoading && participantDetails ? participantDetails.PlayerID : '')
        // orderBy('UserName')
        // Add more query constraints as needed
    ];

    const queryConstraintsRegistrationPartner = [
        where('EventID', '==', eventID),
        where('PartnerPlayerID', '==', !participantLoading && participantDetails ? participantDetails.PlayerID : ' ')
        // orderBy('UserName')
        // Add more query constraints as needed
    ];
    const { documents: palyerPartcipationDoc, error: palyerPartcipationDocError, loading: playerLoading } = useCollection('EventRegistrationDetails', queryConstraintsRegistrationPlayer)
    const { documents: partnerPartcipationDoc, error: partnerPartcipationDocError, loading: partnerLoading } = useCollection('EventRegistrationDetails', queryConstraintsRegistrationPartner)
    console.log('1113 palyerPartcipationDoc', palyerPartcipationDoc, palyerPartcipationDocError, playerLoading)
    console.log('21 partnerPartcipationDoc', partnerPartcipationDoc, partnerPartcipationDocError, partnerLoading)

    // const [paymentData, setPaymentData] = useState({
    //     token: "",
    //     order: "",
    //     mid: "",
    //     amount: ""
    // });

    // async function getAllRegisteredEvent() {
    //     var para = {};
    //     let registeredList = 0;
    //     //let createrUnpaid = [];
    //     para = {
    //         EventID: eventID,
    //         PlayerID: playerID
    //     }
    //     console.log(para)
    //     const ret = await httpsCallable(functions, "getAllRegisteredEventList");
    //     ret(para).then(async (result) => {
    //         console.log(result);
    //         setSelectedCategory(result.data)
    //     })

    // }
    useEffect(() => {

        // getAllRegisteredEvent();

        let participantDetailsObj = [];
        let pendingCnt = 0
        let pendingPrize = 0
        // console.log('AllCategoryList', eventCategoryDoc)
        console.log('palyerPartcipationDoc', palyerPartcipationDoc)

        palyerPartcipationDoc.forEach(element => {
            console.log('palyerPartcipationDoc', element)
            participantDetailsObj.push({
                ...element,
                RegType: 'Self'
            })
            // console.log('1. element.PaymentStatus', element.PaymentStatus, totalFees, Number(element.Fees))

            if (element.PaymentStatus === 'Pending') {
                pendingPrize = pendingPrize + Number(element.Fees)
                pendingCnt = pendingCnt + 1
            }
            // console.log('2. element.PaymentStatus', element.PaymentStatus, totalFees, Number(element.Fees))

        });
        partnerPartcipationDoc.forEach(element1 => {
            console.log('partnerPartcipationDoc', element1)
            participantDetailsObj.push({
                ...element1,
                RegType: 'Partner'
            })
        });
        setSelectedCategory(participantDetailsObj)
        let cntEvent = 0;
        let tPayment = 0;
        let tPendingPayment = 0;
        let pCat = [];
        let regid = [];
        selectedCategory.forEach(element => {
            console.log('element', element)
            tPayment = tPayment + Number(element.Fees);
            if (element.PaymentStatus.toUpperCase() === 'PENDING') {
                cntEvent++;
                tPendingPayment = tPendingPayment + Number(element.Fees);
                pCat.push(element.CategoryName);
                regid.push(element.id)
            }
        });
        // setEventCount(cntEvent);
        // setTotalPayment(tPayment);
        // setTotalPendingPayment(tPendingPayment);
        let conCharge = 0;
        if (eventDetails && eventDetails.ConvenienceCharge && Number(eventDetails.ConvenienceCharge) > 0) {
            //setConvenienceCharge(conCharge);
            conCharge = (tPendingPayment * eventDetails.ConvenienceCharge) / 100;
        }

        setPaymentObject({
            eventCount: cntEvent,
            totalPayment: tPayment,
            totalPendingPayment: tPendingPayment,
            convenienceCharge: conCharge,
            RegID: regid,
        });
        // console.log(pCat);
        setPendingCategory(pCat);

    }, [palyerPartcipationDoc, partnerPartcipationDoc]);

    function openMorePaymentDetails() {
        setOpenCheckoutDetails(!openCheckoutDetails);
    }
    // function paymentGateway() {
    //     setLoading(true);
    //     let orderId = 'O_' + eventDetails.EventCode + '_' + participantDetails.PlayerID + '_' + new Date().getTime();
    //     const razorpayOptions = {
    //         key: 'rzp_test_gaZqhFw4MY2o6v',
    //         amount: Number(paymentObject.totalPendingPayment + paymentObject.convenienceCharge) * 100, // amount in paise
    //         name: 'TPLiVE',
    //         description: 'Payment for TP Live',
    //         email: participantDetails.Email,
    //         contact: participantDetails.Phone,

    //         image: 'https://tplive-prod--tplive-test-h1bjje65.web.app/img/TPLiVE_Logo.webp',
    //         handler: function (response) {
    //             // console.log(response);
    //             navigate("/PaymentSuccessful", {
    //                 state: {
    //                     id: 1, participantDetails: participantDetails,
    //                     paymentObj: {
    //                         TXNAMOUNT: paymentObject.totalPendingPayment,
    //                         TXNID: response.razorpay_payment_id,
    //                         ORDERID: orderId
    //                     },
    //                     paymentStatus: 'Completed',
    //                     selectedCategory: pendingCategory,
    //                     updatePayment: true
    //                 }
    //             });

    //         },
    //         prefill: {
    //             name: participantDetails.UserName,
    //             email: participantDetails.Email,
    //             contact: participantDetails.Phone,
    //         },
    //         notes: {
    //             address: '',
    //         },
    //         theme: {
    //             color: '#348DCB',
    //         },
    //     };

    //     const rzp1 = new window.Razorpay(razorpayOptions);
    //     rzp1.open();
    //     setLoading(false);
    // }


    // useEffect(() => {
    //     console.log(paymentResponse)
    // }, [paymentResponse])

    const handlePayment = () => {

        // let orderId = 'O_' + props.event.EventCode + '_' + props.participant.PlayerID + '_' + new Date().getTime();
        let orderId = 'O_' + eventDetails.EventCode + '_' + participantDetails.PlayerID + '_' + new Date().getTime();
        setPaymentObject({
            ...paymentObject,
            orderID: orderId
        })
        makePayment(orderId,
            // Number(paymentObject.totalPendingPayment + paymentObject.convenienceCharge),
            1,
            user && user.userInfo ? user.userInfo.UserName : '', 'Payment for Registration',

            user && user.userInfo ? user.userInfo.Email : '',
            user && user.userInfo ? user.userInfo.Phone : '',
            paymentObject.RegID,
            // 'anita tripathi', 'Payment for Registration',
            // 'anitatripathi@gmail.com',
        ).then(async (e) => {
            console.log(e)
        })


    };
    useEffect(() => {
        console.log(paymentResponse)
        //save data in DB
        let status = ''
        console.log('paymentObject', paymentObject, paymentResponse)
        if (paymentResponse) {
            console.log('paymentResponse.paymentStatus.toUpperCase()', paymentResponse.paymentStatus.toUpperCase())
            status = paymentResponse.paymentStatus.toUpperCase() === 'SUCCESS' ? 'Completed' : 'Pending';


            if (status === 'Completed') {
                console.log('set payment completed')
                // setNewPaymentStatus(status);
                paymentResponse && paymentObject &&
                    updateTransactioninDB(paymentResponse.transactionID, paymentObject.totalPendingPayment + paymentObject.convenienceCharge,
                        status, paymentObject.RegID)//to be un commented
            }
            // else {
            //     console.log('set payment pending')

            // }
        }

    }, [paymentResponse])

    async function updateTransactioninDB(transactionID, amount, status, RegID) {

        var para = {};
        let registeredList = 0;
        //let createrUnpaid = [];
        para = {
            RegID: RegID,
            transactionID: transactionID,
            paymentAmount: amount,
            paymentStatus: status,
            orderID: paymentObject.orderID,
            BookingID: RegID
        }
        console.log(para)
        const ret = await httpsCallable(functions, "updatePaymentStatusForBookingID");
        ret(para).then(async (result) => {
            console.log(result);
            navigate("/PaymentSuccessful" + "/" + eventID + "/" + playerID)
            // setSelectedCategory(result.data)
        })
    }
    function goBack() {
        navigate(-1);
    }
    // console.log('eventDetails', eventDetails)
    let tAmount = paymentObject.totalPendingPayment + paymentObject.convenienceCharge;
    let newArray = participantDetails && participantDetails.Phone && participantDetails.Phone.replace('+', '').match(/^(91|)?(\d{3})(\d{3})(\d{4})$/)
    return (
        <>
            {
                eventDetails && <EventDetailsMenu calledFrom='Registration'
                    eventID={eventID}
                    eventDetails={eventDetails}
                />
            }
            <div className="container-fluid">
                <div className="row no-gutters">
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <br />
                        <div id="regProfileNewParticipantDetails">
                            <h3 style={{ fontWeight: '1000', color: '#348DCB', textAlign: 'center' }}>ORDERS</h3>
                            <h1 className="reg-form-email" id="playerName">{participantDetails && participantDetails.UserName} ({participantDetails && participantDetails.PlayerID})</h1>
                            <h5 className="reg-form-email">{participantDetails && participantDetails.Email}</h5>
                            <h6 className="reg-form-email">+{newArray && newArray.length >= 5 ? +newArray[1] + '-' + newArray[2] + '-' + newArray[3] + '-' + newArray[4] : ''}</h6>
                            {participantDetails && participantDetails.Gender && participantDetails.Gender.toUpperCase() === 'FEMALE' && <h5 className="reg-form-email female" id="playerGender">FEMALE</h5>}
                            {participantDetails && participantDetails.Gender && participantDetails.Gender.toUpperCase() === 'MALE' && <h5 className="reg-form-email male" id="playerGender">MALE</h5>}

                            <br />
                        </div>

                        <div className="row no-gutters" id="divRegEvent">

                            {selectedCategory && selectedCategory.map((events) => {
                                if (events.PaymentStatus === 'Pending') {
                                    return <CategoryCartItem key={events.CategoryName} eventDetails={events} ></CategoryCartItem>

                                } else {
                                    return null
                                }

                            })}

                            {/* <CategoryCartItem></CategoryCartItem> */}

                        </div>

                        <br />
                        {(eventDetails && eventDetails.ConvenienceCharge && Number(eventDetails.ConvenienceCharge) > 0) ?
                            <div className="convenience-fee-div" id="convenience-fee">

                                <h1>
                                    Convenience & Internet Charges: ( <span id="ConvenienceRate">{eventDetails && eventDetails.ConvenienceCharge} %</span> ) :
                                    <strong > Rs. {paymentObject && paymentObject.convenienceCharge} /- </strong>
                                </h1>
                            </div> : ''}

                        <br />

                        <div className="reg-checkout-note" style={{ background: '#fff' }}>
                            <h1>NOTE</h1>
                            <hr />
                            <div>
                                <ul>
                                    <li>
                                        <small>
                                            We request you to go through the eligibility criteria and rules & regulations
                                            before participating in the event.
                                        </small><br /><br />
                                    </li>
                                    <li>
                                        <small>
                                            For Doubles entry, payment and withdrawal can be done by the participant who has
                                            done the registration for the event.
                                        </small>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <br />
                        <hr style={{ border: 'none', borderTop: '1px solid #aaa' }} />
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <button onClick={goBack} className="mybutton button5"
                                style={{ fontWeight: 'bold', textAlign: 'center' }}> &lt;&lt;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back </button>
                        </div>

                        <div className={openCheckoutDetails ? 'payment-grid-div registration-checkout-payment open' : 'payment-grid-div registration-checkout-payment'} >
                            <div className='payment-grid-div-arrow'>
                                <span className="material-symbols-outlined">
                                    expand_less
                                </span>
                            </div>
                            <div className='payment-grid-div-second'>

                                {selectedCategory && selectedCategory.map((category) => {

                                    let amt = Number(category.Fees).toLocaleString('en-IN', curFormat)
                                    return category.PaymentStatus === 'Pending' ?
                                        <div key={category.CategoryName}>
                                            <small style={{ color: '#eee' }}>{category.CategoryName}</small>
                                            <span style={{ color: '#eee' }}>{amt}</span>
                                        </div> : ''
                                })}

                                {paymentObject && paymentObject.convenienceCharge > 0 &&
                                    <div>
                                        <small style={{ color: '#fff' }}>{'Convenience Charges (' + eventDetails && eventDetails.ConvenienceCharge + '%)'}</small>
                                        <span style={{ color: '#fff' }}>{paymentObject && Number(paymentObject.convenienceCharge).toLocaleString('en-IN', curFormat)}</span>
                                    </div>
                                }

                            </div>
                            <div className='payment-grid-div-first'>
                                {selectedCategory.length === 0 ? <div>
                                    <img src="/img/paid1.png" width='100px' alt="" />
                                </div> : <div>

                                    {/* <button className='mybuttonpaymentObject.totalPayment + paymentObject.convenienceCharge button5' style={{ fontWeight: '600', background: '#333C5D' }} */}
                                    <button className='mybutton button5' style={{ fontWeight: '600', background: '#333C5D' }}
                                        onClick={() => handlePayment()}>{'Pay : ' + Number(tAmount).toLocaleString('en-IN', curFormat)}</button>
                                </div>}
                                <div onClick={() => {
                                    setOpenCheckoutDetails(!openCheckoutDetails);
                                }}>
                                    <h1>{Number(tAmount).toLocaleString('en-IN', curFormat)}</h1>
                                    <h2 style={{ color: '#eee' }}>{paymentObject.eventCount} Category</h2>
                                </div>
                            </div>
                        </div>


                        {/* <div className={openCheckoutDetails ? "category-checkout active" : "category-checkout"} id="paymentDiv" style={{ opacity: '1', pointerEvents: 'all', cursor: 'auto' }}>

                        <div className="category-checkout-expand-more" style={{ width: '200px', textAlign: 'center', cursor: 'pointer' }} onClick={openMorePaymentDetails}>
                            <span className="material-symbols-outlined">
                                expand_more
                            </span>
                        </div>

                        <div className="category-checkout-full-details">

                            {selectedCategory && selectedCategory.map((events) => {
                                if (events.PaymentStatus === 'Pending') {
                                    return <div key={events.CategoryName}>
                                        <span>{events.CategoryName}</span>
                                        <small>₹ {events.Fees}</small>
                                    </div>
                                }
                                return;

                            })}


                            {paymentObject.convenienceCharge > 0 && <div>
                                <span>Convenience Charge</span>
                                <small>₹ {paymentObject.convenienceCharge}</small>
                            </div>}

                        </div>

                        <div className="category-checkout-first-details">
                            <div className="first-details-total">

                                <div>
                                    <h3>TOTAL</h3>
                                </div>
                                <div>
                                    <h1><span>Payment : ₹ </span> <span id="totalPrice">{paymentObject.totalPendingPayment + paymentObject.convenienceCharge}</span></h1>
                                    <h2><span id="noOfCategories">{paymentObject.eventCount}</span> <span> CATEGORIES</span></h2>
                                </div>
                            </div>
                            {paymentObject.totalPendingPayment > 0 && <div className="category-checkout-button-div">

                                <button onClick={paymentGateway} className="mybutton button5" style={{ width: '120px', height: '35px', background: '#333C5D' }}>
                                    <div style={{ display: !loading ? 'block' : 'none' }}>
                                        <span
                                            style={{ position: 'relative', fontSize: '0.9rem', color: '#fff' }}>PAY NOW</span>
                                    </div>
                                    <div className='btn-loading' style={{ display: loading ? 'block' : 'none' }}>
                                        <lottie-player
                                            src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                            speed="0.7" loop autoplay></lottie-player>
                                    </div>
                                </button>
                            </div>}

                        </div>

                    </div> */}

                    </div>
                    {eventDetails && <EDTournamentDetails eventDetails={eventDetails}
                        showRegistration={false}
                        showRegistrationButton={false}
                    />}


                </div><br /><br /><br />
            </div>
        </>
    )
}
