import React, { useState } from 'react'

export default function LeagueMatchDetails(props) {
    const [showMatch, setShowMatch] = useState(true)
    const [showLeague, setShowLeague] = useState(true)

    return (
        <>
            {/* <div className="container-fluid"> */}
            {/* {console.log('props ', props)} */}

            <div className="row no-gutters">
                <div className='col-lg-12'>
                    <div className={showLeague ? 'calculator-div open' : 'calculator-div'}>
                        <div className='calculator-heading' onClick={() => setShowLeague(!showLeague)}>
                            <h1 style={{ fontSize: '1.8rem', fontWeight: 'normal' }}>
                                League Status
                                <span className="material-symbols-outlined">
                                    expand_more
                                </span>
                            </h1>
                        </div>

                        <div className='calculator-content' style={{ background: '#fff' }}>
                            <div className="row no-gutters">

                                <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: '0' }}>
                                    <div className="" style={{ textAlign: 'right', position: 'relative', zIndex: '5' }}>
                                        <table className="content-table" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>Player Name</th>
                                                    <th>Matches Playes</th>
                                                    <th>Won</th>
                                                    <th>Lost</th>
                                                    <th>No Result</th>
                                                    <th>Point Scored</th>
                                                    <th>Point Against</th>
                                                    <th>Run rate</th>
                                                    <th>Position</th>
                                                </tr>
                                            </thead>
                                            <tbody id="eventCategoryDetails">
                                                {props.MatchStatus && props.MatchStatus.map((match) => (
                                                    <tr key={match.PlayerCode}>
                                                        <td>{match.PlayerName}</td>
                                                        <td>{match.MatchWon}</td>
                                                        <td>{match.MatchLost}</td>
                                                        <td>{match.MatchNoResult}</td>
                                                        <td>{match.PointScored}</td>
                                                        <td>{match.PointAgainst}</td>
                                                        <td>{match.RunRate}</td>
                                                        <td>{match.Position}</td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className={showMatch ? 'calculator-div open' : 'calculator-div'}>
                        <div className='calculator-heading' onClick={() => setShowMatch(!showMatch)}>
                            <h1 style={{ fontSize: '1.8rem', fontWeight: 'normal' }}>
                                League Matches
                                <span className="material-symbols-outlined">
                                    expand_more
                                </span>
                            </h1>
                        </div>

                        <div className='calculator-content' style={{ background: '#fff' }}>
                            <div className="row no-gutters">

                                <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: '0' }}>
                                    <div className="" style={{ textAlign: 'right', position: 'relative', zIndex: '5' }}>
                                        <table className="content-table" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>Match ID</th>
                                                    <th>First Player Name</th>
                                                    <th>Second Player Name</th>
                                                    <th>Winner Player Code</th>
                                                    <th>MatchScore</th>

                                                </tr>
                                            </thead>
                                            <tbody id="eventCategoryDetails">
                                                {props.MatchDetails && props.MatchDetails.map((match) => (
                                                    <tr key={match.Matchid}>
                                                        <td>{match.Matchid}</td>
                                                        <td>{match.FirstPlayerName}</td>
                                                        <td>{match.SecondPlayerName}</td>
                                                        <td>{match.WinnerPlayerCode}</td>
                                                        <td>{match.MatchScore}</td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}
