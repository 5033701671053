import React, { useState, useEffect } from 'react'
import { useRazorpay } from '../hooks/useRozarPay';
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";
import SignIn from './SignIn.js';
import { useDocument } from '../hooks/useDocument.js';
export default function EventCategoryParticipant(props) {

    const { makePayment, paymentResponse } = useRazorpay();
    const { user } = useUserAuth();
    const [isPayClicked, setIsPayClicked] = useState(false);

    console.log('props', props);
    const [signinFlag, setSigninFlag] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);


    const [localPlayerList, setLocalPlayerList] = useState(props.localParticipantDetails);
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };
    const userid = user && user.userInfo ? user.userInfo.uid : 'test'
    const { document: userProfileDoc, error: userProfileDocError } = useDocument('UserList', user && user.userInfo ? user.userInfo.uid : '')

    function setSignIn(flag, userdata) {
        setSigninFlag(flag);
        // setUserDetails(userdata);
    }
    function sortPlayerList() {
        localPlayerList && localPlayerList.length > 0 && setLocalPlayerList(localPlayerList.sort((a, b) => {
            let fa = a.PlayerName.toLowerCase(),
                fb = b.PlayerName.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        }));
        // setLocalPlayerList(localPlayerList);
    }

    useEffect(() => {
        setIsPayClicked(false);

        sortPlayerList();
    }, []);

    useEffect(() => {
        if (!isNewUser) {
            // console.log('not new user', isNewUser)
            if (user.isLoggedIn) {
                if (user.userInfo) {
                    setSigninFlag(true)
                }
            }
        }
    }, [user])


    const handlePayment = (obj) => {
        console.log(obj);
        if (!(user && user.isLoggedIn && user.userInfo)) {
            setIsPayClicked(true);
        } else {
            let orderId = 'O_' + props.eventDetails.EventCode + '_' + obj.ParticipantID + '_' + new Date().getTime();
            let totalPayment = Number(obj.Fees);
            if (props.eventDetails.ConvenienceCharge && props.eventDetails.ConvenienceCharge > 0) {
                totalPayment = totalPayment + Number(totalPayment * props.eventDetails.ConvenienceCharge / 100);
            }

            makePayment(orderId,
                Number(totalPayment),
                user.userInfo.UserName, 'Payment for Registration',
                user.userInfo.Email,
                user.phoneNumber,
                [obj.RegID]
            ).then(async (e) => {
                console.log(e, 'paymentResponse', paymentResponse);
            })
        }
    }
    useEffect(() => {
        // console.log(paymentResponse)
        //to do anita save in DB 
    }, [paymentResponse])

    useEffect(() => {
        if (!isNewUser) {
            // console.log('not new user', isNewUser)
            if (user.isLoggedIn) {
                if (user.userInfo) {

                    setSigninFlag(true)
                }
            }
        }
    }, [user])

    let cnt = 0;
    // console.log('localPlayerList', localPlayerList);
    return (
        <>
            {!signinFlag && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>}
            {localPlayerList && signinFlag && localPlayerList.length > 0 && localPlayerList.map(entry => {
                entry.CategoryName === props.selectedCategory ? cnt = cnt + 1 : cnt = cnt + 0;
                return entry.CategoryName === props.selectedCategory ?
                    <div className='event-category-participant-card' key={entry.ParticipantID + entry.CategoryName}>
                        <div style={{ width: '8%' }}></div>
                        <div className='event-category-participant-card-number'>
                            <small>{cnt}</small>
                        </div>
                        <div className='event-category-participant-card-names'>
                            <span>{entry.ParticipantName}</span>
                            {entry.PartnerPlayerName && entry.PartnerPlayerName !== '' && <span>{entry.PartnerPlayerName}</span>}
                        </div>
                        {entry.PaymentStatus === 'Pending' && <div className='event-category-participant-card-status' onClick={() =>
                            handlePayment(entry)}>
                            <button className='mybutton button5'>PAY {Number(entry.Fees).toLocaleString('en-IN', curFormat)}</button>
                        </div>}
                        {entry.PaymentStatus === 'Completed' && <div className='event-category-participant-card-status completed'>
                            <h1>COMPLETED</h1>
                        </div>}
                        <div style={{ width: '27%' }}></div>
                    </div>
                    : null
            })}
            {/* props.playerList    */}
            {/* <div className='event-category-participant-card'>
                <div style={{ width: '8%' }}></div>
                <div className='event-category-participant-card-number'>
                    <small>1</small>
                </div>
                <div className='event-category-participant-card-names'>
                    <span>TRIPATHI, Atul</span>
                    <span>TRIPATHI, Aditya</span>
                </div>
                <div className='event-category-participant-card-status'>
                    <button className='mybutton button5'>PAY ₹600</button>
                </div>
                <div style={{ width: '27%' }}></div>
            </div>

            <div className='event-category-participant-card'>
                <div style={{ width: '8%' }}></div>
                <div className='event-category-participant-card-number'>
                    <small>2</small>
                </div>
                <div className='event-category-participant-card-names'>
                    <span>NAGAPHANI, Vedic Gupta</span>
                </div>
                <div className='event-category-participant-card-status completed'>
                    <h1>COMPLETED</h1>
                </div>
                <div style={{ width: '27%' }}></div>
            </div> */}

        </>
    )
}
