import React, { useEffect, useState } from 'react'

export default function Disclaimer(props) {
    console.log('props', props)
    const [collapseFlag, setcollapseFlag] = useState(false);

    useEffect(() => {
        setcollapseFlag(props.collapseFlag);
    }, [props])

    const disclaimerClick = () => {
        setcollapseFlag(!collapseFlag)
    }

    return (
        <div style={{ border: '2px solid #333C5D', padding: '2% 3% 10px 3%' }} className="secbox">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={() =>
                disclaimerClick()
            } className="">
                <h4 style={{ fontWeight: '1000', color: '#348DCB', position: 'relative', top: '2px' }}>
                    <span className="material-symbols-outlined" style={{ paddingRight: '10px', position: 'relative', top: '5px' }}>
                        privacy_tip
                    </span>
                    Disclaimer
                </h4>

                <div style={{
                    width: '40px',
                    height: '40px',
                    background: '#348DCB',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <span className="material-symbols-outlined" style={{
                        color: '#fff',
                        fontSize: '2rem',
                        transition: '0.5s',
                        transform: !collapseFlag ? 'rotate(180deg) translateY(2px)' : '',
                    }}>
                        keyboard_arrow_down
                    </span>
                </div>
            </div>
            <ul className={!collapseFlag ? 'collapse-div-disclamer' : 'collapse-div-disclamer open'} >
                <hr />
                <li>
                    <p>
                        TPLiVE (Tournament Planner LiVE) provided the www.tplive.in website as a service to the public. The information provided on the website is for general informational purposes only.
                    </p>
                </li>
                <li>
                    <p>
                        TPLiVE is not responsible for any loss or damage of any kind arising out of use. While the information contained within the site is periodically updated, no guarantee is given that the information provided in this website is correct, complete, and up-to-date. Although the site may include links providing direct access to other internet resources, including websites, TPLiVE is not responsible for the accuracy, adequacy, validity, reliability, availability or completeness of the content of information contained in these sites. Links from the website to third-party sites do not constitute an endorsement by TPLiVE of the parties or their products and services. The appearance on the Web site of advertisements and product or service information does not constitute an endorsement by TPLiVE , and TPLiVE has not investigated the claims made by any advertiser. Advertisements and Product information is based solely on the content received from suppliers.
                    </p>
                </li>
            </ul>
        </div>
    )
}
