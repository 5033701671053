import React, { useEffect, useState } from 'react'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'
import Loading from './Loading.js'
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
import ReactDatePicker from 'react-datepicker';
import { useUserAuth } from '../context/UserAuthcontext';
import useDocumentUpdate from '../hooks/useDocumentUpdate';
import SearchComponents from './SearchComponents.js';

export default function MatchScheduleSetup(props) {
    console.log('props : ', props)
    const [loading, setLoading] = useState(false)
    const { user } = useUserAuth();

    const [drawCategoryList, setDrawCategoryList] = useState([])
    const [drawCategoryName, setDrawCategoryName] = useState('')
    const [date, setDate] = useState(null)
    const [showSingleMatch, setshowSingleMatch] = useState(false);
    const [singleMatchIDUpdate, setSingleMatchIDUpdate] = useState('');
    const [singleMatchCourt, setSingleMatchCourt] = useState('');
    const [singleMatchTime, setSingleMatchTime] = useState(null);
    const [showMatchSchedule, setshowMatchSchedule] = useState(false);
    const [singleMatchDrawType, setSingleMatchDrawType] = useState('');
    const [matchDrawType, setMatchDrawType] = useState('');
    const [roundList, setRoundList] = useState([]);
    const [selectedRound, setSelectedRound] = useState('');
    const [matchInterval, setMatchInterval] = useState(15);
    const [parallelCourt, setParallelCourt] = useState(1);
    const [matchTime, setMatchTime] = useState(null);


    // const [matchKnockOut, setMatchKnockOut] = useState([])
    // const [matchLeague, setmatchLeague] = useState([])
    // const [matchLeagueStatus, setmatchLeagueStatus] = useState([])
    const [matchSchedule, setMatchSchedule] = useState([]);
    const [selectedMatchDetails, setSelectedMatchDetails] = useState(null);
    const [drawCategory, setDrawCategory] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [matchListSingle, setMatchListSingle] = useState([]);
    const [matchList, setMatchList] = useState([]);
    const [categoryNameSingleMatch, setCategoryNameSingleMatch] = useState('');
    const [categoryNameMatch, setCategoryNameMatch] = useState('');
    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();

    const eventQueryConstraints = [
        where('EventID', '==', props.EventID),
    ];
    const { documents: matchKnockOut, error: matchKnockOutError } = useCollection('TX_MatchDetails', eventQueryConstraints)
    const { documents: matchLeague, error: matchLeagueError } = useCollection('TX_MatchDetailsLeague', eventQueryConstraints)
    const { documents: matchLeagueStatus, error: matchLeagueStatusError } = useCollection('TX_LeagueStatus', eventQueryConstraints)


    useEffect(() => {
        let catList = categoryList;
        let round = [];
        let schedules = []
        // let id = 1;
        matchKnockOut.forEach(elementKnockOut => {
            // if (!round.find(e => e.CategoryName === elementKnockOut.CategoryName && e.Round === elementKnockOut.Round)) {
            //     round.push({
            //         CategoryName: elementKnockOut.CategoryName,
            //         Published: elementKnockOut.Round,
            //         DrawType: 'Knockout',
            //         id: elementKnockOut.id,
            //         searchKey: elementKnockOut.Round,//+ 'Knockout',
            //         displayText: elementKnockOut.Round,//+ '#''Knockout',

            //     })
            // }
            if (!catList.find(e => e.CategoryName === elementKnockOut.CategoryName)) {
                catList.push({
                    CategoryName: elementKnockOut.CategoryName,
                    Published: elementKnockOut.Published ? elementKnockOut.Published : 'NO',
                    DrawType: 'Knockout',
                    //added for search components
                    id: elementKnockOut.id,
                    searchKey: elementKnockOut.CategoryName,//+ 'Knockout',
                    displayText: elementKnockOut.CategoryName,//+ '#''Knockout',

                })
                // id = id + 1;
            }
            schedules.push({
                MatchID: elementKnockOut.id,
                DrawType: 'Knockout',
                MatchSchedule: elementKnockOut.MatchStatus === 'DONE' ? '' : elementKnockOut.MatchSchedule ? elementKnockOut.MatchSchedule : new Date()
            })
        });
        matchLeague.forEach(elementLeague => {

            if (!round.find(e => e.CategoryName === elementLeague.CategoryName && e.Round === elementLeague.Round)) {
                round.push({
                    CategoryName: elementLeague.CategoryName,
                    Published: elementLeague.Round,
                    DrawType: 'League',
                    id: elementLeague.id,
                    searchKey: elementLeague.Round,//+ 'Knockout',
                    displayText: elementLeague.Round,//+ '#''Knockout',

                })
            }
            if (!catList.find(e => e.CategoryName === elementLeague.CategoryName)) {
                catList.push({
                    CategoryName: elementLeague.CategoryName,
                    Published: elementLeague.Published ? elementLeague.Published : 'NO',
                    DrawType: 'League',
                    //added for search components
                    id: elementLeague.id,
                    searchKey: elementLeague.CategoryName,//+ 'Knockout',
                    displayText: elementLeague.CategoryName,//+ 'Knockout',
                })
                // id = id + 1
            }
            schedules.push({
                MatchID: elementLeague.id,
                DrawType: 'League',
                MatchSchedule: elementLeague.MatchStatus === 'DONE' ? '' : elementLeague.MatchSchedule ? elementLeague.MatchSchedule : new Date()
            })
        });
        let matchDetails = [];
        let lCatList = [];
        setMatchSchedule(schedules)
        catList && catList.forEach(element => {
            if (element.DrawType === 'Knockout') {
                matchDetails = matchKnockOut.filter(e => e.CategoryName === element.CategoryName)
            }
            if (element.DrawType === 'League') {
                matchDetails = matchLeague.filter(e => e.CategoryName === element.CategoryName)
            }
            lCatList.push({
                ...element,
                MatchDetails: matchDetails
            })
        });
        setCategoryList(lCatList);
        setCategoryNameMatch(lCatList[0] ? lCatList[0].CategoryName : '');
        setCategoryNameSingleMatch(lCatList[0] ? lCatList[0].CategoryName : '');
        setDrawCategoryList(lCatList);
        setDrawCategoryName(lCatList[0] && lCatList[0].CategoryName)
        setSelectedMatchDetails(lCatList[0])
    }, [matchKnockOut, matchLeague, matchLeagueStatus]);

    function updateMatchSchedule(matchID, drawType, schedule) {
        // console.log('matchID, drawType, schedule', matchID, drawType, schedule)
        // let scheduleLocal = matchSchedule;
        let matchLocal = selectedMatchDetails.MatchDetails
        //selectedMatchDetails

        let indexMatch = matchLocal.findIndex(e => e.id === matchID)
        console.log('matchLocal', matchLocal, '::indexMatch ::', indexMatch)
        matchLocal[indexMatch] = {
            ...matchLocal[indexMatch],
            MatchSchedule: new Date(schedule)
        }
        setSelectedMatchDetails({
            ...selectedMatchDetails,
            MatchDetails: matchLocal
        })
    }

    // async function getDraw() {
    //     var para1 = {};
    //     console.log('EventDetails', props.EventID)
    //     para1 = {
    //         EventID: props.EventID //props.EventID //'BP3A5E0nFt5QBJMyDthp'//props.EventID,
    //     };
    //     setLoading(true);
    //     // console.log('para1 : ', para1)
    //     const ret1 = httpsCallable(functions, 'getDrawDetails');

    //     ret1(para1).then(async (result) => {
    //         console.log('result', result)
    //         setDrawCategoryList(result.data.CategoryList);
    //         setDrawCategoryName(result.data.CategoryList ? result.data.CategoryList[0] : '');
    //         setMatchKnockOut(result.data.MatchDetailsKnockOut);
    //         setmatchLeague(result.data.MatchDetailsLeague);
    //         setmatchLeagueStatus(result.data.MatchDetailsLeagueStatus)
    //         ShowCategoryDraw(result.data && result.data.CategoryList && result.data.CategoryList[0]);
    //         setLoading(false);
    //     })

    // }
    function setSearchClicked(flag) {

    }
    function ShowCategoryDraw(category) {
        let dType = '';
        let published = 'No';
        let matchDetails = [];
        let matchStatus = [];
        console.log('category : ', category, ' :: matchKnockOut : ', matchKnockOut, ' :: matchLeague : ', matchLeague)
        if (matchKnockOut.find(e => e.CategoryName === category)) {
            // console.log('category : ', category, ' ::Knockout ')
            published = matchKnockOut[0].Published ? matchKnockOut[0].Published : 'No';
            dType = 'Knockout';
            matchDetails = matchKnockOut.filter(e => e.CategoryName === category);
            matchDetails.sort(function (a, b) { return a.MatchID - b.MatchID })

        } else if (matchLeague.find(e => e.CategoryName === category)) {
            // console.log('category : ', category, ' ::League ')
            dType = 'League';
            published = matchLeague[0].Published ? matchLeague[0].Published : 'No';
            matchDetails = matchLeague.filter(e => e.CategoryName === category);
            matchStatus = matchLeagueStatus.filter(e => e.CategoryName === category);
            matchDetails.sort(function (a, b) { return a.MatchID - b.MatchID })
        }
    }

    function changeCategory(catname) {
        setDrawCategoryName(catname)
        console.log('changeCategory', catname)
        if (matchKnockOut.find(e => e.CategoryName === catname)) {
            setSelectedMatchDetails({
                drawType: 'Knockout',
                matchList: matchKnockOut.filter(e => e.CategoryName === catname)
            })
        } else if (matchLeague.find(e => e.CategoryName === catname)) {
            setSelectedMatchDetails(
                {
                    drawType: 'League',
                    matchList: matchLeague.filter(e => e.CategoryName === catname)
                })
        }
        console.log('setSelectedMatchDetails : ', selectedMatchDetails);

    }
    async function updateScheduleinDB() {
        console.log('selectedMatchDetails', selectedMatchDetails)
        selectedMatchDetails.MatchDetails.forEach(element => {
            if (element.MatchSchedule && !element.MatchSchedule.seconds) {
                console.log('element', element)

                let _updatedData = {
                    'MatchSchedule': element.MatchSchedule && element.MatchSchedule.seconds ? new Date(element.MatchSchedule.seconds * 1000) : new Date(element.MatchSchedule),
                    "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                    "UpdatedTimestamp": new Date()
                }
                if (selectedMatchDetails.DrawType === 'Knockout') {
                    updateDocument('TX_MatchDetails', element.id, _updatedData)
                }
                else {
                    updateDocument('TX_MatchDetailsLeague', element.id, _updatedData)
                }
            }
        });


    }

    async function saveMatchSchedule() {

        let _updatedData = {
            'MatchSchedule': singleMatchTime ? new Date(singleMatchTime) : new Date(),
            'CourtDetails': singleMatchCourt,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        if (singleMatchDrawType === 'Knockout') {
            updateDocument('TX_MatchDetails', singleMatchIDUpdate, _updatedData)
        }
        else {
            updateDocument('TX_MatchDetailsLeague', singleMatchIDUpdate, _updatedData)
        }
    }

    function setSelectedItemSearchComponent(id, text) {
        console.log(id, text, 'matchKnockOut', matchKnockOut)
        setCategoryNameSingleMatch(text)

        let _matchListSingle = [];

        if (matchKnockOut.find(e => e.CategoryName === text)) {
            setMatchDrawType('Knockout')
            _matchListSingle = matchKnockOut.filter(e => e.CategoryName === text && e.MatchStatus === '')
        } else if (matchLeague.find(e => e.CategoryName === text)) {
            setMatchDrawType('League')
            _matchListSingle = matchLeague.filter(e => e.CategoryName === text && e.MatchStatus === '');
        }

        console.log('_matchListSingle', _matchListSingle)
        setMatchListSingle(_matchListSingle)
    }

    function setSelectedItemCategory(id, text) {
        console.log(id, text, 'matchKnockOut', matchKnockOut)
        setCategoryNameMatch(text)
        let _matchList = [];

        if (matchKnockOut.find(e => e.CategoryName === text)) {
            setMatchDrawType('Knockout')
            // _matchList = matchKnockOut.filter(e => e.CategoryName === text && e.MatchStatus === '')
            _matchList = matchKnockOut.filter(e => e.CategoryName === text)
        } else if (matchLeague.find(e => e.CategoryName === text)) {
            setMatchDrawType('League')
            _matchList = matchLeague.filter(e => e.CategoryName === text && e.MatchStatus === '');
        }

        // let _matchListSingle = matchKnockOut.filter(e => e.CategoryName === text)
        console.log('_matchList', _matchList)
        let _round = [];
        _matchList.forEach(element => {
            console.log('element', element)
            if (!_round.includes(element.Round))
                _round.push(element.Round)
        });
        console.log('_round', _round)
        // setMatchList(_matchList)
        setRoundList(_round);
    }
    async function updateScheduleForRound() {


        let para = {
            EventID: props.EventID,
            CategoryName: categoryNameMatch,
            Round: selectedRound,
            MatchInterval: matchInterval,
            NumberOfCourt: parallelCourt,
            StartTime: matchTime,
            DrawType: matchDrawType,
            UserID: user && user.userInfo && user.userInfo.uid,

        };

        console.log(para);
        setLoading(true);

        const ret = await httpsCallable(functions, "UpdateScheduleOneRound");
        ret(para).then(async (result) => {
            setLoading(false);

        })

    }
    // let matchTimeindex = -1;
    // console.log('setSelectedMatchDetails', selectedMatchDetails, matchSchedule)
    return (
        <>
            {/* {loading && <Loading></Loading>} */}
            {!loading && <div className='row no-gutters' style={{ background: '#fff' }}>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <strong>Category List</strong>
                </div>
                {/* {console.log('in div MatchScheduleSetup')} */}
                <div className='col-lg-12' style={{ border: '1' }}>
                    <div className='row no-gutters' style={{ padding: '0 5px' }}>
                        {/* {console.log('drawCategoryList', drawCategoryList)} */}
                        {drawCategoryList && drawCategoryList.map((obj) => {
                            return <div key={"1" + obj} className="col-4" style={{ padding: '0' }}>

                                <div style={{ widht: '100%', textAlign: 'center', padding: '8px 5px' }}>
                                    <input type="radio" checked={drawCategoryName === obj.CategoryName ? true : false}
                                        className="checkbox" style={{ width: '0px' }}
                                        name="EventName" id={obj.CategoryName.replaceAll(' ', '').replaceAll('-', '')}
                                        value={obj.CategoryName}
                                        onChange={() => {
                                            changeCategory(obj.CategoryName);
                                        }} />
                                    <label style={{ height: '30px', border: '1px solid #ddd', paddingTop: '1px' }}
                                        className="checkbox-label setup-draw-label"
                                        id={obj.CategoryName.replaceAll(' ', '').replaceAll('-', '') + "Label"}
                                        htmlFor={obj.CategoryName.replaceAll(' ', '').replaceAll('-', '')}>
                                        <i className="fas fa-plus"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        <i className="fas fa-check"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        <span>{obj.CategoryName}</span>
                                    </label>
                                </div>

                            </div>
                        })}
                    </div>
                </div>
            </div>
            }
            {selectedMatchDetails && selectedMatchDetails.MatchDetails && selectedMatchDetails.MatchDetails.length > 0 && <div className='col-lg-12' style={{ border: '1' }}>
                {selectedMatchDetails.MatchDetails.map((obj) => {
                    if (selectedMatchDetails.DrawType === 'Knockout') {

                        // matchTimeindex = matchSchedule.findIndex(e => e.MatchID === obj.id && e.DrawType === 'Knockout')
                        // console.log('obj ::', obj)
                        return <div key={obj.ParticipantName + obj.Round} style={{ display: 'flex' }}>
                            <span style={{ width: '100%' }}>{obj.Round} :: {obj.ParticipantName}  :: {obj.SecondParticipantName}</span>
                            <ReactDatePicker
                                readOnly={obj.MatchStatus === 'DONE' ? true : false}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd-MMM-yy hh:mm aa"
                                selected={obj.MatchSchedule && obj.MatchSchedule.seconds ? new Date(obj.MatchSchedule.seconds * 1000) :
                                    obj.MatchSchedule && obj.MatchSchedule ? new Date(obj.MatchSchedule) : new Date()}

                                onChange={(e) => {
                                    // console.log('matchTimeindex', matchTimeindex)
                                    updateMatchSchedule(obj.id, 'Knockout', e)
                                }}
                            />
                        </div>
                    } else {
                        // matchTimeindex = matchSchedule.findIndex(e => e.id === obj.id && e.DrawType === 'League')

                        return <div>
                            <span>{obj.Round} :: {obj.FirstPlayerName} :: {obj.SecondPlayerName}</span>
                            <ReactDatePicker
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd-MMM-yy hh:mm aa"
                                selected={obj.MatchSchedule ?
                                    new Date(obj.MatchSchedule) : new Date()}
                                onChange={(e) => {
                                    updateMatchSchedule(obj.id, 'League', e)
                                }}
                            />
                        </div>

                    }

                })}
                <div>
                    <button type="button" className="mybutton button5"
                        onClick={(e) => {
                            console.log('saved')
                            updateScheduleinDB();
                        }}
                        style={{ cursor: "pointer", fontSize: '14' }}>Update Schedule</button>
                </div>
            </div>

            }

            <div className={showSingleMatch ? 'calculator-div open' : 'calculator-div'}>
                <div className='calculator-heading' onClick={() => setshowSingleMatch(!showSingleMatch)}>
                    <h1 style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>
                        Schedule Single Match
                        <span className="material-symbols-outlined">
                            expand_more
                        </span>
                    </h1>
                </div>
                <div className='calculator-content' style={{ background: '#fff' }}>

                    <div className='row no-gutters'>
                        <div className='col-6'>
                            <div className="event-detail-input-div">
                                <label>Category</label>
                                <SearchComponents ItemList={categoryList}
                                    placeholder={'search Category...'}
                                    setSearchClicked={setSearchClicked}

                                    setSelectedItem={setSelectedItemSearchComponent}></SearchComponents>
                                {/* <input type="text" id="organizationName" placeholder="" required /> */}
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="event-detail-input-div">
                                <label>Match ID</label>
                                {/* <input type="text" id="organizationName" placeholder="" required /> */}
                                <select style={{ background: '#e4e4e4' }} name=""
                                    value={singleMatchIDUpdate}
                                    onChange={(e) => {
                                        // console.log('e.target.value', e.target.value)
                                        setSingleMatchIDUpdate(
                                            e.target.value
                                        )
                                    }}
                                    onClick={(e) => {
                                        // console.log('e.target.value', e.target.value)
                                        setSingleMatchIDUpdate(
                                            e.target.value
                                        )
                                    }}
                                >
                                    {matchListSingle.map((match) => {
                                        return <option value={match.id} key={match.id}>{match.ParticipantName}-{match.SecondParticipantName}</option>
                                    })}

                                </select>
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="event-detail-input-div">
                                <label>Court</label>
                                <input type="text" placeholder="court number.."
                                    value={singleMatchCourt}
                                    onChange={(e) => {
                                        // console.log('e.target.value', e.target.value)
                                        setSingleMatchCourt(
                                            e.target.value
                                        )
                                    }} required />
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="event-detail-input-div">
                                <label>Schedule</label>
                                <ReactDatePicker
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="dd-MMM-yy hh:mm aa"
                                    wrapperClassName="match-setup-date-picker"
                                    selected={singleMatchTime ? new Date(singleMatchTime) : new Date()}

                                    onChange={(e) => {
                                        // console.log('matchTimeindex', matchTimeindex)
                                        setSingleMatchTime(e)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <hr style={{ margin: '30px 0 18px 0' }} />

                    <center>
                        <button className="mybutton button5" onClick={saveMatchSchedule}>Update Schedule</button>
                    </center>
                </div>
            </div >

            <div className={showMatchSchedule ? 'calculator-div open' : 'calculator-div'}>
                <div className='calculator-heading' onClick={() => setshowMatchSchedule(!showMatchSchedule)}>
                    <h1 style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>
                        Update Schedule By Slot
                        <span className="material-symbols-outlined">
                            expand_more
                        </span>
                    </h1>
                </div>
                <div className='calculator-content' style={{ background: '#fff' }}>

                    <div className='row no-gutters'>
                        <div className='col-6'>
                            <div className="event-detail-input-div">
                                <label>Category</label>
                                <SearchComponents ItemList={categoryList}
                                    placeholder={'search Category...'}
                                    setSearchClicked={setSearchClicked}

                                    setSelectedItem={setSelectedItemCategory}></SearchComponents>
                                {/* <input type="text" id="organizationName" placeholder="" required /> */}
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="event-detail-input-div">
                                <label>Rounds</label>
                                <select style={{ background: '#e4e4e4' }} name=""
                                    value={selectedRound}
                                    onChange={(e) => {
                                        // console.log('e.target.value', e.target.value)
                                        setSelectedRound(
                                            e.target.value
                                        )
                                    }}
                                    onClick={(e) => {
                                        // console.log('e.target.value', e.target.value)
                                        setSelectedRound(
                                            e.target.value
                                        )
                                    }}
                                >
                                    {roundList.map((match) => {
                                        return <option value={match} key={match}>{match}</option>
                                    })}

                                </select>
                                {/* <input type="text" id="organizationName" placeholder="" required /> */}
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="event-detail-input-div">
                                <label>Match intervals (mins)</label>
                                <input type="number"
                                    value={matchInterval}
                                    placeholder=""
                                    onChange={(e) => {
                                        setMatchInterval(e.target.value)
                                    }} required />
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="event-detail-input-div">
                                <label>Parallel Courts</label>
                                <input type="number"
                                    value={parallelCourt}
                                    placeholder="" onChange={(e) => {
                                        setParallelCourt(e.target.value)
                                    }} required />
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="event-detail-input-div">
                                <label>Start Time</label>
                                <ReactDatePicker
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="dd-MMM-yy hh:mm aa"
                                    wrapperClassName="match-setup-date-picker"
                                    selected={matchTime ? new Date(matchTime) : new Date()}

                                    onChange={(e) => {
                                        // console.log('matchTimeindex', matchTimeindex)
                                        setMatchTime(e)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <hr style={{ margin: '30px 0 18px 0' }} />

                    <center>
                        <div style={{ display: !loading ? 'block' : 'none' }} >
                            <button className="mybutton button5" onClick={updateScheduleForRound}>Update Timings</button>
                        </div>
                        <div style={{ display: loading ? 'block' : 'none' }}>
                            <lottie-player id="btnSendOTPLoad"
                                src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                speed="0.7" loop autoplay></lottie-player>
                        </div>
                    </center>
                </div>
            </div >


        </>
    )
}
