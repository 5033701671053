import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import { functions, projectStorage } from '../firebase.js'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { httpsCallable } from "firebase/functions";
import $ from "jquery";
import '../css/UserProfiles.css'
// import '../css/EventRegistration.css'
// import '../css/UserProfile_niu.css'
import DatePicker from "react-datepicker";
import '../css/MydatePicker.css'
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import SignIn from './SignIn';

import { useImageUpload } from '../hooks/useImageUpload';
import { useDocument } from '../hooks/useDocument.js';

export default function EditProfile() {

    const { user } = useUserAuth();
    const [showError, setShowError] = useState(false);
    const [showSaveError, setShowSaveError] = useState(false);
    const [flagLoad, setFlagLoad] = useState(false);
    const [thumbnail, setThumbnail] = useState(null)
    const [loadImage, setLoadImage] = useState(false);
    const [thumbnailError, setThumbnailError] = useState(null)
    const { imgUpload, isImgCompressPending, imgCompressedFile } = useImageUpload()
    const [signinFlag, setSigninFlag] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [localUserProfile, setLocalUserProfile] = useState(user && user.userInfo);

    function setSignIn(flag, userData) {
        // console.log(flag)
        setSigninFlag(flag);
        // setUserDetails(userData);
    }
    const { document: userProfileDoc, error: userProfileDocError } = useDocument('UserList', user && user.userInfo ? user.userInfo.uid : '')

    useEffect(() => {
        // console.log(user);
        if (!isNewUser) {

            setLocalUserProfile(userProfileDoc);
            if (user.isLoggedIn) {
                if (user.userInfo) {
                    setLocalUserProfile({
                        ...localUserProfile,
                        DateOfBirth: localUserProfile && localUserProfile.DateOfBirth && localUserProfile.DateOfBirth.seconds ?
                            new Date(localUserProfile.DateOfBirth.seconds * 1000) : new Date()
                    })

                    setSigninFlag(true)

                }
            }
            else {
                setSigninFlag(false);

                // navigate("/PhoneSignUp", { state: { url: 'Profile' } });
            }
        }
    }, [user, userProfileDoc])
    async function regSaveProfileDetails(e) {

        var para = {};
        para = {
            UserID: user && user.userInfo && user.userInfo.uid,
            Address: localUserProfile.Address,
            City: localUserProfile.City,
            Country: localUserProfile.Country,
            DateOfBirth: localUserProfile.DateOfBirth ?
                localUserProfile.DateOfBirth.seconds ? new Date(localUserProfile.DateOfBirth.seconds * 1000) : new Date(localUserProfile.DateOfBirth)
                : new Date(),
            Email: localUserProfile.Email,
            Gender: localUserProfile.Gender,
            Pincode: localUserProfile.Pincode,
            PlayerID: localUserProfile.PlayerID,
            ProfilePicURL: localUserProfile.ProfilePicURL,
            SelectedRole: localUserProfile.SelectedRole,
            State: localUserProfile.State,
            UserName: localUserProfile.UserName,
            UserRole: localUserProfile.UserRole,
        };
        // console.log(para);
        // console.log('in true')
        setLoading(true);

        const ret = await httpsCallable(functions, "updateProfileDetailsNew");
        ret(para).then(async (result) => {
            // props.addNewMember(false, playerDetailsCopy.PlayerID);
            setLoading(false);
            window.localStorage.setItem("userProfile", JSON.stringify(localUserProfile));
            // console.log("From Function " + result.data);
            setShowSaveError(true);

            setTimeout(function () {
                setShowSaveError(false);
                // navigate(-1);
            }, 3000);


        });

    }


    function onChangeValueMale(checked) {
        checked && setLocalUserProfile({
            ...localUserProfile,
            Gender: 'Male'
        });
        !checked && setLocalUserProfile({
            ...localUserProfile,
            Gender: 'Female'
        });
    }

    function onChangeValueFemale(checked) {

        checked && setLocalUserProfile({
            ...localUserProfile,
            Gender: 'Female'
        });
        !checked && setLocalUserProfile({
            ...localUserProfile,
            Gender: 'Male'
        });
    }

    function funSelectedRole(type) {
        setLocalUserProfile({
            ...localUserProfile,
            SelectedRole: type
        })
        window.localStorage.setItem("SelectedRole", JSON.stringify({
            uid: user.userInfo.uid,
            selectedRole: type
        }));
    }

    const handleFileChange = async (e) => {
        setThumbnail(null)
        let file = e.target.files[0]
        setLoadImage(true);
        const compressedImage = await imgUpload(file, 300, 300);

        if (!compressedImage) {
            setThumbnailError('Please select a file')
            setLoadImage(false);
            return
        }
        if (!compressedImage.type.includes('image')) {
            setThumbnailError('Selected file must be an image')
            setLoadImage(false);
            return
        }

        // if (newImageFile.size > 20000000) {
        //   setThumbnailError('Image file size must be less than 20mb')
        //   return
        // }

        setThumbnailError(null)
        // setThumbnail(compressedImage)

        if (compressedImage) {
            // console.log('thumbnail in useSignup 2:', thumbnail)
            // let ts = Date.now();
            // console.log(ts);
            let uploadPath = '';

            uploadPath = `userThumbnails/${user.userInfo.uid}/${'thumbnail' + localUserProfile.PlayerID + '.png'}`
            // console.log('uploadPath : changed :: ', uploadPath);
            const imgRef = ref(projectStorage, uploadPath);

            uploadBytes(imgRef, compressedImage)
                .then((snapshot) => {
                    getDownloadURL(imgRef).then(async (urlRef) => {
                        // console.log('urlRef', urlRef);
                        setLocalUserProfile({
                            ...localUserProfile,
                            ProfilePicURL: urlRef
                        })
                        //save is DB

                        // console.log('image updated successfully : ', playerDetailsCopy);
                        //update in DB
                        console.log(localUserProfile)
                        let para1 = {
                            userID: localUserProfile.id,
                            ProfilePicURL: urlRef,
                        };
                        // console.log(para1);
                        // setLoading(true);
                        const ret1 = httpsCallable(functions, "saveProfilePicture");
                        ret1(para1).then(async (result) => {
                            // console.log('JSON.stringify(localUserProfile) : ', JSON.stringify(localUserProfile))
                            setShowSaveError(true);

                            setTimeout(function () {
                                setShowSaveError(false);

                            }, 3000);
                            window.localStorage.setItem("userProfile", JSON.stringify(localUserProfile));

                            setLoadImage(false);

                        });

                    });
                })
        }
    }
    // console.log('localUserProfile', localUserProfile)
    return (
        <>
            {flagLoad && <Loading></Loading>}

            <br />
            {!signinFlag && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>}

            {!flagLoad && signinFlag && <>
                <div className='container-fluid'>
                    <div className="row no-gutters">
                        <br></br>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            {localUserProfile && localUserProfile.PlayerID !== '' && <div className='upload-img-div' >
                                <img src={localUserProfile && localUserProfile.ProfilePicURL ? localUserProfile.ProfilePicURL :
                                    localUserProfile && localUserProfile.Gender === 'Male' ? "./img/user_icon.png" : "./img/user_icon.png"} alt="" />
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    id='profilePic'
                                    className='profile-pic-input'
                                />

                                <label htmlFor='profilePic'>
                                    <span className="material-symbols-outlined profile-pic-icon">
                                        upload
                                    </span>
                                </label>

                                {loadImage && <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0,0,0,0.7)',
                                    width: '100%',
                                    height: '100%',
                                    maxWidth: '200px',
                                    maxHeight: '200px',
                                    borderRadius: '50%'
                                }}>
                                    <div style={{
                                        width: '150px',
                                        height: '150px'
                                    }}>
                                        <lottie-player id="btnSendOTPLoad"
                                            src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" width="50px" height="50px" background="transparent"
                                            speed="0.7" loop autoplay></lottie-player>
                                    </div>
                                </div>}
                            </div>
                            }
                            <div className='upload-img-div-details'>
                                <h1>{localUserProfile && localUserProfile.Phone}</h1>
                                <h2>{localUserProfile && localUserProfile.PlayerID}</h2>

                                <div style={{ position: 'relative', padding: '10px', borderRadius: '8px', border: '1px solid #348DCB', width: 'calc(100% - 18px)' }}>
                                    <span className="already-active"
                                        style={{ position: 'relative', left: '0', top: '-10px', background: 'none' }}>Gender
                                    </span>
                                    <div className="row no-gutters">

                                        <div className="col-6" >

                                            <input type="radio" checked={localUserProfile && localUserProfile.Gender === 'Male' ? true : false}
                                                onChange={(e) => {
                                                    e.target.checked && onChangeValueMale(e.target.checked);
                                                }} className="checkbox" style={{ width: '0px' }}
                                                name="EventStatus" id="regParticipantMale" value="Male" />
                                            <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                className="checkbox-label" id="regParticipantMaleLabel"
                                                htmlFor="regParticipantMale">
                                                <i className="fas fa-plus"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <i className="fas fa-check"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <span>Male</span>
                                            </label>

                                        </div>

                                        <div className="col-6">
                                            <input type="radio" checked={localUserProfile && localUserProfile.Gender === 'Female' ? true : false}
                                                onChange={(e) => {
                                                    e.target.checked && onChangeValueFemale(e.target.checked);
                                                }} className="checkbox" style={{ width: '0px' }}
                                                name="EventStatus" id="regParticipantFemale" value="Female" />
                                            <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                className="checkbox-label" id="regParticipantFemaleLabel"
                                                htmlFor="regParticipantFemale">
                                                <i className="fas fa-plus"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <i className="fas fa-check"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <span>Female</span>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>

                        {localUserProfile && localUserProfile.PlayerID !== '' && <div className="col-lg-4 col-md-6 col-sm-12">

                            <div className="reg-participant-form-field">
                                <input type="text" id="tbUserName" required onChange={(e) => {
                                    setLocalUserProfile({
                                        ...localUserProfile,
                                        UserName: e.target.value
                                    });

                                }} value={localUserProfile && localUserProfile.UserName ? localUserProfile.UserName : ''} />
                                <span>Name</span>
                            </div>

                            <div className="reg-participant-form-field">
                                <input type="text" required onChange={(e) => {
                                    setLocalUserProfile({
                                        ...localUserProfile,
                                        Email: e.target.value
                                    });
                                    // console.log(playerDetailsCopy);

                                }} value={localUserProfile && localUserProfile.Email} />
                                <span className='' >Email</span>
                            </div>

                            <div className="event-detail-input-div"
                                style={{ position: 'relative', padding: '15px 5px', }}>

                                <DatePicker
                                    showMonthDropdown
                                    showYearDropdown
                                    dateFormat="dd-MMM-yyyy"

                                    maxDate={new Date()}
                                    selected={localUserProfile && localUserProfile.DateOfBirth ?
                                        localUserProfile.DateOfBirth._seconds ? new Date(localUserProfile.DateOfBirth._seconds * 1000) :
                                            localUserProfile && localUserProfile.DateOfBirth ? localUserProfile.DateOfBirth
                                                : new Date() : new Date()}
                                    onChange={(date) => {

                                        // <DatePicker selected={localUserProfile && localUserProfile.DateOfBirth ?
                                        //     localUserProfile.DateOfBirth._seconds ? new Date(localUserProfile.DateOfBirth._seconds * 1000) : localUserProfile && localUserProfile.DateOfBirth
                                        //     : new Date()}
                                        //     onChange={(date) => {

                                        // console.log(date)
                                        setLocalUserProfile({
                                            ...localUserProfile,
                                            DateOfBirth: new Date(date)
                                        })
                                    }} />

                                <span className="already-active"
                                    style={{ zIndex: '6', position: 'absolute', top: '5px', left: '12px', padding: '0 8px' }}>Date Of Birth</span>
                                <span style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-55%)', color: '#333C5D', pointerEvents: 'none' }} className="material-symbols-outlined">
                                    calendar_month
                                </span>
                            </div>

                            <div style={{ padding: '10px 18px' }}>
                                <div className="row no-gutters" style={{ overflow: 'hidden', borderRadius: '8px', border: '1px solid #348DCB' }}>

                                    <span className="already-active"
                                        style={{ position: 'relative', left: '12px', top: '-0', background: 'none' }}>User Role
                                    </span>

                                    {localUserProfile && localUserProfile.UserRole && localUserProfile.UserRole.map((role) => (

                                        <div className="col-6" style={{ padding: '10px' }} key={role.TYPE}>
                                            <input type="radio" className="checkbox" style={{ width: '0px' }}
                                                onChange={(e) => {
                                                    e.target.checked && funSelectedRole(role.TYPE)
                                                }}
                                                name="userRole" id={"reg" + role.TYPE} value={role.TYPE}
                                                checked={localUserProfile && localUserProfile.SelectedRole ? (localUserProfile.SelectedRole.toUpperCase() === role.TYPE.toUpperCase() ? true : false) : false} />
                                            <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                className="checkbox-label" id={"reg" + role.TYPE + "Label"}
                                                htmlFor={"reg" + role.TYPE}>
                                                <i className="fas fa-plus"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <i className="fas fa-check"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <span style={{ fontSize: '0.7rem' }}>{role.TYPE}</span>
                                            </label>
                                        </div>
                                    ))}
                                    {!localUserProfile || (localUserProfile && !localUserProfile.UserRole) &&

                                        <div className="col-6" style={{ padding: '10px' }} key={'PARTICIPANT'}>
                                            <input type="radio" className="checkbox" style={{ width: '0px' }}
                                                onChange={(e) => {
                                                    e.target.checked && funSelectedRole('PARTICIPANT')
                                                }}
                                                name="userRole" id={"regPARTICIPANT"} value={'PARTICIPANT'}
                                                checked={localUserProfile ? (localUserProfile.SelectedRole === 'PARTICIPANT' ? true : false) : false} />
                                            <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                className="checkbox-label" id={"reg" + 'PARTICIPANT' + "Label"}
                                                htmlFor={"reg" + 'PARTICIPANT'}>
                                                <i className="fas fa-plus"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <i className="fas fa-check"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <span style={{ fontSize: '0.7rem' }}>{'PARTICIPANT'}</span>
                                            </label>
                                        </div>
                                    }

                                </div>

                            </div>

                        </div>}


                        <div className="col-lg-4 col-md-6 col-sm-12">
                            {/* <br /> */}
                            <div className="reg-participant-form-field"
                                style={{ width: '100%', display: 'none' }}>
                                <input type="number" id="pinCode" maxLength={6}
                                    onInput={(e) => {
                                        if (e.target.value.length > e.target.maxLength)
                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                    }}

                                    value={localUserProfile && localUserProfile.Pincode}
                                    onChange={(e) => {

                                        setLocalUserProfile({
                                            ...localUserProfile,
                                            Pincode: e.target.value
                                        })


                                    }}
                                    onBlur={async (e) => {
                                        // console.log('onblur', pincode)
                                        if (localUserProfile.Pincode.length !== 6) {

                                            setShowError(true);
                                        } else {
                                            setShowError(false);
                                            await $.getJSON("https://api.postalpincode.in/pincode/" + localUserProfile.Pincode,
                                                async function (data) {
                                                    if (data[0].Message === "No records found") {
                                                        setShowError(true);
                                                        setTimeout(function () {
                                                            setShowError(false);
                                                        }, 5000);

                                                    } else {

                                                        setLocalUserProfile({
                                                            ...localUserProfile,
                                                            Pincode: localUserProfile.Pincode,
                                                            District: data[0].PostOffice[0].District,
                                                            City: data[0].PostOffice[0].Block,
                                                            State: data[0].PostOffice[0].State,
                                                            Country: data[0].PostOffice[0].Country
                                                        })

                                                    }
                                                });
                                        }
                                    }}
                                    required />
                                <span className="pin-code-span">Pin Code</span>
                                <h5 style={{ display: !showError ? 'block' : 'none', }}>{localUserProfile && localUserProfile.City} {localUserProfile && localUserProfile.City !== '' && localUserProfile.City !== undefined ? ',' : ''} {localUserProfile && localUserProfile.State}</h5>
                                <h5 style={{ display: showError ? 'block' : 'none' }}>Please enter Valid Pincode</h5>

                            </div>

                            <div className="reg-participant-form-field">
                                <input type="text" required onChange={(e) => {

                                    setLocalUserProfile({
                                        ...localUserProfile,
                                        City: e.target.value
                                    })

                                }} value={localUserProfile && localUserProfile.City} />
                                <span id="idNumber">City</span>
                            </div>

                            <div className="reg-participant-form-field">
                                <textarea style={{ resize: 'none' }} name="" id="tbParticipantAddress" onChange={(e) => {
                                    setLocalUserProfile({
                                        ...localUserProfile,
                                        Address: e.target.value
                                    })

                                }} value={localUserProfile && localUserProfile.Address} required></textarea>
                                <span>User Address</span>
                            </div>

                        </div>

                    </div>

                    <div className="row no-gutters">

                        <div className="col-lg-12 col-md-12 col-sm-12"><br />

                            <div className="ref-participant-save-div">

                                <button className="mybutton button5" style={{ height: '35px' }} onClick={regSaveProfileDetails}>
                                    <div style={{ display: !loading ? 'block' : 'none' }}>
                                        <span
                                            style={{ paddingLeft: '8px', position: 'relative', top: '-1px', fontSize: '1rem' }}>SAVE</span>
                                        <span
                                            style={{ position: 'relative', top: '3px', paddingLeft: '5px', fontSize: '1.1rem' }}
                                            className="material-symbols-outlined">
                                            arrow_right_alt
                                        </span>
                                    </div>
                                    <div className='btn-loading' style={{ display: loading ? 'block' : 'none' }}>
                                        <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                            speed="0.7" loop autoplay></lottie-player>
                                    </div>
                                </button>

                            </div>
                            {showSaveError && <center>
                                <span>Details saved Successfully !!  </span>
                            </center>}
                            <br />
                        </div>

                    </div>
                </div>
            </>
            }
        </>
    )
}
