import React, { useState, useEffect } from 'react'
import { useUserAuth } from '../context/UserAuthcontext'
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";
import useDocumentUpdate from '../hooks/useDocumentUpdate';
// import { format, parse } from "date-fns"; // To format and parse dates
// import DatePicker from "react-datepicker";
// import '../css/MydatePicker.css'
// import "react-datepicker/dist/react-datepicker.css";


export default function EDTournamentDetails(props) {
    // console.log(props)
    const { user, users, logout } = useUserAuth();
    const navigate = useNavigate();
    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();

    const [isEditEnable, setIsEditEnable] = useState(props.IsEditEnabled)
    const [editStartDate, setEditStartDate] = useState(false)
    const [startDate, setStartDate] = useState(
        props.eventDetails && props.eventDetails.EventStartDate && props.eventDetails.EventStartDate.seconds ? (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0] :
            props.eventDetails && props.eventDetails.EventStartDate && props.eventDetails.EventStartDate._seconds ? (new Date(props.eventDetails.EventStartDate._seconds * 1000)).toISOString().split("T")[0] :
                (new Date()).toISOString().split("T")[0]

    );

    const [editEndDate, setEditEndDate] = useState(false)
    const [endDate, setEndDate] = useState(
        props.eventDetails && props.eventDetails.EventEndDate && props.eventDetails.EventEndDate.seconds ? (new Date(props.eventDetails.EventEndDate.seconds * 1000)).toISOString().split("T")[0] :
            props.eventDetails && props.eventDetails.EventEndDate && props.eventDetails.EventEndDate._seconds ? (new Date(props.eventDetails.EventEndDate._seconds * 1000)).toISOString().split("T")[0] :
                (new Date()).toISOString().split("T")[0]
    )
    const [editWithdrawDate, setEditWithdrawDate] = useState(false)
    const [withdrawDate, setWithdrawDate] = useState(
        props.eventDetails && props.eventDetails.WithdrawalEndDate && props.eventDetails.WithdrawalEndDate.seconds ? (new Date(props.eventDetails.WithdrawalEndDate.seconds * 1000)).toISOString().split("T")[0] :
            props.eventDetails && props.eventDetails.WithdrawalEndDate && props.eventDetails.WithdrawalEndDate._seconds ? (new Date(props.eventDetails.WithdrawalEndDate._seconds * 1000)).toISOString().split("T")[0] :
                props.eventDetails && props.eventDetails.EventStartDate && props.eventDetails.EventStartDate.seconds ? (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0] :
                    props.eventDetails && props.eventDetails.EventStartDate && props.eventDetails.EventStartDate._seconds ? (new Date(props.eventDetails.EventStartDate._seconds * 1000)).toISOString().split("T")[0] :
                        (new Date()).toISOString().split("T")[0]
    );

    const [editRegistrationStartDate, setEditRegistrationStartDate] = useState(false)
    const [registrationStartDate, setRegistrationStartDate] = useState(
        props.eventDetails && props.eventDetails.RegistrationStartDate && props.eventDetails.RegistrationStartDate.seconds ? (new Date(props.eventDetails.RegistrationStartDate.seconds * 1000)).toISOString().split("T")[0] :
            props.eventDetails && props.eventDetails.RegistrationStartDate && props.eventDetails.RegistrationStartDate._seconds ? (new Date(props.eventDetails.RegistrationStartDate._seconds * 1000)).toISOString().split("T")[0] :
                props.eventDetails && props.eventDetails.EventStartDate && props.eventDetails.EventStartDate.seconds ? (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0] :
                    props.eventDetails && props.eventDetails.EventStartDate && props.eventDetails.EventStartDate._seconds ? (new Date(props.eventDetails.EventStartDate._seconds * 1000)).toISOString().split("T")[0] :
                        (new Date()).toISOString().split("T")[0]
    );

    const [editRegistrationEndtDate, setEditRegistrationEndDate] = useState(false)
    const [registrationEndDate, setRegistrationEndDate] = useState(
        // props.eventDetails.RegistrationEndDate ? (new Date(props.eventDetails.RegistrationEndDate.seconds * 1000)).toISOString().split("T")[0] : (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]
        props.eventDetails && props.eventDetails.RegistrationEndDate && props.eventDetails.RegistrationEndDate.seconds ? (new Date(props.eventDetails.RegistrationEndDate.seconds * 1000)).toISOString().split("T")[0] :
            props.eventDetails && props.eventDetails.RegistrationEndDate && props.eventDetails.RegistrationEndDate._seconds ? (new Date(props.eventDetails.RegistrationEndDate._seconds * 1000)).toISOString().split("T")[0] :
                props.eventDetails && props.eventDetails.EventStartDate && props.eventDetails.EventStartDate.seconds ? (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0] :
                    props.eventDetails && props.eventDetails.EventStartDate && props.eventDetails.EventStartDate._seconds ? (new Date(props.eventDetails.EventStartDate._seconds * 1000)).toISOString().split("T")[0] :
                        (new Date()).toISOString().split("T")[0]
    );

    const [editVenue, setEditVenue] = useState(false)
    const [venue, setVenue] = useState(props.eventDetails && props.eventDetails.EventVenue);

    const [editLocality, setEditLocality] = useState(false)
    const [locality, setLocality] = useState(props.eventDetails && props.eventDetails.Locality);

    const [editVenueMap, setEditVenueMap] = useState(false)
    const [venueMap, setVenueMap] = useState(props.eventDetails && props.eventDetails.LocationMap);
    const [eventDetails, setEventDetails] = useState(props.eventDetails);

    const [eventName, setEventName] = useState(props.eventDetails.EventName);
    const [organizationName, setOrganizationName] = useState(props.eventDetails.OrganizationName);
    const [editEventName, setEditEventName] = useState(false);

    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    var options = {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
        // year: 'numeric',
    };

    useEffect(() => {
        // let flag = false;
        setIsEditEnable(props.IsEditEnabled);
        setVenue(props.eventDetails && props.eventDetails.EventVenue)
        setStartDate((new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]);
        setEndDate(props.eventDetails.EventEndDate ? (new Date(props.eventDetails.EventEndDate.seconds * 1000)).toISOString().split("T")[0] : (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]);
        setWithdrawDate(props.eventDetails.WithdrawalEndDate ? (new Date(props.eventDetails.WithdrawalEndDate.seconds * 1000)).toISOString().split("T")[0] : (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]);
        setRegistrationStartDate(props.eventDetails.RegistrationStartDate ? (new Date(props.eventDetails.RegistrationStartDate.seconds * 1000)).toISOString().split("T")[0] : (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]);
        setRegistrationEndDate(props.eventDetails.RegistrationEndDate ? (new Date(props.eventDetails.RegistrationEndDate.seconds * 1000)).toISOString().split("T")[0] : (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]);
        // setVenue(props.eventDetails.EventVenue);
        setVenueMap(props.eventDetails.LocationMap);
        setEventName(props.eventDetails.EventName)
        setOrganizationName(props.eventDetails.OrganizationName)
        setEventDetails(props.eventDetails)
    }, [props.IsEditEnabled, props.eventDetails])

    // function registerInEvent() {
    //     // RegisteredProfile
    //     if (eventDetails.EventURL && eventDetails.EventURL === '')
    //         navigate("/RegisteredProfile");
    //     else
    //         navigate(eventDetails.EventURL)
    // }

    async function saveEventName() {
        console.log(eventName, props.eventDetails.EventName, eventDetails.id)
        // if (eventName !== props.eventDetails.EventName) {
        let _updatedData = {
            'EventName': eventName,
            'OrganizationName': organizationName,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()

        }
        await updateDocument('EventList', eventDetails.id, _updatedData)
        // }

    }
    async function saveStartDate() {

        if (new Date(startDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]) {
            let _updatedData = {
                'EventStartDate': new Date(startDate),
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()

            }
            await updateDocument('EventList', eventDetails.id, _updatedData)
        }
        // var para = {};

        // para = {
        //     EventID: eventDetails.id,
        //     StartDate: startDate
        // }

        // if (new Date(startDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]) {

        //     // setLoading(true);
        //     const ret = await httpsCallable(functions, "saveStartDate");
        //     ret(para).then(async (result) => {
        //         console.log(result);
        //         // setLoading(false);
        //     })
        // }
    }
    async function saveEndDate() {
        if (new Date(endDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.EventEndDate.seconds * 1000)).toISOString().split("T")[0]) {
            // console.log('in saveEndDate endDate', new Date(endDate))
            let _updatedData = {
                'EventEndDate': new Date(endDate),
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()

            }
            await updateDocument('EventList', eventDetails.id, _updatedData)
        }
        // var para = {};
        // console.log('props : ', props)
        // para = {
        //     EventID: eventDetails.id,
        //     EndDate: endDate
        // }
        // if (new Date(endDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.EventEndDate.seconds * 1000)).toISOString().split("T")[0]) {

        //     console.log('para::', para)
        //     // setLoading(true);
        //     const ret = await httpsCallable(functions, "saveEndDate");
        //     ret(para).then(async (result) => {
        //         console.log(result);
        //         // setLoading(false);
        //     })
        // }
    }


    async function saveWithdrawDate() {
        if (!props.eventDetails.WithdrawalEndDate || (props.eventDetails.WithdrawalEndDate &&
            new Date(withdrawDate).toISOString().split("T")[0] !=
            (new Date(props.eventDetails.WithdrawalEndDate.seconds * 1000)).toISOString().split("T")[0])) {


            // if (new Date(withdrawDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.WithdrawalEndDate.seconds * 1000)).toISOString().split("T")[0]) {

            let _updatedData = {
                'WithdrawalEndDate': new Date(withdrawDate),
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()

            }
            await updateDocument('EventList', eventDetails.id, _updatedData)

        }
        // var para = {};
        // console.log('props : ', props)
        // para = {
        //     EventID: props.eventDetails.id,
        //     WithdrawDate: withdrawDate
        // }
        // if (new Date(withdrawDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.WithdrawalEndDate.seconds * 1000)).toISOString().split("T")[0]) {

        //     console.log('para::', para)
        //     // setLoading(true);
        //     const ret = await httpsCallable(functions, "saveWithdrawDate");
        //     ret(para).then(async (result) => {
        //         console.log(result);
        //         // setLoading(false);
        //     })
        // }
    }

    async function saveRegistrationStartDate() {
        if (!props.eventDetails.RegistrationStartDate || (props.eventDetails.RegistrationStartDate &&
            new Date(registrationStartDate).toISOString().split("T")[0] !=
            (new Date(props.eventDetails.RegistrationStartDate.seconds * 1000)).toISOString().split("T")[0])) {

            // if (!props.eventDetails.RegistrationStartDate || (!props.eventDetails && props.eventDetails.RegistrationStartDate && new Date(registrationStartDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.RegistrationStartDate.seconds * 1000)).toISOString().split("T")[0])) {
            let _updatedData = {
                'RegistrationStartDate': new Date(registrationStartDate),
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()

            }
            await updateDocument('EventList', eventDetails.id, _updatedData)
        }
        // var para = {};
        // console.log('props : ', props)
        // para = {
        //     EventID: props.eventDetails.id,
        //     RegistrationStartDate: registrationStartDate
        // }
        // if (!props.eventDetails.RegistrationStartDate || (!props.eventDetails && props.eventDetails.RegistrationStartDate && new Date(registrationStartDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.RegistrationStartDate.seconds * 1000)).toISOString().split("T")[0])) {

        //     console.log('para::', para)
        //     // setLoading(true);
        //     const ret = await httpsCallable(functions, "saveRegistrationStartDate");
        //     ret(para).then(async (result) => {
        //         console.log(result);
        //         // setLoading(false);
        //     })
        // }
    }


    async function saveRegistrationEndDate() {
        if (!props.eventDetails.RegistrationEndDate || (props.eventDetails.RegistrationEndDate &&
            new Date(registrationEndDate).toISOString().split("T")[0] !=
            (new Date(props.eventDetails.RegistrationEndDate.seconds * 1000)).toISOString().split("T")[0])) {


            // if (new Date(registrationEndDate).toISOString().split("T")[0] !== (new Date(props.eventDetails.RegistrationEndDate.seconds * 1000)).toISOString().split("T")[0]) {
            let _updatedData = {
                'RegistrationEndDate': new Date(registrationEndDate),
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()

            }
            await updateDocument('EventList', eventDetails.id, _updatedData)
        }


    }

    async function saveVenue() {
        if (venue !== props.eventDetails.EventVenue) {

            let _updatedData = {
                'EventVenue': venue,
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()

            }
            await updateDocument('EventList', eventDetails.id, _updatedData)
        }
        // var para = {};
        // console.log('props : ', props)
        // para = {
        //     EventID: props.eventDetails.id,
        //     EventVenue: venue
        // }
        // // setLoading(true);
        // if (venue !== props.eventDetails.EventVenue) {
        //     console.log('para::', para)

        //     const ret = await httpsCallable(functions, "saveEventVenue");
        //     ret(para).then(async (result) => {
        //         console.log(result);
        //         // setLoading(false);
        //     })
        // }

    }
    async function saveVenueMap() {
        if (venueMap !== props.eventDetails.LocationMap) {

            let _updatedData = {
                'LocationMap': venueMap,
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()

            }
            await updateDocument('EventList', eventDetails.id, _updatedData)
        }
    }
    async function saveLocality() {
        if (locality !== props.eventDetails.Locality) {

            let _updatedData = {
                'Locality': locality,
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()

            }
            await updateDocument('EventList', eventDetails.id, _updatedData)
        }
    }

    function formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + '-' + month + '-' + year;
    }

    function showDraw() {
        console.log('props.eventDetails', props.eventDetails)
        // navigate('/ShowDraw', { state: { EventDetails: props.eventDetails, partcipantObj: props.partcipantObj, rentryCount: props.rentryCount, ShowMenu: true } })
        navigate("/ShowDraw/" + props.eventDetails.id + "/true");
        // { state: { EventDetails: props.eventDetails, partcipantObj: props.partcipantObj, rentryCount: props.rentryCount, ShowMenu: true } })
    }
    // const refSDate = new Date(props.eventDetails.EventStartDate.seconds * 1000);
    // const refEDate = new Date(props.eventDetails.EventEndDate.seconds * 1000);
    const refWDate = props.eventDetails.WithdrawalEndDate ? new Date(props.eventDetails.WithdrawalEndDate.seconds * 1000) : null;
    // console.log(new Date(props.eventDetails.EventEndDate.seconds * 1000))
    //element.EventStartDate = refdate.toLocaleDateString("en-IN", options);
    return (
        <div className="col-lg-4 col-md-12 col-sm-12">
            {/* {props.showRegistration && props.eventDetails.RegistrationOpenFlag === 'ON' && user &&
                <div className="register-btn-fixed small">
                    <button className="mybutton button5" onClick={registerInEvent}>Register</button>
                </div>
            } */}
            {/* need to uncomment */}
            {/* {props.eventDetails.RegistrationOpenFlag !== 'YES' && props.eventDetails.EventMode && props.eventDetails.EventMode.toUpperCase() === 'FIXTURE' && */}
            {/* {true && <div className="register-btn-fixed small">

                <button type="button" name="button" className="mybutton button5" onClick={() => {
                    showDraw();
                }} style={{ background: '#348DCB' }}>
                    Draw
                </button>

            </div>} */}

            {props.eventDetails.RegistrationOpenFlag === 'ON' && props.showRegistrationButton && <div className='sticky-register-button small' onClick={() => {
                navigate("/RegisteredProfile/" + eventDetails.id)
            }}>
                <button className='mybutton button5'>Register Now</button>
            </div>}

            <div className="firstbox" style={{ paddingBottom: '0', }}>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        {!editEventName && <h4 style={{ fontWeight: '1000', color: '#348DCB', margin: '0' }}> {eventName}
                        </h4>}

                        {editEventName &&
                            <input type="text"
                                style={{
                                    width: '100%',
                                    fontSize: '1.5rem',
                                    color: '#348DCB',
                                    fontWeight: 'bold',
                                    background: '#eee',
                                    border: '1px dashed #aaa',
                                    outline: 'none',
                                }}
                                // value={(new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]}
                                value={eventName}
                                onChange={(e) => {
                                    setEventName(e.target.value);
                                    // console.log(e.target.value)
                                }}
                            // min={new Date().toISOString().split("T")[0]}
                            />
                        }
                        {isEditEnable && !editEventName && <span style={{
                            position: 'relative', top: '10px', fontSize: '1.3rem',
                            color: '#348DCB', cursor: 'pointer', paddingLeft: '10px'
                        }}

                            className="material-symbols-outlined"
                            onClick={() => {
                                setEditEventName(true)
                            }}
                        >
                            edit
                        </span>}

                        {isEditEnable && editEventName && <span style={{ position: 'relative', top: '10px', fontSize: '1.5rem', color: '#348DCB', cursor: 'pointer', paddingLeft: '10px' }}

                            className="material-symbols-outlined"
                            onClick={() => {
                                saveEventName()
                                setEditEventName(false)
                            }}
                        >
                            check
                        </span>}


                    </div>
                    {!editEventName && <h1 style={{ fontSize: '1rem' }}>{organizationName}</h1>}
                    {editEventName && <input type="text"
                        style={{
                            width: '100%',
                            fontSize: '1rem',
                            color: '#333C5D',
                            fontWeight: 'bold',
                            background: '#eee',
                            border: '1px dashed #aaa',
                            outline: 'none',
                            width: '90%'
                        }}
                        value={organizationName}
                        onChange={(e) => {
                            setOrganizationName(e.target.value);
                            // console.log(e.target.value)
                        }}
                        placeholder='Organization Name'
                    />}
                </div>
                {/* <input type="hidden"  value={props.eventDetails.id} />
                <input type="hidden"  value={props.eventDetails.OrganizationID} /> */}




                <hr />
                <ul>
                    {/* <li>
                        <i className="far fa-bookmark"></i>
                        <span className="textheadleft">Organiser : </span>
                        <span className="textheadright">{props.eventDetails.OrganizationName}</span>
                    </li> */}
                    <li>
                        <div className='event-details-venue-edit'>
                            <span className="material-symbols-outlined">
                                calendar_month
                            </span>
                            <div style={{ position: 'relative', top: '-3px' }}>
                                <span className="textheadleft">Start Date : </span>
                                {/* <span className="textheadright">{props.eventDetails.EventSDate}</span> */}
                                {!editStartDate && <span style={{ border: '1px solid transparent', paddingLeft: '5px', color: startDate > endDate ? 'Red' : '' }}
                                    className="textheadright"

                                > {formatDate(startDate)}</span>}

                                {editStartDate &&
                                    // <DatePicker
                                    //     showMonthDropdown
                                    //     showYearDropdown
                                    //     // maxDate={new Date()}
                                    //     dateFormat="dd-MMM-yyyy"
                                    //     // style={{ color: startDate > endDate ? 'Red' : '', transform: 'translateX(9px)' }}

                                    //     selected={new Date(startDate)}
                                    //     onChange={(date) => {
                                    //         console.log(new Date(date))
                                    //         setStartDate(new Date(date));
                                    //     }}
                                    // />
                                    <input type="date"
                                        // value={(new Date(props.eventDetails.EventStartDate.seconds * 1000)).toISOString().split("T")[0]}
                                        value={startDate}
                                        style={{ color: startDate > endDate ? 'Red' : '' }}
                                        readOnly={!editStartDate ? true : false}
                                        className='event-details-date-picker'
                                        onChange={(e) => {
                                            setStartDate(e.target.value);
                                            // console.log(e.target.value)
                                        }}
                                    // min={new Date().toISOString().split("T")[0]}
                                    />
                                }
                            </div>
                            {isEditEnable && !editStartDate && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    setEditStartDate(true)
                                }}
                            >
                                edit
                            </span>}

                            {isEditEnable && editStartDate && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    saveStartDate()
                                    setEditStartDate(false)
                                }}
                            >
                                check
                            </span>}

                            {/* <span style={{ position: 'relative', top: '5px', fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}
                            className="material-symbols-outlined"
                            // onClick={setEditStartDate(true)}>
                            edit
                        </span> */}
                        </div>
                    </li>
                    <li>
                        <div className='event-details-venue-edit'>
                            <span className="material-symbols-outlined">
                                {/* calendar_month */}
                                event_busy

                            </span>
                            <div style={{ position: 'relative', top: '-3px' }}>
                                <span className="textheadleft">End Date : </span>
                                {!editEndDate && <span style={{
                                    paddingLeft: '5px', color: startDate > endDate ? 'Red' : ''
                                }} className="textheadright"> {formatDate(endDate)}</span>}

                                {/* <span className="textheadright">{props.eventDetails.EventEDate}</span> */}
                                {editEndDate &&
                                    // <DatePicker
                                    //     showMonthDropdown
                                    //     showYearDropdown
                                    //     // maxDate={new Date()}
                                    //     dateFormat="dd-MMM-yyyy"
                                    //     // style={{ color: startDate > endDate ? 'Red' : '', transform: 'translateX(9px)' }}

                                    //     selected={new Date(endDate)}
                                    //     onChange={(date) => {
                                    //         console.log(new Date(date))
                                    //         setEndDate(new Date(date));
                                    //     }}
                                    // />
                                    <input type="date"
                                        // value={(new Date(props.eventDetails.EventEndDate.seconds * 1000)).toISOString().split("T")[0]} 
                                        // className='event-details-date-picker' readOnly min={new Date().toISOString().split("T")[0]} 
                                        value={endDate}
                                        style={{ color: startDate > endDate ? 'Red' : '', transform: 'translateX(9px)' }}

                                        readOnly={!editEndDate ? true : false}
                                        className='event-details-date-picker'
                                        onChange={(e) => {
                                            setEndDate(e.target.value);
                                            // console.log(e.target.value)
                                        }}

                                    />
                                }
                            </div>
                            {isEditEnable && !editEndDate && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    setEditEndDate(true)
                                }}
                            >
                                edit
                            </span>}

                            {isEditEnable && editEndDate && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    saveEndDate()
                                    setEditEndDate(false)
                                }}
                            >
                                check
                            </span>}
                        </div>
                    </li>

                    <li>
                        <div className='event-details-venue-edit'>
                            <span className="material-symbols-outlined">
                                currency_rupee
                            </span>
                            <div style={{ position: 'relative', top: '-3px' }}>
                                <span className="textheadleft">Price : </span>
                                <span style={{ paddingLeft: '10px' }} className="textheadright"> ₹ {(props.eventDetails.MinimumFee)} onwards</span>
                            </div>
                        </div>
                    </li>
                    {/* {venueMap && venueMap !== "" && <li> */}
                    {<li>
                        <div className='event-details-venue-edit'>
                            <span className="material-symbols-outlined">
                                location_on
                            </span>
                            <div style={{ position: 'relative', top: '-3px' }}>
                                <span className="textheadleft">Venue Map : </span>
                                {/* <input type="text" style={{ color: '#889' }} readonly className="textheadright" value={venueMap}></input> */}
                                {editVenueMap && <textarea
                                    readOnly={!editVenueMap ? true : false}
                                    value={venueMap}
                                    onChange={(e) => setVenueMap(e.target.value)}
                                    style={{
                                        color: '#889',
                                        resize: 'none',
                                        height: '67px',
                                        width: '55%',
                                        marginLeft: '10px',
                                        lineHeight: '1.2',
                                        outline: 'none',
                                        border: editVenueMap ? '1px solid #aaa' : 'none',
                                        // border: 'none',
                                        padding: 'none',
                                        paddingTop: '3px',
                                        borderRadius: '4px',
                                        verticalAlign: 'text-top'
                                    }} ></textarea>}
                                {!editVenueMap && venueMap !== '' && <a href={venueMap} target='_blank' >Click here for Venue Map</a>}
                            </div>
                            {isEditEnable && !editVenueMap && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    setEditVenueMap(true)
                                }}
                            >
                                edit
                            </span>}

                            {isEditEnable && editVenueMap && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    saveVenueMap()
                                    setEditVenueMap(false)
                                }}
                            >
                                check
                            </span>}

                        </div>
                    </li>}
                    {<li>
                        <div className='event-details-venue-edit'>
                            <span className="material-symbols-outlined">
                                location_on
                            </span>
                            <div style={{ position: 'relative', top: '-3px' }}>
                                <span className="textheadleft">Locality/Area : </span>
                                {/* <input type="text" style={{ color: '#889' }} readonly className="textheadright" value={venueMap}></input> */}
                                {editLocality && <textarea
                                    readOnly={!editLocality ? true : false}
                                    value={locality}
                                    onChange={(e) => setLocality(e.target.value)}
                                    style={{
                                        color: '#889',
                                        resize: 'none',
                                        height: '67px',
                                        width: '55%',
                                        marginLeft: '10px',
                                        lineHeight: '1.2',
                                        outline: 'none',
                                        border: editLocality ? '1px solid #aaa' : 'none',
                                        // border: 'none',
                                        padding: 'none',
                                        paddingTop: '3px',
                                        borderRadius: '4px',
                                        verticalAlign: 'text-top'
                                    }} ></textarea>}
                                {!editLocality && <span>{locality}</span>}
                            </div>
                            {isEditEnable && !editLocality && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    setEditLocality(true)
                                }}
                            >
                                edit
                            </span>}

                            {isEditEnable && editLocality && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    saveLocality()
                                    setEditLocality(false)
                                }}
                            >
                                check
                            </span>}

                        </div>
                    </li>}

                    <li>
                        <div className='event-details-venue-edit'>
                            <span className="material-symbols-outlined">
                                keep
                            </span>
                            <div style={{ position: 'relative', top: '-3px' }}>
                                <span className="textheadleft">Venue : </span>
                                <textarea
                                    readOnly={!editVenue ? true : false}
                                    value={venue}
                                    maxLength={15}
                                    onChange={(e) => setVenue(e.target.value)}
                                    style={{
                                        color: '#889',
                                        resize: 'none',
                                        height: '165px',
                                        width: '70%',
                                        marginLeft: '10px',
                                        lineHeight: '1.2',
                                        outline: 'none',
                                        border: editVenue ? '1px solid #aaa' : 'none',
                                        // border: 'none',
                                        padding: 'none',
                                        paddingTop: '3px',
                                        borderRadius: '4px',
                                        verticalAlign: 'text-top'
                                    }} ></textarea>
                                {/* <input type="text"
                                    className='text-input'
                                    value={venue}
                                    readOnly={!editVenue ? true : false}
                                    onChange={(e) => {
                                        setVenue(e.target.value);
                                    }}
                                    style={{
                                        border: editVenue ? '1px solid #aaa' : 'none',
                                        borderRadius: '4px'
                                    }}
                                /> */}
                            </div>
                            {isEditEnable && !editVenue && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    setEditVenue(true)
                                }}
                            >
                                edit
                            </span>}

                            {isEditEnable && editVenue && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    saveVenue()
                                    setEditVenue(false)
                                }}
                            >
                                check
                            </span>}

                        </div>
                    </li>



                    <li style={{ padding: '0' }}>
                        {/* {props.showRegistration && props.eventDetails.RegistrationOpenFlag === 'ON' &&
                            !props.isDrawAvailable && */}
                        {props.eventDetails.RegistrationOpenFlag === 'ON' && props.showRegistrationButton &&
                            <div style={{ textAlign: 'center' }} className='large'>
                                <Link to={"/RegisteredProfile/" + eventDetails.id}>

                                    <button type="button" name="button" className="mybutton button5" style={{ background: '#b91010', width: '100%', maxWidth: '150px' }}>
                                        Register Now
                                    </button>
                                </Link>
                            </div>
                        }
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            {props.showRegistration && props.eventDetails.RegistrationOpenFlag === 'YES' &&
                                props.showRegistrationButton &&
                                !users && !props.isDrawAvailable &&
                                <Link
                                    to={"/RegisteredProfile/" + eventDetails.id} >
                                    <button type="button" name="button" className="mybutton button5" style={{ background: '#348DCB' }}>
                                        Register
                                    </button>
                                </Link>}

                            {/* {props.eventDetails.RegistrationOpenFlag !== 'YES' && props.eventDetails.EventMode && props.eventDetails.EventMode.toUpperCase() === 'FIXTURE' && <a href={"https://tournamentplanner.in/screens/TPLive_Draws.aspx?SCode=" + props.eventDetails.SportCode + "&TCode=" + props.eventDetails.EventCode} > */}
                            {/* {props.isDrawAvailable && props.eventDetails.PublishDrawFlag && <div onClick={showDraw}>
                                <button type="button" name="button" className="mybutton button5" style={{ background: '#348DCB' }}>
                                    Fixture
                                </button>
                            </div>}
                            {props.isDrawAvailable && props.eventDetails.PublishDrawFlag && <div onClick={showDraw} >
                                <button type="button" name="button" className="mybutton button5" style={{ background: '#348DCB' }}>
                                    Schedule
                                </button>
                            </div>}
                            {props.isDrawAvailable && props.eventDetails.PublishDrawFlag && <div onClick={showDraw} >
                                <button type="button" name="button" className="mybutton button5" style={{ background: '#348DCB' }}>
                                    Result
                                </button>

                            </div>} */}

                            {/* {props.isDrawAvailable && props.eventDetails && props.eventDetails.OnlinePaymentModeFlag && props.eventDetails.PublishDrawFlag && props.eventDetails.OnlinePaymentModeFlag.toLowerCase() !== 'yes' && props.eventDetails.PublishDrawFlag.toLowerCase() === 'on' &&
                                <div onClick={showDraw}>
                                    <button type="button" name="button" className="mybutton button5" style={{ background: '#348DCB' }}>
                                        Draw
                                    </button>
                                </div>} */}
                        </div>
                    </li>

                </ul>

            </div><br />

            <div className="secbox">
                <h4 style={{ fontWeight: '1000', color: '#348DCB' }}>More Information</h4>
                <hr />
                <ul>
                    <li>
                        <div className='event-details-venue-edit'>
                            <span className="material-symbols-outlined">
                                calendar_month
                            </span>


                            {/* <span className="textheadleft">Withdrawal Date : <span style={{ color: '#889' }} className="textheadright"> {refWDate ? refWDate.toLocaleDateString("en-IN", options) : ''}</span>
                        </span> */}
                            <div style={{ position: 'relative', top: '-3px' }}>
                                <span className="textheadleft">Booking Start Date : </span>
                                {!editRegistrationStartDate && <span style={{ paddingLeft: '5px', color: registrationStartDate > registrationEndDate ? 'Red' : '' }} className="textheadright">
                                    {formatDate(registrationStartDate)}
                                </span>}

                                {editRegistrationStartDate &&
                                    // <DatePicker
                                    //     showMonthDropdown
                                    //     showYearDropdown
                                    //     // maxDate={new Date()}
                                    //     dateFormat="dd-MMM-yyyy"

                                    //     selected={new Date(registrationStartDate)}
                                    //     onChange={(date) => {
                                    //         console.log(new Date(date))
                                    //         setRegistrationStartDate(new Date(date));
                                    //     }}
                                    // />
                                    <input type="date"
                                        value={registrationStartDate}
                                        style={{
                                            color: registrationStartDate > registrationEndDate ? 'Red' : '',
                                            width: '115px'
                                        }}
                                        // readOnly={!editRegistrationStartDate ? true : false}
                                        className='event-details-date-picker'
                                        onChange={(e) => {
                                            setRegistrationStartDate(e.target.value);
                                            // console.log(e.target.value)
                                        }}
                                    // min={new Date().toISOString().split("T")[0]}
                                    />
                                }
                            </div>

                            {isEditEnable && !editRegistrationStartDate && <span
                                style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    setEditRegistrationStartDate(true)
                                }}
                            >
                                edit
                            </span>}

                            {isEditEnable && editRegistrationStartDate && <span style={{ position: 'relative', top: '5px', fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    saveRegistrationStartDate()
                                    setEditRegistrationStartDate(false)
                                }}
                            >
                                check
                            </span>}

                        </div>
                    </li>

                    <li>
                        <div className='event-details-venue-edit'>
                            <span className="material-symbols-outlined">
                                event_busy

                                {/* calendar_month */}
                            </span>
                            {/* <span className="textheadleft">Withdrawal Date : <span style={{ color: '#889' }} className="textheadright"> {refWDate ? refWDate.toLocaleDateString("en-IN", options) : ''}</span>
                        </span> */}
                            <div style={{ position: 'relative', top: '-3px' }}>
                                <span className="textheadleft">Booking Close Date : </span>
                                {!editRegistrationEndtDate && <span style={{ paddingLeft: '5px', color: registrationStartDate > registrationEndDate ? 'Red' : '' }} className="textheadright">
                                    {formatDate(registrationEndDate)}
                                </span>}

                                {editRegistrationEndtDate &&
                                    // <DatePicker
                                    //     showMonthDropdown
                                    //     showYearDropdown
                                    //     // maxDate={new Date()}
                                    //     dateFormat="dd-MMM-yyyy"

                                    //     selected={new Date(registrationEndDate)}
                                    //     onChange={(date) => {
                                    //         console.log(new Date(date))
                                    //         setRegistrationEndDate(new Date(date));
                                    //     }}
                                    // />
                                    <input type="date"
                                        value={registrationEndDate}
                                        // value={registrationEndDate ? parse(registrationEndDate, "dd-MMM-yyyy", new Date()) : ""}
                                        style={{ color: registrationStartDate > registrationEndDate ? 'Red' : '', width: '115px' }}
                                        // readOnly={!editRegistrationEndtDate ? true : false}
                                        className='event-details-date-picker'
                                        onChange={(e) => {
                                            setRegistrationEndDate(e.target.value);
                                        }}
                                    // min={new Date().toISOString().split("T")[0]}
                                    />
                                }
                            </div>

                            {isEditEnable && !editRegistrationEndtDate && <span
                                style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    setEditRegistrationEndDate(true)
                                }}
                            >
                                edit
                            </span>}

                            {isEditEnable && editRegistrationEndtDate && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    saveRegistrationEndDate()
                                    setEditRegistrationEndDate(false)
                                }}
                            >
                                check
                            </span>}
                        </div>
                    </li>
                    <li>
                        <div className='event-details-venue-edit'>
                            <span className="material-symbols-outlined">
                                settings_backup_restore
                            </span>
                            {/* <span className="textheadleft">Withdrawal Date : <span style={{ color: '#889' }} className="textheadright"> {refWDate ? refWDate.toLocaleDateString("en-IN", options) : ''}</span>
                        </span> */}
                            <div style={{ position: 'relative', top: '-3px' }}>
                                <span className="textheadleft">Withdraw Date : </span>
                                {!editWithdrawDate && <span style={{ paddingLeft: '5px' }} className="textheadright">
                                    {formatDate(withdrawDate)}
                                </span>}
                                {editWithdrawDate &&
                                    // <DatePicker
                                    //     showMonthDropdown
                                    //     showYearDropdown
                                    //     // maxDate={new Date()}
                                    //     dateFormat="dd-MMM-yyyy"

                                    //     selected={new Date(withdrawDate)}
                                    //     onChange={(date) => {
                                    //         console.log(new Date(date))
                                    //         setWithdrawDate(new Date(date));
                                    //     }}
                                    // />

                                    <input type="date"
                                        value={withdrawDate}
                                        style={{ color: startDate < withdrawDate ? 'Red' : '', width: '115px' }}
                                        // readOnly={!editWithdrawDate ? true : false}
                                        className='event-details-date-picker'
                                        onChange={(e) => {
                                            setWithdrawDate(e.target.value);
                                        }}
                                    />
                                }
                            </div>

                            {isEditEnable && !editWithdrawDate && <span
                                style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    setEditWithdrawDate(true)
                                }}
                            >
                                edit
                            </span>}

                            {isEditEnable && editWithdrawDate && <span style={{ fontSize: '1.3rem', color: '#348DCB', cursor: 'pointer' }}

                                className="material-symbols-outlined"
                                onClick={() => {
                                    saveWithdrawDate()
                                    setEditWithdrawDate(false)
                                }}
                            >
                                check
                            </span>}
                        </div>
                    </li>
                </ul>
            </div><br />

            {/* <div className="thirdbox">
                <h4 style={{ fontWeight: '1000', color: '#348DCB' }}>Have a question?</h4>
                <hr />
                <ul>
                    <li>
                        <span style={{ fontSize: '0.9rem' }}>Ask the organizer : <span className="textheadright" >{props.eventDetails.EventOwnerName} </span> </span>
                        <br />
                        <span style={{ fontSize: '0.9rem' }}>Email : </span>

                        <span className='textheadright' style={{ fontSize: '0.9rem', wordBreak: 'break-all' }}>{props.eventDetails.EventOwnerEmail}</span>
                        <br />
                        <span style={{ fontSize: '0.9rem' }}>Contact : </span>
                        <a className='textheadright' href="/">{props.eventDetails.EventOwnerPhone}</a>

                    </li>

                </ul>

            </div> */}

        </div >
    )
}
