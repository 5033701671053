import React, { useEffect, useState } from 'react'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'
import Loading from './Loading.js';
import ShowDraw from './ShowDraw.js';
import { useUserAuth } from '../context/UserAuthcontext';

import ShowDrawComponent from './ShowDrawComponent.js';
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
import SearchComponents from './SearchComponents.js';
export default function EventSetupDraw(props) {
    // const [category, setCategory] = useState('');
    const { user } = useUserAuth();

    const [showPlayer, setShowPlayer] = useState(false)
    const [categoryList, setCategoryList] = useState([]);
    const [showSeeding, setShowSeeding] = useState(false);
    const [showGenerateDraw, setShowGenerateDraw] = useState(false);
    const [showGeneratedDraw, setShowGeneratedDraw] = useState(false);
    const [drawType, setDrawType] = useState('knockout');
    const [drawCategoryName, setDrawCategoryName] = useState('');
    const [categoryPlayer, setCategoryPlayer] = useState([]);
    const [loading, setLoading] = useState(false);
    const [drawCategoryList, setDrawCategoryList] = useState([]);

    // const [matchKnockOut, setMatchKnockOut] = useState([]);
    // const [matchLeague, setmatchLeague] = useState([]);
    // const [matchLeagueStatus, setmatchLeagueStatus] = useState([]);
    const queryConstraints = [
        where('EventID', '==', props.EventDetails.id),
        // Add more query constraints as needed
    ];


    // console.log('queryConstraints : ', queryConstraints)
    const { documents: categoryDocs, error: categoryDocError } = useCollection('EventCategory', queryConstraints)
    const { documents: matchKnockOut, error: knockoutMatchDocsError } = useCollection('TX_MatchDetails', queryConstraints)
    const { documents: matchLeague, error: leagueMatchDocsError } = useCollection('TX_MatchDetailsLeague', queryConstraints)
    const { documents: matchLeagueStatus, error: leagueStatusDocsError } = useCollection('TX_LeagueStatus', queryConstraints)

    const [drawCategory, setDrawCategory] = useState({
        CategoryName: '',
        DrawType: '',
        Published: 'No',
        MatchDetails: [],
        MatchStatus: []
    });

    const [seededPlayer, setSeededPlayer] = useState({
        CategoryName: '',
        Seeded1: '',
        Seeded2: '',
        Seeded3: '',
        Seeded4: '',
        Seeded5: '',
        Seeded6: '',
        Seeded7: '',
        Seeded8: ''
    });
    function getCategoryList() {
        let objList = [];
        let partList = []
        let participants = [];
        categoryDocs.forEach(element => {
            partList = props.ParticipantList.filter(e => e.CategoryName === element.CategoryName)
            participants = [];
            partList.forEach(element => {
                participants.push({
                    ...element,
                    id: element.ParticipantID + "#" + element.CategoryName,
                    searchKey: element.ParticipantID + element.ParticipantName + element.PartnerPlayerID + element.PartnerPlayerName,
                    displayText: element.ParticipantName + "(" + element.ParticipantID + ")" +
                        (element.PartnerPlayerID && element.PartnerPlayerID !== '' ? ("-" +
                            element.PartnerPlayerName + "(" + element.PartnerPlayerID + ")") : '')
                })
            });
            objList.push({
                Category: element,
                ParticipantList: participants, //partList,
                //added for search components
                // id: element.id,
                // searchKey: element.CategoryName + element.EventType + element.Gender,
                // displayText: element.CategoryName + "-" + element.EventType + "-" + element.Gender,
            })
        });
        setCategoryList(objList)
        setDrawCategoryName(categoryDocs && categoryDocs[0] ? categoryDocs[0].CategoryName : '')


        changeCategory(categoryDocs && categoryDocs[0] ? categoryDocs[0].CategoryName : '')


        // const getEventCategory = httpsCallable(functions, "getEventCategory");
        // let para = {
        //     EventID: props.EventDetails.id,
        // };
        // console.log('para', para);
        // return getEventCategory(para)
        //     .then(async (result) => {
        //         console.log('result', result)
        //         let objList = [];
        //         let partList = []
        //         result.data.forEach(element => {
        //             partList = props.ParticipantList.filter(e => e.CategoryName === element.CategoryName)
        //             objList.push({
        //                 Category: element,
        //                 ParticipantList: partList
        //             })
        //         });
        //         setCategoryList(objList)
        //         setDrawCategoryName(result && result.data[0] ? result.data[0].CategoryName : '')


        //         changeCategory(result && result.data[0] ? result.data[0].CategoryName : '')

        //     })
    }
    useEffect(() => {
        getCategoryList();
        getDraw();
    }, [categoryDocs, matchKnockOut, matchLeague, matchLeagueStatus])


    async function getDraw() {
        setLoading(true);

        let catList = [];
        matchKnockOut.forEach(knockOut => {
            if (!catList.includes(knockOut.CategoryName)) {
                catList.push(knockOut.CategoryName)
            }
        });

        matchLeague.forEach(league => {
            if (!catList.includes(league.CategoryName)) {
                catList.push(league.CategoryName)
            }
        });

        setDrawCategoryList(catList);
        setDrawCategoryName(catList && catList[0] ? catList[0] : '');
        ShowCategoryDraw(catList && catList[0] ? catList[0] : '');
        setLoading(false);

        // var para1 = {};
        // // console.log('EventDetails', props.EventID)
        // para1 = {
        //     EventID: props.EventDetails.id //props.EventID //'BP3A5E0nFt5QBJMyDthp'//props.EventID,
        // };
        // setLoading(true);
        // console.log('para1 : ', para1)
        // const ret1 = httpsCallable(functions, 'getDrawDetails');

        // ret1(para1).then(async (result) => {
        //     console.log('result', result)
        //     setDrawCategoryList(result.data.CategoryList);
        //     setDrawCategoryName(result.data.CategoryList ? result.data.CategoryList[0] : '');
        //     // setMatchKnockOut(result.data.MatchDetailsKnockOut);
        //     // setmatchLeague(result.data.MatchDetailsLeague);
        //     // setmatchLeagueStatus(result.data.MatchDetailsLeagueStatus)
        //     ShowCategoryDraw(result.data && result.data.CategoryList && result.data.CategoryList[0]);
        //     setLoading(false);
        // })
    }

    function generateDraw() {
        var para1 = {};

        para1 = {
            EventID: props.EventDetails.id,//'BP3A5E0nFt5QBJMyDthp'
            CategoryName: drawCategoryName,//'GS U11',
            UserID: user && user.userInfo ? user.userInfo.uid : ''
        };
        setLoading(true);
        console.log('para1 : ', para1);
        let functionName = ''
        if (drawType === 'knockout') {
            functionName = 'generateDrawKnockout';
        }

        else {
            functionName = 'generateDrawLeague';
        }

        const ret1 = httpsCallable(functions, functionName);

        ret1(para1).then(async (result) => {
            setLoading(false);
            console.log('result', result)
        })
    }
    function updateSeed() {
        //update Seeded entry in DB
        var para1 = {};
        // console.log('seededPlayer : ', seededPlayer)
        para1 = {
            EventID: props.EventDetails.id,//'BP3A5E0nFt5QBJMyDthp'
            CategoryName: drawCategoryName, //category
            Seed1: seededPlayer.Seeded1,
            Seed2: seededPlayer.Seeded2,
            Seed3: seededPlayer.Seeded3,
            Seed4: seededPlayer.Seeded4,
            Seed5: seededPlayer.Seeded5,
            Seed6: seededPlayer.Seeded6,
            Seed7: seededPlayer.Seeded7,
            Seed8: seededPlayer.Seeded8,
            UserID: user.userInfo.uid,
        };
        setLoading(true);
        const ret1 = httpsCallable(functions, 'updateSeededEntry');
        console.log('para1', para1)
        ret1(para1).then(async (result) => {
            // console.log('result', result)
            setShowPlayer(!showPlayer)
            // setSeededPlayer({
            //     ...seededPlayer,
            //     Seeded1: '',
            //     Seeded2: '',
            //     Seeded3: '',
            //     Seeded4: '',
            //     Seeded5: '',
            //     Seeded6: '',
            //     Seeded7: '',
            //     Seeded8: ''
            // })
            setLoading(false);
        })

    }
    function ShowCategoryDraw(category) {
        let dType = '';
        let published = 'No';
        let matchDetails = [];
        let matchStatus = [];
        // console.log('category : ', category, ' :: matchKnockOut : ', matchKnockOut, ' :: matchLeague : ', matchLeague)
        if (matchKnockOut.find(e => e.CategoryName === category)) {
            // console.log('category : ', category, ' ::Knockout ')
            published = matchKnockOut[0].Published ? matchKnockOut[0].Published : 'No';
            dType = 'Knockout';
            matchDetails = matchKnockOut.filter(e => e.CategoryName === category);
            matchDetails.sort(function (a, b) { return a.MatchID - b.MatchID })

        } else if (matchLeague.find(e => e.CategoryName === category)) {
            // console.log('category : ', category, ' ::League ')
            dType = 'League';
            published = matchLeague[0].Published ? matchLeague[0].Published : 'No';
            matchDetails = matchLeague.filter(e => e.CategoryName === category);
            matchStatus = matchLeagueStatus.filter(e => e.CategoryName === category);
            matchDetails.sort(function (a, b) { return a.MatchID - b.MatchID })
        }

        setDrawCategory({
            CategoryName: category,
            DrawType: dType,
            Published: published,
            MatchDetails: matchDetails,
            MatchStatus: matchStatus
        });
    }

    function changeCategory(catName) {
        setDrawCategoryName(catName);
        let pList = '';
        let seeded1 = '';
        let seeded2 = '';
        let seeded3 = '';
        let seeded4 = '';
        let seeded5 = '';
        let seeded6 = '';
        let seeded7 = '';
        let seeded8 = '';


        pList = categoryList.find(e => e.Category.CategoryName === catName)
        pList && setCategoryPlayer(pList.ParticipantList)
        pList && pList.ParticipantList && pList.ParticipantList.forEach(element => {
            if (element.SeededEntry === 1) {
                seeded1 = element.ParticipantID
            } else if (element.SeededEntry === 2) {
                seeded2 = element.ParticipantID
            } else if (element.SeededEntry === 3) {
                seeded3 = element.ParticipantID
            } else if (element.SeededEntry === 4) {
                seeded4 = element.ParticipantID
            } else if (element.SeededEntry === 5) {
                seeded5 = element.ParticipantID
            } else if (element.SeededEntry === 6) {
                seeded6 = element.ParticipantID
            } else if (element.SeededEntry === 7) {
                seeded7 = element.ParticipantID
            } else if (element.SeededEntry === 8) {
                seeded8 = element.ParticipantID
            }
        });
        setSeededPlayer({
            CategoryName: catName,
            Seeded1: seeded1,
            Seeded2: seeded2,
            Seeded3: seeded3,
            Seeded4: seeded4,
            Seeded5: seeded5,
            Seeded6: seeded6,
            Seeded7: seeded7,
            Seeded8: seeded8
        })
        // console.log('CategoryPlayer', categoryPlayer)
    }

    function PublishDraw(categoryName, publishStatus) {
        // setDrawCategory({
        //     ...drawCategory,
        //     Published: publishStatus,
        // });
    }
    function setSelectedItemSearchComponent1(id, text) {
        // console.log(id, text)
        let idlist = id.split("#");
        setSeededPlayer({
            ...seededPlayer,
            CategoryName: drawCategoryName,
            Seeded1: idlist[0]
        })

    }
    function setSelectedItemSearchComponent2(id, text) {
        // console.log(id, text)
        let idlist = id.split("#");
        setSeededPlayer({
            ...seededPlayer,
            CategoryName: drawCategoryName,
            Seeded2: idlist[0]
        })

    }
    function setSelectedItemSearchComponent3(id, text) {
        // console.log(id, text)
        let idlist = id.split("#");
        setSeededPlayer({
            ...seededPlayer,
            CategoryName: drawCategoryName,
            Seeded3: idlist[0]
        })

    }
    function setSelectedItemSearchComponent4(id, text) {
        // console.log(id, text)
        let idlist = id.split("#");
        setSeededPlayer({
            ...seededPlayer,
            CategoryName: drawCategoryName,
            Seeded4: idlist[0]
        })

    }
    function setSelectedItemSearchComponent5(id, text) {
        // console.log(id, text)
        let idlist = id.split("#");
        setSeededPlayer({
            ...seededPlayer,
            CategoryName: drawCategoryName,
            Seeded5: idlist[0]
        })

    }
    function setSearchClicked(flag) {

    }
    function setSelectedItemSearchComponent6(id, text) {
        // console.log(id, text)
        let idlist = id.split("#");
        setSeededPlayer({
            ...seededPlayer,
            CategoryName: drawCategoryName,
            Seeded6: idlist[0]
        })

    }
    function setSelectedItemSearchComponent7(id, text) {
        // console.log(id, text)
        let idlist = id.split("#");
        setSeededPlayer({
            ...seededPlayer,
            CategoryName: drawCategoryName,
            Seeded7: idlist[0]
        })

    }
    function setSelectedItemSearchComponent8(id, text) {
        // console.log(id, text)
        let idlist = id.split("#");
        setSeededPlayer({
            ...seededPlayer,
            CategoryName: drawCategoryName,
            Seeded8: idlist[0]
        })

    }
    // let player = [];
    return (
        <>
            {loading && <Loading></Loading>}
            {!loading && <div className='row no-gutters' style={{ background: '#fff', borderRadius: '10px' }}>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <strong>Category List</strong>
                </div>
                {/* <div className='col-lg-12' style={{ border: '1' }}>
                    <div className='row no-gutters' style={{ padding: '0 5px' }}>
                        Search Component Test
                        <SearchComponents ItemList={categoryList}
                            placeholder={'search Category...'}
                            setSelectedItem={setSelectedItemSearchComponent}
                        ></SearchComponents>
                    </div>
                </div> */}
                <div className='col-lg-12' style={{ border: '1' }}>
                    <div className='row no-gutters' style={{ padding: '0 5px' }}>

                        {categoryList && categoryList.map((obj) => {
                            // console.log('obj.CategoryName', obj)
                            return <div key={"1" + obj.Category.CategoryName} className="col-4" style={{ padding: '0' }}>

                                <div style={{ widht: '100%', textAlign: 'center', padding: '8px 5px' }}>
                                    <input type="radio" checked={drawCategoryName === obj.Category.CategoryName ? true : false}
                                        className="checkbox" style={{ width: '0px' }}
                                        name="EventName" id={obj.Category.CategoryName.replaceAll(' ', '').replaceAll('-', '')}
                                        value={obj.Category.CategoryName}
                                        onChange={() => {
                                            changeCategory(obj.Category.CategoryName);
                                        }} />
                                    <label style={{ height: '30px', border: '1px solid #ddd', paddingTop: '1px' }}
                                        className="checkbox-label setup-draw-label"
                                        id={obj.Category.CategoryName.replaceAll(' ', '').replaceAll('-', '') + "Label"}
                                        htmlFor={obj.Category.CategoryName.replaceAll(' ', '').replaceAll('-', '')}>
                                        <i className="fas fa-plus"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        <i className="fas fa-check"
                                            style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                        {/* <span>{obj.CategoryName + ' ( ' + obj && obj.ParticipantList ? obj.ParticipantList.length : '0' + ' ) '}</span> */}
                                        {/* <span>{'test' + ' ( ' + obj && obj.ParticipantList ? obj.ParticipantList.length : '0' + ' ) '}</span> */}
                                        {/* <span>{obj.CategoryName + ' ( ' + obj && obj.ParticipantList ? obj.ParticipantList.length.toString() : '0' + ' ) '}</span> */}
                                        <span>{obj.Category.CategoryName + ' ( ' + (obj && obj.ParticipantList ? obj.ParticipantList.length.toString() : '0') + ' ) '}</span>
                                    </label>
                                </div>

                            </div>
                        })}
                    </div>
                    <hr />
                </div>
                <div className='col-lg-12'>

                    <div className={showSeeding ? 'calculator-div open' : 'calculator-div'}>
                        <div className='calculator-heading' onClick={() => setShowSeeding(!showSeeding)}>
                            <h1 style={{ fontSize: '1.8rem', fontWeight: 'normal' }}>
                                Add Seeding {drawCategoryName !== '' ? " - " + drawCategoryName : ''}
                                <span className="material-symbols-outlined">
                                    expand_more
                                </span>
                            </h1>
                        </div>
                        <div className='calculator-content' style={{ background: '#fff' }}>
                            <div className="row no-gutters">
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
                                    <div className="seed-div border-right">
                                        <h2>Seed 1 : </h2>

                                        <SearchComponents
                                            ItemList={categoryPlayer}
                                            width='75%'
                                            placeholder={'search Player...'}
                                            setSearchClicked={setSearchClicked}
                                            setSelectedItem={setSelectedItemSearchComponent1}
                                        ></SearchComponents>
                                        {/* <select onChange={(e) => {
                                            setSeededPlayer({
                                                ...seededPlayer,
                                                CategoryName: drawCategoryName,
                                                Seeded1: e.target.value
                                            })

                                        }}
                                            value={seededPlayer.CategoryName === drawCategoryName ? seededPlayer.Seeded1 : ''}>
                                            <option value=''> Select Player</option>
                                            {categoryPlayer && categoryPlayer.map((obj) => {
                                                return <option key={obj.ParticipantID}
                                                    value={obj.ParticipantID}>{obj.ParticipantName + (obj.PartnerPlayerName !== "" ? " - " + obj.PartnerPlayerName : '')}</option>
                                            })}
                                        </select> */}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
                                    <div className="seed-div">
                                        <h2>Seed 2 : </h2>
                                        <SearchComponents
                                            ItemList={categoryPlayer}
                                            width='75%'
                                            placeholder={'search Player...'}
                                            setSearchClicked={setSearchClicked}

                                            setSelectedItem={setSelectedItemSearchComponent2}
                                        ></SearchComponents>
                                        {/* <select onChange={(e) => {
                                            setSeededPlayer({
                                                ...seededPlayer,
                                                CategoryName: drawCategoryName,
                                                Seeded2: e.target.value
                                            })
                                            // console.log('Seed 2 : :', seededPlayer)
                                        }}
                                            value={seededPlayer.CategoryName === drawCategoryName ? seededPlayer.Seeded2 : ''}>
                                            <option> Select Player</option>

                                            {categoryPlayer && categoryPlayer.map((obj) => {
                                                return <option key={obj.ParticipantID}
                                                    value={obj.ParticipantID}>{obj.ParticipantName + (obj.PartnerPlayerName !== "" ? " - " + obj.PartnerPlayerName : '')}</option>
                                            })}
                                        </select> */}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
                                    <div className="seed-div border-right">
                                        <h2>Seed 3 : </h2>
                                        <SearchComponents
                                            ItemList={categoryPlayer}
                                            width='75%'
                                            placeholder={'search Player...'}
                                            setSearchClicked={setSearchClicked}

                                            setSelectedItem={setSelectedItemSearchComponent3}
                                        ></SearchComponents>
                                        {/* <select onChange={(e) => {
                                            setSeededPlayer({
                                                ...seededPlayer,
                                                CategoryName: drawCategoryName,
                                                Seeded3: e.target.value
                                            })
                                        }}
                                            value={seededPlayer.CategoryName === drawCategoryName ? seededPlayer.Seeded3 : ''}>
                                            <option> Select Player</option>

                                            {categoryPlayer && categoryPlayer.map((obj) => {
                                                return <option key={obj.ParticipantID}
                                                    value={obj.ParticipantID}>{obj.ParticipantName + (obj.PartnerPlayerName !== "" ? " - " + obj.PartnerPlayerName : '')}</option>
                                            })}
                                        </select> */}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
                                    <div className="seed-div">
                                        <h2>Seed 4 : </h2>
                                        <SearchComponents
                                            ItemList={categoryPlayer}
                                            width='75%'
                                            placeholder={'search Player...'}
                                            setSearchClicked={setSearchClicked}

                                            setSelectedItem={setSelectedItemSearchComponent4}
                                        ></SearchComponents>
                                        {/* <select onChange={(e) => {
                                            setSeededPlayer({
                                                ...seededPlayer,
                                                CategoryName: drawCategoryName,
                                                Seeded4: e.target.value
                                            })
                                        }}
                                            value={seededPlayer.CategoryName === drawCategoryName ? seededPlayer.Seeded4 : ''}>
                                            <option> Select Player</option>

                                            {categoryPlayer && categoryPlayer.map((obj) => {
                                                return <option key={obj.ParticipantID}
                                                    value={obj.ParticipantID}>{obj.ParticipantName + (obj.PartnerPlayerName !== "" ? " - " + obj.PartnerPlayerName : '')}</option>
                                            })}
                                        </select> */}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
                                    <div className="seed-div border-right">
                                        <h2>Seed 5 : </h2>
                                        <SearchComponents
                                            ItemList={categoryPlayer}
                                            width='75%'
                                            placeholder={'search Player...'}
                                            setSearchClicked={setSearchClicked}

                                            setSelectedItem={setSelectedItemSearchComponent5}
                                        ></SearchComponents>
                                        {/* <select onChange={(e) => {
                                            setSeededPlayer({
                                                ...seededPlayer,
                                                CategoryName: drawCategoryName,
                                                Seeded5: e.target.value
                                            })
                                        }}
                                            value={seededPlayer.CategoryName === drawCategoryName ? seededPlayer.Seeded5 : ''}>
                                            <option> Select Player</option>

                                            {categoryPlayer && categoryPlayer.map((obj) => {
                                                return <option key={obj.ParticipantID}
                                                    value={obj.ParticipantID}>{obj.ParticipantName + (obj.PartnerPlayerName !== "" ? " - " + obj.PartnerPlayerName : '')}</option>
                                            })}
                                        </select> */}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
                                    <div className="seed-div">
                                        <h2>Seed 6 : </h2>
                                        <SearchComponents
                                            ItemList={categoryPlayer}
                                            width='75%'
                                            placeholder={'search Player...'}
                                            setSearchClicked={setSearchClicked}

                                            setSelectedItem={setSelectedItemSearchComponent6}
                                        ></SearchComponents>
                                        {/* <select onChange={(e) => {
                                            setSeededPlayer({
                                                ...seededPlayer,
                                                CategoryName: drawCategoryName,
                                                Seeded6: e.target.value
                                            })
                                        }}
                                            value={seededPlayer.CategoryName === drawCategoryName ? seededPlayer.Seeded6 : ''}>
                                            <option> Select Player</option>

                                            {categoryPlayer && categoryPlayer.map((obj) => {
                                                return <option key={obj.ParticipantID}
                                                    value={obj.ParticipantID}>{obj.ParticipantName + (obj.PartnerPlayerName !== "" ? " - " + obj.PartnerPlayerName : '')}</option>
                                            })}
                                        </select> */}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
                                    <div className="seed-div border-right no-border-bottom">
                                        <h2>Seed 7 : </h2>
                                        <SearchComponents
                                            ItemList={categoryPlayer}
                                            width='75%'
                                            placeholder={'search Player...'}
                                            setSearchClicked={setSearchClicked}

                                            setSelectedItem={setSelectedItemSearchComponent7}
                                        ></SearchComponents>
                                        {/* <select onChange={(e) => {
                                            setSeededPlayer({
                                                ...seededPlayer,
                                                CategoryName: drawCategoryName,
                                                Seeded7: e.target.value
                                            })
                                        }}
                                            value={seededPlayer.CategoryName === drawCategoryName ? seededPlayer.Seeded7 : ''}>
                                            <option> Select Player</option>

                                            {categoryPlayer && categoryPlayer.map((obj) => {
                                                return <option key={obj.ParticipantID}
                                                    value={obj.ParticipantID}>{obj.ParticipantName + (obj.PartnerPlayerName !== "" ? " - " + obj.PartnerPlayerName : '')}</option>
                                            })}
                                        </select> */}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
                                    <div className="seed-div" style={{ border: 'none', paddingBottom: '0' }}>
                                        <h2>Seed 8 : </h2>
                                        <SearchComponents
                                            ItemList={categoryPlayer}
                                            width='75%'
                                            placeholder={'search Player...'}
                                            setSearchClicked={setSearchClicked}

                                            setSelectedItem={setSelectedItemSearchComponent8}
                                        ></SearchComponents>
                                        {/* <select onChange={(e) => {
                                            setSeededPlayer({
                                                ...seededPlayer,
                                                CategoryName: drawCategoryName,
                                                Seeded8: e.target.value
                                            })
                                        }}
                                            value={seededPlayer.CategoryName === drawCategoryName ? seededPlayer.Seeded8 : ''}>
                                            <option> Select Player</option>

                                            {categoryPlayer && categoryPlayer.map((obj) => {
                                                return <option key={obj.ParticipantID}
                                                    value={obj.ParticipantID}>{obj.ParticipantName + (obj.PartnerPlayerName !== "" ? " - " + obj.PartnerPlayerName : '')}</option>
                                            })}
                                        </select> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
                                <button className="mybutton button5" onClick={updateSeed}>Update Seed</button>
                            </div>
                        </div>

                    </div >
                    <hr />

                </div>
                <div className='col-lg-12'>

                    <div className={showGeneratedDraw ? 'calculator-div open' : 'calculator-div'}>
                        <div className='calculator-heading' onClick={() => setShowGeneratedDraw(!showGeneratedDraw)}>
                            <h1 style={{ fontSize: '1.8rem', fontWeight: 'normal' }}>
                                Draw Status
                                <span className="material-symbols-outlined">
                                    expand_more
                                </span>
                            </h1>
                        </div>
                        <div className='calculator-content' style={{ background: '#fff' }}>

                            <div className='row no-gutters'>
                                <div className="col-6">
                                    <div style={{ widht: '100%', textAlign: 'center' }}>
                                        <input type="radio" checked={drawType === 'knockout' ? true : false} className="checkbox" style={{ width: '0px' }}
                                            name="DrawType"
                                            onClick={() => {
                                                setDrawType(drawType === 'knockout' ? 'league' : 'knockout')
                                            }} id="knockoutDraw" value="knockoutDraw" />
                                        <label style={{ height: '40px', border: '1px solid #ddd' }}

                                            className="checkbox-label setup-draw-label" id="knockoutDrawLabel"
                                            htmlFor="knockoutDraw">
                                            <i className="fas fa-plus"
                                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                            <i className="fas fa-check"
                                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                            <span>Knockout Draws</span>
                                        </label>
                                    </div>

                                </div>

                                <div className="col-6">

                                    <div style={{ widht: '100%', textAlign: 'center' }}>
                                        <input type="radio" checked={drawType !== 'knockout' ? true : false} className="checkbox" style={{ width: '0px' }}
                                            name="DrawType"
                                            onClick={() => {
                                                setDrawType(drawType === 'league' ? 'knockout' : 'league')
                                            }} id="leagueDraw" value="leagueDraw" />
                                        <label style={{ height: '40px', border: '1px solid #ddd' }}

                                            className="checkbox-label setup-draw-label" id="leagueDrawLabel"
                                            htmlFor="leagueDraw">
                                            <i className="fas fa-plus"
                                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                            <i className="fas fa-check"
                                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                            <span>League Draws</span>
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <hr style={{ marginTop: '20px' }} />

                            <center>
                                <button className="mybutton button5" onClick={generateDraw}>Generate Draw</button>
                            </center>
                        </div>
                    </div >
                    <hr />

                </div>
                <div className='col-lg-12'>

                    <div className={showGenerateDraw ? 'calculator-div open' : 'calculator-div'}>
                        <div className='calculator-heading' onClick={() => setShowGenerateDraw(!showGenerateDraw)}>
                            <h1 style={{ fontSize: '1.8rem', fontWeight: 'normal' }}>
                                Draw Details
                                <span className="material-symbols-outlined">
                                    expand_more
                                </span>
                            </h1>
                        </div>
                        <div className='calculator-content' style={{ background: '#fff' }}>
                            {/* {console.log('props.EventDetails', props.EventDetails, 'drawCategory :: ', drawCategory, '::DrawCategoryList:', drawCategoryList)} */}
                            {/* <ShowDraw EventDetails={props.EventDetails} partcipantObj={categoryList} rentryCount={0} ShowMenu={false}></ShowDraw> */}
                            <ShowDrawComponent
                                // drawCategory={drawCategory}
                                //  categoryList={drawCategoryList}
                                // ShowCategoryDraw={ShowCategoryDraw}
                                IsAdmin={true}
                                EventID={props.EventDetails.id}
                            // PublishDraw={PublishDraw}
                            >

                            </ShowDrawComponent>
                            <hr style={{ marginTop: '20px' }} />

                            <center>
                                <button className="mybutton button5" onClick={generateDraw}>Generate Draw</button>
                            </center>
                        </div>
                    </div >
                    <br />

                </div>




            </div >}

        </>
    )
}
