import React from 'react'
import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";


export default function PaymentSuccess() {
    const { transactionid } = useParams();
    const location = useLocation();
    const [eventDetails, setEventDetails] = useState(window.localStorage.getItem('EventDetails') ? JSON.parse(window.localStorage.getItem('EventDetails')) : null);
    const [registeredEvents, setRegisteredEvents] = useState(null);
    const [partnerList, setPartnerList] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    async function updateTransactioninDB() {


        const partnerListQ = httpsCallable(functions, "updatePaymentStatusForTransactionID");
        let para = {
            TransactionID: transactionid,
            PaymentStatus: 'Completed',

        };
        console.log('para', para);
        return partnerListQ(para)
            .then(async (result) => {
                console.log('result', result)

                // getParticipantList(selectedEvent.Eventid, searchEvent.EventCode);
                // setPopupLoading(false);
                // setShowPopup(false);
            })
    }

    useEffect(() => {
        updateTransactioninDB();
        //getRegisteredEvents();
    }, [])
    return (
        <div>
            {console.log('transactionid:', transactionid)}
            Payment Completed
        </div>
    )
}
