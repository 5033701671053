import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import { Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import 'react-phone-input-2/lib/style.css'
import { useUserAuth } from '../context/UserAuthcontext';
import { GetUserDetails } from './GetUserDetails.js'
import '../css/Login.css'
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import DatePicker from "react-datepicker";
import '../css/MydatePicker.css'
import "react-datepicker/dist/react-datepicker.css";
// const navigate = useNavigate();


export default function SignIn(props) {
    const [error, setError] = useState('')
    const [showErrorOTP, setShowErrorOTP] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showErrorGender, setShowErrorGender] = useState(false)
    const [isUserExists, setIsUserExists] = useState(true)

    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    // const [resendOTPFlag, setResendOTPFlag] = useState(false)
    const [loading, setLoading] = useState(false);
    const { setUpRecapcha, users, resendOTP } = useUserAuth();
    const [isLogged, setIsLogged] = useState(false);
    const [confirmObj, setConfirmObj] = useState("");
    const [flag, setFlag] = useState(false);
    const [sendOTPFlag, setSendOTPFlag] = useState(true);
    const [resultData, setResultData] = useState(null);
    const [gender, setGender] = useState('');
    const [saveGenderLoading, setSaveGenderLoading] = useState(false);
    const [saveProfileLoading, setSaveProfileLoading] = useState(false);
    const [localUserProfile, setLocalUserProfile] = useState({
        UserName: '',
        Email: '',
        City: '',
        UserID: '',
        Phone: ''

    });

    const [loginSection, setloginSection] = useState('1');// useState('1');

    const getOTP = async (e) => {
        e.preventDefault();
        setError("");
        if (phone === '' || phone === undefined || phone.length < 10) {
            return setError("Please enter a valid phone number");
        }
        if (users.current && users.current.phoneNumber === ("+" + phone)) {
            setIsLogged(true);
        }
        setSendOTPFlag(false);
        try {
            const respons = await setUpRecapcha('+' + phone, '');
            setConfirmObj(respons);
            setFlag(true);
            otpSectionshow('2')
        } catch (error) {
            let lError = ''
            console.log('2 error.message', error)
            if (error.message === 'TOO_SHORT (auth/invalid-phone-number)') {
                lError = 'Enter Valid Phone Number';
            }
            setError(lError);

            await resendOTP("+" + phone);
        }
    }
    const verifyOTP = async (e) => {
        e.preventDefault();
        // setError("");
        console.log('in verifyOTP')
        setLoading(true);
        if (otp === "" || otp === undefined || otp === null) {
            setShowErrorOTP(true)
            setLoading(false);
            setTimeout(function () {
                setError("");
                setShowErrorOTP(false)
            }, 5000);
            return;
        }
        try {
            await confirmObj.confirm(otp).then(async (result) => {

                const user = result.user;
                console.log("user", result.user);
                console.log('result._tokenResponse.isNewUser', result._tokenResponse.isNewUser);
                //if existing users then navigate to requested URL, otherwise go to profile page
                //get user profile
                await GetUserDetails(user.uid).then((data) => {
                    console.log('user.uid : ', data);
                    if (result._tokenResponse.isNewUser) {
                        props.setIsNewUser(true);
                        // isUserExists(false);
                        user && setResultData({
                            id: user.uid,
                            Phone: result.user.phoneNumber
                        });
                        saveUserInDB(user.uid, result.user.phoneNumber)
                        console.log('after saveUserInDB', loginSection)
                        otpSectionshow('3')
                    }
                    else {
                        setResultData(data);
                        props.setSignIn(true, data);
                    }

                    setLoading(false);

                });
            });

        } catch (error) {
            console.log('error.message', error.message)

            // setError('Given OTP is not valid, please enter the valid OTP sent to your phone');
            setLoading(false);
            setShowErrorOTP(true)
            setTimeout(function () {
                setError("");
                setShowErrorOTP(false)
            }, 5000);
        }
    }

    let newArray = phone && phone.match(/^(91|)?(\d{3})(\d{3})(\d{4})$/)
    // let newArray = phone && phone.match(/^(91|)?(\d{5})(\d{5}))$/)

    async function saveUserInDB(uid, phone) {
        var para = {};
        para = {
            userID: uid,
            Phone: phone
        };
        console.log(para);
        const ret = await httpsCallable(functions, "addUserDetails");
        ret(para).then(async (result) => {
            // window.localStorage.setItem("userProfile", JSON.stringify(resultData));

        })
    }
    const otpSectionshow = (stage) => {
        setloginSection(stage);
    }
    async function saveGender() {
        if (gender === '') {
            setShowErrorGender(true)
            setTimeout(function () {
                setShowErrorGender(false);

            }, 5000);

        }
        else {
            var para = {};
            para = {
                userID: resultData.id,
                Gender: gender,
                Phone: resultData.Phone
            };
            setSaveGenderLoading(true)
            console.log(para);
            // console.log('in true')
            setLoading(true);

            const ret = await httpsCallable(functions, "saveUserGender");
            ret(para).then(async (result) => {
                setSaveGenderLoading(false)
                setResultData({
                    ...resultData,
                    Gender: gender
                })
                // window.localStorage.getItem('userProfile')
                // await GetUserDetails(resultData.id).then((data) => {
                otpSectionshow('4')
                // })
                console.log(gender)
                console.log(resultData)

            })
        }

    }
    async function saveProfileDetails() {
        let error = 'Please enter'
        let errorFlag = false
        console.log(localUserProfile)

        if (!localUserProfile.UserName || localUserProfile.UserName === '') {
            errorFlag = true
            error = error.concat(' name')

        }
        if (!localUserProfile.Email || localUserProfile.Email === '' || !validateEmail(localUserProfile.Email)) {
            error = error.concat(errorFlag ? ',' : '', ' valid email')
            errorFlag = true
            console.log('in if 2', error)

        }

        if (!localUserProfile.City || localUserProfile.City === '') {
            error = error.concat(errorFlag ? ',' : '', ' city')
            errorFlag = true
        }

        if (!localUserProfile.DateOfBirth || validateEmail(localUserProfile.DateOfBirth)) {
            error = error.concat(errorFlag ? ',' : '', ' date of birth')
            errorFlag = true
        }
        if (errorFlag) {
            setErrorMessage(error)
            setShowErrorMessage(true)
            setTimeout(function () {
                setErrorMessage("");
                setShowErrorMessage(false)
            }, 3000);
            // return 
        } else {


            var para = {};
            para = {
                userID: resultData.id,
                UserName: localUserProfile.UserName,
                Email: localUserProfile.Email,
                City: localUserProfile.City,
                DateOfBirth: localUserProfile.DateOfBirth ? new Date(localUserProfile.DateOfBirth) : new Date(),
            };
            setSaveProfileLoading(true)
            console.log(para);
            // console.log('in true')
            setLoading(true);

            const ret = await httpsCallable(functions, "saveUserDetails");
            ret(para).then(async (result) => {
                // otpSectionshow('4')
                setResultData({
                    ...resultData,
                    UserName: localUserProfile.UserName,
                    Email: localUserProfile.Email,
                    City: localUserProfile.City,
                    DateOfBirth: localUserProfile.DateOfBirth ? new Date(localUserProfile.DateOfBirth) : new Date(),
                })
                await GetUserDetails(resultData.id).then((data) => {
                    console.log(resultData);
                    props.setIsNewUser(false);
                    props.setSignIn(true, resultData);
                    setSaveProfileLoading(false)
                    // window.localStorage.setItem("userProfile", JSON.stringify(resultData));

                    // navigate(-1)
                })
                // props.setSignIn(true, resultData);
            })
        }
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    return (
        <div>
            <div className="logdiv">

                <div className='row no-gutters'>

                    <div className='col-lg-6 col-md-6 col-sm-12'>

                        <div className='login-slider-outter'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                <img src="/img/TPLiVE_text.png" style={{ width: '100%', maxWidth: '200px' }} alt="" />
                            </div>
                            <div className='login-slider-inner'>
                                <div className='login-slides'
                                    style={{ transform: loginSection === '1' ? 'translateX(0%)' : loginSection === '2' ? 'translateX(-100%)' : loginSection === '3' ? 'translateX(-200%)' : loginSection === '4' ? 'translateX(-300%)' : 'translateX(0%)' }}>
                                    <br />
                                    <h1 style={{ fontSize: '1.1rem', fontWeight: 'normal' }}>Mobile Number</h1>
                                    {<div style={{ width: '100%', display: !flag ? "block" : "none" }}>

                                        <div className='mb-b' controlid='formBasicPhoneNumber'>
                                            <PhoneInput
                                                country={'in'}
                                                value={phone}
                                                onChange={setPhone}
                                                international
                                                keyboardType="phone-pad"
                                                countryCodeEditable={false}
                                                placeholder="Enter Phone Number"
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                            >
                                            </PhoneInput>

                                            <br />
                                            <div id='recapcha-container'></div>
                                        </div>
                                        <div className='d-grid gap-2'>
                                            <br />
                                            {error && <Alert variant='danger'>{error}</Alert>}
                                            {/* {sendOTPFlag &&  */}
                                            <Button className='mybutton button5'
                                                onClick={getOTP}
                                                style={{ transform: 'translateX(-2.5px)', width: '100%', borderRadius: '10px', height: '40px', background: sendOTPFlag ? '#348DCB' : '#aaa', cursor: sendOTPFlag ? 'pointer' : 'auto' }} type="submit">
                                                <span style={{ fontSize: '1.1rem', display: sendOTPFlag ? 'block' : 'none' }}>
                                                    Continue
                                                </span>
                                                <div className='btn-loading' style={{ display: !sendOTPFlag ? 'block' : 'none' }}>
                                                    <lottie-player id="btnSendOTPLoad"
                                                        src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                                        speed="0.7" loop autoplay></lottie-player>
                                                </div>
                                            </Button>
                                            {/* } */}
                                            <center>
                                                <h2 style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>By proceeding, I agree to TPLiVE <Link to='/TermsAndConditions' style={{ color: '#348DCB' }}>T&C</Link> & <Link to='/PrivacyPolicy' style={{ color: '#348DCB' }}>Privacy Policy</Link></h2>
                                            </center>
                                            <br></br>
                                        </div>
                                    </div>
                                    }
                                    <br />
                                </div>

                                <div className='login-slides'
                                    style={{ transform: loginSection === '1' ? 'translateX(0%)' : loginSection === '2' ? 'translateX(-100%)' : loginSection === '3' ? 'translateX(-200%)' : loginSection === '4' ? 'translateX(-300%)' : 'translateX(0%)' }}>

                                    {

                                        <>
                                            <br />
                                            <h1 style={{ fontSize: '1.1rem', fontWeight: 'normal' }}>Enter 6 digit OTP. Sent to <span style={{ fontWeight: 'bold', color: '#348DCB' }}>+
                                                {newArray && newArray.length >= 5 ? +newArray[1] + '-' + newArray[2] + '-' + newArray[3] + '-' + newArray[4] : ''}</span></h1>

                                            <div style={{ display: flag ? "block" : "none", width: '100%', }}>

                                                <div className="reg-participant-form-field" style={{ width: '100%' }}>
                                                    <input type="number" required maxLength={6}
                                                        onInput={(e) => {
                                                            if (e.target.value.length > e.target.maxLength)
                                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                        }}
                                                        value={otp}
                                                        onChange={(e) => setOtp(e.target.value)}>

                                                    </input>
                                                    <span>Enter OTP</span>
                                                </div>
                                                {<div className='d-grid gap-2'>
                                                    <div id='recapcha-container'></div>
                                                    <br />
                                                    {showErrorOTP && <Alert variant='danger'>{'Pleae enter the OTP sent to your mobile.'}</Alert>}
                                                    <button className="mybutton button5"
                                                        onClick={verifyOTP} style={{ transform: 'translateX(-2.5px)', width: '100%', borderRadius: '10px', height: '40px', background: !loading ? '#348DCB' : '#aaa', cursor: !loading ? 'pointer' : 'auto' }}>
                                                        <div style={{ display: !loading ? 'block' : 'none' }}>
                                                            <span
                                                                style={{ fontSize: '1.1rem' }}>Continue</span>
                                                        </div>
                                                        <div className='btn-loading' style={{ display: loading ? 'block' : 'none' }}>
                                                            <lottie-player id="btnSendOTPLoad"
                                                                src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                                                speed="0.7" loop autoplay></lottie-player>
                                                        </div>
                                                    </button>
                                                </div>}
                                            </div>
                                            <br></br>
                                        </>
                                    }
                                    <br />
                                </div>
                                {<div className='login-slides'
                                    style={{ transform: loginSection === '1' ? 'translateX(0%)' : loginSection === '2' ? 'translateX(-100%)' : loginSection === '3' ? 'translateX(-200%)' : loginSection === '4' ? 'translateX(-300%)' : 'translateX(0%)' }}>
                                    <br />
                                    <h1 style={{ fontSize: '1.1rem', fontWeight: 'normal' }}>Gender</h1>
                                    <div className="row no-gutters" style={{ width: '100%' }}>

                                        <div className="col-6">

                                            <input type="radio" checked={gender === 'Male' ? true : false}
                                                onChange={(e) => {
                                                    e.target.checked && setGender('Male');
                                                }}
                                                className="checkbox" style={{ width: '0px' }}
                                                name="EventStatus" id="loginMale" value="Male" />
                                            <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                className="checkbox-label" htmlFor="loginMale">
                                                <i className="fas fa-plus"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <i className="fas fa-check"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <span>Male</span>
                                            </label>

                                        </div>

                                        <div className="col-6">
                                            <input type="radio" checked={gender === 'Female' ? true : false}
                                                onChange={(e) => {
                                                    e.target.checked && setGender('Female');
                                                }}
                                                className="checkbox" style={{ width: '0px' }}
                                                name="EventStatus" id="loginFemale" value="Female" />
                                            <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                className="checkbox-label"
                                                htmlFor="loginFemale">
                                                <i className="fas fa-plus"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <i className="fas fa-check"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <span>Female</span>
                                            </label>
                                        </div>

                                    </div>
                                    <br />
                                    <br />

                                    {showErrorGender && <Alert variant='danger'>{'Pleae select gender.'}</Alert>}
                                    <button className="mybutton button5"
                                        onClick={() => {
                                            saveGender()
                                        }}
                                        style={{ transform: 'translateX(-2.5px)', width: '100%', borderRadius: '10px', height: '40px', background: !saveGenderLoading ? '#348DCB' : '#aaa', cursor: !saveGenderLoading ? 'pointer' : 'auto' }}>
                                        <div style={{ display: !saveGenderLoading ? 'block' : 'none' }} >
                                            <span
                                                style={{ fontSize: '1.1rem' }}>Next</span>
                                        </div>
                                        <div className='btn-loading' style={{ display: saveGenderLoading ? 'block' : 'none' }}>
                                            <lottie-player id="btnSendOTPLoad"
                                                src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                                speed="0.7" loop autoplay></lottie-player>
                                        </div>
                                    </button>

                                </div>}
                                <div className='login-slides'
                                    style={{ transform: loginSection === '1' ? 'translateX(0%)' : loginSection === '2' ? 'translateX(-100%)' : loginSection === '3' ? 'translateX(-200%)' : loginSection === '4' ? 'translateX(-300%)' : 'translateX(0%)' }}>
                                    {/* <form onSubmit={saveProfileDetails} method='POST'> */}
                                    <br />
                                    <h1 style={{ fontSize: '1.1rem', fontWeight: 'normal' }}>Welcome to TPLiVE</h1>
                                    <div className="reg-participant-form-field" style={{ width: '100%' }}>
                                        <input type="text" maxLength={'50'} id="tbUserName" required
                                            onChange={(e) => {
                                                setLocalUserProfile({
                                                    ...localUserProfile,
                                                    UserName: e.target.value
                                                });

                                            }} value={localUserProfile.UserName}
                                        />
                                        <span>Name</span>
                                    </div>
                                    <div className="reg-participant-form-field" style={{ width: '100%' }}>
                                        <input type="email" id="tbUserName" required
                                            onChange={(e) => {
                                                setLocalUserProfile({
                                                    ...localUserProfile,
                                                    Email: e.target.value
                                                });

                                            }} value={localUserProfile.Email}
                                        />
                                        <span>Email</span>
                                    </div>
                                    <div className="reg-participant-form-field" style={{ width: '100%' }}>
                                        <input type="text" maxLength={'25'} id="tbUserName" required
                                            onChange={(e) => {
                                                setLocalUserProfile({
                                                    ...localUserProfile,
                                                    City: e.target.value
                                                });

                                            }} value={localUserProfile.City}
                                        />
                                        <span>City</span>
                                    </div>


                                    <div className="reg-participant-form-field" style={{ width: '100%' }}>

                                        <DatePicker
                                            showMonthDropdown
                                            showYearDropdown
                                            dateFormat="dd-MMM-yyyy"

                                            maxDate={new Date()}
                                            required selected={localUserProfile.DateOfBirth} onChange={(date) => {
                                                // console.log(date)
                                                setLocalUserProfile({
                                                    ...localUserProfile,
                                                    DateOfBirth: new Date(date)
                                                })
                                            }} />

                                        <span
                                            style={{ fontSize: '0.9rem', fontWeight: 'bold', zIndex: '6', position: 'absolute', top: '15px', left: '12px', padding: '0 8px', color: '#348DCB', background: '#fff' }}>Date Of Birth</span>
                                        <span style={{ right: '10px', left: 'initial', color: '#348DCB', fontSize: '1.4rem' }} className="material-symbols-outlined">
                                            calendar_month
                                        </span>
                                    </div>

                                    <br />

                                    {showErrorMessage && <Alert variant='danger'>{errorMessage}</Alert>}


                                    <button type='submit' className="mybutton button5"
                                        onClick={() => {
                                            saveProfileDetails()
                                        }}
                                        style={{ transform: 'translateX(-2.5px)', width: '100%', borderRadius: '10px', height: '40px', background: !saveProfileLoading ? '#348DCB' : '#aaa', cursor: !saveProfileLoading ? 'pointer' : 'auto' }}>
                                        <div style={{ display: !saveProfileLoading ? 'block' : 'none' }} >
                                            <span
                                                style={{ fontSize: '1.1rem' }}>Submit</span>
                                        </div>
                                        <div style={{ display: saveProfileLoading ? 'block' : 'none' }}>
                                            <lottie-player id="btnSendOTPLoad"
                                                src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                                speed="0.7" loop autoplay></lottie-player>
                                        </div>
                                    </button>
                                    <br></br>
                                    {/* </form> */}
                                </div>

                            </div>
                        </div>


                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12'>

                        <div className='large'>
                            <center style={{ padding: '0 20px' }}>
                                <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_rztnd89t.json" background="transparent"
                                    speed="1" style={{ width: '100%', height: '500px' }} loop autoplay></lottie-player>
                            </center>
                        </div>
                    </div>

                </div>


            </div>
            <br /><br /><br />
        </div >
    )
}
