import React, { useState, useEffect } from 'react';
import '../css/ListYourEvent.css';
import RegistrationPackage from './RegistrationPackage';
import { useNavigate, useLocation } from 'react-router-dom';

export default function ListYourEvent() {
    const [showSection, setShowSection] = useState('')
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    // Scroll to the top of the page whenever the location changes end
    // const packageCardClick = (selectCard) => {
    //     document.getElementById('firstPackageCard').classList.remove('active');
    //     document.getElementById('secondPackageCard').classList.remove('active');
    //     document.getElementById('thirdPackageCard').classList.remove('active');

    //     document.getElementById(selectCard).classList.add('active');
    // }

    return (
        <>
            <div className='list-your-event-background-image'>
                <br />
                <br />
                <div className='container'>
                    <h1>Publish your event in under five minutes.</h1>
                    <h2>Craft unique experiences using our simple and powerful
                        event platform. Create, ticket and host both on-ground
                        and digital events on a platform used by millions of live event loving fans.</h2>
                    <button className='mybutton button5'>List Your Event</button>
                </div>
                <br />
                <br />
            </div>
            <br /><br />

            <div className="container">
                <div style={{ padding: '0 20px' }}>
                    <div className='row no-gutters'>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className='list-event-step-details'>
                                <small>01</small>
                                <h1>Easy Event <br />
                                    Setup</h1>
                                <h2>List your event in under five minutes. Our all-in-one
                                    platform lets you set up, sell event tickets, host them,
                                    generate reports and collect payments</h2>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12">
                            <div className="list-event-step-img">
                                <img src="/img/event_setup.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row no-gutters flex-row-reverse'>
                        <div className="col-lg-7 col-md-6 col-sm-12 col-sm-push-12">
                            <div className="list-event-step-img">
                                <img src="/img/smart_tracking.svg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-sm-pull-12 order-first">
                            <div className='list-event-step-details'>
                                <small>02</small>
                                <h1>Smart <br />
                                    Ticketing</h1>
                                <h2>Integrated attendee management and guest list management.
                                    An event ticketing system with automated reminders to ticket buyers.</h2>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row no-gutters'>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className='list-event-step-details'>
                                <small>03</small>
                                <h1>Extensive <br /> Distribution</h1>
                                <h2>Your events get auto-listed and
                                    you benefit from our network of 10 million+ across insider.</h2>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12">
                            <div className="list-event-step-img">
                                <img src="/img/easy_distribution.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row no-gutters flex-row-reverse'>
                        <div className="col-lg-7 col-md-6 col-sm-12 col-sm-push-12">
                            <div className="list-event-step-img">
                                <img src="/img/event_setup.svg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-sm-pull-12 order-first">
                            <div className='list-event-step-details'>
                                <small>04</small>
                                <h1>Host Virtual <br /> Experiences</h1>
                                <h2>Stream live or pre-recorded content
                                    while you engage and interact with
                                    your audience via live chat, polls, Q&A and more. <strong>Know More</strong></h2>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row no-gutters'>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className='list-event-step-details'>
                                <small>05</small>
                                <h1>Track <br />
                                    Analytics</h1>
                                <h2>Real-time tracking for your event's sales,
                                    daily ticketing trends, traffic attribution and more.</h2>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12">
                            <div className="list-event-step-img">
                                <img src="/img/track_analytics.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <br />
                </div>

                <hr />
                <div className='container'>
                    <div className="heading">
                        <span className="material-symbols-outlined">
                            deployed_code
                        </span>
                        <h4 style={{ fontWeight: '1000' }}>Packages</h4>
                    </div><br />
                    <RegistrationPackage calledFrom='dashboard' setShowSection={setShowSection}></RegistrationPackage>
                </div>

                {/* <div className='large'>
                    <div className='container'>
                        <div className='row no-gutters'>
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                <div className='packages-div'>
                                    <div className='packages-div-heading'>
                                        <h1>1 Event</h1>
                                    </div>
                                    <div className='packages-div-details'>
                                        <h2>Just Pay ₹2,000</h2>
                                        <hr />
                                        <p>Sell tickets to digital events</p>
                                        <br />
                                        <p>Host an event on any platform of your choice</p>
                                        <br />
                                        <p>Send customers details to join your event online</p>
                                        <br />
                                        <div>
                                            <button className='mybutton button5'>CHOOSE THIS</button>
                                        </div>
                                    </div>
                                </div >
                            </div >

                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                <div className='packages-div'>
                                    <div className='packages-div-heading'>
                                        <h1>6 Events</h1>
                                    </div>
                                    <div className='packages-div-details'>
                                        <h2>Just Pay <small>₹12,000</small> ₹11,000</h2>
                                        <hr />
                                        <p>Supports upto 4K of HD livestreams</p>
                                        <br />
                                        <p>No limit on time and capacity</p>
                                        <br />
                                        <p>Live engagements & interactions</p>
                                        <br />
                                        <div>
                                            <button className='mybutton button5'>CHOOSE THIS</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                <div className='packages-div'>
                                    <div className='packages-div-heading'>
                                        <h1>12 Events</h1>
                                    </div>
                                    <div className='packages-div-details'>
                                        <h2>Just Pay <small>₹24,000</small> ₹19,000</h2>
                                        <hr />
                                        <p>Create an event from a video file.</p>
                                        <br />
                                        <p>Pre-program engagements & interactions</p>
                                        <br />
                                        <p>Theatre Mode (screen at a set time) and Video On Demand (watch anytime) options</p>
                                        <br />
                                        <div>
                                            <button className='mybutton button5'>CHOOSE THIS</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                    <br />
                </div > */}

                {/* <div className='small' >
                    <div>
                        <div className="package-slider-container">
                            <div className='package-slider-cards'>
                                <div className='package-slider-single-card active' id='firstPackageCard' onClick={() => packageCardClick('firstPackageCard')}>
                                    <div className='packages-div package-small-card'>
                                        <div className='packages-div-heading'>
                                            <h1>1 Event</h1>
                                        </div>
                                        <div className='packages-div-details'>
                                            <h2>Just Pay ₹2,000</h2>

                                            <hr />
                                            <p>Sell tickets to digital events</p>
                                            <br />
                                            <p>Host an event on any platform of your choice</p>
                                            <br />
                                            <p>Send customers details to join your event online</p>
                                            <br />
                                            <div>
                                                <button className='mybutton button5'>CHOOSE THIS</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='package-slider-single-card' id='secondPackageCard' onClick={() => packageCardClick('secondPackageCard')}>
                                    <div className='packages-div package-small-card'>
                                        <div className='packages-div-heading'>
                                            <h1>6 Events</h1>
                                        </div>
                                        <div className='packages-div-details'>
                                            <h2>Just Pay <small>₹12,000</small> ₹11,000</h2>
                                            <hr />
                                            <p>Supports upto 4K of HD livestreams</p>
                                            <br />
                                            <p>No limit on time and capacity</p>
                                            <br />
                                            <p>Live engagements & interactions</p>
                                            <br />
                                            <div>
                                                <button className='mybutton button5'>CHOOSE THIS</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='package-slider-single-card' id='thirdPackageCard' onClick={() => packageCardClick('thirdPackageCard')}>
                                    <div className='packages-div package-small-card'>
                                        <div className='packages-div-heading'>
                                            <h1>12 Events</h1>
                                        </div>
                                        <div className='packages-div-details'>
                                            <h2>Just Pay <small>₹20,000</small> ₹18,000</h2>
                                            <hr />
                                            <p>Create an event from a video file.</p>
                                            <br />
                                            <p>Pre-program engagements & interactions</p>
                                            <br />
                                            <p>Theatre Mode (screen at a set time) and Video On Demand (watch anytime) options</p>
                                            <br />
                                            <div>
                                                <button className='mybutton button5'>CHOOSE THIS</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div > */}

            </div >
            <br />
        </>
    )
}
