import { useState } from 'react'
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import axios from 'axios';
export function useRazorpay() {

    // export const useRazorpay = () => {

    const [paymentResponse, setPaymentResponse] = useState({
        transactionID: '',
        paymentStatus: 'Pending'
    })

    async function savePaymentStatus(bookingIDList, amount, transactionID, orderID) {
        var para1 = {};


        para1 = {
            paymentStatus: 'Completed',
            paymentAmount: amount,
            transactionID: transactionID,
            orderID: orderID,
            BookingID: bookingIDList// transaction ids to be updated as success

        };
        console.log('para1', para1);
        const ret1 = await httpsCallable(functions, "updatePaymentStatusForBookingID");
        ret1(para1).then(async (result) => {

        });


    }

    async function makePayment(orderId, amount, name, description, email, contact, bookingIDList) {

        console.log(orderId);
        const razorpayOptions = {
            key: 'rzp_live_PtXDhri40XHxjr',//'rzp_test_CwsCC1LHx5EJMb',//'LVOZrM0C1CoNu0',//text 'rzp_test_CwsCC1LHx5EJMb',//'rzp_test_gaZqhFw4MY2o6v',LVOZrM0C1CoNu0
            amount: amount * 100, // amount in paise
            name: 'TPLiVE',
            description: description,
            email: email,
            contact: contact,
            image: './img/TPLiVE_Logo.webp',

            // image: 'https://tplive-prod--tplive-test-h1bjje65.web.app/img/TPLiVE_Logo.webp',
            handler: async function (response) {
                console.log('before call', new Date(), ':: response.razorpay_payment_id', response.razorpay_payment_id)

                setPaymentResponse({
                    transactionID: response.razorpay_payment_id,
                    paymentStatus: 'Success'
                })
                //update list of booking id in DB
                // await savePaymentStatus(bookingIDList, amount, response.razorpay_payment_id, orderId);
                console.log('after call, response', new Date(), response)

            },
            modal: {
                ondismiss: function () {
                    console.log('cancelled')
                    setPaymentResponse({
                        transactionID: '',
                        paymentStatus: 'Cancelled'
                    })
                    // return paymentResponse;
                },
                onfailure: function () {
                    console.log('cancelled')
                    setPaymentResponse({
                        transactionID: '',
                        paymentStatus: 'Cancelled'
                    })
                    // return paymentResponse;
                }
            }
            ,

            prefill: {
                name: name,
                email: email,
                contact: contact,
            },
            notes: {
                orderId: orderId,
            },
            theme: {
                color: '#348DCB',
            },
        };

        const rzp1 = new window.Razorpay(razorpayOptions);

        await rzp1.open();


    }


    async function handleRefund2(obj, description) {
        console.log('obj::', obj, '::description:', description)
        const razorpayOptions = {

            key: 'rzp_live_PtXDhri40XHxjr',//'rzp_test_CwsCC1LHx5EJMb',//'LVOZrM0C1CoNu0',//text 'rzp_test_CwsCC1LHx5EJMb',//'rzp_test_gaZqhFw4MY2o6v',LVOZrM0C1CoNu0
            amount: Number(obj.Fees) * 100, // amount in paise
            currency: 'INR',
            name: 'TPLiVE',
            entity: 'refund',
            payment_id: obj.TransactionID,
            description: description,
            email: 'anitatripathi@gmail.com',
            contact: '9922112886',
            image: './img/TPLiVE_Logo.webp',
            "batch_id": null,
            "status": "processed",
            "speed_processed": "normal",
            "speed_requested": "normal",
            "notes": {
                "notes_key_1": "Refund From TPLIVE .",
                "notes_key_2": "Engage"
            },
            // image: 'https://tplive-prod--tplive-test-h1bjje65.web.app/img/TPLiVE_Logo.webp',
            // handler: async function (response) {
            //     console.log('before call', new Date(), ':: response.razorpay_payment_id', response.razorpay_payment_id)

            //     setPaymentResponse({
            //         transactionID: response.razorpay_payment_id,
            //         paymentStatus: 'Success'
            //     })
            //     //update list of booking id in DB
            //     // await savePaymentStatus(bookingIDList, amount, response.razorpay_payment_id, orderId);
            //     console.log('after call, response', new Date(), response)

            // },
            // modal: {
            //     ondismiss: function () {
            //         console.log('cancelled')
            //         setPaymentResponse({
            //             transactionID: '',
            //             paymentStatus: 'Cancelled'
            //         })
            //         // return paymentResponse;
            //     },
            //     onfailure: function () {
            //         console.log('cancelled')
            //         setPaymentResponse({
            //             transactionID: '',
            //             paymentStatus: 'Cancelled'
            //         })
            //         // return paymentResponse;
            //     }
            // }
            // ,
            // prefill: {
            //     name: 'anita',
            //     email: 'anitatripathi@gmail.com',
            //     contact: '9922112886',
            // },

            // theme: {
            //     color: '#348DCB',
            // },
        };
        console.log('razorpayOptions', razorpayOptions);
        console.log('wondow', window)
        // axios.request(razorpayOptions).then(function (response) {
        //     console.log(response)
        //     // return res.redirect(response.data.data.instrumentResponse.redirectInfo.url)
        // })
        //     .catch(function (error) {
        //         console.log(error);
        //     });



    }

    async function handleRefund3(obj, description) {

        const razorpayOptions = {
            key: 'rzp_live_PtXDhri40XHxjr',//'rzp_test_CwsCC1LHx5EJMb',//'LVOZrM0C1CoNu0',//text 'rzp_test_CwsCC1LHx5EJMb',//'rzp_test_gaZqhFw4MY2o6v',LVOZrM0C1CoNu0
            amount: Number(obj.Fees) * 100, // amount in paise
            currency: 'INR',
            name: 'TPLiVE',
            entity: 'refund',
            payment_id: obj.TransactionID,
            description: description,
            email: 'anitatripathi@gmail.com',
            contact: '9922112886',
            image: './img/TPLiVE_Logo.webp',
            "batch_id": null,
            "status": "processed",
            "speed_processed": "normal",
            "speed_requested": "normal",
            "notes": {
                "notes_key_1": "Refund From TPLIVE .",
                "notes_key_2": "Engage"
            },
            // image: 'https://tplive-prod--tplive-test-h1bjje65.web.app/img/TPLiVE_Logo.webp',
            handler: async function (response) {
                console.log('before call', new Date(), ':: response.razorpay_payment_id', response.razorpay_payment_id)

                setPaymentResponse({
                    transactionID: response.razorpay_payment_id,
                    paymentStatus: 'Success'
                })
                //update list of booking id in DB
                // await savePaymentStatus(bookingIDList, amount, response.razorpay_payment_id, orderId);
                console.log('after call, response', new Date(), response)

            },
            modal: {
                ondismiss: function () {
                    console.log('cancelled')
                    setPaymentResponse({
                        transactionID: '',
                        paymentStatus: 'Cancelled'
                    })
                    // return paymentResponse;
                },
                onfailure: function () {
                    console.log('cancelled')
                    setPaymentResponse({
                        transactionID: '',
                        paymentStatus: 'Cancelled'
                    })
                    // return paymentResponse;
                }
            }
            ,
            prefill: {
                name: 'anita',
                email: 'anitatripathi@gmail.com',
                contact: '9922112886',
            },

            theme: {
                color: '#348DCB',
            },
        };

        const rzp1 = new window.Razorpay(razorpayOptions);

        await rzp1.open();


    }
    const handleRefund = async (obj, refundReason) => {
        const razorpayOptions = {
            "id": "rfnd_FgRAHdNOM4ZVbO",
            "entity": "refund",
            "amount": Number(obj.Fees) * 100,
            "currency": "INR",
            "payment_id": obj.TransactionID,
            "notes": {
                "notes_key_1": "Refudn from TPLIVE ",
                "notes_key_2": ""
            },
            "receipt": null,
            "acquirer_data": {
                "arn": "10000000000000"
            },
            "created_at": 1600856650,
            "batch_id": null,
            "status": "processed",
            "speed_processed": "normal",
            "speed_requested": "normal"
        }

        try {
            const response = await axios.post(
                `https://api.razorpay.com/v1/payments/${obj.TransactionID}/refund`,
                {
                    amount: Number(obj.Fees) * 100, // replace with the amount to be refunded
                    notes: {
                        reason: refundReason, // replace with the reason for the refund
                    },
                    "id": "rfnd_FgRAHdNOM4ZVbO",
                    "entity": "refund",
                    "currency": "INR",
                    "payment_id": obj.TransactionID,

                },
                {
                    auth: {
                        username: 'rzp_live_PtXDhri40XHxjr',//'rzp_test_gaZqhFw4MY2o6v',
                        password: 'yzmgeKof87Ywz57ujFkBJdnB',//'mL2eEEGVYMTq0aMCaB2EEUpd',
                    },
                }
            );

            console.log('success ', response.data);


        } catch (error) {
            console.log('error', error);
        }

    };


    return { makePayment, paymentResponse, handleRefund }
}