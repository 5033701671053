import '../css/Navbar.css'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthcontext'
import { useGetOrder } from '../hooks/useGetOrder'
import { useLocation } from 'react-router-dom'
export default function Navbar(props) {
    const { user, users, logout } = useUserAuth();

    const [city, setCity] = useState(!window.localStorage.getItem("userLocation") ? 'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
        'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', ''))
    const [name, setName] = useState('User')
    const [reload, setReload] = useState(false);
    const [entryCnt, setEntryCnt] = useState(0);
    const [signinFlag, setSigninFlag] = useState(true);
    const { orders, orderCnt, isCompleted } = useGetOrder();
    // function setLoginFlag(flag) {
    //     // console.log('in setLoginFlag', flag)
    //     // setLoginFlagForWithdraw(true)
    //     setSigninFlag(flag);
    // }
    const location = useLocation();

    useEffect(() => {
        // Trigger an effect on route change
        console.log('Route changed:', location.pathname);
        setReload(!reload)
    }, [location]);
    useEffect(() => {
        // console.log('reload', reload)
        setReload(!reload)
        orders && setEntryCnt(orders.length)
    }, [user, isCompleted, user, user.userInfo])

    useEffect(() => {
        // console.log(user);
        setName('User')
        if (user && user.userInfo) {
            let fullName = user && user.userInfo ? user.userInfo.UserName : '';
            let nameList = []
            if (fullName && fullName !== '') {
                nameList = fullName.split(" ");
                setName(nameList[0])
            }
            setCity(!window.localStorage.getItem("userLocation") ? 'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
                'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', ''));
        }
    }, [user, city])
    console.log('window.localStorage.getItem("userLocation")', window.localStorage.getItem("userLocation"))
    return (
        <nav className="navbar navbar-expand-lg sticky-top" >

            {/* {console.log(props.isFlag)} */}
            <Link className="navbar-brand" to="/">
                <img className="large" style={{ height: '50px' }} src="/img/TPLiVE_text_white.png" alt="TPLiVE" />
            </Link>
            <div className="collapse navbar-collapse" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="">

                </div>
                <ul className="nav navbar-nav" >

                    {/* {props.isFlag !== 'home' && <li className="nav-item"> */}
                    {<li className="nav-item">
                        <b></b>
                        <b></b>
                        <Link className="nav-link " style={{ color: '#fff' }} to="/">

                            <span className="material-symbols-outlined">
                                home
                            </span>
                            <small>Home</small>
                        </Link>
                    </li>}
                    {/* {props.isFlag !== 'event' && */}
                    {<li className="nav-item">
                        <b></b>
                        <b></b>

                        <li className='navbar-location-new' style={{ background: 'transparent' }}>
                            <Link to="/Event">
                                <div className='navbar-location-new-details location'>
                                    <span style={{ position: 'relative', left: '2px' }} className="material-symbols-outlined">
                                        location_on
                                    </span>
                                    <small style={{ fontSize: '0.9rem', paddingRight: '3px' }}>Events in {!window.localStorage.getItem("userLocation") ? 'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
                                        'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '')}</small>
                                </div>
                            </Link>
                        </li>
                        {/* //Code chaneg for Prod */}

                        {/* <Link className="nav-link"
                            to="/Event">
                            <span className="material-symbols-outlined">
                                redeem
                            </span>
                            <small>Events</small>
                        </Link> */}
                        {/* <a className="nav-link"
                                href="https://tournamentplanner.in/screens/TPLive_TournamentList.aspx?tstatus=upcoming&ocode=QQBDAFQASQBWAEUA">
                                <span className="material-symbols-outlined">
                                    redeem
                                </span>
                                <small>Events</small>
                            </a> */}
                    </li>}
                    {/* {props.isFlag !== 'clubs' && 
                    <li className="nav-item">
                        <Link className="nav-link"
                            to="/Clubs">
                            <span className="material-symbols-outlined">
                                category
                            </span>
                            <small>Clubs</small>
                        </Link>
                    

                    </li>
                    } */}
                    {/* {(props.isFlag !== 'contactus') && <li className="nav-item">
                        <b></b>
                        <b></b>
                        <Link className="nav-link" to="/ContactUs">
                            <span className="material-symbols-outlined">
                                group_work
                            </span>
                            <small>Contact us</small>
                        </Link>
                    </li>} */}
                    {/* {(props.isFlag !== 'dashboard') && <li className="nav-item">
                        <b></b>
                        <b></b>
                        <Link className="nav-link " style={{ color: '#fff' }} to="/Dashboard">

                            <span className="material-symbols-outlined">
                                dashboard
                            </span>
                            <small>Dashboard</small>
                        </Link>
                    </li>} */}


                    {/* {(props.isFlag !== 'more') && <li className="nav-item"> */}


                    <li className='navbar-location-new'>
                        <Link to="/Orders" style={{ position: 'relative' }}>
                            <div className='navbar-location-new-details location' style={{ background: '#fff', color: '#333C5D' }}>
                                <span style={{ position: 'relative', top: '2px', left: '2px', fontSize: '1.7rem' }} className="material-symbols-outlined">
                                    remove_shopping_cart
                                </span>

                            </div>
                            <div className='navbar-cart-number-new' style={{
                                top: '6px',
                                left: '56%',
                                color: '#333C5D',
                                background: '#fff',
                                width: '9px',
                            }}>
                                <small style={{ top: '-2px', fontSize: '0.7rem' }}>{user && user.userInfo && orders ? orderCnt : 0}</small>
                            </div>
                        </Link>
                    </li>

                    {/* <li className='navbar-location-new'>
                        <Link to="/Location">
                            <div className='navbar-location-new-details location'>
                                <span style={{ position: 'relative', left: '2px' }} className="material-symbols-outlined">
                                    location_on
                                </span>
                                <small> {window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ? 'All City' : window.localStorage.getItem("userLocation").replaceAll('"', '')}</small>
                            </div>
                        </Link>
                    </li> */}
                    <li className='navbar-location-new'>
                        <Link to="/Notification" style={{ position: 'relative' }}>
                            <div className='navbar-location-new-details notification'>
                                <span style={{ left: '2px', fontSize: '1.5rem' }} className="material-symbols-outlined">
                                    notifications
                                </span>
                                <b className='navbar-notification-dot'></b>
                            </div>

                        </Link>
                    </li>

                    <li className='navbar-location-new' style={{ marginRight: '12px' }}>
                        <Link to="/Profile">
                            <div className='navbar-location-new-details' style={{ paddingRight: '3px' }}>
                                {/* <span className="material-symbols-outlined">
                                person
                            </span> */}
                                <small>{name !== 'User' ? 'Hi, ' + name : 'Login'}</small>
                                <div>
                                    <img src={user && user.userInfo && user.userInfo.ProfilePicURL ? user.userInfo.ProfilePicURL : '/img/user_icon_transparent.png'} alt="" />
                                </div>
                            </div>
                        </Link>
                    </li>

                    {<li className="nav-item">
                        <b></b>
                        <b></b>
                        <Link className="nav-link " style={{ color: '#fff' }} to="/more">

                            <span className="material-symbols-outlined">
                                apps
                            </span>
                            <small>More</small>
                        </Link>
                    </li>}

                </ul>

            </div>

        </nav>

    )
}
