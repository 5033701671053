import React from 'react';
import '../css/TournamentDraw.css'; // Create this CSS file for styling

class TournamentDraw extends React.Component {
    renderBracketRounds(participants) {
        const rounds = Math.log2(participants.length);
        let bracket = [];
        let matchUps = [...participants];

        for (let round = 0; round < rounds; round++) {
            let roundMatchUps = [];
            for (let i = 0; i < matchUps.length; i += 2) {
                roundMatchUps.push(
                    <div key={`round-${round}-match-${i}`} className="matchup">
                        <div className="participant">{matchUps[i]}</div>
                        <div className="participant">{matchUps[i + 1]}</div>
                    </div>
                );
            }
            bracket.push(
                <div key={`round-${round}`} className="round">
                    {roundMatchUps}
                </div>
            );
            // Prepare matchups for the next round
            matchUps = matchUps.reduce((acc, _, i, arr) => i % 2 === 0 ? [...acc, "Winner"] : acc, []);
        }

        return bracket;
    }

    render() {
        const { participants } = this.props;

        return (
            <div className="tournament-bracket">
                {this.renderBracketRounds(participants)}
            </div>
        );
    }
}

export default TournamentDraw;
