import React, { useState, useEffect } from 'react'
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
import { useUserAuth } from '../context/UserAuthcontext.js';
import { useNavigate } from 'react-router-dom';
import useDocumentUpdate from '../hooks/useDocumentUpdate.js';
import SignIn from './SignIn.js';

export default function RegistrationPackage(props) {
    const { user } = useUserAuth()
    const navigate = useNavigate();
    const { addDocument, deleteDocument } = useDocumentUpdate();
    const [singleEvent, setSingleEvent] = useState({})
    const [signinFlag, setSigninFlag] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);
    const [showSignIn, setShowSignIn] = useState(false);

    const [sixEvent, setSixEvent] = useState({})
    const [dozenEvent, setDozenEvent] = useState({})
    const query = [
        where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ' '),
    ]
    const emptyquery = [
    ]
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };
    const { documents: packageDoc, error: packageDocError } = useCollection('UserPackageDetails', query)
    const { documents: masterPackageDoc, error: masterPackageDocError } = useCollection('MasterPackage', emptyquery)

    useEffect(() => {
        let _single = masterPackageDoc.find(e => e.PackageName === 'SingleEvent')
        // { Number(category.Fees).toLocaleString('en-IN', curFormat) }
        if (_single) {
            let _totalAmount = Number(_single.PrizePerEvent) * Number(_single.NumberOfEvent);
            let _singleDiscountedPrize = _totalAmount - (Number(_totalAmount) * Number(_single.Discount) / 100);

            setSingleEvent({
                ..._single,
                TotalAmount: Number(_totalAmount).toLocaleString('en-IN', curFormat),
                DiscoundedAmount: Number(_singleDiscountedPrize).toLocaleString('en-IN', curFormat)
            })
        }

        let _sixevent = masterPackageDoc.find(e => e.PackageName === 'SixEvent')
        if (_sixevent) {
            let _totalAmountSix = Number(_sixevent.PrizePerEvent) * Number(_sixevent.NumberOfEvent);
            let _discountedPrizesix = _totalAmountSix - (Number(_totalAmountSix) * Number(_sixevent.Discount) / 100);
            console.log(_totalAmountSix, _discountedPrizesix)
            setSixEvent({
                ..._sixevent,
                TotalAmount: Number(_totalAmountSix).toLocaleString('en-IN', curFormat),
                DiscoundedAmount: Number(_discountedPrizesix).toLocaleString('en-IN', curFormat)
            })
        }

        let _dozenevent = masterPackageDoc.find(e => e.PackageName === 'DozenEvent')
        if (_dozenevent) {
            let _totalAmountDozen = Number(_dozenevent.PrizePerEvent) * Number(_dozenevent.NumberOfEvent);
            let _discountedPrizeDozen = _totalAmountDozen - (Number(_totalAmountDozen) * Number(_dozenevent.Discount) / 100);
            setDozenEvent({
                ..._dozenevent,
                TotalAmount: Number(_totalAmountDozen).toLocaleString('en-IN', curFormat),
                DiscoundedAmount: Number(_discountedPrizeDozen).toLocaleString('en-IN', curFormat)
            })
        }
        if (packageDoc && packageDoc[0] && packageDoc[0].EventsAlreadyCreated === packageDoc[0].NumberOfEvent) {
            //already used all event
            addDocument('UserPackageDetailsArchive', {
                ...packageDoc[0],
                'ArchivedOn': new Date()
            })
            deleteDocument('UserPackageDetails', packageDoc[0].id)

        } else {
            if (packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SingleEvent') {
                packageCardClick('firstPackageCard')
            } else if (packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SixEvent') {
                packageCardClick('secondPackageCard')
            } else if (packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'DozenEvent') {
                packageCardClick('thirdPackageCard')
            }
        }

    }, [masterPackageDoc, packageDoc])

    const packageCardClick = (selectCard) => {
        document.getElementById('firstPackageCard').classList.remove('active');
        document.getElementById('secondPackageCard').classList.remove('active');
        document.getElementById('thirdPackageCard').classList.remove('active');

        document.getElementById(selectCard).classList.add('active');
    }
    function ProceedToCreateEvent() {
        props.setShowSection('sport')
        if (props.calledFrom === 'dashboard') {
            navigate('/CreateEvent', {
                state: {
                    createStage: 'sport'
                }
            })
        }
    }

    function setPackage(selectedPackage) {
        if (!user || !user.userInfo) {
            setShowSignIn(true)
        }
        else {

            console.log('selectedPackage', selectedPackage)
            let packageDetails = masterPackageDoc.find(e => e.PackageName === selectedPackage)
            console.log(packageDetails)
            let paymentAmount = Number(packageDetails.PrizePerEvent) * Number(packageDetails.NumberOfEvent)
            console.log(paymentAmount)
            let discount = Number(paymentAmount) * Number(packageDetails.Discount) / 100;
            console.log(discount)

            let addData = {
                'UserID': user && user.userInfo ? user.userInfo.uid : '',
                'PackageName': selectedPackage,
                'NumberOfEvent': packageDetails.NumberOfEvent,
                'EventsAlreadyCreated': 0,
                'PaymentAmount': Number(paymentAmount) - Number(discount),
                'PaymentStatus': 'Pending',
                'DiscountAmount': discount,
                'DiscountPercentage': packageDetails.Discount,
                "CreatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "CreatedTimestamp": new Date(),

            }
            addDocument('UserPackageDetails', addData)
            props.setShowSection('Sport')
            if (props.calledFrom === 'dashboard') {
                navigate('/CreateEvent')
            }
        }

    }
    function setSignIn(flag, userData) {
        console.log(flag, userData)
        // setUserDetails(userData);
        // setSigninFlag(flag);
        setShowSignIn(!flag);

    }

    console.log('packageDoc', packageDoc)
    return (
        <>
            {showSignIn && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>}

            <div className='large'>
                <div className='container'>
                    <div className='row no-gutters'>
                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <div className='packages-div'>
                                <div className='packages-div-heading'>
                                    <h1>{singleEvent && singleEvent.NumberOfEvent} Event</h1>
                                </div>
                                <div className='packages-div-details'>
                                    <h2>Just Pay {singleEvent.TotalAmount}</h2>
                                    <hr />
                                    <p>Sell tickets to digital events</p>
                                    <br />
                                    {/* <p>Host an event on any platform of your choice</p>
                                        <br />
                                        <p>Send customers details to join your event online</p>
                                        <br /> */}
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SingleEvent' &&
                                        <>
                                            <p style={{ fontWeight: 'bold', }}>Already Created Events : {packageDoc[0].EventsAlreadyCreated}</p><br />
                                            <p style={{ fontWeight: 'bold', }}>Events Pending : {Number(packageDoc[0].NumberOfEvent - packageDoc[0].EventsAlreadyCreated)}</p>
                                        </>
                                    }
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SingleEvent' &&
                                        <div onClick={() => {
                                            ProceedToCreateEvent()
                                        }}>
                                            <button className='mybutton button5'>Current Package - Proceed</button>
                                        </div>
                                    }
                                    {packageDoc && !packageDoc[0] && <div onClick={() => {
                                        setPackage('SingleEvent')
                                    }}>
                                        <button className='mybutton button5'>CHOOSE THIS</button>
                                    </div>}

                                </div>
                            </div >
                        </div >

                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <div className='packages-div'>
                                <div className='packages-div-heading'>
                                    <h1>{sixEvent && sixEvent.NumberOfEvent} Events</h1>
                                </div>
                                <div className='packages-div-details'>
                                    <h2>Just Pay <small>{sixEvent && sixEvent.TotalAmount}</small> {sixEvent && sixEvent.DiscoundedAmount}</h2>
                                    <hr />
                                    <p>Live engagements & interactions</p>
                                    <br />
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SixEvent' &&
                                        <>
                                            <p style={{ fontWeight: 'bold', }}>Already Created Events : {packageDoc[0].EventsAlreadyCreated}</p><br />
                                            <p style={{ fontWeight: 'bold', }}>Events Pending : {Number(packageDoc[0].NumberOfEvent - packageDoc[0].EventsAlreadyCreated)}</p>
                                        </>
                                    }
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SixEvent' &&
                                        <div onClick={() => {
                                            ProceedToCreateEvent()
                                        }}>
                                            <button className='mybutton button5'>Current Package - Proceed</button>
                                        </div>
                                    }
                                    {packageDoc && !packageDoc[0] && <div onClick={() => {
                                        setPackage('SixEvent')
                                    }}>
                                        <button className='mybutton button5'>CHOOSE THIS</button>
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12'>
                            <div className='packages-div'>
                                <div className='packages-div-heading'>
                                    <h1>{dozenEvent && dozenEvent.NumberOfEvent} Events</h1>
                                </div>
                                <div className='packages-div-details'>
                                    <h2>Just Pay <small>{dozenEvent && dozenEvent.TotalAmount}</small> {dozenEvent && dozenEvent.DiscoundedAmount}</h2>
                                    <hr />
                                    <p>Create an event from a video file.</p>
                                    <br />

                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'DozenEvent' &&
                                        <>
                                            <p style={{ fontWeight: 'bold', }}>Already Created Events : {packageDoc[0].EventsAlreadyCreated}</p><br />
                                            <p style={{ fontWeight: 'bold', }}>Events Pending : {Number(packageDoc[0].NumberOfEvent - packageDoc[0].EventsAlreadyCreated)}</p>
                                        </>
                                    }
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'DozenEvent' &&
                                        <div onClick={() => {
                                            ProceedToCreateEvent()
                                        }}>
                                            <button className='mybutton button5'>Current Package - Proceed</button>
                                        </div>
                                    }
                                    {packageDoc && !packageDoc[0] && <div onClick={() => {
                                        setPackage('DozenEvent')
                                    }}>
                                        <button className='mybutton button5'>CHOOSE THIS</button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                <br />
            </div >
            <div className='small'>
                <div className="package-slider-container">
                    <div className='package-slider-cards'>
                        <div className='package-slider-single-card active' id='firstPackageCard' onClick={() => packageCardClick('firstPackageCard')}>
                            <div className='packages-div package-small-card'>
                                <div className='packages-div-heading'>
                                    <h1>{singleEvent && singleEvent.NumberOfEvent} Event</h1>
                                </div>
                                <div className='packages-div-details'>
                                    <h2>Just Pay {singleEvent && singleEvent.TotalAmount}</h2>

                                    <hr />
                                    <p>Sell tickets to digital events</p>
                                    {/* <br />
                                        <p>Host an event on any platform of your choice</p>
                                        <br />
                                        <p>Send customers details to join your event online</p>
                                        <br /> */}
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SingleEvent' &&
                                        <>
                                            <p style={{ fontWeight: 'bold', }}>Already Created Events : {packageDoc[0].EventsAlreadyCreated}</p><br />
                                            <p style={{ fontWeight: 'bold', }}>Events Pending : {Number(packageDoc[0].NumberOfEvent - packageDoc[0].EventsAlreadyCreated)}</p>
                                        </>
                                    }
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SingleEvent' &&
                                        <div onClick={() => {
                                            ProceedToCreateEvent()
                                        }}>
                                            <button className='mybutton button5'>Current Package - Proceed</button>
                                        </div>
                                    }
                                    {packageDoc && !packageDoc[0] && <div onClick={() => {
                                        setPackage('SingleEvent')
                                    }}>
                                        <button className='mybutton button5'>CHOOSE THIS</button>
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div className='package-slider-single-card' id='secondPackageCard' onClick={() => packageCardClick('secondPackageCard')}>
                            <div className='packages-div package-small-card'>
                                <div className='packages-div-heading'>
                                    <h1>{sixEvent && sixEvent.NumberOfEvent} Events</h1>
                                </div>
                                <div className='packages-div-details'>
                                    <h2>Just Pay <small>{sixEvent && sixEvent.TotalAmount}</small> {sixEvent && sixEvent.DiscoundedAmount}</h2>
                                    <hr />

                                    <p>Live engagements & interactions</p>
                                    <br />
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SixEvent' &&
                                        <>
                                            <p style={{ fontWeight: 'bold', }}>Already Created Events : {packageDoc[0].EventsAlreadyCreated}</p><br />
                                            <p style={{ fontWeight: 'bold', }}>Events Pending : {Number(packageDoc[0].NumberOfEvent - packageDoc[0].EventsAlreadyCreated)}</p>
                                        </>
                                    }
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'SixEvent' &&
                                        <div onClick={() => {
                                            ProceedToCreateEvent()
                                        }}>
                                            <button className='mybutton button5'>Current Package - Proceed</button>
                                        </div>
                                    }
                                    {packageDoc && !packageDoc[0] && <div onClick={() => {
                                        setPackage('SixEvent')
                                    }}>
                                        <button className='mybutton button5'>CHOOSE THIS</button>
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div className='package-slider-single-card' id='thirdPackageCard' onClick={() => packageCardClick('thirdPackageCard')}>
                            <div className='packages-div package-small-card'>
                                <div className='packages-div-heading'>
                                    <h1>{dozenEvent && dozenEvent.NumberOfEvent} Events</h1>
                                </div>
                                <div className='packages-div-details'>
                                    <h2>Just Pay <small>{dozenEvent && dozenEvent.TotalAmount}</small> {dozenEvent && dozenEvent.DiscoundedAmount}</h2>
                                    <hr />
                                    <p>Create an event from a video file.</p>
                                    {/* <br />
                                        <p>Pre-program engagements & interactions</p>
                                        <br />
                                        <p>Theatre Mode (screen at a set time) and Video On Demand (watch anytime) options</p>
                                        <br /> */}
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'DozenEvent' &&
                                        <>
                                            <p style={{ fontWeight: 'bold', }}>Already Created Events : {packageDoc[0].EventsAlreadyCreated}</p><br />
                                            <p style={{ fontWeight: 'bold', }}>Events Pending : {Number(packageDoc[0].NumberOfEvent - packageDoc[0].EventsAlreadyCreated)}</p>
                                        </>
                                    }
                                    {packageDoc && packageDoc[0] && packageDoc[0].PackageName === 'DozenEvent' &&
                                        <div onClick={() => {
                                            ProceedToCreateEvent()
                                        }}>
                                            <button className='mybutton button5'>Current Package - Proceed</button>
                                        </div>
                                    }
                                    {packageDoc && !packageDoc[0] && <div onClick={() => {
                                        setPackage('DozenEvent')
                                    }}>
                                        <button className='mybutton button5'>CHOOSE THIS</button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
