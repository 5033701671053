import '../css/BottomBar.css'
import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useGetOrder } from '../hooks/useGetOrder';
export default function BottomBar() {

    let location = useLocation();
    const { orderCnt } = useGetOrder();

    return (
        <div className="small navbar-mobile-bottom">
            <div className="navbar-mobile-bottom-menu" id="divBottomNavBar">
                <NavLink to="/" className={(location.pathname === '/' || location.pathname === '/Home') ? "navbar-mobile-bottom-menu-a active" : "navbar-mobile-bottom-menu-a"} style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className="material-symbols-outlined">
                        home
                    </span>
                    <small>Home</small>
                </NavLink>

                <NavLink to="/Event"

                    className={"navbar-mobile-bottom-menu-a "} style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className="material-symbols-outlined">
                        redeem
                    </span>
                    <small>Events</small>
                </NavLink>
                {/* <a href="https://tournamentplanner.in/screens/TPLive_TournamentList.aspx?tstatus=upcoming&ocode=QQBDAFQASQBWAEUA"
                    className={"navbar-mobile-bottom-menu-a "} style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className="material-symbols-outlined">
                        calendar_view_month
                    </span>
                    <small>All Events</small>
                </a> */}
                <div style={{ width: '140px' }}>

                </div>

                <NavLink to="/Orders" className={(location.pathname === '/Dashboard') ? "navbar-mobile-bottom-menu-a active" : "navbar-mobile-bottom-menu-a"}
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className="material-symbols-outlined">
                        shopping_cart
                    </span>
                    <div className='navbar-cart-number-new'>
                        <small>{orderCnt}</small>
                    </div>
                    <small>Cart</small>

                </NavLink>
                {/* Code chaneg for Prod */}
                {/* <NavLink to="/ManageParticipant" className={(location.pathname === '/ManageParticipant') ? "navbar-mobile-bottom-menu-a active" : "navbar-mobile-bottom-menu-a"}
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className="material-symbols-outlined">
                        category
                    </span>
                    <small>My Events</small>
                </NavLink> */}
                {/* <a href="https://tournamentplanner.in/screens/TPLive_Orgs.aspx" className={(location.pathname === '/Clubs') ? "navbar-mobile-bottom-menu-a active" : "navbar-mobile-bottom-menu-a"}
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className="material-symbols-outlined">
                        category
                    </span>
                    <small>My Events</small>
                </a> */}
                <NavLink to="/More" className={(location.pathname === '/More') ? "navbar-mobile-bottom-menu-a active" : "navbar-mobile-bottom-menu-a"}
                    style={{ display: 'flex', flexDirection: 'column' }}>

                    <span className="material-symbols-outlined">
                        apps
                    </span>
                    <small id='small4'>More</small>
                </NavLink>
            </div>
            {/* Code chaneg for Prod */}
            <Link to="/Profile" className="new-user">
                <span className="material-symbols-outlined">
                    person
                </span>
            </Link>
        </div >
    )
}
