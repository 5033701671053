import React, { useState, useEffect } from 'react'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthcontext';
import useDocumentUpdate from '../hooks/useDocumentUpdate';

export default function TournamentCategory(props) {
    const { user } = useUserAuth();

    const [categoryList, setCategoryList] = useState(null);
    const [isEditEnabled, setIsEditEnabled] = useState(props.IsEditEnabled);
    const [loading, setLoading] = useState(false);
    const [categoryMessage, setCategoryMessage] = useState('');
    const [categoryAdd, setCategoryAdd] = useState(null);
    const [categoryEdit, setCategoryEdit] = useState(null)
    const [viewAddCategory, setViewAddCategory] = useState(false);
    const navigate = useNavigate();
    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();

    const [categoryPopup, setCategoryPopup] = useState({
        showPopup: false,
        action: 'Add',
        categoryObj: null
    });
    const [confirmDelete, setConfirmDelete] = useState({
        isDelete: false,
        deleteObjectType: '',
        deleteMessage: '',
        deleteID: ''
    });

    async function addCategoryDetails(cat) {
        // console.log('in addCategoryDetails', cat)
        if (!cat || (cat && cat.CategoryName === '') ||
            (cat && cat.ReferenceDate === '') ||
            (cat && cat.Fees === '')) {
            setCategoryMessage('Enter all details!!')
            // setShowError(true);
            setTimeout(function () {
                setCategoryMessage('')

            }, 5000);
        } else if (
            categoryList.findIndex(e => e.CategoryName.toUpperCase() === cat.CategoryName.toUpperCase() && e.id !== cat.id) >= 0) {
            setCategoryMessage('Category already exists!!')
            // setShowError(true);
            setTimeout(function () {
                setCategoryMessage('')

            }, 5000);
        }
        else {
            ////////////////////
            console.log('cat', cat)
            var para1 = {
                EventID: props.eventDetails.id,
                CategoryName: cat.CategoryName,
                EventType: cat.EventType,
                DateRefType: cat.DateRefType,
                Fees: cat.Fees,
                Gender: cat.Gender,
                MaxTeamSize: cat.MaxTeamSize,
                ReferenceDate: cat.ReferenceDate && cat.ReferenceDate.seconds ? (new Date(cat.ReferenceDate.seconds * 1000)) :
                    cat.ReferenceDate && cat.ReferenceDate._seconds ? (new Date(cat.ReferenceDate._seconds * 1000)) :
                        cat.ReferenceDate ? (new Date(cat.ReferenceDate)) : new Date(),
                IsActive: cat.IsActive,
                id: cat.id ? cat.id : ''
            };

            console.log(para1);
            let category = null;
            let catList = categoryList;

            category = {
                EventID: props.eventDetails.id,
                CategoryName: cat.CategoryName,
                EventType: cat.EventType,
                DateRefType: cat.DateRefType,
                Fees: cat.Fees,
                Gender: cat.Gender,
                MaxTeamSize: cat.MaxTeamSize,
                IsActive: cat.IsActive,
                ReferenceDate: cat.ReferenceDate && cat.ReferenceDate._seconds ? (new Date(cat.ReferenceDate._seconds * 1000)) : (new Date(cat.ReferenceDate)),
                id: cat.id
            }
            // console.log('category', category)
            // console.log('catList', catList)

            setCategoryAdd(null);
            setCategoryEdit(null)
            // catList.push(category)

            const ret = await httpsCallable(functions, "updateTournamentCategory");
            ret(para1).then(async (result) => {
                // console.log(result.data);

                // if (result.data === cat.id) {
                //     let index = catList.findIndex(e => e.id === cat.id);
                //     catList.splice(index, 1)
                // }
                // category = {
                //     EventID: props.eventDetails.id,
                //     CategoryName: cat.CategoryName,
                //     EventType: cat.EventType,
                //     DateRefType: cat.DateRefType,
                //     Fees: cat.Fees,
                //     Gender: cat.Gender,
                //     MaxTeamSize: cat.MaxTeamSize,
                //     ReferenceDate: (new Date(cat.ReferenceDate)),
                //     IsActive: cat.IsActive,
                //     id: result.data
                // }

                setCategoryAdd(null);
                setCategoryEdit(null)
                // catList.push(category)
                // setCategoryList(catList)
                setViewAddCategory(false)
            })
        }
    }

    async function deleteCategory(id) {
        if (!id || id === '') {
            return
        }
        var para1 = {
            EventID: props.eventDetails.id,
            id: id
        };
        setLoading(true);

        // console.log(para1);

        const ret = await httpsCallable(functions, "deleteTournamentCategory");
        ret(para1).then(async (result) => {
            let categoryListObject = categoryList
            let index = categoryListObject.findIndex(e => e.id === id);
            categoryListObject.splice(index, 1)
            setCategoryList(categoryListObject)
            setLoading(false);

            setConfirmDelete({
                isDelete: false,
                deleteMessage: '',
                deleteObjectType: '',
                deleteID: ''
            });
        })
    }

    useEffect(() => {
        setIsEditEnabled(props.IsEditEnabled);

        setCategoryList(props.categoryDetails)
        // let userPhone = ''

        // userPhone = user && user.userInfo && user.userInfo.Phone && user.userInfo.Phone.substring(user.userInfo.Phone.length - 10, user.userInfo.Phone.length)
        // console.log('userPhone : ', userPhone)

    }, [props, props.eventDetails])

    async function changeCategoryActive(id, status) {
        let _updatedData = {
            'IsActive': status,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventCategory', id, _updatedData)

    }
    return (
        <>
            <div className={categoryPopup.showPopup ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner'>
                    <h1>Edit Category</h1>
                    <hr />
                    <div style={{ padding: '20px' }}>
                        <div className="category-card" style={{ position: 'relative', borderRadius: '10px' }} >
                            <input type="text" placeholder="Category Name"
                                className="category-name event-details"
                                onChange={(e) => {
                                    setCategoryPopup({
                                        ...categoryPopup,
                                        categoryObj: {
                                            ...categoryPopup.categoryObj,
                                            CategoryName: e.target.value,
                                        }
                                    })
                                }}

                                value={categoryPopup && categoryPopup.categoryObj ? categoryPopup.categoryObj.CategoryName : ''}
                            />
                            <div className="gender-event-div">
                                <div>
                                    <select style={{ background: '#e4e4e4' }} name="" id="genderAdd"
                                        value={categoryPopup && categoryPopup.categoryObj ? categoryPopup.categoryObj.Gender : 'Male'}
                                        onChange={(e) => {
                                            setCategoryPopup({
                                                ...categoryPopup,
                                                categoryObj: {
                                                    ...categoryPopup.categoryObj,
                                                    Gender: e.target.value,
                                                }
                                            })
                                        }}
                                    >
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="MIXED">MIXED</option>
                                    </select>
                                </div>
                                <span>|</span>
                                <div>
                                    <select name="" style={{ background: '#e4e4e4' }} id="catTypeAdd"
                                        value={categoryPopup && categoryPopup.categoryObj ? categoryPopup.categoryObj.EventType : 'SINGLE'}
                                        onChange={(e) => {
                                            setCategoryPopup({
                                                ...categoryPopup,
                                                categoryObj: {
                                                    ...categoryPopup.categoryObj,
                                                    EventType: e.target.value,
                                                    MaxTeamSize: e.target.value === 'SINGLE' ? 1 : e.target.value === 'DOUBLE' ? 2 : -1
                                                }
                                            })
                                        }}
                                    >
                                        <option value="SINGLE">SINGLE</option>
                                        <option value="DOUBLE">DOUBLE</option>
                                        <option value="TEAM">TEAM</option>
                                    </select>
                                </div>
                            </div>
                            <div className="born-before-div">
                                <h3
                                    onClick={() => {
                                        setCategoryPopup({
                                            ...categoryPopup,
                                            categoryObj: {
                                                ...categoryPopup.categoryObj,
                                                DateRefType: categoryPopup && categoryPopup.categoryObj && categoryPopup.categoryObj.DateRefType === 'Before' ? 'After' : 'Before'
                                            }
                                        })

                                    }}
                                >
                                    <span>Born</span>
                                    <div id="dateRefFlagadd" className={categoryPopup && categoryPopup.categoryObj && categoryPopup.categoryObj.DateRefType ? categoryPopup && categoryPopup.categoryObj.DateRefType.toLowerCase() : 'before'}>
                                        <span> Before </span>
                                        <span className="material-symbols-outlined">
                                            keyboard_arrow_down
                                        </span>
                                        <span> After </span>
                                    </div>
                                    <span>:</span>
                                </h3>
                                {/* {console.log('categoryPopup.categoryObj', categoryPopup.categoryObj)} */}
                                <input type="date" style={{ background: '#e4e4e4', width: 'auto' }}
                                    value={categoryPopup && categoryPopup.categoryObj && categoryPopup && categoryPopup.categoryObj.ReferenceDate && (categoryPopup && categoryPopup.categoryObj.ReferenceDate._seconds ?
                                        ((new Date(categoryPopup && categoryPopup.categoryObj.ReferenceDate._seconds * 1000)).toISOString().split("T")[0]) :
                                        categoryPopup && categoryPopup.categoryObj.ReferenceDate.seconds ? ((new Date(categoryPopup && categoryPopup.categoryObj.ReferenceDate.seconds * 1000)).toISOString().split("T")[0]) :
                                            (new Date(categoryPopup && categoryPopup.categoryObj.ReferenceDate)).toISOString().split("T")[0])}
                                    onChange={(date) => {
                                        setCategoryPopup({
                                            ...categoryPopup,
                                            categoryObj: {
                                                ...categoryPopup.categoryObj,
                                                ReferenceDate: new Date(date.target.value).toISOString().split("T")[0]
                                            }
                                        })

                                    }}
                                />
                            </div>
                            <div className="born-before-div">
                                <h3>Fees : </h3>
                                <input type="text" style={{ maxWidth: '197px', width: '100%' }} className='event-details' id="feeAdd"
                                    value={categoryPopup && categoryPopup.categoryObj ? ("₹" + categoryPopup.categoryObj.Fees ? categoryPopup.categoryObj.Fees : 0) : "₹0"}
                                    onChange={(e) => {
                                        setCategoryPopup({
                                            ...categoryPopup,
                                            categoryObj: {
                                                ...categoryPopup.categoryObj,
                                                // Fees: e.target.value.replace("₹", '')
                                                Fees: e.target.value
                                            }
                                        })

                                    }}
                                />
                            </div>
                            <div className="born-before-div">
                                <h3>Active : </h3>
                                <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: '10px' }}>
                                    <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                                        <span className={categoryPopup && categoryPopup.categoryObj && categoryPopup.categoryObj.IsActive === true ? 'active' : ''}
                                            id="RegistrationOn" style={{ color: '#59981A' }}>Yes</span>

                                        <div className={categoryPopup && categoryPopup.categoryObj && categoryPopup.categoryObj.IsActive === true ? 'switch-button on' : 'switch-button off'} id="RegistrationToggleBtn"

                                            onClick={(e) => {
                                                setCategoryPopup({
                                                    ...categoryPopup,
                                                    categoryObj: {
                                                        ...categoryPopup.categoryObj,
                                                        IsActive: categoryPopup.categoryObj && categoryPopup.categoryObj.IsActive === true ? false : true
                                                    }
                                                })
                                            }}>

                                            <div className="switch-button-inner"></div>
                                        </div>

                                        <span className={categoryPopup && categoryPopup.categoryObj && categoryPopup.categoryObj.IsActive === false ? '' : 'active'} id="RegistrationOff"
                                            style={{ color: '#EB542E' }}>No</span>
                                    </div>
                                </div>
                            </div>

                            <div className="category-card-edit"
                            >

                                <span id="errorMessage" className="errorMessage"
                                >
                                    {categoryMessage}
                                </span>
                            </div>
                        </div>
                    </div>


                    <hr style={{ marginBottom: '0' }} />

                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={() => {
                            addCategoryDetails(categoryPopup && categoryPopup.categoryObj)
                            setCategoryPopup({
                                showPopup: false,
                                action: '',
                                categoryObj: null
                            })
                        }}>
                            <span>Save</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={() => {
                            setCategoryPopup({
                                showPopup: false,
                                action: '',
                                categoryObj: null
                            })
                        }}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div >
            </div>
            <div className="heading" style={{ display: 'flex', alignItems: 'center' }}>
                <span className="material-symbols-outlined">
                    category
                </span>
                <h4 style={{ fontWeight: '1000' }}>Categories ( {categoryList && isEditEnabled ? categoryList.length :
                    categoryList && !isEditEnabled ? categoryList.filter(e => e.IsActive === true).length : '0'} )</h4>
                {isEditEnabled && <div style={{
                    position: 'relative',
                    left: '20px',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                    background: '#348DCB',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }} onClick={() => {

                    setCategoryPopup({
                        showPopup: true,
                        action: 'Add',
                        categoryObj: {
                            id: '',
                            CategoryName: '',
                            Gender: 'MALE',
                            EventType: 'SINGLE',
                            DateRefType: 'Before',
                            MaxTeamSize: 1,
                            Fees: 0,
                            ReferenceDate: new Date()

                        }
                    })
                }}>
                    <span style={{ fontSize: '1.4rem', color: '#fff', position: 'absolute', left: '50%', transform: 'translateX(-35%)' }} className="material-symbols-outlined">
                        add
                    </span>
                </div>}
            </div><br />

            <div className='container-fluid'>
                <div className='row no-gutters'>
                    {categoryList && categoryList.map((category) => (
                        // (isEditEnabled || (!isEditEnabled && category.IsActive)) &&
                        (isEditEnabled || (!isEditEnabled && category.IsActive)) &&
                        (
                            <div className={props.categoryInRow === 2 ? 'col-lg-6 col-md-6 col-sm-12' : 'col-lg-4 col-md-6 col-sm-12'} key={category.id}>
                                <div className={category && category.IsActive === true ? 'event-details-category-card active' : 'event-details-category-card inactive'} >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className='event-details-category-card-icon'>
                                            {category.Gender.toLowerCase() === 'male' && category.EventType.toLowerCase() === 'single' ?

                                                <div className='boys-singles'>
                                                    <span className="material-symbols-outlined">
                                                        man
                                                    </span>
                                                </div>
                                                : category.Gender.toLowerCase() === 'male' && category.EventType.toLowerCase() === 'double' ?
                                                    <div className='boys-doubles'>
                                                        <span className="material-symbols-outlined">
                                                            man
                                                        </span>
                                                        <span className="material-symbols-outlined">
                                                            man
                                                        </span>
                                                    </div>
                                                    : category.Gender.toLowerCase() === 'female' && category.EventType.toLowerCase() === 'single' ?
                                                        <div className='girls-singles'>
                                                            <span className="material-symbols-outlined">
                                                                woman
                                                            </span>
                                                        </div>
                                                        : category.Gender.toLowerCase() === 'female' && category.EventType.toLowerCase() === 'double' ?
                                                            <div className='girls-doubles'>
                                                                <span className="material-symbols-outlined">
                                                                    woman
                                                                </span>
                                                                <span className="material-symbols-outlined">
                                                                    woman
                                                                </span>
                                                            </div>
                                                            : category.Gender.toLowerCase() === 'mixed' && category.EventType.toLowerCase() === 'double' ?

                                                                <div className='mixed-doubles'>
                                                                    <span className="material-symbols-outlined">
                                                                        man
                                                                    </span>
                                                                    <span className="material-symbols-outlined">
                                                                        woman
                                                                    </span>
                                                                </div>
                                                                : category.Gender.toLowerCase() === 'male' && category.EventType.toLowerCase() === 'team' ?
                                                                    <div className='boys-team'>
                                                                        <span className="material-symbols-outlined">
                                                                            man
                                                                        </span>
                                                                        <span className="material-symbols-outlined">
                                                                            man
                                                                        </span>
                                                                        <span className="material-symbols-outlined">
                                                                            man
                                                                        </span>
                                                                        <span className="material-symbols-outlined">
                                                                            man
                                                                        </span>
                                                                    </div>
                                                                    : category.Gender.toLowerCase() === 'female' && category.EventType.toLowerCase() === 'team' ?

                                                                        <div className='girls-team'>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                        </div>
                                                                        : category.Gender.toLowerCase() === 'mixed' && category.EventType.toLowerCase() === 'team' &&
                                                                        <div className='mixed-team'>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                        </div>}
                                        </div>
                                        <div className='event-details-category-card-details'>
                                            <h1>{category.CategoryName}</h1>
                                            <hr />
                                            <div className='type'>
                                                <h2 style={{
                                                    color: category.Gender.toLowerCase() === 'male' ?
                                                        'rgb(33, 122, 255)' :
                                                        category.Gender.toLowerCase() === 'female' ?
                                                            'rgb(255, 33, 103)' : '#aaa'
                                                }}>
                                                    {category.Gender.toLowerCase() === 'male' ? 'MALE'
                                                        : category.Gender.toLowerCase() === 'female' ? 'FEMALE' : 'MIXED'}</h2>
                                                <h3>{category.EventType.toLowerCase() === 'single' ? 'SINGELS'
                                                    : category.EventType.toLowerCase() === 'double' ? 'DOUBLES' : 'TEAM'}</h3>
                                            </div>
                                            <div className='type' style={{ justifyContent: 'space-between', paddingRight: '5px' }}>

                                                <h4>₹{category.Fees}</h4>
                                                {/* <button className='mybutton button5'
                                                    onClick={(e) => { navigate("/RegisteredProfile/" + props.eventDetails.id) }}
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        height: '25px',
                                                        borderRadius: '8px',
                                                        paddingTop: '3px',
                                                        margin: '0'
                                                    }}>Register</button> */}
                                            </div>
                                        </div>
                                        {isEditEnabled && <div className='event-details-category-card-edit-delete'>
                                            <div onClick={() => {
                                                setCategoryPopup({
                                                    showPopup: true,
                                                    action: 'Edit',
                                                    categoryObj: category
                                                });
                                            }}>
                                                <span className="material-symbols-outlined">
                                                    edit
                                                </span>
                                            </div>
                                            {/* <div onClick={(e) => {
                                                setConfirmDelete({
                                                    isDelete: true,
                                                    deleteObjectType: 'Category',
                                                    deleteMessage: 'Are you sure to delete category ' + category.CategoryName,
                                                    deleteID: category.id
                                                })
                                            }} >
                                                <span style={{ color: 'red' }} className="material-symbols-outlined">
                                                    delete
                                                </span>
                                            </div> */}
                                            {/* <div className="switch category-switch">
                                                <span className={category && category.IsActive === true ? 'active' : ''}
                                                    id="RegistrationOn" style={{ color: '#59981A', }}>Yes</span>

                                                <div className={category && category.IsActive === true ? 'switch-button on' : 'switch-button off'} id="RegistrationToggleBtn"

                                                    onClick={(e) => {
                                                        changeCategoryActive(category.id, category.IsActive === true ? false : true)
                                                        // updateCategory()
                                                        // setCategoryPopup({
                                                        //     ...categoryPopup,
                                                        //     categoryObj: {
                                                        //         ...categoryPopup.categoryObj,
                                                        //         IsActive: categoryPopup.categoryObj && categoryPopup.categoryObj.IsActive === true ? false : true
                                                        //     }
                                                        // })
                                                    }}>

                                                    <div className="switch-button-inner" ></div>
                                                </div>

                                                <span className={category && category.IsActive === false ? '' : 'active'} id="RegistrationOff"
                                                    style={{ color: '#EB542E' }}>No</span>
                                            </div> */}

                                            <div style={{ fontSize: '0.8rem', color: category && category.IsActive === true ? 'green' : 'red' }}>{category && category.IsActive === true ? 'Active' : 'Inactive'}</div>

                                        </div>}

                                    </div>
                                </div>
                            </div>


                        )

                    ))}

                </div>
            </div >
        </>
    )
}
