import React from 'react'

export default function CategoryCartItem(props) {

    return (
        <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0' }}>
            <div style={{ padding: '10px' }}>
                {/* {console.log(props)} */}

                <div style={{ padding: '0px' }} className={props && props.eventDetails && props.eventDetails.PaymentStatus.toUpperCase() === 'PENDING' ?
                    "reg-category-card active payment-pending" : "reg-category-card active payment-completed"}>

                    {props && props.eventDetails && props.eventDetails.Gender.toUpperCase() === 'FEMALE' && props.eventDetails.EventType.toUpperCase() === 'SINGLE' ?
                        <div className='event-details-category-card' style={{ margin: '0' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='event-details-category-card-icon'>
                                    <div className='girls-singles'>
                                        <span className="material-symbols-outlined female">
                                            woman
                                        </span>
                                    </div>
                                </div>
                                <div className='event-details-category-card-details'>
                                    <h1 style={{ fontSize: '1.2rem' }}>{props && props.eventDetails && props.eventDetails.CategoryName}</h1>
                                    <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                        <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                            <span>{props && props.eventDetails && props.eventDetails.Fees}</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        props && props.eventDetails.Gender.toUpperCase() === 'FEMALE' && props.eventDetails.EventType.toUpperCase() === 'DOUBLE' ?
                            <div className='event-details-category-card' style={{ margin: '0' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className='event-details-category-card-icon'>
                                        <div className='girls-doubles'>
                                            <span className="material-symbols-outlined female">
                                                woman
                                            </span>
                                            <span className="material-symbols-outlined female">
                                                woman
                                            </span>
                                        </div>
                                    </div>
                                    <div className='event-details-category-card-details'>
                                        <h1 style={{ fontSize: '1.2rem' }}>{props && props.eventDetails && props.eventDetails.CategoryName}</h1>
                                        <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                            <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                <span>{props && props.eventDetails && props.eventDetails.Fees}</span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            props && props.eventDetails.Gender.toUpperCase() === 'MIXED' && props.eventDetails.EventType.toUpperCase() === 'DOUBLE' ?
                                <div className='event-details-category-card' style={{ margin: '0' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className='event-details-category-card-icon'>
                                            <div className='mixed-doubles'>
                                                <span className="material-symbols-outlined female">
                                                    woman
                                                </span>
                                                <span className="material-symbols-outlined male">
                                                    man
                                                </span>
                                            </div>
                                        </div>
                                        <div className='event-details-category-card-details'>
                                            <h1 style={{ fontSize: '1.2rem' }}>{props && props.eventDetails && props.eventDetails.CategoryName}</h1>
                                            <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                    <span>{props && props.eventDetails && props.eventDetails.Fees}</span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                props && props.eventDetails.Gender.toUpperCase() === 'MALE' && props.eventDetails.EventType.toUpperCase() === 'SINGLE' ?
                                    <div className='event-details-category-card' style={{ margin: '0' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='event-details-category-card-icon'>
                                                <div className='boys-singles'>
                                                    <span className="material-symbols-outlined male">
                                                        man
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='event-details-category-card-details'>
                                                <h1 style={{ fontSize: '1.2rem' }}>{props && props.eventDetails && props.eventDetails.CategoryName}</h1>
                                                <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                    <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                        <span>{props && props.eventDetails && props.eventDetails.Fees}</span>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    props && props.eventDetails.Gender.toUpperCase() === 'MALE' && props.eventDetails.EventType.toUpperCase() === 'DOUBLE' ?
                                        <div className='event-details-category-card' style={{ margin: '0' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='event-details-category-card-icon'>
                                                    <div className='boys-doubles'>
                                                        <span className="material-symbols-outlined male">
                                                            man
                                                        </span>
                                                        <span className="material-symbols-outlined male">
                                                            man
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='event-details-category-card-details'>
                                                    <h1 style={{ fontSize: '1.2rem' }}>{props && props.eventDetails && props.eventDetails.CategoryName}</h1>
                                                    <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                        <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                            <span>{props && props.eventDetails && props.eventDetails.Fees}</span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        props && props.eventDetails.Gender.toUpperCase() === 'FEMALE' && props.eventDetails.EventType.toUpperCase() === 'TEAM' ?
                                            <div className='event-details-category-card' style={{ margin: '0' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className='event-details-category-card-icon'>
                                                        <div className='girls-team'>
                                                            <span className="material-symbols-outlined female">
                                                                woman
                                                            </span>
                                                            <span className="material-symbols-outlined female">
                                                                woman
                                                            </span>
                                                            <span className="material-symbols-outlined female">
                                                                woman
                                                            </span>
                                                            <span className="material-symbols-outlined female">
                                                                woman
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='event-details-category-card-details'>
                                                        <h1 style={{ fontSize: '1.2rem' }}>{props && props.eventDetails && props.eventDetails.CategoryName}</h1>
                                                        <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                            <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                                <span>{props && props.eventDetails && props.eventDetails.Fees}</span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            props && props.eventDetails.Gender.toUpperCase() === 'MALE' && props.eventDetails.EventType.toUpperCase() === 'TEAM' ?
                                                <div className='event-details-category-card' style={{ margin: '0' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className='event-details-category-card-icon'>
                                                            <div className='boys-team'>
                                                                <span className="material-symbols-outlined male">
                                                                    man
                                                                </span>
                                                                <span className="material-symbols-outlined male">
                                                                    man
                                                                </span>
                                                                <span className="material-symbols-outlined male">
                                                                    man
                                                                </span>
                                                                <span className="material-symbols-outlined male">
                                                                    man
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='event-details-category-card-details'>
                                                            <h1 style={{ fontSize: '1.2rem' }}>{props && props.eventDetails && props.eventDetails.CategoryName}</h1>
                                                            <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                                <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                                    <span>{props && props.eventDetails && props.eventDetails.Fees}</span>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='event-details-category-card' style={{ margin: '0' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className='event-details-category-card-icon'>
                                                            <div className='mixed-team'>
                                                                <span className="material-symbols-outlined female">
                                                                    woman
                                                                </span>
                                                                <span className="material-symbols-outlined female">
                                                                    woman
                                                                </span>
                                                                <span className="material-symbols-outlined male">
                                                                    man
                                                                </span>
                                                                <span className="material-symbols-outlined male">
                                                                    man
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='event-details-category-card-details'>
                                                            <h1 style={{ fontSize: '1.2rem' }}>{props && props.eventDetails && props.eventDetails.CategoryName}</h1>
                                                            <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                                <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                                    <span>{props && props.eventDetails && props.eventDetails.Fees}</span>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                    }
                    {props && props.eventDetails && props.eventDetails.PaymentStatus.toUpperCase() === 'PENDING' &&
                        <div className="payment-status pending">
                            <h1>Payment Pending</h1>
                        </div>
                    }
                    {props && props.eventDetails && props.eventDetails.PaymentStatus.toUpperCase() === 'COMPLETED' &&
                        <div className="payment-status completed">
                            <h1>Payment Completed</h1>
                        </div>
                    }
                </div>
            </div>
        </div >

    )
}
