import React, { useState, useMemo, useEffect } from 'react'
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import '../css/Events.css';
import Loading from './Loading.js';
import { useLocation } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";
import NewEventCard from './NewEventCard.js';


export default function EventList() {
    const { user } = useUserAuth();
    // const [userDetails] = useLocalStorage('userProfile', null);

    const [minValue, setMinValue] = useState(2018);

    const [maxValue, setMaxValue] = useState(new Date().getFullYear());
    const [sliderValue, setSliderValue] = useState(new Date().getFullYear());

    const [city, setCity] = useState(window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
        'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', ''));
    const [loading, setLoading] = useState(true);
    const [eventList, setEventList] = useState([]);
    const [eventListFilter, setEventListFilter] = useState([]);
    const [sliderLeftPer, setSliderLeftPer] = useState({ left: '100%' });
    const [searchKey, setSearchKey] = useState('');
    const [filterEventStatus, setFilterEventStatus] = useState('Active');
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    var options = {
        year: '2-digit',
        // year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    // let eventCntList = [];
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    async function getEventList() {
        // const getEventList = async () => {
        console.log('in getEventList');
        //getAllEventDetailsForOrganizer
        // getAllEventDetails
        var functionName = '';
        if (user && user.userInfo && user.userInfo.SelectedRole.toUpperCase() === 'ADMIN') {
            functionName = 'getAllEventDetails';
        } else {
            functionName = 'getAllEventDetailsForOrganizer';
        }
        // functionName = 'getAllEventDetailsForOrganizer';
        // console.log('functionname', functionName)
        const eventSummary = httpsCallable(functions, functionName);
        // OrganizationName
        setLoading(true);
        let para = {

            organizerID: user.userInfo.uid
        };
        // console.log(para, ': functionName :: ', functionName)

        return await eventSummary(para)
            .then(async (result) => {
                let data = result.data;
                // console.log('result.data', result.data)
                var refdate = "";
                var today = new Date();
                var newData = [];
                var eventSDate = '';
                var eventEDate = '';
                // console.log(data)
                data.forEach(element => {
                    eventEDate = '';
                    eventSDate = '';
                    if (element.EventStartDate) {
                        refdate = new Date(element.EventStartDate._seconds * 1000);
                        eventSDate = refdate.toLocaleDateString("en-IN", options);
                    }
                    else {
                        eventSDate = "";
                    }
                    if (element.EventEndDate) {
                        var eDate = new Date(element.EventEndDate._seconds * 1000 + 60 * 60 * 24 * 1000);
                        eventEDate = eDate.toLocaleDateString("en-IN", options);
                    }
                    else {
                        eventEDate = '';
                    }
                    if (refdate <= today && eDate >= today && element.EventStatus.toUpperCase() === 'ACTIVE') {
                        element.isLive = true;
                    } else {
                        element.isLive = false;
                    }

                    newData.push({
                        ...element,
                        EventSDate: eventSDate,
                        EventEDate: eventSDate,
                        Fees: element.MinimumFee ? (Number(element.MinimumFee).toLocaleString('en-IN', curFormat)) : "",
                        flag: true,
                        searchKey: element.EventCode + element.EventName + element.EventOwnerName + element.OrganizationName + element.City
                    })
                });
                setLoading(false);
                setEventListFilter(newData);
                return newData;
            });

    }
    const mEvents = useMemo(async () => {
        return await getEventList().then(result => {
            setEventList(result);

            return result;
        })

        // const response = await getEventList().then(rec => {
        //     return rec
        // });
        // return response;

    }, [city]);


    function searchEvent() {
        // console.log('in searchEvent : ', searchKey);
        if (searchKey !== '') {
            var newArray = eventList.filter(function (el) {
                return el.searchKey.toUpperCase().includes(searchKey.toUpperCase());
            }
            );
            // console.log(newArray);
            setEventListFilter(newArray);
            setFilterEventStatus('');
        }

    }


    function searchEventStatus(status) {
        // console.log('in eventList : ', eventList);

        var newArray = eventList.filter(e => e.EventStatus === status);
        // console.log(newArray);
        setEventListFilter(newArray);
        setFilterEventStatus(status);
    }
    let eDate = '';
    let index = 0;
    // let cnt = 0;
    return (
        <div>

            <section id="event-new">
                <div className="container"><br /><br />

                    <div className='row no-gutters'>

                        <div className='col-lg-6 col-md-6 col-sm-12'>

                            <div className="" style={{ display: 'flex', justifyContent: 'center', padding: '4px 0' }}>
                                <div className="year-range">
                                    <div className="slider-value">
                                        <span id="sliderValue" className="show" style={sliderLeftPer}>{sliderValue}</span>
                                    </div>
                                    <div className="draging-field">
                                        <div className="value left">
                                            {minValue}
                                        </div>
                                        <input id="inputSlider" type="range" min={minValue} max={maxValue} value={sliderValue}
                                            onChange={(e) => {

                                                let difference = maxValue - minValue;
                                                // console.log((e.target.value - minValue) * 100 / difference + '%');
                                                setSliderLeftPer({ left: (e.target.value - minValue) * 100 / difference + '%' });

                                                // sliderLeftPer.current = { left: (e.target.value - minValue) * 100 / difference + '%' };

                                                setSliderValue(e.target.value);
                                            }}
                                            steps="1" />
                                        <div className="value right" id='rightValue'>
                                            {maxValue}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <br className='small'></br>
                            <br className='small'></br>
                            <div className='event-search-div'>
                                <div>
                                    <input type="text" id="userName" placeholder='e.g. event name or location etc' required
                                        onChange={(e) => {
                                            setSearchKey(e.target.value)
                                            searchEvent()

                                        }} value={searchKey} />
                                    <button className="mybutton button5" onClick={() => {
                                        searchEvent();

                                    }}>
                                        <span className="material-symbols-outlined">
                                            search
                                        </span>
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                    <br></br>
                    <div style={{ background: '#fff', borderRadius: '8px', padding: '10px' }}>
                        <div className='row no-gutters'>
                            <div className="col-12 event-label-div">

                                Total {searchKey === '' && <strong>{filterEventStatus} </strong>}
                                Events for year <strong>{Number(sliderValue)} </strong> given search : <strong>{eventListFilter.filter(e => new Date(e.EventStartDate._seconds * 1000 + 60 * 60 * 24 * 1000).getFullYear() === Number(sliderValue)).length}</strong>
                            </div>
                        </div>


                        <div className='row no-gutters'>

                            <div className="col-4 event-label-div">

                                <input type="radio" checked={filterEventStatus === 'Active' ? true : false} className="checkbox" style={{ width: '0px' }}
                                    name="EventStatus"
                                    id="Active" value="Active"
                                    onChange={(e) => {
                                        console.log('in radio checked click')
                                    }}
                                />
                                <label onClick={() => {
                                    // setFilterEventStatus('Active')
                                    searchEventStatus('Active');
                                }}

                                    className="checkbox-label"
                                    id="Active-label" htmlFor="Active">
                                    <i className="fas fa-plus"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <span>Active</span>
                                </label>

                            </div>

                            <div className="col-4 event-label-div">

                                <input type="radio" className="checkbox" checked={filterEventStatus === 'Inactive' ? true : false} style={{ width: '0px' }} name="EventStatus"
                                    id="Inactive" value="Inactive"
                                    onChange={(e) => {
                                        console.log('in radio checked click')

                                    }} />
                                <label onClick={() => {
                                    // setFilterEventStatus('Inactive');
                                    searchEventStatus('Inactive');
                                }} className="checkbox-label"
                                    id="Inactive-label" htmlFor="Inactive">
                                    <i className="fas fa-plus"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <span>Inactive</span>
                                </label>

                            </div>


                            <div className="col-4 event-label-div">

                                <input type="radio" className="checkbox" checked={filterEventStatus === 'Closed' ? true : false} style={{ width: '0px' }} name="EventStatus"
                                    id="Closed" value="Closed" onChange={(e) => {
                                        console.log('in radio checked click')

                                    }} />
                                <label onClick={() => {
                                    // setFilterEventStatus('Closed');
                                    searchEventStatus('Closed');
                                }}
                                    className="checkbox-label"
                                    id="Closed-label" htmlFor="Closed">
                                    <i className="fas fa-plus"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <span>Closed</span>
                                </label>

                            </div>

                            <div className="col-6 event-label-div">

                                <input type="radio" className="checkbox" checked={filterEventStatus === 'On Hold' ? true : false} style={{ width: '0px' }} name="EventStatus"
                                    id="Onhold" value="Onhold" onChange={(e) => {
                                        console.log('in radio checked click')

                                    }} />
                                <label onClick={() => {
                                    // setFilterEventStatus('On Hold');
                                    searchEventStatus('On Hold');
                                }} className="checkbox-label"
                                    id="Onhold-label" htmlFor="Onhold">
                                    <i className="fas fa-plus"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <span>Onhold</span>
                                </label>

                            </div>

                            <div className="col-6 event-label-div">

                                <input type="radio" className="checkbox" checked={filterEventStatus === 'Cancelled' ? true : false} style={{ width: '0px' }} name="EventStatus"
                                    id="Cancelled" value="Cancelled" onChange={(e) => {
                                        console.log('in radio checked click')

                                    }} />
                                <label onClick={() => {
                                    // setFilterEventStatus('Cancelled');
                                    searchEventStatus('Cancelled');
                                }} className="checkbox-label"
                                    id="Cancelled-label" htmlFor="Cancelled">
                                    <i className="fas fa-plus"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                                    <span>Cancelled</span>
                                </label>

                            </div>

                        </div>
                    </div>
                    <br></br>
                    <div className="row no-gutters">
                        {/* {loading && <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json" style={{ height: '400px' }} background="transparent" speed="1" loop autoplay></lottie-player>} */}
                        {loading && <Loading height='400px' weight='100%' ></Loading>}

                        {/* {

                            eventListFilter.map((event) => {

                                var eDate = new Date(event.EventStartDate._seconds * 1000 + 60 * 60 * 24 * 1000);
                                // console.log(event.EventName, "::", eDate.getFullYear(), "::slider value :: ", sliderValue);
                                if (eDate.getFullYear() === Number(sliderValue)) {
                                    // cnt = cnt + 1;
                                    return <EventCard key={event.Eventid} keyIndex={index++} event={event} action={'EDIT'}>{event.EventName}</EventCard>
                                    // return <NewEventCard key={event.Eventid} keyIndex={index++} event={event} action={'EDIT'}>{event.EventName}</NewEventCard>

                                }
                                else {
                                    return null
                                }
                            })


                        } */}

                        {

                            eventListFilter.map((event) => {

                                var eDate = new Date(event.EventStartDate._seconds * 1000 + 60 * 60 * 24 * 1000);
                                // console.log(event.EventName, "::", eDate.getFullYear(), "::slider value :: ", sliderValue);
                                if (eDate.getFullYear() === Number(sliderValue)) {
                                    // cnt = cnt + 1;
                                    // return <EventCard key={event.Eventid} keyIndex={index++} event={event} action={'EDIT'}>{event.EventName}</EventCard>
                                    return <NewEventCard key={event.Eventid} keyIndex={index++} event={event} action={'EDIT'}>{event.EventName}</NewEventCard>

                                }
                                else {
                                    return null
                                }
                            })


                        }
                        {/* {(eventListFilter.length <= 0) ? "No event found for given search key, Please enter tournament name, Orgaizer Name or City to search  " : ""} */}

                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="item" style={{ margin: '30px 0 100px 0' }}>
                                <a href="#event-new" className="event-card">
                                    <div className="event-card-img">
                                        <img src="img/eventnew2.png" alt="" />
                                    </div>

                                    <div className="event-card-content">
                                        <h1 className="event-name">Prakash Padukone Badminton Tournament 2022 Badminton Tournament</h1>
                                        <h2 className="event-organiser">Prakash Padukone Badminton Academy</h2>
                                        <h3 className="rating">
                                            <div className="">
                                                <span className="material-symbols-outlined">star</span>
                                                <span className="material-symbols-outlined">star</span>
                                                <span className="material-symbols-outlined">star</span>
                                                <span className="material-symbols-outlined">star_half</span>
                                                <span className="material-symbols-outlined grade">grade</span>
                                            </div>
                                            <small>1,724</small>
                                        </h3>

                                        <div className="event-schedule-div">
                                            <div className="details">
                                                <div className="">
                                                    <h3>Chennai</h3>
                                                    <h4>Location</h4>
                                                </div>
                                                <div className="">
                                                    <h3>12 Jun 2022</h3>
                                                    <h4>Starting Date</h4>
                                                </div>
                                                <div className="">
                                                    <h3>₹ 600</h3>
                                                    <h4>Entry Fee</h4>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <button type="button" className="mybutton button5 event-card-button" name="button">Details</button>
                                                </div>
                                                <div className="col-7">
                                                    <button type="button" className="mybutton button5 event-card-button entries"
                                                        style={{ background: 'none', border: '2px solid #ddd', color: '#aaa' }} name="button">
                                                        <img src="./img/multipleuser.png" alt="" />
                                                        132
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </a>
                            </div>
                        </div> */}

                    </div>

                </div>

                {(index <= 0) ? <>
                    <br /><br />
                    <div className='event-page-error-message'>
                        <h1>No events found for given search key/Year, Please change year or enter tournament name, Orgaizer Name or City to search</h1>
                    </div>
                    <br />
                </> : ""}

                <br />
            </section>

            {/* <div className="col-lg-12 col-md-8 col-sm-12" style={{ padding: '0' }}>

                <div className="reg-participant-form-field profile-name-input" >
                    <input type="text" id="userName" placeholder='Search Key' required onChange={(e) => {
                        setSearchKey(e.target.value)

                    }} value={searchKey} />
                    <button className="mybutton button5" onClick={() => {
                        searchEvent();

                    }}>Search Event
                    </button>
                </div>
            </div>
 */}

        </div>
    )
}
