import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
// import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
import Loading from './Loading.js';
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import { useRazorpay } from '../hooks/useRozarPay';
import { useNavigate } from 'react-router-dom';
import '../css/Ticket.css';
import EntryTicket from './EntryTicket';
// import QRCodeComponent from './QRCodeComponent';
export default function ManageParticipant(props) {
    const { user, logout } = useUserAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [objPlayerParticipant, setObjPlayerParticipant] = useState(null);
    const [withdraw, setWithdraw] = useState(false);
    const [payment, setPayment] = useState(false);
    const { makePayment, paymentResponse, handleRefund } = useRazorpay();
    const [searchKey, setSearchKey] = useState('');
    const [searchedEntry, setSearchedEntry] = useState([]);
    const [upcomingEvent, setUpcomingEvent] = useState([]);
    const [compleredEvent, setCompleredEvent] = useState([]);
    const [cancelledEvent, setCancelledEvent] = useState([]);
    const [onholdEvent, setOnhold] = useState([]);
    // const [pendingCount, setPendingCount] = useState(0);

    const [entryActive, setEntryActive] = useState([]);
    const [entryCompleted, setEntryCompleted] = useState([]);
    const [entryCancelled, setEntryCancelled] = useState([]);
    const [entryOnhold, setEntryOnhold] = useState([]);

    const [selectTab, setSelectTab] = useState('Active');

    useEffect(() => {
        // console.log(eventDetails);
        var para1 = {};
        async function fetchData() {
            setLoading(true);
            para1 = {
                UserID: user.userInfo.id,
            };
            console.log(para1);
            const ret1 = httpsCallable(functions, "getAllRegisteredEventListByUserID");
            ret1(para1).then(async (result) => {
                console.log('result : ', result)
                let entryDet = [];
                let eventDet = [];
                let eventActive = [];
                let eventCompleted = [];
                let eventCancelled = [];
                let eventOnhold = [];

                let entryActive = [];
                let entryCompleted = [];
                let entryCancelled = [];
                let entryOnhold = [];

                let eventIDActive = [];
                let eventIDCompleted = [];
                let eventIDCancelled = [];
                let eventIDOnhold = [];

                result.data.eventDetails.forEach(element => {
                    if (element.EventStatus.toUpperCase() === 'OPEN' || element.EventStatus.toUpperCase() === 'UPCOMING' || element.EventStatus.toUpperCase() === 'ACTIVE') {
                        eventDet.push(element)
                        eventActive.push(element)
                        eventIDActive.push(element.EventID);
                    } else if (element.EventStatus.toLowerCase() === 'cancelled') {
                        eventCancelled.push(element)
                        eventIDCancelled.push(element.EventID);
                    } else if (element.EventStatus.toLowerCase() === 'closed' || element.EventStatus.toLowerCase() === 'completed') {
                        eventCompleted.push(element)
                        eventIDCompleted.push(element.EventID);
                    } else if (element.EventStatus.toLowerCase() === 'onhold') {
                        eventOnhold.push(element)
                        eventIDOnhold.push(element.EventID);
                    }
                });

                setEntryActive(result.data.entryDetails.filter(e => eventIDActive.includes(e.EventID)));

                setEntryCancelled(result.data.entryDetails.filter(e => eventIDCancelled.includes(e.EventID)));
                setEntryCompleted(result.data.entryDetails.filter(e => eventIDCompleted.includes(e.EventID)));
                setEntryOnhold(result.data.entryDetails.filter(e => eventIDOnhold.includes(e.EventID)));

                result.data.entryDetails.forEach(element => {
                    if (eventDet.filter(e => e.EventID === element.EventID)) {
                        entryDet.push({
                            ...element,
                            searchKey: element.CategoryName + element.ParticipantName + (element.PartnerPlayerName ? element.PartnerPlayerName : '') + element.PaymentStatus + element.EventType
                        })
                    }
                });

                // console.log('entryDet 1', entryDet)
                setObjPlayerParticipant({
                    eventDetails: result.data.eventDetails,
                    entryDetails: entryDet
                });
                console.log('objPlayerParticipant', objPlayerParticipant);
                // console.log('setObjPlayerParticipant : ', objPlayerParticipant);
                setSearchedEntry(entryDet);
                // let para2 = {
                //     emails: 'atulmani@gmail.com;anitatripathi@gmail.com',
                //     subject: 'test email',
                //     body: 'test email from TPLive',
                // };
                // console.log(para2);
                // const ret2 = httpsCallable(functions, "sendMailApi");
                // ret2(para2).then(async (result) => {

                // });
                setLoading(false);
            });

        }
        user && user.userInfo && user.userInfo.uid !== '0' && fetchData();


    }, []);

    const handleRefundEvent = async (obj) => {
        //uppdate the registration status as withdrawn

        WithdrawEntry(obj);

        if (obj.PaymentStatus.toUpperCase() === 'COMPLETED') {
            handleRefund(obj, 'Entry Withdraw ')
        }
    };


    async function ConfirmPayment(obj, amount, transactionID, orderID) {
        var catDel = [];
        catDel.push(obj.CategoryName);
        var para1 = {};
        para1 = {
            EventID: obj.EventID,
            PlayerID: obj.ParticipantID,
            CategoryList: catDel,
            paymentStatus: 'Completed',
            paymentAmount: amount,
            transactionID: transactionID,
            orderID: orderID,
        };

        const ret1 = await httpsCallable(functions, "updatePaymentStatus");
        ret1(para1).then(async (result) => {
            // props.refreshParent();
        })

    }
    async function WithdrawEntry(participantDetails) {
        var catDel = [];
        catDel.push(participantDetails.CategoryName);
        var para1 = {};
        para1 = {
            EventID: participantDetails.EventID,
            PlayerID: participantDetails.ParticipantID,
            DeleteCategoryList: [participantDetails.CategoryName],
        };

        const ret1 = await httpsCallable(functions, "withdrawRegistration");
        ret1(para1).then(async (result) => {

        })

    }
    function searchParticipation() {
        if (searchKey !== '') {
            var newArray = objPlayerParticipant.entryDetails.filter(function (el) {
                return el.searchKey.toUpperCase().includes(searchKey.toUpperCase());
            }
            );
            // console.log(newArray);
            setSearchedEntry(newArray);
            // setFilterEventStatus('');
        }
    }
    function resetParam() {
        navigate(-1);
    }
    let eventDet = null;
    // console.log('props', props)
    let pendingCount = 0;
    return (
        <div className='container-fluid'>
            <div>
                <h2>{props.registrationType === 'Pending' ? 'Pending Payments' : 'Participated Events'}</h2>
            </div>
            {/* <div className='fixed-settings-breadcrum'>
                <div>
                    <span style={{ fontSize: '1.8rem' }} className="material-symbols-outlined">
                        person
                    </span>
                </div>
                <span style={{ position: 'relative', top: '-2px' }} onClick={resetParam} >  Profile </span>
                {<span style={{ position: 'relative', top: '-2px' }}> &lt; </span>}
                <span className='active' style={{ position: 'relative', top: '-2px' }}> Manage Participant </span>
            </div> */}

            <div className="row no-gutters">

                {loading && <Loading></Loading>}
                {props.registrationType !== 'Pending' && (!objPlayerParticipant || searchedEntry.length === 0) &&
                    // {true &&

                    <div style={{ alignContent: "center" }}>
                        <strong >You have no registration !!</strong>

                    </div>

                }
                {props.registrationType === 'Pending' && (!objPlayerParticipant || searchedEntry.find(e => e.PaymentStatus.toLowerCase() === 'pending') === 0) &&
                    // {true &&

                    <div style={{ alignContent: "center" }}>
                        <strong >You have no pending registration !!</strong>

                    </div>

                }
                {props.registrationType === 'Pending' && (objPlayerParticipant || searchedEntry.find(e => e.PaymentStatus.toLowerCase() === 'pending') > 0) &&
                    // {true &&

                    <div style={{ alignContent: "center" }}>
                        <strong >You have {pendingCount} pending registration !!</strong>

                    </div>

                }
                {props.registrationType !== 'Pending' && objPlayerParticipant && objPlayerParticipant.entryDetails && objPlayerParticipant.entryDetails.length > 5 &&
                    <>
                        <br className='small'></br>
                        <div className='event-search-div'>
                            <div>
                                <input style={{ height: '45px', borderRadius: '8px 0 0 8px' }} type="text" id="userName" placeholder='e.g. event name or player name or category name etc' required
                                    onChange={(e) => {
                                        setSearchKey(e.target.value)
                                        searchParticipation()

                                    }} value={searchKey} />
                                <button className="mybutton button5" style={{ height: '45px', borderRadius: '0 8px 8px 0' }} onClick={() => {
                                    searchParticipation();

                                }}>
                                    <span style={{ fontSize: '1.6rem' }} className="material-symbols-outlined">
                                        search
                                    </span>
                                </button>
                            </div>
                        </div>
                    </>
                }

                {props.registrationType !== 'Pending' && <div>
                    <br />
                    <div className="event-details-menu-outter sticky-top">
                        <div className="event-details-menu">
                            <a className={(selectTab === 'Active') ? 'active' : ''} onClick={(e) => {
                                setSelectTab('Active')
                                console.log('selectTab', selectTab)
                            }}>
                                {/* <span className="material-symbols-outlined">
                                    info
                                </span> */}
                                <h1>Upcoming</h1>
                                <h2 className={(selectTab === 'Active') ? 'active' : ''}>{entryActive.length}</h2>

                            </a>
                            <a className={(selectTab === 'Completed') ? 'active' : ''} onClick={(e) => {
                                setSelectTab('Completed')
                                console.log('selectTab', selectTab)
                            }}>
                                {/* <span className="material-symbols-outlined">
                                    directions_run
                                </span> */}
                                <h1>Completed</h1>
                                <h2 className={(selectTab === 'Completed') ? 'active' : ''}>{entryCompleted.length}</h2>
                            </a>
                            <a className={(selectTab === 'Cancelled') ? 'active' : ''} onClick={(e) => {
                                setSelectTab('Cancelled')
                                console.log('selectTab', selectTab)
                            }}>
                                {/* <span className="material-symbols-outlined">
                                    groups
                                </span> */}
                                <h1>Cancelled</h1>
                                <h2 className={(selectTab === 'Cancelled') ? 'active' : ''}>{entryCancelled.length}</h2>
                                {/* <h2 className={calledFrom.current === 'Participant' ? 'active' : ''}>{participantCount.current}</h2> */}
                            </a>
                            <a className={(selectTab === 'Onhold') ? 'active' : ''} onClick={(e) => {
                                setSelectTab('Onhold')
                                console.log('selectTab', selectTab)
                            }}>
                                {/* <span className="material-symbols-outlined">
                                    groups
                                </span> */}
                                <h1>Onhold</h1>
                                <h2 className={(selectTab === 'Onhold') ? 'active' : ''}>{entryOnhold.length}</h2>
                                {/* <h2 className={cal
                                    ledFrom.current === 'Participant' ? 'active' : ''}>{participantCount.current}</h2> */}
                            </a>
                            <div style={{ width: false ? "100%" : "25%" }} className={selectTab === 'Active' ? 'event-details-menu-indicator Details' :
                                selectTab === 'Completed' ? 'event-details-menu-indicator Entries' :
                                    selectTab === 'Cancelled' ? 'event-details-menu-indicator Participant' : ''}></div>
                        </div>

                    </div>
                </div>}
                <br />
                {searchedEntry.map((participant) => {

                    // console.log('participant', participant);
                    eventDet = objPlayerParticipant.eventDetails.find(e => e.EventID === participant.EventID)
                    // if (selectTab === 'Active' && objPlayerParticipant.eventDetails.)
                    // if (element.EventStatus.toUpperCase() === 'OPEN' || element.EventStatus.toUpperCase() === 'UPCOMING' || element.EventStatus.toUpperCase() === 'ACTIVE') {
                    //     eventDet.push(element)
                    //     eventActive.push(element)
                    //     eventIDActive.push(element.EventID);
                    // } else if (element.EventStatus.toLowerCase() === 'cancelled') {
                    //     eventCancelled.push(element)
                    //     eventIDCancelled.push(element.EventID);
                    // } else if (element.EventStatus.toLowerCase() === 'closed' || element.EventStatus.toLowerCase() === 'completed') {
                    //     eventCompleted.push(element)
                    //     eventIDCompleted.push(element.EventID);
                    // } else if (element.EventStatus.toLowerCase() === 'onhold') {
                    //     eventOnhold.push(element)
                    //     eventIDOnhold.push(element.EventID);
                    // }
                    if (props.registrationType !== 'Pending') {
                        if (selectTab === 'Active' && (eventDet.EventStatus.toUpperCase() === 'OPEN' || eventDet.EventStatus.toUpperCase() === 'UPCOMING' || eventDet.EventStatus.toUpperCase() === 'ACTIVE')) {
                            return <EntryTicket key={participant.RegID} event={eventDet} participant={participant} showQR={true}></EntryTicket>
                        } else if (selectTab === 'Cancelled' && eventDet.EventStatus.toUpperCase() === 'CANCELLED') {
                            return <EntryTicket key={participant.RegID} event={eventDet} participant={participant} showQR={true}></EntryTicket>
                        } else if (selectTab === 'Completed' && (eventDet.EventStatus.toUpperCase() === 'CLOSED' || eventDet.EventStatus.toUpperCase() === 'COMPLETED')) {
                            return <EntryTicket key={participant.RegID} event={eventDet} participant={participant} showQR={true}></EntryTicket>
                        } else if (selectTab === 'Onhold' && eventDet.EventStatus.toUpperCase() === 'ONHOLD') {
                            return <EntryTicket key={participant.RegID} event={eventDet} participant={participant} showQR={true}></EntryTicket>
                        }
                    } else {
                        if (participant.PaymentStatus && participant.PaymentStatus.toUpperCase() === 'PENDING') {
                            // setPendingCount(pendingCount + 1);
                            pendingCount = pendingCount + 1;
                            console.log('pendingCount : ', pendingCount)
                            return <EntryTicket key={participant.RegID} event={eventDet} participant={participant} showQR={true}></EntryTicket>

                        }
                    }
                    // return <div className="col-lg-4 col-md-6 col-sm-12">
                    //     <div style={{ padding: '10px' }}>
                    //         {/* {console.log(props)} */}

                    //         <div className={participant.PaymentStatus.toUpperCase() === 'PENDING' ?
                    //             "reg-category-card active payment-pending" : "reg-category-card active payment-completed"} style={{ display: 'block' }}>

                    //             <div className="display-flex-div">
                    //                 <div className="category-details">
                    //                     <h1 style={{ color: '#666', fontSize: '0.8rem', fontWeight: 'normal' }}>{eventDet.EventName}</h1>

                    //                     <h1 width='100%'>{participant.ParticipantName}
                    //                         {participant.PartnerPlayerID !== '' ? '-' + participant.PartnerPlayerName : ''
                    //                         }
                    //                     </h1>

                    //                     {/* <h1>{participant.ParticipantName}- {participant.CategoryName}</h1> */}

                    //                     {participant.Gender.toUpperCase() === 'FEMALE' && participant.EventType.toUpperCase() === 'SINGLE' ? <div className="category-icons">
                    //                         <span className="material-symbols-outlined female">
                    //                             woman
                    //                         </span>
                    //                     </div> :
                    //                         participant.Gender.toUpperCase() === 'FEMALE' && participant.EventType.toUpperCase() === 'DOUBLE' ? <div className="category-icons">
                    //                             <span className="material-symbols-outlined female">
                    //                                 woman
                    //                             </span>
                    //                             <span className="material-symbols-outlined female">
                    //                                 woman
                    //                             </span>
                    //                         </div> :
                    //                             participant.Gender.toUpperCase() === 'MIXED' && participant.EventType.toUpperCase() === 'DOUBLE' ? <div className="category-icons">
                    //                                 <span className="material-symbols-outlined female">
                    //                                     woman
                    //                                 </span>
                    //                                 <span className="material-symbols-outlined male">
                    //                                     man
                    //                                 </span>
                    //                             </div> :
                    //                                 participant.Gender.toUpperCase() === 'MALE' && participant.EventType.toUpperCase() === 'SINGLE' ? <div className="category-icons">
                    //                                     <span className="material-symbols-outlined male">
                    //                                         man
                    //                                     </span>
                    //                                 </div> :
                    //                                     participant.Gender.toUpperCase() === 'MALE' && participant.EventType.toUpperCase() === 'DOUBLE' ? <div className="category-icons">
                    //                                         <span className="material-symbols-outlined male">
                    //                                             man
                    //                                         </span>
                    //                                         <span className="material-symbols-outlined male">
                    //                                             man
                    //                                         </span>
                    //                                     </div> :
                    //                                         participant.Gender.toUpperCase() === 'FEMALE' && participant.EventType.toUpperCase() === 'TEAM' ? <div className="category-icons">
                    //                                             <span className="material-symbols-outlined female">
                    //                                                 woman
                    //                                             </span>
                    //                                             <span className="material-symbols-outlined female">
                    //                                                 woman
                    //                                             </span>
                    //                                             <span className="material-symbols-outlined female">
                    //                                                 woman
                    //                                             </span>
                    //                                             <span className="material-symbols-outlined female">
                    //                                                 woman
                    //                                             </span>
                    //                                         </div> :
                    //                                             participant.Gender.toUpperCase() === 'MALE' && participant.EventType.toUpperCase() === 'TEAM' ? <div className="category-icons">
                    //                                                 <span className="material-symbols-outlined male">
                    //                                                     man
                    //                                                 </span>
                    //                                                 <span className="material-symbols-outlined male">
                    //                                                     man
                    //                                                 </span>
                    //                                                 <span className="material-symbols-outlined male">
                    //                                                     man
                    //                                                 </span>
                    //                                                 <span className="material-symbols-outlined male">
                    //                                                     man
                    //                                                 </span>
                    //                                             </div> :
                    //                                                 <div className="category-icons">
                    //                                                     <span className="material-symbols-outlined female">
                    //                                                         woman
                    //                                                     </span>
                    //                                                     <span className="material-symbols-outlined female">
                    //                                                         woman
                    //                                                     </span>
                    //                                                     <span className="material-symbols-outlined male">
                    //                                                         man
                    //                                                     </span>
                    //                                                     <span className="material-symbols-outlined male">
                    //                                                         man
                    //                                                     </span>
                    //                                                 </div>
                    //                     }

                    //                     {<h3>
                    //                         <strong>{participant.CategoryName} </strong>
                    //                         {participant.PartnerPlayerID !== '' && participant.RegistrationType === 'Partner' ? ' (registered by Partner)' : ''}
                    //                     </h3>}
                    //                 </div>

                    //                 <div className="category-fees">
                    //                     <h2 style={{ position: 'relative', top: '5px' }}><span>₹ </span>
                    //                         <span>{participant.Fees}</span>
                    //                     </h2>

                    //                     {withdraw && <button className='mybutton button5' onClick={(e) => {
                    //                         if (window.confirm('Are you sure you wish to withdraw for "'
                    //                             + participant.CategoryName + '" for Event : ' + eventDet.EventName))
                    //                             console.log('deleted : ', participant.TransactionID);
                    //                         if (participant.TransactionID !== null && participant.TransactionID !== undefined && participant.TransactionID !== '')
                    //                             handleRefund(participant);
                    //                     }
                    //                     }>Withdraw</button>}

                    //                     {payment && <button className='mybutton button5' onClick={(e) => {
                    //                         handlePayment(participant, eventDet);
                    //                     }
                    //                     }>Pay Now</button>}

                    //                 </div>

                    //             </div>
                    //             {participant.PaymentStatus.toUpperCase() === 'PENDING' &&
                    //                 <div className="payment-status pending">
                    //                     <h1>Payment Pending</h1>
                    //                 </div>
                    //             }
                    //             {participant.PaymentStatus.toUpperCase() === 'COMPLETED' &&
                    //                 <div className="payment-status completed">
                    //                     <h1>Payment Completed</h1>
                    //                 </div>
                    //             }


                    //         </div>
                    //     </div>
                    // </div >
                })}

            </div>

        </div>
    )
}
