import React from 'react'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'
import { useState } from 'react';
import RichTextEditor from "react-rte";


export default function EditEventNotice(props) {
    const [resultMessage, setResultMessage] = useState('');
    const [editedNoticeBoard, setEditedNoticeBoard] = useState(props.eventDetails && props.eventDetails.NoticeBoard ? props.eventDetails.NoticeBoard : '');
    const [isNoticeBoardEdit, setIsNoticeBoardEdit] = useState(false);
    const [noticeBoardvalue, setNoticeBoardvalue] = useState(
        RichTextEditor.createValueFromString(editedNoticeBoard, "html")
    );
    const handleCancelNoticeBoard = () => {
        setIsNoticeBoardEdit(false);
    };

    const handleEditNoticeBoard = () => {
        setIsNoticeBoardEdit(true);
    };

    const handleSaveNoticeBoard = async () => {
        try {
            //save in DB Start
            SaveNotice(noticeBoardvalue.toString("html"))
            //save in DB End

            setIsNoticeBoardEdit(false);
        } catch (error) {
            console.error("Error updating document:", error);
        }
    };


    async function SaveNotice(notice) {
        console.log('in SaveNotice');


        var para1 = {
            EventID: props.eventDetails.Eventid,
            NoticeBoard: notice,
        };

        console.log(para1);

        const ret = await httpsCallable(functions, "updateEventDetails_NoticeBoard");
        ret(para1).then(async (result) => {
            setResultMessage('Notice Details Saved Successfully !!')
            props.setEventDetails({
                ...props.eventDetails,
                NoticeBoard: notice
            })

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }
    return (
        <div id="noticeDiv" className="event-setup-notice-board-section">

            <div className="row no-gutter">

                <div className="event-detail-input-div" style={{ display: 'flex', padding: '0 20px' }}>
                    <h1>Event Notice</h1>
                    {/* <small>( The below details will be displayed on Event Details page under
                        Notice Board
                        Section. User ; for new line seperator.)</small>
                         */}
                    {<div style={{
                        position: 'relative',
                        left: '20px',
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        background: '#348DCB',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }} onClick={() => handleEditNoticeBoard()}>
                        <span style={{ fontSize: '1.1rem', color: '#fff', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} className="material-symbols-outlined">
                            edit
                        </span>
                    </div>}
                    {/* <br /><br /> */}
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="event-detail-input-div">
                        {/* <textarea name="" id="noticeBoard" onChange={(e) =>
                            props.setEventDetails({
                                ...props.eventDetails,
                                NoticeBoard: e.target.value
                            })}
                            value={props.eventDetails.NoticeBoard}
                            className=""></textarea> */}
                        {isNoticeBoardEdit ? (
                            <div>
                                <div>
                                    <RichTextEditor
                                        value={noticeBoardvalue}
                                        onChange={setNoticeBoardvalue}
                                    />
                                </div>
                                <div className="vg10"></div>
                                <div className="d-flex justify-content-between">
                                    <div
                                        className="theme_btn btn_border"
                                        onClick={handleCancelNoticeBoard}
                                        style={{
                                            width: "fit-content",
                                        }}
                                    >
                                        <button className="mybutton button5 event-details-save-btn"
                                            onClick={handleCancelNoticeBoard} type="button">Cancel</button>

                                    </div>

                                    <div
                                        className="theme_btn btn_fill"
                                        onClick={handleSaveNoticeBoard}
                                        style={{
                                            width: "fit-content",
                                        }}
                                    >
                                        <button className="mybutton button5 event-details-save-btn"
                                            onClick={handleSaveNoticeBoard} type="button">Save Notice</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="d-flex align-items-center">
                                    <p
                                        className="editortext"
                                        dangerouslySetInnerHTML={{
                                            __html:

                                                noticeBoardvalue.toString(
                                                    "html"
                                                ),
                                        }}
                                    ></p>

                                </div>
                            </>
                        )}

                    </div>
                </div>
                {/* <div id="errorMsgNotice" style={{ display: 'none' }}
                  className="col-lg-12 col-md-12 col-sm-12"><br />

                  <div className="event-detail-input-div">
                      <div></div>
                      <span id="errorMssageNotice">{errorMssageNotice}</span>
                  </div>

              </div> */}
                {/* <div className="col-lg-12 col-md-12 col-sm-12"><br />

                    <div className="event-details-save-btn-div">
                        <div></div>
                        <button className="mybutton button5 event-details-save-btn"
                            onClick={SaveNotice} type="button">Save Notice</button>
                    </div>

                </div> */}
                <div className="col-lg-12 col-md-12 col-sm-12"><br />
                    <div className="event-details-save-btn-div">
                        {resultMessage}
                    </div><br />

                </div>


            </div>

        </div>
    )
}
