import React, { useState, useEffect } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import useDocumentUpdate from '../hooks/useDocumentUpdate';
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import UpdateMatchScore from './UpdateMatchScore.js';
import '../css/UpdateEvent.css'
export default function UpdateMatch(props) {
    console.log('props : ', props)
    const [loading, setLoading] = useState(false)
    const [searchKey, setSearchKey] = useState('')
    const location = useLocation();
    const { user } = useUserAuth();
    const [matchUpdate, setMatchUpdate] = useState(null)
    const [matchList, setMatchList] = useState([])
    const [matchListFilter, setMatchListFilter] = useState([])
    const eventQueryConstraints = [
        where('EventID', '==', props.EventID),
    ];
    const { documents: matchKnockOut, error: matchKnockOutError } = useCollection('TX_MatchDetails', eventQueryConstraints)
    const { documents: matchLeague, error: matchLeagueError } = useCollection('TX_MatchDetailsLeague', eventQueryConstraints)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    useEffect(() => {
        let _mList = [];
        matchKnockOut.forEach(knockoutMatch => {
            if (knockoutMatch.ParticipantName !== 'Bye' && knockoutMatch.SecondParticipantName !== 'Bye') {
                _mList.push({
                    ...knockoutMatch,
                    DrawType: 'Knockout',
                    searchKey: knockoutMatch.CategoryName + knockoutMatch.PlayerCode + knockoutMatch.ParticipantName
                        + knockoutMatch.PartnerPlayerName + knockoutMatch.SecondPartnerPlayerName
                        + knockoutMatch.SecondParticipantName + knockoutMatch.SecondPlayerCode + knockoutMatch.Round,

                })
            }
        });
        setMatchList(_mList)
        setMatchListFilter(_mList);
    }, [matchKnockOut, matchLeague])
    function searchMatch() {


        var newArray = matchList.filter(function (el) {
            return el.searchKey.toUpperCase().includes(searchKey.toUpperCase());
        }
        );
        setMatchListFilter(newArray);
    }
    return (
        <>
            <div className='' style={{ background: '#fff' }}>


                <div className={true ? 'pop-up-div open' : 'pop-up-div'}>
                    <div className='pop-up-div-inner'>
                        <h1 >
                            <strong>Update Match</strong>
                        </h1>
                        <hr />

                        <div className='live-score-update'>
                            <div className='live-score-update-player' style={{ borderRight: '1px solid #ddd' }}>
                                <h1>Aditya Tripathi</h1>
                                <div>
                                    <small>-</small>
                                    <h2>0</h2>
                                    <small>+</small>
                                </div>
                            </div>
                            <div className='live-score-update-player'>
                                <h1>Koushik Marela</h1>
                                <div>
                                    <small>-</small>
                                    <h2>15</h2>
                                    <small>+</small>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div style={{ padding: '0 10px' }}>
                            <div className='live-score-result'>
                                <div className='live-score-result-heading'>
                                    <h1>Men's Singles - Semi Final</h1>
                                </div>
                                <div className='live-score-result-player' style={{ borderBottom: '1px solid #ddd' }}>
                                    <h2>Aditya Tripathi</h2>
                                    <div>
                                        <h3><strong>15</strong></h3>
                                        <h3>8</h3>
                                        <h3>0</h3>
                                    </div>
                                </div>
                                <div className='live-score-result-player'>
                                    <h2>Koushik</h2>
                                    <div>
                                        <h3>10</h3>
                                        <h3><strong>15</strong></h3>
                                        <h3><strong>15</strong></h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='btn-div'>
                            <button className='mybbutton button5'>
                                <span>YES</span>
                            </button>
                            <button style={{ background: '#aaa' }} className='mybbutton button5' >
                                <span>Cancel</span>
                            </button>
                        </div>
                    </div >
                </div>

                <div style={{ textAlign: 'center' }}>
                    <strong>Update Match</strong>
                </div>

                <div className='row no-gutters'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='event-search-div'>
                            <div style={{ maxWidth: '400px' }}>
                                <input type="text" style={{
                                    boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
                                    border: '1px solid #ddd'
                                }} placeholder='e.g. category , player name etc' required
                                    onChange={(e) => {
                                        setSearchKey(e.target.value)
                                        searchMatch()

                                    }} value={searchKey} />
                                <button className="mybutton button5" onClick={() => {
                                    searchMatch()

                                }}>
                                    <span className="material-symbols-outlined">
                                        search
                                    </span>
                                </button>
                            </div>
                        </div>
                        <br></br>
                    </div>

                    {matchListFilter.map((obj) => {
                        return <div className='col-lg-4 col-md-6 col-sm-12' key={obj.id}>
                            <div className="event-list-card" style={{ height: '90px', border: '1px solid #aaa', }} >
                                <div className="" style={{ width: '100%' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ paddingRight: '5px' }}>
                                                <h2 style={{ margin: '0' }}>Reporting</h2>
                                                <h1>Aditya Tripathi</h1>
                                            </div>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                        </div>
                                        <div style={{ textAlign: 'center', width: '50px' }}>
                                            <h1 style={{ fontSize: '1.1rem' }}>VS</h1>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                                            <input type="checkbox" style={{ width: '20px', height: '20px' }} />
                                            <div style={{ paddingLeft: '5px' }}>
                                                <h2 style={{ margin: '0' }}>Reporting</h2>
                                                <h1>Aditya Tripathi</h1>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <h1> {(obj.ParticipantName === '' ? 'TBD' : (obj.ParticipantName
                                        + (obj.PartnerPlayerName && obj.PartnerPlayerName !== '' ? '-' + obj.PartnerPlayerName : '')))
                                        + ' vs ' + (obj.SecondParticipantName === '' ? 'TBD' : (obj.SecondParticipantName + (obj.SecondPartnerPlayerName && obj.SecondPartnerPlayerName !== '' ? '-' + obj.SecondPartnerPlayerName : '')))}</h1> */}
                                    {obj.MatchStatus === 'DONE' && <h3>{obj.MatchSchedule}  | {obj.MatchScore}  | {obj.MatchStatus}   | {obj.WinnerPlayerCode && obj.WinnerPlayerCode}</h3>}
                                    {/* <br></br> */}
                                    <hr style={{ margin: '5px 0' }} />
                                    <center>
                                        <button className="msybutton button5"
                                            onClick={() => {
                                                // console.log('action', action)
                                                // getParticipantList(obj.id, obj.EventCode);
                                                setMatchUpdate({
                                                    ...obj,
                                                    FirstPlayer: (obj.ParticipantName === '' ? 'TBD' : (obj.ParticipantName
                                                        + (obj.PartnerPlayerName && obj.PartnerPlayerName !== '' ? '-' + obj.PartnerPlayerName : ''))),
                                                    SecondPlayer: (obj.SecondParticipantName === '' ? 'TBD' : (obj.SecondParticipantName + (obj.SecondPartnerPlayerName && obj.SecondPartnerPlayerName !== '' ? '-' + obj.SecondPartnerPlayerName : '')))

                                                })
                                            }}
                                            style={{
                                                // position: 'absolute',
                                                // right: '10px',
                                                // top: '50%',
                                                // transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                                height: '25px',
                                                border: '1px solid #fff',
                                                paddingTop: '1px',
                                                fontSize: '0.9rem'
                                            }}>
                                            Update Match
                                        </button>
                                    </center>
                                </div>

                                <div className="event-id" style={{ right: '50%', transform: 'translateX(50%)', background: '#333C5D', height: '20px', width: 'auto' }}>
                                    <h5>{obj.CategoryName} - {obj.Round}</h5>
                                </div>

                            </div>
                            <br></br>

                        </div>
                    })}

                </div>
                {matchUpdate && <UpdateMatchScore match={matchUpdate} setMatchUpdate={setMatchUpdate}></UpdateMatchScore>}
            </div>
        </>
    )
}
