// import { projectFirestore } from "../firebase/config"
import { projectFirestore } from '../firebase.js'


import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';


export const useCollection = (collectionName, _query) => {

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const colRef = collection(projectFirestore, collectionName);

    // Construct the query
    const q = query(colRef, ..._query);

    // Fetch the documents once
    // getDocs(q)
    //   .then(snapshot => {
    //     const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //     setDocuments(docs);
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     setError(err.message);
    //     setLoading(false);
    //   });

    // OR listen for real-time updates:
    const unsubscribe = onSnapshot(q, (snapshot) => {
      try {
        const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDocuments(docs);
      } catch (err) {

      }
      setLoading(false);
    }, (err) => {
      setError(err.message);
      setLoading(false);
    });

    // unsubscribe on unmount
    return () => unsubscribe()

  }, [collection, query])

  return { documents, loading, error }
}