import React, { useState, useEffect } from 'react'
import EntryTicket from './EntryTicket'
import { useLocation } from 'react-router-dom';
export default function MyOrder() {
    const { state } = useLocation();
    const { orderList } = state;
    console.log('orderList', orderList)
    const [eventID, setEventID] = useState([])
    const [eventList, setEventList] = useState([])
    const [isPayClicked, setIsPayClicked] = useState(false);
    const [signinFlag, setSigninFlag] = useState(true);

    useEffect(() => {
        let _eventID = [];
        orderList && orderList.forEach(element => {
            if (!_eventID.includes(element.EventID))
                _eventID.push(element.EventID)
        });
        setEventID(_eventID)
    }, [orderList])
    function GetDocumentList(list) {
        console.log(list)
        setEventList(list)
    }

    function setLoginFlag(flag) {
        // console.log('in setLoginFlag', flag)
        // setLoginFlagForWithdraw(true)
        setSigninFlag(flag);
    }
    // console.log('_eventID', eventID)
    return (
        <>
            <div className="container-fluid">

                <div className="row no-gutters">
                    {/* {loading && <Loading></Loading>} */}
                    <div className="col-lg-12 col-md-12 col-sm-12">

                        <br></br>
                        <h4 style={{ fontWeight: '1000', color: '#348DCB', position: 'relative', top: '2px', textAlign: 'center' }}>
                            <span className="material-symbols-outlined" style={{ paddingRight: '10px', position: 'relative', top: '5px' }}>
                                subscriptions
                            </span>
                            My Orders
                        </h4>

                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">

                        <div className="row no-gutters" id="divRegEvent">


                            {orderList && eventList && eventList.length > 0 && orderList.map((registrerObj) => {
                                let eventObt = eventList.find(e => e.id === registrerObj.EventID);

                                return <EntryTicket key={registrerObj.CategoryName + eventObt.EventID + registrerObj.ParticipantID}
                                    event={eventObt ? eventObt : ''}
                                    participant={registrerObj}
                                    setIsPayClicked={setIsPayClicked}
                                    showQR={false}
                                    setLoginFlag={setLoginFlag}
                                    playerID={registrerObj.ParticipantID}
                                    showColumn={4}
                                ></EntryTicket>
                            })}

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
