import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase.js"
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

export const useDocument = (collection, id) => {
  const [document, setDocument] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true);

  // realtime document data
  useEffect(() => {
    // console.log('projectFirestore', projectFirestore)
    // const ref = projectFirestore.collection(collection).doc(id)
    const docRef = doc(projectFirestore, collection, id);
    // console.log('collection, id', collection, id)
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setDocument({ id: docSnap.id, ...docSnap.data() });
      } else {
        setError("Document does not exist");
      }
      setLoading(false);
    }, (err) => {
      setError(err.message);
      setLoading(false);
    });

    // unsubscribe on unmount
    return () => unsubscribe()

  }, [collection, id])

  return { document, loading, error }
}