import React, { useState, useEffect, useMemo } from 'react'
// import { httpsCallable } from "firebase/functions";
// import { functions } from '../firebase.js'
import Loading from './Loading.js'
// import { useLocation } from 'react-router-dom';
import EDTournamentDetails from './EDTournamentDetails.js';
import EDAboutEvent from './EDAboutEvent.js';
// import SingleElimination from './SingleElimination.js'
// import LeagueMatchDetails from './LeagueMatchDetails.js'
import EventDetailsMenu from './EventDetailsMenu.js';
import ShowDrawComponent from './ShowDrawComponent.js';
import { useParams } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument.js';
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';

export default function ShowDraw() {
    const { eventID, showMenu } = useParams();
    console.log('EventID, ShowMenu', eventID, showMenu)

    const [categoryName, setCategoryName] = useState('')
    const [loading, setLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [rentryCount, setRentryCount] = useState(0);


    const [matchKnockOut, setMatchKnockOut] = useState([]);
    const [matchLeague, setmatchLeague] = useState([]);
    const [matchLeagueStatus, setmatchLeagueStatus] = useState([]);
    const [drawCategory, setDrawCategory] = useState({
        CategoryName: '',
        DrawType: '',
        MatchDetails: [],
        MatchStatus: []
    });
    console.log('EventID', eventID)
    const queryConstraints = [
        where('EventID', '==', eventID ? eventID : ''),
        // Add more query constraints as needed
    ];
    const { document: eventDetailsDoc, error: eventDetailsDocError } = useDocument('EventList', eventID)
    //  useDocument('EventList', eventID ? eventID : '')

    console.log('eventDetailsDocError  ', eventDetailsDocError, ':: eventDetailsDoc', eventDetailsDoc)

    console.log('1 queryConstraints : ', queryConstraints)
    const { documents: partcipantObj, error: partcipantObjError } = useCollection('EventRegistrationDetails', queryConstraints)
    console.log('partcipantObj  ', partcipantObj, ':: partcipantObjError', partcipantObjError)

    useEffect(() => {
        // console.log('in useEffect')
        // setCategoryName('BD U11');
        // console.log('EventID', EventID)
        // getDraw();
        setRentryCount(partcipantObj.length)
    }, [eventDetailsDoc, partcipantObj])

    // const mLink = useMemo(() => {
    //     console.log('EventDetails: ', eventDetailsDoc, 'partcipantObj:', partcipantObj, 'rentryCount : ', rentryCount)
    //     return eventDetailsDoc && partcipantObj && partcipantObj.flagSet &&
    //         <EventDetailsMenu calledFrom='Draws'
    //             eventDetails={eventDetailsDoc}

    //             eventID={eventDetailsDoc && eventDetailsDoc.id ? eventDetailsDoc.id : ''}

    //         // entryCount={rentryCount}
    //         // uniqueParticipantDetails={partcipantObj.uniqueParticipantDetails}
    //         // participantDetails={partcipantObj.participantDetails}
    //         // participantCount={partcipantObj.participantCount}
    //         />
    // }, [eventDetailsDoc, partcipantObj, rentryCount])

    // async function getDraw() {
    //     var para1 = {};
    //     console.log('EventDetails', eventDetailsDoc)
    //     para1 = {
    //         EventID: eventDetailsDoc.id //props.EventID //'BP3A5E0nFt5QBJMyDthp'//props.EventID,
    //     };
    //     setLoading(true);
    //     // console.log('para1 : ', para1)
    //     const ret1 = httpsCallable(functions, 'getDrawDetails');

    //     ret1(para1).then(async (result) => {
    //         // console.log('result', result)
    //         setCategoryList(result.data.CategoryList);
    //         setCategoryName(result.data.CategoryList ? result.data.CategoryList[0] : '');
    //         setMatchKnockOut(result.data.MatchDetailsKnockOut);
    //         setmatchLeague(result.data.MatchDetailsLeague);
    //         setmatchLeagueStatus(result.data.MatchDetailsLeagueStatus)
    //         setLoading(false);
    //     })
    // }
    function ShowCategoryDraw(category) {
        let dType = '';
        let matchDetails = [];
        let matchStatus = [];
        // console.log('category : ', category, ' :: matchKnockOut : ', matchKnockOut, ' :: matchLeague : ', matchLeague)
        if (matchKnockOut.find(e => e.CategoryName === category)) {
            // console.log('category : ', category, ' ::Knockout ')

            dType = 'Knockout';
            matchDetails = matchKnockOut.filter(e => e.CategoryName === category);
            matchDetails.sort(function (a, b) { return a.MatchID - b.MatchID })

        } else if (matchLeague.find(e => e.CategoryName === category)) {
            // console.log('category : ', category, ' ::League ')
            dType = 'League';
            matchDetails = matchLeague.filter(e => e.CategoryName === category);
            matchStatus = matchLeagueStatus.filter(e => e.CategoryName === category);
            matchDetails.sort(function (a, b) { return a.MatchID - b.MatchID })
        }

        setDrawCategory({
            CategoryName: category,
            DrawType: dType,
            MatchDetails: matchDetails,
            MatchStatus: matchStatus
        });
    }
    console.log('eventDetailsDoc', eventDetailsDoc)
    return (
        <>
            {loading && <Loading></Loading>}
            {eventDetailsDoc && <EventDetailsMenu calledFrom='Draws'
                eventID={eventID}
                eventDetails={eventDetailsDoc}
            />}
            {/* {console.log(memoSetPartcipantObj)} */}
            <div className="container-fluid">

                <div className="row no-gutters">
                    <div className={showMenu ? "col-lg-8 col-md-8 col-sm-12" : "col-lg-12 col-md-12 col-sm-12"}>
                        {/* {mLink} */}

                        {!loading &&
                            <ShowDrawComponent
                                IsAdmin={false}
                                EventID={eventID}
                            // PublishDraw={PublishDraw}
                            >

                            </ShowDrawComponent>

                        }
                        {/* {!loading && <div className='row no-gutters' style={{ background: '#fff' }}>
                            <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                <strong>Draw for - Category List</strong>
                            </div>
                            <div className='col-lg-12' style={{ border: '1' }}>
                                <div className='row no-gutters' style={{ padding: '0 5px' }}>

                                    {categoryList && categoryList.map((obj) => {
                                        return <div key={obj} className="col-4" style={{ padding: '0' }}>

                                            <div style={{ widht: '100%', textAlign: 'center', padding: '8px 5px' }}>
                                                <input type="radio" checked={drawCategory.CategoryName === obj ? true : false} className="checkbox" style={{ width: '0px' }}
                                                    name="EventName" id={obj.replaceAll(' ', '').replaceAll('-', '')}
                                                    value={obj}
                                                    onChange={() => {
                                                        ShowCategoryDraw(obj);

                                                    }} />
                                                <label style={{ height: '30px', border: '1px solid #ddd', paddingTop: '1px' }}
                                                    className="checkbox-label setup-draw-label" id="MSLabel"

                                                    htmlFor={obj.replaceAll(' ', '').replaceAll('-', '')}>
                                                    <i className="fas fa-plus"
                                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                    <i className="fas fa-check"
                                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                    <span>{obj}</span>
                                                </label>
                                            </div>

                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>}
                        {drawCategory.DrawType === 'Knockout' && <>
                            <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                <hr></hr>
                                <strong>Draw for Category : {drawCategory.CategoryName}</strong>
                            </div>
                            <SingleElimination MatchDetails={drawCategory.MatchDetails}></SingleElimination>
                        </>
                        }
                        {drawCategory.DrawType === 'League' && <>
                            <div className='col-lg-12' style={{ textAlign: 'center' }}>
                                <hr></hr>
                                <strong>Draw for Category : {drawCategory.CategoryName}</strong>
                                <hr></hr>
                            </div>
                            <LeagueMatchDetails MatchDetails={drawCategory.MatchDetails} MatchStatus={drawCategory.MatchStatus}></LeagueMatchDetails>
                        </>
                        } */}
                    </div>
                    {/* {loading && <lottie-player style={{ height: '400px' }} background="transparent" speed="1" loop autoplay></lottie-player>} */}
                    {/* {loading && <Loading height='400px' weight='100%'></Loading>} */}

                    {eventDetailsDoc && <EDTournamentDetails eventDetails={eventDetailsDoc} showRegistration={true}
                        showRegistrationButton={true} />}
                    {eventDetailsDoc && <EDAboutEvent eventDetails={eventDetailsDoc} />}
                </div>
            </div>








        </>
    )
}
