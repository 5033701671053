import React, { useState, useEffect } from 'react'
import useDocumentUpdate from '../hooks/useDocumentUpdate';
import { useUserAuth } from '../context/UserAuthcontext';

export default function UpdateMatchScore(props) {
    console.log('props', props)
    const { user } = useUserAuth();

    const [firstPlayer, setFirstPlayer] = useState(true)
    const [firstPlayerReported, setFirstPlayerReported] = useState(props.match.FirstPlayerReported ? props.match.FirstPlayerReported : false)
    const [secondPlayerReported, setSecondPlayerReported] = useState(props.match.SecondPlayerReported ? props.match.SecondPlayerReported : false)
    const [matchScore, setMatchScore] = useState(props.match.MatchScore ? props.match.MatchScore : '')
    const [matchDuration, setMatchDuration] = useState(props.match.MatchDuration ? props.match.MatchDuration : 15)
    const [courtDetails, setCourtDetails] = useState(props.match.CourtDetails ? props.match.CourtDetails : '')

    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();

    useEffect(() => {
        setFirstPlayerReported(props.match.FirstPlayerReported ? props.match.FirstPlayerReported : false)
        setSecondPlayerReported(props.match.SecondPlayerReported ? props.match.SecondPlayerReported : false)
        setMatchScore(props.match.MatchScore ? props.match.MatchScore : '')
        setMatchDuration(props.match.MatchDuration ? props.match.MatchDuration : 15)
        setCourtDetails(props.match.CourtDetails ? props.match.CourtDetails : '')

    }, [props.match])

    function updateMatch() {

    }
    function updateNoMatch() {

    }
    function updateWalkover() {

    }
    function updateRetiered() {

    }
    async function updatePlayerReported(flag) {
        let _updatedData = {};
        if (flag === 'First') {
            _updatedData = {
                'FirstPlayerReported': firstPlayerReported,
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()
            }
        } else {
            _updatedData = {
                'SecondPlayerReported': secondPlayerReported,
                "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                "UpdatedTimestamp": new Date()
            }
        }
        if (props.match.DrawType === 'Knockout') {
            await updateDocument('TX_MatchDetails', props.match.id, _updatedData)
        }
        else {
            await updateDocument('TX_MatchDetailsLeague', props.match.id, _updatedData)
        }
    }
    return (
        <div>
            <div className='row no-gutters'>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <strong>Update Match Score : {props.match.CategoryName}</strong>
                </div>
                <div className='col-6'>

                    <div style={{ widht: '50%' }} >

                        <input type="radio" checked={firstPlayer ? true : false} onChange={(e) => {
                            setFirstPlayer(!firstPlayer)
                        }} className="checkbox" style={{ width: '0px' }}
                            name="matchstatus" id="regParticipantFirst" value="First" />
                        <label style={{ height: '40px', border: '1px solid #ddd' }}
                            className="checkbox-label" id="regParticipantFirstLabel"
                            htmlFor="regParticipantFirst">
                            <i className="fas fa-plus"
                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                            <span>{props.match.FirstPlayer}</span>
                        </label>

                    </div>


                    <div style={{ widht: '50%' }} >

                        <input type="checkbox"
                            checked={firstPlayerReported}
                            onChange={(e) => {
                                setFirstPlayerReported(!firstPlayerReported)
                                updatePlayerReported('First')
                                // saveEventStatus();

                            }}
                            className="checkbox" style={{ width: '0px' }}
                            name="firstPlayer" id="firstPlayer" value="Event Status" />
                        <label style={{ height: '40px', border: '1px solid #ddd' }}
                            className="checkbox-label" htmlFor="firstPlayer">
                            <i className="fas fa-plus"
                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                            <span> {props.match.FirstPlayer} Reported</span>
                        </label>
                    </div>


                </div>

                <div className='col-6'>

                    <div style={{ widht: '50%' }}>
                        <input type="radio" checked={!firstPlayer ? true : false} onChange={(e) => {
                            setFirstPlayer(!firstPlayer)
                        }} className="checkbox" style={{ width: '0px' }}
                            name="matchstatus" id="regParticipantSecond" value="Second" />
                        <label style={{ height: '40px', border: '1px solid #ddd' }}
                            className="checkbox-label" id="regParticipantSecondLabel"
                            htmlFor="regParticipantSecond">
                            <i className="fas fa-plus"
                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                            <span>{props.match.SecondPlayer}</span>
                        </label>
                    </div>
                    <div style={{ widht: '50%' }} >

                        <input type="checkbox"
                            checked={secondPlayerReported}
                            onChange={(e) => {
                                setSecondPlayerReported(!secondPlayerReported)
                                // saveEventStatus();
                                updatePlayerReported('Second')
                            }}
                            className="checkbox" style={{ width: '0px' }}
                            name="secondPlayer" id="secondPlayer" value="Event Status" />
                        <label style={{ height: '40px', border: '1px solid #ddd' }}
                            className="checkbox-label" htmlFor="secondPlayer">
                            <i className="fas fa-plus"
                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                            <span> {props.match.SecondPlayer} Reported</span>
                        </label>
                    </div>

                </div>
                <div className='col-6'>
                    <div className="event-detail-input-div">
                        <label>Round</label>

                        <input type="text" readOnly placeholder="match score..."
                            value={props.match.Round}
                        />
                    </div>
                </div>
                <div className='col-6'>
                    <div className="event-detail-input-div">
                        <label>court</label>

                        <input type="text" placeholder="Court..."
                            value={courtDetails}
                            onChange={(e) => {
                                setCourtDetails(e.target.value)
                            }}
                        />
                    </div>
                </div>

                <div className='col-6'>
                    <div className="event-detail-input-div">
                        <label>Match Score</label>

                        <input type="text" placeholder="match score..."

                            onChange={(e) =>
                                setMatchScore(e.target.value)
                            }

                            value={matchScore}

                            required />
                    </div>
                </div>
                <div className='col-6'>
                    <div className="event-detail-input-div">
                        <label>Match Duration</label>

                        <input type="text" placeholder="match duration in mins..."

                            onChange={(e) =>
                                setMatchDuration(e.target.value)
                            }

                            value={matchDuration}

                            required />
                    </div>
                </div>

                <div className='col-3'>
                    <button className="mybutton button5" onClick={() => {
                        console.log('save match')
                        updateMatch()
                    }}>
                        Update Match Score

                    </button>
                </div>
                <div className='col-3'>
                    <button className="mybutton button5" onClick={() => {
                        console.log('save match')
                        updateNoMatch()
                    }}>
                        No Match

                    </button>
                </div>
                <div className='col-3'>
                    <button className="mybutton button5" onClick={() => {
                        console.log('save match')
                        updateWalkover()
                    }}>
                        Walkover

                    </button>
                </div>
                <div className='col-3'>
                    <button className="mybutton button5" onClick={() => {
                        console.log('save match')
                        updateRetiered()
                    }}>
                        Retiered

                    </button>
                </div>
                <div className='col-3'>
                    <button className="mybutton button5" onClick={() => {
                        console.log('save match')
                        props.setMatchUpdate(null)
                    }}>
                        Cancel

                    </button>
                </div>
                <hr></hr>

            </div>

        </div>
    )
}
