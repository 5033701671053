import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { functions } from '../firebase.js';
import '../css/UpdateEvent.css';

import { httpsCallable } from "firebase/functions";
import { useUserAuth } from '../context/UserAuthcontext.js';
// import { useLocalStorage } from "../context/useLocalStorage.js";
import { useExcelImportExport } from '../hooks/useExcelImportExport';
import ExcelToJson from './ExcelToJson';
import axios from 'axios';
import Loading from './Loading.js';
import EventSetupDraw from './EventSetupDraw.js';
import MatchScheduleSetup from './MatchScheduleSetup.js';
import { where, orderBy } from 'firebase/firestore';
import { useCollection } from '../hooks/useCollection.js';
import SearchComponents from './SearchComponents.js';
import UpdateMatch from './UpdateMatch.js';
export default function UpdateEvent() {

    // console.log(props);
    // const { state } = useLocation();
    const { action } = useParams();
    const [draw, setDraw] = useState(false);
    const navigate = useNavigate();

    const { exportExcel } = useExcelImportExport()
    const { user, logout } = useUserAuth();
    const [showEventList, setShowEventList] = useState(true);
    const [resetFlag, setResetFlag] = useState(false);
    const [expandParam, setExpandParam] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingParticipant, setLoadingParticipant] = useState(true);
    const [paymentRemarks, setPaymentRemarks] = useState('');
    const [eventList, setEventList] = useState([]);
    const [eventListFilter, setEventListFilter] = useState([]);
    const [participantList, setParticipantList] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState();
    const [entryDetails, setEntryDetails] = useState(null);
    const [searchKey, setSearchKey] = useState('');
    const [searchKeyPlayer, setSearchKeyPlayer] = useState('');
    const [participantListSearch, setParticipantListSearch] = useState(null);

    const [popupLoading, setPopupLoading] = useState(false);
    const [showExportLoading, setShowExportLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [withdrawAllEntry, setWithdrawAllEntry] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [scheduleSetup, setScheduleSetup] = useState(false);
    const [matchUpdate, setMatchUpdate] = useState(false);
    const [partnerList, setPartnerList] = useState([]);
    const [partnerCode, setPartnerCode] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const location = useLocation();

    const queryEvent = [
        orderBy('EventStatus'),
        orderBy('EventStartDate', 'desc'),
    ]

    const queryEventUser = [
        where('OrganizerID', '==', user && user.userInfo && user.userInfo.uid ? user.userInfo.uid : ''),
        orderBy('EventStatus'),
        orderBy('EventStartDate', 'desc'),
    ]

    const { documents: eventDoc, error: eventDocError } = useCollection('EventList', user && user.userInfo && user.userInfo.SelectedRole === 'ADMIN' ? queryEvent : queryEventUser)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    function setJSON(json) {
        // console.log('json : ', json)
        json.forEach(element => {
            console.log('element', element)
        });
    }

    const [entryTemplate, setEntryTemplate] = useState([{
        EventCode: '',
        PlayerName: '',
        PlayerDOB: new Date(),
        PlayerGender: '',
        PlayerEmail: '',
        PlayerPhone: '',
        PartnerName: '',
        PartnerDOB: '',
        PartnerGender: '',
        CategoryName: '',
        PaymentStatus: 'Pending/Completed'
    }])
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    var options = {
        year: '2-digit',
        // year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    // let eventCntList = [];

    async function getEventList() {
        console.log('in getEventList');
        var functionName = '';


        let data = eventDoc;
        var refdate = "";
        var today = new Date();
        var newData = [];
        var eventSDate = '';
        var eventEDate = '';
        // console.log(data)
        data.forEach(element => {
            eventEDate = '';
            eventSDate = '';
            if (element.EventStartDate) {
                refdate = new Date(element.EventStartDate.seconds * 1000);
                eventSDate = refdate.toLocaleDateString("en-IN", options);
            }
            else {
                eventSDate = "";
            }
            if (element.EventEndDate) {
                var eDate = new Date(element.EventEndDate.seconds * 1000 + 60 * 60 * 24 * 1000);
                eventEDate = eDate.toLocaleDateString("en-IN", options);
            }
            else {
                eventEDate = '';
            }
            if (refdate <= today && eDate >= today && element.EventStatus.toUpperCase() === 'ACTIVE') {
                element.isLive = true;
            } else {
                element.isLive = false;
            }

            newData.push({
                ...element,
                EventSDate: eventSDate,
                EventEDate: eventSDate,
                Fees: element.MinimumFee ? (Number(element.MinimumFee).toLocaleString('en-IN', curFormat)) : "",
                flag: true,
                searchKey: element.EventCode + element.EventName + element.EventOwnerName + element.OrganizationName + element.City
            })
        });
        // console.log('newData', newData)
        setLoading(false);
        setEventListFilter(newData);
        return newData;


    }
    // useEffect(() => {
    //     console.log('in useEffect 1')
    //     if (!isNewUser) {
    //         console.log('not new user', isNewUser)
    //         if (user.isLoggedIn) {
    //             if (user.userInfo) {
    //                 // userDetails && userDetails.id === user.userInfo.id &&
    //                 setSigninFlag(true)
    //             }
    //         }
    //     }
    // }, [user])

    useEffect(() => {
        console.log('in useEffect 1')
        getEventList().then(result => {
            setEventList(result);

            return result;
        })


    }, [eventDoc])


    useEffect(() => {
        console.log('in useEffect 2')

        if (action === 'SetupDraw' || action === 'MatchSetup') {
            setDraw(true);
        } else if (action === 'ScheduleSetup') {
            setScheduleSetup(true);
        } else if (action === 'UpdateMatch') {
            setMatchUpdate(true);
        }

    }, [action])

    // const mEvents = useMemo(async () => {
    //     return await getEventList().then(result => {
    //         setEventList(result);

    //         return result;
    //     })

    // }, []);


    function searchEvent() {

        var newArray = eventList.filter(function (el) {
            return el.searchKey.toUpperCase().includes(searchKey.toUpperCase());
        }
        );
        setEventListFilter(newArray);
    }

    function searchPlayer() {

        var newArray = participantList.filter(function (el) {
            return el.searchKeyPlayer.toUpperCase().includes(searchKeyPlayer.toUpperCase());
        }
        );
        setParticipantListSearch(newArray);
    }
    let eDate = '';
    let index = 0
    let refdate = '';
    async function updatePartnerForParticipant(eventID, eventCode) {
        console.log('event Code', eventID)
        // getParticipants
        const eventSummaryBySports = httpsCallable(functions, "getParticipants");

        setLoading(true);
        let para = {
            EventID: eventID,
        };
        return await eventSummaryBySports(para)
            .then(async (result) => {
                console.log('result', result)
                let objParticipant = [];
                //                setParticipantList(result.data);
                result.data.forEach(element => {
                    objParticipant.push({
                        ...element,
                        searchKeyPlayer: element.ParticipantID + element.CategoryName + element.EventType +
                            element.ParticipantName + (element.PartnerPlayerName ? element.PartnerPlayerName : '')
                    })
                });
                setParticipantList(objParticipant);
                setParticipantListSearch(objParticipant);

                setShowEventList(false);

            });

    }

    async function getParticipantList(eventID, eventCode) {
        console.log('event Code', eventID)
        setShowExportLoading(true);
        // getParticipants
        let objEvent = eventList.find(e => e.EventCode === eventCode);
        setSelectedEvent(objEvent);
        objEvent && objEvent.CategoryDetails && objEvent.CategoryDetails[0] && setSelectedCategory(objEvent.CategoryDetails[0].CategoryName);
        const eventSummaryBySports = httpsCallable(functions, "getParticipants");

        setLoadingParticipant(true);
        let para = {
            EventID: eventID,
        };
        return await eventSummaryBySports(para)
            .then(async (result) => {
                console.log('result', result)

                // exportExcel(result.data, eventCode + "_ParticipantList");

                if (action === 'ParticipantList') {
                    exportExcel(result.data, eventCode + "_ParticipantList");
                }
                else {
                    let objParticipant = [];
                    result.data.forEach(element => {
                        objParticipant.push({
                            ...element,
                            searchKeyPlayer: element.ParticipantID + element.CategoryName + element.EventType +
                                element.ParticipantName + (element.PartnerPlayerName ? element.PartnerPlayerName : '')
                        })
                    });
                    setParticipantList(objParticipant);
                    setParticipantListSearch(objParticipant);


                    // setParticipantList(result.data);
                    setShowEventList(false);
                }
                setLoadingParticipant(false);
                setShowExportLoading(false);
            });

    }
    function selectPartner(sel) {
        console.log('sel', sel.target.value);
    }
    async function withdrawEntry(obj) {
        console.log('in withDrawEvent', obj)

        //update database status for transaction

        const withdraw = httpsCallable(functions, "withdrawRegistration");
        let catList = [];
        catList.push(obj.CategoryName);
        setLoading(true);
        let para = {

            EventID: obj.EventID,
            PlayerID: obj.ParticipantID,
            DeleteCategoryList: catList
        };
        console.log('para', para);
        return await withdraw(para)
            .then(async (result) => {
                console.log('result', result)
                //delete from list
                console.log('participantList', participantList)
                let index = participantList.findIndex(e => e.CategoryName === obj.CategoryName && e.ParticipantID === obj.ParticipantID)
                console.log('index', index);
                catList = participantList;
                catList.splice(index, 1);
                console.log('catList', catList);
                setParticipantList(catList);
                if (obj.PaymentStatus === 'Completed' && obj.TransactionID && obj.TransactionID !== '' && obj.TransactionID !== undefined) {
                    try {
                        handleRefund(obj);
                    }
                    catch (err) {
                        console.log('error while  refund');
                    }


                }
            });

    }

    async function withdrawAllEntryFunction() {
        console.log('selectedCategory', selectedCategory);
        console.log('selectedevent', selectedEvent);

        //update database status for transaction

        const withdraw = httpsCallable(functions, "withdrawRegistrationForCategory");
        let catList = [];
        catList.push(selectedCategory);
        setLoading(true);
        let para = {

            EventID: selectedEvent.Eventid,
            CategoryName: selectedCategory
        };
        console.log('para', para);
        return await withdraw(para)
            .then(async (result) => {
                console.log('result', result)
                //delete from list
                console.log('participantList', participantList)
                catList = participantList;
                var deletedParticipant = catList.filter(e => e.CategoryDetails === selectedCategory)
                catList = catList.filter(e => e.CategoryDetails !== selectedCategory)
                console.log('catList', catList);
                setParticipantList(catList);
                deletedParticipant.forEach(element => {
                    if (element.PaymentStatus === 'Completed' && element.TransactionID && element.TransactionID !== '' && element.TransactionID !== undefined) {
                        try {
                            handleRefund(element);
                        }
                        catch (err) {
                            console.log('error while  refund');
                        }
                    }

                });



            });

    }

    async function handleRefund(obj) {
        // const handleRefund = async (obj) => {

        //uppdate the registration status as withdrawn

        if (obj.TransactionID !== null && obj.TransactionID !== undefined && obj.TransactionID !== '') {
            // if (true)
            console.log(`https://api.razorpay.com/v1/payments/pay_LesYEQHTq8bi3w/refund`)
            try {
                const response = await axios.post(
                    "https://api.razorpay.com/v1/payments/pay_LesYEQHTq8bi3w/refund",
                    {
                        amount: 1000, // replace with the amount to be refunded
                        notes: {
                            reason: 'Incorrect item received', // replace with the reason for the refund
                        },
                    },
                    {
                        auth: {
                            username: 'rzp_test_gaZqhFw4MY2o6v',
                            password: 'mL2eEEGVYMTq0aMCaB2EEUpd',
                        },
                    }
                );
                console.log('success ', response.data);
            } catch (error) {
                console.log('error', error);
                // throw 'Error while refund';
            }
        }
    };

    async function updatePartnerDetailsPopup(obj) {
        console.log('in updatePartnerDetails', obj, 'getEventList ', eventList, '::selectedEvent : ', selectedEvent)
        let _objEvent = eventList.find(e => e.id === obj.EventID);
        setSelectedEvent(_objEvent)
        if (_objEvent) {

            setEntryDetails(obj);
            setPopupLoading(true);
            const partnerListQ = httpsCallable(functions, "getAllPartnerListForEvent");
            setLoading(true);

            // var categoryList = selectedEvent.CategoryDetails;
            // console.log('categoryList : ', categoryList)
            // var cat = categoryList.filter(e => e.CategoryName === obj.CategoryName);
            // console.log('cat : ', cat)

            // console.log('partnerGender', partnerGender);
            let para = {
                EventID: _objEvent.id,
                PlayerID: obj.ParticipantID,
                CategoryName: obj.CategoryName,
                // CategoryDetails: cat[0],

            };
            console.log('para', para);
            await partnerListQ(para)
                .then(async (result) => {
                    console.log('result', result)
                    setPopupLoading(false);
                    setShowPopup(true);
                    let players = []
                    {/* <select name="" onChange={(sel) => {
                            // console.log('sel.target.text:', sel.target.selectedOptions[0].text, ':: sel.target.value:', sel.target.value)
                            setPartnerCode({
                                code: sel.target.value,
                                name: sel.target.selectedOptions[0].text
                            });
                        }}>
                            {partnerList && partnerList.map((e) => {
                                let str = e.Phone ? e.Phone.slice(-4).padStart(e.Phone.length, '*') : '';
                                return <option key={e.PlayerID} value={e.PlayerID}>{e.UserName}-{str}</option>
                            })}
                            
                        </select> */}

                    result && result.data && result.data.forEach(element => {
                        let str = '';// element.Phone ? element.Phone.slice(-4).padStart(element.Phone.length, '*') : '';
                        console.log('element', element)
                        const start = element.Phone ? element.Phone.slice(0, 5) : '';       // First 5 characters
                        const masked = '****';               // Mask the mast next 4 characters
                        const end = element.Phone ? element.Phone.slice(9) : '';
                        // console.log(start, masked, end, start + masked + end)
                        str = start + masked + end;
                        players.push({
                            id: element.PlayerID + "#" + element.UserName + '#' + element.id,
                            searchKey: element.PlayerID + element.UserName + element.Phone,
                            displayText: element.UserName + '-' + str,
                        })

                    });
                    setPartnerList(players);
                })
        }

    }
    function updatePaymentDetailsPopup(obj) {
        console.log('in updatePaymentDetails', obj)
        setEntryDetails(obj);

        setShowPopup(true);

    }
    function withdrawEntryPopup(obj) {
        console.log('in withdrawEntryPopup', obj)
        setEntryDetails(obj);
        setShowPopup(true);
    }
    function resetParam() {
        navigate(-1)
    }
    function setSearchClickedEvent(flag) {
        // console.log('setSearchClickedEvent : ', flag)
        setSearchClicked(flag)
    }
    function confirmActionPopup() {
        if (action === 'UpdatePayment') {
            console.log('paymentRemarks', paymentRemarks, "::", entryDetails);
            setPopupLoading(true);

            const partnerListQ = httpsCallable(functions, "updatePaymentStatusWithRemarks");
            setLoading(true);
            let para = {
                EventID: entryDetails.EventID,
                PlayerID: entryDetails.ParticipantID,
                CategoryList: [entryDetails.CategoryName],
                PaymentStatus: entryDetails.PaymentStatus === 'Completed' ? 'Pending' : 'Completed',
                Remarks: paymentRemarks,
            };
            console.log('para', para, selectedEvent);
            return partnerListQ(para)
                .then(async (result) => {
                    console.log('result', selectedEvent)
                    getParticipantList(selectedEvent.id, searchEvent.EventCode);
                    setPopupLoading(false);
                    setShowPopup(false);
                })
        } else if (action === 'WithdrawEntry' && !withdrawAllEntry) {
            withdrawEntry(entryDetails);
            setShowPopup(false);
        } else if (action === 'WithdrawEntry' && withdrawAllEntry) {
            console.log('WithdrawEntry :: ', withdrawAllEntry)
            withdrawAllEntryFunction();
            setShowConfirmation(true);
            // setShowPopup(false);
        } else if (action === 'UpdatePartner') {
            console.log('WithdrawEntry :: ', withdrawAllEntry)
            updatePartnerinDB();
        }
        // setShowPopup(false);

    }
    async function updatePartnerinDB() {
        setPopupLoading(true);

        const partnerListQ = httpsCallable(functions, "updatePartner");
        console.log('PartnerCode', partnerCode)
        let partnerName = partnerCode.name.split('-');
        console.log('partnerName', partnerName[0])
        let para = {
            EventID: entryDetails.EventID,
            PlayerID: entryDetails.ParticipantID,
            CategoryName: entryDetails.CategoryName,
            PartnerID: partnerCode.code,
            PartnerName: partnerName[0],//partnerCode.name
        };
        console.log('para', para);
        return partnerListQ(para)
            .then(async (result) => {
                console.log('result', result)
                getParticipantList(selectedEvent.id, searchEvent.EventCode);
                setPopupLoading(false);
                setShowPopup(false);
            })
    }
    function cancleActionPopup() {
        setShowPopup(false);
    }
    function setSelectedItemSearchPartner(id, text) {
        console.log(id, text)
        let idlist = id.split("#");
        console.log(idlist)
        setPartnerCode({
            code: idlist[0],
            name: idlist[1]
        });
    }
    return (
        <div className="container"><br /><br />
            <div className='fixed-settings-breadcrum'>
                <div>
                    <span style={{ fontSize: '1.8rem' }} className="material-symbols-outlined">
                        person
                    </span>
                </div>
                <span style={{ position: 'relative', top: '-2px' }} onClick={resetParam} >  Profile </span>
                {!resetFlag && <span style={{ position: 'relative', top: '-2px' }}> &lt; </span>}
                <span className='active' style={{ position: 'relative', top: '-2px' }}> {
                    action === 'ParticipantList' ? 'Download Participant' :
                        action === 'UploadEntry' ? 'Upload Entry' :
                            action === 'UpdatePartner' ? 'Update Partner' :
                                action === 'UpdatePayment' ? 'Update Payment' :
                                    action === 'WithdrawEntry' ? 'Withdraw Entry' :
                                        'Event'} </span>
                <span style={{ position: 'relative', top: '-2px' }}> {!resetFlag && expandParam} </span>

            </div>
            <br></br>

            {/* {showExportLoading && <Loading></Loading>} */}

            <div className={showExportLoading ? 'pop-up-div open' : 'pop-up-div'}>
                <Loading></Loading>
            </div >
            {showEventList && <div className='row no-gutters'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className='event-search-div'>
                        <div style={{ maxWidth: '400px' }}>
                            <input type="text" id="userName" style={{
                                boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
                                border: '1px solid #ddd'
                            }} placeholder='e.g. event name or location etc' required
                                onChange={(e) => {
                                    setSearchKey(e.target.value)
                                    searchEvent()

                                }} value={searchKey} />
                            <button className="mybutton button5" onClick={() => {
                                searchEvent();

                            }}>
                                <span className="material-symbols-outlined">
                                    search
                                </span>
                            </button>
                        </div>
                    </div>
                    <br></br>
                    {loading && <Loading></Loading>}
                </div>
            </div>}
            {showEventList && <div className='row no-gutters'>

                {eventListFilter.map((obj) => {
                    return <div className='col-lg-4 col-md-6 col-sm-12' key={obj.EventCode}>
                        <div className="event-list-card" style={{ height: '120px', borderColor: 'green' }} >
                            <div className="">
                                <h1> {obj.EventName}</h1>
                                <h2>{obj.OrganizationName}</h2>
                                <h3>{obj.EventSDate} - {obj.EventEDate} | {obj.City}</h3>
                                {/* <br></br> */}
                                <button className="mybutton button5"
                                    onClick={() => {
                                        console.log('action', action)
                                        getParticipantList(obj.id, obj.EventCode);
                                    }}
                                    style={{ margin: '5px 0 0 0', cursor: 'pointer' }}>


                                    {action === 'ScheduleSetup' ? 'Update Scheudle' :
                                        action === 'SetupDraw' ? 'Setup Draw' :
                                            action === 'UpdatePartner' ? 'Update Partner' :
                                                action === 'UpdatePayment' ? 'Update Payment Status' :
                                                    action === 'WithdrawEntry' ? 'Withdraw Entry' :
                                                        action === 'ParticipantList' ? 'Get Participant List' :
                                                            action === 'UploadEntry' ? 'Upload Entry' :
                                                                action === 'MatchSetup' ? 'Match Setup' :
                                                                    action === 'UpdateMatch' ? 'Update Match' : ''}
                                </button>

                            </div>

                            <div className="event-id" style={{ width: 'auto' }}>
                                <h5>{obj.EventCode}</h5>
                            </div>
                            <div>
                                <hr></hr>
                            </div>
                        </div>
                        <br></br>

                    </div>
                }
                )}
            </div>
            }
            {!showEventList &&
                <div className=''>
                    <div
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => {
                            setShowEventList(true);
                        }}>
                        <span className="material-symbols-outlined" style={{ paddingRight: '10px', position: 'relative', top: '-5px' }}>
                            arrow_back
                        </span>
                        <h1 style={{ fontSize: '1.2rem', fontWeight: '500' }}>
                            Go Back to Event List
                        </h1>
                    </div>

                    <span style={{ color: '#348DCB' }}>
                        {(action === 'ParticipantList') ? ' Get participant List for ' :
                            (action === 'UpdatePayment') ? ' Update Payment Status for ' :
                                (action === 'WithdrawEntry') ? ' Withdraw Entry for ' :
                                    (action === 'UploadEntry') ? ' Upload Participant Entry for ' :
                                        (action === 'UpdatePartner') ? ' Update entry for partner details for ' :
                                            (action === 'SetupDraw') ? ' Setup Draw for ' :
                                                (action === 'MatchSetup') ? ' Update Match Status for ' :
                                                    (action === 'ScheduleSetup') ? ' Update Schedule for ' :
                                                        (action === 'UpdateMatch') ? ' Update Match status for ' : ''}
                        <strong>{selectedEvent && selectedEvent.EventName}</strong> - <strong>{selectedEvent && selectedEvent.EventCode}</strong>
                    </span>

                </div>
            }
            {!showEventList && action === 'UploadEntry' &&
                <>
                    <hr></hr>
                    <div className='steps-div'>
                        <h1>Steps to Follow : </h1>
                        <div>
                            <span><strong>Step 1 :</strong> First download the entry format</span>
                        </div>
                        <div>
                            <span><strong>Step 2 :</strong> Select the file</span>
                        </div>
                        <div>
                            <span><strong>Step 3 :</strong> Upload the file</span>
                        </div>
                    </div>

                    <hr></hr>

                    <div className='row no-gutters'>
                        <div className='col-lg-6 col-md-6 col-sm-12' onClick={() => {
                            console.log('selectedEvent', selectedEvent);
                            exportExcel(entryTemplate, selectedEvent.EventCode + "_ParticipantToUpload");

                        }}>
                            {/* <span>Download Template </span> */}
                            <br></br>

                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
                                <div className='upload-entry-card' style={{ paddingTop: '0', background: '#fff' }}>
                                    {/* <img src="./img/upload_entry.png" alt="" /> */}
                                    <div className='upload-entry-card-details'>
                                        {/* <div className='upload-entry-card-details-icon'>
                                            <img src="./img/icon_excel.png" alt="" />
                                        </div> */}
                                        <div>
                                            <h1>Download_Entry_Template</h1>
                                            <div>
                                                <span>1 Page</span>
                                                <span>16 kb</span>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <center>
                                        <div
                                            style={{
                                                background: '#348DCB',
                                                padding: '5px 30px',
                                                color: '#fff',
                                                borderRadius: '20px',
                                                cursor: 'pointer',
                                                width: '250px',
                                                textAlign: 'center',
                                            }}>Download Template</div>
                                    </center>
                                </div>

                            </div>

                            <br className='small'></br>
                            <hr className='small'></hr>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>

                            <div>Upload Entry

                                <ExcelToJson setJSON={setJSON}></ExcelToJson>

                            </div>


                        </div>

                    </div>
                </>
            }
            {!showEventList && action === 'WithdrawEntry' &&
                <div className='row no-gutters'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='event-detail-input-div'>
                            <select onChange={(e) => {
                                console.log('e.target', e.target.value);
                                setSelectedCategory(e.target.value)
                            }} className=""
                                style={{ letterSpacing: '1px', borderRadius: '5px' }} name="">
                                {selectedEvent.CategoryDetails.map((cat) => {
                                    return <option value={cat.CategoryName} key={cat.CategoryName}>{cat.CategoryName}</option>
                                })}

                            </select>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='event-detail-input-div' onClick={() => {
                            console.log('clicked', action);

                            setWithdrawAllEntry(true)
                            setShowPopup(true);
                        }}>
                            <button className="mybutton button5"
                                style={{
                                    pointerEvents: 'none',
                                    position: 'relative',
                                }}>
                                <span >Withdraw All entry from : <strong>{selectedCategory}</strong></span>

                            </button>
                        </div>
                    </div>
                </div>

            }
            {!showEventList && action === 'SetupDraw' &&
                <div className='row no-gutters'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        {/* <div className='mybutton button5' onClick={() => {
                            console.log('in Set up Draw ::selectedEvent.CategoryDetails :  ', selectedEvent.CategoryDetails, '::participantList : ', participantList,
                                ":: selectedEvent.Eventid : ", selectedEvent.Eventid,
                                ":: selectedEvent : ", selectedEvent)
                            setDraw(true);
                        }}>Setup Draw</div> */}
                        {/* <br /> */}
                        <br />
                        {draw && <EventSetupDraw ParticipantList={participantList} EventID={selectedEvent.id} EventDetails={selectedEvent}></EventSetupDraw>

                        }
                    </div>
                </div>

            }
            {!showEventList && action === 'MatchSetup' &&
                <div className='row no-gutters'>
                    {/* <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div onClick={() => {
                            console.log('in MatchSetup')
                            setDraw(true);
                        }}>Match Setup</div>
                    </div> */}
                    {draw && <MatchScheduleSetup
                        // Category={selectedEvent.CategoryDetails}
                        // ParticipantList={participantList}
                        EventID={selectedEvent.id}
                        EventDetails={selectedEvent}
                    ></MatchScheduleSetup>

                    }
                </div>

            }
            {!showEventList && action === 'ScheduleSetup' &&
                <div className='row no-gutters'>
                    {/* <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div onClick={() => {
                            console.log('in ScheduleSetup')

                            setScheduleSetup(true);
                        }}>Schedule Setup</div>
                    </div> */}
                    {scheduleSetup && <MatchScheduleSetup
                        // Category={selectedEvent.CategoryDetails}
                        //  ParticipantList={participantList}
                        EventID={selectedEvent.id}
                        EventDetails={selectedEvent}></MatchScheduleSetup>

                    }
                </div>

            }

            {!showEventList && action === 'UpdateMatch' &&
                <div className='row no-gutters'>
                    {matchUpdate && <UpdateMatch
                        EventID={selectedEvent.id}
                        EventDetails={selectedEvent}></UpdateMatch>

                    }
                </div>

            }
            <br></br>
            {(!showEventList && (action === 'UpdatePartner' || action === 'UpdatePayment' || action === 'WithdrawEntry')) && <>
                <div className='row no-gutters'>
                    {loadingParticipant && <Loading></Loading>}
                    <div className='event-search-div'>
                        <div>
                            <input type="text" id="userName" placeholder='search player name, player code ' required
                                onChange={(e) => {
                                    setSearchKeyPlayer(e.target.value)
                                    searchPlayer()

                                }} value={searchKeyPlayer} />
                            <button className="mybutton button5" onClick={() => {
                                searchPlayer();

                            }}>
                                <span className="material-symbols-outlined">
                                    search
                                </span>
                            </button>
                        </div>
                    </div>
                    {participantListSearch && participantListSearch.map((obj) => {

                        // console.log('selectedEvent.WithdrawalEndDate', selectedEvent.WithdrawalEndDate, ' obj.RegType ', obj.RegType);
                        refdate = (selectedEvent && selectedEvent.WithdrawalEndDate) ? new Date(selectedEvent.WithdrawalEndDate._seconds * 1000) : null
                        // console.log('refdate : ', refdate);
                        // return ((action === 'UpdatePartner' && obj.EventType !== 'Partner' && obj.PartnerPlayerID !== '')
                        return ((action === 'UpdatePartner' && obj.EventType !== 'SINGLE')

                            || (action === 'UpdatePayment') || (action === 'WithdrawEntry')) ? <div className='col-lg-4 col-md-6 col-sm-12' key={obj.CategoryName + obj.ParticipantID} >
                            <div style={{ padding: '10px' }} onClick={() => {
                                if (action === 'UpdatePartner') {
                                    updatePartnerDetailsPopup(obj);
                                } else if (action === 'UpdatePayment') {
                                    updatePaymentDetailsPopup(obj);
                                } else if (action === 'WithdrawEntry') {
                                    withdrawEntryPopup(obj);
                                }

                            }}>

                                <div className={obj.PaymentStatus.toUpperCase() === 'PENDING' ?
                                    "reg-category-card active payment-pending" : "reg-category-card active payment-completed"}
                                    style={{ height: '160px' }}>

                                    <div className="display-flex-div">
                                        <div className="category-details">
                                            <h1>{obj.CategoryName}</h1>
                                            {<h3>
                                                <strong>Player : </strong>{obj.ParticipantName}
                                            </h3>}
                                            <h3 style={{ opacity: obj.PartnerPlayerID !== '' ? '1' : '0' }}>
                                                <strong>Partner : </strong>{obj.PartnerPlayerName}
                                                {/* {obj.RegType === 'Partner' ? ' (registered by Partner)' : ''} */}
                                            </h3>


                                            {action === 'UpdatePayment' && <button className="mybutton button5"
                                                style={{
                                                    margin: '0',
                                                    pointerEvents: 'none',
                                                    position: 'relative',
                                                    top: '15px',
                                                    height: '25px',
                                                    width: '120px'
                                                }}>
                                                {entryDetails && entryDetails.ParticipantID === obj.ParticipantID &&
                                                    entryDetails.CategoryName === obj.CategoryName && showPopup ? <div className='btn-loading' style={{ display: true ? 'block' : 'none' }}>
                                                    <lottie-player id="btnSendOTPLoad" style={{ position: 'relative', top: '-3px' }}
                                                        src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                                        speed="0.7" loop autoplay></lottie-player>
                                                </div> : <span style={{ fontSize: '0.8rem', position: 'relative', top: '-4px' }}>Change Payment</span>}
                                                {/* <span style={{ fontSize: '0.8rem', position: 'relative', top: '-4px' }}>Change Payment</span> */}


                                            </button>}


                                            {/* {action === 'UpdatePayment' && <div className="mybutton button5"
                                                style={{ margin: '0', pointerEvents: 'none' }}>
                                                <input type="text" onChange={()=>{
                                                    
                                                } } value='in payment'></input>
                                                

                                            </div>} */}
                                            {action === 'UpdatePartner' &&
                                                <button className="mybutton button5"
                                                    style={{
                                                        margin: '0',
                                                        pointerEvents: 'none',
                                                        position: 'relative',
                                                        top: '15px',
                                                        height: '25px',
                                                        width: '120px'
                                                    }}>

                                                    {entryDetails && entryDetails.ParticipantID === obj.ParticipantID &&
                                                        entryDetails.CategoryName === obj.CategoryName && showPopup ? <div className='btn-loading' style={{ display: true ? 'block' : 'none' }}>
                                                        <lottie-player id="btnSendOTPLoad" style={{ position: 'relative', top: '-3px' }}
                                                            src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                                            speed="0.7" loop autoplay></lottie-player>
                                                    </div> : <span style={{ fontSize: '0.8rem', position: 'relative', top: '-4px' }}>Update Partner</span>}
                                                    {/* <span style={{ fontSize: '0.8rem', position: 'relative', top: '-4px' }}>Update Partner</span> */}

                                                </button>}

                                            {action === 'WithdrawEntry' && refdate > new Date() && <button className="mybutton button5"
                                                style={{
                                                    margin: '0',
                                                    pointerEvents: 'none',
                                                    position: 'relative',
                                                    top: '15px',
                                                    height: '25px',
                                                    width: '120px'
                                                }} >
                                                <span style={{ fontSize: '0.8rem', position: 'relative', top: '-4px' }}>Withdraw Event</span>

                                            </button>}


                                        </div>

                                        <div className="category-fees">
                                            <h2 style={{ position: 'relative', top: '5px' }}><span>₹ </span>
                                                <span>{obj.Fees}</span>
                                            </h2>
                                        </div>

                                    </div>

                                    {obj.PaymentStatus.toUpperCase() === 'PENDING' &&
                                        <div className="payment-status pending" style={{ position: 'absolute', bottom: '0' }}>
                                            <h1>Payment Pending</h1>
                                        </div>
                                    }
                                    {obj.PaymentStatus.toUpperCase() === 'COMPLETED' &&
                                        <div className="payment-status completed" style={{ position: 'absolute', bottom: '0' }}>
                                            <h1>Payment Completed</h1>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                            :
                            null

                    })}

                </div>
            </>}

            <div className={showPopup ? 'pop-up-div open' : 'pop-up-div'}>
                {popupLoading && <Loading></Loading>}
                {/* {console.log('entryDetails', entryDetails)} */}
                {action === 'WithdrawEntry' && !withdrawAllEntry && entryDetails && <div className='pop-up-div-inner'>
                    <h1 >
                        Are you sure you wish to withdraw for <br />
                        Event : <strong> {selectedEvent.EventName} </strong><br></br>
                        Category : <strong>{entryDetails.CategoryName}</strong> <br></br>
                        Player : <strong> {entryDetails.ParticipantName}</strong> {(entryDetails.PartnerPlayerName && entryDetails.PartnerPlayerName !== '' && <strong> - {entryDetails.PartnerPlayerName}</strong>)}
                    </h1>


                    <hr />

                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={confirmActionPopup}>
                            <span>CONFIRM</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={cancleActionPopup}>
                            <span>Cancel</span>
                        </button>
                    </div>

                </div>}
                {action === 'WithdrawEntry' && withdrawAllEntry && !showConfirmation && <div className='pop-up-div-inner'>
                    <h1 >
                        Are you sure you wish to withdraw all entry for <br />
                        Event : <strong> {selectedEvent.EventName} </strong><br></br>
                        Category : <strong>{selectedCategory}</strong> <br></br>
                    </h1>


                    <hr />

                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={confirmActionPopup}>
                            <span>CONFIRM</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={cancleActionPopup}>
                            <span>Cancel</span>
                        </button>
                    </div>

                </div>}
                {action === 'WithdrawEntry' && withdrawAllEntry && showConfirmation && <div className='pop-up-div-inner'>
                    <h1 >
                        Entry withdrawn for  <br />
                        Event : <strong> {selectedEvent.EventName} </strong><br></br>
                        Category : <strong>{selectedCategory}</strong> <br></br>
                    </h1>


                    <div className='btn-div'>
                        <button className='mybbutton button5' style={{ alignContent: 'center' }} onClick={() => {
                            setShowConfirmation(false);
                            setWithdrawAllEntry(false);
                            setShowPopup(false);
                        }}>
                            <span>OK</span>
                        </button>

                    </div>

                </div>}
                {action === 'UpdatePartner' && !popupLoading && <div className='pop-up-div-inner'>

                    <h1>Change Partner <strong>{entryDetails && entryDetails.CategoryName}</strong> for <strong>{entryDetails && entryDetails.ParticipantName}</strong></h1>
                    <hr />

                    <div className='event-detail-input-div' style={{ padding: '0 30px' }}>
                        <label htmlFor="">Select Partner</label>
                        {/* <select name="" onChange={(sel) => {
                            // console.log('sel.target.text:', sel.target.selectedOptions[0].text, ':: sel.target.value:', sel.target.value)
                            setPartnerCode({
                                code: sel.target.value,
                                name: sel.target.selectedOptions[0].text
                            });
                        }}>
                            {partnerList && partnerList.map((e) => {
                                let str = e.Phone ? e.Phone.slice(-4).padStart(e.Phone.length, '*') : '';
                                return <option key={e.PlayerID} value={e.PlayerID}>{e.UserName}-{str}</option>
                            })}
                            
                        </select> */}

                        <SearchComponents
                            ItemList={partnerList}
                            width='100%'
                            placeholder={'search partner...'}
                            setSelectedItem={setSelectedItemSearchPartner}
                            setSearchClicked={setSearchClickedEvent}
                        ></SearchComponents>
                    </div>

                    <div className='event-detail-input-div' style={{ padding: '0 30px' }}>
                        {/* <label htmlFor="">Input</label> */}
                        <br></br>
                        <input type="text" value={'Partner will be changed to ' + partnerCode.name} />
                    </div>
                    {searchClicked && <div style={{ height: '140px' }}></div>}
                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={confirmActionPopup}>
                            <span>CONFIRM</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={cancleActionPopup}>
                            <span>Cancel</span>
                        </button>
                    </div>

                </div>}
                {action === 'UpdatePayment' && !popupLoading && <div className='pop-up-div-inner'>

                    <h1>Update Payment Status for  <strong>{entryDetails && entryDetails.CategoryName}</strong> for <strong>{entryDetails && entryDetails.ParticipantName}</strong></h1>
                    <hr />

                    <div className='event-detail-input-div' style={{ padding: '0 30px' }}>
                        <label htmlFor="">Select Remarks</label>
                        {entryDetails && entryDetails.PaymentStatus === 'Pending' && <select name="" onChange={(e) => {
                            setPaymentRemarks(e.target.value);
                            console.log('e.target.value', e.target.value);
                        }}>
                            <option value="Offline Payment">Offline Payment</option>
                            <option value="Payment Reconcilation">Payment Reconcilation</option>
                            <option value="Wrong Update">Wrong Update</option>

                        </select>
                        }
                        {entryDetails && entryDetails.PaymentStatus === 'Completed' && <select name="" onChange={(e) => {
                            setPaymentRemarks(e.target.value);
                            console.log('e.target.value', e.target.value);
                        }}>
                            <option value="Payment Reconcilation">Payment Reconcilation</option>
                            <option value="Wrong Update">Wrong Update</option>

                        </select>
                        }
                    </div>

                    <div className='event-detail-input-div' style={{ padding: '0 30px' }}>
                        {/* <label htmlFor="">Input</label>
                        <br />
                        <input type="text" /> */}
                        <span style={{ textAlign: 'center' }}>payment status will be updated as <strong>{entryDetails && entryDetails.PaymentStatus === 'Completed' ? 'Pending' : 'Completed'}</strong></span>
                    </div>

                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={confirmActionPopup}>
                            <span>CONFIRM</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={cancleActionPopup}>
                            <span>Cancel</span>
                        </button>
                    </div>

                </div>}

            </div>

        </div >

    )
}
