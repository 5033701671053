import React, { useEffect, useState } from 'react'

export default function SearchComponents(props) {
    // props.ItemList - List of Items to be searched in
    // props.placeholder
    // function need to have props.setSelectedItem(itemId);
    // function need to have setSearchClicked(flag)
    // itemList should have member as id, searchKey , displayText
    // console.log('props : ', props)
    const [showList, setShowList] = useState(false);

    const [filteredList, setFilteredList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemList, setItemList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    useEffect(() => {
        setFilteredList(props.ItemList);
        setItemList(props.ItemList);

    }, [props])

    const handleSearchChange = (event) => {
        props.setSearchClicked(true)

        setShowList(true)
        const query = event.target.value;
        // console.log('query ', query)
        setSearchQuery(query);
        filterList(query);
    };

    const filterList = (query) => {
        // console.log('query: ', query)
        const filtered =
            itemList &&
            itemList.filter(
                (item) =>
                    item.searchKey.toLowerCase().includes(query.toLowerCase())

            );
        // console.log('filtered: ', filtered)
        setFilteredList(filtered);
    };

    const handleItemSelect = (itemId, itemDisplay) => {
        // console.log('itemId, itemDisplay', itemId, itemDisplay)
        setSearchQuery(itemDisplay)
        setShowList(false);
        props.setSelectedItem(itemId, itemDisplay);
        props.setSearchClicked(false)

    };

    // console.log('filteredList', filteredList)
    return (
        <div style={{ width: props.width ? props.width : '60%', position: 'relative' }}>
            <input
                style={{
                    outline: 'none', border: '1px solid #aaa',
                    borderRadius: '8px',
                    background: "#efefef",
                    height: props.height ? props.height : "40px",
                    width: "100%", paddingLeft: "16px"
                }}
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                onClick={() => {
                    props.setSearchClicked(!showList)
                    setShowList(!showList)
                }}
                // onBlur={() => {
                //     setShowList(false)
                // }}
                placeholder={props.placeholder} //"Search users by mobile no or name..."
            />

            <ul
                style={{
                    borderRadius: '0 0 10px 10px',
                    maxHeight: '200px',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
                    overflow: 'auto',
                    position: 'absolute',
                    zIndex: '999',
                    width: '100%',
                    listStyleType: 'none',
                    padding: "0 0 0 0"
                }}>
                {/* <li className='update-event-ul-li' key={'newKey'}>
                    <label>
                        <input
                            style={{ width: "10%" }}
                            name="selectedList"
                            // type="checkbox"
                            // checked={selectedUsers.includes(user.id)}
                            type="radio"
                            checked={selectedItem === ''}
                            onChange={() => handleItemSelect('', '')}
                        />
                        {''}
                    </label>
                </li> */}
                {showList && filteredList &&
                    filteredList.map((item) => (
                        <li className='update-event-ul-li' key={item.id}>
                            <label>
                                <input
                                    style={{ width: "10%" }}
                                    name="selectedList"
                                    // type="checkbox"
                                    // checked={selectedUsers.includes(user.id)}
                                    type="radio"
                                    checked={selectedItem === item.id}
                                    onChange={() => handleItemSelect(item.id, item.displayText)}
                                />
                                {item.displayText}
                            </label>
                        </li>
                    ))}
            </ul>
        </div>

    )
}
