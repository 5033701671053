import React, { useEffect } from 'react'
import DatePicker from "react-datepicker";
import '../css/MydatePicker.css'
import { useState } from 'react';
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'


export default function EditEventDetails(props) {
    console.log('props', props)
    const [loading, setLoading] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [isEditEnable, setIsEditEnable] = useState(props.IsEditEnabled)
    async function saveEventDetails() {
        console.log('in saveEventDetails');
        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            EventName: props.eventDetails.EventName,

            OrganizationName: props.eventDetails.OrganizationName,
            EventVenue: props.eventDetails.EventVenue,
            VenueContact: props.eventDetails.VenueContact,
            LocationMap: props.eventDetails.LocationMap,
            RegistrationStartDate: props.eventDetails.RegistrationStartDate,
            RegistrationEndDate: props.eventDetails.RegistrationEndDate,
            WithdrawalEndDate: props.eventDetails.WithdrawalEndDate,
            EventURL: props.eventDetails.EventURL,
            EventStartDate: props.eventDetails.EventStartDate,
            EventEndDate: props.eventDetails.EventEndDate,
            ConvenienceCharge: props.eventDetails.ConvenienceCharge,
            MaxEntryForParticipant: props.eventDetails.MaxEntryForParticipant,

        };
        // console.log(para);
        setLoading(true);

        const ret = await httpsCallable(functions, "updateEventBasicDetails");
        ret(para1).then(async (result) => {

            setLoading(false);
            setResultMessage('Event Details Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }
    useEffect(() => {
        setIsEditEnable(props.IsEditEnabled);
    }, [])
    return (
        <div className='event-setup-details-section'>
            <div className="row no-gutters">
                <div className="event-detail-input-div" style={{ padding: '0 20px' }}>
                    <h1>Event Details</h1><br />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label>Organization / Club</label>
                        <input type="text" id="organizationName" onChange={(e) =>
                            props.setEventDetails({
                                ...props.eventDetails,
                                OrganizationName: e.target.value
                            })} placeholder="Organization Name..."
                            required
                            value={props.eventDetails.OrganizationName} />

                    </div>

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label >Event Name</label>
                        <input type="text" id="eventNametb"
                            onChange={(e) =>
                                props.setEventDetails({
                                    ...props.eventDetails,
                                    EventName: e.target.value
                                })}
                            value={props.eventDetails.EventName}
                            placeholder="Enter Here..." required />
                    </div>

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label>Venue Address</label>
                        <input type="text" id="venueName"
                            onChange={(e) =>
                                props.setEventDetails({
                                    ...props.eventDetails,
                                    EventVenue: e.target.value
                                })}
                            value={props.eventDetails.EventVenue}
                            placeholder="Full Address..." required />
                    </div>

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label>Venue POC Name & Contact</label>
                        <input type="text" id="vanueContact"
                            onChange={(e) =>
                                props.setEventDetails({
                                    ...props.eventDetails,
                                    VenueContact: e.target.value
                                })}
                            value={props.eventDetails.VenueContact}
                            placeholder="Name & Contact Here..."
                            required />
                    </div>

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label >Venue Map Google Link</label>
                        <input type="text" id="vanueMap"
                            onChange={(e) =>
                                props.setEventDetails({
                                    ...props.eventDetails,
                                    LocationMap: e.target.value
                                })}
                            value={props.eventDetails.LocationMap}
                            placeholder="Enter Map URL..." required />
                    </div>

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label >Registration Open Date</label>
                        <div className="input-group date datetimepicker11">

                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                dateFormat="dd-MMM-yyyy"

                                selected={props.eventDetails.RegistrationStartDate} onChange={(date) => {
                                    // console.log(date)
                                    props.setEventDetails({
                                        ...props.eventDetails,
                                        RegistrationStartDate: new Date(date)
                                    })
                                }} />

                        </div>
                    </div>

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label>Registration Closed Date</label>
                        <div className="input-group date datetimepicker11">
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                dateFormat="dd-MMM-yyyy"
                                selected={props.eventDetails.RegistrationEndDate} onChange={(date) => {
                                    // console.log(date)
                                    props.setEventDetails({
                                        ...props.eventDetails,
                                        RegistrationEndDate: new Date(date)
                                    })
                                }} />
                        </div>
                    </div>

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label >Registration Withdraw Date</label>
                        <div className="input-group date datetimepicker11">
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                dateFormat="dd-MMM-yyyy"
                                selected={props.eventDetails.WithdrawalEndDate} onChange={(date) => {
                                    // console.log(date)
                                    props.setEventDetails({
                                        ...props.eventDetails,
                                        WithdrawalEndDate: new Date(date)
                                    })
                                }} />
                        </div>
                    </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label>Event Start Date</label>
                        <div className="input-group date datetimepicker11">
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                dateFormat="dd-MMM-yyyy"
                                selected={props.eventDetails.EventStartDate} onChange={(date) => {
                                    // console.log(date)
                                    props.setEventDetails({
                                        ...props.eventDetails,
                                        EventStartDate: new Date(date)
                                    })
                                }} />
                        </div>
                    </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label>Event End Date</label>
                        <div className="input-group date datetimepicker11">
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                dateFormat="dd-MMM-yyyy"
                                selected={props.eventDetails.EventEndDate} onChange={(date) => {
                                    // console.log(date)
                                    props.setEventDetails({
                                        ...props.eventDetails,
                                        EventEndDate: new Date(date)
                                    })
                                }} />
                        </div>
                    </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="event-detail-input-div">
                        <label>Convenience Charge</label>
                        <input type="number" placeholder="Convenience Charge"
                            min={5}
                            onChange={(e) => {
                                if (Number(e.target.value) < 5) {
                                    props.setEventDetails({
                                        ...props.eventDetails,
                                        ConvenienceCharge: 5
                                    })
                                } else {
                                    props.setEventDetails({
                                        ...props.eventDetails,
                                        ConvenienceCharge: Number(e.target.value)
                                    })
                                }
                            }
                            }
                            value={props.eventDetails.ConvenienceCharge}
                            required />

                    </div>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">

                    <div className="event-detail-input-div">
                        <label>MaxEntryLimit by One participant</label>
                        <input id="maxEntry" type="number" className="" onChange={(e) =>
                            props.setEventDetails({
                                ...props.eventDetails,
                                MaxEntryForParticipant: e.target.value
                            })}
                            value={props.eventDetails.MaxEntryForParticipant} required />
                    </div>

                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">

                    <div className="event-detail-input-div">
                        <label>Event Link (in case needs to be redirected)</label>
                        <div className="input-group date datetimepicker11">
                            <input id="eventURL" placeholder='Event registration URL, if it outside TPLiVE' className="" onChange={(e) =>
                                props.setEventDetails({
                                    ...props.eventDetails,
                                    EventURL: e.target.value
                                })}
                                value={props.eventDetails.EventURL} required />

                        </div>
                    </div>

                </div>
                {/* 
                <div id="errorMsg" style={{ display: 'none' }} className="col-lg-6 col-md-6 col-sm-12"><br />

                    <div className="event-detail-input-div">
                        <div></div>
                        <span id="errorMssage" onChange={(e) =>
                            setError(e.target.value)}
                            value={error}></span>
                    </div>

                </div> */}
                <div className="col-lg-6 col-md-6 col-sm-12"><br />

                    <div className="event-details-save-btn-div">
                        <div></div>
                        {/* <button className="mybutton button5 event-details-save-btn"
                            onClick={saveEventDetails} type="button">SAVE</button> */}


                        <button className="mybutton button5" onClick={saveEventDetails} style={{ width: '150px', height: '40px' }}>
                            <div style={{ display: !loading ? 'block' : 'none' }}>
                                <span
                                    style={{ position: 'relative', top: '-1px', fontSize: '1rem' }}>SAVE</span>
                            </div>
                            <div className='btn-loading' style={{ display: loading ? 'block' : 'none' }}>
                                <lottie-player
                                    src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                    speed="0.7" loop autoplay></lottie-player>
                            </div>
                        </button>

                    </div>

                </div>
                <div className="col-lg-12 col-md-12 col-sm-12"><br />
                    <div className="event-details-save-btn-div">
                        {resultMessage}
                    </div><br />

                </div>


            </div>
        </div>
    )
}
