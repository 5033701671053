import React, { useEffect, useState } from "react";

import PGDashboardParticipant from "./PGDashboardParticipant";
import { useLocalStorage } from "../context/useLocalStorage";
import SignIn from '../components/SignIn';
import { useDocument } from '../hooks/useDocument.js';
import { useUserAuth } from '../context/UserAuthcontext';

const PGDashboard = () => {

    const { user, users, logout } = useUserAuth();
    const userid = user && user.userInfo ? user.userInfo.uid : 'test'
    // console.log('userid', userid)
    // const { document: userProfileDoc, error: userProfileDocError } = useDocument('UserList', userid)
    const [signinFlag, setSigninFlag] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);

    useEffect(() => {
        if (!isNewUser) {

            // setUserDetails(userProfileDoc)

            if (user.isLoggedIn) {
                if (user.userInfo) {

                    setSigninFlag(true)
                    // console.log('userDetails: ', userDetails);
                    setSigninFlag(true)
                    // setFlagLoad(true);
                }
            }
        }

    }, [user])
    // }, [user, userProfileDoc])
    function setSignIn(flag, userData) {
        console.log(flag, userData)
        // setUserDetails(userData);
        setSigninFlag(flag);

    }

    return (
        <>
            {!signinFlag && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>}

            {signinFlag && <div>
                <PGDashboardParticipant
                // EventCnt={totalEvent}
                // OwnerCnt={ownedEvent}
                // ActiveCnt={activeCount}
                // CompletedCount={completedCount}
                // CancelledCount={cancelledCount}
                // OnholdCount={onholdCount}
                // EventList={eventList}
                // UnpaidCount={unpaidCount}
                />
            </div>}
            {/* <div>
                {user && user.role.toLowerCase() === 'participant' && <ParticipantDashboard />}
            </div>
            <div>
                {user && user.role.toLowerCase() === 'admin' && <AdminDashboard />}
            </div> */}

        </>
    );
};

export default PGDashboard;
