import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'
import { Alert } from 'react-bootstrap';
import RegistrationPackage from '../components/RegistrationPackage.js';
import '../css/StepProgress.css';
import PGCreateEventPayment from './PGCreateEventPayment'
import useDocumentUpdate from '../hooks/useDocumentUpdate.js';
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
function PGCreateEvent() {

    const { state } = useLocation();
    const { createStage } = state;

    const [eventName, setEventName] = useState('')
    const [eventStartDate, setEventStartDate] = useState(new Date())
    const [city, setCity] = useState('Pune')
    const [sportCode, setSportCode] = useState('')
    const [organizationName, setOrganizationName] = useState('')
    const [sportName, setSportName] = useState('')
    const [sportImage, setSportImage] = useState("")
    const [isAdmin, setIsAdmin] = useState(false)
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showSection, setShowSection] = useState(createStage)
    const [eventID, setEventID] = useState('')


    const { user, logout } = useUserAuth();
    // const [userDetails, setUserDetails] = useLocalStorage('userProfile', null);
    const [phone, setPhone] = useState('');

    const navigate = useNavigate();
    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();

    const queryConstraints = [
        where('CreatedBy', '==', user && user.userInfo ? user.userInfo.uid : ' '),
        where('ApprovalStatus', '==', 'RegistrationPending')
        // Add more query constraints as needed
    ];

    const { documents: unpaidEvent, error: unpaidEventError } = useCollection('EventList', queryConstraints)
    console.log('unpaidEvent', queryConstraints, unpaidEvent, unpaidEventError)

    const selectSport = (sportname, sportCode, imgURL) => {
        setShowSection('details');
        setSportCode(sportCode);
        setSportName(sportname);
        setSportImage(imgURL)
    }
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    useEffect(() => {
        setShowSection(createStage);
    }, [createStage])

    useEffect(() => {
        console.log(unpaidEvent);
        if (unpaidEvent && unpaidEvent[0]) {
            setEventID(unpaidEvent[0].id);
            setEventName(unpaidEvent[0].EventName);
            setSportCode(unpaidEvent[0].SportCode);
            setSportName(unpaidEvent[0].SportName);
            setOrganizationName(unpaidEvent[0].OrganizationName);
            setCity(unpaidEvent[0].City);
            // setEventStartDate(new Date(unpaidEvent[0].EventStartDate.seconds );

        }
    }, [unpaidEvent])

    useEffect(() => {
        setPhone(user && user.userInfo && user.userInfo.Phone ? user.userInfo.Phone : '');
        if (user && user.userInfo && user.userInfo.UserRole.find(e => e.TYPE.toLowerCase() === 'admin')) {
            setIsAdmin(true)
        }

    }, [user])


    async function saveEvent() {
        let error = 'Please enter '
        let flag = false;
        setLoading(true)
        if (eventName === '') {
            error = error.concat(' Event Name')
            flag = true
        }
        if (city === '') {
            error = flag && error.concat(",")
            error = error.concat(' City')
            flag = true
        }
        if (organizationName === '') {
            error = flag && error.concat(",")
            error = error.concat(' Organization')
            flag = true
        }
        if (flag) {
            setShowError(true)
            setErrorMessage(error)


            setTimeout(function () {
                setShowError(false)
                setErrorMessage('')

            }, 3000);
            setLoading(false)
        }
        else {
            console.log('eventID', eventID)
            if (eventID === '') {

                var para1 = {};
                para1 = {
                    EventName: eventName,
                    EventStartDate: new Date(eventStartDate),
                    City: city,
                    SportCode: sportCode,
                    SportName: sportName,
                    OrganizationName: organizationName,
                    UserID: user.userInfo.uid,
                    EventOwnerName: isAdmin ? '' : user.userInfo.UserName,
                    EventOwnerEmail: isAdmin ? '' : user.userInfo.Email,
                    EventOwnerPhone: isAdmin ? phone : user.userInfo.Phone,
                };
                console.log('para1', para1)

                const ret = await httpsCallable(functions, "CreateEvent");
                ret(para1).then(async (result) => {
                    console.log(result)
                    setLoading(false)
                    setShowSection('payment')
                    console.log('result.data.EventID', result.data.EventID)
                    setEventID(result.data.EventID)

                })
            } else {
                let _updateData = {
                    EventName: eventName,
                    EventStartDate: new Date(eventStartDate),
                    City: city,
                    SportCode: sportCode,
                    SportName: sportName,
                    OrganizationName: organizationName,
                }
                await updateDocument('EventList', eventID, _updateData)
                setLoading(false)
                setShowSection('payment')

            }

        }
    }
    function showPayment() {
        if (eventID === '') {
            setErrorMessage('update event details, then proceed for payment')
            setShowError(true)

            setTimeout(function () {
                setShowError(false)
                setErrorMessage('')

            }, 3000);
        } else {

            // setErrorMessage('update event details, then proceed for payment')
            // setShowError(true)

            // setTimeout(function () {
            //     setShowError(false)
            //     setErrorMessage('')

            // }, 3000);
            setShowSection('payment')
        }
    }
    console.log('showSection', showSection)
    return (
        <div>
            <div className='container'>
                <br /><br />

                <div className="stepper-wrapper">
                    <div className={showSection === 'package' ? "stepper-item active" : "stepper-item completed"}
                        onClick={() => {
                            setShowError(false)
                            setErrorMessage('')

                            setShowSection('package')
                        }}
                    >
                        <div className='step-icon'>
                            <span className="material-symbols-outlined">
                                deployed_code
                            </span>
                        </div>
                        <div className="step-counter">1</div>
                        <div className="step-name">Package</div>
                    </div>
                    <div
                        onClick={() => {
                            setShowError(false)
                            setErrorMessage('')

                            setShowSection('sport')
                        }} className={showSection === 'package' ? "stepper-item" : showSection === 'sport' ? "stepper-item active" : "stepper-item completed"}>
                        <div className='step-icon'>
                            <span className="material-symbols-outlined">
                                trophy
                            </span>
                        </div>
                        <div className="step-counter">2</div>
                        <div className="step-name">Sport</div>
                    </div>
                    <div onClick={() => setShowSection('details')} className={showSection === 'package' || showSection === 'sport' ? "stepper-item" : showSection === 'details' ? "stepper-item active" : "stepper-item completed"}>
                        <div className='step-icon'>
                            <span className="material-symbols-outlined">
                                description
                            </span>
                        </div>
                        <div className="step-counter">3</div>
                        <div className="step-name">Details</div>
                    </div>
                    <div onClick={() => showPayment()} className={showSection === 'package' || showSection === 'sport' || showSection === 'details' ? "stepper-item" : showSection === 'payment' ? "stepper-item active" : "stepper-item completed"}>
                        <div className='step-icon'>
                            <span className="material-symbols-outlined">
                                currency_rupee
                            </span>
                        </div>
                        <div className="step-counter">4</div>
                        <div className="step-name">Payment</div>
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>

                    {/* {showErrorOTP && <Alert variant='danger'>{'Pleae enter the OTP sent to your mobile.'}</Alert>} */}
                    {showError && <Alert variant='danger'>{errorMessage}</Alert>}
                </div>
                <div className='login-slider-outter'>
                    <div className='login-slider-inner' style={{ width: '400%', }}>
                        <div className='login-slides' style={{ overflow: 'hidden', transform: showSection === 'package' ? 'translateX(-0%)' : showSection === 'sport' ? 'translateX(-100%)' : showSection === 'details' ? 'translateX(-200%)' : showSection === 'payment' ? 'translateX(-300%)' : 'translateX(0%)' }}>
                            <div className='container'>
                                <div className="heading" style={{ justifyContent: 'center', width: '100%', }}>
                                    {/* <span className="material-symbols-outlined">
                                        deployed_code
                                    </span> */}
                                    <h4 style={{ fontWeight: '1000', }}>Packages</h4>
                                </div><br />
                                <RegistrationPackage calledFrom='create' setShowSection={setShowSection}></RegistrationPackage>
                            </div>
                        </div>
                        <div className='login-slides' style={{ transform: showSection === 'package' ? 'translateX(-0%)' : showSection === 'sport' ? 'translateX(-100%)' : showSection === 'details' ? 'translateX(-200%)' : showSection === 'payment' ? 'translateX(-300%)' : 'translateX(0%)' }}>
                            <div className='row no-gutters' style={{ width: '100%', }}>
                                <div className='col-12' >
                                    <div className="heading" style={{ justifyContent: 'center', width: '100%', }}>
                                        {/* <span className="material-symbols-outlined">
                                        deployed_code
                                    </span> */}
                                        <h4 style={{ fontWeight: '1000', }}>Select Sport</h4>
                                    </div><br />
                                </div>
                            </div>
                            <div className='row no-gutters' style={{ width: '100%', }}>
                                <div className='col-4' style={{ padding: '10px' }}>
                                    <div className={sportCode === 'BD' ? 'sports-div active' : 'sports-div'} id='sportBadminton'
                                        onClick={(e) => {
                                            selectSport("Badminton", "BD", "/img/genre_badminton.png")
                                        }}>
                                        <img src="/img/genre_badminton.png" alt="" />
                                        <h1>Badminton</h1>
                                    </div>
                                </div>

                                <div className='col-4' style={{ padding: '10px' }}>
                                    <div className={sportCode === 'TT' ? 'sports-div active' : 'sports-div'} id='sportTT'
                                        onClick={() => {
                                            selectSport('Table Tennis', 'TT', "/img/genre_tt.png")
                                        }}>
                                        <img src="/img/genre_tt.png" alt="" />
                                        <h1>Table Tennis</h1>
                                    </div>
                                </div>

                                <div className='col-4' style={{ padding: '10px' }}>
                                    <div className={sportCode === 'TN' ? 'sports-div active' : 'sports-div'} id='sportTennis'
                                        onClick={() => {
                                            selectSport('Tennis', 'TN', "/img/genre_tennis.png")
                                        }}>
                                        <img src="/img/genre_tennis.png" alt="" />
                                        <h1>Tennis</h1>
                                    </div>
                                </div>
                                <div className='col-4' style={{ padding: '10px' }}>
                                    <div className={sportCode === 'CH' ? 'sports-div active' : 'sports-div'} id='sportTennis'
                                        onClick={() => {
                                            selectSport('Chess', 'CH', "/img/genre_chess.png")
                                        }}>
                                        <img src="/img/genre_chess.png" alt="" />
                                        <h1>Chess</h1>
                                    </div>
                                </div>
                                <div className='col-4' style={{ padding: '10px' }}>
                                    <div className={sportCode === 'CR' ? 'sports-div active' : 'sports-div'} id='sportTennis'
                                        onClick={() => {
                                            selectSport('Carrom', 'CR', "/img/genre_carrom.png")
                                        }}>
                                        <img src="/img/genre_carrom.png" alt="" />
                                        <h1>Carrom</h1>
                                    </div>
                                </div>
                                <div className='col-4' style={{ padding: '10px' }}>
                                    <div className={sportCode === 'SQ' ? 'sports-div active' : 'sports-div'} id='sportTennis'
                                        onClick={() => {
                                            selectSport('Squash', 'SQ', "/img/genre_squash.png")
                                        }}>
                                        <img src="/img/genre_squash.png" alt="" />
                                        <h1>Squash</h1>
                                    </div>
                                </div>
                                <div className='col-4' style={{ padding: '10px' }}>
                                    <div className={sportCode === 'SW' ? 'sports-div active' : 'sports-div'} id='sportTennis'
                                        onClick={() => {
                                            selectSport('Swimming', 'SW', "/img/genre_swimming.png")
                                        }}>
                                        <img src="/img/genre_swimming.png" alt="" />
                                        <h1>Swimming</h1>
                                    </div>
                                </div>
                                <div className='col-4' style={{ padding: '10px' }}>
                                    <div className={sportCode === 'CT' ? 'sports-div active' : 'sports-div'} id='sportTennis'
                                        onClick={() => {
                                            selectSport('Cricket', 'CT', "/img/genre_cricket.png")
                                        }}>
                                        <img src="/img/genre_cricket.png" alt="" />
                                        <h1>Cricket</h1>
                                    </div>
                                </div>
                                <div className='col-4' style={{ padding: '10px' }}>
                                    <div className={sportCode === 'FB' ? 'sports-div active' : 'sports-div'} id='sportTennis'
                                        onClick={() => {
                                            selectSport('Football', 'FB', "/img/genre_football.png")
                                        }}>
                                        <img src="/img/genre_football.png" alt="" />
                                        <h1>Football</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='login-slides' style={{ transform: 'translateX(-0%)' }}> */}
                        <div className='login-slides' style={{ transform: showSection === 'package' ? 'translateX(-0%)' : showSection === 'sport' ? 'translateX(-100%)' : showSection === 'details' ? 'translateX(-200%)' : showSection === 'payment' ? 'translateX(-300%)' : 'translateX(0%)' }}>

                            <div className='row no-gutters' style={{ width: '100%', }}>
                                <div className='col-12' >
                                    <div className="heading" style={{ justifyContent: 'center', width: '100%', }}>
                                        {/* <span className="material-symbols-outlined">
                                        deployed_code
                                    </span> */}
                                        <h4 style={{ fontWeight: '1000', }}>Event Details</h4>
                                    </div><br />
                                </div>
                            </div>
                            <div className='row no-gutters' style={{ width: '100%' }}>
                                {/* <div className='col-2' style={{ padding: '10px' }}></div> */}
                                {/* <div className='col-6' style={{ padding: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', }}
                                    >
                                        <span onClick={() => {
                                            setShowSection('sport')
                                        }} style={{ fontSize: '1.2rem', position: 'relative', top: '-4px', color: '#348DCB', cursor: 'pointer' }} className="material-symbols-outlined">
                                            arrow_back_ios
                                        </span>
                                        <h1 onClick={() => {
                                            setShowSection('sport')
                                        }} style={{ fontSize: '1rem', color: '#348DCB', cursor: 'pointer' }}>Back to change Sport</h1>
                                    </div>
                                </div> */}
                                <div className='col-3'></div>
                                <div className='col-6' style={{ padding: '10px' }}>
                                    <div className='sports-div active'>
                                        <img src={sportImage} alt="" />
                                        <h1>{sportName}</h1>
                                    </div>
                                </div>
                                <div className='col-3'></div>
                                {/* <div className='col-2' style={{ padding: '10px' }}></div> */}
                            </div>
                            <br className='large' />
                            <div className="row no-gutters" style={{ width: '100%' }}>
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className='create-event-form-field'>
                                        <h1>Event Name</h1>
                                        <input type="text" value={eventName} onChange={(e) => setEventName(e.target.value)} placeholder='Event Name...' />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className='create-event-form-field'>
                                        <h1>Event Start Date</h1>
                                        <input type="date" value={eventStartDate}
                                            min={new Date()}
                                            onChange={(e) => setEventStartDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className='create-event-form-field'>
                                        <h1>Event City</h1>
                                        <input type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder='City...' />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-12">
                                    <div className='create-event-form-field'>
                                        <h1>Organization Name</h1>
                                        <input type="text" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} placeholder='Organization...' />
                                    </div>
                                </div>
                            </div>
                            <br />



                            <div style={{ textAlign: 'center' }}>
                                <button className="mybutton button5" onClick={() => saveEvent()} style={{ width: '150px', height: '35px', fontSize: '1.2rem' }}>
                                    <div style={{ display: !loading ? 'block' : 'none', }}>
                                        <span
                                            style={{ position: 'relative', top: '-2.5px', fontSize: '1rem' }}>Next</span>
                                    </div>
                                    <div className='btn-loading' style={{ display: loading ? 'block' : 'none', width: '150px', fontSize: '1.2rem' }}>
                                        <lottie-player id="btnSendOTPLoad"
                                            src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                            speed="0.7" loop autoplay></lottie-player>
                                    </div>
                                </button>

                                {/* <button className='mybutton button5' style={{ width: '150px', fontSize: '1.2rem' }} onClick={saveEvent}>Next</button> */}
                            </div>

                        </div>
                        <div className='login-slides' style={{ transform: showSection === 'package' ? 'translateX(-0%)' : showSection === 'sport' ? 'translateX(-100%)' : showSection === 'details' ? 'translateX(-200%)' : showSection === 'payment' ? 'translateX(-300%)' : 'translateX(0%)' }}>
                            {/* <RegistrationPackage></RegistrationPackage> */}
                            <div className='row no-gutters' style={{ width: '100%', }}>
                                <div className='col-12' >
                                    <div className="heading" style={{ justifyContent: 'center', width: '100%', }}>
                                        {/* <span className="material-symbols-outlined">
                                        deployed_code
                                    </span> */}
                                        <h4 style={{ fontWeight: '1000', }}>Payment</h4>
                                    </div><br />
                                </div>
                            </div>
                            <div className='row no-gutters' style={{ width: '100%' }}>
                                {/* <div className='col-2' style={{ padding: '10px' }}></div> */}

                                <PGCreateEventPayment EventID={eventID}
                                    EventName={eventName}
                                    EventStartDate={eventStartDate}
                                    City={city}
                                    SportCode={sportCode}
                                    SportName={sportName}
                                    sportImage={sportImage}
                                    OrganizationName={organizationName}
                                ></PGCreateEventPayment>

                            </div>
                            {/* // navigate('/PGCreateEventPayment', {
                            //     state: {
                            //         EventID: result.data.EventID,
                            //         EventName: eventName,
                            //         EventStartDate: eventStartDate,
                            //         City: city,
                            //         SportCode: sportCode,
                            //         SportName: sportName,
                            //     }
                            // }) */}

                        </div>
                    </div>

                </div>

                {/* <div className='create-event-form-field'>
                    <h1>Organizer Phone</h1>
                    <input type="text" readOnly={isAdmin ? false : true} value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Organizer phone...' />
                </div> */}
            </div>
            <br />
        </div >
    )
}

export default PGCreateEvent;
