import React, { useEffect, useState } from 'react'
import '../css/AddEvent.css'
import { useLocation } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";
import '../css/MydatePicker.css'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'
import EditEventDetails from './EditEventDetails';
import EditEventNotice from './EditEventNotice';
// import EditEventPoster from './EditEventPoster';
import EventDetailsLogo from './EventDetailsLogo';
import EditEventAnnouncement from './EditEventAnnouncement';
import EditEventRules from './EditEventRules';
import EditEventSetup from './EditEventSetup';
import EditEventCategory from './EditEventCategory';
import Loading from './Loading';
import { useNavigate } from 'react-router-dom';


export default function EditEvent() {
    const { state } = useLocation();
    const { eventID } = state;
    const { user, logout } = useUserAuth();
    const [error, setError] = useState('');
    const [errorMssageNotice, setErrorMssageNotice] = useState('');
    const [errorMssageAnnouncement, setErrorMssageAnnouncement] = useState('');
    const [errorMssageRegulation, setErrorMssageRegulation] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetFlag, setResetFlag] = useState(false);
    const [expandParam, setExpandParam] = useState(false);
    const navigate = useNavigate();
    const [showSideBar, setShowSideBar] = useState(false);

    const [activeSection, setActiveSection] = useState('DETAILS')
    const [eventDetails, setEventDetails] = useState({
        Eventid: eventID,
        EventName: '',
        EventDetails: '',
        EventType: '',
        OrganizationID: '',
        OrganizationName: '',
        OrganizerID: '',
        EventOwnerName: '',
        EventOwnerEmail: '',
        EventOwnerPhone: '',
        OrganizerLogo: '',
        EventLogo: [],
        EventCode: '',
        EventMode: '',
        SportCode: '',
        EntryCount: '',
        CategoryDetails: '',
        MinimumFee: 0,
        MaximumFee: 0,
        SportName: '',
        EventStartDate: new Date(),
        EventEndDate: new Date(),
        EventVenue: '',
        City: '',
        State: '',
        RegistrationStartDate: new Date(),
        RegistrationEndDate: new Date(),
        WithdrawalEndDate: new Date(),
        EventURL: '',
        PaymentMode: '',
        ApprovalStatus: '',
        EventStatus: 'ACTIVE',
        Comments: '',

        RegistrationOpenFlag: 'ON',
        PaymentOpenFlag: "ON",
        ShowParticipantFlag: "ON",
        ShowParticipantPostPaymentFlag: "OFF",
        LocationMap: '',
        VenueContact: '',
        MaxEntryForParticipant: -1,
        ConvenienceCharge: 0,
        IsMiscellaneousChargeMandatory: 'No',
        MiscellaneousChargeRemark: '',
        MiscellaneousChargeFees: 0,
        DiscountRemarks: '',
        DiscountValue: 0,
        OnlinePaymentModeFlag: 'ON',
        NoticeBoard: '',
        Announcement: '',
        RulesAndRegulations: '',
        CloseEventFlag: 'NO',

        RegistrationStatusOnFlag: 'ON',
        RegistrationCompletePostPaymentFlag: 'NO',
        OnlinePaymentGatewayFlag: 'ON',
        PublishDrawFlag: 'OFF',
        PublishSeedEntryFlag: 'OFF',
        PublishResultFlag: 'ON',
        PublishScheduleFlag: 'OFF',
        PublishGalleryFlag: 'OFF',

    })
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    useEffect(() => {
        console.log('eventID', eventID);
        eventID && eventID !== '' && getEventDetails();
    }, [])

    async function getEventDetails() {
        var para = {};

        para = {
            EventID: eventID,
        }
        setLoading(true);
        const ret = await httpsCallable(functions, "getEventDetails");
        ret(para).then(async (result) => {
            console.log(result);
            if (result && result.data && result.data.EventDetails) {


                setEventDetails({
                    Eventid: eventID,
                    EventName: result.data.EventDetails.EventName ? result.data.EventDetails.EventName : '',
                    EventDetails: result.data.EventDetails.EventDetails ? result.data.EventDetails.EventDetails : '',
                    EventType: result.data.EventDetails.EventType ? result.data.EventDetails.EventType : '',
                    OrganizationID: result.data.EventDetails.OrganizationID ? result.data.EventDetails.OrganizationID : '',
                    OrganizationName: result.data.EventDetails.OrganizationName ? result.data.EventDetails.OrganizationName : '',
                    OrganizerID: result.data.EventDetails.OrganizerID ? result.data.EventDetails.OrganizerID : '',
                    EventOwnerName: result.data.EventDetails.EventOwnerName ? result.data.EventDetails.EventOwnerName : '',
                    EventOwnerEmail: result.data.EventDetails.EventOwnerEmail ? result.data.EventDetails.EventOwnerEmail : '',
                    EventOwnerPhone: result.data.EventDetails.EventOwnerPhone ? result.data.EventDetails.EventOwnerPhone : '',
                    OrganizerLogo: result.data.EventDetails.OrganizerLogo ? result.data.EventDetails.OrganizerLogo : '',
                    EventLogo: result.data.EventDetails.EventLogo ? result.data.EventDetails.EventLogo : [],
                    EventCode: result.data.EventDetails.EventCode ? result.data.EventDetails.EventCode : '',
                    EventMode: result.data.EventDetails.EventMode ? result.data.EventDetails.EventMode : '',
                    SportCode: result.data.EventDetails.SportCode ? result.data.EventDetails.SportCode : '',
                    EntryCount: result.data.EventDetails.EntryCount ? result.data.EventDetails.EntryCount : 0,
                    CategoryDetails: result.data.EventDetails.CategoryDetails ? result.data.EventDetails.CategoryDetails : [],
                    MinimumFee: result.data.EventDetails.MinimumFee ? result.data.EventDetails.MinimumFee : 0,
                    MaximumFee: result.data.EventDetails.MaximumFee ? result.data.EventDetails.MaximumFee : 0,
                    SportName: result.data.EventDetails.SportName ? result.data.EventDetails.SportName : '',
                    EventStartDate: result.data.EventDetails.EventStartDate === '' ? new Date() : new Date(result.data.EventDetails.EventStartDate._seconds * 1000),
                    EventEndDate: result.data.EventDetails.EventEndDate === '' ? new Date() : new Date(result.data.EventDetails.EventEndDate._seconds * 1000),
                    EventVenue: result.data.EventDetails.EventVenue ? result.data.EventDetails.EventVenue : '',
                    City: result.data.EventDetails.City ? result.data.EventDetails.City : '',
                    State: result.data.EventDetails.State ? result.data.EventDetails.State : '',
                    RegistrationStartDate: result.data.EventDetails.RegistrationStartDate === '' ? new Date() : new Date(result.data.EventDetails.RegistrationStartDate._seconds * 1000),
                    RegistrationEndDate: result.data.EventDetails.RegistrationEndDate === '' ? new Date() : new Date(result.data.EventDetails.RegistrationEndDate._seconds * 1000),
                    WithdrawalEndDate: result.data.EventDetails.WithdrawalEndDate === '' ? new Date() : new Date(result.data.EventDetails.WithdrawalEndDate._seconds * 1000),
                    EventURL: result.data.EventDetails.EventURL ? result.data.EventDetails.EventURL : '',

                    PaymentMode: result.data.EventDetails.PaymentMode ? result.data.EventDetails.PaymentMode : '',
                    ApprovalStatus: result.data.EventDetails.ApprovalStatus ? result.data.EventDetails.ApprovalStatus : '',
                    EventStatus: result.data.EventDetails.EventStatus ? result.data.EventDetails.EventStatus : '',
                    Comments: result.data.EventDetails.Comments ? result.data.EventDetails.Comments : '',

                    RegistrationOpenFlag: result.data.EventDetails.RegistrationOpenFlag ? result.data.EventDetails.RegistrationOpenFlag : '',
                    PaymentOpenFlag: result.data.EventDetails.PaymentOpenFlag ? result.data.EventDetails.PaymentOpenFlag : '',
                    ShowParticipantFlag: result.data.EventDetails.ShowParticipantFlag ? result.data.EventDetails.ShowParticipantFlag : '',
                    ShowParticipantPostPaymentFlag: result.data.EventDetails.ShowParticipantPostPaymentFlag ? result.data.EventDetails.ShowParticipantPostPaymentFlag : '',
                    LocationMap: result.data.EventDetails.LocationMap ? result.data.EventDetails.LocationMap : '',
                    VenueContact: result.data.EventDetails.VenueContact ? result.data.EventDetails.VenueContact : '',
                    MaxEntryForParticipant: result.data.EventDetails.MaxEntryForParticipant ? result.data.EventDetails.MaxEntryForParticipant : '',
                    ConvenienceCharge: result.data.EventDetails.ConvenienceCharge ? result.data.EventDetails.ConvenienceCharge : 0,
                    IsMiscellaneousChargeMandatory: result.data.EventDetails.IsMiscellaneousChargeMandatory ? result.data.EventDetails.IsMiscellaneousChargeMandatory : '',
                    MiscellaneousChargeRemark: result.data.EventDetails.MiscellaneousChargeRemark ? result.data.EventDetails.MiscellaneousChargeRemark : '',
                    MiscellaneousChargeFees: result.data.EventDetails.MiscellaneousChargeFees ? result.data.EventDetails.MiscellaneousChargeFees : '',
                    DiscountRemarks: result.data.EventDetails.DiscountRemarks ? result.data.EventDetails.DiscountRemarks : '',
                    DiscountValue: result.data.DiscountValue ? result.data.DiscountValue : 0,
                    OnlinePaymentModeFlag: result.data.EventDetails.OnlinePaymentModeFlag ? result.data.EventDetails.OnlinePaymentModeFlag : '',
                    NoticeBoard: result.data.EventDetails.NoticeBoard ? result.data.EventDetails.NoticeBoard : '',
                    Announcement: result.data.EventDetails.Announcement ? result.data.EventDetails.Announcement : '',
                    RulesAndRegulations: result.data.EventDetails.RulesAndRegulations ? result.data.EventDetails.RulesAndRegulations : '',
                    CloseEventFlag: result.data.EventDetails.CloseEventFlag ? result.data.EventDetails.CloseEventFlag : '',

                    RegistrationStatusOnFlag: result.data.EventDetails.RegistrationStatusOnFlag ? result.data.EventDetails.RegistrationStatusOnFlag : '',
                    RegistrationCompletePostPaymentFlag: result.data.EventDetails.RegistrationCompletePostPaymentFlag ? result.data.EventDetails.RegistrationCompletePostPaymentFlag : '',
                    OnlinePaymentGatewayFlag: result.data.EventDetails.OnlinePaymentGatewayFlag ? result.data.EventDetails.OnlinePaymentGatewayFlag : '',
                    PublishDrawFlag: result.data.EventDetails.PublishDrawFlag ? result.data.EventDetails.PublishDrawFlag : '',
                    PublishSeedEntryFlag: result.data.EventDetails.PublishSeedEntryFlag ? result.data.EventDetails.PublishSeedEntryFlag : '',
                    PublishResultFlag: result.data.EventDetails.PublishResultFlag ? result.data.EventDetails.PublishResultFlag : '',
                    PublishScheduleFlag: result.data.EventDetails.PublishScheduleFlag ? result.data.EventDetails.PublishScheduleFlag : '',
                    PublishGalleryFlag: result.data.EventDetails.PublishGalleryFlag ? result.data.EventDetails.PublishGalleryFlag : '',

                })
            }
            setLoading(false);
        })
    }

    var getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };
    function resetParam() {
        navigate(-2);
    }
    function openSideBar(flag) {
        setShowSideBar(flag);
    }
    return (
        <div className="container-fluid">

            <div className="row no-gutters">

                <div className="col-lg-3 col-md-3 col-sm-12">
                    {/* <div className={showSideBar ? 'user-profile-side' : 'user-profile-side close'}>
                        <div className='user-profile-side-close-icon' onClick={(e) => {
                            console.log('in props.openSideBar');
                            openSideBar(!showSideBar);
                        }
                        } >
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </div>
                    </div> */}

                    {/* <div className="side-navbar mobile" id="sideNavbar"> */}
                    <div className={showSideBar ? 'side-navbar mobile open' : 'side-navbar mobile'} id="sideNavbar" style={{ top: '55px' }}>
                        <br />
                        <div className='' onClick={(e) => {
                            console.log('in props.openSideBar');
                            openSideBar(!showSideBar);
                        }
                        } style={{ position: 'absolute', top: '10px', left: '10px' }}>
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </div><br />

                        <ul>
                            <li className={activeSection === 'FLAG' ? 'active' : ''} onClick={() => {
                                openSideBar(false);
                                setActiveSection('FLAG');
                            }}>
                                <div className='side-navbar-menu-item'>
                                    <b></b>
                                    <b></b>
                                    <span className="material-symbols-outlined">
                                        flag
                                    </span>
                                    <small>Event Status</small>
                                </div>
                                <div className="side-navbar-menu-arrow">
                                    <span className="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </div>
                            </li>
                            <li className={activeSection === 'LOGO' ? 'active' : ''} onClick={() => {
                                openSideBar(false);
                                setActiveSection('LOGO')
                            }}>
                                <div className='side-navbar-menu-item'>
                                    <b></b>
                                    <b></b>
                                    <span className="material-symbols-outlined">
                                        broken_image
                                    </span>
                                    <small>Event Poster</small>
                                </div>
                                <div className="side-navbar-menu-arrow">
                                    <span className="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </div>
                            </li>


                            <li className={activeSection === 'DETAILS' ? 'active' : ''} onClick={() => {
                                openSideBar(false);
                                setActiveSection('DETAILS')
                            }}>
                                <div className='side-navbar-menu-item'>
                                    <b></b>
                                    <b></b>

                                    <span className="material-symbols-outlined">
                                        event_note
                                    </span>
                                    <small>Event Details</small>
                                </div>
                                <div className="side-navbar-menu-arrow">
                                    <span className="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </div>
                            </li>

                            <li className={activeSection === 'CATEGORY' ? 'active' : ''} onClick={() => {
                                openSideBar(false);
                                setActiveSection('CATEGORY')
                            }}>
                                <div className='side-navbar-menu-item'>
                                    <b></b>
                                    <b></b>
                                    <span className="material-symbols-outlined">
                                        widgets
                                    </span>
                                    <small>Category Setup</small>
                                </div>
                                <div className="side-navbar-menu-arrow">
                                    <span className="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </div>
                            </li>

                            <li className={activeSection === 'NOTICE' ? 'active' : ''} onClick={() => {
                                openSideBar(false);
                                setActiveSection('NOTICE')
                            }}>
                                <div className='side-navbar-menu-item'>
                                    <b></b>
                                    <b></b>
                                    <span className="material-symbols-outlined">
                                        assignment_late
                                    </span>
                                    <small>Notice Board</small>
                                </div>
                                <div className="side-navbar-menu-arrow">
                                    <span className="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </div>
                            </li>

                            <li className={activeSection === 'ANNOUNCEMENT' ? 'active' : ''} onClick={() => {
                                openSideBar(false);
                                setActiveSection('ANNOUNCEMENT')
                            }}>
                                <div className='side-navbar-menu-item'>
                                    <b></b>
                                    <b></b>
                                    <span className="material-symbols-outlined">
                                        keyboard_voice
                                    </span>
                                    <small>Announcement</small>
                                </div>
                                <div className="side-navbar-menu-arrow">
                                    <span className="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </div>
                            </li>

                            <li className={activeSection === 'RULES' ? 'active' : ''} onClick={() => {
                                openSideBar(false);
                                setActiveSection('RULES')
                            }}>
                                <div className='side-navbar-menu-item'>
                                    <b></b>
                                    <b></b>
                                    <span className="material-symbols-outlined">
                                        rule
                                    </span>
                                    <small>Rules & Regulations</small>
                                </div>
                                <div className="side-navbar-menu-arrow">
                                    <span className="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </div>
                            </li>

                        </ul>

                    </div>


                </div>

                <div className="col-lg-9 col-md-9 col-sm-12" style={{ padding: '10px 0' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => {
                        openSideBar(true);
                    }
                    }>
                        <div style={{ paddingLeft: '10px' }} className='small' >
                            <span className="material-symbols-outlined">
                                menu
                            </span>
                        </div>
                    </div>

                    <div className='fixed-settings-breadcrum'>
                        <div>
                            <span style={{ fontSize: '1.8rem' }} className="material-symbols-outlined">
                                person
                            </span>
                        </div>
                        <span style={{ position: 'relative', top: '-2px' }} onClick={resetParam} >  Profile </span>
                        {/* {!resetFlag && <span style={{ position: 'relative', top: '-2px' }}> &lt; </span>} */}
                        {/* <span style={{ position: 'relative', top: '-2px' }}> Edit Event </span> */}
                        <span style={{ position: 'relative', top: '-2px' }}> {!resetFlag && expandParam} </span>
                        {!resetFlag && <span style={{ position: 'relative', top: '-2px' }}> &lt; </span>}
                        <span style={{ position: 'relative', top: '-2px' }}> {
                            activeSection === 'DETAILS' ? 'Event Basic Details' :
                                activeSection === 'NOTICE' ? 'Setup Notice' :
                                    activeSection === 'LOGO' ? 'Event Poster' :
                                        activeSection === 'ANNOUNCEMENT' ? 'Event Announcement' :
                                            activeSection === 'RULES' ? 'Event Rules' :
                                                activeSection === 'FLAG' ? 'Event Flag' :
                                                    activeSection === 'CATEGORY' ? 'Event Category' : ''



                        } </span>
                        <span style={{ position: 'relative', top: '-2px' }}> {!resetFlag && expandParam} </span>

                        {/* <div className='dots'>

                    <span className="material-symbols-outlined">
                        more_vert
                    </span>

                    <div className='dots-pop-up'>
                        <div>
                            <h1>Profile</h1>
                        </div>
                    </div>

                </div> */}

                    </div>
                    {loading && <Loading></Loading>}
                    <div className="analytics-div" style={{ textAlign: 'center' }}>
                        <h1>Event   <span id="eventName"> {eventDetails && "-" + eventDetails.EventName}</span> </h1>
                    </div>
                    <hr style={{ border: 'none', borderTop: '1px solid #888' }} />

                    <div className="update-event-form" id="EventSetupForm3">
                        {/* 
                        <div className="event-detail-main-menu-heading">
                            <h1>EVENT SETUP</h1>
                        </div><br /> */}

                        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                            <div className="event-details-sub-menu-parent-div">
                                <div className="filter-btn" style={{ flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div className="filter-btn-div event-details-sub-menu">
                                            <button type="button" id='btnDetails'
                                                className={activeSection === 'DETAILS' ? "mybutton buttonTransparent active" : "mybutton buttonTransparent"}
                                                onClick={() => setActiveSection('DETAILS')} name="button">DETAILS</button>
                                            <div className="indicator"></div>
                                            <span id="active3"></span>
                                            <span className="half" id="active4"></span>
                                        </div>
                                        <div className={activeSection === 'NOTICE' ? "filter-btn-div event-details-sub-menu active" : "filter-btn-div event-details-sub-menu"}>
                                            <button type="button" id='btnNotice' className={activeSection === 'NOTICE' ? "mybutton buttonTransparent active" : "mybutton buttonTransparent"}
                                                onClick={() => setActiveSection('NOTICE')} name="button">NOTICE
                                                BOARD</button>
                                            <div className="indicator"></div>
                                            <span id="close3"></span>
                                            <span className="half" id="close4"></span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div className="filter-btn-div event-details-sub-menu">
                                            <button type="button" id='btnAnnouncement'
                                                className={activeSection === 'ANNOUNCEMENT' ? "mybutton buttonTransparent active" : "mybutton buttonTransparent"}
                                                onClick={() => setActiveSection('ANNOUNCEMENT')}
                                                name="button">ANNOUNCEMENT</button>
                                            <div className="indicator"></div>
                                            <span id="close3"></span>
                                            <span className="half" id="close4"></span>
                                        </div>
                                        <div className="filter-btn-div event-details-sub-menu">
                                            <button type="button" id='btnRegulation'
                                                className={activeSection === 'RULES' ? "mybutton buttonTransparent active" : "mybutton buttonTransparent"}

                                                onClick={() => setActiveSection('RULES')} name="button">RULES &
                                                REGULATIONS</button>
                                            <div className="indicator"></div>
                                            <span id="others3"></span>
                                            <span className="half" id="others4"></span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div className="filter-btn-div event-details-sub-menu">
                                            <button type="button" id='btnAnnouncement'
                                                className={activeSection === 'LOGO' ? "mybutton buttonTransparent active" : "mybutton buttonTransparent"}
                                                onClick={() => setActiveSection('LOGO')}
                                                name="button">Event Poster</button>
                                            <div className="indicator"></div>
                                            <span id="close3"></span>
                                            <span className="half" id="close4"></span>
                                        </div>
                                        <div className="filter-btn-div event-details-sub-menu">
                                            <button type="button" id='btnAnnouncement'
                                                className={activeSection === 'FLAG' ? "mybutton buttonTransparent active" : "mybutton buttonTransparent"}
                                                onClick={() => setActiveSection('FLAG')}
                                                name="button">Event Flag</button>
                                            <div className="indicator"></div>
                                            <span id="close3"></span>
                                            <span className="half" id="close4"></span>
                                        </div>
                                        <div className="filter-btn-div event-details-sub-menu">
                                            <button type="button"
                                                className={activeSection === 'CATEGORY' ? "mybutton buttonTransparent active" : "mybutton buttonTransparent"}
                                                onClick={() => setActiveSection('CATEGORY')}
                                                name="button">Event Category Setup</button>
                                            <div className="indicator"></div>
                                            <span id="close3"></span>
                                            <span className="half" id="close4"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div><br /> */}

                        <div className="white-div"><br />
                            {activeSection === 'DETAILS' && <EditEventDetails setEventDetails={setEventDetails}
                                eventDetails={eventDetails} ></EditEventDetails>}
                            {activeSection === 'NOTICE' && <EditEventNotice setEventDetails={setEventDetails}
                                eventDetails={eventDetails} ></EditEventNotice>}
                            {activeSection === 'LOGO' && <EventDetailsLogo
                                //setEventDetails={setEventDetails}
                                eventDetails={eventDetails} ></EventDetailsLogo>}
                            {activeSection === 'ANNOUNCEMENT' && <EditEventAnnouncement setEventDetails={setEventDetails}
                                eventDetails={eventDetails} ></EditEventAnnouncement>}
                            {activeSection === 'RULES' && <EditEventRules setEventDetails={setEventDetails}
                                eventDetails={eventDetails} ></EditEventRules>}
                            {activeSection === 'FLAG' && <EditEventSetup setEventDetails={setEventDetails}
                                eventDetails={eventDetails} ></EditEventSetup>}
                            {activeSection === 'CATEGORY' && <EditEventCategory setEventDetails={setEventDetails}
                                eventDetails={eventDetails} ></EditEventCategory>}


                            <br />
                        </div >
                    </div > <br />

                </div >

            </div >
        </div >

    )
}
