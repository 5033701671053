import React from 'react';

class TournamentBracket extends React.Component {
    renderMatch(match, roundIndex, matchIndex) {
        return (
            <div key={`round-${roundIndex}-match-${matchIndex}`} className="match">
                <div className="participant">{match[0]}</div>
                <div className="participant">{match[1]}</div>
            </div>
        );
    }

    renderRound(round, index) {
        return (
            <div key={`round-${index}`} className="round">
                {round.map((match, matchIndex) => this.renderMatch(match, index, matchIndex))}
            </div>
        );
    }

    render() {
        const { participants } = this.props;
        // This example assumes participants are correctly paired for simplicity
        // In a real app, you'd need to generate these pairings based on your tournament logic
        const rounds = [
            // For simplicity, assuming an 8-participant tournament
            // Round 1
            [[participants[0], participants[1]], [participants[2], participants[3]], [participants[4], participants[5]], [participants[6], participants[7]]],
            // Round 2
            [[participants[0], participants[2]], [participants[4], participants[6]]],
            // Final
            [[participants[0], participants[4]]],
        ];

        return (
            <div className="tournament-bracket">
                {rounds.map((round, index) => this.renderRound(round, index))}
            </div>
        );
    }
}

export default TournamentBracket;
