import {
    SingleEliminationBracket,
    Match,
    SVGViewer,
    createTheme
} from "react-tournament-brackets";
// import "./styles.css";
import React, { useEffect, useState } from 'react'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'

export default function SingleElimination(props) {
    const [matchDetails, setMatchDetails] = useState([]);
    const [categoryName, setCategoryName] = useState('')
    useEffect(() => {
        console.log('in useEffect', props)
        populateMatch(props.MatchDetails);

        // setCategoryName('BD U11');
        // getDraw();
    }, [])

    async function getDraw() {
        var para1 = {};

        para1 = {
            EventID: props.EventID,
            CategoryName: categoryName//'BD U11' //props.CategoryName
        };
        const ret1 = httpsCallable(functions, 'getMatchDetails');
        // const ret1 = httpsCallable(functions, 'generateDrawKnockout');

        ret1(para1).then(async (result) => {
            console.log('result', result)
            populateMatch(result.data);

        })
    }

    function populateMatch(data) {
        let matchList = [];
        let firstPlayer = false;
        let secondPlayer = false;
        let MatchScore = [];
        let wonScore = '';
        let lostScore = '';
        let setScore = [];
        let participantList = [];
        data.sort(function (a, b) { return a.MatchID - b.MatchID })


        data.forEach(element => {
            MatchScore = element.MatchPoints.split(",")
            MatchScore.forEach(score => {
                // console.log('score : ', score)
                setScore = score.split('-');
                // console.log('setScore : ', setScore)
                if (wonScore === '') {
                    wonScore = wonScore + setScore[0]
                } else {
                    wonScore = wonScore + ' . ' + setScore[0]
                }
                if (lostScore === '') {
                    lostScore = lostScore + setScore[1] ? setScore[1] : '';
                } else {
                    lostScore = lostScore + ' . ' + setScore[1] ? setScore[1] : '';
                }
            });
            // console.log('wonScore : ', wonScore, " :: element.WinnerPlayerCode : ", element.WinnerPlayerCode)
            // console.log('lostScore : ', lostScore, " :: element.WinnerPlayerCode : ", element.WinnerPlayerCode)
            if (element.PlayerCode && element.PlayerCode !== '') {
                firstPlayer = true
            }
            if (element.SecondPlayerCode && element.SecondPlayerCode !== '') {
                secondPlayer = true
            }
            participantList = []
            let fName = ''
            let sName = ''

            if (firstPlayer) {
                fName = element.ParticipantName + (element.PartnerPlayerName && element.PartnerPlayerName !== 'Bye' ? " - " + element.PartnerPlayerName : '')
                if (fName.length > 25) {
                    fName = fName.substring(0, 25) + '..';
                }
                participantList.push({
                    id: element.PlayerCode,
                    resultText: element.WinnerPlayerCode === 'First' ? wonScore : element.WinnerPlayerCode === 'Second' ? lostScore : '',//;'11 . 21 . 21',
                    isWinner: element.WinnerPlayerCode === 'First' ? true : element.WinnerPlayerCode === 'Second' ? false : '',
                    status: null,
                    name: fName,
                    picture: "./img/game/badminton.png"
                })
            } else {
                participantList.push({});
            }
            if (secondPlayer) {
                sName = element.SecondParticipantName + (element.SecondPartnerPlayerName && element.SecondPartnerPlayerName !== 'Bye' ? " - " + element.SecondPartnerPlayerName : '')
                if (sName.length > 25) {
                    sName = sName.substring(0, 25) + '..';
                }
                participantList.push({
                    id: element.SecondPlayerCode,
                    resultText: element.WinnerPlayerCode === 'First' ? lostScore : element.WinnerPlayerCode === 'Second' ? wonScore : '',//'11 . 21 . 21',
                    isWinner: element.WinnerPlayerCode === 'Second' ? true : element.WinnerPlayerCode === 'First' ? false : '',
                    status: null,
                    name: sName,
                    picture: "./img/game/badminton.png"
                })
            } else {
                participantList.push({});
            }

            matchList.push({
                width: '200',
                id: element.MatchID,
                nextMatchId: element.Nextmatchid === '' ? null : element.Nextmatchid,
                tournamentRoundText: "",
                startTime: '',//,'2021-05-30 (21-2,21-3)', //replace schedule
                state: "SCHEDULED",
                participants: participantList
            })

            firstPlayer = false;
            secondPlayer = false;
            wonScore = '';
            lostScore = '';
            participantList = [];
        });
        setMatchDetails(matchList)

    }
    return (
        <>

            {matchDetails && matchDetails.length > 0 && <SingleEliminationBracket
                theme={GlootTheme}
                matches={matchDetails}
                matchComponent={Match}
                options={{
                    style: {
                        connectorColor: "#ffffff",
                        connectorColorHighlight: "#333C5D",
                    },
                }}
                svgWrapper={({ children, ...props }) => (
                    <SVGViewer
                        width={10000}
                        height={500000}
                        background="#EEEEEE"
                        SVGBackground="#EEEEEE"
                        {...props}
                    >
                        {children}
                    </SVGViewer>
                )}
                onMatchClick={(match) => console.log(match)}
                onPartyClick={(match) => console.log(match)}
            />
            }
        </>
        // <SingleEliminationBracket
        //     theme={GlootTheme}
        //     matches={simpleSmallBracket}
        //     matchComponent={Match}
        //     options={{
        //         style: {
        //             connectorColor: "#ffffff",
        //             connectorColorHighlight: "#333C5D",
        //         },
        //     }}
        //     svgWrapper={({ children, ...props }) => (
        //         <SVGViewer
        //             width={10000}
        //             height={500000}
        //             background="#EEEEEE"
        //             SVGBackground="#EEEEEE"
        //             {...props}
        //         >
        //             {children}
        //         </SVGViewer>
        //     )}
        //     onMatchClick={(match) => console.log(match)}
        //     onPartyClick={(match) => console.log(match)}
        // />
    )
};

const GlootTheme = createTheme({
    textColor: { main: "#fff", highlighted: "auto", dark: "rgb(51, 60, 93,0.4)" },
    matchBackground: { wonColor: "#24D936", lostColor: "#fff" },
    score: {
        background: {
            // wonColor: `#fff`,
            wonColor: `rgba(0,0,0,0.1)`,
            lostColor: `red`,
        },
        text: { highlightedWonColor: "#333C5D", highlightedLostColor: "rgb(51, 60, 93,0.4)", },
    },
    border: {
        color: "rgb(51, 60, 93,0)",
        highlightedColor: "rgb(51, 60, 93,0.5)",
    },
    roundHeader: { backgroundColor: "#3B3F73", fontColor: "#F4F2FE" },
    // connectorColor: "#333C5D",
    // connectorColorHighlight: "#3B3F73",
    svgBackground: "#0F121C",
});


export const simpleSmallBracket = [
    {
        width: '200',
        id: "1",
        nextMatchId: '9',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "1",
                resultText: '11 . 21 . 21',
                isWinner: true,
                status: null,
                name: "Player hjjjjjjj kkjkjkjkj 1",
                picture: "./img/game/badminton.png"
            },
            {
                id: "2",
                resultText: "21 . 08 . 09",
                isWinner: false,
                status: null,
                name: "Player 2",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "2",
        nextMatchId: '9',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "3",
                resultText: '09 . 07',
                isWinner: false,
                status: null,
                name: "Player 3",
                picture: "./img/game/badminton.png"
            },
            {
                id: "4",
                resultText: '21 . 21',
                isWinner: true,
                status: null,
                name: "Player 4",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "3",
        nextMatchId: '10',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "5",
                resultText: '',
                isWinner: false,
                status: null,
                name: "Player 5",
                picture: "./img/game/badminton.png"
            },
            {
                id: "6",
                resultText: null,
                isWinner: true,
                status: null,
                name: "Player 6",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "4",
        nextMatchId: '10',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "7",
                resultText: 'lost',
                isWinner: false,
                status: null,
                name: "Player 7",
                picture: "./img/game/badminton.png"
            },
            {
                id: "8",
                resultText: 'won',
                isWinner: true,
                status: null,
                name: "Player 8",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "5",
        nextMatchId: '11',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "9",
                resultText: 'lost',
                isWinner: false,
                status: null,
                name: "Player 9",
                picture: "./img/game/badminton.png"
            },
            {
                id: "10",
                resultText: 'won',
                isWinner: true,
                status: null,
                name: "Player 10",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "6",
        nextMatchId: '11',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "11",
                resultText: 'lost',
                isWinner: false,
                status: null,
                name: "Player 11",
                picture: "./img/game/badminton.png"
            },
            {
                id: "12",
                resultText: 'won',
                isWinner: true,
                status: null,
                name: "Player 12",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "7",
        nextMatchId: '12',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "13",
                resultText: 'lost',
                isWinner: false,
                status: null,
                name: "Player 13",
                picture: "./img/game/badminton.png"
            },
            {
                id: "14",
                resultText: 'won',
                isWinner: true,
                status: null,
                name: "Player 14",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "8",
        nextMatchId: '12',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "15",
                resultText: 'lost',
                isWinner: false,
                status: null,
                name: "Player 15",
                picture: "./img/game/badminton.png"
            },
            {
                id: "16",
                resultText: 'won',
                isWinner: true,
                status: null,
                name: "Player 16",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "9",
        nextMatchId: '13',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "1",
                resultText: 'lost',
                isWinner: false,
                status: null,
                name: "Player 1",
                picture: "./img/game/badminton.png"
            },
            {
                id: "4",
                resultText: 'won',
                isWinner: true,
                status: null,
                name: "Player 4",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "10",
        nextMatchId: '13',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "6",
                resultText: 'won',
                isWinner: true,
                status: null,
                name: "Player 6",
                picture: "./img/game/badminton.png"
            },
            {
                id: "8",
                resultText: 'lost',
                isWinner: false,
                status: null,
                name: "Player 8",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "11",
        nextMatchId: '14',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "10",
                resultText: 'lost',
                isWinner: false,
                status: null,
                name: "Player 10",
                picture: "./img/game/badminton.png"
            },
            {
                id: "12",
                resultText: 'won',
                isWinner: true,
                status: null,
                name: "Player 12",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "12",
        nextMatchId: '14',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "14",
                resultText: 'lost',
                isWinner: false,
                status: null,
                name: "Player 14",
                picture: "./img/game/badminton.png"
            },
            {
                id: "16",
                resultText: 'won',
                isWinner: true,
                status: null,
                name: "Player 16",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "13",
        nextMatchId: '15',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "4",
                resultText: null,
                isWinner: null,
                status: null,
                name: "Player 4",
                picture: "./img/game/badminton.png"
            },
            {
                id: "6",
                resultText: null,
                isWinner: null,
                status: null,
                name: "Player 6",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "14",
        nextMatchId: '15',
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
            {
                id: "12",
                resultText: null,
                isWinner: null,
                status: null,
                name: "Player 12",
                picture: "./img/game/badminton.png"
            },
            {
                id: "16",
                resultText: null,
                isWinner: null,
                status: null,
                name: "Player 16",
                picture: "./img/game/badminton.png"
            }
        ]
    },
    {
        id: "15",
        nextMatchId: null,
        tournamentRoundText: "3",
        startTime: "2021-05-30 (21-2,21-3)",
        state: "SCHEDULED",
        participants: [
        ]
    },
];