import '../css/NavbarMobile.css';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthcontext'
import { where, orderBy } from 'firebase/firestore';
import { useCollection } from '../hooks/useCollection';

export default function NavbarMobile(props) {
    // 
    // const { user, users, logout } = useUserAuth();

    // const [city, setCity] = useState(window.localStorage.getItem("userLocation") ? window.localStorage.getItem("userLocation").replaceAll('"', '') : 'All')
    // const [name, setName] = useState('User')

    // const [eventIDs, setEventIDs] = useState([])
    // const [playerIDs, setPlayerIDs] = useState([])
    // const [orders, setOrders] = useState(null)
    // const [isPayClicked, setIsPayClicked] = useState(false);
    const [signinFlag, setSigninFlag] = useState(true);
    // const [orderCnt, setOrderCnt] = useState(0);
    // const [flag, setFlag] = useState();

    // const queryUser = [
    //     where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ' '),

    // ]
    // const queryRegistration = [
    //     where('PaymentStatus', '==', 'Pending'),
    //     where('CreatedBy', '==', user && user.userInfo ? user.userInfo.uid : ' '),

    // ]
    // const queryEvent = [
    //     where('EventStatus', '==', 'Active'),
    //     orderBy('EventStartDate', 'desc'),
    //     // limit(30)
    // ]

    // const { documents: eventDocs, error: eventDocError } = useCollection('EventList', queryEvent)
    // const { documents: playerDocs, error: PlayerDocError } = useCollection('Participants', queryUser)
    // const { documents: registrationDoc, error: registrationDocError } = useCollection('EventRegistrationDetails', queryRegistration)

    // console.log('1eventDocError', eventDocs, ':: PlayerDocError', playerDocs, 'registrationDoc', registrationDoc)

    // useEffect(() => {

    //     let playerList = [];
    //     playerDocs.forEach(player => {
    //         player.PlayerID && playerList.push(player.PlayerID)
    //     });
    //     // setPlayerIDs(playerList)

    //     let eventIds = []

    //     eventDocs.forEach(element => {
    //         if (element.EventStatus === 'Active') {
    //             eventIds.push(element.id)
    //         }
    //     });

    //     // setEventIDs(eventIds)

    //     let _finalList = [];
    //     registrationDoc.forEach(registration => {
    //         // console.log(registration, eventIds, playerIDs)
    //         if (playerList.includes(registration.ParticipantID) && eventIds.includes(registration.EventID)) {
    //             _finalList.push(registration)
    //         }
    //     });

    //     setOrders(_finalList)
    //     setOrderCnt(_finalList.length)
    // }, [playerDocs, eventDocs, registrationDoc])

    // function GetDocumentList(list) {
    //     // console.log(list)
    //     setOrderCnt(list.length)
    // }
    function setLoginFlag(flag) {
        // console.log('in setLoginFlag', flag)
        // setLoginFlagForWithdraw(true)
        setSigninFlag(flag);
    }

    // export default class NavbarMobile extends Component {
    // constructor(props) {

    //     // console.log('in set constructore from BeforeNavbar ', props)
    //     super(props);
    //     // this.setState({
    //     //     City: localStorage.getItem("userLocation") ? localStorage.getItem("userLocation") : 'All'
    //     // })
    // }
    return (
        <div className="small navbar-mobile sticky-top">
            <div className="navbar-mobile-menu">
                <div className="" style={{ display: 'flex', alignItems: 'center' }}>


                    <Link to="/">
                        <img style={{ height: '45px' }} src="/img/TPLiVE_text_white.png" alt="TPLiVE" />
                    </Link>
                </div>


                <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to='/Notification'>

                        <div style={{
                            position: 'relative',
                            top: '2px',
                            paddingRight: '10px'
                        }}>
                            <span style={{ color: '#fff' }} className="material-symbols-outlined">
                                notifications
                            </span>
                            <div style={{
                                position: 'absolute',
                                top: '3px',
                                right: '11px',
                                width: '8px',
                                height: '8px',
                                background: 'red',
                                borderRadius: '50%'
                            }}></div>
                        </div>
                    </Link>
                    {/* <Link to="/Orders"
                        style={{
                            position: 'relative',
                            paddingRight: '10px',
                        }} >
                        <span style={{ fontSize: '1.9rem' }} className="material-symbols-outlined navbar-search-icon">
                            remove_shopping_cart
                        </span>
                        <div className='navbar-cart-number-new'>
                            <small>{orderCnt}</small>
                        </div>
                    </Link> */}

                    {/* <a href="https://tournamentplanner.in/screens/TPLive_TournamentList.aspx?tstatus=upcoming&ocode=QQBDAFQASQBWAEUA"
                            style={{
                                paddingRight: '10px'
                            }} >
                            <span className="material-symbols-outlined navbar-search-icon">
                                manage_search
                            </span>
                        </a> */}

                    {/* <a href="JavaScript:askforNotification();"> */}
                    {/* <a href="/" style={{ position: 'relative' }}>

                            <span className="material-symbols-outlined">
                                notifications
                            </span>
                            <div className="notification-dot"></div>
                        </a> */}

                    <Link to="/Location" className="nav-location" style={{ height: '48px', color: '#fff;background:none' }}>
                        <span className="material-symbols-outlined">
                            add_location_alt
                        </span>

                        <small id="location1">{window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
                            'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '')}</small>
                    </Link>
                </div>

            </div>

        </div >

    )
}

