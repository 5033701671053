import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";
// import { httpsCallable } from "firebase/functions";
// import { functions } from '../firebase.js'
import { useNavigate } from 'react-router-dom';
import '../css/UserList.css';
import UserCard from './UserCard';
import Loading from './Loading';
import { useCollection } from '../hooks/useCollection.js';
import { orderBy } from 'firebase/firestore';
export default function UserList() {
    // const { state } = useLocation();
    // const { actionType } = state;
    const { user } = useUserAuth();
    const [selectedUser, setSelectedUser] = useState(null);
    const [error, setError] = useState('');
    // const [userDetails] = useLocalStorage('userProfile', null);
    const [loading, setLoading] = useState(false);
    const [selectedRole, setSelectedRole] = useState([]);
    const [userList, setUserList] = useState(null);
    const [userSearchList, setUserSearchList] = useState(null);
    const [showError, setShowError] = useState(false);
    const [resetFlag, setResetFlag] = useState(false);
    const [expandParam, setExpandParam] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const navigate = useNavigate();

    const [roleList, setRoleList] = useState(['PARTICIPANT', 'ADMIN', 'ORGANIZER', 'REFREE'])

    const queryConstraints = [
        // where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ''),
        // orderBy('UserName')
        // Add more query constraints as needed
    ];

    const queryConstraintsRoles = [
        orderBy('RoleName')

        // where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ''),
        // orderBy('UserName')
        // Add more query constraints as needed
    ];

    const { documents: userListDoc, error: userListDocError } = useCollection('UserList', queryConstraints)
    const { documents: rolesDoc, error: rolesDocError } = useCollection('MasterRoles', queryConstraintsRoles)


    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    useEffect(() => {
        // console.log('organizationID', organizationID);
        getUserList();
        setRoleList(rolesDoc);

        // getMasterRoles();
        // console.log('userList : ', userList);
    }, [userListDoc, rolesDoc])



    // async function saveUserDetails() {
    //     var para = {};
    //     console.log('selectUser', selectedUser)
    //     para = {
    //         UserID: selectedUser.Userid,
    //         PlayerID: selectedUser.PlayerID,
    //         UserRole: selectedRole,
    //         ApprovalStatus: selectedUser.ApprovalStatus ? selectedUser.ApprovalStatus : 'Approved',

    //     }
    //     console.log('para', para);
    //     // console.log('functionName', functionName);
    //     const ret = await httpsCallable(functions, 'updateUserDetails');

    //     ret(para).then(async (element) => {

    //     })
    // }

    // async function getMasterRoles() {

    //     let roleLst = [];
    //     const ret = await httpsCallable(functions, "getMasterRoles");
    //     ret().then(async (result) => {
    //         // console.log(result);
    //         result.data.forEach(element => {
    //             roleLst.push({
    //                 RoleName: element.RoleName,
    //                 Docid: element.Docid,
    //             })

    //         });

    //         setRoleList(roleLst);
    //         console.log('roleList:', roleList)
    //     })
    // }

    async function getUserList() {

        let useLst = [];
        setLoading(true);
        userListDoc.forEach(element => {
            useLst.push({
                ...element,
                searchKey: (element.PlayerID ? element.PlayerID : '') + (element.UserName ? element.UserName : '')
                    + (element.Phone ? element.Phone : '') + (element.Email ? element.Email : ''),
            })

        });
        setUserList(useLst);
        setLoading(false);
        setUserSearchList(useLst);
    }

    function selectUser(val) {
        console.log('in selectUser val : ', val, userList)
        let index = userList.find(e => e.Userid === val);
        console.log('in selectUser index : ', index)
        if (index) {
            setSelectedUser(index);
            setSelectedRole(index.UserRole);
        }
        console.log('SelectedRole : ', selectedRole)
        console.log('SelectedUser : ', selectedUser)
        console.log('selectedRole include Admin', selectedRole.find(e => e.TYPE === 'admin') ? true : false)

    }

    function funSelectedRole(type, action) {
        let rList = selectedRole;
        if (action === 'ADD') {
            if (!rList.find(e => e.TYPE === type)) {
                rList.push({
                    TYPE: type
                });
            }
        }
        else {
            const index = rList.findIndex(e => e.TYPE === type);
            if (index > -1) { // only splice array when item is found
                rList.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setSelectedRole(rList)
        console.log('in funSelectedRole ', type, action, selectedRole)


    }

    function filterUser(text) {
        console.log('searchKey', text, userList)
        if (text !== '') {
            var newArray = userList.filter(function (el) {
                return el.searchKey.toUpperCase().includes(text.toUpperCase());
            }
            );
            // console.log(newArray);
            setUserSearchList(newArray);
        }

    }
    function setRole(userID, roleList) {
        let userlistL = userSearchList;
        let obj = userlistL.find(e => e.id === userID);
        obj.UserRole = roleList;
        setUserSearchList(userlistL)
        console.log('userID, roleList', userID, roleList, userlistL)
    }

    function resetParam() {
        navigate(-1);
    }
    console.log('userSearchList :: ', userSearchList)
    return (
        <div className="container-fluid">

            <div className='fixed-settings-breadcrum'>
                <div>
                    <span style={{ fontSize: '1.8rem' }} className="material-symbols-outlined">
                        person
                    </span>
                </div>
                <span style={{ position: 'relative', top: '-2px' }} onClick={resetParam} >  Profile </span>
                {!resetFlag && <span style={{ position: 'relative', top: '-2px' }}> &lt; </span>}
                <span className='active' style={{ position: 'relative', top: '-2px' }}> Update User </span>
                <span style={{ position: 'relative', top: '-2px' }}> {!resetFlag && expandParam} </span>


            </div>

            <div>
                <div className='event-search-div'>
                    <div>
                        <input type="text" id="userName" placeholder='e.g. user name, phone, code'
                            value={searchKey}
                            onChange={(e) => {
                                setSearchKey(e.target.value)
                                filterUser(e.target.value);
                            }}
                            required
                        />
                        <button className="mybutton button5" onClick={() => {
                            filterUser(searchKey)
                        }}>
                            <span className="material-symbols-outlined">
                                search
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <br />

            <div className='row no-gutters'>
                {loading && <Loading></Loading>}
                {userSearchList && userSearchList.map((userL) => {
                    return <UserCard key={userL.PlayerID} userL={userL}
                        setRole={setRole} ></UserCard>
                })}

            </div >

            <br></br>
            <div className="row no-gutters">
            </div >
        </div >

    )
}
