import React, { useEffect, useState } from 'react'
import '../css/AddEvent.css'
import { useLocation } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";
import DatePicker from "react-datepicker";
import '../css/MydatePicker.css'
import { httpsCallable } from "firebase/functions";
import { functions, projectStorage } from '../firebase.js'
import { useNavigate } from 'react-router-dom';
import Loading from './Loading.js'
import { useCollection } from '../hooks/useCollection.js';
import { orderBy } from 'firebase/firestore';
export default function AddEvent() {
    const { state } = useLocation();
    const { eventID } = state;
    const { user, logout } = useUserAuth();
    const [error, setError] = useState('');
    const [errorMssageNotice, setErrorMssageNotice] = useState('');
    const [errorMssageAnnouncement, setErrorMssageAnnouncement] = useState('');
    const [errorMssageRegulation, setErrorMssageRegulation] = useState('');
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();
    const [resetFlag, setResetFlag] = useState(false);
    const [expandParam, setExpandParam] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [organizationList, setOrganizationList] = useState([]);
    const [sportList, setSportList] = useState([]);
    const [activeSection, setActiveSection] = useState('DETAILS')

    const queryConstraints = [
        orderBy('SportName')
        // Add more query constraints as needed
    ];


    const organizationQueryConstraints = [
        orderBy('OrganizationName')
        // Add more query constraints as needed
    ];
    const { documents: sportName, error: sportNameDocError } = useCollection('MasterSportName', queryConstraints)
    // const { documents: organizationDoc, error: organizationDocError } = useCollection('OrganizationList', organizationQueryConstraints)

    const [eventDetails, setEventDetails] = useState({
        Eventid: eventID,
        EventName: '',
        EventDetails: '',
        EventType: '',
        OrganizationID: '',
        OrganizationName: '',
        OrganizerID: '',
        EventOwnerName: '',
        EventOwnerEmail: '',
        EventOwnerPhone: '',
        OrganizerLogo: '',
        EventLogo: [],
        EventCode: '',
        EventMode: '',
        SportCode: '',
        EntryCount: '',
        CategoryDetails: '',
        MinimumFee: 0,
        MaximumFee: 0,
        SportName: '',
        EventStartDate: new Date(),
        EventEndDate: new Date(),
        EventVenue: '',
        City: '',
        State: '',
        RegistrationStartDate: new Date(),
        RegistrationEndDate: new Date(),
        WithdrawalEndDate: new Date(),
        EventURL: '',
        PaymentMode: '',
        ApprovalStatus: '',
        EventStatus: 'ACTIVE',
        Comments: '',
        RegistrationOpenFlag: 'ON',
        PaymentOpenFlag: "ON",
        ShowParticipantFlag: "ON",
        ShowParticipantPostPaymentFlag: "OFF",
        LocationMap: '',
        VenueContact: '',
        MaxEntryForParticipant: -1,
        ConvenienceCharge: 5,
        IsMiscellaneousChargeMandatory: 'No',
        MiscellaneousChargeRemark: '',
        MiscellaneousChargeFees: 0,
        DiscountRemarks: '',
        DiscountValue: 0,
        OnlinePaymentModeFlag: 'ON',
        NoticeBoard: '',
        Announcement: '',
        RulesAndRegulations: '',
        CloseEventFlag: 'NO',

        RegistrationStatusOnFlag: 'ON',
        RegistrationCompletePostPaymentFlag: 'NO',
        OnlinePaymentGatewayFlag: 'ON',
        PublishDrawFlag: 'OFF',
        PublishSeedEntryFlag: 'OFF',
        PublishResultFlag: 'ON',
        PublishScheduleFlag: 'OFF',
        PublishGalleryFlag: 'OFF',

    })
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    useEffect(() => {
        console.log('in useEffect')
        // getOrganizationList();
        getSportList();
    }, [])


    async function getSportList() {
        // var para1 = {};

        // para1 = {

        // };
        // // console.log('in getOrganizationList : para', para1)
        // let sports = [];
        // const ret1 = httpsCallable(functions, 'getSportList');
        // let sCode = '';
        // ret1(para1).then(async (result) => {
        //     result.data.forEach(async element => {
        //         // console.log('element : ', element);
        //     if (sCode === '') {
        //         sCode = element.SportCode;
        //     }
        //     sports.push({
        //         Docid: element.Docid,
        //         SportName: element.SportName,
        //         SportCode: element.SportCode,
        //     });
        // })
        // console.log('sCode', sCode);
        setSportList(sportName);
        // setEventDetails({
        //     ...eventDetails,
        //     // SportCode: sCode,
        // })
        // })
    }
    // async function getOrganizationList() {
    //     var para1 = {};

    //     para1 = {
    //         organizerID: user && user.userInfo.SelectedRole && user.userInfo.SelectedRole.toUpperCase() === 'ADMIN' ? '' : user.userInfo.uid,
    //     };
    //     setLoading(true);
    //     // console.log('in getOrganizationList : para', para1)
    //     let functionName = user && user.userInfo && user.userInfo.SelectedRole && user.userInfo.SelectedRole.toUpperCase() === 'ADMIN' ? 'getAllOrganizationDetails' : 'getAllOrganizationDetailsForOrganizer';
    //     let orgList = [];
    //     let orgName = '';

    //     // console.log('functionName', functionName);
    //     const ret1 = httpsCallable(functions, functionName);
    //     ret1(para1).then(async (result) => {
    //         // console.log('in getOrganizationList : result', result)
    //         result.data.forEach(async element => {
    //             // console.log('element : ', element);
    //             if (orgName === '') {
    //                 orgName = element.OrganizationName;
    //             }
    //             orgList.push({
    //                 resultsid: element.resultsid,
    //                 PartnerName: element.PartnerName,
    //                 OrganizationName: element.OrganizationName,
    //                 OrganizerID: element.OrganizerID,
    //                 PartnerEmailID: element.PartnerEmailID,
    //                 PartnerPhone: element.PartnerPhone,
    //                 DateOfBirth: element.DateOfBirth,
    //                 City: element.City,
    //                 ApprovalStatus: element.ApprovalStatus,
    //                 Comments: element.Comments,
    //                 State: element.State,
    //                 IdentityType: element.IdentityType,
    //                 IdentityNumber: element.IdentityNumber,
    //                 OrganizationType: element.OrganizationType
    //             });
    //         })
    //         setOrganizationList(orgList);
    //         // console.log('orgName', orgName);
    //         setEventDetails({
    //             ...eventDetails,
    //             OrganizationName: orgName
    //         })
    //         setLoading(false);
    //     })
    // }

    async function createEvent() {
        console.log('in saveEventDetails');
        //
        var para = {};
        // console.log('eventDetails.OrganizationID: ', eventDetails)
        // console.log('organizationList : ', organizationList);

        // let selOrg = organizationList.find(e => e.OrganizationName === eventDetails.OrganizationName);
        let selSport = sportList.find(e => e.SportCode === eventDetails.SportCode);
        // console.log('selOrg', selOrg);
        // console.log('selSport', selSport);

        para = {
            // OrganizationID: selOrg ? selOrg.resultsid : '',
            // OrganizerID: selOrg ? selOrg.OrganizerID : '',
            // OrganizationName: selOrg ? selOrg.OrganizationName : '',
            SportName: selSport ? selSport.SportName : '',
            EventName: eventDetails.EventName,
            // EventOwnerName: selOrg ? selOrg.PartnerName : '',
            // EventOwnerEmail: selOrg ? selOrg.PartnerEmailID : '',
            // EventOwnerPhone: selOrg ? selOrg.PartnerPhone : '',
            EventVenue: eventDetails.EventVenue,
            LocationMap: eventDetails.LocationMap,
            VenueContact: eventDetails.VenueContact,
            ConvenienceCharge: eventDetails.ConvenienceCharge,
            ApprovalStatus: user && user.userInfo && user.userInfo.SelectedRole === 'ADMIN' ? 'Approved' : 'Pending',
            EventStatus: eventDetails.EventStatus,
            EventCode: eventDetails.EventCode,
            EventMode: eventDetails.EventMode,
            EventStartDate: eventDetails.EventStartDate,
            EventEndDate: eventDetails.EventEndDate,
            SportCode: eventDetails.SportCode,
            City: eventDetails.City

        };

        // console.log(para);
        setLoading(true);

        const ret = await httpsCallable(functions, "addEventDetails");
        ret(para).then(async (result) => {
            // console.log(result);

            if (result.data.EventID === '0') {

            } else {
                setEventDetails({
                    ...eventDetails,
                    Eventid: result.data.EventID
                });
                setLoading(false);
                setShowError(true);
                setTimeout(function () {
                    setShowError(false);
                    navigate("/EditEvent", { state: { eventID: result.data.EventID } });
                }, 5000);


            }

        })
    }
    // var getInitials = function (string) {
    //     var names = string.split(' '),
    //         initials = names[0].substring(0, 1).toUpperCase();

    //     if (names.length > 1) {
    //         initials += names[names.length - 1].substring(0, 1).toUpperCase();
    //     }
    //     return initials;
    // };
    function resetParam() {
        navigate(-1);
    }
    return (
        <div className="container-fluid">

            <div className='fixed-settings-breadcrum'>
                <div>
                    <span style={{ fontSize: '1.8rem' }} className="material-symbols-outlined">
                        person
                    </span>
                </div>
                <span style={{ position: 'relative', top: '-2px' }} onClick={resetParam} >  Profile </span>
                {!resetFlag && <span style={{ position: 'relative', top: '-2px' }}> &lt; </span>}
                <span className='active' style={{ position: 'relative', top: '-2px' }}> Add Event </span>
                <span style={{ position: 'relative', top: '-2px' }}> {!resetFlag && expandParam} </span>


            </div>

            <br />
            <div className="row no-gutters">

                <div className="analytics-div">
                    <h1>Create Event</h1>
                </div><br /><br />
                {loading && <Loading></Loading>}
                {!loading && <div style={{ padding: '0 10px' }}>
                    <div className="create-event-div" style={{ padding: '0 10px' }}><br />
                        <div className="row no-gutters">

                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <div className="" style={{ position: 'relative' }}>
                                    <div className="event-detail-input-div" style={{
                                        margin: '15px 0', border: 'none'
                                    }}>
                                        <label>Select Organization</label>

                                        <select onChange={(e) => {
                                            console.log('e.target', e.target.value);
                                            setEventDetails({
                                                ...eventDetails,
                                                OrganizationName: e.target.value,

                                            })
                                        }} className=""
                                            style={{ letterSpacing: '1px', borderRadius: '5px' }} name="">
                                            {/* {organizationList.map((org) => {
                                                return <option value={org.id} key={org.id}>{org.OrganizationName}</option>
                                            })} */}

                                        </select>
                                        <span></span>
                                    </div>


                                </div>

                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <div className="" style={{ position: 'relative' }}>
                                    <div className="event-detail-input-div" style={{ margin: '15px 0', border: 'none' }}>
                                        <label >Select Sport</label>
                                        <select className="" style={{ letterSpacing: '1px', borderRadius: '5px' }} name="" onChange={(e) => {
                                            // console.log('sportcode ', e.target);
                                            setEventDetails({
                                                ...eventDetails,
                                                SportCode: e.target.value
                                            })
                                        }}>
                                            {sportList.map((sport) => {
                                                return <option value={sport.SportCode} key={sport.SportCode}>{sport.SportName}</option>
                                            })}

                                        </select>
                                        <span></span>

                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <div className="event-detail-input-div">
                                    <label>Event Code (TP_BDXXXXXX)</label>
                                    <input type="text" id="organizationName" placeholder="Event Code..."

                                        onChange={(e) =>
                                            setEventDetails({
                                                ...eventDetails,
                                                EventCode: e.target.value
                                            })}

                                        value={eventDetails.EventCode}

                                        required />

                                </div>

                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <label className="labelsize" style={{
                                    color: '#348DCB',
                                    paddingLeft: '5px',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    paddingTop: '15px'
                                }}>Event Type</label>

                                <div className='row no-gutters'>
                                    <div className='col-6'>

                                        <div style={{ widht: '50%' }} >

                                            <input type="radio" checked={eventDetails.EventMode === 'OPEN' ? true : false} onChange={(e) => {
                                                e.target.checked && setEventDetails({
                                                    ...eventDetails,
                                                    EventMode: 'OPEN'
                                                });
                                            }} className="checkbox" style={{ width: '0px' }}
                                                name="EventType" id="regParticipantOPEN" value="OPEN" />
                                            <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                className="checkbox-label" id="regParticipantOPENLabel"
                                                htmlFor="regParticipantOPEN">
                                                <i className="fas fa-plus"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <i className="fas fa-check"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <span>Open Event</span>
                                            </label>

                                        </div>

                                    </div>

                                    <div className='col-6'>

                                        <div style={{ widht: '50%' }}>
                                            <input type="radio" checked={eventDetails.EventMode === 'CLOSED' ? true : false} onChange={(e) => {
                                                e.target.checked && setEventDetails({
                                                    ...eventDetails,
                                                    EventMode: 'CLOSED'
                                                });
                                            }} className="checkbox" style={{ width: '0px' }}
                                                name="EventType" id="regParticipantCLOSED" value="CLOSED" />
                                            <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                className="checkbox-label" id="regParticipantCLOSEDLabel"
                                                htmlFor="regParticipantCLOSED">
                                                <i className="fas fa-plus"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <i className="fas fa-check"
                                                    style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                                <span>Closed Event</span>
                                            </label>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="event-detail-input-div">
                                    <label>City</label>
                                    <input type="text" placeholder="City"
                                        onChange={(e) =>
                                            setEventDetails({
                                                ...eventDetails,
                                                City: e.target.value
                                            })}
                                        value={eventDetails.City}
                                        required />

                                </div>

                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <div className="event-detail-input-div">
                                    <label>Event Name</label>
                                    <input type="text" placeholder="Event Name"

                                        onChange={(e) =>
                                            setEventDetails({
                                                ...eventDetails,
                                                EventName: e.target.value
                                            })}

                                        value={eventDetails.EventName}
                                        required />

                                </div>



                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <div className="event-detail-input-div">
                                    <label>Event Start Date</label>
                                    <div className="input-group date datetimepicker11">
                                        <DatePicker
                                            showMonthDropdown
                                            showYearDropdown
                                            dateFormat="dd-MMM-yyyy"
                                            minDate={new Date()}
                                            selected={eventDetails.EventStartDate} onChange={(date) => {
                                                // console.log(date)
                                                setEventDetails({
                                                    ...eventDetails,
                                                    EventStartDate: new Date(date)
                                                })
                                            }} />
                                    </div>
                                </div>


                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">

                                <div className="event-detail-input-div">
                                    <label>Event End Date</label>
                                    <div className="input-group date datetimepicker11">
                                        <DatePicker
                                            showMonthDropdown
                                            showYearDropdown
                                            dateFormat="dd-MMM-yyyy"

                                            minDate={new Date()}
                                            selected={eventDetails.EventEndDate} onChange={(date) => {
                                                // console.log(date)
                                                setEventDetails({
                                                    ...eventDetails,
                                                    EventEndDate: new Date(date)
                                                })
                                            }} />
                                    </div>
                                </div>


                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="event-detail-input-div">
                                    <label>convenience Charge</label>
                                    <input type="number" placeholder="Convenience Charge"
                                        min={5}
                                        onChange={(e) => {
                                            if (Number(e.target.value) < 5) {
                                                setEventDetails({
                                                    ...eventDetails,
                                                    ConvenienceCharge: 5
                                                })
                                            } else {
                                                setEventDetails({
                                                    ...eventDetails,
                                                    ConvenienceCharge: Number(e.target.value)
                                                })
                                            }
                                        }
                                        }
                                        value={eventDetails.ConvenienceCharge}
                                        required />


                                </div>

                            </div>

                            {showError && <div className="col-lg-12 col-md-12 col-sm-12">
                                <br />
                                <span style={{ color: 'green' }}>Event details saved Successfully !! Please wait, you will be redirected to set other settigns for Event... </span>
                            </div>}

                            <div className="col-lg-6 col-md-6 col-sm-12" ><br />

                                {/* <button type="button" style={{ width: '100px' }} className="mybutton button5"
                                    name="button" >CREATE</button> */}

                                <button className="mybutton button5" onClick={createEvent} style={{ width: '150px', height: '40px' }}>
                                    <div style={{ display: !loading ? 'block' : 'none' }}>
                                        <span
                                            style={{ position: 'relative', top: '-1px', fontSize: '1rem' }}>CREATE</span>
                                    </div>
                                    <div className='btn-loading' style={{ display: loading ? 'block' : 'none' }}>
                                        <lottie-player id="btnSendOTPLoad"
                                            src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                            speed="0.7" loop autoplay></lottie-player>
                                    </div>
                                </button>

                            </div>

                        </div>
                        <br />
                    </div><br />
                </div>}
            </div>
        </div>

    )
}
