import React, { useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import '../css/Events.css';
import Loading from './Loading.js';
import NewEventCard from './NewEventCard'
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from "../context/useLocalStorage";
import { useUserAuth } from '../context/UserAuthcontext.js';
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
// import { isEditable } from '@testing-library/user-event/dist/utils/index.js';
export default function Events() {
    const { user } = useUserAuth()
    // const [minValue, setMinValue] = useState(2018);
    const minValue = 2018;
    const maxValue = new Date().getFullYear();
    const [sliderValue, setSliderValue] = useState(new Date().getFullYear());

    // const city = window.localStorage.getItem("userLocation") ? window.localStorage.getItem("userLocation").replaceAll('"', '') : 'All City';
    const city = !window.localStorage.getItem("userLocation") ? 'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
        'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '');

    const [loading, setLoading] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [eventListFilter, setEventListFilter] = useState([]);
    const [sliderLeftPer, setSliderLeftPer] = useState({ left: '100%' });
    const [isAdmin, setIsAdmin] = useState(false);
    const [userPhone, setUserPhone] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [filterText, setFilterText] = useState('');
    const queryEventCity = [
        where('City', '==', city),
        // orderBy('EventStartDate', 'desc'),
        // limit(30)
    ]
    const queryEvent = [

    ]
    const { documents: eventDoc, error: eventDocError } = useCollection('EventList', (city === 'All City' || city === 'All - Location') ? queryEvent : queryEventCity)

    // console.log('eventDoc', eventDoc)
    // const [eventCount, setEventCount] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    var options = {
        year: '2-digit',
        // year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    // let eventCntList = [];
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    useEffect(() => {

        setUserPhone(user && user.userInfo && user.userInfo.Phone && user.userInfo.Phone.substring(user.userInfo.Phone.length - 10, user.userInfo.Phone.length));
        if (user && user.userInfo && user.userInfo.UserRole && user.userInfo.UserRole.find(e => e.TYPE === 'ADMIN')) {
            setIsAdmin(true)
            setFilterText('In-Review')
        } else {
            setFilterText('Active')
        }
        getEventList()
        // filter();
    }, [eventDoc])
    useEffect(() => {
        filter();
    }, [filterText, eventList])
    function getEventList() {
        // const getEventList = async () => {
        // console.log('in getEventList');
        //        const eventSummaryBySports = httpsCallable(functions, "getAllEventWithLocation");
        // OrganizationName
        // setLoading(true);
        // let para = {

        //     City: city === 'All City' ? 'All' : city,
        // };
        // console.log('para', para)
        // return await eventSummaryBySports(para)
        //     .then(async (result) => {

        let data = eventDoc;
        var refdate = "";
        var today = new Date();
        var newData = [];
        var eventSDate = '';
        var eventEDate = '';
        // console.log(data)
        let _inreview = 0;
        data.forEach(element => {
            eventEDate = '';
            if (element.EventStatus === 'RegistrationPending') {
                _inreview = _inreview + 1
            }
            eventSDate = '';
            if (element.EventStartDate) {
                refdate = element.EventStartDate && element.EventStartDate._seconds ? new Date(element.EventStartDate._seconds * 1000) :
                    element.EventStartDate && element.EventStartDate.seconds ? new Date(element.EventStartDate.seconds * 1000) :
                        element.EventStartDate && element.EventStartDate ? new Date(element.EventStartDate) : new Date();
                eventSDate = refdate.toLocaleDateString("en-IN", options);
            }
            else {
                eventSDate = "";
            }
            if (element.EventEndDate) {
                var eDate = element.EventEndDate._seconds ? new Date(element.EventEndDate._seconds * 1000 + 60 * 60 * 24 * 1000) :
                    element.EventEndDate.seconds ? new Date(element.EventEndDate.seconds * 1000 + 60 * 60 * 24 * 1000) : new Date();
                var eDate = element.EventEndDate && element.EventEndDate._seconds ? new Date(element.EventEndDate._seconds * 1000 + 60 * 60 * 24 * 1000) :
                    element.EventEndDate && element.EventEndDate.seconds ? new Date(element.EventEndDate.seconds * 1000 + 60 * 60 * 24 * 1000) :
                        element.EventEndDate && element.EventEndDate ? new Date(element.EventEndDate + 1) : new Date();

                eventEDate = eDate.toLocaleDateString("en-IN", options);

            }
            else {
                eventEDate = '';
            }
            if (refdate <= today && eDate >= today && element.EventStatus.toUpperCase() === 'ACTIVE') {
                element.isLive = true;
            } else {
                element.isLive = false;
            }
            newData.push({
                ...element,
                EventSDate: eventSDate,
                EventEDate: eventEDate,
                Fees: element.MinimumFee ? (Number(element.MinimumFee).toLocaleString('en-IN', curFormat)) : "",
                flag: true,
                searchKey: element.EventCode + element.EventName + element.EventOwnerName + element.OrganizationName + element.City

            })
        });
        console.log('newData', newData);

        setEventList(newData)
        setEventListFilter(newData);

        if (isAdmin && _inreview > 0) {
            setFilterText('In-Review')

        }
        else {
            setFilterText('Active')
        }
        filter()
        setLoading(false);

        // return newData;
        // });

    }
    // const mEvents = useMemo(async () => {
    //     return await getEventList().then(result => {
    //         setEventList(result);

    //         return result;
    //     })

    // }, [city]);


    function searchEvent() {
        // console.log('in searchEvent : ', searchKey);

        var newArray = eventList.filter(function (el) {
            return el.searchKey.toUpperCase().includes(searchKey.toUpperCase());
        }
        );
        setFilterText('All')
        // console.log(newArray);
        setEventListFilter(newArray);
    }

    function filter() {
        console.log('text', filterText, eventList)
        var newArray = eventList;
        if (filterText === 'In-Review') {
            newArray = eventList.filter(e => e.EventStatus === 'RegistrationPending')
        } else if (filterText === 'In-Active') {
            newArray = eventList.filter(e => e.EventStatus === 'In-Active')
        }
        else if (filterText === 'Active') {
            newArray = eventList.filter(e => e.EventStatus === 'Active')
        } else if (filterText === 'Completed') {
            newArray = eventList.filter(e => e.EventStatus === 'Closed')
        } else if (filterText === 'Cancelled') {
            newArray = eventList.filter(e => e.EventStatus === 'Cancelled')
        } else if (filterText === 'On-Hold') {
            newArray = eventList.filter(e => e.EventStatus === 'On-hold')
        }
        else if (filterText === 'My Events') {
            newArray = eventList.filter(e => e.CreatedBy === user.userInfo.uid)
        }
        // var newArray = eventList.filter(e => e.EventStatus === status);
        setEventListFilter(newArray);

    }
    console.log('1 eventListFilter', eventListFilter)
    // let eDate = '';
    let index = 0;
    return (
        <div>

            <section id="event-new">
                <div className="container">
                    <div className="heading" style={{ position: 'relative', padding: '10px 0 20px 0', justifyContent: 'center' }}>
                        {/* <span className="material-symbols-outlined">
                            free_cancellation
                        </span> */}
                        <div>
                            <h4 style={{
                                top: '0',
                                fontWeight: '1000',
                                margin: '0',
                                paddingRight: '10px',
                                fontWeight: 'normal'
                            }}>Events in <strong>{city}</strong></h4>
                            <Link to='/Location'>
                                <div style={{
                                    position: 'absolute',
                                    bottom: '2px',
                                    left: '50.7%',
                                    // width: '30px',
                                    // height: '30px',
                                    background: '#df3aa3',
                                    display: 'flex',
                                    // flexDirection: 'column',
                                    padding: '0 5px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                }}>
                                    <span style={{
                                        padding: '0',
                                        color: '#fff',
                                        fontSize: '1.2rem'
                                    }} className="material-symbols-outlined">
                                        edit
                                    </span>
                                    <small style={{ color: '#fff' }}>Change City</small>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className='row no-gutters'>

                        {/* <div className='col-lg-6 col-md-6 col-sm-12'>

                            <div className="" style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                <div className="year-range">
                                    <div className="slider-value">
                                        <span id="sliderValue" className="show" style={sliderLeftPer}>{sliderValue}</span>
                                    </div>
                                    <div className="draging-field">
                                        <div className="value left">
                                            {minValue}
                                        </div>
                                        <input id="inputSlider" type="range" min={minValue} max={maxValue} value={sliderValue}
                                            onChange={(e) => {

                                                let difference = maxValue - minValue;
                                                // console.log((e.target.value - minValue) * 100 / difference + '%');
                                                setSliderLeftPer({ left: (e.target.value - minValue) * 100 / difference + '%' });

                                                // sliderLeftPer.current = { left: (e.target.value - minValue) * 100 / difference + '%' };

                                                setSliderValue(e.target.value);
                                            }}
                                            steps="1" />
                                        <div className="value right" id='rightValue'>
                                            {maxValue}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> */}
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                                <div className="" style={{
                                    height: '100%',
                                    position: 'relative',
                                    top: '-3px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '75%'
                                }}>
                                    {/* <br className='small'></br> */}
                                    <div className='event-search-div'>
                                        <div style={{ maxWidth: '100%' }}>
                                            <input type="text" style={{ height: '40px' }} id="userName" placeholder={city === 'All' ? 'e.g. event, city, organizer ....' : 'e.g. event, organizer ....'} required
                                                onChange={(e) => {
                                                    setSearchKey(e.target.value)
                                                    searchEvent()

                                                }} value={searchKey} />
                                            <button className="mybutton button5" style={{ height: '40px' }} onClick={() => {
                                                searchEvent();

                                            }}>
                                                <span className="material-symbols-outlined">
                                                    search
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: '25%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', }}
                                    onClick={() => {
                                        setShowFilter(!showFilter)
                                    }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <div style={{
                                            width: '60px',
                                            height: '40px',
                                            background: '#333C5D',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '10px'
                                        }}>
                                            <span style={{ color: '#fff' }} className="material-symbols-outlined">
                                                tune
                                            </span>
                                        </div>
                                        <small style={{
                                            color: '#333C5D',
                                            fontSize: '0.8rem',
                                            margin: '0'
                                        }}>{filterText}</small>
                                    </div>
                                </div>
                            </div>

                            <div className={showFilter ? 'filter-pop-up open' : 'filter-pop-up'}>
                                <h1 style={{
                                    padding: '20px 0 0 0',
                                    margin: '0',
                                    fontSize: '1.8rem',
                                    textAlign: 'center',
                                    color: '#888',
                                    color: '#333C5D',
                                    fontWeight: 'bold',
                                }}>Event Filter - {city}</h1>
                                <div onClick={() => {
                                    setShowFilter(false)
                                }}>
                                    <span style={{
                                        position: 'absolute',
                                        right: '15px',
                                        top: '20px',
                                        background: '#348DCB',
                                        color: '#fff',
                                        width: '30px',
                                        height: '30px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '50%',
                                        fontSize: '1.2rem',
                                        cursor: 'pointer'
                                    }}

                                        className="material-symbols-outlined">
                                        close
                                    </span>
                                </div>
                                <hr />
                                <div className='container-fluid'>
                                    <div className='row no-gutters'>
                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <div className="" style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <div className="year-range">
                                                    <div className="slider-value">
                                                        <span id="sliderValue" className="show" style={sliderLeftPer}>{sliderValue}</span>
                                                    </div>
                                                    <div className="draging-field">
                                                        <div className="value left">
                                                            {minValue}
                                                        </div>
                                                        <input id="inputSlider" type="range" min={minValue} max={maxValue} value={sliderValue}
                                                            onChange={(e) => {

                                                                let difference = maxValue - minValue;
                                                                // console.log((e.target.value - minValue) * 100 / difference + '%');
                                                                setSliderLeftPer({ left: (e.target.value - minValue) * 100 / difference + '%' });

                                                                // sliderLeftPer.current = { left: (e.target.value - minValue) * 100 / difference + '%' };

                                                                setSliderValue(e.target.value);
                                                            }}
                                                            steps="1" />
                                                        <div className="value right" id='rightValue'>
                                                            {maxValue}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ padding: '6px 0' }}></div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12'>

                                            <div>
                                                <div className='container-fluid'>
                                                    <div className='row no-gutters'>

                                                        <div className='col-6' style={{ padding: '10px' }} >

                                                            <div style={{ widht: '50%', position: 'relative' }} onClick={() => {
                                                                setFilterText('All')
                                                                filter();
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    background: '#333C5D',
                                                                    color: '#fff',
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    left: '60%',
                                                                    width: 'auto',
                                                                    padding: '0 10px',
                                                                    height: '20px',
                                                                    borderRadius: '20px'
                                                                }}>
                                                                    <span style={{ fontSize: '0.8rem', position: 'relative', top: '0.5px' }}>{eventList ? eventList.length : 0}</span>
                                                                </div>

                                                                <input type="radio"
                                                                    className="checkbox" style={{ width: '0px' }}
                                                                    checked={filterText === 'All' ? true : false}
                                                                    name="EventType" id="regParticipantAll" value="All" />
                                                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                                    className="checkbox-label" id="regParticipantAllLabel"
                                                                    htmlFor="regParticipantAll">
                                                                    <i className="fas fa-plus"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <i className="fas fa-check"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <span style={{ fontSize: '1.1rem' }}>All</span>
                                                                </label>

                                                            </div>

                                                        </div>

                                                        {isAdmin && <div className='col-6' style={{ padding: '10px' }}>

                                                            <div style={{ widht: '50%', position: 'relative' }} onClick={() => {
                                                                setFilterText('In-Review')
                                                                filter();
                                                            }} >
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    background: '#333C5D',
                                                                    color: '#fff',
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    left: '60%',
                                                                    width: 'auto',
                                                                    padding: '0 10px',
                                                                    height: '20px',
                                                                    borderRadius: '20px'
                                                                }}>
                                                                    <span style={{ fontSize: '0.8rem', position: 'relative', top: '0.5px' }}>{eventList ? eventList.filter(e => e.EventStatus === 'RegistrationPending').length : 0}</span>
                                                                </div>

                                                                <input type="radio"
                                                                    className="checkbox"
                                                                    checked={filterText === 'In-Review' ? true : false} style={{ width: '0px' }}
                                                                    name="EventType" id="regParticipantIn-Review" value="In-Review" />
                                                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                                    className="checkbox-label" id="regParticipantIn-ReviewLabel"
                                                                    htmlFor="regParticipantIn-Review">
                                                                    <i className="fas fa-plus"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <i className="fas fa-check"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <span style={{ fontSize: '1.1rem' }}>In-Review</span>
                                                                </label>

                                                            </div>

                                                        </div>}

                                                        {isAdmin && <div className='col-6' style={{ padding: '10px' }}>

                                                            <div style={{ widht: '50%', position: 'relative' }} onClick={() => {
                                                                setFilterText('In-Active')
                                                                filter();
                                                            }} >

                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    background: '#333C5D',
                                                                    color: '#fff',
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    left: '60%',
                                                                    width: 'auto',
                                                                    padding: '0 10px',
                                                                    height: '20px',
                                                                    borderRadius: '20px'
                                                                }}>
                                                                    <span style={{ fontSize: '0.8rem', position: 'relative', top: '0.5px' }}>{eventList ? eventList.filter(e => e.EventStatus === 'Inactive').length : 0}</span>
                                                                </div>

                                                                <input type="radio"
                                                                    className="checkbox"
                                                                    checked={filterText === 'In-Active' ? true : false} style={{ width: '0px' }}
                                                                    name="EventType" id="regParticipantIn-Active" value="In-Active" />
                                                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                                    className="checkbox-label" id="regParticipantIn-ActiveLabel"
                                                                    htmlFor="regParticipantIn-Active">
                                                                    <i className="fas fa-plus"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <i className="fas fa-check"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <span style={{ fontSize: '1.1rem' }}>In-Active</span>
                                                                </label>

                                                            </div>

                                                        </div>}

                                                        <div className='col-6' style={{ padding: '10px' }}>

                                                            <div style={{ widht: '50%', position: 'relative' }} onClick={() => {
                                                                setFilterText('Active')
                                                                filter();
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    background: '#333C5D',
                                                                    color: '#fff',
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    left: '60%',
                                                                    width: 'auto',
                                                                    padding: '0 10px',
                                                                    height: '20px',
                                                                    borderRadius: '20px'
                                                                }}>
                                                                    <span style={{ fontSize: '0.8rem', position: 'relative', top: '0.5px' }}>{eventList ? eventList.filter(e => e.EventStatus === 'Active').length : 0}</span>
                                                                </div>
                                                                <input type="radio"
                                                                    className="checkbox"
                                                                    checked={filterText === 'Active' ? true : false} style={{ width: '0px' }}
                                                                    name="EventType" id="regParticipantActive" value="Active" />
                                                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                                    className="checkbox-label" id="regParticipantActiveLabel"
                                                                    htmlFor="regParticipantActive">
                                                                    <i className="fas fa-plus"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <i className="fas fa-check"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <span style={{ fontSize: '1.1rem' }}>Active</span>
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <div className='col-6' style={{ padding: '10px' }}>

                                                            <div style={{ widht: '50%', position: 'relative' }} onClick={() => {
                                                                console.log('Completed clicked')
                                                                setFilterText('Completed')
                                                                filter();
                                                            }}>

                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    background: '#333C5D',
                                                                    color: '#fff',
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    left: '60%',
                                                                    width: 'auto',
                                                                    padding: '0 10px',
                                                                    height: '20px',
                                                                    borderRadius: '20px'
                                                                }}>
                                                                    <span style={{ fontSize: '0.8rem', position: 'relative', top: '0.5px' }}>{eventList ? eventList.filter(e => e.EventStatus === 'Completed').length : 0}</span>
                                                                </div>
                                                                <input type="radio"
                                                                    className="checkbox"
                                                                    checked={filterText === 'Completed' ? true : false} style={{ width: '0px' }}
                                                                    name="EventType" id="regParticipantCompleted" value="Completed" />
                                                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                                    className="checkbox-label" id="regParticipantCompletedLabel"
                                                                    htmlFor="regParticipantCompleted">
                                                                    <i className="fas fa-plus"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <i className="fas fa-check"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <span style={{ fontSize: '1.1rem' }}>Completed</span>
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <div className='col-6' style={{ padding: '10px' }}>

                                                            <div style={{ widht: '50%', position: 'relative' }} onClick={() => {
                                                                setFilterText('Cancelled')
                                                                filter();
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    background: '#333C5D',
                                                                    color: '#fff',
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    left: '60%',
                                                                    width: 'auto',
                                                                    padding: '0 10px',
                                                                    height: '20px',
                                                                    borderRadius: '20px'
                                                                }}>
                                                                    <span style={{ fontSize: '0.8rem', position: 'relative', top: '0.5px' }}>{eventList ? eventList.filter(e => e.EventStatus === 'Cancelled').length : 0}</span>
                                                                </div>
                                                                <input type="radio"
                                                                    className="checkbox"
                                                                    checked={filterText === 'Cancelled' ? true : false} style={{ width: '0px' }}
                                                                    name="EventType" id="regParticipantCancelled" value="Cancelled" />
                                                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                                    className="checkbox-label" id="regParticipantCancelledLabel"
                                                                    htmlFor="regParticipantCancelled">
                                                                    <i className="fas fa-plus"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <i className="fas fa-check"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <span style={{ fontSize: '1.1rem' }}>Cancelled</span>
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <div className='col-6' style={{ padding: '10px' }}>

                                                            <div style={{ widht: '50%', position: 'relative' }} onClick={() => {
                                                                setFilterText('On-Hold')
                                                                filter();
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    background: '#333C5D',
                                                                    color: '#fff',
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    left: '60%',
                                                                    width: 'auto',
                                                                    padding: '0 10px',
                                                                    height: '20px',
                                                                    borderRadius: '20px'
                                                                }}>
                                                                    <span style={{ fontSize: '0.8rem', position: 'relative', top: '0.5px' }}>{eventList ? eventList.filter(e => e.EventStatus === 'On-hold').length : 0}</span>
                                                                </div>
                                                                <input type="radio"
                                                                    className="checkbox"
                                                                    checked={filterText === 'On-Hold' ? true : false} style={{ width: '0px' }}
                                                                    name="EventType" id="regParticipantOnHold" value="OnHold" />
                                                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                                    className="checkbox-label" id="regParticipantOnHoldLabel"
                                                                    htmlFor="regParticipantOnHold">
                                                                    <i className="fas fa-plus"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <i className="fas fa-check"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <span style={{ fontSize: '1.1rem' }}>On-Hold</span>
                                                                </label>
                                                            </div>

                                                        </div>

                                                        {user && user.userInfo && <div className='col-6' style={{ padding: '10px' }}>

                                                            <div style={{ widht: '50%', position: 'relative' }} onClick={() => {
                                                                setFilterText('My Events')
                                                                filter();
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    background: '#333C5D',
                                                                    color: '#fff',
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    left: '60%',
                                                                    width: 'auto',
                                                                    padding: '0 10px',
                                                                    height: '20px',
                                                                    borderRadius: '20px'
                                                                }}>
                                                                    <span style={{ fontSize: '0.8rem', position: 'relative', top: '0.5px' }}>{eventList.filter(e => e.CreatedBy === user.userInfo.uid).length}</span>
                                                                </div>
                                                                <input type="radio"
                                                                    className="checkbox"
                                                                    checked={filterText === 'My Events' ? true : false} style={{ width: '0px' }}
                                                                    name="EventType" id="regParticipantMyEvents" value="MyEvents" />
                                                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                                                    className="checkbox-label" id="regParticipantMyEventsLabel"
                                                                    htmlFor="regParticipantMyEvents">
                                                                    <i className="fas fa-plus"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <i className="fas fa-check"
                                                                        style={{ paddingRight: '10px', fontSize: '0.8rem' }}></i>
                                                                    <span style={{ fontSize: '1.1rem' }}>My Events</span>
                                                                </label>
                                                            </div>

                                                        </div>}

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        {/* <div className='col-lg-12 col-md-12 col-sm-12'>
                            {isAdmin && <span style={{
                                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
                            }} onClick={(e) => filter('Pending')}>Pending</span>}
                            {isAdmin && '|'}
                            <span style={{
                                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
                            }} onClick={(e) => filter('Active')}>Active</span> |
                            <span style={{
                                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
                            }} onClick={(e) => filter('Completed')}>Completed</span> |
                            <span style={{
                                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
                            }} onClick={(e) => filter('Cancelled')}>Cancelled</span> |
                            <span style={{
                                color: 'blue', textDecoration: 'underline', cursor: 'pointer'
                            }} onClick={(e) => filter('On-Hold')}>On-Hold</span>
                        </div> */}
                    </div><hr style={{ marginTop: '0' }} />

                    <div className="row no-gutters">
                        {/* {loading && <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json" style={{ height: '400px' }} background="transparent" speed="1" loop autoplay></lottie-player>} */}
                        {loading && <Loading height='400px' weight='100%' ></Loading>}

                        {

                            eventListFilter.map((event) => {

                                var eDate = new Date(event.EventStartDate.seconds * 1000 + 60 * 60 * 24 * 1000);
                                console.log('SON.stringify(', event, eDate.getFullYear());

                                if (eDate.getFullYear() === Number(sliderValue)) {
                                    // if (isAdmin || (!isAdmin && event.EventStatus === 'RegistrationPending' &&
                                    //     event.EventOwnerPhone.includes(userPhone)) ||
                                    //     (!isAdmin && event.EventStatus === 'Inactive' &&
                                    //         event.EventOwnerPhone.includes(userPhone)) ||
                                    //     (!isAdmin && event.EventStatus !== 'RegistrationPending')) {

                                    if (isAdmin || event.EventStatus === 'Active' ||
                                        (!isAdmin && event.EventStatus === 'Inactive' &&
                                            event.EventOwnerPhone.includes(userPhone))) {
                                        return <NewEventCard key={event.Eventid} keyIndex={index++} event={event} action={'DETAIL'} >{event.EventName}</NewEventCard>
                                    }
                                    else {
                                        return null
                                        // return <NewEventCard key={event.Eventid} keyIndex={index++} event={event} action={'DETAIL'} >{event.EventName}</NewEventCard>
                                    }
                                }
                                else {
                                    return null
                                }
                            })


                        }
                        {/* {(eventListFilter.length <= 0) ? "No event found for given search key, Please enter tournament name, Orgaizer Name or City to search  " : ""} */}

                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="item" style={{ margin: '30px 0 100px 0' }}>
                                <a href="#event-new" className="event-card">
                                    <div className="event-card-img">
                                        <img src="img/eventnew2.png" alt="" />
                                    </div>

                                    <div className="event-card-content">
                                        <h1 className="event-name">Prakash Padukone Badminton Tournament 2022 Badminton Tournament</h1>
                                        <h2 className="event-organiser">Prakash Padukone Badminton Academy</h2>
                                        <h3 className="rating">
                                            <div className="">
                                                <span className="material-symbols-outlined">star</span>
                                                <span className="material-symbols-outlined">star</span>
                                                <span className="material-symbols-outlined">star</span>
                                                <span className="material-symbols-outlined">star_half</span>
                                                <span className="material-symbols-outlined grade">grade</span>
                                            </div>
                                            <small>1,724</small>
                                        </h3>

                                        <div className="event-schedule-div">
                                            <div className="details">
                                                <div className="">
                                                    <h3>Chennai</h3>
                                                    <h4>Location</h4>
                                                </div>
                                                <div className="">
                                                    <h3>12 Jun 2022</h3>
                                                    <h4>Starting Date</h4>
                                                </div>
                                                <div className="">
                                                    <h3>₹ 600</h3>
                                                    <h4>Entry Fee</h4>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <button type="button" className="mybutton button5 event-card-button" name="button">Details</button>
                                                </div>
                                                <div className="col-7">
                                                    <button type="button" className="mybutton button5 event-card-button entries"
                                                        style={{ background: 'none', border: '2px solid #ddd', color: '#aaa' }} name="button">
                                                        <img src="./img/multipleuser.png" alt="" />
                                                        132
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </a>
                            </div>
                        </div> */}

                    </div>

                </div>

                {
                    (index <= 0) ? <>
                        <br /><br />
                        <div className='event-page-error-message'>
                            <h1>No events found for the provided search criteria. Please try searching by event name, organizer name, or city...</h1>
                        </div>
                        <br />
                    </> : ""
                }

                <br />
            </section >

            {/* <div className="col-lg-12 col-md-8 col-sm-12" style={{ padding: '0' }}>

                <div className="reg-participant-form-field profile-name-input" >
                    <input type="text" id="userName" placeholder='Search Key' required onChange={(e) => {
                        setSearchKey(e.target.value)

                    }} value={searchKey} />
                    <button className="mybutton button5" onClick={() => {
                        searchEvent();

                    }}>Search Event
                    </button>
                </div>
            </div>
 */}

        </div >
    )
}
