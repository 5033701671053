import React, { useState, useEffect } from 'react'
// import { useLocation, useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// import { functions } from '../firebase.js'
// import { httpsCallable } from "firebase/functions";
import EventDetailsMenu from './EventDetailsMenu';
import EDTournamentDetails from './EDTournamentDetails';
// import CategoryCartItem from './CategoryCartItem';
import Loading from './Loading.js';
import '../css/EventRegistration.css'
import '../css/PlayerParticipation.css'
import EntryTicket from './EntryTicket.js';
import PaymentSection from './PaymentSection.js';
import SignIn from './SignIn.js';
import { useUserAuth } from '../context/UserAuthcontext.js';
// import { useLocalStorage } from '../context/useLocalStorage.js';
// import { useDocument } from '../hooks/useDocument.js';
import { useCollection } from '../hooks/useCollection.js';
import { where } from 'firebase/firestore';
import { useDocument } from '../hooks/useDocument.js';

export default function PlayerParticipation() {
    const { eventID, playerID } = useParams()
    console.log('eventID, playerID', eventID, playerID)

    const { user } = useUserAuth();

    const [isPayClicked, setIsPayClicked] = useState(false);
    const [loginFlagForWithdraw, setLoginFlagForWithdraw] = useState(false);
    const [loading, setLoading] = useState(false);
    const [objPlayerParticipant, setObjPlayerParticipant] = useState({
        playerParticipantDetails: [],
        playerParticipantCount: 0,
        showFlag: false
    });
    const [signinFlag, setSigninFlag] = useState(true);
    const [isNewUser, setIsNewUser] = useState(false);
    const [paymentSection, setPaymentSection] = useState({
        flag: false,
        pendingCategory: []
    });
    const queryConstraintsRegistrationPlayer = [
        where('EventID', '==', eventID),
        where('ParticipantID', '==', playerID)
        // orderBy('UserName')
        // Add more query constraints as needed
    ];

    const queryConstraintsRegistrationPartner = [
        where('EventID', '==', eventID),
        where('PartnerPlayerID', '==', playerID)
        // orderBy('UserName')
        // Add more query constraints as needed
    ];
    const { document: eventDetails, error: eventDetailsError } = useDocument('EventList', eventID)
    // console.log('eventDetails, error: eventDetailsError', eventDetails, eventDetailsError)
    const { documents: palyerPartcipationDoc, error: palyerPartcipationDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistrationPlayer)
    // console.log('palyerPartcipationDoc, palyerPartcipationDocError :: ', palyerPartcipationDoc, palyerPartcipationDocError)

    const { documents: partnerPartcipationDoc, error: partnerPartcipationDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistrationPartner)
    // console.log('partnerPartcipationDoc, partnerPartcipationDocError :: ', partnerPartcipationDoc, partnerPartcipationDocError)


    useEffect(() => {
        console.log('in useEffect 1')
        if (!isNewUser) {
            console.log('not new user', isNewUser)
            if (user.isLoggedIn) {
                if (user.userInfo) {
                    // userDetails && userDetails.id === user.userInfo.id &&
                    setSigninFlag(true)
                }
            }
        }
    }, [user])
    useEffect(() => {
        console.log('in useEffect 2')
        let participantDetailsObj = [];
        let pendingPrize = 0;
        let pendingCnt = 0;
        let pendingCategory = []
        !palyerPartcipationDocError && palyerPartcipationDoc && palyerPartcipationDoc.forEach(element => {
            console.log('palyerPartcipationDoc', element)
            participantDetailsObj.push({
                ...element,
                RegType: 'Self'
            })
            // console.log('1. element.PaymentStatus', element.PaymentStatus, totalFees, Number(element.Fees))

            if (user && user.userInfo) {


                if (element.PaymentStatus === 'Pending' && element.CreatedBy === user.userInfo.uid) {
                    pendingPrize = pendingPrize + Number(element.Fees)
                    pendingCnt = pendingCnt + 1
                    pendingCategory.push(element);
                }
            }
            // console.log('2. element.PaymentStatus', element.PaymentStatus, totalFees, Number(element.Fees))

        });
        !partnerPartcipationDocError && partnerPartcipationDoc && partnerPartcipationDoc.forEach(element1 => {
            console.log('partnerPartcipationDoc', element1)
            participantDetailsObj.push({
                ...element1,
                RegType: 'Partner'
            })
        });

        setObjPlayerParticipant({
            playerParticipantDetails: participantDetailsObj,
            playerParticipantCount: participantDetailsObj.length,
            showFlag: true
        });
        setIsPayClicked(false);

        console.log('pending', pendingCategory, pendingCnt);
        setPaymentSection({
            flag: pendingCnt > 0 ? true : false,
            pendingCategory: pendingCategory,
        })
        setLoading(false);


    }, [palyerPartcipationDoc, partnerPartcipationDoc]);

    // useEffect(() => {
    //     // console.log(user)
    //     // console.log('SigninFlag', signinFlag)
    //     setIsPayClicked(false);


    //     // if (!(user && user.isLoggedIn && user.userInfo)) {
    //     //     setSigninFlag(false);
    //     // }
    //     // console.log(eventDetails);
    //     var para1 = {};

    //     async function fetchData() {
    //         setLoading(true);
    //         para1 = {
    //             EventID: eventDetails.Eventid,
    //             PlayerID: playerID

    //         };
    //         // console.log(para1);
    //         const ret1 = httpsCallable(functions, "getAllRegisteredEventListByPlayerCode");
    //         ret1(para1).then(async (result) => {
    //             // console.log('result', result);
    //             setObjPlayerParticipant({
    //                 playerParticipantDetails: result.data.entryDetails,
    //                 playerParticipantCount: result.data.entryDetails.length,
    //                 showFlag: true
    //             });
    //             // let pending = result.data.entryDetails.filter(e => e.PaymentStatus === 'Pending' && e.RegType === 'Self')
    //             let pending = result.data.entryDetails.filter(e => e.PaymentStatus === 'Pending')

    //             // console.log('pending', pending);
    //             setPaymentSection({
    //                 flag: pending.length <= 1 ? false : true,
    //                 pendingCategory: pending,
    //             })
    //             setLoading(false);
    //         });

    //     }
    //     fetchData();
    // }, []);
    function setSignIn(flag, userData) {
        // console.log(flag)
        setSigninFlag(flag);
        // setUserDetails(userData);
    }

    function setLoginFlag(flag) {
        // console.log('in setLoginFlag', flag)
        // setLoginFlagForWithdraw(true)
        setSigninFlag(flag);
    }
    // console.log('objPlayerParticipant.playerParticipantDetails', objPlayerParticipant.playerParticipantDetails);
    console.log('paymentSection : ', paymentSection, 'ObjPlayerParticipant', objPlayerParticipant);
    return (
        <>
            {!signinFlag && <SignIn setSignIn={setSignIn} setIsNewUser={setIsNewUser} ></SignIn>}

            {signinFlag &&
                <>
                    {eventDetails && <EventDetailsMenu calledFrom='Participant'
                        eventID={eventID}
                        eventDetails={eventDetails}
                    />}
                    {paymentSection && paymentSection.flag && <PaymentSection Category={paymentSection.pendingCategory}
                        ConvenienceCharge={eventDetails && eventDetails.ConvenienceCharge}
                        PlayerID={playerID}
                        EventCode={eventDetails && eventDetails.EventCode} setIsPayClicked={setIsPayClicked}></PaymentSection>
                    }
                    <br />
                    <div className="container-fluid">

                        <div className="row no-gutters">
                            {loading && <Loading></Loading>}

                            <br></br>
                            <div className="col-lg-8 col-md-8 col-sm-12">

                                <div className="row no-gutters" id="divRegEvent">


                                    {objPlayerParticipant && objPlayerParticipant.showFlag &&
                                        objPlayerParticipant.playerParticipantDetails.map((registrerObj) => {
                                            return <EntryTicket key={registrerObj.CategoryName}
                                                event={eventDetails}
                                                participant={registrerObj}
                                                setIsPayClicked={setIsPayClicked}
                                                showQR={false}
                                                setLoginFlag={setLoginFlag}
                                                playerID={playerID}
                                            ></EntryTicket>
                                        })}

                                </div>

                            </div>

                            {eventDetails && <EDTournamentDetails eventDetails={eventDetails}
                                showRegistration={true}
                                showRegistrationButton={false} />}
                        </div>
                    </div >
                </>
            }
        </>

    )

}
