import React, { useState, useEffect } from 'react'
import Disclaimer from './Disclaimer';
import RichTextEditor from 'react-rte';
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js';
import { useUserAuth } from '../context/UserAuthcontext';
// import { useLocalStorage } from "../context/useLocalStorage";

import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import { useDocument } from '../hooks/useDocument.js';
import TournamentCategory from './TournamentCategory'
import useDocumentUpdate from '../hooks/useDocumentUpdate';
import { useCollection } from '../hooks/useCollection.js';
import { where, orderBy } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
export default function EDAboutEvent(props) {
    // console.log('props', props)
    const [ruleAndRegulation, setRuleAndRegulation] = useState(props.eventDetails && props.eventDetails.RulesAndRegulations);
    const { user } = useUserAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [aboutText, setAboutText] = useState("");
    const [ruleText, setRuleText] = useState("");
    const [IsRuleEditor, setIsRuleEditor] = useState(false);
    const [IsAboutEditor, setIsAboutEditor] = useState(false);
    const [aboutContent, setaboutContent] = useState(RichTextEditor.createValueFromString(aboutText, "html"));
    const [rulesContent, setrulesContent] = useState(RichTextEditor.createValueFromString(ruleText, "html"));
    const [addControlName, setAddControlName] = useState('');
    const [addControlPhone, setAddControlPhone] = useState('');
    const [viewAddController, setViewAddController] = useState(false);
    // const [viewAddCategory, setViewAddCategory] = useState(false);

    const [viewAddOwner, setViewAddOwner] = useState(false);
    const [addOwnerName, setAddOwnerName] = useState('');
    const [addOwnerPhone, setAddOwnerPhone] = useState('');
    const [ownerEdit, setOwnerEdit] = useState(null)
    const [controllerEdit, setControllerEdit] = useState(null)
    const [controllerList, setControllerList] = useState(null);
    const [saveControllerLoading, setSaveControllerLoading] = useState(null);
    const [editControllerLoading, setEditControllerLoading] = useState(null);
    const [saveOwnerLoading, setSaveOwnerLoading] = useState(null);
    const [editOwnerLoading, setEditOwnerLoading] = useState(null);

    // const [categoryEdit, setCategoryEdit] = useState(null)
    const [categoryList, setCategoryList] = useState(null);
    // const [categoryAdd, setCategoryAdd] = useState(null);
    const [ownerList, setOwnerList] = useState(null);
    const [isEditEnabled, setIsEditEnabled] = useState(props.IsEditEnabled);
    // const [categoryMessage, setCategoryMessage] = useState('');
    const [showDraw, setShowDraw] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [showResult, setShowResult] = useState(false);

    const [eventStatus, setEventStatus] = useState('');
    const [registrationStatus, setRegistrationStatus] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [showParticipantFlag, setShowParticipantFlag] = useState(false);


    const [registrationList, setRegistrationList] = useState(null);
    const [playerList, setPlayerList] = useState(null);
    const [paymentCompleted, setPaymentCompleted] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState({
        isDelete: false,
        deleteObjectType: '',
        deleteMessage: '',
        deleteID: ''
    });

    const [confirmStatusChange, setConfirmStatusChange] = useState({
        showPopup: false,
        message: '',
        Status: '',
    });

    const queryConstraintsRegistration = [
        where('EventID', '==', props.eventDetails.id),
        orderBy('ParticipantName')
        // Add more query constraints as needed
    ];

    // console.log('1 queryConstraints : ', queryConstraintsRegistration)
    const { documents: registrationDoc, error: registrationDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistration)
    // console.log('1 registrationDoc : ', props.eventDetails.id, registrationDoc, registrationDocError)

    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();

    const [editedNoticeBoard, setEditedNoticeBoard] = useState(props.eventDetails && props.eventDetails.NoticeBoard ? props.eventDetails.NoticeBoard : '');
    const [isNoticeBoardEdit, setIsNoticeBoardEdit] = useState(false);
    const [noticeBoardvalue, setNoticeBoardvalue] = useState(
        RichTextEditor.createValueFromString(editedNoticeBoard, "html")
    );
    const handleCancelNoticeBoard = () => {
        setIsNoticeBoardEdit(false);
    };

    const handleEditNoticeBoard = () => {
        setIsNoticeBoardEdit(true);
    };

    const handleSaveNoticeBoard = async () => {
        try {
            //save in DB Start
            SaveNotice(noticeBoardvalue.toString("html"))
            //save in DB End

            setIsNoticeBoardEdit(false);
        } catch (error) {
            // console.error("Error updating document:", error);
        }
    };

    ///code end - NoticeBoard

    ///code start - Rules
    const [editedRules, setEditedRules] = useState(props.eventDetails && props.eventDetails.RulesAndRegulations ? props.eventDetails.RulesAndRegulations : '');
    const [isRulesEdit, setIsRulesEdit] = useState(false);
    const [categoryPopup, setCategoryPopup] = useState({
        showPopup: false,
        action: 'Add',
        categoryObj: null
    });
    const [rulesvalue, setRulesvalue] = useState(
        RichTextEditor.createValueFromString(editedRules, "html")
    );
    const handleCancelRules = () => {
        setIsRulesEdit(false);
    };

    const handleEditRules = () => {
        setIsRulesEdit(true);
    };

    const handleSaveRules = async () => {
        try {
            //save in DB Start
            SaveRegulation(rulesvalue.toString("html"))
            //save in DB End

            setIsRulesEdit(false);
        } catch (error) {
            // console.error("Error updating document:", error);
        }
    };

    ///code end - Rules

    useEffect(() => {
        setIsEditEnabled(props.IsEditEnabled);

        setRuleAndRegulation(props.eventDetails && props.eventDetails.RulesAndRegulations);
        setEditedNoticeBoard(props.eventDetails && props.eventDetails.NoticeBoard ? props.eventDetails.NoticeBoard : '');
        setEditedRules(props.eventDetails && props.eventDetails.RulesAndRegulations ? props.eventDetails.RulesAndRegulations : '');

        setOwnerList(props.ownerDetails)
        setControllerList(props.controllerDetails)
        setCategoryList(props.categoryDetails)
        let userPhone = ''

        userPhone = user && user.userInfo && user.userInfo.Phone && user.userInfo.Phone.substring(user.userInfo.Phone.length - 10, user.userInfo.Phone.length)
        // console.log('userPhone : ', userPhone)

        setShowDraw(props.eventDetails && props.eventDetails.PublishDrawFlag && props.eventDetails.PublishDrawFlag.toLowerCase() === 'on' ? true : false);
        // setEventStatus(props.eventDetails && props.eventDetails.EventStatus && props.eventDetails.EventStatus.toLowerCase() === 'active' ? true : false);
        setEventStatus(props.eventDetails && props.eventDetails.EventStatus);
        setRegistrationStatus(props.eventDetails && props.eventDetails.RegistrationOpenFlag && props.eventDetails.RegistrationOpenFlag.toLowerCase() === 'on' ? true : false);
        setPaymentStatus(props.eventDetails && props.eventDetails.PaymentOpenFlag && props.eventDetails.PaymentOpenFlag.toLowerCase() === 'on' ? true : false);
        setShowParticipantFlag(props.eventDetails && props.eventDetails.ShowParticipantFlag && props.eventDetails.ShowParticipantFlag.toLowerCase() === 'yes' ? true : false)
        console.log('1 registrationDoc', registrationDoc)
        getRegistrationDetails();
    }, [props, props.eventDetails], registrationDoc)

    async function getRegistrationDetails() {
        let result = registrationDoc;
        let pList = [];

        let paymentCount = 0;
        if (result) {
            setRegistrationList(result)
            result.forEach(element => {
                if (!pList.find(e => e.PlayerID === element.ParticipantID)) {
                    pList.push({
                        PlayerCode: element.ParticipantID,
                        PlayerName: element.ParticipantName
                    })
                }
                if (!element.PartnerPlayerID && element.PartnerPlayerID !== '' && !pList.find(e => e.PlayerID === element.PartnerPlayerID)) {
                    pList.push({
                        PlayerCode: element.PartnerPlayerID,
                        PlayerName: element.PartnerPlayerName
                    })
                }
                if (element.PaymentStatus && element.PaymentStatus.toLowerCase() === 'completed') {
                    paymentCount = paymentCount + 1;
                }
            });
            setPlayerList(pList);
            setPaymentCompleted(paymentCount)
            // console.log(result);
        }
        setLoading(false);

    }

    // async function getRegistrationDetails() {

    //     var para1 = {};
    //     setLoading(true);
    //     let pList = [];
    //     para1 = {
    //         EventID: props.eventDetails.id
    //     };
    //     // console.log('para1', para1);
    //     const ret1 = await httpsCallable(functions, "getParticipants");
    //     ret1(para1).then(async (result) => {
    //         // console.log('result', result)
    //         let paymentCount = 0;
    //         if (result && result.data) {
    //             setRegistrationList(result.data)
    //             result.data.forEach(element => {
    //                 if (!pList.find(e => e.PlayerID === element.ParticipantID)) {
    //                     pList.push({
    //                         PlayerCode: element.ParticipantID,
    //                         PlayerName: element.ParticipantName
    //                     })
    //                 }
    //                 if (!element.PartnerPlayerID && element.PartnerPlayerID !== '' && !pList.find(e => e.PlayerID === element.PartnerPlayerID)) {
    //                     pList.push({
    //                         PlayerCode: element.PartnerPlayerID,
    //                         PlayerName: element.PartnerPlayerName
    //                     })
    //                 }
    //                 if (element.PaymentStatus && element.PaymentStatus.toLowerCase() === 'completed') {
    //                     paymentCount = paymentCount + 1;
    //                 }
    //             });
    //             setPlayerList(pList);
    //             setPaymentCompleted(paymentCount)
    //             // console.log(result);
    //         }
    //         setLoading(false);

    //     });
    // }

    // async function getEventRoleData() {
    //     var para1 = {};
    //     setLoading(true);

    //     para1 = {
    //         EventID: props.eventDetails.id
    //     };
    //     // console.log('para1', para1);
    //     const ret1 = await httpsCallable(functions, "getEventRoleAndCategoryData");
    //     ret1(para1).then(async (result) => {
    //         // console.log('result', result)
    //         if (result && result.data) {
    //             setControllerList(result.data.Controller)
    //             setOwnerList(result.data.EventOwner)
    //             setCategoryList(result.data.categoryList)
    //         }
    //         setLoading(false);

    //     });
    // }

    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    async function saveController(name, phone, conrollerid, email, isCreater, id) {

        id === '' ? setSaveControllerLoading(true) :
            setEditControllerLoading(true)
        var para1 = {
            EventID: props.eventDetails.id,
            ControllerID: conrollerid,
            ControllerName: name,
            ControllerPhone: phone,
            ControllerEmail: email,
            IsCreater: isCreater,
            id: id
        };
        let controllerListLocal = controllerList;
        const ret = await httpsCallable(functions, "updateTournamentController");
        ret(para1).then(async (result) => {
            // console.log(result.data.id);
            let controller = {};

            if (result.data.id === id) {

                // let index = controllerListLocal.findIndex(e => e.id === id);
                // controllerListLocal.splice(index, 1)
                // controller = {
                //     EventID: props.eventDetails.id,
                //     ControllerID: conrollerid,
                //     ControllerName: name,
                //     ControllerPhone: phone,
                //     ControllerEmail: email,
                //     IsCreater: isCreater,
                //     id: result.data.id
                // }
                setEditControllerLoading(false)

            } else {
                // controller = {
                //     EventID: props.eventDetails.id,
                //     ControllerID: conrollerid,
                //     ControllerName: name,
                //     ControllerPhone: phone,
                //     ControllerEmail: email,
                //     IsCreater: false,
                //     id: result.data.id
                // }
                setSaveControllerLoading(false)
            }

            setAddControlName('');
            setAddControlPhone('');
            setControllerEdit(null)
            // controllerListLocal.push(controller)
            // setControllerList(controllerListLocal);
            setSaveControllerLoading(false)
            setViewAddController(false)
        })
    }

    const addControllerShow = (flag) => {
        setViewAddController(flag)
    }

    const addOwnerShow = (flag) => {
        // console.log('in addControllerShow')
        setViewAddOwner(flag)
    }

    // async function addCategoryDetails(cat) {
    //     // console.log('in addCategoryDetails', cat)
    //     if (!cat || (cat && cat.CategoryName === '') ||
    //         (cat && cat.ReferenceDate === '') ||
    //         (cat && cat.Fees === '')) {
    //         setCategoryMessage('Enter all details!!')
    //         // setShowError(true);
    //         setTimeout(function () {
    //             setCategoryMessage('')

    //         }, 5000);
    //     } else if (
    //         categoryList.findIndex(e => e.CategoryName.toUpperCase() === cat.CategoryName.toUpperCase() && e.id !== cat.id) >= 0) {
    //         setCategoryMessage('Category already exists!!')
    //         // setShowError(true);
    //         setTimeout(function () {
    //             setCategoryMessage('')

    //         }, 5000);
    //     }
    //     else {
    //         ////////////////////
    //         var para1 = {
    //             EventID: props.eventDetails.id,
    //             CategoryName: cat.CategoryName,
    //             EventType: cat.EventType,
    //             DateRefType: cat.DateRefType,
    //             Fees: cat.Fees,
    //             Gender: cat.Gender,
    //             MaxTeamSize: cat.MaxTeamSize,
    //             ReferenceDate: cat.ReferenceDate && cat.ReferenceDate._seconds ? Date(cat.ReferenceDate._seconds * 1000) : new Date(cat.ReferenceDate),
    //             IsActive: cat.IsActive,
    //             id: cat.id ? cat.id : ''
    //         };

    //         // console.log(para1);
    //         let category = null;
    //         let catList = categoryList;

    //         category = {
    //             EventID: props.eventDetails.id,
    //             CategoryName: cat.CategoryName,
    //             EventType: cat.EventType,
    //             DateRefType: cat.DateRefType,
    //             Fees: cat.Fees,
    //             Gender: cat.Gender,
    //             MaxTeamSize: cat.MaxTeamSize,
    //             IsActive: cat.IsActive,
    //             ReferenceDate: cat.ReferenceDate && cat.ReferenceDate._seconds ? (new Date(cat.ReferenceDate._seconds * 1000)) : (new Date(cat.ReferenceDate)),
    //             id: cat.id
    //         }
    //         // console.log('category', category)
    //         // console.log('catList', catList)

    //         setCategoryAdd(null);
    //         setCategoryEdit(null)
    //         catList.push(category)

    //         const ret = await httpsCallable(functions, "updateTournamentCategory");
    //         ret(para1).then(async (result) => {
    //             // console.log(result.data);

    //             if (result.data === cat.id) {
    //                 let index = catList.findIndex(e => e.id === cat.id);
    //                 catList.splice(index, 1)
    //             }
    //             category = {
    //                 EventID: props.eventDetails.id,
    //                 CategoryName: cat.CategoryName,
    //                 EventType: cat.EventType,
    //                 DateRefType: cat.DateRefType,
    //                 Fees: cat.Fees,
    //                 Gender: cat.Gender,
    //                 MaxTeamSize: cat.MaxTeamSize,
    //                 ReferenceDate: (new Date(cat.ReferenceDate)),
    //                 IsActive: cat.IsActive,
    //                 id: result.data
    //             }

    //             setCategoryAdd(null);
    //             setCategoryEdit(null)
    //             catList.push(category)
    //             setCategoryList(catList)
    //             setViewAddCategory(false)
    //         })
    //     }
    // }
    async function saveOwner(name, phone, ownerid, email, isCreater, id) {
        id === '' ? setSaveOwnerLoading(true) :
            setEditOwnerLoading(true)

        var para1 = {
            EventID: props.eventDetails.id,
            OwnerID: ownerid,
            OwnerName: name,
            OwnerPhone: phone,
            OwnerEmail: email,
            IsCreater: isCreater,
            id: id
        };

        // console.log(para1);
        let ownerListLocal = []
        ownerListLocal = ownerList
        const ret = await httpsCallable(functions, "updateTournamentOwner");
        ret(para1).then(async (result) => {
            // console.log(result.data.id);
            let owner = {};

            if (result.data.id === id) {

                // let index = ownerListLocal.findIndex(e => e.id === id);
                // ownerListLocal.splice(index, 1)
                // owner = {
                //     EventID: props.eventDetails.id,
                //     OwnerID: ownerid,
                //     OwnerName: name,
                //     OwnerPhone: phone,
                //     OwnerEmail: email,
                //     IsCreater: isCreater,
                //     id: result.data.id
                // }
                setEditOwnerLoading(false)

            } else {
                // owner = {
                //     EventID: props.eventDetails.id,
                //     OwnerID: '',
                //     OwnerName: addOwnerName,
                //     OwnerPhone: addOwnerPhone,
                //     OwnerEmail: '',
                //     IsCreater: false,
                //     id: result.data.id
                // }
                setSaveOwnerLoading(false)
            }

            setAddOwnerName('');
            setAddOwnerPhone('');
            setOwnerEdit(null)
            // ownerListLocal.push(owner)
            // setOwnerList(ownerListLocal)
            setViewAddOwner(false)
        })

    }

    async function saveShowParticipantFlag() {
        console.log('showParticipantFlag', showParticipantFlag)
        let _updateDate = {
            'ShowParticipantFlag': !showParticipantFlag ? 'YES' : 'NO',
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.eventDetails.id, _updateDate)
        setShowParticipantFlag(!showParticipantFlag)
    }
    async function SaveNotice(notice) {

        let _updateData = {
            'NoticeBoard': notice,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }

        await updateDocument('EventList', props.eventDetails.id, _updateData)

    }
    async function SaveRegulation(rule) {
        let _updatedData = {
            'RulesAndRegulations': rule,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.eventDetails.id, _updatedData)

    }
    function callDelete(deleteObjectType, id) {
        if (deleteObjectType === 'Owner')
            deleteOwner(id)
        else if (deleteObjectType === 'Controller')
            deleteController(id)
        else if (deleteObjectType === 'Category')
            deleteCategory(id)


    }
    async function deleteOwner(id) {
        if (!id || id === '') {
            return
        }
        var para1 = {
            EventID: props.eventDetails.id,
            id: id
        };

        // console.log(para1);

        const ret = await httpsCallable(functions, "deleteTournamentOwner");
        ret(para1).then(async (result) => {
            // console.log('result ::', result)
            // let owner = {};
            // let ownerListObject = ownerList
            // let index = ownerListObject.findIndex(e => e.id === id);
            // console.log(index);
            // ownerListObject.splice(index, 1)
            // console.log(ownerListObject);

            // setOwnerList(ownerListObject)
            setConfirmDelete({
                isDelete: false,
                deleteMessage: '',
                deleteObjectType: '',
                deleteID: ''
            });
        })
    }

    async function deleteController(id) {
        if (!id || id === '') {
            return
        }
        var para1 = {
            EventID: props.eventDetails.id,
            id: id
        };

        // console.log(para1);

        const ret = await httpsCallable(functions, "deleteTournamentController");
        ret(para1).then(async (result) => {
            // let controllerListObject = controllerList;
            // let index = controllerListObject.findIndex(e => e.id === id);
            // controllerListObject.splice(index, 1)
            // setControllerList(controllerListObject)

            setConfirmDelete({
                isDelete: false,
                deleteMessage: '',
                deleteObjectType: '',
                deleteID: ''
            });
        })
    }

    async function deleteCategory(id) {
        if (!id || id === '') {
            return
        }
        var para1 = {
            EventID: props.eventDetails.id,
            id: id
        };
        setLoading(true);

        // console.log(para1);

        const ret = await httpsCallable(functions, "deleteTournamentCategory");
        ret(para1).then(async (result) => {
            // let categoryListObject = categoryList
            // let index = categoryListObject.findIndex(e => e.id === id);
            // categoryListObject.splice(index, 1)
            // setCategoryList(categoryListObject)
            setLoading(false);

            setConfirmDelete({
                isDelete: false,
                deleteMessage: '',
                deleteObjectType: '',
                deleteID: ''
            });
        })
    }

    async function savePublishDrawFlag(flag) {

        let _updatedData = {
            'PublishDrawFlag': flag ? 'ON' : 'OFF',
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.eventDetails.id, _updatedData)

    }

    async function savePublishResultFlag(flag) {
        let _updatedData = {
            'PublishResultFlag': flag ? 'ON' : 'OFF',
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.eventDetails.id, _updatedData)

    }
    async function savePublishScheduleFlag() {
        let _updatedData = {
            'PublishScheduleFlag': showSchedule ? 'ON' : 'OFF',
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.eventDetails.id, _updatedData)

    }
    async function saveRegistrationFlag(flag) {
        console.log('registrationStatus', flag, props.eventDetails.id)
        let _updatedData = {
            'RegistrationOpenFlag': flag ? 'ON' : 'OFF',
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.eventDetails.id, _updatedData)

    }
    async function saveEventStatus(status) {
        let _updatedData = {
            'EventStatus': status,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.eventDetails.id, _updatedData)
        if (!updateLoading) {
            setConfirmStatusChange({
                showPopup: false,
                message: '',
                Status: ''
            })
        }

    }


    async function savePaymentFlag(flag) {
        let _updatedData = {
            'OnlinePaymentModeFlag': flag ? 'ON' : 'OFF',
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        await updateDocument('EventList', props.eventDetails.id, _updatedData)

        // var para1 = {};
        // para1 = {
        //     EventID: props.eventDetails.id,
        //     OnlinePaymentModeFlag: paymentStatus ? 'ON' : 'OFF',
        // };
        // console.log(para1);
        // const ret = await httpsCallable(functions, "updateEventFlag_OnlinePaymentMode");
        // ret(para1).then(async (result) => {
        //     // setResultMessage('Payment Flag Saved Successfully !!')

        //     // setTimeout(function () {
        //     //     setResultMessage('');
        //     // }, 5000);

        // })
    }
    function toCamelCase(str) {
        return str
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toLowerCase() : match.toUpperCase()
            )
            .replace(/\s+/g, '');
    }

    return (
        <div className="col-lg-12">
            <hr className='small' />
            <br className='small' />
            <div className={confirmDelete.isDelete ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner'>
                    <h1 >
                        <strong>{confirmDelete.deleteMessage}</strong>
                    </h1>
                    <hr />

                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={() => {
                            callDelete(confirmDelete.deleteObjectType, confirmDelete.deleteID)
                        }}>
                            <span>YES</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={() => {
                            setConfirmDelete({
                                isDelete: false,
                                deleteMessage: '',
                                deleteObjectType: '',
                                deleteID: ''
                            });

                        }}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div >
            </div>

            <div className={confirmStatusChange.showPopup ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner'>
                    <h1 >
                        <strong>{confirmStatusChange.message}</strong>
                    </h1>
                    <hr />

                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={() => {
                            setEventStatus(confirmStatusChange.Status)
                            saveEventStatus(confirmStatusChange.Status)
                            // setConfirmStatusChange({
                            //     showPopup: false,
                            //     message: '',
                            //     Status: '',
                            // });
                            // changeStatus(confirmStatusChange.Status)
                        }}>
                            <span>YES</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={() => {
                            setConfirmStatusChange({
                                showPopup: false,
                                message: '',
                                Status: '',
                            });

                        }}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div >
            </div>

            {
                <>
                    {isEditEnabled && <div style={{ background: '#ffae00', borderRadius: '8px', padding: '20px 10px' }}>
                        <div className='event-details-checkbox-div'>

                            <div onClick={() => {

                                setConfirmStatusChange({
                                    showPopup: true,
                                    message: 'Change event Status to Inactive',
                                    Status: 'Inactive',
                                });
                            }}>
                                <input type="radio"
                                    checked={eventStatus === 'Inactive' ? true : false}

                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventFlag" id="EventStatusInactive" value={eventStatus} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventStatusInactive">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>Inactive</span>
                                </label>
                            </div>

                            <div onClick={() => {

                                setConfirmStatusChange({
                                    showPopup: true,
                                    message: 'Change event Status to Active',
                                    Status: 'Active',
                                });
                            }}>
                                <input type="radio"
                                    checked={eventStatus === 'Active' ? true : false}
                                    // onClick={(e) => {
                                    //     console.log(e.target.checked)
                                    //     if (e.target.checked === true) {
                                    //         setEventStatus('Active')
                                    //         saveEventStatus('Active')
                                    //     }

                                    // }}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventFlag" id="EventStatusActive" value={eventStatus} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventStatusActive">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>Active</span>
                                </label>
                            </div>

                            <div onClick={() => {
                                setConfirmStatusChange({
                                    showPopup: true,
                                    message: 'Change event Status to Completed',
                                    Status: 'Completed',
                                });

                            }}>
                                <input type="radio"
                                    checked={eventStatus === 'Completed' ? true : false}
                                    // onClick={(e) => {
                                    //     console.log(e.target.checked)
                                    //     if (e.target.checked === true) {
                                    //         setEventStatus('Completed')
                                    //         saveEventStatus('Completed')

                                    //     }

                                    // }}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventFlag" id="EventStatusCompleted" value="Event Status" />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventStatusCompleted">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>Completed</span>
                                </label>
                            </div>
                            <div onClick={() => {
                                setConfirmStatusChange({
                                    showPopup: true,
                                    message: 'Change event Status to Cancelled',
                                    Status: 'Cancelled',
                                });
                            }}>
                                <input type="checkbox"
                                    checked={eventStatus === 'Cancelled' ? true : false}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventFlag" id="EventStatusCancelled" value="Event Status" />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventStatusCancelled">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>Cancelled</span>
                                </label>
                            </div>
                            <div onClick={() => {
                                setConfirmStatusChange({
                                    showPopup: true,
                                    message: 'Change event Status to Onhold',
                                    Status: 'Onhold',
                                });
                            }}>
                                <input type="radio"
                                    checked={eventStatus === 'Onhold' ? true : false}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventFlag" id="EventStatusOnhold" value="Event Status" />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventStatusOnhold">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>On-hold</span>
                                </label>
                            </div>
                        </div>
                        <div style={{ borderBottom: '1px solid #ddd', margin: '10px 0' }} ></div>
                        <div className='event-details-checkbox-div'>
                            <div>
                                <input type="checkbox"
                                    checked={registrationStatus}
                                    onClick={(e) => {
                                        // console.log('registrationStatus', registrationStatus)

                                        saveRegistrationFlag(!registrationStatus);
                                        // setRegistrationStatus(!registrationStatus);
                                    }}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventRegistration" id="EventRegistration" value='Registration Status' />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventRegistration">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>Registration</span>
                                </label>
                            </div>
                            <div>
                                <input type="checkbox"
                                    checked={paymentStatus}
                                    onChange={(e) => {
                                        // setPaymentStatus(!paymentStatus);
                                        savePaymentFlag(!paymentStatus);
                                    }}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventPayment" id="EventPayment" value={paymentStatus} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventPayment">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>Payment</span>
                                </label>
                            </div>
                            {props.isDrawAvailable && <div>

                                <input type="checkbox"
                                    checked={showDraw}
                                    onChange={(e) => {
                                        // setShowDraw(!showDraw);
                                        savePublishDrawFlag(!showDraw);
                                    }}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventDraws" id="EventDraws" value={showDraw} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventDraws">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}
                                    ></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}
                                    ></i>
                                    <span>Draws</span>
                                </label>
                            </div>}
                            {/* {props.isDrawAvailable && <div>
                                <input type="checkbox"
                                    checked={showDraw}
                                    onChange={(e) => {
                                        setShowSchedule(!showSchedule);
                                        savePublishScheduleFlag();
                                    }}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventDraws" id="EventDraws" value={showDraw} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventDraws">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}
                                    ></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}
                                    ></i>
                                    <span>Schedule</span>
                                </label>

                            </div>} */}
                            {props.isDrawAvailable && <div>
                                <input type="checkbox"
                                    checked={showResult}
                                    onChange={(e) => {
                                        // setShowResult(!showResult);
                                        savePublishResultFlag(!showResult);
                                    }}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventResult" id="EventResult" value={showResult} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventResult">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}
                                    ></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}
                                    ></i>
                                    <span>Result</span>
                                </label>
                            </div>}

                            <div>
                                <input type="checkbox"
                                    checked={showParticipantFlag}
                                    onChange={(e) => {
                                        // setShowParticipantFlag(!showParticipantFlag);
                                        saveShowParticipantFlag();
                                    }}
                                    className="checkbox event-details-logo" style={{ width: '0px' }}
                                    name="EventStatusShowPlayerList" id="EventStatusShowPlayerList" value={showParticipantFlag} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label" htmlFor="EventStatusShowPlayerList">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>Show Player List</span>
                                </label>
                            </div>


                        </div>
                    </div>}
                </>
            }

            <hr /><br />
            {/* <section>
                <div className='container-fluid'> */}
            {/* <Swiper
                        spaceBetween={15}
                        breakpoints={{
                            0: {
                                slidesPerView: 2.5,
                            },

                            576: {
                                slidesPerView: 3.5,
                            },
                            768: {
                                slidesPerView: 7.5,
                            },
                        }}
                        pagination={{ clickable: true }}
                        freeMode={true}
                        className='all_tenants'
                    > */}

            {/* <SwiperSlide key={1}>

                            <div className='event-data-div' onClick={(e) => {
                                navigate("/EventEntries/" + props.eventDetails.id)
                            }}>
                                <span className="material-symbols-outlined">
                                    passkey
                                </span>
                                <h2>{registrationDoc ? registrationDoc.length : 0}</h2>
                                <h1>Entries</h1>
                            </div>
                        </SwiperSlide> */}

            {/* <SwiperSlide key={2}>
                            <div className='event-data-div' onClick={(e) => {
                                navigate("/EventParticipants/" + props.eventDetails.id)
                            }}>
                                <span className="material-symbols-outlined">
                                    groups
                                </span>
                                <h2>{playerList ? playerList.length : 0}</h2>
                                <h1>Participants</h1>
                            </div>
                        </SwiperSlide> */}
            {/* {props.IsEditEnabled && <SwiperSlide key={3}>

                            <div className='event-data-div'>
                                <span className="material-symbols-outlined">
                                    payments
                                </span>
                                <h2>{paymentCompleted}</h2>
                                <h1>Payments</h1>
                            </div>
                        </SwiperSlide>} */}

            {/* {props.isDrawAvailable && props.eventDetails.PublishDrawFlag === 'ON' && <SwiperSlide SwiperSlide key={4}> */}
            {/* {true && <SwiperSlide SwiperSlide key={4}>
                            <div className='event-data-div'>
                                <span className="material-symbols-outlined">
                                    polyline
                                </span>
                                <h2>1,78,096</h2>
                                <h1>Draws</h1>
                            </div>
                        </SwiperSlide>} */}

            {/* {props.isDrawAvailable && props.eventDetails.PublishDrawFlag === 'ON' && <SwiperSlide key={5}> */}
            {/* {true && <SwiperSlide key={5}>
                            <div className='event-data-div'>
                                <span className="material-symbols-outlined">
                                    alarm
                                </span>
                                <h2>8711</h2>
                                <h1>Schedule</h1>
                            </div>
                        </SwiperSlide>} */}

            {/* {props.isDrawAvailable && props.eventDetails.PublishDrawFlag === 'ON' && <SwiperSlide key={6}> */}
            {/* {true && <SwiperSlide key={6}>
                            <div className='event-data-div'>
                                <span className="material-symbols-outlined">
                                    contract
                                </span>
                                <h2>1,78,096</h2>
                                <h1>Results</h1>
                            </div>
                        </SwiperSlide>} */}
            {/* {props.eventDetails && props.eventDetails.LocationMap && props.eventDetails.LocationMap !== '' && <SwiperSlide key={7}> */}
            {/* {true !== '' && <SwiperSlide key={7}>
                            <div className='event-data-div'>
                                <span className="material-symbols-outlined">
                                    map
                                </span>
                                <h2>MAP</h2>
                                <h1>Venue</h1>
                            </div>
                        </SwiperSlide>} */}
            {/* </Swiper> */}
            {/* </div>
            </section > */}
            {/* <br /> */}
            {/* <hr /><br /> */}


            {
                !isEditEnabled && ownerList && ownerList.length === 0 ? <></> : <><section className="property_card_single full_width_sec property_user" style={{ position: 'relative', background: 'rgba(59, 171, 91,0.2)' }}>
                    <br />
                    <span className="verticall_title">
                        Owners
                        {isEditEnabled && <span style={{
                            margin: '10px 0', padding: '3px', background: '#fff', color: '#444',
                            borderRadius: '50%', cursor: 'pointer'
                        }} className="material-symbols-outlined" onClick={() =>
                            addOwnerShow(true)
                        } >
                            add
                        </span>}
                    </span>
                    {/* <div className="more_detail_card_inner"> */}
                    <div className='container-fluid'>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="tenant_card">
                                    <Swiper
                                        spaceBetween={15}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1.2,
                                            },
                                            576: {
                                                slidesPerView: 2.5,
                                            },
                                            768: {
                                                slidesPerView: 3.5,
                                                EDAboutEvent
                                            },
                                        }}
                                        pagination={{ clickable: true }}
                                        freeMode={true}
                                        // autoplay={true}
                                        // scrollbar={{ draggable: true }}
                                        // onSwiper={(swiper) => { console.log(swiper) }}
                                        // onSlideChange={() => { console.log('slide change') }}
                                        className='all_tenants'
                                    >
                                        {viewAddOwner && isEditEnabled &&
                                            <SwiperSlide key='add'>

                                                <div className="tc_single relative">
                                                    {saveOwnerLoading && <div style={{
                                                        position: 'absolute',
                                                        top: '15px',
                                                        left: '0',
                                                        background: 'rgba(255,255,255,1)',
                                                        width: '100%',
                                                        height: 'calc(100% - 15px)',
                                                        borderRadius: '15px',
                                                        zIndex: '99'
                                                    }}>
                                                        <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json" background="transparent"
                                                            speed="1" style={{ width: 'auto', height: '100%' }} loop autoplay></lottie-player>
                                                    </div>}

                                                    <div className="left">
                                                        <div className="tcs_img_container">
                                                            <img
                                                                src={"/img/user_icon.png"}
                                                                alt="Preview"
                                                                width='100%'
                                                            />
                                                        </div>
                                                        <div className="tenant_detail">
                                                            <h5 className="t_name pointer">
                                                                <input type="text" placeholder='Owner name.. ' value={addOwnerName}
                                                                    onChange={(e) => {
                                                                        setAddOwnerName(e.target.value)
                                                                    }} />

                                                            </h5>
                                                            <h6 className="t_number">
                                                                <input type="text" placeholder='Owner Phone.. ' value={addOwnerPhone}
                                                                    onChange={(e) => {
                                                                        setAddOwnerPhone(e.target.value)
                                                                    }} />
                                                            </h6>
                                                        </div>
                                                    </div>

                                                    <div className="wha_call_icon">
                                                        <div
                                                            onClick={() =>
                                                                saveOwner(addOwnerName, addOwnerPhone, '', '', false, '')
                                                            }
                                                            style={{ color: 'green', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                                                            <span className="material-symbols-outlined" style={{ fontSize: '1.1rem' }}>
                                                                save
                                                            </span>
                                                            <small style={{ fontSize: '0.7rem' }}>Save</small>
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                addOwnerShow(false)
                                                            }}
                                                            style={{ color: 'red', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                                                            <span className="material-symbols-outlined" style={{ fontSize: '1.1rem' }}>
                                                                cancel
                                                            </span>
                                                            <small style={{ fontSize: '0.7rem' }}>Cancel</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        }
                                        {ownerList && ownerList.map((ownerObj) => {
                                            return <SwiperSlide key={ownerObj.id}>
                                                <div className="tc_single relative">
                                                    {ownerEdit && ownerEdit.id === ownerObj.id && editOwnerLoading && <div style={{
                                                        position: 'absolute',
                                                        top: '15px',
                                                        left: '0',
                                                        background: 'rgba(255,255,255,1)',
                                                        width: '100%',
                                                        height: 'calc(100% - 15px)',
                                                        borderRadius: '15px',
                                                        zIndex: '99'
                                                    }}>
                                                        <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json" background="transparent"
                                                            speed="1" style={{ width: 'auto', height: '100%' }} loop autoplay></lottie-player>
                                                    </div>}

                                                    <div className="left">
                                                        <div className="tcs_img_container">
                                                            <img
                                                                src={"/img/user_icon.png"}
                                                                alt="Preview"
                                                                width='100%'
                                                            />
                                                        </div>
                                                        <div className="tenant_detail">
                                                            <h5
                                                                onClick={() => {
                                                                    if (ownerEdit && ownerEdit.id === ownerObj.id) {
                                                                        saveOwner(ownerEdit.OwnerName,
                                                                            ownerEdit.OwnerPhone,
                                                                            ownerEdit.OwnerID,
                                                                            ownerEdit.OwnerEmail,
                                                                            ownerEdit.IsCreater,
                                                                            ownerEdit.id)
                                                                    }
                                                                    else if (!ownerEdit || (ownerEdit && ownerEdit.id !== ownerObj.id)) {
                                                                        setOwnerEdit({
                                                                            ...ownerObj,
                                                                            isEdit: true
                                                                        })
                                                                    }
                                                                }}

                                                                className={`t_name ${true ? "pointer" : ""}`}
                                                            >
                                                                {(!ownerEdit || (ownerEdit && ownerEdit.id !== ownerObj.id)) ? ownerObj.OwnerName :
                                                                    (ownerEdit && ownerEdit.id !== ownerObj.id) ? ownerObj.OwnerName :

                                                                        <input type="text"
                                                                            value={ownerEdit.OwnerName}
                                                                            style={{ width: '80%' }}
                                                                            onChange={(e) => {
                                                                                setOwnerEdit({
                                                                                    ...ownerEdit,
                                                                                    OwnerName: e.target.value
                                                                                })
                                                                            }} />
                                                                }
                                                                {isEditEnabled &&

                                                                    ((ownerEdit && ownerEdit.id === ownerObj.id) ?
                                                                        <span style={{ fontSize: '1.2rem', position: 'relative', top: '3px' }} className="material-symbols-outlined click_icon text_near_icon">
                                                                            save
                                                                        </span>
                                                                        :
                                                                        <span style={{ fontSize: '1.2rem', position: 'relative', top: '3px' }} className="material-symbols-outlined click_icon text_near_icon">
                                                                            edit
                                                                        </span>)
                                                                }


                                                            </h5>
                                                            <h6 className="t_number">
                                                                {(!ownerEdit || (ownerEdit && ownerEdit.id !== ownerObj.id)) ? ownerObj.OwnerPhone :
                                                                    (ownerEdit && ownerEdit.id !== ownerObj.id) ? ownerObj.OwnerPhone :
                                                                        <input type="text" placeholder='Controller Phone.. '
                                                                            value={ownerEdit.OwnerPhone}
                                                                            onChange={(e) => {
                                                                                setOwnerEdit({
                                                                                    ...ownerEdit,
                                                                                    OwnerPhone: e.target.value
                                                                                })
                                                                            }} />
                                                                }
                                                            </h6>
                                                            {isEditEnabled &&
                                                                !ownerObj.IsCreater &&
                                                                <div onClick={(e) => {
                                                                    setConfirmDelete({
                                                                        deleteObjectType: 'Owner',
                                                                        isDelete: true,
                                                                        deleteMessage: 'Are you sure to delete Owner ' + ownerObj.OwnerName + '',
                                                                        deleteID: ownerObj.id
                                                                    })
                                                                }}
                                                                    style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                                                                    <span className="material-symbols-outlined" style={{ position: 'relative', top: '-5px', fontSize: '0.9rem' }}>
                                                                        delete
                                                                    </span>
                                                                    <h6
                                                                        className="text_red pointer"
                                                                        style={{
                                                                            width: "fit-content",
                                                                            fontSize: "10px",
                                                                            letterSpacing: "0.4px",
                                                                            marginLeft: "3px"
                                                                        }}

                                                                    >
                                                                        Delete
                                                                    </h6></div>}
                                                        </div>
                                                    </div>
                                                    <div className="wha_call_icon">
                                                        <Link
                                                            className="call_icon wc_single"
                                                            to={true ? `tel:${ownerObj.OwnerPhone}` : "#"}
                                                        >
                                                            <img src="/img/simple_call.png" alt="" />
                                                        </Link>
                                                        <Link
                                                            className="wha_icon wc_single"
                                                            to={true ? `https://wa.me/${ownerObj.OwnerPhone}` : "#"}
                                                            target="_blank"
                                                        >
                                                            <img src="/img/whatsapp_simple.png" alt="" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        })}

                                    </Swiper>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                    <br />
                </section >
                    <br /> </>
            }
            {
                !isEditEnabled && controllerList && controllerList.length === 0 ? <></> : <><section className="property_card_single full_width_sec red_color property_user" style={{ position: 'relative', background: 'rgba(239, 56, 93,0.2)' }}>
                    <br />
                    <span className="verticall_title">
                        Controllers
                        {isEditEnabled && <span style={{
                            margin: '10px 0', padding: '3px', background: '#fff', color: '#444',
                            borderRadius: '50%', cursor: 'pointer'
                        }} className="material-symbols-outlined" onClick={() =>
                            addControllerShow(true)
                        } >
                            add
                        </span>}
                    </span>
                    <div className="more_detail_card_inner">
                        <div className='container-fluid'>
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <div className="tenant_card">
                                        <Swiper
                                            spaceBetween={15}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 1.2,
                                                },
                                                576: {
                                                    slidesPerView: 2.5,
                                                },
                                                768: {
                                                    slidesPerView: 3.5,
                                                    EDAboutEvent
                                                },
                                            }}
                                            pagination={{ clickable: true }}
                                            freeMode={true}
                                            className='all_tenants'
                                        >
                                            {viewAddController &&
                                                <SwiperSlide key='add'>
                                                    <div className="tc_single relative">
                                                        {saveControllerLoading && <div style={{
                                                            position: 'absolute',
                                                            top: '15px',
                                                            left: '0',
                                                            background: 'rgba(255,255,255,1)',
                                                            width: '100%',
                                                            height: 'calc(100% - 15px)',
                                                            borderRadius: '15px',
                                                            zIndex: '99'
                                                        }}>
                                                            <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json" background="transparent"
                                                                speed="1" style={{ width: 'auto', height: '100%' }} loop autoplay></lottie-player>
                                                        </div>}
                                                        <div className="left">
                                                            <div className="tcs_img_container">
                                                                <img
                                                                    src={"/img/user_icon.png"}
                                                                    alt="Preview"
                                                                    width='100%'
                                                                />
                                                            </div>
                                                            <div className="tenant_detail">
                                                                <h5 className="t_name pointer">
                                                                    <input type="text" placeholder='Controller name.. ' value={addControlName}
                                                                        onChange={(e) => {
                                                                            setAddControlName(e.target.value)
                                                                        }} />
                                                                </h5>
                                                                <h6 className="t_number">
                                                                    <input type="text" placeholder='Controller Phone.. ' value={addControlPhone}
                                                                        onChange={(e) => {
                                                                            setAddControlPhone(e.target.value)
                                                                        }} />
                                                                </h6>
                                                            </div>
                                                        </div>

                                                        <div className="wha_call_icon">
                                                            <div
                                                                onClick={() =>
                                                                    saveController(addControlName, addControlPhone, '', '', false, '')
                                                                }
                                                                style={{ color: 'green', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                                                                <span style={{ fontSize: '1.1rem' }} className="material-symbols-outlined">
                                                                    save
                                                                </span>
                                                                <small style={{ fontSize: '0.7rem' }}>Save</small>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    addControllerShow(false)
                                                                }}
                                                                style={{ color: 'red', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
                                                                <span style={{ fontSize: '1.1rem' }} className="material-symbols-outlined">
                                                                    cancel
                                                                </span>
                                                                <small style={{ fontSize: '0.7rem' }}>Cancel</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            }
                                            {controllerList && controllerList.map((controllerObj) => {
                                                return <SwiperSlide key={controllerObj.id}>
                                                    <div className="tc_single relative">
                                                        {controllerEdit && controllerEdit.id === controllerObj.id && editControllerLoading && <div style={{
                                                            position: 'absolute',
                                                            top: '15px',
                                                            left: '0',
                                                            background: 'rgba(255,255,255,1)',
                                                            width: '100%',
                                                            height: 'calc(100% - 15px)',
                                                            borderRadius: '15px',
                                                            zIndex: '99'
                                                        }}>
                                                            <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json" background="transparent"
                                                                speed="1" style={{ width: 'auto', height: '100%' }} loop autoplay></lottie-player>
                                                        </div>}
                                                        <div className="left">
                                                            <div className="tcs_img_container">
                                                                <img
                                                                    src={"/img/user_icon.png"}
                                                                    alt="Preview"
                                                                    width='100%'
                                                                />
                                                            </div>
                                                            <div className="tenant_detail">
                                                                <h5
                                                                    onClick={() => {
                                                                        if (controllerEdit && controllerEdit.id === controllerObj.id) {
                                                                            saveController(controllerEdit.ControllerName,
                                                                                controllerEdit.ControllerPhone,
                                                                                controllerEdit.ControllerID,
                                                                                controllerEdit.ControllerEmail,
                                                                                controllerEdit.IsCreater,
                                                                                controllerEdit.id)
                                                                        }
                                                                        else if (!controllerEdit || (controllerEdit && controllerEdit.id !== controllerObj.id)) {
                                                                            setControllerEdit({
                                                                                ...controllerObj,
                                                                                isEdit: true
                                                                            })
                                                                        }
                                                                    }}
                                                                    className={`t_name ${true ? "pointer" : ""}`}
                                                                >
                                                                    {(!controllerEdit || (controllerEdit && controllerEdit.id !== controllerObj.id)) ? controllerObj.ControllerName :
                                                                        (controllerEdit && controllerEdit.id !== controllerObj.id) ? controllerObj.ControllerName :

                                                                            <input type="text"
                                                                                value={controllerEdit.ControllerName}
                                                                                style={{ width: '80%' }}
                                                                                onChange={(e) => {
                                                                                    setControllerEdit({
                                                                                        ...controllerEdit,
                                                                                        ControllerName: e.target.value
                                                                                    })
                                                                                }} />
                                                                    }
                                                                    {isEditEnabled &&
                                                                        ((controllerEdit && controllerEdit.id === controllerObj.id) ?
                                                                            <span style={{ fontSize: '1.2rem', position: 'relative', top: '3px' }} className="material-symbols-outlined click_icon text_near_icon">
                                                                                save
                                                                            </span>
                                                                            :
                                                                            <span style={{ fontSize: '1.2rem', position: 'relative', top: '3px' }} className="material-symbols-outlined click_icon text_near_icon">
                                                                                edit
                                                                            </span>)
                                                                    }
                                                                </h5>
                                                                <h6 className="t_number">
                                                                    {(!controllerEdit || (controllerEdit && controllerEdit.id !== controllerObj.id)) ? controllerObj.ControllerPhone :
                                                                        (controllerEdit && controllerEdit.id !== controllerObj.id) ? controllerObj.ControllerPhone :
                                                                            <input type="text" placeholder='Controller Phone.. '
                                                                                value={controllerEdit.ControllerPhone}
                                                                                onChange={(e) => {
                                                                                    setControllerEdit({
                                                                                        ...controllerEdit,
                                                                                        ControllerPhone: e.target.value
                                                                                    })
                                                                                }} />
                                                                    }
                                                                </h6>
                                                                {isEditEnabled &&
                                                                    !controllerObj.IsCreater &&
                                                                    <div onClick={(e) => {
                                                                        setConfirmDelete({
                                                                            isDelete: true,
                                                                            deleteObjectType: 'Controller',
                                                                            deleteMessage: 'Are you sure to delete controller ' + controllerObj.ControllerName,
                                                                            deleteID: controllerObj.id
                                                                        })
                                                                    }}
                                                                        style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                                                                        <span className="material-symbols-outlined" style={{ position: 'relative', top: '-5px', fontSize: '0.9rem' }}>
                                                                            delete
                                                                        </span>
                                                                        <h6
                                                                            className="text_red pointer"
                                                                            style={{
                                                                                width: "fit-content",
                                                                                fontSize: "10px",
                                                                                letterSpacing: "0.4px",
                                                                                marginLeft: "3px"
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </h6></div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="wha_call_icon">
                                                            <Link
                                                                className="call_icon wc_single"
                                                                to={true ? `tel:${controllerObj.ControllerPhone}` : "#"}
                                                            >
                                                                <img src="/img/simple_call.png" alt="" />
                                                            </Link>
                                                            <Link
                                                                className="wha_icon wc_single"
                                                                to={true ? `https://wa.me/${controllerObj.ControllerPhone}` : "#"}
                                                                target="_blank"
                                                            >
                                                                <img src="/img/whatsapp_simple.png" alt="" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </section > <br /></>
            }
            <TournamentCategory
                categoryDetails={props.categoryDetails}
                IsEditEnabled={props.IsEditEnabled}
                eventDetails={props.eventDetails}
                categoryInRow={3}></TournamentCategory>
            {/* <div className="heading" style={{ display: 'flex', alignItems: 'center' }}>
                <span className="material-symbols-outlined">
                    category
                </span>
                <h4 style={{ fontWeight: '1000' }}>Categories ( {categoryList && isEditEnabled ? categoryList.length :
                    categoryList && !isEditEnabled ? categoryList.filter(e => e.IsActive === true).length : '0'} )</h4>
                {isEditEnabled && <div style={{
                    position: 'relative',
                    left: '20px',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                    background: '#348DCB',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }} onClick={() => {

                    setCategoryPopup({
                        showPopup: true,
                        action: 'Add',
                        categoryObj: {
                            id: '',
                            CategoryName: '',
                            Gender: 'MALE',
                            EventType: 'SINGLE',
                            DateRefType: 'Before',
                            MaxTeamSize: 1,
                            Fees: 0,
                            ReferenceDate: new Date()

                        }
                    })
                }}>
                    <span style={{ fontSize: '1.4rem', color: '#fff', position: 'absolute', left: '50%', transform: 'translateX(-35%)' }} className="material-symbols-outlined">
                        add
                    </span>
                </div>}
            </div><br /> */}
            {/* <div className='container-fluid'>
                <div className='row no-gutters'>
                    {categoryList && categoryList.map((category) => (
                        // (isEditEnabled || (!isEditEnabled && category.IsActive)) &&
                        (isEditEnabled || (!isEditEnabled && category.IsActive)) &&
                        (
                            <div className='col-lg-4 col-md-6 col-sm-12' key={category.id}>
                                <div className='event-details-category-card'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className='event-details-category-card-icon'>
                                            {category.Gender.toLowerCase() === 'male' && category.EventType.toLowerCase() === 'single' ?

                                                <div className='boys-singles'>
                                                    <span className="material-symbols-outlined">
                                                        man
                                                    </span>
                                                </div>
                                                : category.Gender.toLowerCase() === 'male' && category.EventType.toLowerCase() === 'double' ?
                                                    <div className='boys-doubles'>
                                                        <span className="material-symbols-outlined">
                                                            man
                                                        </span>
                                                        <span className="material-symbols-outlined">
                                                            man
                                                        </span>
                                                    </div>
                                                    : category.Gender.toLowerCase() === 'female' && category.EventType.toLowerCase() === 'single' ?
                                                        <div className='girls-singles'>
                                                            <span className="material-symbols-outlined">
                                                                woman
                                                            </span>
                                                        </div>
                                                        : category.Gender.toLowerCase() === 'female' && category.EventType.toLowerCase() === 'double' ?
                                                            <div className='girls-doubles'>
                                                                <span className="material-symbols-outlined">
                                                                    woman
                                                                </span>
                                                                <span className="material-symbols-outlined">
                                                                    woman
                                                                </span>
                                                            </div>
                                                            : category.Gender.toLowerCase() === 'mixed' && category.EventType.toLowerCase() === 'double' ?

                                                                <div className='mixed-doubles'>
                                                                    <span className="material-symbols-outlined">
                                                                        man
                                                                    </span>
                                                                    <span className="material-symbols-outlined">
                                                                        woman
                                                                    </span>
                                                                </div>
                                                                : category.Gender.toLowerCase() === 'male' && category.EventType.toLowerCase() === 'team' ?
                                                                    <div className='boys-team'>
                                                                        <span className="material-symbols-outlined">
                                                                            man
                                                                        </span>
                                                                        <span className="material-symbols-outlined">
                                                                            man
                                                                        </span>
                                                                        <span className="material-symbols-outlined">
                                                                            man
                                                                        </span>
                                                                        <span className="material-symbols-outlined">
                                                                            man
                                                                        </span>
                                                                    </div>
                                                                    : category.Gender.toLowerCase() === 'female' && category.EventType.toLowerCase() === 'team' ?

                                                                        <div className='girls-team'>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                        </div>
                                                                        : category.Gender.toLowerCase() === 'mixed' && category.EventType.toLowerCase() === 'team' &&
                                                                        <div className='mixed-team'>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                        </div>}
                                        </div>
                                        <div className='event-details-category-card-details'>
                                            <h1>{category.CategoryName}</h1>
                                            <hr />
                                            <div className='type'>
                                                <h2 style={{
                                                    color: category.Gender.toLowerCase() === 'male' ?
                                                        'rgb(33, 122, 255)' :
                                                        category.Gender.toLowerCase() === 'female' ?
                                                            'rgb(255, 33, 103)' : '#aaa'
                                                }}>
                                                    {category.Gender.toLowerCase() === 'male' ? 'Boys'
                                                        : category.Gender.toLowerCase() === 'female' ? 'Girls' : 'Mixed'}</h2>
                                                <h3>{category.EventType.toLowerCase() === 'single' ? 'Singles'
                                                    : category.EventType.toLowerCase() === 'double' ? 'Doubles' : 'Team'}</h3>
                                            </div>
                                            <div className='type' style={{ justifyContent: 'space-between', paddingRight: '5px' }}>

                                                <h4>₹{category.Fees}</h4>
                                                <button className='mybutton button5'
                                                    onClick={(e) => { navigate("/RegisteredProfile/" + props.eventDetails.id) }}
                                                    style={{
                                                        fontSize: '0.8rem',
                                                        height: '25px',
                                                        borderRadius: '8px',
                                                        paddingTop: '3px',
                                                        margin: '0'
                                                    }}>Register</button>
                                            </div>
                                        </div>
                                        {isEditEnabled && <div className='event-details-category-card-edit-delete'>
                                            <div onClick={() => {
                                                setCategoryPopup({
                                                    showPopup: true,
                                                    action: 'Edit',
                                                    categoryObj: category
                                                });
                                            }}>
                                                <span className="material-symbols-outlined">
                                                    edit
                                                </span>
                                            </div>
                                            <div onClick={(e) => {
                                                setConfirmDelete({
                                                    isDelete: true,
                                                    deleteObjectType: 'Category',
                                                    deleteMessage: 'Are you sure to delete category ' + category.CategoryName,
                                                    deleteID: category.id
                                                })
                                            }} >
                                                <span style={{ color: 'red' }} className="material-symbols-outlined">
                                                    delete
                                                </span>
                                            </div>

                                        </div>}
                                    </div>
                                </div>
                            </div>


                        )

                    ))}

                </div>
            </div> */}
            <hr></hr>
            <div className="heading" style={{ display: 'flex', alignItems: 'center' }}>

                <span className="material-symbols-outlined">
                    display_settings
                </span>
                <h4 style={{ fontWeight: '1000' }}>Event Details</h4>
                {isEditEnabled && <div style={{
                    position: 'relative',
                    left: '20px',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50%',
                    background: '#348DCB',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }} onClick={() => handleEditNoticeBoard()}>
                    <span style={{ fontSize: '1.1rem', color: '#fff', position: 'absolute', left: '50%', transform: 'translateX(-30%)' }} className="material-symbols-outlined">
                        edit
                    </span>
                </div>}
            </div>

            <h2 className="event-details" id="EventDetails">
                {isNoticeBoardEdit ? (
                    <div>
                        <div>
                            <RichTextEditor
                                value={noticeBoardvalue}
                                onChange={setNoticeBoardvalue}
                            />
                        </div>
                        <div className="vg10"></div>
                        <div className="d-flex" style={{ paddingTop: '5px' }}>
                            <div
                                className="theme_btn btn_border"
                                onClick={handleCancelNoticeBoard}
                                style={{
                                    width: "fit-content",
                                }}
                            >
                                <button style={{ background: '#aaa', fontWeight: 'bold' }} className="mybutton button5 event-details-save-btn"
                                    onClick={handleCancelNoticeBoard} type="button">Cancel</button>


                            </div>
                            <div
                                className="theme_btn btn_fill"
                                onClick={handleSaveNoticeBoard}
                                style={{
                                    width: "fit-content",
                                }}
                            >
                                <button style={{ fontWeight: 'bold' }} className="mybutton button5 event-details-save-btn"
                                    onClick={handleSaveNoticeBoard} type="button">Save Event Details</button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="d-flex align-items-center">
                            <p
                                className="editortext"
                                dangerouslySetInnerHTML={{
                                    __html:

                                        noticeBoardvalue.toString(
                                            "html"
                                        ),
                                }}
                            ></p>

                        </div>
                    </>
                )}

            </h2>
            <hr></hr>
            <div style={{ border: '2px solid #333C5D' }} className="secbox">
                <div style={{ display: 'inline-block', position: 'relative' }} className="">
                    <h4 style={{ fontWeight: '1000', color: '#348DCB' }}>
                        <span className="material-symbols-outlined" style={{ paddingRight: '10px', position: 'relative', top: '5px' }}>
                            menu_book
                        </span>
                        Rules & Regulations </h4>
                    <hr />

                    {isEditEnabled && <div style={{
                        position: 'absolute',
                        right: '-50px',
                        top: '50%',
                        transform: 'translateY(-100%)',
                        width: '35px',
                        height: '35px',
                        borderRadius: '50%',
                        background: '#348DCB',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }} onClick={() => handleEditRules()}>
                        <span style={{ fontSize: '1.1rem', color: '#fff' }} className="material-symbols-outlined">
                            edit
                        </span>
                    </div>}
                </div>

                <div id="RulesAndRegulations">

                    {isRulesEdit ? (
                        <div>
                            <div>
                                <RichTextEditor
                                    value={rulesvalue}
                                    onChange={setRulesvalue}
                                />
                            </div>
                            <div className="vg10"></div>
                            <div className="d-flex justify-content-between">
                                <div
                                    className="theme_btn btn_border"
                                    onClick={handleCancelRules}
                                    style={{
                                        width: "fit-content",
                                    }}
                                >
                                    <button className="mybutton button5 event-details-save-btn"
                                        onClick={handleCancelRules} type="button">Cancel</button>

                                </div>
                                <div
                                    className="theme_btn btn_fill"
                                    onClick={handleSaveRules}
                                    style={{
                                        width: "fit-content",
                                    }}
                                >
                                    <button className="mybutton button5 event-details-save-btn"
                                        onClick={handleSaveRules} type="button">Save Rules</button>


                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="d-flex align-items-center">
                                <p
                                    className="editortext"
                                    dangerouslySetInnerHTML={{
                                        __html:

                                            rulesvalue.toString(
                                                "html"
                                            ),
                                    }}
                                ></p>

                            </div>
                        </>
                    )}

                </div>
            </div><br />
            <Disclaimer collapseFlag={false}></Disclaimer>
            <br /><hr />


        </div >
    )
}

