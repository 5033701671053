import React, { useState } from 'react'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'

export default function EditEventSetup(props) {
    const [resultMessage, setResultMessage] = useState('')

    async function saveEventStatus(status) {
        props.setEventDetails({
            ...props.eventDetails,
            EventStatus: status
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            EventStatus: status,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventDetails_EventStatus");
        ret(para1).then(async (result) => {
            setResultMessage('Event Status Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);
        })


    }
    async function saveEventMode(mode) {
        props.setEventDetails({
            ...props.eventDetails,
            EventMode: mode
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            EventMode: mode,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventDetails_EventMode");
        ret(para1).then(async (result) => {
            setResultMessage('Event Mode Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);
        })


    }
    async function saveRegistrationFlag(flag) {
        props.setEventDetails({
            ...props.eventDetails,
            RegistrationOpenFlag: flag
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            RegistrationOpenFlag: flag,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_RegistrationOn");
        ret(para1).then(async (result) => {
            setResultMessage('Registration Flag Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);
        })
    }

    async function savePaymentFlag(flag) {
        props.setEventDetails({
            ...props.eventDetails,
            OnlinePaymentModeFlag: flag
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            OnlinePaymentModeFlag: flag,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_OnlinePaymentMode");
        ret(para1).then(async (result) => {
            setResultMessage('Payment Flag Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }


    async function saveShowParticipantFlag(flag) {
        props.setEventDetails({
            ...props.eventDetails,
            ShowParticipantFlag: flag
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            ShowParticipantFlag: flag,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_ShowParticipant");
        ret(para1).then(async (result) => {
            setResultMessage('Show Participants Flag Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }
    async function saveShowParticipantPostPaymentFlag(flag) {
        props.setEventDetails({
            ...props.eventDetails,
            ShowParticipantPostPaymentFlag: flag
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            ShowParticipantPostPaymentFlag: flag,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_ShowParticipantPostPayment");
        ret(para1).then(async (result) => {
            setResultMessage('Show Participant Post Registration Flag Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }
    async function savePublishDrawFlag(flag) {
        props.setEventDetails({
            ...props.eventDetails,
            PublishDrawFlag: flag
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            PublishDrawFlag: flag,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_PublishDraw");
        ret(para1).then(async (result) => {
            setResultMessage('Publish Draw Flag Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }

    async function savePublishSeedEntryFlag(flag) {
        props.setEventDetails({
            ...props.eventDetails,
            PublishSeedEntryFlag: flag
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            PublishSeedEntryFlag: flag,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_PublishSeed");
        ret(para1).then(async (result) => {
            setResultMessage('Publish Seed Flag Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }
    async function savePublishResultFlag(flag) {
        props.setEventDetails({
            ...props.eventDetails,
            PublishResultFlag: flag
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            PublishResultFlag: flag,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_PublishResult");
        ret(para1).then(async (result) => {
            setResultMessage('Publish Result Flag Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }
    async function savePublishGalleryFlag(flag) {
        props.setEventDetails({
            ...props.eventDetails,
            PublishGalleryFlag: flag
        })

        var para1 = {};
        para1 = {
            EventID: props.eventDetails.Eventid,
            PublishGalleryFlag: flag,
        };
        console.log(para1);
        const ret = await httpsCallable(functions, "updateEventFlag_PublishGallery");
        ret(para1).then(async (result) => {
            setResultMessage('Event details Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }
    return (
        <div className="row no-gutters">

            <div className="event-detail-input-div" style={{ padding: '0 20px' }}>
                <h1>Event Status</h1><br />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 event-status-right-border"><br />
                <input type="hidden" id="hfEventID" />
                <div className="txt_field" style={{ margin: '15px 0', border: 'none' }}>
                    <label className="labelsize active" style={{ top: '-10px' }}>Status</label>
                </div>

                <div className="row no-gutters">

                    <div className="col-6 event-label-div">

                        <input type="radio" checked={props.eventDetails.EventStatus === 'Active' ? true : false} className="checkbox" style={{ width: '0px' }}
                            name="EventStatus"
                            id="Active" value="Active"
                            onChange={(e) => {
                                console.log('in radio checked click')
                            }}
                        />
                        <label onClick={() => {
                            saveEventStatus('Active')
                        }}

                            className="checkbox-label"
                            id="Active-label" htmlFor="Active">
                            <i className="fas fa-plus"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <span>Active</span>
                        </label>

                    </div>

                    <div className="col-6 event-label-div">

                        <input type="radio" className="checkbox" checked={props.eventDetails.EventStatus === 'Inactive' ? true : false} style={{ width: '0px' }} name="EventStatus"
                            id="Inactive" value="Inactive"
                            onChange={(e) => {

                                console.log('in radio checked click')
                            }} />
                        <label onClick={() => {
                            saveEventStatus('Inactive');
                        }} className="checkbox-label"
                            id="Inactive-label" htmlFor="Inactive">
                            <i className="fas fa-plus"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <span>Inactive</span>
                        </label>

                    </div>


                    <div className="col-6 event-label-div">

                        <input type="radio" className="checkbox" checked={props.eventDetails.EventStatus === 'Closed' ? true : false} style={{ width: '0px' }} name="EventStatus"
                            id="Closed" value="Closed" onChange={(e) => {

                                console.log('in radio checked click')
                            }} />
                        <label onClick={() => {
                            saveEventStatus('Closed');
                        }}
                            className="checkbox-label"
                            id="Closed-label" htmlFor="Closed">
                            <i className="fas fa-plus"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <span>Closed</span>
                        </label>

                    </div>

                    <div className="col-6 event-label-div">

                        <input type="radio" className="checkbox" checked={props.eventDetails.EventStatus === 'On Hold' ? true : false} style={{ width: '0px' }} name="EventStatus"
                            id="Onhold" value="Onhold" onChange={(e) => {

                                console.log('in radio checked click')
                            }} />
                        <label onClick={() => {
                            saveEventStatus('On Hold');
                        }} className="checkbox-label"
                            id="Onhold-label" htmlFor="Onhold">
                            <i className="fas fa-plus"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <span>Onhold</span>
                        </label>

                    </div>

                    <div className="col-6 event-label-div">

                        <input type="radio" className="checkbox" checked={props.eventDetails.EventStatus === 'Cancelled' ? true : false} style={{ width: '0px' }} name="EventStatus"
                            id="Cancelled" value="Cancelled" onChange={(e) => {

                                console.log('in radio checked click')
                            }} />
                        <label onClick={() => {
                            saveEventStatus('Cancelled');
                        }} className="checkbox-label"
                            id="Cancelled-label" htmlFor="Cancelled">
                            <i className="fas fa-plus"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <span>Cancelled</span>
                        </label>

                    </div>

                </div>

            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 event-status-left-border"><br />

                <div className="txt_field" style={{ margin: '15px 0', border: 'none' }}>
                    <label className="labelsize active" style={{ top: '-10px' }}>Mode</label>
                </div>

                <div className="row no-gutters">

                    <div className="col-6 event-label-div">

                        <input type="radio" className="checkbox" checked={props.eventDetails.EventMode === 'Open' ? true : false} style={{ width: '0px' }} name="EventMode"
                            id="Open" value="Open" onChange={(e) => {

                                console.log('in radio checked click')
                            }} />
                        <label onClick={() => {
                            saveEventMode('Open');
                        }} className="checkbox-label"
                            id="Open-label" htmlFor="Open">
                            <i className="fas fa-plus"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <span>Open</span>
                        </label>

                    </div>

                    <div className="col-6 event-label-div">

                        <input type="radio" className="checkbox" checked={props.eventDetails.EventMode === 'Fixture' ? true : false} style={{ width: '0px' }} name="EventMode"
                            id="Fixture" value="Fixture" onChange={(e) => {

                                console.log('in radio checked click')
                            }} />
                        <label className="checkbox-label" onClick={() => {
                            saveEventMode('Fixture');
                        }}
                            id="Fixture-label" htmlFor="Fixture">
                            <i className="fas fa-plus"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <span>Fixture</span>
                        </label>

                    </div>

                    <div className="col-6" style={{ padding: '5px 10px' }}>

                        <input type="radio" className="checkbox" checked={props.eventDetails.EventMode === 'Book' ? true : false} style={{ width: '0px' }} name="EventMode"
                            id="Book" value="Book" onChange={(e) => {

                                console.log('in radio checked click')
                            }} />
                        <label className="checkbox-label" onClick={() => {
                            saveEventMode('Book');
                        }}
                            id="Book-label" htmlFor="Book">
                            <i className="fas fa-plus"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <i className="fas fa-check"
                                style={{ paddingRight: '2px', fontSize: '0.6rem' }}></i>
                            <span>Book</span>
                        </label>

                    </div>

                </div>

            </div>

            <div className="col-lg-12 col-md-12 col-sm-12"><br />
                <hr className="event-update-hr" />

                <div className="txt_field"
                    style={{ margin: '15px 0', border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <label className="labelsize active"
                        style={{ position: 'relative', top: '10px' }}>Registration</label>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                            <span className={props.eventDetails.RegistrationOpenFlag === 'ON' ? 'active' : ''}
                                id="RegistrationOn" style={{ color: '#59981A' }}>On</span>
                            {/* {console.log('props.eventDetails.EventStatus', props.eventDetails.EventStatus)} */}
                            <div className={props.eventDetails.RegistrationOpenFlag === 'ON' ? 'switch-button on' : 'switch-button off'} id="RegistrationToggleBtn"

                                onClick={() => {
                                    saveRegistrationFlag(props.eventDetails.RegistrationOpenFlag === 'ON' ? 'OFF' : 'ON')

                                }}
                            // pointer-events={props.eventDetails.EventStatus.toUpperCase() !== 'ACTIVE' ? 'none' : ''}
                            >

                                <div className="switch-button-inner" ></div>
                            </div>

                            <span className={props.eventDetails.RegistrationOpenFlag === 'ON' ? '' : 'active'} id="RegistrationOff"
                                style={{ color: '#EB542E' }}>Off</span>
                        </div>
                    </div>

                </div>

            </div>
            <hr className="event-update-hr" />

            <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="txt_field"

                    style={{ margin: '15px 0', border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <label className="labelsize active"
                        style={{ position: 'relative', top: '10px' }}>Payment</label>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                            <span className={props.eventDetails.OnlinePaymentModeFlag === 'ON' ? 'active' : ''} id="PaymentOn" style={{ color: '#59981A' }}>On</span>

                            <div
                                className={props.eventDetails.OnlinePaymentModeFlag === 'ON' ? 'switch-button on' : 'switch-button off'} id="RegistrationToggleBtn"

                                onClick={() => {
                                    savePaymentFlag(props.eventDetails.OnlinePaymentModeFlag === 'ON' ? 'OFF' : 'ON')

                                }}>

                                <div className="switch-button-inner"></div>
                            </div>

                            <span className={props.eventDetails.OnlinePaymentModeFlag === 'ON' ? '' : 'active'} id="PaymentOff" style={{ color: '#EB542E' }}>Off</span>
                        </div>
                    </div>

                </div>

            </div>
            <hr className="event-update-hr" />

            <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="txt_field"
                    style={{ margin: '15px 0', border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <label className="labelsize active" style={{ position: 'relative', top: '10px' }}>Show
                        Participants</label>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                            <span className={props.eventDetails.ShowParticipantFlag === 'ON' ? 'active' : ''} id="ShowParticipantsOn" style={{ color: '#59981A' }}>On</span>

                            <div className={props.eventDetails.ShowParticipantFlag === 'ON' ? 'switch-button on' : 'switch-button off'}

                                onClick={() => {
                                    saveShowParticipantFlag(props.eventDetails.ShowParticipantFlag === 'ON' ? 'OFF' : 'ON')
                                }}
                            >
                                <div className="switch-button-inner"></div>
                            </div>

                            <span className={props.eventDetails.ShowParticipantFlag === 'ON' ? '' : 'active'} id="ShowParticipantsOff"
                                style={{ color: '#EB542E' }}>Off</span>
                        </div>
                    </div>

                </div>

            </div>
            <hr className="event-update-hr" />

            <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="txt_field"
                    style={{ margin: '15px 0', border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <label className="labelsize active" style={{ position: 'relative', top: '10px' }}>Show
                        Participants Post
                        Payment</label>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                            <span className={props.eventDetails.ShowParticipantPostPaymentFlag === 'ON' ? 'active' : ''} id="ShowParticipantsPostPaymentOn"
                                style={{ color: '#59981A' }}>On</span>

                            <div className={props.eventDetails.ShowParticipantPostPaymentFlag === 'ON' ? 'switch-button on' : 'switch-button off'}
                                onClick={() => {
                                    saveShowParticipantPostPaymentFlag(props.eventDetails.ShowParticipantPostPaymentFlag === 'ON' ? 'OFF' : 'ON')

                                }}
                            >
                                <div className="switch-button-inner"></div>
                            </div>

                            <span className={props.eventDetails.ShowParticipantPostPaymentFlag === 'ON' ? '' : 'active'} id="ShowParticipantsPostPaymentOff"
                                style={{ color: '#EB542E' }}>Off</span>
                        </div>
                    </div>

                </div>

            </div>
            <hr className="event-update-hr" />

            <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="txt_field"
                    style={{ margin: '15px 0', border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <label className="labelsize active"
                        style={{ position: 'relative', top: '10px' }}>Publish Draws</label>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>

                            <span className={props.eventDetails.PublishDrawFlag === 'ON' ? 'active' : ''} id="DrawsOn" style={{ color: '#59981A' }}>On</span>

                            <div className={props.eventDetails.PublishDrawFlag === 'ON' ? 'switch-button on' : 'switch-button off'}
                                onClick={() => {
                                    savePublishDrawFlag(props.eventDetails.PublishDrawFlag === 'ON' ? 'OFF' : 'ON')

                                }}
                            >
                                <div className="switch-button-inner"></div>
                            </div>

                            <span className={props.eventDetails.PublishDrawFlag === 'ON' ? '' : 'active'} id="DrawsOff" style={{ color: '#EB542E' }}>Off</span>
                        </div>
                    </div>

                </div>

            </div>
            <hr className="event-update-hr" />

            <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="txt_field"
                    style={{ margin: '15px 0', border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <label className="labelsize active"
                        style={{ position: 'relative', top: '10px' }}>Show Seed</label>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                            <span className={props.eventDetails.PublishSeedEntryFlag === 'ON' ? 'active' : ''} id="SeedOn" style={{ color: '#59981A' }}>On</span>

                            <div className={props.eventDetails.PublishSeedEntryFlag === 'ON' ? 'switch-button on' : 'switch-button off'}
                                onClick={() => {
                                    savePublishSeedEntryFlag(props.eventDetails.PublishSeedEntryFlag === 'ON' ? 'OFF' : 'ON')

                                }}>
                                <div className="switch-button-inner"></div>
                            </div>

                            <span className={props.eventDetails.PublishSeedEntryFlag === 'ON' ? '' : 'active'} id="SeedOff" style={{ color: '#EB542E' }}>Off</span>
                        </div>
                    </div>

                </div>

            </div>
            <hr className="event-update-hr" />

            <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="txt_field"
                    style={{ margin: '15px 0', border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <label className="labelsize active"
                        style={{ position: 'relative', top: '10px' }}>Publish Result</label>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                            <span className={props.eventDetails.PublishResultFlag === 'ON' ? 'active' : ''} id="ResultOn" style={{ color: '#59981A' }}>On</span>

                            <div className={props.eventDetails.PublishResultFlag === 'ON' ? 'switch-button on' : 'switch-button off'}
                                onClick={() => {
                                    savePublishResultFlag(props.eventDetails.PublishResultFlag === 'ON' ? 'OFF' : 'ON')
                                }}

                            >
                                <div className="switch-button-inner"></div>
                            </div>

                            <span className={props.eventDetails.PublishResultFlag === 'ON' ? '' : 'active'} id="ResultOff" style={{ color: '#EB542E' }}>Off</span>
                        </div>
                    </div>

                </div>

            </div>
            <hr className="event-update-hr" />

            <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="txt_field"
                    style={{ margin: '15px 0', border: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <label className="labelsize active"
                        style={{ position: 'relative', top: '10px' }}>Gallery</label>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                            <span className={props.eventDetails.PublishGalleryFlag === 'ON' ? 'active' : ''} id="GalleryOn" style={{ color: '#59981A' }}>On</span>

                            <div className={props.eventDetails.PublishGalleryFlag === 'ON' ? 'switch-button on' : 'switch-button off'}
                                onClick={() => {
                                    savePublishGalleryFlag(props.eventDetails.PublishGalleryFlag === 'ON' ? 'OFF' : 'ON')
                                }}

                            >
                                <div className="switch-button-inner"></div>
                            </div>

                            <span className={props.eventDetails.PublishGalleryFlag === 'ON' ? '' : 'active'} id="GalleryOff" style={{ color: '#EB542E' }}>Off</span>
                        </div>
                    </div>

                </div>

            </div>
            <div className="col-lg-12 col-md-12 col-sm-12"><br />
                <div className="event-details-save-btn-div">
                    {resultMessage}
                </div><br />

            </div>


        </div >
    )
}
