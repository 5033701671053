import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";
import '../css/MydatePicker.css'
import { httpsCallable } from "firebase/functions";
import { functions, projectStorage } from '../firebase.js'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useImageUpload } from '../hooks/useImageUpload';
import $ from "jquery";
import { useNavigate } from 'react-router-dom';


export default function EditOrganization() {
    const { state } = useLocation();
    const { actionType } = state;

    const { user } = useUserAuth();
    const [resultMessage, setResultMessage] = useState('');

    const [loading, setLoading] = useState(false);
    const [userList, setUserList] = useState(null);
    const [organizationList, setOrganizationList] = useState([]);
    const [lOrganizationID, setLOrganizationID] = useState('')
    const [showError, setShowError] = useState(false);
    const [thumbnail, setThumbnail] = useState(null)
    const [loadImage, setLoadImage] = useState(false);
    const [thumbnailError, setThumbnailError] = useState(null)
    const { imgUpload, isImgCompressPending, imgCompressedFile } = useImageUpload()
    const [resetFlag, setResetFlag] = useState(false);
    const [expandParam, setExpandParam] = useState(false);
    const navigate = useNavigate();

    const [organizationDetails, setOrganizationDetails] = useState({
        OrganizationID: '',
        OrganizationName: '',
        OrganizationType: '',
        OrganizerID: '',
        PartnerEmailID: '',
        PartnerName: '',
        PartnerPhone: '',
        OrganizerLogo: '',
        City: '',
        State: '',
        ApprovalStatus: 'Approved',
        Comments: '',
        IdentityNumber: '',
        IdentityType: '',
        PANNumber: '',
        AadharNumber: '',
    })
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    function selectStatus(status) {
        setOrganizationDetails({
            ...organizationDetails,
            OrganizerID: status
        })
    }
    function selectOrgType(orgType) {
        setOrganizationDetails({
            ...organizationDetails,
            OrganizationType: orgType
        })
    }
    function selectOrganizer(organiser) {
        setOrganizationDetails({
            ...organizationDetails,
            OrganizerID: organiser
        })
    }
    useEffect(() => {
        // console.log('organizationID', organizationID);
        getUserList();
        lOrganizationID && lOrganizationID !== '' && getOrganizationDetails();
        // console.log('userList : ', userList);
        if (actionType === 'Edit') {
            getOrganizationList();
        }
    }, [])

    async function getOrganizationList() {
        let orgLst = [];
        const ret = await httpsCallable(functions, "getAllOrganizationDetails");
        ret().then(async (result) => {
            // console.log(result);
            result.data.forEach(element => {
                // console.log(element)
                orgLst.push({
                    OrganizationID: element.resultsid,
                    PartnerName: element.PartnerName ? element.PartnerName : '',
                    OrganizationName: element.OrganizationName ? element.OrganizationName : '',
                    OrganizerID: element.OrganizerID ? element.OrganizerID : '',
                    PartnerEmailID: element.PartnerEmailID ? element.PartnerEmailID : '',
                    PartnerPhone: element.PartnerPhone ? element.PartnerPhone : '',
                    PANNumber: element.PANNumber ? element.PANNumber : '',
                    AadharNumber: element.AadharNumber ? element.AadharNumber : '',
                    Pincode: element.Pincode ? element.Pincode : '',
                    City: element.City ? element.City : '',
                    State: element.State ? element.State : '',
                    Country: element.Country ? element.Country : '',
                    Address: element.Address ? element.Address : '',
                    ApprovalStatus: element.ApprovalStatus ? element.ApprovalStatus : '',
                    Comments: element.Comments ? element.Comments : '',
                    OrganizationType: element.OrganizationType ? element.OrganizationType : '',
                    OrganizerLogo: element.OrganizerLogo ? element.OrganizerLogo : '',
                })

            });
            // console.log('useLst:', orgLst)
            setOrganizationList(orgLst);
        })
    }
    async function getOrganizationDetails() {
        var para = {};

        para = {
            organizationID: lOrganizationID,
        }

        const ret = await httpsCallable(functions, "getOrganizationDetails");
        ret(para).then(async (element) => {

            setOrganizationDetails({
                OrganizationID: element.data.resultsid,
                OrganizationName: element.data.OrganizationName ? element.data.OrganizationName : '',
                OrganizationType: element.data.OrganizationType ? element.data.OrganizationType : '',
                OrganizerID: element.data.OrganizerID ? element.data.OrganizerID : '',
                PartnerEmailID: element.data.PartnerEmailID ? element.data.PartnerEmailID : '',
                PartnerName: element.data.PartnerName ? element.data.PartnerName : '',
                PartnerPhone: element.data.PartnerPhone ? element.data.PartnerPhone : '',
                OrganizerLogo: element.data.OrganizerLogo ? element.data.OrganizerLogo : '',
                City: element.data.City ? element.data.City : '',
                State: element.data.State ? element.data.State : '',
                Address: element.data.Address ? element.data.Address : '',
                Pincode: element.data.Pincode ? element.data.Pincode : '',
                ApprovalStatus: element.data.ApprovalStatus ? element.data.ApprovalStatus : '',
                Comments: element.data.Comments ? element.data.Comments : '',
                IdentityNumber: element.data.IdentityNumber ? element.data.IdentityNumber : '',
                IdentityType: element.data.IdentityType ? element.data.IdentityType : '',
                PANNumber: element.data.PANNumber ? element.data.PANNumber : '',
                AadharNumber: element.data.AadharNumber ? element.data.AadharNumber : '',

            });
        })
    }

    async function saveOrganizationDetails() {
        var para = {};
        if (organizationDetails.OrganizationName === '' || organizationDetails.PartnerName === ''
            || organizationDetails.PartnerEmailID === '' || organizationDetails.PartnerPhone === '') {

            setResultMessage('Please enter Organization Name, Partner Name, Email and Phone number to add/Update !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);
        } else {


            para = {
                OrganizationID: lOrganizationID,
                OrganizationName: organizationDetails.OrganizationName,
                PartnerName: organizationDetails.PartnerName,
                PartnerEmailID: organizationDetails.PartnerEmailID,
                PartnerPhone: organizationDetails.PartnerPhone,
                PANNumber: organizationDetails.PANNumber,
                AadharNumber: organizationDetails.AadharNumber,
                Pincode: organizationDetails.Pincode,
                City: organizationDetails.City,
                State: organizationDetails.State,
                Country: organizationDetails.Country,
                Address: organizationDetails.Address,
                Comments: organizationDetails.Comments,
                ApprovalStatus: organizationDetails.ApprovalStatus,
                OrganizationType: organizationDetails.OrganizationType,
                OrganizerID: organizationDetails.OrganizerID,
                OrganizerLogo: organizationDetails.OrganizerLogo,

            }
            // console.log('para', para);
            let functionName = '';
            if (lOrganizationID === '') {
                functionName = 'addOrganizationDetails';
            } else {
                functionName = 'updateOrganizationDetails';
            }
            // console.log('functionName', functionName);
            const ret = await httpsCallable(functions, functionName);

            ret(para).then(async (element) => {


                if (element.data.OrganizationID && element.data.OrganizationID !== '') {
                    setLOrganizationID(element.data.OrganizationID);
                }
                setResultMessage('Organization Details Saved Successfully !!')

                setTimeout(function () {
                    setResultMessage('');
                }, 5000);

            })
        }
    }
    async function getUserList() {

        let useLst = [];
        const ret = await httpsCallable(functions, "getUsers");
        ret().then(async (result) => {
            // console.log(result);
            result.data.forEach(element => {
                useLst.push({
                    Userid: element.userid,
                    PlayerID: element.PlayerID ? element.PlayerID : '',
                    Address: element.Address ? element.Address : '',
                    AlternatePhone: element.AlternatePhone ? element.AlternatePhone : '',
                    City: element.City ? element.City : '',
                    Country: element.Country ? element.Country : '',
                    DateOfBirth: element.DateOfBirth ? new Date(element.DateOfBirth._second * 1000) : new Date(),
                    Email: element.Email ? element.Email : '',
                    Gender: element.Gender ? element.Gender : 'Male',
                    Phone: element.Phone ? element.Phone : '',
                    State: element.State ? element.State : '',
                    UserName: element.UserName ? element.UserName : '',
                    UserRole: element.UserRole ? element.UserRole : [],
                    SelectedRole: element.SelectedRole ? element.SelectedRole : '',
                })

            });
            // console.log('useLst:', useLst)
            setUserList(useLst);
        })
    }


    // var getInitials = function (string) {
    //     var names = string.split(' '),
    //         initials = names[0].substring(0, 1).toUpperCase();

    //     if (names.length > 1) {
    //         initials += names[names.length - 1].substring(0, 1).toUpperCase();
    //     }
    //     return initials;
    // };


    const handleFileChange = async (e) => {
        console.log('in handleFileChange');
        setThumbnail(null)
        let file = e.target.files[0]
        setLoadImage(true);
        const compressedImage = await imgUpload(file, 300, 300);

        if (!compressedImage) {
            setThumbnailError('Please select a file')
            setLoadImage(false);
            return
        }
        if (!compressedImage.type.includes('image')) {
            setThumbnailError('Selected file must be an image')
            setLoadImage(false);
            return
        }

        // if (newImageFile.size > 20000000) {
        //   setThumbnailError('Image file size must be less than 20mb')
        //   return
        // }

        setThumbnailError(null)
        // setThumbnail(compressedImage)

        if (compressedImage) {
            // console.log('thumbnail in useSignup 2:', thumbnail)
            // let ts = Date.now();
            // console.log(ts);
            let uploadPath = '';

            uploadPath = `Organization/${'uid_' + lOrganizationID + '.png'}`
            // console.log('uploadPath : ', uploadPath);
            const imgRef = ref(projectStorage, uploadPath);

            uploadBytes(imgRef, compressedImage)
                .then((snapshot) => {
                    getDownloadURL(imgRef).then(async (urlRef) => {
                        // console.log('urlRef', urlRef);
                        setOrganizationDetails({
                            ...organizationDetails,
                            OrganizerLogo: urlRef
                        })
                        // console.log('image updated successfully : ', playerDetailsCopy);
                        //update in DB

                        let para1 = {
                            OrganizationID: lOrganizationID,
                            OrganizerLogo: urlRef,
                        };
                        // console.log(para1);
                        // setLoading(true);
                        const ret1 = httpsCallable(functions, "updateOrganizationLOGO");
                        ret1(para1).then(async (result) => {
                            // setLoadImage(false);
                            setResultMessage('Organization LOGO Saved Successfully !!')

                            setTimeout(function () {
                                setResultMessage('');
                            }, 5000);

                        });

                    });
                })


        }
    }
    function selectrganizationForEdit(orgID) {
        // console.log('orgID', orgID)
        let selectedOrg = organizationList.findIndex(e => e.OrganizationID === orgID);
        // console.log('selectedOrg', selectedOrg)
        setLOrganizationID(orgID);
        setOrganizationDetails(organizationList[selectedOrg])
    }
    function resetParam() {
        navigate(-1);

    }
    // console.log('OrganizationDetails', organizationDetails);
    return (
        <div className="container-fluid">
            <div className='fixed-settings-breadcrum'>
                <div>
                    <span style={{ fontSize: '1.8rem' }} className="material-symbols-outlined">
                        person
                    </span>
                </div>
                <span style={{ position: 'relative', top: '-2px' }} onClick={resetParam} >  Profile </span>
                {!resetFlag && <span style={{ position: 'relative', top: '-2px' }}> &lt; </span>}
                <span className='active' style={{ position: 'relative', top: '-2px' }}> {actionType === 'Add' ? 'Add Organization' : 'Edit Organization'} </span>
                <span style={{ position: 'relative', top: '-2px' }}> {!resetFlag && expandParam} </span>


            </div>

            <br />

            <div className="event-detail-input-div" style={{ padding: '0' }}>

                {actionType === 'Edit' && <> <label style={{ fontSize: '1.2rem' }}>Select Organization to Edit </label>

                    <select name="" value={lOrganizationID} onChange={(e) => {
                        selectrganizationForEdit(e.target.value)
                    }}>

                        {organizationList && organizationList.map((userL) => {
                            // console.log('userL', userL);
                            return <option value={userL.OrganizationID} key={userL.OrganizationID}>{userL.OrganizationName}</option>
                        })}

                    </select>
                </>}
                <div className="analytics-div" style={{ textAlign: 'center' }}>
                    <h1>Organization   <span > {organizationDetails && " - " + (actionType === 'Add' ? "(New)" : organizationDetails.OrganizationName)}</span> </h1>
                </div>
                <hr style={{ border: 'none', borderTop: '1px solid #888' }} />
            </div>

            <div className="row no-gutters">
                {actionType === 'Edit' && <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: '10px 0' }}>

                </div>}
                <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: '10px 0' }}>

                    <div className="update-event-form" id="EventSetupForm3">


                        <div className="white-div"><br />

                            <div className='event-setup-details-section'>
                                <div className="row no-gutters">
                                    {/* <div className="event-detail-input-div" style={{ padding: '0 20px' }}>
                                        <h1>Organization Details</h1><br />
                                    </div> */}
                                    <div className="col-lg-6 col-md-6 col-sm-12">


                                        <div className="event-detail-input-div">
                                            <label>Organization Name</label>
                                            <input type="text" id="organizationName" onChange={(e) =>
                                                setOrganizationDetails({
                                                    ...organizationDetails,
                                                    OrganizationName: e.target.value
                                                })} placeholder="Organization Name..."
                                                required
                                                value={organizationDetails.OrganizationName} />

                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">

                                        <div className="event-detail-input-div">
                                            <label>Organizer/Partner Name</label>
                                            <input type="text" id="organizationName" onChange={(e) =>
                                                setOrganizationDetails({
                                                    ...organizationDetails,
                                                    PartnerName: e.target.value
                                                })} placeholder="Organizer / Partner Name..."
                                                required
                                                value={organizationDetails.PartnerName} />

                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="event-detail-input-div">
                                            <label>Organizer Email</label>
                                            <input type="text" onChange={(e) =>
                                                setOrganizationDetails({
                                                    ...organizationDetails,
                                                    PartnerEmailID: e.target.value
                                                })} placeholder="Organizer / Partner Email..."
                                                required
                                                value={organizationDetails.PartnerEmailID} />

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="event-detail-input-div">
                                            <label >Organizer Phone</label>
                                            <input type="text" onChange={(e) =>
                                                setOrganizationDetails({
                                                    ...organizationDetails,
                                                    PartnerPhone: e.target.value
                                                })} placeholder="Organizer / Partner Phone..."
                                                required
                                                value={organizationDetails.PartnerPhone} />

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">

                                        <div className="event-detail-input-div">
                                            <label>PAN Number</label>
                                            <input type="text" onChange={(e) =>
                                                setOrganizationDetails({
                                                    ...organizationDetails,
                                                    PANNumber: e.target.value
                                                })} placeholder="PAN Number..."
                                                required
                                                value={organizationDetails.PANNumber} />

                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="event-detail-input-div">
                                            <label >Aadhar Number</label>
                                            <input type="text" onChange={(e) =>
                                                setOrganizationDetails({
                                                    ...organizationDetails,
                                                    AadharNumber: e.target.value
                                                })} placeholder="Aadhar Number..."
                                                required
                                                value={organizationDetails.AadharNumber} />

                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="event-detail-input-div">
                                            <label>Pincode</label>

                                            <input type="number" id="pinCode" maxLength={6}
                                                onInput={(e) => {
                                                    if (e.target.value.length > e.target.maxLength)
                                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                }}

                                                value={organizationDetails.Pincode}
                                                onChange={(e) => {

                                                    setOrganizationDetails({
                                                        ...organizationDetails,
                                                        Pincode: e.target.value
                                                    })


                                                }}
                                                onBlur={async (e) => {
                                                    // console.log('onblur', pincode)
                                                    if (organizationDetails.Pincode.length !== 6) {

                                                        setShowError(true);
                                                    } else {
                                                        setShowError(false);
                                                        await $.getJSON("https://api.postalpincode.in/pincode/" + organizationDetails.Pincode,
                                                            async function (data) {
                                                                if (data[0].Message === "No records found") {
                                                                    setShowError(true);
                                                                    setTimeout(function () {
                                                                        setShowError(false);
                                                                    }, 5000);

                                                                } else {

                                                                    setOrganizationDetails({
                                                                        ...organizationDetails,
                                                                        Pincode: organizationDetails.Pincode,
                                                                        City: data[0].PostOffice[0].Block,
                                                                        State: data[0].PostOffice[0].State,
                                                                        Country: data[0].PostOffice[0].Country
                                                                    })

                                                                }
                                                            });
                                                    }
                                                }}
                                                required />
                                            <h5 id="userLocation" style={{ display: !showError ? 'block' : 'none', fontSize: '0.9rem' }}>{organizationDetails.City} {organizationDetails.City !== '' && organizationDetails.City !== undefined ? ',' : ''} {organizationDetails.State}</h5>
                                            <h5 id="errorMessage" style={{ display: showError ? 'block' : 'none', fontSize: '0.9rem' }}>Please enter Valid Pincode</h5>
                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="event-detail-input-div">
                                            <label>City</label>
                                            <input type="text" id="vanueContact"
                                                onChange={(e) =>
                                                    setOrganizationDetails({
                                                        ...organizationDetails,
                                                        City: e.target.value
                                                    })}
                                                value={organizationDetails.City}
                                                placeholder="City..."
                                                required />
                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">

                                        <div className="event-detail-input-div">
                                            <label>Full address</label>
                                            <input type="text"
                                                onChange={(e) =>
                                                    setOrganizationDetails({
                                                        ...organizationDetails,
                                                        Address: e.target.value
                                                    })}
                                                value={organizationDetails.Address}
                                                placeholder="Enter Address / Map URL..." required />
                                        </div>

                                    </div>



                                    <div className="col-lg-6 col-md-6 col-sm-12">

                                        <div className="event-detail-input-div">
                                            <label>Comments / Remarks</label>
                                            <input type="text" className="" onChange={(e) =>
                                                setOrganizationDetails({
                                                    ...organizationDetails,
                                                    Comments: e.target.value
                                                })}
                                                value={organizationDetails.Comments} required />
                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">

                                        <div className="event-detail-input-div">

                                            <label>Organization status</label>

                                            <select name="" value={organizationDetails.ApprovalStatus} onChange={(e) => {
                                                selectStatus(e.target.value)
                                            }}>
                                                <option value="Approved">Approved</option>
                                                <option value="Pending Approval">Pending Approval</option>
                                                <option value="Inactive">Inactive</option>

                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">

                                        <div className="event-detail-input-div">
                                            <label >Organization Type</label>

                                            <select name="" value={organizationDetails.OrganizationType}

                                                onChange={(e) => {
                                                    selectOrgType(e.target.value)
                                                }}>
                                                <option value="Sports Club">Sports Club</option>
                                                <option value="School">School</option>
                                                <option value="Corporate">Corporate</option>
                                                <option value="Housing Society">Housing Society</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">

                                        <div className="event-detail-input-div">
                                            <label>Organizer Mapping</label>

                                            <select name="" value={organizationDetails.OrganizerID} onChange={(e) => {
                                                selectOrganizer(e.target.value)
                                            }}>

                                                {userList && userList.map((userL) => {
                                                    // console.log('userL', userL);
                                                    return <option value={userL.Userid} key={userL.Userid}>{userL.UserName}({userL.Phone})</option>
                                                })}

                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="event-details-save-btn-div" style={{ height: '100%', alignItems: 'center' }}>
                                            <div></div>

                                            <button className="mybutton button5" onClick={saveOrganizationDetails} style={{ width: '150px', height: '40px' }}>
                                                <div style={{ display: !loading ? 'block' : 'none' }}>
                                                    <span
                                                        style={{ position: 'relative', top: '-1px', fontSize: '1rem' }}>SAVE</span>
                                                </div>
                                                <div className='btn-loading' style={{ display: loading ? 'block' : 'none' }}>
                                                    <lottie-player id="btnSendOTPLoad"
                                                        src="https://assets8.lottiefiles.com/packages/lf20_fiqoxpcg.json" background="transparent"
                                                        speed="0.7" loop autoplay></lottie-player>
                                                </div>
                                            </button>

                                            {/* <button className="mybutton button5 event-details-save-btn"
                                                onClick={saveOrganizationDetails} type="button">SAVE</button> */}
                                        </div>
                                        <br /><br />
                                    </div>

                                    {lOrganizationID !== '' && <div className="col-lg-12 col-md-12 col-sm-12"><br />

                                        <hr />
                                        <div className='upload-img-div' >
                                            <img src={organizationDetails.OrganizerLogo ? organizationDetails.OrganizerLogo : "./img/badminton.jpg"} alt="" />
                                            <input
                                                type="file"
                                                onChange={handleFileChange}
                                                id='profilePic'
                                                className='profile-pic-input'
                                            />

                                            <label htmlFor='profilePic'>
                                                <br />
                                                <div
                                                    style={{
                                                        background: '#348DCB',
                                                        padding: '5px 30px',
                                                        color: '#fff',
                                                        borderRadius: '20px',
                                                        cursor: 'pointer'
                                                    }}>Upload Image / Logo</div>
                                            </label>
                                            <br /><br /><br />
                                        </div>


                                    </div>}
                                    <div className="col-lg-12 col-md-12 col-sm-12"><br />
                                        <div className="event-details-save-btn-div">
                                            {resultMessage}
                                        </div><br />

                                    </div>


                                </div>
                            </div>
                        </div >
                    </div > <br />

                </div >

            </div >
        </div >

    )
}
