import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import '../css/More.css';
import Disclaimer from './Disclaimer';

export default function RefundAndCancellation() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    return (
        <div>
            <div>
                {/* {console.log("in render")} */}
                <div className="" id="fullContent">

                    <div className="city-select-div" style={{ display: 'none' }}>
                        <div className="search">
                            {/* <input type="text" onChange={callOnChange()} placeholder="Enter city" name="" value="" /> */}
                            {/* <input type="text" onChange={this.callOnChange()} placeholder="Enter city" name="" value="" /> */}

                            <span className="material-symbols-outlined">
                                search
                            </span>
                        </div>

                        <div className="cities">
                            <a href="/">Banglore</a>
                            <a href="/">Pune</a>
                            <a href="/">Mumbai</a>
                            <a href="/">Delhi</a>
                            <a href="/">Kolkata</a>
                            <hr />
                            <a href="/">Agra</a>
                            <a href="/">Ahemdabad</a>
                            <a href="/">Ambala</a>
                        </div>

                    </div>
                    {/* {console.log(loading)} */}
                    <div className="" style={{ display: 'flex', justifyContent: 'center', background: '#333C5D', padding: '20px 0' }}>
                        <img src="../img/refund.svg" className='refund-img' alt="" />
                    </div>

                    <section id="genre">

                        <div className='container'>
                            <br />
                            <div style={{ border: '2px solid #333C5D' }} className="secbox">
                                <div style={{ display: 'inline-block' }} className="">
                                    <h4 style={{ fontWeight: '1000', color: '#348DCB' }}>
                                        <span className="material-symbols-outlined" style={{ paddingRight: '10px', position: 'relative', top: '5px' }}>
                                            free_cancellation
                                        </span>
                                        Refund & Cancellation
                                    </h4>
                                    <hr />
                                </div>
                                <ul>
                                    <li>
                                        <p><strong>TPLiVE (Tournament Planner LiVE)</strong> provided the www.tplive.in website as a service to the public. The information provided on the website is for general informational purposes only.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            You can make the request for entry withdrawal directly from the website or to the event organizers or to TPLiVE support team. The Refund is subject to be determined by event organizers and varies from event to event.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            We would recommend users read Rules & Regulations and Terms & Conditions before registering for the event. In case the event is postponed, suspended or delayed, or canceled for any reason, TPLiVE will not be responsible for any refunds nor we will be liable for any loss caused by such cancellation, postponement, suspension, or delay. This is the full responsibility and decision of the event organizers & Participants. It is the participant's responsibility to ascertain whether an event has been canceled and the date and time of any rearranged event and get in touch with the event organizers to know about the refund process. In case a refund is initiated, that will be settled within 7 days after initiation of the refund date.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            TPLiVE does not guarantee that participants will be informed of such cancellation or date & time change before the date of the event. It's the participant’s responsibility to stay updated with the system or coordinate with the Organizer to know the current status of the event.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <br /><br />
                            <Disclaimer collapseFlag={true}></Disclaimer>
                            {/* <div style={{ border: '2px solid #333C5D' }} className="secbox">
                                <div style={{ display: 'inline-block' }} className="">
                                    <h4 style={{ fontWeight: '1000', color: '#348DCB' }}>
                                        <span className="material-symbols-outlined" style={{ paddingRight: '10px', position: 'relative', top: '5px' }}>
                                            privacy_tip
                                        </span>
                                        Disclaimer
                                    </h4>
                                    <hr />
                                </div>
                                <ul>
                                    <li>
                                        <p>
                                            TPLiVE (Tournament Planner LiVE) provided the www.tplive.in website as a service to public.
                                            The information provided on the website is for general informational purposes only.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            TPLiVE is not responsible for any loss or damage of any kind arising out of use.
                                            While the information contained within the site is periodically updated, no guarantee is given that
                                            the information provided in this web site is correct, complete, and up-to-date.
                                            Although the site may include links providing direct access to other internet resources,
                                            including web sites, TPLiVE is not responsible for the accuracy, adequacy, validity,
                                            reliability, availability or completeness of content of information contained in
                                            these sites. Links from the website to third-party sites do not constitute an
                                            endorsement by TPLiVE of the parties or their products and services.
                                            The appearance on the Web site of advertisements and product or service
                                            information does not constitute an endorsement by TPLiVE , and TPLiVE has
                                            not investigated the claims made by any advertiser. Advertisements and
                                            Product information is based solely on the content received from suppliers.
                                        </p>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        <br /><br /><hr />

                    </section>

                </div>

            </div>
        </div>
    )
}
