import React from 'react'
import { useState, useEffect } from 'react';
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import { useLocation } from 'react-router-dom';
import useDocumentUpdate from '../hooks/useDocumentUpdate.js';
import { useUserAuth } from '../context/UserAuthcontext.js';
import SearchComponents from './SearchComponents.js';
// import '../css/UpdateEvent.css';

export default function RenderCategoryForRegistration(props) {
    console.log(props);
    const { user } = useUserAuth();
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    const { deleteDocument, addDocument } = useDocumentUpdate();
    const [partnerFlag, setPartnerFlag] = useState(false);
    const [categorySelectFlag, setCategorySelectFlag] = useState(props.registeredEventStatus ? true : false);
    const [registeredEvent, setRegisteredEvent] = useState(props.registeredEventStatus);
    const [refDate, setRefDate] = useState(props.eventsCategory.ReferenceDate);
    const [playerGender, setPlayerGender] = useState(props.playerGender);
    const [dateRefType, setDateRefType] = useState(props.eventsCategory.DateRefType);
    const [partnerList, setPartnerList] = useState([]);

    const [partnerObject, setPartnerObject] = useState({
        partnerName: props.registeredEventStatus ? props.registeredEventStatus.PartnerPlayerName : "",
        partnerID: props.registeredEventStatus ? props.registeredEventStatus.PartnerPlayerID : ""
    });
    const [errorObj, setErrorObj] = useState({
        showError: props.registeredEventStatus ? false : true,
        alreadyRegistered: false
    });
    const [showError, setShowError] = useState(false)
    const [popupLoading, setPopupLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [isEdit, setIsEdit] = useState(true);
    const [searchClicked, setSearchClicked] = useState(false);
    const [partnerCode, setPartnerCode] = useState('');

    useEffect(() => {
        setCategorySelectFlag(props.registeredEventStatus ? true : false);
        setPartnerObject({
            partnerName: props.registeredEventStatus ? props.registeredEventStatus.PartnerPlayerName : "",
            partnerID: props.registeredEventStatus ? props.registeredEventStatus.PartnerPlayerID : ""
        });

        setErrorObj({
            showError: props.registeredEventStatus ? false : true,
            alreadyRegistered: false
        })
        setRegisteredEvent(props.registeredEventStatus);
        if (props.registeredEventStatus && props.registeredEventStatus.EventType.toUpperCase() === 'DOUBLE') {
            setPartnerFlag(true);
        }
        if (props.registeredEventStatus && props.registeredEventStatus.PaymentStatus
            && (props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' || props.registeredEventStatus.RegType !== 'Self')) {
            setIsEdit(false);
        } else {
            setIsEdit(true);
        }
    }, [props.registeredEventStatus]);

    function CheckPartnerCode() {
        if (partnerObject.partnerID !== '') {
            var rDate = new Date(refDate._seconds * 1000);
            var userRole = {};
            var para1 = {};
            para1 = {
                PlayerID: partnerObject.partnerID,
                EventID: props.eventID,
                CategoryID: props.eventsCategory.CategoryName,
            };
            // console.log(para1);
            const ret1 = httpsCallable(functions, "getPlayerDetailsWithPlayerID");
            ret1(para1).then((result) => {
                // console.log(result.data[0]);
                var flag = false;
                if (result.data[0] !== undefined) {

                    userRole = {
                        userid: result.data[0].userid,
                        PlayerID: result.data[0].PlayerID,
                        Address: result.data[0].Address,
                        AlternatePhone: result.data[0].AlternatePhone,
                        City: result.data[0].City,
                        playerID: result.data[0].PlayerID,
                        Country: result.data[0].Country,
                        DateOfBirth: result.data[0].DateOfBirth,
                        Email: result.data[0].Email,
                        Gender: result.data[0].Gender,
                        Phone: result.data[0].Phone,
                        State: result.data[0].State,
                        UserName: result.data[0].UserName,
                        AlreadyRegistered: result.data[0].AlreadyRegistered,
                    }
                    // console.log(userRole.AlreadyRegistered ? 'True flag' : 'false flag');
                    if (userRole.AlreadyRegistered) {
                        // console.log(errorObj);
                        setErrorObj({
                            alreadyRegistered: 'false',//true,
                            showError: 'false'//true,
                        });
                        // console.log(errorObj);
                    } else {
                        var dob = new Date(userRole.DateOfBirth._seconds * 1000);

                        if ((playerGender.toUpperCase() === 'MALE' && userRole.Gender.toUpperCase() === 'MALE' && props.eventsCategory.Gender.toUpperCase() === 'MALE') ||
                            (playerGender.toUpperCase() === 'FEMALE' && userRole.Gender.toUpperCase() === 'FEMALE' && props.eventsCategory.Gender.toUpperCase() === 'FEMALE') ||
                            (playerGender.toUpperCase() === 'MALE' && userRole.Gender.toUpperCase() === 'FEMALE' && props.eventsCategory.Gender.toUpperCase() === 'MIXED') ||
                            (playerGender.toUpperCase() === 'FEMALE' && userRole.Gender.toUpperCase() === 'MALE' && props.eventsCategory.Gender.toUpperCase() === 'MIXED')) {

                            if (dateRefType === 'Before' && dob <= rDate) {
                                flag = true;

                            } else if (dateRefType === 'After' && dob >= rDate) {
                                flag = true;
                            } else {
                                flag = false;
                            }

                        } else {
                            flag = false;
                        }
                        if (flag === true) {
                            setPartnerObject({
                                partnerName: userRole.UserName,
                                partnerID: partnerObject.partnerID
                            });

                            props.partnerSetup(props.eventsCategory.CategoryName, partnerObject.partnerID, userRole.UserName, userRole.userid);
                            setErrorObj({
                                showError: false,
                                alreadyRegistered: false
                            });
                        }
                        if (flag === false) {
                            setErrorObj({
                                showError: false,
                                alreadyRegistered: false
                            });
                        }

                    }
                }
            });
        }
    }

    async function confirmActionPopup() {
        console.log('in confirmActionPopup', partnerCode)
        // updatePartnerinDB();
        setPartnerObject({
            partnerName: partnerCode.name,
            partnerID: partnerCode.code
        });
        let addData = {
            'EventID': props.eventID,// eventID,
            'ParticipantID': props.playerDetails ? props.playerDetails.PlayerID : '',
            'ParticipantName': props.playerDetails ? props.playerDetails.UserName : '',
            'PlayerID': props.playerDetails ? props.playerDetails.id : '',
            'CategoryName': props.eventsCategory.CategoryName,
            'EventType': props.eventsCategory.EventType,
            'Fees': Number(props.eventsCategory.Fees),
            'Gender': props.eventsCategory.Gender,
            'MaxTeamSize': props.eventsCategory.MaxTeamSize, //
            'PartnerName': partnerCode.name,
            'PartnerPlayerID': partnerCode.code,
            'PaymentStatus': 'Pending',
            "CreatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "CreatedTimestamp": new Date(),
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date(),
            // CategoryList: regCategory,
        }
        await addDocument('EventRegistrationDetails', addData)
        setShowPopup(false);
    }
    function cancleActionPopup() {
        setShowPopup(false);
    }
    async function updatePartnerDetailsPopup() {

        setPopupLoading(true);
        const partnerListQ = httpsCallable(functions, "getAllPartnerListForEvent");
        setLoading(true);

        let para = {
            EventID: props.eventID,
            PlayerID: props.playerDetails ? props.playerDetails.PlayerID : '',
            CategoryName: props.eventsCategory.CategoryName,
        };
        // console.log('para', para);
        await partnerListQ(para)
            .then(async (result) => {
                // console.log('result', result)
                setPopupLoading(false);
                setShowPopup(true);
                let players = []

                result && result.data && result.data.forEach(element => {
                    let str = '';// element.Phone ? element.Phone.slice(-4).padStart(element.Phone.length, '*') : '';

                    const start = element.Phone ? element.Phone.slice(0, 5) : '';       // First 5 characters
                    const masked = '****';               // Mask the mast next 4 characters
                    const end = element.Phone ? element.Phone.slice(9) : '';
                    // console.log(start, masked, end, start + masked + end)
                    str = start + masked + end;
                    players.push({
                        id: element.PlayerID + "#" + element.UserName + '#' + element.id,
                        searchKey: element.PlayerID + element.UserName + element.Phone,
                        displayText: element.UserName + '-' + str,
                    })

                });
                setPartnerList(players);
                console.log(players)
                setShowPopup(true)
            })
    }

    function showExtendeddiv() {
        let flag = false;
        console.log('showExtendeddiv', props.eventsCategory)
        if (categorySelectFlag !== true) {
            if (props.eventsCategory.EventType.toUpperCase() !== 'SINGLE') {
                openPartnerSelection();
            }

        }
    }
    async function registerCategory() {
        let flag = false;
        console.log('registerCategory', props.eventsCategory)
        if (categorySelectFlag === true) {
            //remove item from firebase document
            // console.log('props.eventsCategory.id', props.registeredEventStatus)
            deleteDocument('EventRegistrationDetails', props.registeredEventStatus.id)
            // flag = props.calculateFees('REMOVE', Number(props.eventsCategory.Fees), props.eventsCategory, partnerObject.partnerName, partnerObject.partnerID);
            setShowPopup(false)
            setShowError(false)

        } else {
            // console.log('props.eventsCategory.EventType', props.eventsCategory.EventType)
            if (props.eventsCategory.EventType.toUpperCase() === 'SINGLE') {
                let addData = {
                    'EventID': props.eventID,// eventID,
                    'ParticipantID': props.playerDetails ? props.playerDetails.PlayerID : '',
                    'ParticipantName': props.playerDetails ? props.playerDetails.UserName : '',
                    'PlayerID': props.playerDetails ? props.playerDetails.id : '',
                    'CategoryName': props.eventsCategory.CategoryName,
                    'EventType': props.eventsCategory.EventType,
                    'Fees': Number(props.eventsCategory.Fees),
                    'Gender': props.eventsCategory.Gender,
                    'MaxTeamSize': props.eventsCategory.MaxTeamSize, //
                    'PartnerPlayerName': partnerObject.partnerName,
                    'PartnerPlayerID': partnerObject.partnerID,
                    'PaymentStatus': 'Pending',
                    "CreatedBy": user && user.userInfo ? user.userInfo.uid : '',
                    "CreatedTimestamp": new Date(),
                    "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                    "UpdatedTimestamp": new Date(),
                    // CategoryList: regCategory,
                }
                await addDocument('EventRegistrationDetails', addData)
            } else {
                if (partnerObject.partnerID !== '') {
                    let addData = {
                        'EventID': props.eventID,// eventID,
                        'ParticipantID': props.playerDetails ? props.playerDetails.PlayerID : '',
                        'ParticipantName': props.playerDetails ? props.playerDetails.UserName : '',
                        'PlayerID': props.playerDetails ? props.playerDetails.id : '',
                        'CategoryName': props.eventsCategory.CategoryName,
                        'EventType': props.eventsCategory.EventType,
                        'Fees': Number(props.eventsCategory.Fees),
                        'Gender': props.eventsCategory.Gender,
                        'MaxTeamSize': props.eventsCategory.MaxTeamSize, //
                        'PartnerPlayerName': partnerObject.partnerName,
                        'PartnerPlayerID': partnerObject.partnerID,
                        'PaymentStatus': 'Pending',
                        "CreatedBy": user && user.userInfo ? user.userInfo.uid : '',
                        "CreatedTimestamp": new Date(),
                        "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                        "UpdatedTimestamp": new Date(),
                        // CategoryList: regCategory,
                    }
                    await addDocument('EventRegistrationDetails', addData)

                } else {


                    setShowError(true)
                    updatePartnerDetailsPopup();
                    openPartnerSelection();
                }
            }

            // flag = props.calculateFees('ADD', Number(props.eventsCategory.Fees), props.eventsCategory, partnerObject.partnerName, partnerObject.partnerID);

        }
        // console.log(categorySelectFlag);
        // console.log(flag);
        // if (!flag) {
        //     setCategorySelectFlag(!categorySelectFlag);
        //     if (props.eventsCategory.EventType.toUpperCase() !== 'SINGLE') {
        //         openPartnerSelection();
        //     }
        // }
    }
    function openPartnerSelection() {
        setPartnerFlag(!partnerFlag);
    }
    function closePartner(e) {
        closePartnerSelection();
    }
    function closePartnerSelection() {
        setPartnerFlag(false);

    }
    function setSelectedItemSearchPartner(id, text) {
        console.log(id, text)
        let idlist = id.split("#");
        console.log(idlist)
        setPartnerCode({
            code: idlist[0],
            name: idlist[1]
        });
    }
    function setSearchClickedEvent(flag) {
        // console.log('setSearchClickedEvent : ', flag)
        setSearchClicked(flag)
    }
    function selectPartner() {
        console.log('in selectPartner')
        setShowError(true)
        updatePartnerDetailsPopup();

    }
    function testFunction() {
        alert('hello')
    }

    return (
        <>
            <div className={showPopup ? 'pop-up-div open' : 'pop-up-div'}>
                {!popupLoading && <div className='pop-up-div-inner'>

                    <h1>Update Partner <strong>{props.eventsCategory && props.eventsCategory.CategoryName}</strong> for <strong>{props.playerDetails.UserName}</strong></h1>
                    <hr />

                    <div className='event-detail-input-div' style={{ padding: '0 30px' }}>
                        <label htmlFor="">Select Partner</label>
                        <SearchComponents
                            ItemList={partnerList}
                            width='100%'
                            placeholder={'search partner...'}
                            setSelectedItem={setSelectedItemSearchPartner}
                            setSearchClicked={setSearchClickedEvent}
                        ></SearchComponents>
                    </div>

                    <div className='event-detail-input-div' style={{ padding: '0 30px' }}>
                        {/* <label htmlFor="">Input</label> */}
                        <br></br>
                        <input type="text" value={'Partner will be changed to ' + partnerCode.name} />
                    </div>
                    {searchClicked && <div style={{ height: '140px' }}></div>}
                    <div className='btn-div'>
                        <button className='mybbutton button5' onClick={confirmActionPopup}>
                            <span>CONFIRM</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={cancleActionPopup}>
                            <span>Cancel</span>
                        </button>
                    </div>

                </div>}
            </div>
            <div key={props.eventsCategory.CategoryName} id={props.eventsCategory.CategoryName + props.keyValue} className="col-lg-4 col-md-6 col-sm-12" style={{ padding: '0' }}>
                {/* {console.log('props.eventsCategory.CategoryName', props.eventsCategory.CategoryName, props.eventsCategory.Gender, props.eventsCategory.EventType,
                categorySelectFlag, props.registeredEventStatus)} */}
                <div style={{ padding: '10px' }}>
                    <div id={'div' + props.keyValue} style={{ padding: '0', pointerEvents: isEdit ? 'all' : 'none' }}
                        className={categorySelectFlag ? "reg-category-card active " + (props && props.registeredEventStatus && props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' ? "payment-completed" : "payment-pending") : "reg-category-card"}>

                        <div className="display-flex-div" style={{ padding: '8px 10px 0 10px' }}
                            // onClick={registerCategory}
                            // onClick={showExtendeddiv}

                            id={'div2' + props.keyValue}>

                            <div className="category-details" style={{ width: '100%' }}>
                                {/* <h1>{props.events.CategoryName}</h1> */}
                                {props.eventsCategory.Gender.toUpperCase() === 'FEMALE' && props.eventsCategory.EventType.toUpperCase() === 'SINGLE' ?
                                    <div className='event-details-category-card'
                                        style={{

                                            background: categorySelectFlag && props && props.registeredEventStatus &&
                                                props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' ? '#B1D8B7' :
                                                categorySelectFlag && props && props.registeredEventStatus &&
                                                    props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'PENDING' ? '#F8EA8C'
                                                    : categorySelectFlag ? '#F8EA8C' : '',
                                            boxShadow: '0 0 0 0', marginTop: '0'
                                        }}>
                                        {/* {console.log('props.eventsCategory.CategoryName', props.eventsCategory.CategoryName, props.eventsCategory.Gender, props.eventsCategory.EventType,
                                        categorySelectFlag, props.registeredEventStatus)} */}

                                        <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
                                            <div style={{
                                                position: 'absolute',
                                                width: 'calc(100% - 20px)',
                                                height: '100%'
                                            }}
                                                onClick={showExtendeddiv}
                                            ></div>
                                            <div className='event-details-category-card-icon'>

                                                <div className='girls-singles'>
                                                    <span className="material-symbols-outlined">
                                                        woman
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='event-details-category-card-details'>
                                                <h1 style={{ fontSize: '1.2rem' }}>{props.eventsCategory.CategoryName}</h1>
                                                <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                    <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                        <span>{props.eventsCategory.Fees}</span>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    props.eventsCategory.Gender.toUpperCase() === 'FEMALE' && props.eventsCategory.EventType.toUpperCase() === 'DOUBLE' ?
                                        <div className='event-details-category-card' style={{

                                            background: categorySelectFlag && props && props.registeredEventStatus &&
                                                props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' ? '#B1D8B7' :
                                                categorySelectFlag && props && props.registeredEventStatus &&
                                                    props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'PENDING' ? '#F8EA8C'
                                                    : categorySelectFlag ? '#F8EA8C' : '',
                                            boxShadow: '0 0 0 0', marginTop: '0'
                                        }}>
                                            <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
                                                <div className='doubles-click-div'
                                                    onClick={showExtendeddiv}
                                                ></div>
                                                <div className='event-details-category-card-icon'>

                                                    <div className='girls-doubles'>
                                                        <span className="material-symbols-outlined">
                                                            woman
                                                        </span>
                                                        <span className="material-symbols-outlined">
                                                            woman
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='event-details-category-card-details'>
                                                    <h1 style={{ fontSize: '1.2rem' }}>{props.eventsCategory.CategoryName}</h1>
                                                    <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                        <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                            <span>{props.eventsCategory.Fees}</span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        props.eventsCategory.Gender.toUpperCase() === 'MIXED' && props.eventsCategory.EventType.toUpperCase() === 'DOUBLE' ?
                                            <div className='event-details-category-card' style={{

                                                background: categorySelectFlag && props && props.registeredEventStatus &&
                                                    props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' ? '#B1D8B7' :
                                                    categorySelectFlag && props && props.registeredEventStatus &&
                                                        props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'PENDING' ? '#F8EA8C'
                                                        : categorySelectFlag ? '#F8EA8C' : '',
                                                boxShadow: '0 0 0 0', marginTop: '0'
                                            }}>
                                                <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
                                                    <div className='doubles-click-div'
                                                        onClick={showExtendeddiv}
                                                    ></div>
                                                    <div className='event-details-category-card-icon'>

                                                        <div className='mixed-doubles'>
                                                            <span className="material-symbols-outlined">
                                                                man
                                                            </span>
                                                            <span className="material-symbols-outlined">
                                                                woman
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='event-details-category-card-details'>
                                                        <h1 style={{ fontSize: '1.2rem' }}>{props.eventsCategory.CategoryName}</h1>
                                                        <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                            <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                                <span>{props.eventsCategory.Fees}</span>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            props.eventsCategory.Gender.toUpperCase() === 'MALE' && props.eventsCategory.EventType.toUpperCase() === 'SINGLE' ?

                                                // amber <div className='event-details-category-card' style={{ background: '#F8EA8C', boxShadow: '0 0 0 0', marginTop: '0' }}>
                                                <div className='event-details-category-card'
                                                    style={{

                                                        background: categorySelectFlag && props && props.registeredEventStatus &&
                                                            props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' ? '#B1D8B7' :
                                                            categorySelectFlag && props && props.registeredEventStatus &&
                                                                props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'PENDING' ? '#F8EA8C'
                                                                : categorySelectFlag ? '#F8EA8C' : '',
                                                        boxShadow: '0 0 0 0', marginTop: '0'
                                                    }}>
                                                    {/* {console.log('props.eventsCategory.CategoryName', props.eventsCategory.CategoryName, props.eventsCategory.Gender, props.eventsCategory.EventType,
                                                    categorySelectFlag, props.registeredEventStatus)} */}

                                                    <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: 'calc(100% - 20px)',
                                                            height: '100%'
                                                        }}
                                                            onClick={showExtendeddiv}
                                                        ></div>
                                                        <div className='event-details-category-card-icon'>

                                                            <div className='boys-singles' style={{ background: '#fff' }}>
                                                                <span className="material-symbols-outlined">
                                                                    man
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='event-details-category-card-details'>
                                                            <h1 style={{ fontSize: '1.2rem' }}>{props.eventsCategory.CategoryName}</h1>
                                                            <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                                <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                                    <span>{props.eventsCategory.Fees}</span>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                props.eventsCategory.Gender.toUpperCase() === 'MALE' && props.eventsCategory.EventType.toUpperCase() === 'DOUBLE' ?
                                                    <div className='event-details-category-card' style={{

                                                        background: categorySelectFlag && props && props.registeredEventStatus &&
                                                            props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' ? '#B1D8B7' :
                                                            categorySelectFlag && props && props.registeredEventStatus &&
                                                                props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'PENDING' ? '#F8EA8C'
                                                                : categorySelectFlag ? '#F8EA8C' : '',
                                                        boxShadow: '0 0 0 0', marginTop: '0'
                                                    }}>
                                                        {/* Green
                                                    <div className='event-details-category-card' 
                                                    style={{
                                                        background: '#B1D8B7', boxShadow: '0 0 0 0', marginTop: '0'
                                                    }}> */}
                                                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                                            <div className='doubles-click-div'
                                                                onClick={showExtendeddiv}
                                                            ></div>
                                                            <div className='event-details-category-card-icon'>
                                                                <div className='boys-doubles' style={{ background: '#fff' }}>
                                                                    <span className="material-symbols-outlined">
                                                                        man
                                                                    </span>
                                                                    <span className="material-symbols-outlined">
                                                                        man
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='event-details-category-card-details'>
                                                                <h1 style={{ fontSize: '1.2rem' }}>{props.eventsCategory.CategoryName}</h1>
                                                                <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                                    <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                                        <span>{props.eventsCategory.Fees}</span>
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    props.eventsCategory.Gender.toUpperCase() === 'FEMALE' && props.eventsCategory.EventType.toUpperCase() === 'TEAM' ?
                                                        <div className='event-details-category-card'
                                                            style={{

                                                                background: categorySelectFlag && props && props.registeredEventStatus &&
                                                                    props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' ? '#B1D8B7' :
                                                                    categorySelectFlag && props && props.registeredEventStatus &&
                                                                        props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'PENDING' ? '#F8EA8C'
                                                                        : categorySelectFlag ? '#F8EA8C' : '',
                                                                boxShadow: '0 0 0 0', marginTop: '0'
                                                            }}>
                                                            <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    width: 'calc(100% - 20px)',
                                                                    height: '100%'
                                                                }}
                                                                    onClick={showExtendeddiv}
                                                                ></div>
                                                                <div className='event-details-category-card-icon'>

                                                                    <div className='girls-team'>
                                                                        <span className="material-symbols-outlined">
                                                                            woman
                                                                        </span>
                                                                        <span className="material-symbols-outlined">
                                                                            woman
                                                                        </span>
                                                                        <span className="material-symbols-outlined">
                                                                            woman
                                                                        </span>
                                                                        <span className="material-symbols-outlined">
                                                                            woman
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='event-details-category-card-details'>
                                                                    <h1 style={{ fontSize: '1.2rem' }}>{props.eventsCategory.CategoryName}</h1>
                                                                    <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                                        <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                                            <span>{props.eventsCategory.Fees}</span>
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        props.eventsCategory.Gender.toUpperCase() === 'MALE' && props.eventsCategory.EventType.toUpperCase() === 'TEAM' ?
                                                            <div className='event-details-category-card'
                                                                style={{

                                                                    background: categorySelectFlag && props && props.registeredEventStatus &&
                                                                        props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' ? '#B1D8B7' :
                                                                        categorySelectFlag && props && props.registeredEventStatus &&
                                                                            props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'PENDING' ? '#F8EA8C' :
                                                                            categorySelectFlag ? '#F8EA8C' : '',
                                                                    boxShadow: '0 0 0 0', marginTop: '0'
                                                                }}>
                                                                <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        width: 'calc(100% - 20px)',
                                                                        height: '100%'
                                                                    }}
                                                                        onClick={showExtendeddiv}
                                                                    ></div>
                                                                    <div className='event-details-category-card-icon'>

                                                                        <div className='boys-team'>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='event-details-category-card-details'>
                                                                        <h1 style={{ fontSize: '1.2rem' }}>{props.eventsCategory.CategoryName}</h1>
                                                                        <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                                            <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                                                <span>{props.eventsCategory.Fees}</span>
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <div className='event-details-category-card'
                                                                style={{

                                                                    background: categorySelectFlag && props && props.registeredEventStatus &&
                                                                        props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'COMPLETED' ? '#B1D8B7' :
                                                                        categorySelectFlag && props && props.registeredEventStatus &&
                                                                            props.registeredEventStatus.PaymentStatus && props.registeredEventStatus.PaymentStatus.toUpperCase() === 'PENDING' ? '#F8EA8C'
                                                                            : categorySelectFlag ? '#F8EA8C' : '',
                                                                    boxShadow: '0 0 0 0', marginTop: '0'
                                                                }}>
                                                                <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        width: 'calc(100% - 20px)',
                                                                        height: '100%'
                                                                    }}
                                                                        onClick={showExtendeddiv}
                                                                    ></div>
                                                                    <div className='event-details-category-card-icon'>

                                                                        <div className='mixed-team'>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                man
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                            <span className="material-symbols-outlined">
                                                                                woman
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='event-details-category-card-details'>
                                                                        <h1 style={{ fontSize: '1.2rem' }}>{props.eventsCategory.CategoryName}</h1>
                                                                        <div className='type' style={{ justifyContent: 'space-between', padding: '0 5px 0 0' }}>
                                                                            <h2 style={{ position: 'relative', top: '5px', fontSize: '1.2rem' }}><span>₹ </span>
                                                                                <span>{props.eventsCategory.Fees}</span>
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                }

                                {/* {true && <h3><strong>Partner : </strong>
                                <span id={'spanPartnerName' + props.keyValue}>Aditya Tripathi</span>
                            </h3>} */}
                                {partnerObject.partnerName && partnerObject.partnerName !== "" && <h3><strong>Partner : </strong>
                                    <span id={'spanPartnerName' + props.keyValue}>{partnerObject.partnerName}{props.registeredEventStatus && props.registeredEventStatus.RegType === 'Partner' ? '*' : ''}</span>
                                </h3>}

                            </div>
                        </div>
                        {props.eventsCategory.EventType.toUpperCase() === 'DOUBLE' &&
                            <div className={partnerFlag ? "category-doubles-partner-div show" : "category-doubles-partner-div"}>
                                <hr style={{ margin: '0' }} />
                                <div style={{ position: 'relative', padding: '0 10px' }}>
                                    <div className="reg-participant-form-field" onClick={selectPartner}>
                                        <input id={'txtPartnerID' + props.keyValue}
                                            type="text" required onBlur={CheckPartnerCode}
                                            onChange={(e) => {
                                                // setPartnerID(e.target.value);
                                                setPartnerObject({
                                                    partnerID: e.target.value,
                                                    partnerName: e.target.value === partnerObject.partnerID ? partnerObject.partnerName : ''
                                                })
                                            }} value={partnerObject.partnerName} />
                                        <span>Partner Name</span>
                                        <span className="material-symbols-outlined dont-change" >
                                            edit
                                        </span>
                                    </div>
                                    {errorObj.showError && <div className="partner-error-message">
                                        {/* {console.log(errorObj.alreadyRegistered)} */}
                                        {!showError && !errorObj.alreadyRegistered && <h1>Given ID is not valid. Give another player ID</h1>}
                                        {!showError && errorObj.alreadyRegistered && <h1>Given ID is already Registered. Give another player ID</h1>}
                                        {showError && <h1>Please select Partner</h1>}


                                    </div>}
                                    <div className="cancel-partner"
                                        onClick={closePartner}>
                                        <button type="button" className="mybutton button5">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        }

                        <div
                            // style={{ background: 'red' }}
                            // onClick={testFunction}
                            onClick={registerCategory}
                            className="select-category-div">
                            <h1>
                                <span className="material-symbols-outlined add-to-cart">
                                    check
                                </span>
                                <span className="material-symbols-outlined remove-from-cart">
                                    close
                                </span>
                                {/* <small className='add-to-cart'>Add To</small>
                                <small className='remove-from-cart'>Remove From</small> Cart</h1> */}
                                <span className="material-symbols-outlined add-to-cart">
                                    add_shopping_cart
                                </span>
                                <span className="material-symbols-outlined remove-from-cart">
                                    remove_shopping_cart
                                </span>
                            </h1>
                        </div>

                        {props.registeredEventStatus && props.registeredEventStatus.RegType === 'Partner' && <span style={{
                            position: 'absolute',
                            bottom: '1px',
                            fontSize: '0.8rem',
                            padding: '0 10px',
                        }}>Registered by Partner</span>}
                    </div>
                </div>
                {/* {props.registeredEventStatus.RegType === 'Partner' && <span>Registered by Partner</span>} */}
            </div >

        </>
    )
}
