import React, { useEffect, useState } from 'react'
import Loading from './Loading'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import EventCardSmall from './EventCardSmall.js';
import { useCollection } from '../hooks/useCollection.js';
import { where, orderBy, limit } from 'firebase/firestore';
import { useUserAuth } from '../context/UserAuthcontext';
import { useGetOrder } from '../hooks/useGetOrder.js';
import '../css/BannerItemHP.css'
export default function HomePage(props) {
    const { user } = useUserAuth();

    const [city, setCity] = useState(window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
        'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', ''));
    const [loading, setLoading] = useState(true);
    const [eventList, setEventList] = useState([]);
    const [eventCount, setEventCount] = useState([]);
    const [eventIDs, setEventIDs] = useState([])
    const [playerIDs, setPlayerIDs] = useState([])
    const [upcomingEvent, setUpcomingEvent] = useState(0)
    const [showPopup, setShowPopup] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const emptyQuery = []
    const queryEvent = [
        where('EventStatus', '==', 'Active'),
        orderBy('EventStartDate', 'desc'),
        // limit(30)
    ]
    const queryUser = [
        where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ' '),

    ]
    // console.log('queryEvent', queryEvent)
    const { documents: eventEntrySummaryDocs, error: eventEntrySummaryDocError } = useCollection('EventAllEntryLog', emptyQuery)
    const { documents: eventDocs, error: eventDocError } = useCollection('EventList', queryEvent)
    const { documents: playerDocs, error: PlayerDocError } = useCollection('Participants', queryUser)
    const { orders, orderCnt, isCompleted } = useGetOrder();
    const [bannerSlider, setbannerSlider] = useState({
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 3,
            },
        },
    });


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])


    useEffect(() => {
        let playerList = [];
        playerDocs.forEach(player => {
            player.PlayerID && playerList.push(player.PlayerID)

        });
        setPlayerIDs(playerList)
    }, [playerDocs])
    useEffect(() => {
        let eventIds = []
        let _upcomingCnt = 0
        console.log('orders', orders)
        eventDocs.forEach(element => {
            if (element.EventStatus === 'Active') {
                eventIds.push(element.id)
                if (orders.find(e => e.EventID === element.id)) {
                    _upcomingCnt = _upcomingCnt + 1
                }
            }
        });
        setUpcomingEvent(_upcomingCnt)

        setEventIDs(eventIds)
    }, [eventDocs, orders])

    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    var options = {
        year: '2-digit',
        // year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    let eventCntList = [];

    useEffect(() => {
        setLoading(true);
        let dataCnt = eventEntrySummaryDocs;
        console.log('dataCnt : ', dataCnt);
        dataCnt.forEach(elementCnt => {
            let index = eventCntList.findIndex(e => e.EventID === elementCnt.EventID);
            if (index >= 0) {
                eventCntList[index].EntryCount = Number(eventCntList[index].EntryCount) + Number(elementCnt.EntryCount);
                eventCntList[index].CompletedCount = Number(eventCntList[index].CompletedCount) + Number(elementCnt.CompletedCount);

            } else {
                eventCntList.push({
                    EventID: elementCnt.EventID,
                    EntryCount: Number(elementCnt.EntryCount),
                    CompletedCount: Number(elementCnt.CompletedCount),
                })
            }

        });
        setEventCount(eventCntList)
        //const sanitizedMessage = data.text;
        setLoading(false);



    }, [eventEntrySummaryDocs]);


    useEffect(() => {
        setCity(window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', '') === 'All - Location' ?
            'All City' : window.localStorage.getItem("userLocation") && window.localStorage.getItem("userLocation").replaceAll('"', ''));

        setLoading(true);
        let data = city && city.toLowerCase().trim() === 'all city' ? eventDocs :
            eventDocs.filter(e => e.City.toLowerCase() === city.toLowerCase().trim());

        var refdate = "";
        var today = new Date();
        var eventSDate = '';
        var eventEDate = '';
        data.forEach(element => {

            let defaultLOGO = ''
            switch (element.SportName.toUpperCase()) {
                case 'BADMINTON':
                    defaultLOGO = '/img/game/badminton.webp'
                    break;
                case 'CARROM':
                    defaultLOGO = '/img/game/carrom.webp'
                    break;
                case 'CHESS':
                    defaultLOGO = '/img/game/chess.webp'
                    break;
                case 'SQUASH':
                    defaultLOGO = '/img/game/squash.webp'
                    break;
                case 'TABLE TENNIS':
                    defaultLOGO = '/img/game/tabletennis.webp'
                    break;
                case 'TENNIS':
                    defaultLOGO = '/img/game/tennis.webp'
                    break;
                case 'CRICKET':
                    defaultLOGO = '/img/game/CK.png'
                    break;
                default:
                    defaultLOGO = '/img/game/badminton.webp'

            };
            let logoURL = !element.EventLogo ? defaultLOGO : Array.isArray(element.EventLogo) && element.EventLogo[0] && element.EventLogo[0] !== '' ? element.EventLogo[0] :
                Array.isArray(element.EventLogo) && !element.EventLogo[0] ? defaultLOGO :
                    Array.isArray(element.EventLogo) && element.EventLogo[0] && element.EventLogo[0] == '' ? defaultLOGO :
                        element.EventLogo !== '' ? element.EventLogo : defaultLOGO;
            element.logoURL = logoURL;
            if (element.EventStartDate) {
                refdate = new Date(element.EventStartDate._seconds * 1000);
                // element.EventStartDate = refdate.toLocaleDateString("en-IN", options);
                eventSDate = refdate.toLocaleDateString("en-IN", options);
            }
            else {
                eventSDate = "";
            }
            var eDate = new Date(element.EventEndDate._seconds * 1000 + 60 * 60 * 24 * 1000);
            // element.EventEndDate = eDate.toLocaleDateString("en-IN", options);
            eventEDate = eDate.toLocaleDateString("en-IN", options);
            if (refdate <= today && eDate >= today && element.EventStatus.toUpperCase() === 'ACTIVE') {
                element.isLive = true;
            } else {
                element.isLive = false;
            }

            // element.MinimumFee = element.MinimumFee ? (Number(element.MinimumFee).toLocaleString('en-IN', curFormat)) : "";
            element.Fees = element.MinimumFee ? (Number(element.MinimumFee).toLocaleString('en-IN', curFormat)) : "";

            element.EventEDate = eventEDate;

            element.EventSDate = eventSDate;

        });
        setEventList(data);
        //const sanitizedMessage = data.text;
        setLoading(false);

        // setItemsExists(true);


    }, [city, eventDocs, window.localStorage.getItem("userLocation")]);

    let index = -1;
    console.log('2', playerIDs, eventIDs)
    return (
        <div>
            <div className="" id="fullContent">

                <div className={showPopup && user && user.isLoggedIn && upcomingEvent > 0 ? 'pop-up-div open' : 'pop-up-div'}>
                    {<div className='pop-up-div-inner'>
                        <br />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h1 style={{ width: '90%', padding: '10px', background: '#efefef', borderRadius: '8px', fontWeight: '300' }}>
                                You have <strong>{upcomingEvent}</strong> upcoming events</h1><br />
                            <img src="/img/date_picker.svg" width="60%" alt="date picker" /><br />
                        </div>

                        <div className='btn-div' style={{ alignContent: 'center' }}>
                            <button style={{ background: '#aaa' }} className='mybbutton button5' onClick={() => {
                                setShowPopup(false)
                            }}>
                                <span>Cancel</span>
                            </button>

                            <button className='mybbutton button5' onClick={() => {
                                setShowPopup(false)
                                navigate('/OrderHistory');
                            }} >
                                <span>My Events</span>
                            </button>

                        </div>


                    </div>}

                </div>

                <div className="city-select-div" style={{ display: 'none' }}>
                    <div className="search">
                        {/* <input type="text" onChange={callOnChange()} placeholder="Enter city" name="" value="" /> */}
                        {/* <input type="text" onChange={this.callOnChange()} placeholder="Enter city" name="" value="" /> */}

                        <span className="material-symbols-outlined">
                            search
                        </span>
                    </div>

                    <div className="cities">
                        <a href="/">Banglore</a>
                        <a href="/">Pune</a>
                        <a href="/">Mumbai</a>
                        <a href="/">Delhi</a>
                        <a href="/">Kolkata</a>
                        <hr />
                        <a href="/">Agra</a>
                        <a href="/">Ahemdabad</a>
                        <a href="/">Ambala</a>
                    </div>

                </div>
                {loading && <Loading />}
                <section style={{ background: 'linear-Gradient(#333C5D 95%,rgba(0,0,0,0))' }}>
                    <div style={{
                        background: '#348DCB',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        marginBottom: '10px',
                    }}>
                        <span style={{ color: '#fff', }} className="material-symbols-outlined">
                            campaign
                        </span>
                        <h1 style={{
                            color: '#fff',
                            fontSize: '1rem',
                            fontWeight: 'normal',
                            margin: '0',
                            paddingLeft: '10px'
                        }}>
                            Notification : New event in your city <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>CHECK IT OUT NOW</span>
                        </h1>
                    </div>
                    <br />
                    <div style={{ position: 'relative', top: '-20px' }}>

                        {eventList.length > 0 && <OwlCarousel
                            // {itemsExists && <OwlCarousel
                            className="owl-theme"
                            nav={false}
                            autoplay
                            smartSpeed={3000}
                            autoplayTimeout={15000}
                            autoplayHoverPause={false}
                            dots={true}
                            loop={true}
                            center={true}
                            responsiveRefreshRate={200}
                            stagePadding={30}
                            margin={10}
                            responsive={bannerSlider.responsive}
                            navText={[
                                '<div className="full-nav-arrow left"><div><span class="material-symbols-outlined">arrow_back</span></div></div>',
                                '<div className="full-nav-arrow right"><div><span class="material-symbols-outlined">arrow_forward</span></div></div>'
                            ]}
                        >
                            {/* {console.log('eventList', eventList)} */}
                            {eventList && eventList.map((events) => {
                                // console.log('events.id 1', events.id, 'logoURL : ', events.logoURL)
                                return <Link className="item" style={{ marginTop: '0' }}
                                    key={events.id}
                                    to={"/EventDetails/" + events.id} >
                                    <div className='hompage-banner-card'>
                                        <img src={events.logoURL} alt="" />
                                    </div>
                                </Link>
                            })}


                        </OwlCarousel>
                        }
                    </div>
                </section>
            </div >
            <br />
            {user && user.isLoggedIn && orders && orders.length > 0 && <div className='container'>
                <div className='notification-upcoming-event' style={{ cursor: 'pointer' }}>
                    <div className='notification-upcoming-event-inner' onClick={() => {
                        navigate("/Orders", { state: { 'orderList': orders } });

                    }}>
                        <div>
                            <h1>My Orders</h1>
                        </div>
                        <div>
                            <h2>{orderCnt}</h2>
                        </div>
                        <div>
                            <span className="material-symbols-outlined">
                                arrow_forward_ios
                            </span>
                        </div>
                    </div>

                </div>
            </div>}

            <div>
                <br />
                <div className='container'>
                    <div className='row no-gutters'>
                        {eventList.map((events) => {

                            return <EventCardSmall key={events.id} eventDetails={events}></EventCardSmall>
                        })}
                        {/* <EventCardSmall ></EventCardSmall> */}
                    </div>
                </div>
            </div>

            <br />

        </div >
    )
}
