import React, { useEffect } from 'react'
import { useState } from 'react'
import DatePicker from "react-datepicker";
import '../css/MydatePicker.css'
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js'

export default function EditEventCategory(props) {
    const [showAddFlag, setShowAddFlag] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [categoryMessage, setCategoryMessage] = useState('');
    const [categoryEdit, setCategoryEdit] = useState(null);

    const [category, setCategory] = useState({
        CategoryName: '',
        Gender: 'MALE',
        EventType: 'SINGLE',
        DateRefType: 'After',
        ReferenceDate: new Date(),
        MaxTeamSize: 1,
        Fees: 0
    })
    useEffect(() => {
        let para1 = {
            EventID: props.eventDetails.Eventid,
        };
        console.log('Para1 : ', para1)
        const ret = httpsCallable(functions, "getEventCategoryDetails");
        ret(para1).then((result) => {
            console.log('result', result)

            setCategoryList(result.data);
        })


    }, [])
    // function addCategory() {
    //     let catDetails = props.eventDetails.CategoryDetails ? props.eventDetails.CategoryDetails : [];
    //     if (!catDetails.find(e => e.CategoryName === category.CategoryName)) {
    //         catDetails.push(category);
    //         console.log(catDetails)
    //         props.setEventDetails({
    //             ...props.eventDetails,
    //             CategoryDetails: catDetails
    //         })
    //     }

    //     console.log(props.eventDetails)
    //     setShowAddFlag(false);
    // }

    async function deleteCategory(id) {
        var para1 = {
            EventID: props.eventDetails.Eventid,
            id: id
        };
        // setLoading(true);

        console.log(para1);

        const ret = await httpsCallable(functions, "deleteTournamentCategory");
        ret(para1).then(async (result) => {
            let categoryListObject = categoryList
            let index = categoryListObject.findIndex(e => e.id === id);
            categoryListObject.splice(index, 1)
            setCategoryList(categoryListObject)
            // setLoading(false);

        })
    }
    // function deleteCategory(cat) {
    //     console.log('cat delete', cat);
    //     let index = props.eventDetails.CategoryDetails.findIndex(e => e.CategoryName === cat.CategoryName)
    //     console.log('index', index);
    //     let catList = props.eventDetails.CategoryDetails;
    //     catList.splice(index, 1);
    //     props.setEventDetails({
    //         ...props.eventDetails,
    //         CategoryDetails: catList
    //     })
    //     //catDetails.splice(index, 1);
    //     console.log('props.eventDetails', props.eventDetails);
    // }
    async function SaveCategory() {
        var para1 = {};
        var catList = props.eventDetails.CategoryDetails;
        catList.forEach(element => {
            var refsdate = new Date(element.ReferenceDate && element.ReferenceDate._seconds ? element.ReferenceDate._seconds * 1000 : element.ReferenceDate);

            if (refsdate.toString() === 'Invalid Date') {
                refsdate = new Date(element.ReferenceDate && element.ReferenceDate._seconds ? element.ReferenceDate._seconds * 1000 : element.ReferenceDate);
            }
            element.ReferenceDate = refsdate;
        });

        para1 = {
            EventID: props.eventDetails.Eventid,
            CategoryDetails: catList,
        };
        const ret = await httpsCallable(functions, "setEventCategoryDetails");
        ret(para1).then(async (result) => {
            setResultMessage('Announcement Details Saved Successfully !!')

            setTimeout(function () {
                setResultMessage('');
            }, 5000);

        })
    }


    async function addCategoryDetails(cat) {
        console.log('in addCategoryDetails', cat)
        if (!cat || (cat && cat.CategoryName === '') ||
            (cat && cat.ReferenceDate === '') ||
            (cat && cat.Fees === '')) {
            setCategoryMessage('Enter all details!!')
            // setShowError(true);
            setTimeout(function () {
                setCategoryMessage('')

            }, 5000);
            return
        } else if (categoryList.findIndex(e => e.CategoryName.toUpperCase() === cat.CategoryName.toUpperCase()) >= 0) {
            setCategoryMessage('Category already exists!!')
            // setShowError(true);
            setTimeout(function () {
                setCategoryMessage('')

            }, 5000);
            return
        }
        else {
            ////////////////////
            var para1 = {
                EventID: props.eventDetails.Eventid,
                CategoryName: cat.CategoryName,
                EventType: cat.EventType,
                DateRefType: cat.DateRefType,
                Fees: cat.Fees,
                Gender: cat.Gender,
                MaxTeamSize: cat.MaxTeamSize,
                ReferenceDate: new Date(cat.ReferenceDate && cat.ReferenceDate._seconds ? cat.ReferenceDate._seconds * 1000 : cat.ReferenceDate),
                IsActive: cat.IsActive,
                id: cat.id ? cat.id : ''
            };

            console.log(para1);
            let category = null;
            let catList = categoryList;

            category = {
                EventID: props.eventDetails.Eventid,
                CategoryName: cat.CategoryName,
                EventType: cat.EventType,
                DateRefType: cat.DateRefType,
                Fees: cat.Fees,
                Gender: cat.Gender,
                MaxTeamSize: cat.MaxTeamSize,
                IsActive: cat.IsActive,
                ReferenceDate: (new Date(cat.ReferenceDate && cat.ReferenceDate._seconds ? cat.ReferenceDate._seconds * 1000 : cat.ReferenceDate)),
                id: ''
            }
            console.log('category', category)
            console.log('catList', catList)



            const ret = await httpsCallable(functions, "updateTournamentCategory");
            ret(para1).then(async (result) => {
                // console.log(result.data);

                if (result.data === cat.id) {
                    let index = catList.findIndex(e => e.id === cat.id);
                    catList.splice(index, 1)
                }
                category = {
                    EventID: props.eventDetails.Eventid,
                    CategoryName: cat.CategoryName,
                    EventType: cat.EventType,
                    DateRefType: cat.DateRefType,
                    Fees: cat.Fees,
                    Gender: cat.Gender,
                    MaxTeamSize: cat.MaxTeamSize,
                    ReferenceDate: (new Date(cat.ReferenceDate && cat.ReferenceDate._seconds ? cat.ReferenceDate._seconds * 2000 : cat.ReferenceDate)),
                    IsActive: cat.IsActive,
                    id: result.data
                }

                setCategory(null);
                setCategoryEdit(null)
                catList.push(category)
                setCategoryList(catList)
                setShowAddFlag(false)
            })
        }
    }
    let catIndex = -1;
    return (
        <div className="update-event-form" id="EventCategoryForm4">

            <div className="event-detail-input-div">
                <h1>CATEGORY & FEES</h1><br />
            </div>

            <div className="category-save-div">
                <button type="button" style={{ width: '100px' }} onClick={SaveCategory}
                    className="mybutton button5">SAVE</button>

                <span id="errorMessage" style={{ display: 'none' }}>Category Saved Successfully!!</span>


            </div>
            <br />

            <div className="categoryAndFees-category-section">
                <input type="hidden" id="hfEventID" />
                <div className="row no-gutters">

                    <div className="col-lg-4 col-md-6 col-sm-12" onClick={() => {
                        setShowAddFlag(true)
                        // setCategory({});
                    }}>
                        <div className="category-card add-category">
                            <span className="material-symbols-outlined">
                                add
                            </span>
                            <h1>ADD CATEGORY</h1>
                        </div><br />


                    </div>

                    {showAddFlag && <div id="divAddCategoryAdd" className="col-lg-4 col-md-6 col-sm-12">

                        <div className="category-card" style={{ position: 'relative' }} >
                            <input type="text" placeholder="Category Name"
                                className="category-name" onChange={(e) => {
                                    setCategory({
                                        ...category,
                                        id: '',
                                        CategoryName: e.target.value
                                    })
                                }} value={category && category.CategoryName} />
                            <div className="gender-event-div">
                                <div>
                                    <small style={{ display: 'none' }}>MALE</small>
                                    <select name="" id="genderAdd" value={category && category.Gender}
                                        onChange={(e) => {
                                            setCategory({
                                                ...category,
                                                Gender: e.target.value
                                            })
                                        }}>
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="MIXED">MIXED</option>
                                    </select>
                                </div>
                                <span>|</span>
                                <div>
                                    <small style={{ display: 'none' }}>{category && category.EventType}</small>
                                    <select name="" id="catTypeAdd" value={category && category.EventType}
                                        onChange={(e) => {
                                            // console.log('e.target.value', e.target.value, 'category', category);
                                            setCategory({
                                                ...category,
                                                EventType: e.target.value,
                                                MaxTeamSize: e.target.value === 'SINGLE' ? 1 : e.target.value === 'DOUBLE' ? 2 : -1
                                            })
                                        }}>
                                        <option value="SINGLE">SINGLE</option>
                                        <option value="DOUBLE">DOUBLE</option>
                                        <option value="TEAM">TEAM</option>
                                    </select>
                                </div>
                            </div>
                            <div className="born-before-div">
                                <h3 onClick={() => {
                                    setCategory({
                                        ...category,
                                        DateRefType: category.DateRef === 'Before' ? 'After' : 'Before'
                                    })
                                    // console.log('Category', category);
                                }}>
                                    <span>Born</span>
                                    <div id="dateRefFlagadd" className={category && category.DateRefType && category.DateRefType.toLowerCase()}>
                                        <span> Before </span>
                                        <span className="material-symbols-outlined">
                                            keyboard_arrow_down
                                        </span>
                                        <span> After </span>
                                    </div>
                                    <span>:</span>
                                </h3>
                                <h4 style={{ display: 'none', width: '100%' }}>1 Jan 2007</h4>
                                <DatePicker
                                    showMonthDropdown
                                    showYearDropdown
                                    dateFormat="dd-MMM-yyyy"

                                    selected={category && category.ReferenceDate ? new Date(category.ReferenceDate && category.ReferenceDate._seconds ? category.ReferenceDate._seconds * 1000 : category.ReferenceDate) : new Date()} onChange={(date) => {
                                        // console.log(date)
                                        setCategory({
                                            ...category,
                                            ReferenceDate: new Date(date)
                                        })
                                    }} />
                            </div>
                            <div className="born-before-div">
                                <h3>Fees : </h3>
                                <input type="text" id="feeAdd" value={"₹" + (category && category.Fees ? category.Fees : 0)} onChange={(e) => {
                                    setCategory({
                                        ...category,
                                        Fees: e.target.value.replace("₹", '')
                                    })
                                }} />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h5 style={{ margin: '0', fontSize: '1rem' }}>Active</h5>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                                        <span className={category.IsActive === true ? 'active' : ''}
                                            // <span className='active'
                                            id="RegistrationOn" style={{ color: '#59981A' }}>Yes</span>

                                        <div className={category && category.IsActive === true ? 'switch-button on' : 'switch-button off'} id="RegistrationToggleBtn"
                                            // <div className='switch-button on' id="RegistrationToggleBtn"

                                            onClick={(e) => {
                                                console.log(e.target)
                                                setCategory({
                                                    ...category,
                                                    IsActive: category && category.IsActive === true ? false : true
                                                })
                                                // saveRegistrationFlag(props.eventDetails.RegistrationOpenFlag === 'ON' ? 'OFF' : 'ON')
                                            }}>

                                            <div className="switch-button-inner"></div>
                                        </div>

                                        <span className={category && category.IsActive === false ? '' : 'active'} id="RegistrationOff"
                                            // <span className='' id="RegistrationOff"
                                            style={{ color: '#EB542E' }}>No</span>
                                    </div>
                                </div>
                            </div>

                            <div className="category-card-edit" onClick={(e) => { addCategoryDetails(category) }}>
                                <span id="errorMessage" className="errorMessage" >{categoryMessage}</span>


                                <div style={{ width: '100%', cursor: 'pointer' }} onClick={(e) => { addCategoryDetails(category) }}>
                                    <span className="material-symbols-outlined">
                                        edit_square
                                    </span>
                                    <small>ADD</small>
                                </div>
                            </div>
                        </div>
                        <br />

                    </div>}

                    <div className="row no-gutters" >
                        {/* {console.log('CategoryDetails', props.eventDetails.CategoryDetails)} */}
                        {categoryList && categoryList.length > 0 && categoryList.map((catObj) => {
                            catIndex = catIndex + 1;
                            return <div key={catObj.id} className="col-lg-4 col-md-6 col-sm-12">

                                <div className="category-card" style={{ position: 'relative' }} >
                                    <input type="text"
                                        className="category-name"
                                        // onChange={(e) => {
                                        //     setCategory({
                                        //         ...category,
                                        //         CategoryName: e.target.value
                                        //     })
                                        // }} 
                                        value={catObj.CategoryName} readOnly={true} />
                                    <div className="gender-event-div">
                                        <div>
                                            <small>{catObj.Gender}</small>

                                        </div>
                                        <span>|</span>
                                        <div>
                                            <small >{catObj.EventType}</small>

                                        </div>
                                    </div>
                                    <div className="born-before-div">
                                        <h3>
                                            <span>Born</span>
                                            <div className={catObj && catObj.DateRefType && catObj.DateRefType.toLowerCase()}>
                                                <span> Before </span>
                                                <span className="material-symbols-outlined">
                                                    keyboard_arrow_down
                                                </span>
                                                <span> After </span>
                                            </div>
                                            <span>:</span>
                                        </h3>
                                        <DatePicker
                                            showMonthDropdown
                                            showYearDropdown
                                            dateFormat="dd-MMM-yyyy"

                                            readOnly={true} selected={catObj.ReferenceDate && catObj.ReferenceDate._seconds ? catObj.ReferenceDate._seconds * 1000 : catObj.ReferenceDate}
                                        //  onChange={(date) => {
                                        //     // console.log(date)
                                        //     setCategory({
                                        //         ...category,
                                        //         ReferenceDate: new Date(date)
                                        //     })
                                        // }}
                                        />
                                    </div>
                                    <div className="born-before-div">
                                        <h3>Fees : </h3>
                                        <input type="text" value={"₹" + catObj.Fees}
                                            readOnly={true} />
                                    </div>


                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h5 style={{ margin: '0', fontSize: '1rem' }}>Active</h5>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div className="switch" style={{ height: 'calc(100% - 10px)' }}>
                                                <span className={catObj.IsActive === true ? 'active' : ''}
                                                    // <span className='active'
                                                    id="RegistrationOn" style={{ color: '#59981A' }}>Yes</span>

                                                <div className={catObj.IsActive === true ? 'switch-button on' : 'switch-button off'} id="RegistrationToggleBtn"
                                                // <div className='switch-button on' id="RegistrationToggleBtn"

                                                // onClick={() => {
                                                //     console.log('catIndex', catIndex);
                                                //     console.log('cat.IsActive : ', cat.IsActive)

                                                //     let setCat = categoryList.find(e => e.CategoryName === cat.CategoryName);
                                                //     // lCategory = cat;
                                                //     console.log('setCat', setCat);
                                                //     // setCategoryList(lCategory);
                                                //     setRefreshFlag(!refreshFlag)
                                                //     // saveRegistrationFlag(props.eventDetails.RegistrationOpenFlag === 'ON' ? 'OFF' : 'ON')
                                                // }}
                                                >

                                                    <div className="switch-button-inner"></div>
                                                </div>

                                                <span className={catObj.IsActive === false ? '' : 'active'} id="RegistrationOff"
                                                    // <span className='' id="RegistrationOff"
                                                    style={{ color: '#EB542E' }}>No</span>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="category-card-edit" onClick={() => {
                                        deleteCategory(catObj.id)
                                    }}>

                                        <div style={{ width: '100%', cursor: 'pointer', color: 'red' }}>
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                            <small>DELETE</small>
                                        </div>

                                    </div>

                                </div>
                                <br />

                            </div>

                        })}
                        <div className="col-lg-12 col-md-12 col-sm-12"><br />
                            <div className="event-details-save-btn-div">
                                {resultMessage}
                            </div><br />

                        </div>



                    </div>


                </div>

            </div>
        </div >

    )
}
