import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Notification.css';
import DatePicker from "react-datepicker";
import '../css/MydatePicker.css'
import "react-datepicker/dist/react-datepicker.css";
import { useCollection } from '../hooks/useCollection.js';
import { orderBy, where } from 'firebase/firestore';
import SearchComponents from './SearchComponents.js';
import { useUserAuth } from '../context/UserAuthcontext';
import useDocumentUpdate from '../hooks/useDocumentUpdate.js';

export default function Notification() {
    const { user } = useUserAuth();

    const [showPopup, setShowPopup] = useState(false)
    // const [noticeType, setNoticeType] = useState('Global')
    const [filter, setFilter] = useState('Visible to All')
    const [userType, setUserType] = useState('User')
    const [dropDownList, setDropDownList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [noticeObject, setNoticeObject] = useState({
        EventID: '',
        UserID: '',
        City: '',
        id: '',
        NoticeHeader: '',
        NoticeText: '',
        ValidTill: new Date(),
        NoticeType: 'Global'
    })

    // const [eventID, setEventID] = useState('');
    // const [userID, setUserID] = useState('');

    // const [city, setCity] = useState('');

    // const [noticeID, setNoticeID] = useState('');
    // const [noticeHeader, setNoticeHeader] = useState('');
    // const [noticeText, setNoticeText] = useState('');
    // const [validTill, setValidTill] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState('');
    const [myNotice, setMyNotice] = useState([]);
    const [noticeForMe, setNoticeForMe] = useState([]);
    const { addDocument, deleteDocument, updateDocument } = useDocumentUpdate();

    const queryConstraints = [
        where('CreatedBy', '==', user && user.userInfo ? user.userInfo.uid : ' '),
        orderBy('EventName')
        // Add more query constraints as needed
    ];
    const queryConstraintsAll = [
        // orderBy('EventName')
        // Add more query constraints as needed
    ];
    const queryConstraintsEmpty = [
        // orderBy('EventName')
        // Add more query constraints as needed
    ];
    const queryConstraintsNotice = [
        // where('CreatedBy', '==', user && user.userInfo ? user.userInfo.uid : ' '),
        where('ValidTill', '>=', new Date()),

        // Add more query constraints as needed
    ];

    const queryConstraintsRegistration = [
        where('CreatedBy', '==', user && user.userInfo ? user.userInfo.uid : ' '),
        // Add more query constraints as needed
    ];
    // console.log('user : ', user)
    const { documents: eventDocs, error: eventDocError } = useCollection('EventList', user && user.userInfo
        && user.userInfo.SelectedRole && user.userInfo.SelectedRole.toUpperCase() === 'ADMIN' ? queryConstraintsAll : queryConstraints)
    // console.log('user : ', eventDocs, eventDocError)

    const { documents: userDocs, error: userDocError } = useCollection('UserList', queryConstraintsEmpty)
    const { documents: registrationDocs, error: registrationDocError } = useCollection('EventRegistrationDetails', queryConstraintsRegistration)
    const { documents: noticeDocs, error: noticeDocError } = useCollection('ApplicationNotification', queryConstraintsNotice)
    // console.log('noticeDocs', noticeDocs, noticeDocError, queryConstraintsNotice)
    useEffect(() => {
        let _myNotice = [];//noticeDocs.filter(e => e.CreatedBy === user.userInfo.uid)
        let _noticeForMe = [];
        let diffInMs = 0;
        let diffInDays = 0;

        noticeDocs.forEach(element => {
            diffInMs = new Date() - new Date(element.CreatedTimestamp.seconds * 1000)
            diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));
            if (element.CreatedBy === user.userInfo.uid) {
                _myNotice.push({
                    ...element,
                    DateDiff: diffInDays
                })
            }
            if (element.NoticeType === 'Global' && element.CreatedBy !== user.userInfo.uid) {
                _noticeForMe.push({
                    ...element,
                    DateDiff: diffInDays
                })
            }
            else if (registrationDocs.find(e => e.EventID === element.EventID && element.CreatedBy !== user.userInfo.uid)) {
                _noticeForMe.push({
                    ...element,
                    DateDiff: diffInDays
                })
            }
            else if (user.userInfo.City === element.City && element.CreatedBy !== user.userInfo.uid) {
                _noticeForMe.push({
                    ...element,
                    DateDiff: diffInDays
                })
            } else if (user.userInfo.uid === element.UserID && element.CreatedBy !== user.userInfo.uid) {
                _noticeForMe.push({
                    ...element,
                    DateDiff: diffInDays
                })
            }
        });
        setNoticeForMe(_noticeForMe);
        setMyNotice(_myNotice)


    }, [noticeDocs, user])

    useEffect(() => {
        if (user && user.userInfo) {
            if (user.userInfo.SelectedRole && user.userInfo.SelectedRole.toUpperCase() === 'ADMIN') {
                console.log('in admin')
                setUserType('Admin')
            } else if (eventDocs.length > 0) {
                console.log('in organizer')
                setUserType('Organizer')
            }
        }
        let objCityList = []
        let objEventList = [];
        eventDocs && eventDocs.forEach(element => {
            objEventList.push({
                ...element,
                //added for search components
                searchKey: element.EventName + element.OrganizationName + element.EventCode,
                displayText: element.EventName + "-" + element.OrganizationName,
            })

            if (!objCityList.find(e => e.City === element.City)) {
                objCityList.push({
                    'City': element.City,
                    searchKey: element.City,
                    displayText: element.City
                })
            }
        });
        setEventList(objEventList);
        setCityList(objCityList);

        let objUserList = [];
        userDocs && userDocs.forEach(element => {
            objUserList.push({
                ...element,
                //added for search components
                searchKey: element.UserName + element.Phone + element.City,
                displayText: element.UserName + (element.Phone ? " ( *****" + element.Phone.slice(9) : '') + ") - " + element.City,
            })
        });
        setUserList(objUserList)
    }, [user, eventDocs, userDocs])

    function setSearchClicked(flag) {

    }
    function setSelectedItemSearchComponentEvent(id, text) {
        console.log(id, text)
        setNoticeObject({
            ...noticeObject,
            EventID: id
        })
        // setEventID(id);
        // let idlist = id.split("#");
        // setSeededPlayer({
        //     ...seededPlayer,
        //     CategoryName: drawCategoryName,
        //     Seeded6: idlist[0]
        // })

    }
    function setSelectedItemSearchComponentUser(id, text) {
        console.log(id, text)
        // setUserID(id)
        setNoticeObject({
            ...noticeObject,
            UserID: id
        })
        // let idlist = id.split("#");
        // setSeededPlayer({
        //     ...seededPlayer,
        //     CategoryName: drawCategoryName,
        //     Seeded6: idlist[0]
        // })

    }
    function setSelectedItemSearchComponentCity(id, text) {
        console.log(id, text)
        // setCity(text)
        setNoticeObject({
            ...noticeObject,
            City: text
        })
        // let idlist = id.split("#");
        // setSeededPlayer({
        //     ...seededPlayer,
        //     CategoryName: drawCategoryName,
        //     Seeded6: idlist[0]
        // })

    }
    async function saveNotice() {
        let _message = '';
        if (noticeObject.NoticeType === 'User' && noticeObject.UserID === '') {
            _message = 'Please provide User for notification'
        } else if (noticeObject.NoticeType === 'City' && noticeObject.City === '') {
            _message = 'Please provide City for notification'
        } else if (noticeObject.NoticeType === 'Event' && noticeObject.EventID === '') {
            _message = 'Please provide Event for notification'
        }

        if (noticeObject.NoticeHeader === '') {
            if (_message === '') {
                _message = 'Please provide notice header'
            } else {
                _message = _message + ', notice header'
            }
        }
        if (noticeObject.NoticeText === '') {
            if (_message === '') {
                _message = 'Please provide notice details'
            } else {
                _message = _message + ', notice details'
            }
        }
        if (_message === '') {
            if (noticeObject.id === '') {
                let _addData = {
                    'NoticeType': noticeObject.NoticeType,
                    'City': noticeObject.City,
                    'EventID': noticeObject.EventID,
                    'UserID': noticeObject.UserID,
                    'NoticeHeader': noticeObject.NoticeHeader,
                    'NoticeText': noticeObject.NoticeText,
                    'ValidTill': noticeObject.ValidTill && noticeObject.ValidTill.seconds ? new Date(noticeObject.ValidTill.seconds * 1000) :
                        noticeObject.ValidTill ? new Date(noticeObject.ValidTill) : new Date(),
                    "CreatedBy": user && user.userInfo ? user.userInfo.uid : '',
                    "CreatedTimestamp": new Date(),
                    "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                    "UpdatedTimestamp": new Date(),
                }
                console.log('_addData', _addData)
                await addDocument('ApplicationNotification', _addData)

            } else {
                let _addData = {
                    'NoticeType': noticeObject.NoticeType,
                    'City': noticeObject.City,
                    'EventID': noticeObject.EventID,
                    'UserID': noticeObject.UserID,
                    'NoticeHeader': noticeObject.NoticeHeader,
                    'NoticeText': noticeObject.NoticeText,
                    'ValidTill': noticeObject.ValidTill && noticeObject.ValidTill.seconds ? new Date(noticeObject.ValidTill.seconds * 1000) :
                        noticeObject.ValidTill ? new Date(noticeObject.ValidTill) : new Date(),
                    "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                    "UpdatedTimestamp": new Date(),
                }
                console.log('_addData', _addData, noticeObject.id)
                await updateDocument('ApplicationNotification', noticeObject.id, _addData)

            }
            setNoticeObject({
                EventID: '',
                UserID: '',
                City: '',
                id: '',
                NoticeHeader: '',
                NoticeText: '',
                ValidTill: new Date(),
                NoticeType: 'Global'
            })
            setShowPopup(false)
        } else {
            setErrorMessage(_message)
            setTimeout(function () {
                setErrorMessage('');
            }, 5000);

        }
    }
    console.log('myNotice', myNotice)
    console.log('notice for Me', noticeForMe)
    return (
        <>

            <div className={showPopup ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner'>
                    <h1>Add/Edit Notification</h1>
                    <hr />

                    <div style={{ padding: '10px 30px' }}>
                        <div className="row no-gutters">

                            {userType === 'Admin' && <div className={userType === 'Admin' ? "col-3" : ''} style={{ padding: '0 3px' }}>

                                <input type="radio" className="checkbox" style={{ width: '0px' }}
                                    name="EventStatus" id="notificationOne"
                                    checked={noticeObject.NoticeType === 'Global' ? true : false} onChange={(e) => {
                                        // console.log(e.target.checked)
                                        if (e.target.checked) {
                                            setNoticeObject({
                                                ...noticeObject,
                                                NoticeType: 'Global'
                                            })

                                        }
                                    }} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label"
                                    htmlFor="notificationOne">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>Global </span>
                                </label>

                            </div>}

                            <div className={userType === 'Admin' ? "col-3" : "col-4"} style={{ padding: '0 3px' }}>
                                <input type="radio" className="checkbox" style={{ width: '0px' }}
                                    name="EventStatus" id="notificationTwo" checked={noticeObject.NoticeType === 'Event' ? true : false} onChange={(e) => {
                                        // console.log(e.target.checked)
                                        if (e.target.checked) {
                                            setNoticeObject({
                                                ...noticeObject,
                                                NoticeType: 'Event'
                                            })

                                        }
                                    }} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label"
                                    htmlFor="notificationTwo">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span>Event Notice</span>
                                </label>
                            </div>

                            <div className={userType === 'Admin' ? "col-3" : "col-4"} style={{ padding: '0 3px' }}>

                                <input type="radio" className="checkbox" style={{ width: '0px' }}
                                    name="EventStatus" id="notificationFour" checked={noticeObject.NoticeType === 'City' ? true : false} onChange={(e) => {
                                        // console.log(e.target.checked)
                                        if (e.target.checked) {
                                            setNoticeObject({
                                                ...noticeObject,
                                                NoticeType: 'City'
                                            })

                                        }
                                    }} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label"
                                    htmlFor="notificationFour">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span style={{ textAlign: 'center' }}>City Notice</span>
                                </label>

                            </div>
                            <div className={userType === 'Admin' ? "col-3" : "col-4"} style={{ padding: '0 3px' }}>

                                <input type="radio" className="checkbox" style={{ width: '0px' }}
                                    name="EventStatus" id="notificationThree" checked={noticeObject.NoticeType === 'User' ? true : false} onChange={(e) => {
                                        // console.log(e.target.checked)
                                        if (e.target.checked) {
                                            setNoticeObject({
                                                ...noticeObject,
                                                NoticeType: 'User'
                                            })
                                        }
                                    }} />
                                <label style={{ height: '40px', border: '1px solid #ddd' }}
                                    className="checkbox-label"
                                    htmlFor="notificationThree">
                                    <i className="fas fa-plus"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <i className="fas fa-check"
                                        style={{ paddingTop: '9px', paddingRight: '5px', fontSize: '0.6rem' }}></i>
                                    <span style={{ textAlign: 'center' }}>User Notice</span>
                                </label>

                            </div>


                        </div>
                        {/* {<><select className='notification-select' disabled={noticeType === 'Global' ? true : false} name="" id="">
                            <option value='Visible to All'>Visible to All</option>
                        </select>

                        </>} */}
                        <br />
                        {noticeObject.NoticeType === 'Event' && <><SearchComponents
                            ItemList={eventList}
                            width='100%'
                            placeholder={'Search event...'}
                            setSearchClicked={setSearchClicked}
                            setSelectedItem={setSelectedItemSearchComponentEvent}
                        ></SearchComponents>
                            <br />
                        </>}
                        {noticeObject.NoticeType === 'City' && <><SearchComponents
                            ItemList={cityList}
                            width='100%'
                            placeholder={'Search City...'}
                            setSearchClicked={setSearchClicked}
                            setSelectedItem={setSelectedItemSearchComponentCity}
                        ></SearchComponents>
                            <br />
                        </>}

                        {noticeObject.NoticeType === 'User' && <> <SearchComponents
                            ItemList={userList}
                            width='100%'
                            placeholder={'Search user...'}
                            setSearchClicked={setSearchClicked}
                            setSelectedItem={setSelectedItemSearchComponentUser}
                        ></SearchComponents>
                            <br />
                        </>}
                        <input type="text" placeholder='Notice header...'
                            value={noticeObject.NoticeHeader}
                            onChange={(e) => {
                                setNoticeObject({
                                    ...noticeObject,
                                    NoticeHeader: e.target.value
                                })
                                // setNoticeHeader(e.target.value)
                            }} className='notification-input' />
                        <textarea placeholder='Enter Details'
                            value={noticeObject.NoticeText}
                            onChange={(e) => {
                                setNoticeObject({
                                    ...noticeObject,
                                    NoticeText: e.target.value
                                })
                                // setNoticeText(e.target.value)
                            }}
                            className='notification-textarea'></textarea>
                        <div className='notification-date' style={{ position: 'relative' }}>
                            <DatePicker className='change-name'
                                showMonthDropdown
                                showYearDropdown
                                placeholderText='Valid till... '
                                // showMonthYearDropdown
                                // showFullMonthYearPicker
                                dateFormat="dd-MMM-yyyy"
                                selected={noticeObject.ValidTill && noticeObject.ValidTill.seconds ? new Date(noticeObject.ValidTill.seconds * 1000) :
                                    noticeObject.ValidTill ? new Date(noticeObject.ValidTill) : new Date()} onChange={(date) => {
                                        // setValidTill(new Date(date))
                                        setNoticeObject({
                                            ...noticeObject,
                                            ValidTill: new Date(date)
                                        })

                                    }}
                                minDate={new Date()}
                                maxDate={(new Date().setDate((new Date()).getDate() + 30))}
                            ></DatePicker>
                            {true && <span style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                fontSize: '1.2rem',
                                color: '#348DCB',
                                pointerEvents: 'none'
                            }} className="material-symbols-outlined">
                                calendar_month
                            </span>}
                        </div>
                    </div>

                    <hr style={{ marginBottom: '0' }} />
                    <div className='notification-input' style={{ position: 'relative' }}>

                        <span style={{ color: 'red', textAlign: 'center' }}>{errorMessage} </span>
                    </div>
                    <div className='btn-div' style={{ padding: '15px 20px 0 20px' }}>
                        <button className='mybutton button5' onClick={() => {
                            saveNotice()
                            // setShowPopup(false)
                        }} >
                            <span>Save</span>
                        </button>
                        <button className='mybutton button5' onClick={() => {
                            setShowPopup(false)
                        }} style={{ background: '#aaa' }} >
                            <span>Cancel</span>
                        </button>
                    </div>
                </div >
            </div >

            <div className='container'>
                <br />
                <div className="heading">
                    <span className="material-symbols-outlined">
                        notifications
                    </span>
                    <h4 style={{ fontWeight: '1000' }}>Notifications</h4>
                    {userType !== 'User' && <div
                        onClick={() => {
                            setShowPopup(true)
                        }}
                        style={{
                            position: 'relative',
                            left: '20px',
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%',
                            background: '#348DCB',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}>
                        <span style={{
                            fontSize: '1.4rem',
                            color: '#fff',
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-35%)'
                        }} className="material-symbols-outlined">
                            add
                        </span>
                    </div>}
                </div><br />

                <div className='row no-gutters'>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className='notification-card'>
                            <div className='notification-time'>
                                <small>App Updates</small>
                                <small>7 days ago</small>
                            </div>
                            <div className='notification-details'>
                                <img src="/img/coach.png" alt="" />
                                <div>
                                    <h1>New Update Available on Propdial Web App!</h1>
                                    <h2>
                                        Exciting news! The latest version of the Propdial Web App is here,
                                        packed with new features and improvements. Update now to enjoy an enhanced
                                        {/* user experience, smoother performance, and added functionalities tailored
                                        just for you. Log in to explore the new updates and make the most of your
                                        property management journey with Propdial!" */}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className='notification-card'>
                            <div className='notification-time'>
                                <small>App Updates</small>
                                <small>7 days ago</small>
                            </div>
                            <div className='notification-details'>
                                <img src="/img/coach.png" alt="" />
                                <div>
                                    <h1>New Update Available on Propdial Web App!</h1>
                                    <h2>
                                        Exciting news! The latest version of the Propdial Web App is here,
                                        packed with new features and improvements. Update now to enjoy an enhanced
                                        {/* user experience, smoother performance, and added functionalities tailored
                                        just for you. Log in to explore the new updates and make the most of your
                                        property management journey with Propdial!" */}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className='notification-card'>
                            <div className='notification-time'>
                                <small>App Updates</small>
                                <small>7 days ago</small>
                            </div>
                            <div className='notification-details'>
                                <img src="/img/coach.png" alt="" />
                                <div>
                                    <h1>New Update Available on Propdial Web App!</h1>
                                    <h2>
                                        Exciting news! The latest version of the Propdial Web App is here,
                                        packed with new features and improvements. Update now to enjoy an enhanced
                                        {/* user experience, smoother performance, and added functionalities tailored
                                        just for you. Log in to explore the new updates and make the most of your
                                        property management journey with Propdial!" */}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {noticeForMe && noticeForMe.length > 0 && <div className='row no-gutters'>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        Notice for Me
                    </div>
                    {noticeForMe && noticeForMe.map((notice) => (

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className='notification-card'>
                                <div className='notification-time'>
                                    <small>App Updates - [{notice.NoticeType === 'City' ? 'For ' + notice.City :
                                        notice.NoticeType === 'Event' ? 'For event' :
                                            notice.NoticeType === 'User' ? 'For User' : 'For All'
                                    }]</small>
                                    <small>{notice.DateDiff} days ago</small>
                                </div>
                                <div className='notification-details'>
                                    <img src="/img/coach.png" alt="" />
                                    <div>
                                        <h1>{notice.NoticeHeader}</h1>
                                        <h2>
                                            {notice.NoticeText}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}
                {myNotice && myNotice.length > 0 && <div className='row no-gutters'>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        My Notice
                    </div>

                    {myNotice && myNotice.map((notice) => (

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className='notification-card'>
                                <div className='notification-time'>
                                    <small>App Updates - [{notice.NoticeType === 'City' ? 'For ' + notice.City :
                                        notice.NoticeType === 'Event' ? 'For event' :
                                            notice.NoticeType === 'User' ? 'For User' : 'For All'
                                    }]</small>
                                    <small>{notice.DateDiff} days ago</small>
                                </div>
                                <div className='notification-details'>
                                    <img src="/img/coach.png" alt="" />
                                    <div>
                                        <h1>{notice.NoticeHeader}</h1>
                                        <h2>
                                            {notice.NoticeText}
                                        </h2>
                                    </div>

                                </div>
                                <div onClick={() => {
                                    setNoticeObject(notice);
                                    setShowPopup(true)
                                    // console.log('edit clicked')
                                }}>
                                    <span className="material-symbols-outlined">
                                        edit
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}
                <br />
            </div>
        </>
    )
}
