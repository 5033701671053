import React, { useEffect, useState } from 'react'
import { useUserAuth } from '../context/UserAuthcontext';
import { useLocalStorage } from "../context/useLocalStorage";
import { useNavigate } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument.js';
import DatePicker from "react-datepicker";
import '../css/MydatePicker.css'
import "react-datepicker/dist/react-datepicker.css";
import { httpsCallable } from "firebase/functions";
import { auth, functions, projectStorage } from '../firebase.js'
import PhoneInput from "react-phone-input-2";

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { Alert } from "react-bootstrap";

import { useImageUpload } from '../hooks/useImageUpload';
// import { format } from "date-fns"; // Import date-fns for formatting

import {
    // onAuthStateChanged,
    // signInWithPhoneNumber,
    // createUserWithEmailAndPassword,
    // signInWithEmailAndPassword,
    // signOut,
    RecaptchaVerifier,
    // GoogleAuthProvider,
    // signInWithPopup
} from "firebase/auth";
import useDocumentUpdate from '../hooks/useDocumentUpdate.js';
export default function ProfileHeading() {
    const { user, logout } = useUserAuth();
    const [localUserProfile, setLocalUserProfile] = useState(null);
    const [signinFlag, setSigninFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    // console.log('user :: ', user)
    let userID = user && user.userInfo ? user.userInfo.uid : 'test'
    // console.log('userID', userID)
    const { document: userDoc, error: userDocError } = useDocument('UserList', userID)
    const [showSaveError, setShowSaveError] = useState(false);
    const [thumbnail, setThumbnail] = useState(null)
    const [loadImage, setLoadImage] = useState(false);
    const [thumbnailError, setThumbnailError] = useState(null)
    const { imgUpload, isImgCompressPending, imgCompressedFile } = useImageUpload()
    const [showLogout, setShowLogout] = useState(false);

    const [changeNumberDisplay, setchangeNumberDisplay] = useState(false)
    const [changeNumberScroller, setchangeNumberScroller] = useState(false);
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [error, setError] = useState("");
    const [userPhoneNumber, setUserPhoneNumber] = useState(user.phoneNumber);
    const [verificationId, setVerificationId] = useState(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [editCity, setEditCity] = useState(false);
    const { updateDocument, loading: updateLoading, error: updateError } = useDocumentUpdate();


    const navigate = useNavigate();

    function setSignIn(flag, userData) {
        console.log(flag)
        setSigninFlag(flag);
        // setUserDetails(userData);
    }
    const changeNumberBack = () => {
        // id_sendotpButton
        document.getElementById('id_sendotpButton').style.display = 'block';
        setchangeNumberScroller(false);
    }

    const closeChangeNumber = () => {
        setchangeNumberDisplay(false);
    }
    // const formatDate = (date) => {
    //     return format(date, "dd-MMM-yyyy"); // E.g., 08-Oct-2024
    // };

    useEffect(() => {
        console.log('in UseEffect')
        // setLocalUserProfile(userDoc);

        // setUserDetails(userDoc)
        // setLocalUserProfile(userDetails)
        // console.log('userDoc', userDoc, ' :: userDetails : ', userDetails)
        if (user.isLoggedIn) {
            if (user.userInfo) {
                // console.log('userDetails: ', userDetails);
                setSigninFlag(true)
                // setFlagLoad(true);
            }
        }
        else {
            setSigninFlag(false);
            // setFlagLoad(true);
            // navigate("/PhoneSignUp", { state: { url: 'Profile' } });
            // navigate("/PhoneSignUp", { state: { url: '' } });
        }
    }, [user])

    useEffect(() => {
        console.log('in UseEffect')
        setLocalUserProfile(userDoc);
        // setUserDetails(userDoc)

    }, [userDoc])
    // console.log('userDetails.ProfilePicURL', signinFlag, userDetails)
    const handleSendVerificationCode = async (e) => {
        e.preventDefault();
        setError("");
        // console.log("phone:", phone);
        if (newPhoneNumber === "" || newPhoneNumber === undefined || newPhoneNumber.length < 10) {
            return setError("Please enter valid Phone Number");
        }
        try {
            // console.log('newPhoneNumber: ', newPhoneNumber)

            // hide buttons
            // id_sendotpButton
            document.getElementById('id_sendotpButton').style.display = 'none';

            const phoneProvider = new auth.PhoneAuthProvider();
            // console.log('phoneProvider: ', phoneProvider)
            // const recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container');
            const recaptchaVerifier = new RecaptchaVerifier(auth, 'recapcha-container', {
                size: 'invisible'
            });

            // console.log('recaptchaVerifier: ', recaptchaVerifier)
            var newPhoneNumberWithPlus = "+" + newPhoneNumber;
            const id = await phoneProvider.verifyPhoneNumber(
                newPhoneNumberWithPlus,
                recaptchaVerifier
            );
            // console.log('id: ', id)
            setVerificationId(id);

            setchangeNumberScroller(true);
            // alert('Verification code sent!');
        } catch (error) {
            console.error('Error sending verification code:', error.message);
            setError("Error sending verification code");
        }
    };


    const [editProfile, seteditProfile] = useState(false);

    const editProfileClick = () => {
        console.log(editProfile)
        if (editProfile) {
            console.log('saveDatainDB()');
            regSaveProfileDetails();
        }
        seteditProfile(!editProfile);
    }

    const handleChangePhoneNumber = async () => {
        // console.log('In handleChangePhoneNumber')
        try {

            const credential = auth.PhoneAuthProvider.credential(
                verificationId,
                verificationCode
            );

            const currentUser = auth.currentUser;
            await currentUser.updatePhoneNumber(credential);

            // console.log('Phone number updated successfully!')

            //Logout and redirect to login page
            logout();

        } catch (error) {
            // Handle any errors
            console.error('Error changing phone number:', error.message);
            setError("Error changing phone number");
        }
    }

    async function regSaveProfileDetails() {

        var para = {};
        para = {
            UserID: user && user.userInfo && user.userInfo.uid !== '0' ? user.userInfo.uid : '0',
            Address: localUserProfile.Address,
            City: localUserProfile.City,
            Country: localUserProfile.Country,
            DateOfBirth: localUserProfile.DateOfBirth ?
                localUserProfile.DateOfBirth.seconds ? new Date(localUserProfile.DateOfBirth.seconds * 1000) : new Date(localUserProfile.DateOfBirth)
                : new Date(),
            Email: localUserProfile.Email,
            Gender: localUserProfile.Gender,
            Pincode: localUserProfile.Pincode,
            PlayerID: localUserProfile.PlayerID,
            ProfilePicURL: localUserProfile.ProfilePicURL,
            SelectedRole: localUserProfile.SelectedRole,
            State: localUserProfile.State,
            UserName: localUserProfile.UserName,
            UserRole: localUserProfile.UserRole,
        };
        console.log(para);
        // console.log('in true')
        setLoading(true);

        const ret = await httpsCallable(functions, "updateProfileDetailsNew");
        ret(para).then(async (result) => {
            // props.addNewMember(false, playerDetailsCopy.PlayerID);
            setLoading(false);
            window.localStorage.setItem("userProfile", JSON.stringify(localUserProfile));
            // console.log("From Function " + result.data);
            setShowSaveError(true);

            setTimeout(function () {
                setShowSaveError(false);
                // navigate(-1);
            }, 3000);


        });

    }


    // function onChangeValueMale(checked) {
    //     checked && setLocalUserProfile({
    //         ...localUserProfile,
    //         Gender: 'Male'
    //     });
    //     !checked && setLocalUserProfile({
    //         ...localUserProfile,
    //         Gender: 'Female'
    //     });
    // }

    // function onChangeValueFemale(checked) {

    //     checked && setLocalUserProfile({
    //         ...localUserProfile,
    //         Gender: 'Female'
    //     });
    //     !checked && setLocalUserProfile({
    //         ...localUserProfile,
    //         Gender: 'Male'
    //     });
    // }

    function onChangeValueGender(gender) {
        // console.log('in onChangeValueGender', gender)
        if (editProfile) {
            if (gender === 'Male') {
                setLocalUserProfile({
                    ...localUserProfile,
                    Gender: 'Female'
                })
            }
            else if (gender === 'Female') {
                setLocalUserProfile({
                    ...localUserProfile,
                    Gender: 'Male'
                })
            }
        }
    }
    const handleFileChange = async (e) => {
        setThumbnail(null)
        let file = e.target.files[0]
        setLoadImage(true);
        const compressedImage = await imgUpload(file, 300, 300);

        if (!compressedImage) {
            setThumbnailError('Please select a file')
            setLoadImage(false);
            return
        }
        if (!compressedImage.type.includes('image')) {
            setThumbnailError('Selected file must be an image')
            setLoadImage(false);
            return
        }

        // if (newImageFile.size > 20000000) {
        //   setThumbnailError('Image file size must be less than 20mb')
        //   return
        // }

        setThumbnailError(null)
        // setThumbnail(compressedImage)

        if (compressedImage) {
            // console.log('thumbnail in useSignup 2:', thumbnail)
            // let ts = Date.now();
            // console.log(ts);
            let uploadPath = '';

            uploadPath = `userThumbnails/${user.userInfo.uid}/${'thumbnail' + localUserProfile.PlayerID + '.png'}`
            // console.log('uploadPath : changed :: ', uploadPath);
            const imgRef = ref(projectStorage, uploadPath);

            uploadBytes(imgRef, compressedImage)
                .then((snapshot) => {
                    getDownloadURL(imgRef).then(async (urlRef) => {
                        // console.log('urlRef', urlRef);
                        setLocalUserProfile({
                            ...localUserProfile,
                            ProfilePicURL: urlRef
                        })
                        //save is DB

                        // console.log('image updated successfully : ', playerDetailsCopy);
                        //update in DB
                        console.log(localUserProfile)
                        let para1 = {
                            userID: localUserProfile.id,
                            ProfilePicURL: urlRef,
                        };
                        // console.log(para1);
                        // setLoading(true);
                        const ret1 = httpsCallable(functions, "saveProfilePicture");
                        ret1(para1).then(async (result) => {
                            // console.log('JSON.stringify(localUserProfile) : ', JSON.stringify(localUserProfile))
                            setShowSaveError(true);

                            setTimeout(function () {
                                setShowSaveError(false);

                            }, 3000);
                            window.localStorage.setItem("userProfile", JSON.stringify(localUserProfile));

                            setLoadImage(false);

                        });

                    });
                })
        }
    }
    async function saveCity() {
        console.log('saveCity')
        if (editCity) {
            console.log('in true')
            let _updatedData = {
                'City': localUserProfile ? localUserProfile.City : '',
                "UpdatedTimestamp": new Date()
            }
            console.log(_updatedData, user.userInfo.uid)
            await updateDocument('UserList', user.userInfo.uid, _updatedData)

        } else {
            console.log('in false')
        }
        setEditCity(!editCity)

    }
    return (
        <div>
            <div className={showLogout ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner'>
                    <h1 >
                        <strong>Are you sure you wish to logout?</strong>

                    </h1>

                    <hr />

                    <div className='btn-div'>
                        <button className='mybutton button5' onClick={() => {
                            logout();
                            navigate("/")

                        }}>
                            <span>YES</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybutton button5' onClick={() => {
                            setShowLogout(false);

                        }}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div >
            </div>
            <div className={changeNumberDisplay ? 'pop-up-div open' : 'pop-up-div'} style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50.5%)',
                top: '50px',
                height: '100%',
                width: 'calc(100% - 28px)',
                maxWidth: '500px',
                borderRadius: '15px',
                background: 'rgba(0,0,0,0.6)'
            }} >
                <div className="direct-div" style={{ position: 'relative', background: '#fff', width: '80%', borderRadius: '15px', padding: '10px 20px 5px 20px' }}>
                    <span style={{ position: 'absolute', right: '8px', top: '8px', cursor: 'pointer' }} onClick={closeChangeNumber} className="material-symbols-outlined close-button">
                        close
                    </span>
                    <span >Existing no : {userPhoneNumber} </span>
                    <div style={{ fontWeight: 'bold' }}>
                        Enter New Number
                    </div>

                    {error && <Alert style={{ padding: '5px 20px' }} variant="danger">{error}</Alert>}

                    <form
                        // style={{ background: 'red' }}
                        action=""
                        onSubmit={handleSendVerificationCode}
                    >
                        <div className="form_field label_top">
                            <PhoneInput
                                country={"in"}
                                // onlyCountries={['in', 'us']}
                                value={newPhoneNumber}
                                onChange={setNewPhoneNumber}
                                international
                                keyboardType="phone-pad"
                                // countryCallingCodeEditable={false}
                                // countryCodeEditable={false}
                                // disableCountryCode={true}
                                placeholder="Enter New Mobile Number"
                                inputProps={{
                                    name: "newPhoneNumber",
                                    required: true,
                                    autoFocus: true,
                                }}
                            ></PhoneInput>
                            <div id="id_sendotpButton" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0' }}>
                                <button onClick={closeChangeNumber} className="mybutton button5" style={{ height: '25px', paddingTop: '2.5px', background: '#aaa' }} > Cancel</button>
                                <button className="mybutton button5" style={{ height: '25px', paddingTop: '2.5px', }}>Send OTP</button>
                            </div>
                        </div>

                    </form>

                    <div className="form_field label_top">
                        <input
                            type="text"
                            placeholder="Enter verification code"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            style={{
                                background: "none",
                                width: '100%',
                                outline: 'none',
                                border: '1px solid #ccc',
                                height: '40px',
                                borderRadius: '4px',
                                padding: '0 20px'
                            }}
                        />
                        {/* new code */}
                        <div className="change-number-button-div" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0' }}>
                            <button onClick={changeNumberBack} className="mybutton button5" style={{ height: '25px', paddingTop: '2.5px', background: '#aaa' }} >Back</button>
                            <button onClick={handleChangePhoneNumber} className="mybutton button5" style={{ height: '25px', paddingTop: '2.5px', }}>Confirm</button>
                        </div>
                        {/* new code */}

                    </div>

                </div>
            </div>
            <div className='container-fluid'>
                {
                    signinFlag && localUserProfile && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'calc(100% - 5px)' }}>
                        <div className='profile-heading-card-new'>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                id='profilePic'
                                className='profile-pic-input'
                            />
                            <div className='img-div'>
                                <img src={localUserProfile && localUserProfile.ProfilePicURL && localUserProfile.ProfilePicURL !== '' ? localUserProfile.ProfilePicURL : "/img/user_icon_blue.png"} alt="user" />
                                <label htmlFor="profilePic">
                                    <span className="material-symbols-outlined" >
                                        photo_camera
                                    </span>
                                </label>
                            </div>
                            <h1 style={{ position: 'relative', top: '-5px' }}>( {localUserProfile && localUserProfile.PlayerID} )</h1>
                            <div style={{ position: 'relative', top: '-5px', paddingBottom: '0' }}>
                                <h2>{localUserProfile && localUserProfile.Phone}</h2>
                                {/* <small onClick={() => { setchangeNumberDisplay(true) }} style={{
                                    position: 'absolute',
                                    bottom: '6px',
                                    right: '0',
                                    fontSize: '0.8rem',
                                    color: 'red',
                                    cursor: 'pointer',
                                }}>Change number</small> */}
                                {/* <span style={{
                                    position: 'absolute',
                                    top: '40px',
                                    right: '140px',
                                    width: '20px',
                                    height: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#348DCB',
                                    borderRadius: '50%',
                                    color: '#fff',
                                    fontSize: '1.2rem',
                                    border: '1px solid #fff',
                                    cursor: 'pointer'
                                }} onClick={() => { setchangeNumberDisplay(true) }} className="material-symbols-outlined">
                                    {changeNumberDisplay ? 'check' : 'edit'}
                                </span> */}
                            </div>
                            <div style={{
                                border: '1px solid #ddd',
                                padding: '0',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                width: '100%',
                                borderRadius: '10px'
                            }}>
                                <span style={{
                                    position: 'absolute',
                                    top: '-20px',
                                    right: '-10px',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: '#348DCB',
                                    borderRadius: '50%',
                                    color: '#fff',
                                    fontSize: '1.2rem',
                                    border: '1px solid #fff',
                                    cursor: 'pointer'
                                }} onClick={editProfileClick} className="material-symbols-outlined">
                                    {editProfile ? 'check' : 'edit'}
                                </span>

                                <input type="text" className='change-name' placeholder='Enter your name...' maxLength={'50'} readOnly={editProfile ? false : true} value={localUserProfile && localUserProfile.UserName}
                                    style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
                                    onChange={(e) => {
                                        setLocalUserProfile({
                                            ...localUserProfile,
                                            UserName: e.target.value
                                        })
                                    }} />

                                <input type="email" className='change-name' placeholder='Enter your email...' maxLength={'50'} readOnly={editProfile ? false : true} value={localUserProfile && localUserProfile.Email}
                                    style={{ color: '#348DCB' }}
                                    onChange={(e) => {
                                        setLocalUserProfile({
                                            ...localUserProfile,
                                            Email: e.target.value
                                        })
                                    }} />
                                <div style={{ width: '80%', position: 'relative' }}>
                                    <DatePicker className='change-name' readOnly={editProfile ? false : true}
                                        showMonthDropdown
                                        showYearDropdown
                                        // showMonthYearDropdown
                                        // showFullMonthYearPicker
                                        dateFormat="dd-MMM-yyyy"
                                        maxDate={new Date()}
                                        selected={localUserProfile && localUserProfile.DateOfBirth && localUserProfile.DateOfBirth._seconds ?
                                            new Date(localUserProfile.DateOfBirth._seconds * 1000) :
                                            localUserProfile && localUserProfile.DateOfBirth && localUserProfile.DateOfBirth.seconds ?
                                                new Date(localUserProfile.DateOfBirth.seconds * 1000) :
                                                localUserProfile && localUserProfile.DateOfBirth ? new Date(localUserProfile.DateOfBirth) :
                                                    new Date()}
                                        onChange={(date) => {
                                            setLocalUserProfile({
                                                ...localUserProfile,
                                                DateOfBirth: new Date(date)
                                            })
                                        }}
                                    ></DatePicker>
                                    {editProfile && <span style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '10px',
                                        transform: 'translateY(-50%)',
                                        fontSize: '1.2rem',
                                        color: '#348DCB',
                                        pointerEvents: 'none'
                                    }} className="material-symbols-outlined">
                                        calendar_month
                                    </span>}
                                </div>
                                <div
                                    onClick={() => {
                                        onChangeValueGender(localUserProfile.Gender);
                                    }}
                                    className={(localUserProfile && localUserProfile.Gender === "Male" && editProfile) ? 'gender male edit' :
                                        (localUserProfile && localUserProfile.Gender === "Male" && !editProfile) ? 'gender male' :
                                            (localUserProfile && localUserProfile.Gender === "Female" && editProfile) ? 'gender female edit' : 'gender female'}>

                                    <span className="material-symbols-outlined">
                                        {localUserProfile && localUserProfile.Gender === "Male" ? 'male' : 'female'}
                                    </span>
                                    <h3>{localUserProfile && localUserProfile.Gender}</h3>
                                </div>

                            </div>
                            {/* <input style={{ textTransform: 'capitalize' }} placeholder='Enter your city...' type='text' readOnly={editProfile ? false : true}
                                onChange={(e) => {
                                    setLocalUserProfile({
                                        ...localUserProfile,
                                        City: e.target.value
                                    })
                                }}
                                value={localUserProfile && localUserProfile.City}></input>
                            <textarea placeholder='Enter your address...' maxLength={'150'} readOnly={editProfile ? false : true}
                                onChange={(e) => {
                                    console.log('onchange address ', e.target.value)
                                    setLocalUserProfile({
                                        ...localUserProfile,
                                        Address: e.target.value
                                    })
                                }}
                                value={localUserProfile && localUserProfile.Address}></textarea> */}
                            <div className='profile-address-div'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className="material-symbols-outlined">
                                        location_on
                                    </span>
                                    <div>
                                        {!editCity && <h1>
                                            {localUserProfile.City}
                                        </h1>}


                                        {editCity && <input type="text" placeholder='Enter city...' maxLength={'50'}
                                            value={localUserProfile && localUserProfile.City}
                                            style={{
                                                position: 'relative',
                                                top: '-1px',
                                                border: '1px dashed #aaa',
                                                margin: '0',
                                                fontSize: '1rem',
                                                fontWeight: 'bold',
                                                minWidth: '175px',
                                                width: '100%',
                                                textAlign: 'left',
                                                color: '#333C5D'
                                            }}
                                            onChange={(e) => {
                                                setLocalUserProfile({
                                                    ...localUserProfile,
                                                    City: e.target.value
                                                })
                                            }} />}
                                    </div>
                                </div>
                                <div style={{ textAlign: 'center', }}>
                                    <button className='mybutton buttonWhite' onClick={() => {
                                        saveCity()
                                    }}>{editCity ? 'Save' : 'Change'}</button>
                                </div>
                            </div>
                            {/* <div style={{ width: '100%' }}
                                onClick={() => {
                                    setShowLogout(true);
                                }}>
                                <button style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    left: '-1.5px',
                                    marginBottom: '0',
                                    width: '100%',
                                    borderRadius: '10px',
                                    background: '#e11032',
                                    height: '40px',
                                }} className='mybutton button5'>
                                    <span style={{
                                        position: 'relative',
                                        top: '-px',
                                        fontSize: '1.3rem',
                                        paddingRight: '5px'
                                    }} className="material-symbols-outlined">
                                        logout
                                    </span>
                                    <small style={{ fontSize: '1.1rem', }}>
                                        Logout
                                    </small></button>
                            </div> */}
                            {loading && <div style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                background: 'rgba(0,0,0,0.6)',
                                width: '100%',
                                height: '100%',
                                borderRadius: '15px',
                                zIndex: '99'
                            }}>
                                <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_9yosyj7r.json" background="transparent"
                                    speed="1" style={{ width: 'auto', height: '100%' }} loop autoplay></lottie-player>
                            </div>}
                        </div>
                    </div>}

            </div>

            <br />


            {/* {
                signinFlag && userDetails && <div className='profile-heading-card' style={{ cursor: 'pointer' }} onClick={() => {
                    navigate('/EditProfile');
                }}>
                    <div className='profile-heading-card-img'>
                        <img src={userDetails && userDetails.ProfilePicURL} alt="" />
                    </div>
                    <div className='profile-heading-card-details'>
                        <div style={{ justifyContent: 'initial' }}>
                            <h2>Hi {userDetails && userDetails.UserName}</h2>
                            <h3 style={{ position: 'relative', top: '-2px', paddingLeft: '5px', fontSize: '1rem' }}>({userDetails && userDetails.PlayerID})</h3>
                        </div>
                        <hr style={{ margin: '2px 0 5px 0' }} />
                        <div>
                            <h4>{userDetails && userDetails.Phone}</h4>
                            <h3>{userDetails && userDetails.SelectedRole && userDetails.SelectedRole.toLowerCase().replace(/([A-Z])/g, ' $1')
                                .replace(/^./, function (str) { return str.toUpperCase(); })}</h3>
                        </div>
                        <div>
                            <h5>{userDetails && userDetails.Email}</h5>
                            <h3>{userDetails && userDetails.City}</h3>
                        </div>
                        <div>
                            <h3>{userDetails && userDetails.DateOfBirth && userDetails.DateOfBirth._seconds ?
                                new Date(userDetails.DateOfBirth._seconds * 1000).toISOString().split("T")[0] :
                                userDetails && userDetails.DateOfBirth && userDetails.DateOfBirth.seconds ?
                                    new Date(userDetails.DateOfBirth.seconds * 1000).toISOString().split("T")[0] :
                                    userDetails && userDetails.DateOfBirth ? new Date(userDetails.DateOfBirth).toISOString().split("T")[0] :
                                        new Date().toISOString().split("T")[0]}</h3>
                        </div>
                    </div>
                    <div className='profile-heading-card-arrow'>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                    </div>
                </div>
            } */}
        </div >
    )
}
