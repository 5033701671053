import React, { useState, useRef, useEffect } from "react";

import { useNavigate, useLocation } from 'react-router-dom';
import '../css/Dashboard.css'
import ManageParticipant from '../components/ManageParticipant';
import { useUserAuth } from '../context/UserAuthcontext'
import { useLocalStorage } from "../context/useLocalStorage";
import { functions } from '../firebase.js'
import { httpsCallable } from "firebase/functions";
import ProfileHeading from '../components/ProfileHeading.js';
import LinearProgressBar from '../components/LinearProgressBar.jsx';
import PGEventPendingPayment from './PGEventPendingPayment.js';
import QRCodeComponent from '../components/QRCodeComponent';
import { useRazorpay } from '../hooks/useRozarPay';
import NewEventCard from '../components/NewEventCard.js';
import { useCollection } from '../hooks/useCollection.js';
import useDocumentUpdate from '../hooks/useDocumentUpdate.js';
import RegistrationPackage from "../components/RegistrationPackage.js";
import { where } from "firebase/firestore";
export default function PGDashboardOrganizer(props) {
    // console.log(props)

    const navigate = useNavigate();

    // Scroll to the top of the page whenever the location changes start
    const { user, users, logout } = useUserAuth();
    console.log("user: ", user)
    const [popupLoading, setPopupLoading] = useState(false);

    const [totalEvent, setTotalEvent] = useState(0);
    const [ownedEvent, setOwnedEvent] = useState([]);
    const [activeCount, setActiveCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [cancelledCount, setCancelledCount] = useState(0);
    const [onholdCount, setOnholdCount] = useState(0);
    const [unpaidCount, setUnpaidCount] = useState(0);
    const [eventList, setEventList] = useState([]);
    const [createrUnpaid, setCreaterUnpaid] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPendingPayment, setIsPendingPayment] = useState(false);
    const { makePayment, paymentResponse, handleRefund } = useRazorpay();
    const [totalPayment, setTotalPayment] = useState(0);
    const [gst, setGST] = useState(0);
    const [eventFilter, setEventFilter] = useState('Active');
    const [activeEvent, setActiveEvent] = useState(null)
    const [closedEvent, setClosedEvent] = useState(null)
    const [cancelledEvent, setCancelledEvent] = useState(null)
    const [onholdEvent, setOnholdEvent] = useState(null)
    const [filteredEvent, setFilteredEvent] = useState(null)
    const [showDelete, setShowDelete] = useState(false)
    const [showPackage, setShowPackage] = useState(false)
    const [showSection, setShowSection] = useState('')

    const location = useLocation();
    const { updateDocument, deleteDocument, error: updateError } = useDocumentUpdate();
    const query = [
        where('UserID', '==', user && user.userInfo ? user.userInfo.uid : ' '),
    ]
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };
    const { documents: packageDoc, error: packageDocError } = useCollection('UserPackageDetails', query)

    const divRef = useRef(null); // Create a reference to the div

    const handleFocusDiv = () => {
        console.log('in handleFocusDiv', divRef.current)
        if (divRef.current) {
            divRef.current.focus(); // Set focus to the div
        }
    };

    const queryConstraints = [
        // Add more query constraints as needed
    ];

    // const queryConstraintsOwner = [
    //     where('OwnerPhone', '==', eventID),

    //     // Add more query constraints as needed
    // ];

    const { documents: eventDocs, error: eventDocsError } = useCollection('EventList', queryConstraints)
    const { documents: ownerDocs, error: ownerDocsError } = useCollection('EventOwner', queryConstraints)
    const { documents: controllerDocs, error: controllerDocsError } = useCollection('EventController', queryConstraints)

    // console.log('user:', userDetails.SelectedRole.toUpperCase())

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    // Scroll to the top of the page whenever the location changes end
    useEffect(() => {
        let _amount = 0;
        if (packageDoc[0] && packageDoc[0].PaymentStatus === 'Pending') {
            _amount = Number(packageDoc[0].PaymentAmount) * 0.18;
            setGST(_amount)
            setTotalPayment(Number(packageDoc[0].PaymentAmount));

        }
    }, [packageDoc]);
    // Scroll to the top of the page whenever the location changes end

    useEffect(() => {
        // console.log('userDetails ', userDetails.UserRole)
        // console.log(userDetails.UserRole.findIndex(e => e.TYPE === 'ADMIN'))
        if (user && user.userInfo && user.userInfo.UserRole && (user.userInfo.UserRole.findIndex(e => e.TYPE === 'ADMIN') >= 0)) {
            setIsAdmin(true)
            console.log('ADMIN True')

        }
        setEventList(eventDocs)
        setEventFilters()
    }, [eventDocs, ownerDocs, controllerDocs])


    async function setEventFilters() {
        let phone = user && user.userInfo && user.userInfo.Phone ? user.userInfo.Phone.slice(-10) : ''
        let ownerEventList = []
        let createrUnpaidCount = 0;
        let owners = [];
        //let createrUnpaid = [];
        setTotalEvent(eventDocs.length)
        let unpaindEvent = [];
        if (!isAdmin) {
            unpaindEvent = eventDocs.filter(e => (e.PaymentStatus === 'Pending' && e.CreatedBy === user.userInfo.uid));
        }
        else {
            unpaindEvent = eventDocs.filter(e => (e.PaymentStatus === 'Pending'));
            // console.log(' admin : pending ', unpaindEvent)
        }

        setCreaterUnpaid(unpaindEvent);
        setUnpaidCount(unpaindEvent.length);

        ownerEventList = eventDocs.filter(e => e.CreatedBy === user.userInfo.uid)
        owners = ownerDocs.filter(e => e.OwnerPhone.includes(phone))
        owners.forEach(element => {
            if (ownerEventList.findIndex(e1 => e1.EventID === element.EventID) < 0) {
                ownerEventList.push(element);
            }
        });

        owners = controllerDocs.filter(e => e.ControllerPhone.includes(phone))
        owners.forEach(element => {
            if (ownerEventList.findIndex(e1 => e1.EventID === element.EventID) < 0) {
                ownerEventList.push(element);
            }
        });

        setOwnedEvent(ownerEventList);
        let event = eventDocs.filter(e => e.EventStatus.toLowerCase() === 'active' && (isAdmin || e.CreatedBy === user.userInfo.uid))
        setActiveCount(event.length)
        setActiveEvent(event);
        if (eventFilter === 'Active')
            setFilteredEvent(event)

        event = eventDocs.filter(e => e.EventStatus.toLowerCase() === 'closed')
        setCompletedCount(event.length)
        setClosedEvent(event);

        event = eventDocs.filter(e => e.EventStatus.toLowerCase() === 'cancelled' && (isAdmin || e.CreatedBy === user.userInfo.uid))
        setCancelledCount(event.length)
        setCancelledEvent(event);

        event = eventDocs.filter(e => e.EventStatus.toLowerCase() === 'onhold' && (isAdmin || e.CreatedBy === user.userInfo.uid))
        setOnholdCount(event.length)
        setOnholdEvent(event);

    }

    // async function getAllEvent() {
    //     var para = {};
    //     let eventOwner = [];
    //     let phone = user && user.userInfo && user.userInfo.Phone ? user.userInfo.Phone.slice(-10) : ''
    //     let owners = []
    //     let ownerEventList = []
    //     let createrUnpaidCount = 0;
    //     //let createrUnpaid = [];
    //     const ret = await httpsCallable(functions, "getEventList");
    //     ret(para).then(async (result) => {
    //         console.log(result);
    //         setTotalEvent(result.data.Events.length)
    //         setEventList(result.data.Events);
    //         let unpaindEvent = [];
    //         if (!isAdmin) {
    //             // console.log('e.CreatedBy === userDetails.id', user.userInfo.uid)
    //             unpaindEvent = result.data.Events.filter(e => (e.PaymentStatus === 'Pending' && e.CreatedBy === user.userInfo.uid));
    //             console.log(' not admin : pending ', unpaindEvent)
    //             // console.log('not admin : pending ', result.data.Events.filter((e) => (e.PaymentStatus === 'Pending' && e.CreatedBy === userDetails.id)));
    //             // console.log('not admin : pending ', result.data.Events.filter((e) => (e.PaymentStatus === 'Pending')));
    //             // console.log('not admin : pending ', unpaindEvent, userDetails.id)
    //         }
    //         else {
    //             unpaindEvent = result.data.Events.filter(e => (e.PaymentStatus === 'Pending'));
    //             console.log(' admin : pending ', unpaindEvent)
    //         }

    //         setCreaterUnpaid(unpaindEvent);
    //         setUnpaidCount(unpaindEvent.length);

    //         ownerEventList = result.data.Events.filter(e => e.CreatedBy === user.userInfo.uid)
    //         console.log('1 ownerEventList : ', ownerEventList)
    //         owners = result.data.Owners.filter(e => e.OwnerPhone.includes(phone))
    //         owners.forEach(element => {
    //             if (ownerEventList.findIndex(e1 => e1.EventID === element.EventID) < 0) {
    //                 ownerEventList.push(element);
    //             }
    //         });

    //         owners = result.data.Controller.filter(e => e.ControllerPhone.includes(phone))
    //         owners.forEach(element => {
    //             if (ownerEventList.findIndex(e1 => e1.EventID === element.EventID) < 0) {
    //                 ownerEventList.push(element);
    //             }
    //         });

    //         setOwnedEvent(ownerEventList);
    //         let event = result.data.Events.filter(e => e.EventStatus.toLowerCase() === 'active')
    //         setActiveCount(event.length)
    //         setActiveEvent(event);
    //         if (eventFilter === 'Active')
    //             setFilteredEvent(event)

    //         event = result.data.Events.filter(e => e.EventStatus.toLowerCase() === 'closed')
    //         setCompletedCount(event.length)
    //         setClosedEvent(event);

    //         event = result.data.Events.filter(e => e.EventStatus.toLowerCase() === 'cancelled')
    //         setCancelledCount(event.length)
    //         setCancelledEvent(event);

    //         event = result.data.Events.filter(e => e.EventStatus.toLowerCase() === 'onhold')
    //         setOnholdCount(event.length)
    //         setOnholdEvent(event);


    //     })
    // }

    function createEvent() {
        navigate("/CreateEvent", {
            state: {
                createStage: 'package'
            }
        });
    }
    function payRegistration() {
        if (!isAdmin) {
            // console.log('createrUnpaid[0]', createrUnpaid[0])
            // navigate("/PGCreateEventPayment/" + createrUnpaid[0].Eventid)
            navigate('/PGCreateEventPayment', {
                state: {
                    EventID: createrUnpaid[0].Eventid
                }
            })
        }
    }

    function pendingPayments() {
        navigate('/PGEventPendingPayment', {
            state: {
                eventList: createrUnpaid
            }
        })
    }
    function mymakePayment(EventID) {
        let orderId = createrUnpaid[0].id + '_' + new Date().getTime();
        setTotalPayment(1);
        // console.log('userDetails : ', userDetails, 'orderId : ', orderId, totalPayment)
        makePayment(orderId,
            Number(1),
            user && user.userInfo ? user.userInfo.UserName : '', 'Payment for Registration',
            user && user.userInfo ? user.userInfo.Email : '',
            user && user.userInfo ? user.userInfo.Phone : '',
            ['EventID']
        ).then(async (e) => {
            console.log(e, 'paymentResponse', paymentResponse);

        })

    }

    useEffect(() => {
        console.log(paymentResponse)
        let status = '';
        if (paymentResponse) {
            console.log('paymentResponse.paymentStatus.toUpperCase()', paymentResponse)
            status = paymentResponse.paymentStatus.toUpperCase() === 'SUCCESS' ? 'Completed' : 'Pending';
            if (status.toLowerCase() === 'completed') {
                // setNewPaymentStatus(status);
                updateTransactioninDB(paymentResponse.transactionID, totalPayment, status)
            }
        }

    }, [paymentResponse])

    async function updateTransactioninDB(transactionid, paymentAmount, paymentstatus) {
        setPopupLoading(true);
        console.log('transactionid', transactionid, 'paymentstatus', paymentstatus, createrUnpaid)
        if (user && user.userInfo) {
            // let eventid = createrUnpaid && createrUnpaid[0] && createrUnpaid[0].Eventid
            if (createrUnpaid[0]) {


                let _updatePackage = {
                    'PaymentStatus': paymentstatus,
                    'TransactionID': transactionid,
                    'TotalPaymentRecived': paymentAmount,
                    'EventsAlreadyCreated': packageDoc[0].EventsAlreadyCreated + 1,
                    "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
                    "UpdatedTimestamp": new Date()
                }
                await updateDocument('UserPackageDetails', packageDoc[0].id, _updatePackage)

                let _updateEvent = {
                    'PaymentStatus': paymentstatus,
                    'PaymentAmount': paymentAmount,
                    'TransactionID': transactionid,
                    'ApprovalStatus': 'Approved',
                    'EventStatus': 'Active',
                    'UpdatedBy': user && user.userInfo ? user.userInfo.uid : '',
                    "UpdatedTimestamp": new Date()
                }

                await updateDocument('EventList', createrUnpaid[0].id, _updateEvent)
                navigate("/EventDetails/" + createrUnpaid[0].id);



                // const updatetransaction = httpsCallable(functions, "updateTransactionIDForEventRegistration");
                // let para = {
                //     EventID: createrUnpaid[0].id,
                //     TransactionID: transactionid,
                //     PaymentAmount: paymentAmount,
                //     PaymentStatus: paymentstatus,

                // };
                // console.log('para', para);
                // return updatetransaction(para)
                //     .then(async (result) => {
                //         console.log('result', result)

                //         navigate("/EventDetails/" + createrUnpaid[0].id);

                //     })
            }
        }
    }
    async function activateEvent() {
        let _updateEvent = {
            'PaymentStatus': 'Completed',
            'ApprovalStatus': 'Approved',
            'EventStatus': 'Active',
            'UpdatedBy': user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        console.log(_updateEvent, createrUnpaid[0].id)
        await updateDocument('EventList', createrUnpaid[0].id, _updateEvent)

        let _updatePackage = {
            'EventsAlreadyCreated': packageDoc[0].EventsAlreadyCreated + 1,
            "UpdatedBy": user && user.userInfo ? user.userInfo.uid : '',
            "UpdatedTimestamp": new Date()
        }
        console.log(_updatePackage, packageDoc[0].id)
        await updateDocument('UserPackageDetails', packageDoc[0].id, _updatePackage)

        navigate("/EventDetails/" + createrUnpaid[0].id);

    }
    async function callDelete() {
        deleteDocument('EventList', createrUnpaid[0] ? createrUnpaid[0].id : 'test')
        setShowDelete(false)
    }
    console.log('1 : ownedEvent', isAdmin, createrUnpaid)
    return (
        <div>
            <br />
            {/* <div className='container'>
                <div style={{ padding: '0 10px' }}>
                    <ProfileHeading></ProfileHeading>
                </div>
                <br />

            </div> */}

            {/* <hr /> */}

            <div className={showDelete ? 'pop-up-div open' : 'pop-up-div'}>
                <div className='pop-up-div-inner'>
                    <h1 style={{ fontSize: '1.2rem' }}>
                        <strong style={{ fontWeight: 'normal' }}>Are you sure you want to delete this event?</strong>
                    </h1>

                    {/* <hr /> */}

                    <div className='btn-div' style={{ paddingBottom: '0' }}>
                        <button className='mybutton button5'
                            onClick={() => {
                                callDelete()
                            }}
                        >
                            <span>YES</span>
                        </button>
                        <button style={{ background: '#aaa' }} className='mybutton button5'
                            onClick={() => {
                                setShowDelete(false);

                            }}
                        >
                            <span>Cancel</span>
                        </button>
                    </div>
                </div >
            </div>

            {
                <div className='container'>
                    <div className='row no-gutters'>

                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            {unpaidCount > 0 ?
                                <div className='ticket-card-outter' style={{ paddingBottom: '5px' }}>
                                    <div className='ticket-card'>
                                        <div
                                        // onClick={() => {
                                        //     navigate('/EventDetails/' + props.event.EventID)
                                        // }}
                                        >
                                            <div className='event-id' style={{ width: 'auto' }}>
                                                <h5>{createrUnpaid && createrUnpaid[0] && createrUnpaid[0].SportName} - {createrUnpaid && createrUnpaid[0] && createrUnpaid[0].EventCode}</h5>
                                            </div>
                                            <div className='ticket-card-heading'>
                                                <div style={{ paddingRight: '20px', }}>
                                                    <h1>{createrUnpaid && createrUnpaid[0] && createrUnpaid[0].EventName}</h1>
                                                </div>
                                                <div style={{
                                                    position: 'relative',
                                                    top: '-8px',
                                                    background: 'red',
                                                    width: '30px',
                                                    height: '30px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: '50%',
                                                    cursor: 'pointer'
                                                }}
                                                    onClick={() => {
                                                        setShowDelete(true)
                                                    }}
                                                >
                                                    <span style={{ fontSize: '1.1rem', color: '#fff' }} className="material-symbols-outlined">
                                                        delete
                                                    </span>
                                                </div>
                                            </div>
                                            <hr style={{ margin: '5px 0 0 0' }}></hr>
                                            <div className='ticket-card-details'>
                                                <div>
                                                    <div>
                                                        <span>LOCATION</span>
                                                        <small>{createrUnpaid && createrUnpaid[0] && createrUnpaid[0].City}</small>
                                                    </div>
                                                    {/* <div>
                                                    <span>START DATE</span>
                                                    <small>{createrUnpaid && createrUnpaid[0] && createrUnpaid[0].EventStartDate &&
                                                        createrUnpaid[0].EventStartDate._seconds ? (new Date(createrUnpaid[0].EventStartDate._seconds * 1000)).toISOString().split("T")[0] :
                                                        createrUnpaid && createrUnpaid[0] && createrUnpaid[0].EventStartDate &&
                                                            createrUnpaid[0].EventStartDate.seconds ? (new Date(createrUnpaid[0].EventStartDate.seconds * 1000)).toISOString().split("T")[0] :
                                                            (new Date()).toISOString().split("T")[0]}</small>
                                                </div> */}
                                                    <div>
                                                        <span>TPLiVE Charges</span>
                                                        <small>{Number(totalPayment).toLocaleString('en-IN', curFormat)}</small>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div></div>
                                                    <div>
                                                        <span>GST</span>
                                                        <small>{Number(gst).toLocaleString('en-IN', curFormat)}</small>
                                                    </div>
                                                </div>
                                                <div style={{ paddingTop: '10px' }}>
                                                    <span style={{ fontSize: '0.9rem', color: 'red', lineHeight: '1', paddingRight: '30px' }}>Event will be activated only after payment; event details can be updated later.</span>
                                                    {/* <span style={{ fontSize: '0.7rem', color: '#aaa' }}>Event Code : {createrUnpaid && createrUnpaid[0] && createrUnpaid[0].EventCode}</span> */}

                                                    {totalPayment > 0 && <button className='mybutton button5' onClick={(e) => {
                                                        console.log('create event ')
                                                        mymakePayment(createrUnpaid && createrUnpaid[0] && createrUnpaid[0].Eventid);

                                                    }} style={{ fontWeight: 'bold', minWidth: '150px' }}>Pay {Number(gst + totalPayment).toLocaleString('en-IN', curFormat)}
                                                    </button>}
                                                    {totalPayment === 0 && <button className='mybutton button5' onClick={(e) => {
                                                        activateEvent();

                                                    }} style={{ fontWeight: 'bold', minWidth: '150px' }}>Activate Event
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div> :
                                <div style={{ padding: '10px' }}>

                                    <div className='create-event-button-div'>
                                        <img src="/img/create_event.svg" alt="create event"></img>
                                        <h1>Create Event With Us Now!!</h1>
                                        <h2 style={{ padding: '0 0 10px 0' }}>
                                            Create unforgettable experiences with our user-friendly and robust sports event platform.
                                        </h2>
                                        <h2>Whether you're hosting on-ground or digital events, join a platform trusted by millions of athletes.</h2>
                                        <h2 style={{ color: 'red' }}> <strong>All this, for just ₹2,000/- plus GST</strong></h2>
                                        <div onClick={(e) => {
                                            // console.log('create event ')
                                            // setShowPackage(true);
                                            handleFocusDiv();
                                            navigate('/CreateEvent', {
                                                state: {
                                                    createStage: 'package'
                                                }
                                            }) // to be moved after seletcing package
                                        }}>
                                            <button className='mybutton button5'>Create an Event</button>
                                        </div>
                                    </div>

                                </div>
                            }


                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12'>

                            <div className='dashboard-cards' style={{ margin: '0' }}>
                                <div style={{ padding: '10px' }} >
                                    <div className={eventFilter === 'Pending' ? 'total-number-card pending selected' : 'total-number-card pending'}>
                                        <h1>Pending</h1>
                                        <h2>{unpaidCount}</h2>
                                        <span className="material-symbols-outlined">
                                            new_releases
                                        </span>
                                    </div>
                                </div>
                                <div style={{ padding: '10px' }}
                                    onClick={(e) => {
                                        setEventFilter('Active')
                                        setFilteredEvent(activeEvent)
                                    }}>
                                    <div className={eventFilter === 'Active' ? 'total-number-card pending selected' : 'total-number-card pending'}>
                                        <h1>Active</h1>
                                        <h2>{activeCount}</h2>
                                        <span className="material-symbols-outlined">
                                            done_all
                                        </span>
                                    </div>
                                </div>
                                <div style={{ padding: '10px' }}
                                    onClick={(e) => {
                                        setEventFilter('Completed')
                                        setFilteredEvent(closedEvent)
                                    }}
                                >
                                    <div className={eventFilter === 'Completed' ? 'total-number-card pending selected' : 'total-number-card pending'}>
                                        <h1>Completed</h1>
                                        <h2>{completedCount}</h2>
                                        <span className="material-symbols-outlined">
                                            block
                                        </span>
                                    </div>
                                </div>
                                <div style={{ padding: '10px' }}
                                    onClick={(e) => {
                                        setEventFilter('Cancelled / On-Hold')
                                        setFilteredEvent(...cancelledEvent, ...onholdEvent)
                                    }}
                                >
                                    <div className={eventFilter === 'Cancelled / On-Hold' ? 'total-number-card pending selected' : 'total-number-card pending'}>
                                        <h1>Cancelled/On-hold</h1>
                                        <h2>{cancelledCount + onholdCount}</h2>
                                        <span className="material-symbols-outlined">
                                            back_hand
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            }
            <br />
            {true
                && <div ref={divRef} key={'packagediv'}
                >
                    <div className="container">
                        <div className="heading">
                            <span className="material-symbols-outlined">
                                deployed_code
                            </span>
                            <h4 style={{ fontWeight: '1000' }}>Packages</h4>
                        </div><br />
                        <RegistrationPackage calledFrom='dashboard' setShowSection={setShowSection}></RegistrationPackage>
                    </div>

                </div>}
            <div style={{ padding: '10px' }}>
                <div className='row no-gutters'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div>
                            <h2>{eventFilter + ' Events'}</h2>
                        </div>
                    </div>
                    {

                        filteredEvent && filteredEvent.map((eventObj) => {
                            return <NewEventCard key={eventObj.Eventid}
                                // keyIndex={index++} 
                                event={eventObj} action={'DETAIL'} >eventObj.EventName</NewEventCard>
                        })
                    }

                </div>
            </div>
            {/* Participant Dashboard < br /> */}
            {/* <hr>
            </hr>
            <ManageParticipant EventList={props.EventList} registrationType={'Pending'} ></ManageParticipant>
            <hr>
            </hr>
            <ManageParticipant EventList={props.EventList} registrationType={'all'} ></ManageParticipant> */}

            {/* <button onClick={createEvent}>Create Event</button> */}
        </div >
    )
}
