import React from 'react';
import '../css/EventCardSmall.css';
import { Link } from 'react-router-dom';

export default function EventCardSmall(props) {
    let eventCallout = 'Event is LiVE'

    let defaultLOGO = ''
    // console.log(props)
    switch (props && props.eventDetails && props.eventDetails.SportName.toUpperCase()) {
        case 'BADMINTON':
            defaultLOGO = '/img/genre_badminton.png'
            break;
        case 'CARROM':
            defaultLOGO = '/img/genre_carrom.png'
            break;
        case 'CHESS':
            defaultLOGO = '/img/genre_chess.png'
            break;
        case 'SQUASH':
            defaultLOGO = '/img/genre_squash.png'
            break;
        case 'TABLE TENNIS':
            defaultLOGO = '/img/genre_tt.png'
            break;
        case 'TENNIS':
            defaultLOGO = '/img/genre_tennis.png'
            break;
        case 'CARROM':
            defaultLOGO = '/img/genre_carrom.png'
            break;
        case 'CRICKET':
            defaultLOGO = '/img/genre_badminton.png'
            break;
        default:
            defaultLOGO = "/img/genre_badminton.png"

    };
    var curFormat = {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };
    var options = {
        year: '2-digit',
        // year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    if (props.eventDetails.EventStatus !== 'Active') {
        eventCallout = 'Event is ' + props.eventDetails.EventStatus
    } else if (props.eventDetails.RegistrationOpenFlag === 'ON') {
        let datecheck = new Date(props.eventDetails.EventStartDate.seconds * 1000 - (4 * 24 * 60 * 60 * 1000))
        console.log(props.eventDetails.id, new Date(datecheck))
        if (datecheck < new Date()) {
            eventCallout = 'Registration is closing soon'
        }
        else if (props.eventDetails.PublishDrawFlag && props.eventDetails.PublishDrawFlag.toLowerCase() === 'on') {
            eventCallout = 'Draw is published'
        }
        else {
            eventCallout = 'Event is Active'

        }
    }
    else {
        eventCallout = 'Event is Active'

    }


    let prize = props.eventDetails && props.eventDetails.MinimumFee ? props.eventDetails.MinimumFee.toLocaleString('en-IN', curFormat) : '₹0'
    let sDate = props.eventDetails && props.eventDetails.EventStartDate ? (new Date(props.eventDetails.EventStartDate.seconds * 1000)).toLocaleDateString("en-IN", options) : ''
    // console.log('props.eventDetails.EventLogo', props.eventDetails.EventLogo)
    return (
        <>
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="event-card">
                    <div className='event-card-location'>
                        <h6>Bhubaneshwar</h6>
                    </div>
                    <div className='event-card-details'>
                        <div className='event-card-details-img'>
                            <img src="./img/genre_badminton.png" alt="" />
                        </div>
                        <div style={{ width: '18%' }}></div>
                        <div className='event-card-details-name'>
                            <h1>Manu Badminton tournament 2024 tripathi aditya</h1>
                            <h2>Prakash Padukone Badminton Academy (PPBA) anita</h2>
                            <div style={{ height: '30px' }}></div>
                            <div className='price'>
                                <h3>₹3,000</h3>
                                <h4>11 Jun 2024</h4>
                            </div>
                            <small className='blink'>Live</small>
                        </div>
                        <div className='event-card-details-arrow'>
                            <span className="material-symbols-outlined">
                                arrow_forward_ios
                            </span>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={"/EventDetails/" + props.eventDetails.id}>
                    <div className="event-card-homepage" style={{ background: '#fff', border: '1px solid #ddd' }} >
                        <div className='event-card-homepage-location'>
                            <h6>{props.eventDetails.City}</h6>
                        </div>
                        <div className='event-card-homepage-details'>
                            <div className='event-card-homepage-details-img'>
                                {/* <img src={props.eventDetails.EventLogo && Array.isArray(props.eventDetails.EventLogo) ?
                                    props.eventDetails.EventLogo[0] : props.eventDetails.EventLogo !== '' ? props.eventDetails.EventLogo :
                                        defaultLOGO} alt="" /> */}

                                <img src={defaultLOGO} alt="" />
                            </div>
                            <div style={{ width: '18%' }}></div>
                            <div className='event-card-homepage-details-name'>
                                <h1>{props.eventDetails.EventName}</h1>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ color: '#888', fontSize: '1.4rem', transform: 'translateY(-4px)' }} className="material-symbols-outlined">
                                        location_on
                                    </span>
                                    <h2>{props.eventDetails.EventVenue.length > 50 ? props.eventDetails.EventVenue.substring(0, 70) + '...' : props.eventDetails.EventVenue}</h2>
                                </div>
                                <div style={{ height: '30px' }}></div>
                                <div className='price'>
                                    <h3>{prize} onwards</h3>
                                    <h4>{sDate}</h4>
                                </div>
                                <small className='blink'>{eventCallout}</small>
                            </div>
                            <div className='event-card-homepage-details-arrow'>
                                <span className="material-symbols-outlined">
                                    arrow_forward_ios
                                </span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>

    )
}
