import React, { useEffect, useState } from 'react'
import TournamentCategory from './TournamentCategory'
import { useParams } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument.js'
import { where, orderBy } from 'firebase/firestore';
import { useCollection } from '../hooks/useCollection.js'
import { useUserAuth } from '../context/UserAuthcontext.js'
import EventDetailsMenu from './EventDetailsMenu'
import EDTournamentDetails from './EDTournamentDetails'
import { useLocation } from 'react-router-dom';
export default function EventCategory() {
    const { eventID } = useParams();
    const location = useLocation();
    const { user } = useUserAuth()

    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const queryConstraints = [
        where('EventID', '==', eventID),
        // Add more query constraints as needed
    ];
    const queryConstraintsCategory = [
        where('EventID', '==', eventID),
        orderBy('Gender'),
        orderBy('EventType'),
        orderBy('CategoryName'),
        // Add more query constraints as needed
    ];
    const [eventDetails, setEventDetails] = useState(null);

    const { document: eventDoc, error: eventDocError } = useDocument('EventList', eventID)
    const { documents: categoryDocs, error: categoryDocError } = useCollection('EventCategory', queryConstraintsCategory)
    const { documents: ownerDocs, error: ownerDocError } = useCollection('EventOwner', queryConstraints)
    console.log('categoryDocError', categoryDocError)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])

    function checkEditable() {

        let userPhone = ''
        userPhone = user && user.userInfo && user.userInfo.Phone && user.userInfo.Phone.substring(user.userInfo.Phone.length - 10, user.userInfo.Phone.length)
        // console.log('owner ', ownerDocs)

        if (user && user.userInfo && eventDoc && eventDoc.EventOwnerPhone && eventDoc.EventOwnerPhone.search(userPhone) >= 0) {
            console.log('in 1')
            setIsEditEnabled(true)
        }

        if ((user && user.userInfo && (eventDoc && eventDoc.OrganizerID === user.userInfo.uid)) ||
            (user && user.userInfo && user.userInfo.SelectedRole && (user.userInfo.SelectedRole.toUpperCase() === 'ADMIN'))) {
            console.log('in 2')
            setIsEditEnabled(true)
        }
        if (ownerDocs && ownerDocs.find(e => e.OwnerPhone.includes(userPhone))) {
            console.log('in 3')
            setIsEditEnabled(true)
        }
        if (eventDoc && eventDoc.EventOwnerPhone && eventDoc.EventOwnerPhone.search(userPhone) >= 0) {
            console.log('in 4')
            setIsEditEnabled(true)
        }

        // setLoading(false);
    }

    useEffect(() => {
        setEventDetails(eventDoc);
        // onAuthStateChanged(auth, (user) => {
        //     if (user) {

        //         const uid = user.uid;
        //         // ...
        //         // console.log("uid", uid)
        //     } else {
        //         // User is signed out
        //         // ...
        //         // console.log("user is logged out")
        //     }
        //     // console.log(eventDetails);
        // })
        checkEditable()
    }, [eventDoc, ownerDocs, categoryDocs]);

    return (
        <>
            {eventDoc && <EventDetailsMenu calledFrom='Category'
                eventID={eventID}
                eventDetails={eventDoc}
            />}
            <div className="container-fluid">

                <div className="row no-gutters">

                    {/* {eventDetails && <EventDetailsMenu calledFrom='Entries'
                            eventID={eventDetails.Eventid}
                            eventDetails={eventDetails}
                            entryCount={entryCount}
                            uniqueParticipantDetails={uniqueParticipantDetails}
                            participantDetails={participantDetails}
                            participantCount={participantCount}
                        />} */}
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <TournamentCategory categoryDetails={categoryDocs}
                            IsEditEnabled={isEditEnabled}
                            eventDetails={eventDetails}
                            categoryInRow={2}>

                        </TournamentCategory>

                    </div>

                    {eventDetails && <EDTournamentDetails
                        IsEditEnabled={false}
                        eventDetails={eventDetails}
                        showRegistrationButton={true}
                    // showRegistration={true}
                    // partcipantObj={registrationDoc}
                    // rentryCount={registrationDoc ? registrationDoc.length : 0}
                    // isDrawAvailable={isDrawAvailable}
                    />}
                </div>
            </div >

        </>
    )
}
